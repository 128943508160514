import { Checkbox, Col, DatePicker, Divider, Form, Input, Modal, Row, Select } from "antd";
import get from "lodash/get";
import { Moment } from "moment";
import React, { useEffect } from "react";
import t from "../../../../app/i18n";
import ActionButton from "../../../../common/components/buttons/ActionButton";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import LabelWithTooltip from "../../../../common/components/form/labels/LabelWithTooltip";
import DeleteIcon from "../../../../common/components/icons/DeleteIcon";
import ItemCreatedUpdatedInfoView from "../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import { UUID } from "../../../../common/types";
import { formatAgentAggregatedName } from "../../../../common/utils/formatUtils";
import {
  datePickerStandardProps,
  disableDatePickerOutOfMinDateIncluded,
  resolveFormValidationError,
  selectStandardProps,
  toMoment,
  useFormErrorHandler
} from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { isDefinedValue } from "../../../../common/utils/utils";
import { validations } from "../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { createAgentCompetenceActions, updateAgentCompetenceActions } from "../../ducks";
import { AgentCompetenceLevel, AgentCompetenceProvider, AgentCompetenceType, FinancialSector } from "../../enums";
import { AgentCompetence, AgentCompetenceList, CreateUpdateAgentCompetence } from "../../types";

interface Props {
  open: boolean;
  agentId: UUID;
  competence?: AgentCompetence | AgentCompetenceList;
  onCreate?: typeof createAgentCompetenceActions.request;
  onUpdate?: typeof updateAgentCompetenceActions.request;
  onFormCancel: () => void;
}

const AgentCompetenceForm = ({ open, agentId, competence, onCreate, onUpdate, onFormCancel }: Props) => {
  const [form] = Form.useForm<CreateUpdateAgentCompetence>();
  useFormErrorHandler(form, "agent.competence.attrs", [
    requests.CREATE_AGENT_COMPETENCE,
    requests.UPDATE_AGENT_COMPETENCE
  ]);

  useEffect(() => {
    if (open) {
      if (competence) {
        form.setFieldsValue({
          ...competence,
          intervals: competence.intervals.map(i => ({
            ...i,
            startDate: toMoment(i.startDate),
            endDate: toMoment(i.endDate)
          }))
        });
      } else {
        form.setFieldsValue({ intervals: [{ processed: false }] });
      }
    }
  }, [open, competence, form]);

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_AGENT_COMPETENCE, requests.UPDATE_AGENT_COMPETENCE],
    onFormCancel
  );

  const handleIntervalEndDateDependingField = (fieldIndex: number): void => {
    const { type, intervals } = form.getFieldsValue(["type", "intervals"]) as CreateUpdateAgentCompetence;
    if (type && intervals) {
      form.setFieldsValue({
        intervals: intervals.map(i => {
          let endDate: Moment;
          switch (type) {
            case AgentCompetenceType.TEST:
              endDate = i.startDate ? toMoment(i.startDate).add(4, "year").subtract(1, "day") : null;
              break;
            case AgentCompetenceType.TRAINING:
              endDate = i.startDate ? toMoment(i.startDate).add(1, "year").subtract(1, "day") : null;
              break;
            case AgentCompetenceType.OTHER:
              endDate = isDefinedValue(fieldIndex) ? (i.endDate as Moment) : null;
              break;
          }
          return { ...i, endDate };
        })
      });
    }
  };

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (competence) {
          onUpdate?.({ id1: agentId, id2: competence.id, object: values });
        } else {
          onCreate?.({ id: agentId, object: values });
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={
        competence
          ? t("agent.competence.titles.update") +
            ((competence as AgentCompetenceList).agent
              ? ` | ${formatAgentAggregatedName((competence as AgentCompetenceList).agent)}`
              : "")
          : t("agent.competence.titles.create")
      }
      cancelText={t("common.cancel")}
      okText={t("common.save")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <ItemCreatedUpdatedInfoView className="margin-bottom-small" item={competence} />

      <Form form={form} layout="vertical" name="agentCompetenceForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={8}>
            <Form.Item
              name="type"
              label={t("agent.competence.enums.competenceType._label")}
              rules={[validations.notNull]}
            >
              <Select
                {...selectStandardProps}
                options={Object.keys(AgentCompetenceType).map(type => ({
                  value: type,
                  label: t("agent.competence.enums.competenceType." + type)
                }))}
                onChange={() => handleIntervalEndDateDependingField(null)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item noStyle shouldUpdate={(prev, next) => prev.type !== next.type}>
          {({ getFieldValue }) => {
            const type = getFieldValue("type") as AgentCompetenceType;
            return (
              <>
                {(type === AgentCompetenceType.TEST || type === AgentCompetenceType.TRAINING) && (
                  <Row gutter={rowGutter}>
                    <Col span={8}>
                      <Form.Item
                        name="sector"
                        label={t("agent.enums.financialSector._label")}
                        rules={[validations.notNull]}
                      >
                        <Select
                          {...selectStandardProps}
                          options={Object.keys(FinancialSector).map(sector => ({
                            value: sector,
                            label: t("agent.enums.financialSector." + sector)
                          }))}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        name="level"
                        label={t("agent.competence.enums.competenceLevel._label")}
                        rules={[validations.notNull]}
                      >
                        <Select
                          {...selectStandardProps}
                          options={Object.keys(AgentCompetenceLevel).map(level => ({
                            value: level,
                            label: t("agent.competence.enums.competenceLevel." + level)
                          }))}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        name="provider"
                        label={t("agent.competence.enums.competenceProvider._label")}
                        rules={[validations.none]}
                      >
                        <Select
                          {...selectStandardProps}
                          allowClear
                          options={Object.keys(AgentCompetenceProvider).map(provider => ({
                            value: provider,
                            label: t("agent.competence.enums.competenceProvider." + provider)
                          }))}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                {type === AgentCompetenceType.OTHER && (
                  <Row gutter={rowGutter}>
                    <Col span={24}>
                      <Form.Item
                        name="label"
                        label={t("agent.competence.attrs.label")}
                        rules={[validations.notBlank, validations.size(1, 255)]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <Row gutter={rowGutter}>
                  <Col span={24}>
                    <Form.Item name="note" label={t("agent.competence.attrs.note")} rules={[validations.size(1, 8192)]}>
                      <Input.TextArea autoSize={{ minRows: 2, maxRows: 5 }} />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider>{t("agent.competence.attrs.intervals._label")}</Divider>

                <Form.List name={["intervals"]} initialValue={[{}]}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(field => (
                        <Row gutter={rowGutter} key={field.key}>
                          <Col span={22}>
                            <HiddenInput name={[field.name, "id"]} />
                            <HiddenInput name={[field.name, "optimisticLockVersion"]} />

                            <Row gutter={rowGutter}>
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "startDate"]}
                                  label={t("agent.competence.attrs.intervals.startDate")}
                                  rules={[validations.notNull]}
                                >
                                  <DatePicker
                                    {...datePickerStandardProps}
                                    onChange={() => handleIntervalEndDateDependingField(field.name)}
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={8}>
                                <Form.Item
                                  noStyle
                                  shouldUpdate={(prev, next) =>
                                    get(prev, ["intervals", field.name, "startDate"]) !==
                                    get(next, ["intervals", field.name, "startDate"])
                                  }
                                >
                                  {({ getFieldValue }) => {
                                    const startDate = getFieldValue(["intervals", field.name, "startDate"]) as Moment;
                                    return (
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "endDate"]}
                                        label={t("agent.competence.attrs.intervals.endDate")}
                                        rules={[
                                          validations.notNull,
                                          validations.notBefore(
                                            startDate,
                                            t("agent.competence.attrs.intervals.startDate")
                                          )
                                        ]}
                                      >
                                        <DatePicker
                                          {...datePickerStandardProps}
                                          disabled={
                                            type === AgentCompetenceType.TEST || type === AgentCompetenceType.TRAINING
                                          }
                                          disabledDate={checked =>
                                            startDate
                                              ? disableDatePickerOutOfMinDateIncluded(checked, startDate)
                                              : false
                                          }
                                        />
                                      </Form.Item>
                                    );
                                  }}
                                </Form.Item>
                              </Col>

                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "processed"]}
                                  className="form-item-without-label"
                                  valuePropName="checked"
                                  rules={[validations.none]}
                                  initialValue={false}
                                >
                                  <Checkbox>
                                    <LabelWithTooltip
                                      tooltip={t("agent.competence.helpers.processedIntervalDesc")}
                                      label={t("agent.competence.attrs.intervals.processed")}
                                    />
                                  </Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>

                          <Col span={2}>{fields.length > 1 && <DeleteIcon onClick={() => remove(field.name)} />}</Col>
                        </Row>
                      ))}

                      <ActionButton
                        icon="plus"
                        label={t("agent.competence.actions.addInterval")}
                        className="margin-top-small"
                        onClick={() => add()}
                      />
                    </>
                  )}
                </Form.List>
              </>
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AgentCompetenceForm;
