import React from "react";
import AntIcon from "../../components/icons/AntIcon";
import { ContactType } from "../enums";

interface Props {
  type: ContactType;
}

const ContactTypeIcon = ({ type }: Props) => {
  switch (type) {
    case ContactType.EMAIL:
      return <AntIcon type="mail" />;
    case ContactType.PHONE_NUMBER:
      return <AntIcon type="phone" />;
    case ContactType.OTHER:
      return <AntIcon type="contact" />;
    default:
      return null;
  }
};

export default ContactTypeIcon;
