import { Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import { Moment } from "moment";
import React, { useEffect } from "react";
import t from "../../../../app/i18n";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import ItemCreatedUpdatedInfoView from "../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import { UUID } from "../../../../common/types";
import {
  datePickerClearableProps,
  datePickerStandardProps,
  disableDatePickerOutOfMinDate,
  disableDatePickerOutOfMinDateIncluded,
  resolveFormValidationError,
  selectStandardProps,
  toMoment,
  useFormErrorHandler
} from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { createAgentLicenseActions, updateAgentLicenseActions } from "../../ducks";
import { FinancialSector } from "../../enums";
import { AgentLicense, CreateUpdateAgentLicense } from "../../types";

interface Props {
  open: boolean;
  agentId: UUID;
  defaultLicenseValue?: string;
  license?: AgentLicense;
  onCreate?: typeof createAgentLicenseActions.request;
  onUpdate?: typeof updateAgentLicenseActions.request;
  onFormCancel: () => void;
}

const AgentLicenseForm = ({ open, agentId, defaultLicenseValue, license, onCreate, onUpdate, onFormCancel }: Props) => {
  const [form] = Form.useForm<CreateUpdateAgentLicense>();
  useFormErrorHandler(form, "agent.license.attrs", [requests.CREATE_AGENT_LICENSE, requests.UPDATE_AGENT_LICENSE]);

  useEffect(() => {
    if (open) {
      if (license) {
        form.setFieldsValue({
          ...license,
          registrationProposalDate: toMoment(license.registrationProposalDate),
          registrationDate: toMoment(license.registrationDate),
          deletionProposalDate: toMoment(license.deletionProposalDate),
          deletionDate: toMoment(license.deletionDate)
        });
      } else {
        form.setFieldsValue({ value: defaultLicenseValue });
      }
    }
  }, [open, defaultLicenseValue, license, form]);

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_AGENT_LICENSE, requests.UPDATE_AGENT_LICENSE],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (license) {
          onUpdate?.({ id1: agentId, id2: license.id, object: values });
        } else {
          onCreate?.({ id: agentId, object: values });
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={t(license ? "agent.license.titles.update" : "agent.license.titles.create")}
      cancelText={t("common.cancel")}
      okText={t("common.save")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <ItemCreatedUpdatedInfoView className="margin-bottom-small" item={license} />

      <Form form={form} layout="vertical" name="agentLicenseForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={12}>
            <Form.Item name="sector" label={t("agent.enums.financialSector._label")} rules={[validations.notNull]}>
              <Select
                {...selectStandardProps}
                options={Object.keys(FinancialSector).map(sector => ({
                  value: sector,
                  label: t("agent.enums.financialSector." + sector)
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="value" label={t("agent.license.attrs.value")} rules={[validations.size(1, 64)]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={12}>
            <Form.Item
              name="registrationProposalDate"
              label={t("agent.license.attrs.registrationProposalDate")}
              rules={[validations.notNull]}
            >
              <DatePicker {...datePickerStandardProps} showToday={false} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.registrationProposalDate !== next.registrationProposalDate}
            >
              {({ getFieldValue }) => {
                const registrationProposalDate = getFieldValue("registrationProposalDate") as Moment;
                return (
                  <Form.Item
                    name="registrationDate"
                    label={t("agent.license.attrs.registrationDate")}
                    rules={[
                      registrationProposalDate
                        ? validations.notBefore(
                            registrationProposalDate,
                            t("agent.license.attrs.registrationProposalDate")
                          )
                        : validations.none
                    ]}
                  >
                    <DatePicker
                      {...datePickerClearableProps}
                      showToday={false}
                      disabledDate={checked =>
                        registrationProposalDate
                          ? disableDatePickerOutOfMinDate(checked, registrationProposalDate)
                          : false
                      }
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.registrationProposalDate !== next.registrationProposalDate ||
              prev.registrationDate !== next.registrationDate ||
              prev.deletionProposalDate !== next.deletionProposalDate
            }
          >
            {({ getFieldValue }) => {
              const registrationProposalDate = getFieldValue("registrationProposalDate") as Moment;
              const registrationDate = getFieldValue("registrationDate") as Moment;
              const deletionProposalDate = getFieldValue("deletionProposalDate") as Moment;
              return (
                <>
                  <Col span={12}>
                    <Form.Item
                      name="deletionProposalDate"
                      label={t("agent.license.attrs.deletionProposalDate")}
                      rules={[
                        registrationDate
                          ? validations.notSameOrBefore(registrationDate, t("agent.license.attrs.registrationDate"))
                          : registrationProposalDate
                            ? validations.notSameOrBefore(
                                registrationProposalDate,
                                t("agent.license.attrs.registrationProposalDate")
                              )
                            : validations.none
                      ]}
                    >
                      <DatePicker
                        {...datePickerClearableProps}
                        showToday={false}
                        disabledDate={checked =>
                          registrationDate
                            ? disableDatePickerOutOfMinDateIncluded(checked, registrationDate)
                            : registrationProposalDate
                              ? disableDatePickerOutOfMinDateIncluded(checked, registrationProposalDate)
                              : false
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="deletionDate"
                      label={t("agent.license.attrs.deletionDate")}
                      rules={[
                        deletionProposalDate
                          ? validations.notBefore(deletionProposalDate, t("agent.license.attrs.deletionProposalDate"))
                          : registrationDate
                            ? validations.notSameOrBefore(registrationDate, t("agent.license.attrs.registrationDate"))
                            : registrationProposalDate
                              ? validations.notSameOrBefore(
                                  registrationProposalDate,
                                  t("agent.license.attrs.registrationProposalDate")
                                )
                              : validations.none
                      ]}
                    >
                      <DatePicker
                        {...datePickerClearableProps}
                        showToday={false}
                        disabledDate={checked =>
                          deletionProposalDate
                            ? disableDatePickerOutOfMinDate(checked, deletionProposalDate)
                            : registrationDate
                              ? disableDatePickerOutOfMinDateIncluded(checked, registrationDate)
                              : registrationProposalDate
                                ? disableDatePickerOutOfMinDateIncluded(checked, registrationProposalDate)
                                : false
                        }
                      />
                    </Form.Item>
                  </Col>
                </>
              );
            }}
          </Form.Item>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item name="note" label={t("agent.license.attrs.note")} rules={[validations.size(1, 8192)]}>
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 5 }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AgentLicenseForm;
