import { Card, Col, Divider, Form, Input, InputNumber, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import React from "react";
import { useSelector } from "react-redux";
import t from "../../../../../app/i18n";
import ActionButton from "../../../../../common/components/buttons/ActionButton";
import InputAddon from "../../../../../common/components/form/addons/InputAddon";
import BooleanRadioFormItem from "../../../../../common/components/form/components/BooleanRadioFormItem";
import LabelWithTooltip from "../../../../../common/components/form/labels/LabelWithTooltip";
import DeleteIcon from "../../../../../common/components/icons/DeleteIcon";
import { rowGutter } from "../../../../../common/constants";
import { RootState, UUID } from "../../../../../common/types";
import {
  inputNumberDecimalStandardProps,
  inputNumberIntegerStandardProps,
  selectStandardProps
} from "../../../../../common/utils/formUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { PaymentFrequency } from "../../../../contract/enums";
import InstitutionSelect from "../../../../enumerations/components/form/InstitutionSelect";
import ProductSelect from "../../../../enumerations/components/form/ProductSelect";
import { selectProductsClassificationEnums } from "../../../../enumerations/ducks";
import { InstitutionWithProducts } from "../../../../enumerations/types";
import { ProductFinancialSector } from "../../../../product/enums";
import { PRODUCT_SECTOR_TO_INSTITUTION_TYPE_MAP } from "../../../../product/utils";
import { LoanPurpose } from "../../../enums";
import { CreateUpdateFinancialMediation, FinancialMediationRecommendation } from "../../../types";

interface Props {
  form: FormInstance<CreateUpdateFinancialMediation>;
  formDisabled: boolean;
  sector: ProductFinancialSector;
  validateAllFields: boolean;
}

const FinancialMediationFormRecommendationsSection = ({ form, formDisabled, sector, validateAllFields }: Props) => {
  const productsClassification = useSelector<RootState, InstitutionWithProducts[]>(selectProductsClassificationEnums);

  const colSpan = 4;
  const recommendationTPrefix = "financialMediation.attrs.mediationData.recommendationData.recommendations";

  const handleInstitutionIdChange = (institutionId: UUID, index: number): void => {
    const institution = productsClassification.find(institution => institution.id === institutionId);
    const productId = form.getFieldValue([
      "mediationData",
      "recommendationData",
      "recommendations",
      index,
      "productId"
    ]) as UUID;

    if (
      productId &&
      !institution?.productGroups.flatMap(group => group.products).some(product => product.id === productId)
    ) {
      const updatedRecommendations = [
        ...(form.getFieldValue(["mediationData", "recommendationData", "recommendations"]) || [])
      ] as FinancialMediationRecommendation[];
      updatedRecommendations[index] = { ...updatedRecommendations[index], productId: null };

      form.setFieldsValue({
        mediationData: {
          recommendationData: {
            recommendations: updatedRecommendations
          }
        }
      });
    }
  };

  return (
    <Card type="inner" className="card-box" title={t("financialMediation.sections.recommendationData")}>
      <Form.List name={["mediationData", "recommendationData", "recommendations"]} initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(field => (
              <Row gutter={rowGutter} key={field.key}>
                <Col span={23}>
                  <Row gutter={rowGutter}>
                    <Col className="bold-text margin-bottom-tiny">
                      {t("financialMediation.helpers.recommendation.number", { number: field.name + 1 })}
                    </Col>
                  </Row>

                  <Row gutter={rowGutter}>
                    <Col span={colSpan}>
                      <InstitutionSelect
                        formItemProps={{
                          name: [field.name, "institutionId"],
                          label: (
                            <span className="form-item-additional">{t(`${recommendationTPrefix}.institutionId`)}</span>
                          ),
                          rules: [validations.conditionalRule(validateAllFields, validations.notNull)]
                        }}
                        optionsProps={{
                          filterType: PRODUCT_SECTOR_TO_INSTITUTION_TYPE_MAP.get(sector)
                        }}
                        selectProps={{
                          onChange: value => handleInstitutionIdChange(value, field.name)
                        }}
                      />
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prev, next) =>
                          prev.mediationData?.recommendationData?.recommendations?.[field.name]?.institutionId !==
                          next.mediationData?.recommendationData?.recommendations?.[field.name]?.institutionId
                        }
                      >
                        {({ getFieldValue }) => {
                          const institutionId = getFieldValue([
                            "mediationData",
                            "recommendationData",
                            "recommendations",
                            field.name,
                            "institutionId"
                          ]);

                          return (
                            <ProductSelect
                              formItemProps={{
                                name: [field.name, "productId"],
                                label: (
                                  <span className="form-item-additional">
                                    {t(`${recommendationTPrefix}.productId`)}
                                  </span>
                                ),
                                rules: [validations.conditionalRule(validateAllFields, validations.notNull)]
                              }}
                              optionsProps={{
                                institutionId,
                                groupByProductGroup: true,
                                filterSectors: [sector],
                                hideAll: !!!institutionId
                              }}
                              selectProps={{
                                placeholder: !!!institutionId
                                  ? t("financialMediation.helpers.recommendation.chooseInstitution")
                                  : undefined
                              }}
                            />
                          );
                        }}
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={[field.name, "paymentPeriod"]}
                        label={
                          <span className="form-item-additional">{t(`${recommendationTPrefix}.paymentPeriod`)}</span>
                        }
                        rules={[
                          validations.conditionalRule(validateAllFields, validations.notNull),
                          validations.minNumber(0)
                        ]}
                      >
                        <InputNumber {...inputNumberIntegerStandardProps} />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={[field.name, "paymentAmount"]}
                        label={
                          <span className="form-item-additional">{t(`${recommendationTPrefix}.paymentAmount`)}</span>
                        }
                        rules={[
                          validations.conditionalRule(validateAllFields, validations.notNull),
                          validations.minNumber(0)
                        ]}
                      >
                        <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={[field.name, "paymentFrequency"]}
                        label={
                          <span className="form-item-additional">{t("contract.enums.paymentFrequency._label")}</span>
                        }
                        rules={[validations.conditionalRule(validateAllFields, validations.notNull)]}
                      >
                        <Select
                          {...selectStandardProps}
                          options={Object.keys(PaymentFrequency).map(frequency => ({
                            value: frequency,
                            label: t("contract.enums.paymentFrequency." + frequency)
                          }))}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={[field.name, "feeAmount"]}
                        label={<span className="form-item-additional">{t(`${recommendationTPrefix}.feeAmount`)}</span>}
                        rules={[
                          validations.conditionalRule(validateAllFields, validations.notNull),
                          validations.minNumber(0)
                        ]}
                      >
                        <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={rowGutter}>
                    {(sector === ProductFinancialSector.NON_LIFE_INSURANCES ||
                      sector === ProductFinancialSector.LIFE_INSURANCES ||
                      sector === ProductFinancialSector.SENIOR_PENSION_SAVINGS ||
                      sector === ProductFinancialSector.SUPPLEMENTARY_PENSION_SAVINGS) && (
                      <Col span={colSpan * 2}>
                        <Form.Item
                          name={[field.name, "description"]}
                          label={
                            <LabelWithTooltip
                              label={t(`${recommendationTPrefix}.description`)}
                              tooltip={t(
                                `financialMediation.helpers.recommendation.${
                                  sector === ProductFinancialSector.NON_LIFE_INSURANCES ||
                                  sector === ProductFinancialSector.LIFE_INSURANCES
                                    ? "insuranceDesc"
                                    : "pillarsDesc"
                                }`
                              )}
                              className="form-item-additional"
                            />
                          }
                          rules={[
                            validations.conditionalRule(validateAllFields, validations.notBlank),
                            validations.size(1, 128)
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    )}

                    {sector === ProductFinancialSector.LOANS_AND_MORTGAGES && (
                      <>
                        <Col span={colSpan}>
                          <Form.Item
                            name={[field.name, "loanAmount"]}
                            label={
                              <span className="form-item-additional">{t(`${recommendationTPrefix}.loanAmount`)}</span>
                            }
                            rules={[
                              validations.conditionalRule(validateAllFields, validations.notNull),
                              validations.minNumber(1)
                            ]}
                          >
                            <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
                          </Form.Item>
                        </Col>

                        <Col span={colSpan}>
                          <Form.Item
                            name={[field.name, "loanPurpose"]}
                            label={
                              <span className="form-item-additional">
                                {t("financialMediation.enums.loanPurpose._label")}
                              </span>
                            }
                            rules={[validations.conditionalRule(validateAllFields, validations.notNull)]}
                          >
                            <Select
                              {...selectStandardProps}
                              options={Object.keys(LoanPurpose).map(purpose => ({
                                value: purpose,
                                label: t("financialMediation.enums.loanPurpose." + purpose)
                              }))}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={colSpan}>
                          <Form.Item
                            name={[field.name, "interestRate"]}
                            label={
                              <span className="form-item-additional">{t(`${recommendationTPrefix}.interestRate`)}</span>
                            }
                            rules={[
                              validations.conditionalRule(validateAllFields, validations.notNull),
                              validations.minNumber(0.01),
                              validations.maxNumber(100)
                            ]}
                          >
                            <InputNumber
                              {...inputNumberDecimalStandardProps}
                              addonAfter={<InputAddon type="percentage" />}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={colSpan}>
                          <Form.Item
                            name={[field.name, "aprc"]}
                            label={<span className="form-item-additional">{t(`${recommendationTPrefix}.aprc`)}</span>}
                            rules={[
                              validations.conditionalRule(validateAllFields, validations.notNull),
                              validations.minNumber(0.01),
                              validations.maxNumber(100)
                            ]}
                          >
                            <InputNumber
                              {...inputNumberDecimalStandardProps}
                              addonAfter={<InputAddon type="percentage" />}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>

                <Col span={1} className="right-align" style={{ paddingTop: "28px" }}>
                  {!formDisabled && fields.length > 1 && <DeleteIcon onClick={() => remove(field.name)} />}
                </Col>
              </Row>
            ))}

            {!formDisabled && (
              <ActionButton
                icon="plus"
                className="margin-top-small"
                label={t("financialMediation.actions.addRecommendation")}
                disabled={fields.length >= 5}
                onClick={() => add()}
              />
            )}
          </>
        )}
      </Form.List>

      <Row gutter={rowGutter} className="margin-top-large">
        <Col span={colSpan * 2}>
          <Form.Item
            name={["mediationData", "recommendationData", "crossSellingInformation"]}
            label={t("financialMediation.attrs.mediationData.recommendationData.crossSellingInformation")}
            rules={[validations.size(1, 255)]}
          >
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
          </Form.Item>
        </Col>
      </Row>

      <Divider orientation="left" className="divider-subheader">
        {t("financialMediation.sections.serviceSuitability")}
      </Divider>

      <Row gutter={rowGutter}>
        <Col span={12}>
          <span className="sub-header-info form-item-additional">
            {t("financialMediation.helpers.suitability.desc")}
          </span>

          <BooleanRadioFormItem
            formItemProps={{
              name: ["mediationData", "recommendationData", "allSolutionsSuitable"],
              rules: [validations.conditionalRule(validateAllFields, validations.notNull)]
            }}
            trueLabel={t("financialMediation.helpers.suitability.allSuitable")}
            falseLabel={t("financialMediation.helpers.suitability.notAllSuitable")}
          />
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) =>
            prev.mediationData?.recommendationData?.allSolutionsSuitable !==
            next.mediationData?.recommendationData?.allSolutionsSuitable
          }
        >
          {({ getFieldValue }) =>
            getFieldValue(["mediationData", "recommendationData", "allSolutionsSuitable"]) === false && (
              <Col span={colSpan * 2}>
                <Form.Item
                  name={["mediationData", "recommendationData", "nonSuitableSolutionsDescription"]}
                  label={
                    <LabelWithTooltip
                      label={t(
                        "financialMediation.attrs.mediationData.recommendationData.nonSuitableSolutionsDescription"
                      )}
                      tooltip={t("financialMediation.helpers.suitability.nonSuitableSolutionsDesc")}
                      className="form-item-additional"
                    />
                  }
                  rules={[
                    validations.conditionalRule(validateAllFields, validations.notBlank),
                    validations.size(1, 255)
                  ]}
                >
                  <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
                </Form.Item>
              </Col>
            )
          }
        </Form.Item>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan * 2}>
          <Form.Item
            name={["mediationData", "recommendationData", "suitabilityStatement"]}
            label={
              <span className="form-item-additional">
                {t("financialMediation.attrs.mediationData.recommendationData.suitabilityStatement")}
              </span>
            }
            rules={[validations.conditionalRule(validateAllFields, validations.notBlank), validations.size(1, 255)]}
          >
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
          </Form.Item>
        </Col>

        <Col span={colSpan * 2}>
          <Form.Item
            name={["mediationData", "recommendationData", "otherImportantFacts"]}
            label={t("financialMediation.attrs.mediationData.recommendationData.otherImportantFacts")}
            rules={[validations.size(1, 255)]}
          >
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
          </Form.Item>
        </Col>

        <Col span={colSpan * 2}>
          <Form.Item
            name={["mediationData", "recommendationData", "clientRequirementsDespiteRecommendations"]}
            label={t(
              "financialMediation.attrs.mediationData.recommendationData.clientRequirementsDespiteRecommendations"
            )}
            rules={[validations.size(1, 255)]}
          >
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default FinancialMediationFormRecommendationsSection;
