import { Avatar, Card } from "antd";
import Meta from "antd/lib/card/Meta";
import React from "react";
import UserOutlined from "../../../../../../assets/images/UserOutlined.svg";
import Ellipsis from "../../../../../../common/components/views/Ellipsis";
import { resolveInstitutionLogo } from "../../../../../institution/utils";
import { deleteDashboardContactActions } from "../../../../ducks";
import { DashboardContact, ExternalDashboardContact } from "../../../../types";
import { DashboardContactActionsView } from "./DashboardContactActionsView";
import { DashboardContactFooterView } from "./DashboardContactFooterView";

interface Props {
  contact: ExternalDashboardContact;
  showActions: boolean;
  onUpdate: (contact: DashboardContact) => void;
  onDelete: typeof deleteDashboardContactActions.request;
}

export const ExternalDashboardContactView = ({ contact, showActions, onUpdate, onDelete }: Props) => (
  <Card
    key={contact.id}
    className="dashboard-contacts__card"
    actions={[<DashboardContactFooterView email={contact.email} phone={contact.phone} />]}
  >
    <Meta
      avatar={
        <Avatar
          src={contact.institution ? resolveInstitutionLogo(contact.institution) : UserOutlined}
          className="dashboard-contacts__avatar"
        />
      }
      title={<Ellipsis>{contact.name}</Ellipsis>}
      description={
        <Ellipsis>
          {contact.institution ? `${contact.institution.name} | ${contact.position}` : contact.position}
        </Ellipsis>
      }
    />

    {showActions && (
      <DashboardContactActionsView contact={contact as DashboardContact} onUpdate={onUpdate} onDelete={onDelete} />
    )}
  </Card>
);
