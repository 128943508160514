import { Card, Checkbox, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select } from "antd";
import { Rule } from "antd/lib/form";
import moment from "moment";
import React from "react";
import t from "../../../../../../../app/i18n";
import InputAddon from "../../../../../../../common/components/form/addons/InputAddon";
import LabelWithPopover from "../../../../../../../common/components/form/labels/LabelWithPopover";
import LabelWithTooltip from "../../../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../../../common/constants";
import {
  datePickerStandardProps,
  disableDatePickerOutOfInterval,
  inputNumberIntegerStandardProps,
  selectStandardProps
} from "../../../../../../../common/utils/formUtils";
import { validations } from "../../../../../../../common/utils/validationUtils";
import { ClientType } from "../../../../../../client/enums";
import { PaymentFrequency } from "../../../../../../contract/enums";
import { LoanReinsuranceAmount, RealtyComplicityAmount } from "../../../enums";

interface Props {
  policyHolderType: ClientType;
}

const colSpan = 4;

const vinculationRule = (realtyInsuranceEnabled: boolean): Rule => ({
  validator: (_, value) =>
    value && !realtyInsuranceEnabled ? Promise.reject(t("calc.realty.validations.vinculation")) : Promise.resolve()
});

const RealtyCalcInsuranceSection = ({ policyHolderType }: Props) => {
  const beginningDateMin = moment();
  const beginningDateMax = moment().add(1, "year").add(6, "week");

  return (
    <Card type="inner" className="card-box" title={t("calc.realty.sections.otherDataReinsurancesAndCrossSelling")}>
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={["generalInsuranceData", "effectiveBeginningDate"]}
            label={t("calc.realty.attrs.generalInsuranceData.effectiveBeginningDate")}
            rules={[validations.notNull, validations.dateInInterval(beginningDateMin, beginningDateMax)]}
          >
            <DatePicker
              {...datePickerStandardProps}
              disabledDate={checked => disableDatePickerOutOfInterval(checked, beginningDateMin, beginningDateMax)}
            />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={["generalInsuranceData", "paymentFrequency"]}
            label={t("contract.enums.paymentFrequency._label")}
            rules={[validations.notNull]}
          >
            <Select
              {...selectStandardProps}
              options={[PaymentFrequency.ANNUALLY, PaymentFrequency.SEMI_ANNUALLY, PaymentFrequency.QUARTERLY].map(
                frequency => ({
                  value: frequency,
                  label: t("contract.enums.paymentFrequency." + frequency)
                })
              )}
            />
          </Form.Item>
        </Col>

        <Col span={colSpan} offset={colSpan}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.realtyInsuranceEnabled !== next.realtyInsuranceEnabled}>
            {({ getFieldValue }) => (
              <Form.Item
                name={["generalInsuranceData", "vinculation"]}
                className="form-item-without-label"
                valuePropName="checked"
                dependencies={["realtyInsuranceEnabled"]}
                rules={[vinculationRule(getFieldValue(["realtyInsuranceEnabled"]))]}
                initialValue={false}
              >
                <Checkbox>{t("calc.realty.attrs.generalInsuranceData.vinculation")}</Checkbox>
              </Form.Item>
            )}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={["generalInsuranceData", "complicity"]}
            className="form-item-without-label"
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox>{t("calc.realty.attrs.generalInsuranceData.complicity")}</Checkbox>
          </Form.Item>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.generalInsuranceData.complicity !== next.generalInsuranceData.complicity}
        >
          {({ getFieldValue }) =>
            getFieldValue(["generalInsuranceData", "complicity"]) && (
              <Col span={colSpan}>
                <Form.Item
                  name={["generalInsuranceData", "complicityAmount"]}
                  label={
                    <LabelWithPopover
                      label={t("calc.realty.enums.complicityAmount._label")}
                      popoverTitle={t("calc.realty.helpers.complicityDesc.title")}
                      popoverContent={
                        <table className="data-table-view wide-margins">
                          <tbody>
                            {[1, 2, 3, 4, 5, 6, 7, 8].map(value => (
                              <tr key={value}>
                                <td>{t("calc.realty.helpers.complicityDesc.institution" + value)}</td>
                                <td>{t("calc.realty.helpers.complicityDesc.value" + value)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      }
                    />
                  }
                  rules={[validations.notNull]}
                >
                  <Select
                    {...selectStandardProps}
                    options={Object.keys(RealtyComplicityAmount).map(amount => ({
                      value: amount,
                      label: t("calc.realty.enums.complicityAmount." + amount)
                    }))}
                  />
                </Form.Item>
              </Col>
            )
          }
        </Form.Item>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={12}>
          <Divider className="divider-subheader">{t("calc.realty.sections.reinsurances")}</Divider>

          <Row gutter={rowGutter}>
            <Col span={24}>
              <Form.Item
                name={["generalInsuranceData", "cyberneticReinsurance"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>
                  <LabelWithTooltip
                    label={t("calc.realty.attrs.generalInsuranceData.cyberneticReinsurance")}
                    tooltip={t("calc.realty.helpers.cyberneticReinsuranceDesc")}
                  />
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={24}>
              <Form.Item
                name={["generalInsuranceData", "warrantyReinsurance"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>
                  <LabelWithTooltip
                    label={t("calc.realty.attrs.generalInsuranceData.warrantyReinsurance")}
                    tooltip={t("calc.realty.helpers.warrantyReinsuranceDesc")}
                  />
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={24}>
              <Form.Item
                name={["generalInsuranceData", "cyclingReinsurance"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>
                  <LabelWithTooltip
                    label={t("calc.realty.attrs.generalInsuranceData.cyclingReinsurance")}
                    tooltip={t("calc.realty.helpers.cyclingReinsuranceDesc")}
                  />
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.realtyInsuranceEnabled !== next.realtyInsuranceEnabled ||
                prev.generalInsuranceData.loanReinsurance !== next.generalInsuranceData.loanReinsurance
              }
            >
              {({ getFieldValue }) => {
                const loanReinsurance = getFieldValue(["generalInsuranceData", "loanReinsurance"]);
                return (
                  <>
                    <Col span={8}>
                      <Form.Item
                        name={["generalInsuranceData", "loanReinsurance"]}
                        className={loanReinsurance ? "two-line-form-item-without-label" : null}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox disabled={!getFieldValue(["realtyInsuranceEnabled"])}>
                          <LabelWithTooltip
                            tooltip={t("calc.realty.helpers.loanReinsuranceDesc")}
                            label={t("calc.realty.attrs.generalInsuranceData.loanReinsurance")}
                          />
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    {loanReinsurance && (
                      <Col span={8}>
                        <Form.Item
                          name={["generalInsuranceData", "loanReinsuranceAmount"]}
                          label={t("calc.realty.enums.loanReinsuranceAmount._label")}
                          rules={[validations.notNull]}
                        >
                          <Select
                            {...selectStandardProps}
                            options={Object.keys(LoanReinsuranceAmount).map(amount => ({
                              value: amount,
                              label: t("calc.realty.enums.loanReinsuranceAmount." + amount)
                            }))}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </>
                );
              }}
            </Form.Item>
          </Row>

          <Row gutter={rowGutter}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.generalBuildingData.rented !== next.generalBuildingData.rented ||
                prev.realtyInsuranceEnabled !== next.realtyInsuranceEnabled ||
                prev.generalInsuranceData.rentReinsurance !== next.generalInsuranceData.rentReinsurance
              }
            >
              {({ getFieldValue }) => {
                const rentReinsurance = getFieldValue(["generalInsuranceData", "rentReinsurance"]);
                return (
                  <>
                    <Col span={8}>
                      <Form.Item
                        name={["generalInsuranceData", "rentReinsurance"]}
                        className={rentReinsurance ? "two-line-form-item-without-label" : null}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox
                          disabled={
                            !getFieldValue(["realtyInsuranceEnabled"]) ||
                            !getFieldValue(["generalBuildingData", "rented"])
                          }
                        >
                          <LabelWithTooltip
                            tooltip={t("calc.realty.helpers.rentReinsuranceDesc")}
                            label={t("calc.realty.attrs.generalInsuranceData.rentReinsurance")}
                          />
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    {rentReinsurance && (
                      <Col span={8}>
                        <Form.Item
                          name={["generalInsuranceData", "rentReinsuranceAmount"]}
                          label={
                            <LabelWithTooltip
                              tooltip={t("calc.realty.helpers.rentReinsuranceAmountDesc")}
                              label={t("calc.realty.attrs.generalInsuranceData.rentReinsuranceAmount")}
                            />
                          }
                          rules={[
                            validations.notNull,
                            validations.minNumber(1),
                            validations.maxNumber(24_000),
                            validations.multipleOf100
                          ]}
                        >
                          <InputNumber
                            {...inputNumberIntegerStandardProps}
                            addonAfter={<InputAddon type="euro" />}
                            step={100}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </>
                );
              }}
            </Form.Item>
          </Row>
        </Col>

        <Col span={12}>
          <Divider className="divider-subheader">{t("calc.realty.sections.crossSelling")}</Divider>

          <Row gutter={rowGutter}>
            <Col span={8}>
              <Form.Item
                name={["generalInsuranceData", "crossSelling", "csobContracts"]}
                label={t("calc.realty.attrs.generalInsuranceData.crossSelling.csobContracts")}
                rules={[validations.minNumber(0)]}
              >
                <InputNumber />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name={["generalInsuranceData", "crossSelling", "uniqaContractNumber"]}
                label={t("calc.realty.attrs.generalInsuranceData.crossSelling.uniqaContractNumber")}
                rules={[validations.size(1, 64)]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={8}>
              <Form.Item
                name={["generalInsuranceData", "crossSelling", "premiumContractNumber"]}
                label={t("calc.realty.attrs.generalInsuranceData.crossSelling.premiumContractNumber")}
                rules={[validations.size(1, 64)]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name={["generalInsuranceData", "crossSelling", "unionHealthContract"]}
                valuePropName="checked"
                className="form-item-without-label"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox disabled={policyHolderType !== ClientType.NATURAL}>
                  {t("calc.realty.attrs.generalInsuranceData.crossSelling.unionHealthContract")}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default RealtyCalcInsuranceSection;
