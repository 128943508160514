import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { EntityIdObject, EntityObject, NormalizedError, RootState } from "../../common/types";
import { initPageResult } from "../../common/utils/apiUtils";
import messageUtils from "../../common/utils/messageUtils";
import { replaceInArray } from "../../common/utils/utils";
import { changeRunningRequestKeyAction } from "../ducks";
import { getEnumerationsActions } from "../enumerations/ducks";
import api from "./api";
import {
  Complicity,
  ComplicityFilterPageRequest,
  ComplicityFilterPageResult,
  ComplicityReducerState,
  CreateUpdateComplicity
} from "./types";

/**
 * ACTIONS
 */
export const filterComplicitiesActions = createAsyncAction(
  "complicity/FILTER_REQUEST",
  "complicity/FILTER_SUCCESS",
  "complicity/FILTER_FAILURE"
)<ComplicityFilterPageRequest, ComplicityFilterPageResult, NormalizedError>();

export const createComplicityActions = createAsyncAction(
  "complicity/CREATE_REQUEST",
  "complicity/CREATE_SUCCESS",
  "complicity/CREATE_FAILURE"
)<CreateUpdateComplicity, Complicity, NormalizedError>();

export const updateComplicityActions = createAsyncAction(
  "complicity/UPDATE_REQUEST",
  "complicity/UPDATE_SUCCESS",
  "complicity/UPDATE_FAILURE"
)<EntityObject<CreateUpdateComplicity>, Complicity, NormalizedError>();

export const deleteComplicityActions = createAsyncAction(
  "complicity/DELETE_REQUEST",
  "complicity/DELETE_SUCCESS",
  "complicity/DELETE_FAILURE"
)<EntityIdObject, void, NormalizedError>();

export const deleteStateComplicitiesPageAction = createAction("complicity/DELETE_STATE_LIST")<void>();

const actions = {
  filterComplicitiesActions,
  createComplicityActions,
  updateComplicityActions,
  deleteComplicityActions,
  deleteStateComplicitiesPageAction
};

export type ComplicityAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: ComplicityReducerState = {
  currentPage: { ...initPageResult<Complicity>(), institutionIds: [] }
};

const currentPageReducer = createReducer(initialState.currentPage)
  .handleAction(filterComplicitiesActions.success, (_, { payload }) => payload)
  .handleAction(updateComplicityActions.success, (state, { payload }) => ({
    ...state,
    pageData: replaceInArray(
      state.pageData,
      item => item.id === payload.id,
      () => payload
    )
  }))
  .handleAction([filterComplicitiesActions.failure, deleteStateComplicitiesPageAction], () => initialState.currentPage);

export const complicityReducer = combineReducers<ComplicityReducerState>({ currentPage: currentPageReducer });

/**
 * SELECTORS
 */
const selectComplicity = (state: RootState): ComplicityReducerState => state.complicity;

export const selectComplicitiesCurrentPage = (state: RootState): ComplicityFilterPageResult =>
  selectComplicity(state).currentPage;

/**
 * SAGAS
 */
function* filterComplicities({ payload }: ReturnType<typeof filterComplicitiesActions.request>) {
  try {
    const response: AxiosResponse<ComplicityFilterPageResult> = yield call(api.filterComplicities, payload);
    yield put(filterComplicitiesActions.success(response.data));
  } catch (error) {
    yield put(filterComplicitiesActions.failure(error));
  }
}

function* createComplicity({ payload }: ReturnType<typeof createComplicityActions.request>) {
  try {
    const response: AxiosResponse<Complicity> = yield call(api.createComplicity, payload);
    yield put(createComplicityActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();
    yield* refreshCurrentPage();
  } catch (error) {
    yield put(createComplicityActions.failure(error));
  }
}

function* updateComplicity({ payload }: ReturnType<typeof updateComplicityActions.request>) {
  try {
    const response: AxiosResponse<Complicity> = yield call(api.updateComplicity, payload);
    yield put(updateComplicityActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(updateComplicityActions.failure(error));
  }
}

function* deleteComplicity({ payload }: ReturnType<typeof deleteComplicityActions.request>) {
  try {
    yield call(api.deleteComplicity, payload);
    yield put(deleteComplicityActions.success());
    yield put(getEnumerationsActions.request());
    messageUtils.itemDeletedNotification();
    yield* refreshCurrentPage();
  } catch (error) {
    yield put(deleteComplicityActions.failure(error));
  }
}

function* refreshCurrentPage() {
  const currentPage: ComplicityFilterPageResult = yield select(selectComplicitiesCurrentPage);
  yield put(
    filterComplicitiesActions.request({
      pageIndex: currentPage.pageIndex,
      pageSize: currentPage.pageSize,
      institutionIds: currentPage.institutionIds
    })
  );
}

export function* complicitySaga() {
  yield takeLatest(filterComplicitiesActions.request, filterComplicities);
  yield takeLatest(createComplicityActions.request, createComplicity);
  yield takeLatest(updateComplicityActions.request, updateComplicity);
  yield takeLatest(deleteComplicityActions.request, deleteComplicity);
}
