import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { PageSizes } from "../../../../common/constants";
import ContentWrapper from "../../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../../common/types";
import { appendSearchParamsToURL, numberOrZero } from "../../../../common/utils/utils";
import { selectRouterLocationSearch } from "../../../ducks";
import CommissionsLevelForm from "../components/forms/CommissionsLevelForm";
import CommissionsLevelFilterView from "../components/views/list/CommissionsLevelFilterView";
import CommissionsLevelTableView from "../components/views/list/CommissionsLevelTableView";
import {
  createCommissionsLevelActions,
  deleteStateCommissionsLevelsPageAction,
  filterCommissionsLevelsActions,
  selectCommissionsLevelsCurrentPage
} from "../ducks";
import { CommissionsSettingsLevelType } from "../enums";
import { CommissionsSettingsLevelFilterPageRequest, CommissionsSettingsLevelFilterPageResult } from "../types";

interface StateProps {
  levelsPage: CommissionsSettingsLevelFilterPageResult;
  urlSearchQuery: string;
}

interface ActionsMap {
  filterCommissionsLevels: typeof filterCommissionsLevelsActions.request;
  createCommissionsLevel: typeof createCommissionsLevelActions.request;
  deleteStateCommissionsLevelsPage: typeof deleteStateCommissionsLevelsPageAction;
}

const CommissionsLevelListContainer = ({
  levelsPage,
  urlSearchQuery,
  actions
}: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();

  const [levelFormOpen, setLevelFormOpen] = useState<boolean>(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);
    actions.filterCommissionsLevels({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.LARGE,
      keyword: urlParams.get("keyword"),
      type: urlParams.get("type") as CommissionsSettingsLevelType
    });
    return () => {
      actions.deleteStateCommissionsLevelsPage();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterSubmit = (filter: CommissionsSettingsLevelFilterPageRequest): void => {
    navigate(appendSearchParamsToURL({ ...filter, pageIndex: null, keyword: filter.keyword || null }), {
      replace: true
    });
    actions.filterCommissionsLevels({ pageIndex: 0, pageSize: levelsPage.pageSize, ...filter });
  };

  const handleCreateClick = (): void => {
    setLevelFormOpen(true);
  };

  const handleTablePageChange = (pageNumber: number): void => {
    const { pageSize, keyword, type } = levelsPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    actions.filterCommissionsLevels({ pageIndex: pageNumber - 1, pageSize, keyword, type });
  };

  const handleFormCancel = (): void => {
    setLevelFormOpen(false);
  };

  return (
    <ContentWrapper>
      <CommissionsLevelFilterView
        currentFilter={levelsPage}
        onFilterSubmit={handleFilterSubmit}
        onCreateClick={handleCreateClick}
      />

      <CommissionsLevelTableView levelsPage={levelsPage} onPageChange={handleTablePageChange} />

      <CommissionsLevelForm
        open={levelFormOpen}
        onCreate={actions.createCommissionsLevel}
        onFormCancel={handleFormCancel}
      />
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  levelsPage: selectCommissionsLevelsCurrentPage(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterCommissionsLevels: filterCommissionsLevelsActions.request,
      createCommissionsLevel: createCommissionsLevelActions.request,
      deleteStateCommissionsLevelsPage: deleteStateCommissionsLevelsPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(CommissionsLevelListContainer);
