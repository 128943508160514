import { grey } from "@ant-design/colors";
import { Tag } from "antd";
import React from "react";
import t from "../../../app/i18n";
import { ContractStatus } from "../enums";

interface Props {
  status: ContractStatus;
  closable?: boolean;
  style?: React.CSSProperties;
  onClose?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const ContractStatusTag = ({ status, closable, style, onClose }: Props) => {
  let color: string;

  switch (status) {
    case ContractStatus.UNSTARTED:
      color = "cyan";
      break;
    case ContractStatus.ACTIVE:
      color = "green";
      break;
    case ContractStatus.CANCELED:
      color = grey[5];
      break;
    case ContractStatus.FINISHED:
      color = grey[2];
      break;
    case ContractStatus.TRANSFERRED_TO_BROKER:
      color = grey[6];
      break;
    default:
      return null;
  }

  return (
    <Tag color={color} closable={closable} style={style} onClose={onClose}>
      {t("contract.enums.status." + status)}
    </Tag>
  );
};

export default ContractStatusTag;
