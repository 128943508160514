import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { appendSearchParamsToURL } from "../../../../../common/utils/utils";
import { VEHICLE_ENUMS_TAB } from "../../../containers/VehicleEnumsContainer";
import {
  CertificateFuelType,
  CertificateFuelTypeFilterPageRequest,
  CertificateFuelTypeFilterPageResult,
  VehicleFuelTypeActions
} from "../../types";
import VehicleFuelTypeForm from "../forms/VehicleFuelTypeForm";
import VehicleFuelTypeFilterView from "./VehicleFuelTypeFilterView";
import VehicleFuelTypeTableView from "./VehicleFuelTypeTableView";

interface Props {
  fuelTypesCurrentPage: CertificateFuelTypeFilterPageResult;
  actions: VehicleFuelTypeActions;
}

const VehicleFuelTypeTabView = ({ fuelTypesCurrentPage, actions }: Props) => {
  const navigate = useNavigate();

  const [fuelTypeFormOpen, setFuelTypeFormOpen] = useState<boolean>(false);
  const [fuelTypeToUpdate, setFuelTypeToUpdate] = useState<CertificateFuelType>(null);

  const handleCreateClick = (): void => {
    setFuelTypeFormOpen(true);
  };

  const handleUpdateClick = (category: CertificateFuelType): void => {
    setFuelTypeFormOpen(true);
    setFuelTypeToUpdate(category);
  };

  const handleFormCancel = (): void => {
    setFuelTypeFormOpen(false);
    setFuelTypeToUpdate(null);
  };

  const handleFilterSubmit = (filter: CertificateFuelTypeFilterPageRequest): void => {
    navigate(
      appendSearchParamsToURL({
        pageIndex: null,
        keyword: filter.keyword || null,
        fuelTypes: filter.fuelTypes,
        tab: VEHICLE_ENUMS_TAB.FUEL_TYPES
      }),
      { replace: true }
    );

    actions.onFilter({
      pageIndex: 0,
      pageSize: fuelTypesCurrentPage.pageSize,
      keyword: filter.keyword,
      fuelTypes: filter.fuelTypes,
      fuelNameSources: filter.fuelNameSources
    });
  };

  const handlePageChange = (pageNumber: number): void => {
    const { pageSize, keyword, fuelTypes, fuelNameSources } = fuelTypesCurrentPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    actions.onFilter({ pageIndex: pageNumber - 1, pageSize, keyword, fuelTypes, fuelNameSources });
  };

  return (
    <div className="margin-top-small">
      <VehicleFuelTypeFilterView
        filter={fuelTypesCurrentPage}
        onFilterSubmit={handleFilterSubmit}
        onCreateClick={handleCreateClick}
      />

      <VehicleFuelTypeTableView
        fuelTypesCurrentPage={fuelTypesCurrentPage}
        onPageChange={handlePageChange}
        onUpdateClick={handleUpdateClick}
        onDelete={actions.onDelete}
      />

      <VehicleFuelTypeForm
        fuelType={fuelTypeToUpdate}
        open={fuelTypeFormOpen}
        onCreate={actions.onCreate}
        onUpdate={actions.onUpdate}
        onFormCancel={handleFormCancel}
      />
    </div>
  );
};

export default VehicleFuelTypeTabView;
