import React from "react";
import { Route, Routes } from "react-router-dom";
import { WILDCARD_ROUTE } from "../../common/constants";
import NotFound from "../../common/pages/NotFound/NotFound";
import { Permission } from "../../common/security/authorization/enums";
import PageWithPermission from "../../common/security/authorization/PageWithPermission";
import ContractUpdateContainer from "./containers/ContractUpdateContainer";

const routes = () => (
  <Routes>
    <Route
      index
      element={
        <PageWithPermission
          component={<ContractUpdateContainer />}
          permissions={[Permission.CLIENT_IMPORT, Permission.CONTRACT_IMPORT]}
          anyPermission
        />
      }
    />

    <Route path={WILDCARD_ROUTE} element={<NotFound />} />
  </Routes>
);

export default routes;
