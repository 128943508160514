import { Col, Divider, Popconfirm, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { RcFile } from "antd/lib/upload";
import React from "react";
import { generatePath } from "react-router-dom";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import FileTypeIconWithFilename from "../../../../common/components/icons/FileTypeIconWithFilename";
import PopconfirmDeleteIcon from "../../../../common/components/icons/PopconfirmDeleteIcon";
import ItemCreatedUpdatedInfoView from "../../../../common/components/views/ItemCreatedUpdatedInfoView";
import UploadDragger from "../../../../common/components/views/UploadDragger";
import { TableSizes } from "../../../../common/constants";
import { formatFileSize, formatLocaleDateTime } from "../../../../common/utils/formatUtils";
import { tableStandardProps } from "../../../../common/utils/utils";
import { CALC_ROUTE_PATHS } from "../../paths";
import { deleteCalcDraftAttachmentActions, uploadCalcDraftAttachmentsActions } from "../ducks";
import { CalcDraftAttachment, CalcDraftList } from "../types";

interface Props {
  calcDraft: CalcDraftList;
  onUpload: typeof uploadCalcDraftAttachmentsActions.request;
  onDelete: typeof deleteCalcDraftAttachmentActions.request;
}

const CalcDraftAttachmentsTableView = ({ calcDraft, onUpload, onDelete }: Props) => {
  const handleAttachmentUpload = (file: RcFile, fileList: RcFile[]): boolean => {
    if (fileList.length > 0) {
      const formData = new FormData();
      fileList.forEach(file => formData.append("files", file));
      fileList.splice(0, fileList.length);

      onUpload({ id: calcDraft.id, object: formData });
    }

    return false;
  };

  const columns: ColumnsType<CalcDraftAttachment> = [
    {
      key: "filename",
      title: t("calc.draft.attrs.attachmentFilename"),
      ellipsis: { showTitle: false },
      render: (_, record) => (
        <FileTypeIconWithFilename contentType={record.file.contentType} filename={record.file.filename} ellipsis />
      )
    },
    {
      key: "createdAt",
      title: t("calc.draft.attrs.attachmentCreatedAt"),
      width: 160,
      render: (_, record) => formatLocaleDateTime(record.createdAt)
    },
    {
      key: "size",
      title: t("calc.draft.attrs.attachmentSize"),
      width: 100,
      render: (_, record) => formatFileSize(record.file.size)
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <>
          <ActionTextIcon
            path={generatePath(CALC_ROUTE_PATHS.attachment.to, { id1: calcDraft.id, id2: record.id })}
            target="_blank"
            icon="export"
            color="blue"
            text={t("common.open")}
          />

          <Divider type="vertical" />

          <Popconfirm
            title={t("calc.draft.titles.deleteAttachmentConfirm")}
            icon={<PopconfirmDeleteIcon />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="danger"
            onConfirm={() => onDelete({ id1: calcDraft.id, id2: record.id })}
          >
            <span>
              <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
            </span>
          </Popconfirm>
        </>
      )
    }
  ];

  return (
    <Row>
      <Col span={22} offset={1}>
        <ItemCreatedUpdatedInfoView item={calcDraft} className="margin-bottom-small" />

        <h3 className="margin-bottom-small">{t("calc.draft.titles.attachmentsList")}</h3>

        {calcDraft.attachments && calcDraft.attachments.length > 0 ? (
          <Table<CalcDraftAttachment>
            {...tableStandardProps()}
            columns={columns}
            scroll={{ x: TableSizes.SMALL }}
            dataSource={calcDraft.attachments}
            pagination={{ pageSize: 10, size: "small", hideOnSinglePage: true }}
          />
        ) : (
          <span className="sub-header-info dashed">{t("calc.draft.helpers.noAttachments")}</span>
        )}

        <div className="margin-top-small">
          <UploadDragger multiple showUploadList={false} beforeUpload={handleAttachmentUpload} />
        </div>
      </Col>
    </Row>
  );
};

export default CalcDraftAttachmentsTableView;
