import { Button, Col, Form, Input, Row } from "antd";
import React from "react";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import { useFormErrorHandler } from "../../../../common/utils/formUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { AUTH_ROUTE_PATHS } from "../../../auth/paths";
import { requests } from "../../api";
import { requestPasswordResetActions } from "../../ducks";
import { RequestPasswordReset } from "../../types";

interface Props {
  onFormSubmit: typeof requestPasswordResetActions.request;
}

const RequestPasswordResetForm = (props: Props) => {
  const [form] = Form.useForm<RequestPasswordReset>();
  useFormErrorHandler(form, "user.attrs", [requests.USER_REQUEST_PASSWORD_RESET]);

  return (
    <>
      <h2 className="center-align margin-bottom-large">{t("user.titles.passwordReset")}</h2>

      <Row justify="center">
        <Col className="anonymous-form">
          <Form form={form} layout="vertical" name="requestPasswordResetForm" onFinish={props.onFormSubmit}>
            <Form.Item
              name="email"
              label={t("user.attrs.email")}
              rules={[validations.notBlank, validations.size(1, 254), validations.email]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button className="margin-top-medium" type="primary" htmlType="submit" block>
                {t("user.actions.resetPassword")}
              </Button>
            </Form.Item>

            <div className="center-align margin-top-medium">
              <ActionTextIcon
                color="blue"
                icon="arrow-left"
                text={t("user.actions.backToLogin")}
                path={AUTH_ROUTE_PATHS.login.to}
              />
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default RequestPasswordResetForm;
