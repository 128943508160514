import { Checkbox, Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import React, { useEffect } from "react";
import t from "../../../../app/i18n";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import ItemCreatedUpdatedInfoView from "../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import {
  resolveFormValidationError,
  selectStandardProps,
  useFormErrorHandler
} from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { InsuranceType } from "../../../contract/enums";
import InstitutionSelect from "../../../enumerations/components/form/InstitutionSelect";
import LifeInsuranceTariffGroupSelect from "../../../enumerations/components/form/LifeInsuranceTariffGroupSelect";
import ProductGroupSelect from "../../../enumerations/components/form/ProductGroupSelect";
import { requests } from "../../api";
import { createProductActions, updateProductActions } from "../../ducks";
import { ProductFinancialSector } from "../../enums";
import { CreateUpdateProduct, Product } from "../../types";
import { PRODUCT_SECTOR_TO_INSTITUTION_TYPE_MAP } from "../../utils";

interface Props {
  open: boolean;
  product?: Product;
  onCreate: typeof createProductActions.request;
  onUpdate: typeof updateProductActions.request;
  onFormCancel: () => void;
}

const ProductForm = ({ open, product, onCreate, onUpdate, onFormCancel }: Props) => {
  const [form] = Form.useForm<CreateUpdateProduct>();
  useFormErrorHandler(form, "product.attrs", [requests.CREATE_PRODUCT, requests.UPDATE_PRODUCT]);

  useEffect(() => {
    if (open && product) {
      form.setFieldsValue({
        optimisticLockVersion: product.optimisticLockVersion,
        name: product.name,
        deactivated: product.deactivated,
        sector: product.sector,
        insuranceType: product.insuranceType,
        eicContractNumberFirstDigit: product.eicContractNumberFirstDigit,
        wemContractNumberLastTwoDigits: product.wemContractNumberLastTwoDigits,
        iadOneTimeInvestmentContract: product.iadOneTimeInvestmentContract,
        productGroupId: product.productGroup.id,
        tariffGroupId: product.tariffGroup?.id,
        institutionIds: product.institutions.map(i => i.id)
      });
    }
  }, [open, product, form]);

  const inProgress = useRequestFinishedCallback([requests.CREATE_PRODUCT, requests.UPDATE_PRODUCT], onFormCancel);

  const handleSectorChange = (): void => {
    form.setFieldsValue({ institutionIds: [] });
  };

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (product) {
          onUpdate({ id: product.id, object: values });
        } else {
          onCreate(values);
        }
      })
      .catch(resolveFormValidationError);
  };

  const colSpan = 8;

  return (
    <Modal
      width={ModalSizes.LARGE}
      open={open}
      title={t(product ? "product.titles.updateProduct" : "product.titles.createProduct")}
      cancelText={t("common.cancel")}
      okText={t("common.save")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <ItemCreatedUpdatedInfoView className="margin-bottom-small" item={product} />

      <Form form={form} layout="vertical" name="productForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item
              name="name"
              label={t("product.helpers.productName")}
              rules={[validations.notBlank, validations.size(1, 1024)]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <ProductGroupSelect
              formItemProps={{
                name: "productGroupId",
                label: t("product.attrs.productGroupId"),
                rules: [validations.notNull]
              }}
              selectProps={{ disabled: !!product }}
            />
          </Col>

          <Col span={colSpan}>
            <Form.Item name="sector" label={t("product.enums.financialSector._label")} rules={[validations.notNull]}>
              <Select
                {...selectStandardProps}
                disabled={!!product}
                options={Object.keys(ProductFinancialSector)
                  .filter(sector => sector !== ProductFinancialSector.ALL)
                  .map(sector => ({
                    value: sector,
                    label: t("product.enums.financialSector." + sector)
                  }))}
                onChange={handleSectorChange}
              />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="deactivated"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
              className="form-item-without-label"
            >
              <Checkbox>{t("product.attrs.deactivated")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.sector !== next.sector || prev.insuranceType !== next.insuranceType}
        >
          {({ getFieldValue }) => {
            const sector = getFieldValue("sector");
            return (
              <>
                {sector === ProductFinancialSector.NON_LIFE_INSURANCES ||
                sector === ProductFinancialSector.LIFE_INSURANCES ? (
                  <Row gutter={rowGutter}>
                    <Col span={colSpan}>
                      <Form.Item
                        name="insuranceType"
                        label={t("contract.enums.insuranceType._label")}
                        rules={[validations.notNull]}
                      >
                        <Select
                          {...selectStandardProps}
                          disabled={product?.insuranceType === InsuranceType.LIFE}
                          options={Object.keys(InsuranceType).map(type => ({
                            value: type,
                            label: t("contract.enums.insuranceType." + type)
                          }))}
                        />
                      </Form.Item>
                    </Col>

                    {getFieldValue("insuranceType") === InsuranceType.LIFE && (
                      <Col span={colSpan}>
                        <LifeInsuranceTariffGroupSelect
                          formItemProps={{
                            name: "tariffGroupId",
                            label: t("product.attrs.tariffGroupId"),
                            rules: [validations.notNull]
                          }}
                          selectProps={{ disabled: !!product?.tariffGroup }}
                        />
                      </Col>
                    )}
                  </Row>
                ) : null}

                {sector === ProductFinancialSector.CAPITAL_MARKET && (
                  <Row gutter={rowGutter}>
                    <Col span={colSpan}>
                      <Form.Item
                        name="eicContractNumberFirstDigit"
                        label={t("product.attrs.eicContractNumberFirstDigit")}
                        rules={[validations.minNumber(1), validations.maxNumber(9)]}
                      >
                        <InputNumber />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name="wemContractNumberLastTwoDigits"
                        label={t("product.attrs.wemContractNumberLastTwoDigits")}
                        rules={[validations.size(2, 2), validations.numeric]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name="iadOneTimeInvestmentContract"
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                        className="form-item-without-label"
                      >
                        <Checkbox>{t("product.attrs.iadOneTimeInvestmentContract")}</Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <Row gutter={rowGutter}>
                  <Col span={colSpan * 3}>
                    <InstitutionSelect
                      formItemProps={{
                        name: "institutionIds",
                        label: t("product.attrs.institutionIds"),
                        rules: [validations.notNull]
                      }}
                      selectProps={{ mode: "multiple", maxTagCount: "responsive" }}
                      optionsProps={{
                        selected: product?.institutions,
                        filterType: PRODUCT_SECTOR_TO_INSTITUTION_TYPE_MAP.get(sector)
                      }}
                    />
                  </Col>
                </Row>
              </>
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProductForm;
