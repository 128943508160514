import { Col, Row, Switch, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect } from "react";
import { generatePath, Link, useNavigate } from "react-router-dom";
import t from "../../../../../app/i18n";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { PageSizes, rowGutter, TableSizes } from "../../../../../common/constants";
import { UUID } from "../../../../../common/types";
import { formatLocaleCurrencyWithNullAsZero, formatLocaleDateTime } from "../../../../../common/utils/formatUtils";
import {
  appendSearchParamsToURL,
  numberOrZero,
  paginationTableProps,
  tableStandardProps
} from "../../../../../common/utils/utils";
import NotFinishedBatchTag from "../../../batch/components/NotFinishedBatchTag";
import { CommissionsBatchStep } from "../../../batch/enums";
import { COMMISSIONS_BATCH_ROUTE_PATHS } from "../../../batch/paths";
import { removeBatchNameCustomSuffix } from "../../../batch/utils";
import { deleteStateDebtAccountMovementsPageAction, filterDebtAccountMovementsActions } from "../../ducks";
import { DebtAccountMovement, DebtAccountMovementFilterPageResult } from "../../types";

interface Props {
  agentId: UUID;
  movementsPage: DebtAccountMovementFilterPageResult;
  filterDebtAccountMovements: typeof filterDebtAccountMovementsActions.request;
  deleteStateDebtAccountMovements: typeof deleteStateDebtAccountMovementsPageAction;
  showFullBatchNameWithLink?: boolean;
  showFilterSwitch: boolean;
  urlSearchQuery: string;
}

const DebtAccountMovementTableView = ({
  agentId,
  movementsPage,
  filterDebtAccountMovements,
  deleteStateDebtAccountMovements,
  showFullBatchNameWithLink,
  showFilterSwitch,
  urlSearchQuery
}: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);
    filterDebtAccountMovements({
      id: agentId,
      object: { pageIndex: numberOrZero(urlParams.get("pageIndex")), pageSize: PageSizes.MEDIUM, onlyProcessed: true }
    });
    return () => {
      deleteStateDebtAccountMovements();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDebtAccountMovementsTablePageChange = (pageNumber: number): void => {
    const { pageSize, onlyProcessed } = movementsPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    filterDebtAccountMovements({
      id: agentId,
      object: { pageIndex: pageNumber - 1, pageSize, onlyProcessed }
    });
  };

  const handleDebtAccountMovementsOnlyProcessedChange = (onlyProcessed: boolean): void => {
    const { pageSize } = movementsPage;
    navigate(appendSearchParamsToURL({ pageIndex: null }), { replace: true });
    filterDebtAccountMovements({ id: agentId, object: { pageIndex: 0, pageSize, onlyProcessed } });
  };

  const columns: ColumnsType<DebtAccountMovement> = [
    {
      key: "commissionsBatch",
      title: t("commissions.debtAccount.attrs.commissionsBatch"),
      width: 180,
      ellipsis: { showTitle: false },
      render: (_, record) =>
        showFullBatchNameWithLink ? (
          <Ellipsis tooltip={record.commissionsBatch.name}>
            <Link
              to={generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.detail.to, { id: record.commissionsBatch.id })}
              target="_blank"
            >
              {record.commissionsBatch.name}
            </Link>
          </Ellipsis>
        ) : (
          <Ellipsis>{removeBatchNameCustomSuffix(record.commissionsBatch)}</Ellipsis>
        )
    },
    {
      key: "batchStage",
      width: 130,
      render: (_, record) => record.commissionsBatch.step !== CommissionsBatchStep.FINISH && <NotFinishedBatchTag />
    },
    {
      key: "createdAt",
      title: t("common.createdAt"),
      width: 160,
      render: (_, record) => formatLocaleDateTime(record.createdAt)
    },
    {
      key: "amount",
      title: t("common.amount"),
      align: "right",
      fixed: "right",
      width: 130,
      render: (_, record) => formatLocaleCurrencyWithNullAsZero(record.amount)
    }
  ];

  return (
    <>
      <Row gutter={rowGutter} className="margin-bottom-small">
        <Col span={12}>
          <span>
            {t("commissions.debtAccount.helpers.currentBalance")}:{" "}
            <b>{formatLocaleCurrencyWithNullAsZero(movementsPage.amountsSum)}</b>
          </span>
        </Col>

        {showFilterSwitch && (
          <Col span={12} className="right-align">
            <Switch
              size="small"
              defaultChecked={true}
              checkedChildren={<AntIcon type="check" />}
              unCheckedChildren={<AntIcon type="close" />}
              onChange={handleDebtAccountMovementsOnlyProcessedChange}
            />
            <span className="margin-left-tiny">{t("commissions.debtAccount.helpers.showOnlyProcessed")}</span>
          </Col>
        )}
      </Row>

      <Row gutter={rowGutter}>
        <Col span={24}>
          <Table<DebtAccountMovement>
            {...tableStandardProps()}
            columns={columns}
            scroll={{ x: TableSizes.SMALL }}
            dataSource={movementsPage.pageData}
            pagination={{
              ...paginationTableProps,
              position: ["bottomRight"],
              current: movementsPage.pageIndex + 1,
              pageSize: movementsPage.pageSize,
              total: movementsPage.totalElementsCount,
              onChange: handleDebtAccountMovementsTablePageChange
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default DebtAccountMovementTableView;
