import { Col, Form, Modal, Row } from "antd";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import t from "../../../../../app/i18n";
import { accessTreePathDelimiter, ModalSizes, rowGutter } from "../../../../../common/constants";
import { RootState } from "../../../../../common/types";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import AgentSelect from "../../../../enumerations/components/form/AgentSelect";
import { selectSubordinateAgentsEnums } from "../../../../enumerations/ducks";
import { AgentEnumeration } from "../../../../enumerations/types";
import { requests } from "../../api";
import { downloadCommissionsBatchAttachmentsAsZipActions } from "../../ducks";
import { CommissionsBatchAttachmentType } from "../../enums";
import { CommissionsBatch, CommissionsBatchOutputAttachment, DownloadBatchOutputAttachmentsAsZip } from "../../types";

interface Props {
  batch: CommissionsBatch;
  open: boolean;
  onDownload: typeof downloadCommissionsBatchAttachmentsAsZipActions.request;
  onFormCancel: () => void;
}

const CommissionsBatchAttachmentsAsZipForm = ({ open, batch, onDownload, onFormCancel }: Props) => {
  const agentsEnums = useSelector<RootState, AgentEnumeration[]>(selectSubordinateAgentsEnums);

  const [form] = Form.useForm<DownloadBatchOutputAttachmentsAsZip>();
  useFormErrorHandler(form, "commissions.batch.attrs.attachmentsZipDownload", [
    requests.DOWNLOAD_COMMISSIONS_BATCH_ATTACHMENTS_AS_ZIP
  ]);

  const inProgress = useRequestFinishedCallback([requests.DOWNLOAD_COMMISSIONS_BATCH_ATTACHMENTS_AS_ZIP], onFormCancel);

  const allGainersIds = useMemo(
    () =>
      (
        batch.attachments.filter(
          attachment =>
            attachment.type === CommissionsBatchAttachmentType.OUTPUT &&
            (attachment as CommissionsBatchOutputAttachment).gainer?.accessTreePath.split(accessTreePathDelimiter)
              .length > 1
        ) as CommissionsBatchOutputAttachment[]
      ).map(attachment => attachment.gainer.id),
    [batch]
  );

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        excludedGainerIds: agentsEnums
          .filter(agent => !agent.includeInCommissionsBulkPayment && allGainersIds?.includes(agent.id))
          .map(agent => agent.id)
      });
    }
  }, [open, form, agentsEnums, allGainersIds]);

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => onDownload({ id: batch.id, object: { excludedGainerIds: values.excludedGainerIds } }))
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.LARGE}
      open={open}
      title={t("commissions.batch.titles.downloadBatchAttachmentsAsZip")}
      okText={t("common.download")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="commissionsBatchAttachmentsAsZipForm">
        <Row gutter={rowGutter}>
          <Col span={24}>
            <AgentSelect
              formItemProps={{
                name: "excludedGainerIds",
                label: t("commissions.batch.attrs.attachmentsZipDownload.excludedGainerIds"),
                rules: [validations.none]
              }}
              selectProps={{
                mode: "multiple",
                maxTagCount: "responsive",
                allowClear: true
              }}
              optionsProps={{
                groupByBulkPaymentInclusion: true,
                filter: agent => allGainersIds.includes(agent.id)
              }}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CommissionsBatchAttachmentsAsZipForm;
