import { Form, Input, InputRef, Modal } from "antd";
import React, { useEffect, useRef } from "react";
import t from "../../../../app/i18n";
import { ModalSizes } from "../../../../common/constants";
import { resolveFormValidationError } from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { DocumentNodeNameFormProps, DocumentNodeTree } from "../../types";

interface Props {
  formProps: DocumentNodeNameFormProps;
  onFolderCreate: (name: string) => void;
  onNodeRename: (node: DocumentNodeTree, name: string) => void;
  onFormCancel: () => void;
}

const DocumentNodeNameForm = ({ formProps, onFolderCreate, onNodeRename, onFormCancel }: Props) => {
  const inputRef = useRef<InputRef>();
  const [form] = Form.useForm<{ name: string }>();

  useEffect(() => {
    if (inputRef && inputRef.current && formProps.open) {
      inputRef.current.focus({
        cursor: "end"
      });
    }
  }, [inputRef, formProps.open]);

  useEffect(() => {
    if (formProps.open) {
      form.setFieldsValue({ name: formProps.node?.name });
    }
  }, [formProps.open]); // eslint-disable-line react-hooks/exhaustive-deps

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_DOCUMENT_NODE, requests.UPDATE_DOCUMENT_NODE],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (formProps.type === "createFolder") {
          onFolderCreate(values.name);
        } else {
          onNodeRename(formProps.node, values.name);
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={formProps.open}
      title={t(`documentNode.titles.${formProps.type === "createFolder" ? "createFolder" : "renameNode"}`)}
      cancelText={t("common.cancel")}
      okText={t("common.save")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="documentNodeNameForm">
        <Form.Item
          name="name"
          label={t("documentNode.attrs.name")}
          rules={[validations.notBlank, validations.size(1, 255)]}
        >
          <Input ref={inputRef} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DocumentNodeNameForm;
