import { Button, Dropdown, Modal, Space } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import moment from "moment";
import { MenuInfo } from "rc-menu/lib/interface";
import React from "react";
import { useSelector } from "react-redux";
import { generatePath, Link } from "react-router-dom";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import ComponentWithPermission from "../../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../common/types";
import { serializeParams } from "../../../../../common/utils/apiUtils";
import messageUtils from "../../../../../common/utils/messageUtils";
import { hasPermission } from "../../../../../common/utils/utils";
import { selectPermissions } from "../../../../auth/ducks";
import { RealtyGen } from "../../../../calculator/calcs/realty/types";
import { TravelGen } from "../../../../calculator/calcs/travel/types";
import { CalculatorsGen, GenFinancialMediationData } from "../../../../calculator/calcs/types";
import { isRealtyGenData, isTravelGenData, isVehicleGenData } from "../../../../calculator/calcs/utils";
import { AnimalReinsuranceAmount } from "../../../../calculator/calcs/vehicle/enums";
import { VehicleGen } from "../../../../calculator/calcs/vehicle/types";
import { ClientExperience } from "../../../../calculator/enums";
import { ClientType } from "../../../../client/enums";
import { LegalClient, NaturalClient, SelfEmployedClient } from "../../../../client/types";
import { getCreateUpdateContractClientFromClient, isLegalClient } from "../../../../client/utils";
import { InstitutionEnum } from "../../../../institution/enums";
import { CONTRACT_NUMBER_QUERY_PARAM, TAB } from "../../../containers/ContractFormsContainer";
import {
  changeContractVerificationStatusActions,
  deleteContractActions,
  getContractExternalViewActions,
  loadContractToCalculatorActions
} from "../../../ducks";
import { ContractType, InsuranceType } from "../../../enums";
import { CONTRACT_ROUTE_PATHS } from "../../../paths";
import {
  Contract,
  GenericInsurance,
  InsuranceContract,
  LifeInsurance,
  RealtyInsurance,
  TravelInsurance,
  VehicleInsurance
} from "../../../types";
import { isTravelOrRealtyOrVehicleInsurance } from "../../../utils";
import ContractVerificationStatusButtonView from "./ContractVerificationStatusButtonView";

const TERMINATION_ACTION_KEY = "contract-dropdown-termination";
const DELETE_ACTION_KEY = "contract-dropdown-delete";
const LOAD_TO_CALCULATOR_ACTION_KEY = "contract-dropdown-load-calculator";

const getDefaultGenFinancialMediationData = (policyHolderType: ClientType): GenFinancialMediationData => ({
  clientExperience:
    policyHolderType === ClientType.SELF_EMPLOYED || policyHolderType === ClientType.LEGAL
      ? ClientExperience.SUFFICIENT
      : undefined,
  otherClientRequirements: undefined,
  recommendedResult: undefined,
  recommendationReason: undefined,
  additionalClientStatement: undefined,
  additionalSuitabilityStatement: undefined
});

const prepareClientsData = (clients: (NaturalClient | SelfEmployedClient | LegalClient)[]) => {
  return clients.map(client => {
    const clientData = getCreateUpdateContractClientFromClient(client);

    if (isLegalClient(client)) {
      return { ...clientData };
    }

    return {
      ...clientData,
      client: {
        ...clientData.client,
        identityCardNumber: undefined,
        previousIdentityCardNumber: client.identityCardNumber
      }
    };
  });
};

const prepareGenDataForCalculator = (
  calcData: Partial<TravelGen> | Partial<RealtyGen> | Partial<VehicleGen>,
  contract: InsuranceContract
): CalculatorsGen => {
  let resultCalcData = { ...calcData } as Partial<CalculatorsGen>;

  try {
    if (isVehicleGenData(resultCalcData)) {
      resultCalcData = {
        ...resultCalcData,
        generalData: {
          ...resultCalcData.generalData,
          effectiveBeginningDate: moment(),
          mtplInsuranceEffectiveBeginningDate: undefined,
          crossSelling: {
            csobContract: false,
            kooperativaContract: false,
            unionHealthContract: false,
            unionHealthContractNumber: undefined,
            uniqaContract: false,
            uniqaContractNumber: undefined
          }
        },
        clientsData: {
          ...resultCalcData.clientsData,
          policyHolderIndex: contract.policyHolderIndex,
          holderIndex: (contract.insurances[0] as VehicleInsurance).vehicleHolderIndex,
          ownerIndex: (contract.insurances[0] as VehicleInsurance).vehicleOwnerIndex,
          clients: [...prepareClientsData(contract.clients)],
          holderTimeWithoutAccident: undefined,
          holderAccidentsIn1Year: undefined,
          holderAccidentsIn2Years: undefined,
          holderAccidentsIn3Years: undefined,
          holderAccidentsIn4Years: undefined,
          holderAccidentsIn5Years: undefined,
          holderAccidentsIn8Years: undefined,
          holderAccidentsIn10Years: undefined,
          holderAccidentsInAllYears: undefined,
          currentlyInsuredVehicles: undefined
        },
        vehicleData: {
          ...resultCalcData.vehicleData,
          price: undefined,
          generalPrice: undefined,
          buyingPrice: undefined,
          odometer: undefined,
          newVehicle: false,
          registrationCertificateNumber: undefined,
          previousRegistrationCertificateNumber: resultCalcData.vehicleData.registrationCertificateNumber
        },
        financialMediationData: {
          ...getDefaultGenFinancialMediationData(contract.clients[contract.policyHolderIndex].type),
          recommendedGapComplicityReinsurance: false,
          recommendedGapCoverageLimit: undefined
        },
        reinsurancesData: {
          ...resultCalcData.reinsurancesData,
          animalAmount: Object.values(AnimalReinsuranceAmount).includes(resultCalcData.reinsurancesData.animalAmount)
            ? resultCalcData.reinsurancesData.animalAmount
            : undefined
        }
      };
    } else if (isRealtyGenData(resultCalcData)) {
      resultCalcData = {
        ...resultCalcData,
        generalInsuranceData: {
          ...resultCalcData.generalInsuranceData,
          effectiveBeginningDate: moment(),
          crossSelling: {
            csobContracts: undefined,
            unionHealthContract: false,
            unionHealthContractNumber: undefined,
            uniqaContractNumber: undefined,
            premiumContractNumber: undefined
          }
        },
        clientsData: {
          ...resultCalcData.clientsData,
          policyHolderIndex: contract.policyHolderIndex,
          insuredClientIndex: (contract.insurances[0] as RealtyInsurance).insuredClientIndex,
          vinculationClientIndex: (contract.insurances[0] as RealtyInsurance).vinculationClientIndex,
          clients: [...prepareClientsData(contract.clients)]
        },
        financialMediationData: {
          ...getDefaultGenFinancialMediationData(contract.clients[contract.policyHolderIndex].type),
          ownedByClient: true,
          businessDesc: undefined
        }
      };
    } else if (isTravelGenData(resultCalcData)) {
      resultCalcData = {
        ...resultCalcData,
        generalData: { ...resultCalcData.generalData, effectiveBeginningDate: moment(), effectiveEndDate: undefined },
        clientsData: {
          ...resultCalcData.clientsData,
          policyHolderIndex: contract.policyHolderIndex,
          clients: [...prepareClientsData(contract.clients)]
        },
        cancellationData: {
          ...resultCalcData.cancellationData,
          tripPrice: undefined,
          firstPaymentDate: undefined
        },
        financialMediationData: {
          ...getDefaultGenFinancialMediationData(contract.clients[contract.policyHolderIndex].type)
        },
        coveragesData: {
          ...resultCalcData.coveragesData,
          cancellationData: {
            ...resultCalcData.coveragesData?.cancellationData,
            tripPrice: undefined,
            firstPaymentDate: undefined
          }
        }
      };
    }

    return resultCalcData as CalculatorsGen;
  } catch (e) {
    messageUtils.errorNotification(t("common.error"), t("calc.helpers.loadDataError"));
    throw Error(e.toString());
  }
};

const getDropdownItems = (contract: Contract, permissions: Permission[], deletePermission: Permission) => {
  const hasDeletePermission = hasPermission(permissions, deletePermission);
  const dropdownItems: ItemType[] = [];
  if (contract.type === ContractType.INSURANCE_CONTRACT) {
    const insuranceContract = { ...contract } as InsuranceContract;
    dropdownItems.push({
      key: TERMINATION_ACTION_KEY,
      label: (
        <Link
          to={
            generatePath(CONTRACT_ROUTE_PATHS.forms.to) +
            "?" +
            serializeParams({ tab: TAB.TERMINATION, [CONTRACT_NUMBER_QUERY_PARAM]: insuranceContract.identifier })
          }
          target="_blank"
        >
          <ActionTextIcon text={t("contract.actions.terminate")} color="blue" icon="stop" />
        </Link>
      )
    });

    const insurance = insuranceContract.insurances[0] as
      | TravelInsurance
      | RealtyInsurance
      | VehicleInsurance
      | GenericInsurance
      | LifeInsurance;
    let hasLoadToCalculatorPermission = false;

    switch (insurance.type) {
      case InsuranceType.MTPL:
        hasLoadToCalculatorPermission = hasPermission(permissions, Permission.MTPL_CALCULATE);
        break;
      case InsuranceType.CRASH:
      case InsuranceType.GAP:
      case InsuranceType.PAS:
        hasLoadToCalculatorPermission = hasPermission(permissions, Permission.CRASH_CALCULATE);
        break;
      case InsuranceType.REALTY:
        hasLoadToCalculatorPermission = hasPermission(permissions, Permission.REALTY_CALCULATE);
        break;
      case InsuranceType.TRAVEL:
        hasLoadToCalculatorPermission = hasPermission(permissions, Permission.TRAVEL_CALCULATE);
        break;
    }

    if (hasLoadToCalculatorPermission && isTravelOrRealtyOrVehicleInsurance(insurance) && insurance.calcData) {
      dropdownItems.push({
        key: LOAD_TO_CALCULATOR_ACTION_KEY,
        label: <ActionTextIcon icon="select" color="green" text={t("contract.actions.loadToCalculator")} />
      });
    }
  }

  if (hasDeletePermission) {
    dropdownItems.push({
      key: DELETE_ACTION_KEY,
      label: <ActionTextIcon text={t("contract.actions.deleteContract")} color="red" icon="delete" />
    });
  }

  return dropdownItems;
};

interface Props {
  contract: Contract;
  updatePermission: Permission;
  deletePermission: Permission;
  hasVerificationStatusChangePermission: boolean;
  onVerificationStatusChange: typeof changeContractVerificationStatusActions.request;
  onExternalViewGet: typeof getContractExternalViewActions.request;
  onDelete: typeof deleteContractActions.request;
  onUpdateClick: () => void;
  onContractToCalculatorLoad?: typeof loadContractToCalculatorActions;
}

const ContractHeaderActionsView = ({
  contract,
  updatePermission,
  deletePermission,
  hasVerificationStatusChangePermission,
  onVerificationStatusChange,
  onExternalViewGet,
  onDelete,
  onUpdateClick,
  onContractToCalculatorLoad
}: Props) => {
  const permissions = useSelector<RootState, Permission[]>(selectPermissions);
  const dropdownItems = getDropdownItems(contract, permissions, deletePermission);

  const handleMenuDropdownClick = (clickInfo: MenuInfo): void => {
    switch (clickInfo.key) {
      case DELETE_ACTION_KEY:
        Modal.confirm({
          title: t("contract.helpers.deleteContractConfirm"),
          okText: t("common.yes"),
          cancelText: t("common.no"),
          onOk: () => {
            onDelete({ id: contract.id });
          }
        });
        break;
      case LOAD_TO_CALCULATOR_ACTION_KEY:
        const calcData = (
          (contract as InsuranceContract).insurances[0] as RealtyInsurance | TravelInsurance | VehicleInsurance
        ).calcData;

        if (calcData) {
          onContractToCalculatorLoad?.(prepareGenDataForCalculator(calcData, contract as InsuranceContract));
        }
    }
  };

  return (
    <Space>
      <ComponentWithPermission permissions={[updatePermission]}>
        <Button type="primary" icon={<AntIcon type="edit" />} onClick={onUpdateClick}>
          {t("contract.actions.updateContract")}
        </Button>
      </ComponentWithPermission>

      <ContractVerificationStatusButtonView
        contract={contract}
        hasChangePermission={hasVerificationStatusChangePermission}
        onStatusChange={onVerificationStatusChange}
      />

      {(contract.institution.institutionEnum === InstitutionEnum.AXA_NON_LIFE ||
        contract.institution.institutionEnum === InstitutionEnum.AXA_LIFE ||
        contract.institution.institutionEnum === InstitutionEnum.UNIQA ||
        contract.institution.institutionEnum === InstitutionEnum.UNIQA_INVEST ||
        contract.institution.institutionEnum === InstitutionEnum.UNIQA_DSS ||
        contract.institution.institutionEnum === InstitutionEnum.UNIQA_DDS) && (
        <Button icon={<AntIcon type="delivered-procedure" />} onClick={() => onExternalViewGet({ id: contract.id })}>
          {t("contract.actions.showInExternalView")}
        </Button>
      )}

      {dropdownItems.length > 0 && (
        <Dropdown
          trigger={["click"]}
          placement="bottomRight"
          menu={{
            items: dropdownItems,
            onClick: handleMenuDropdownClick
          }}
        >
          <Button icon={<AntIcon type="more" />} />
        </Dropdown>
      )}
    </Space>
  );
};

export default ContractHeaderActionsView;
