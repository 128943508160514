import { ConfigProvider } from "antd";
import sk_SK from "antd/lib/locale-provider/sk_SK";
import i18next from "i18next";
import React from "react";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { PersistGate } from "redux-persist/integration/react";
import { createApiServiceInterceptors } from "../../common/api/apiService";
import ErrorBoundary from "../../common/pages/ErrorBoundary/ErrorBoundary";
import { history, persistor, store } from "../store/configureStore";
import App from "./App";

createApiServiceInterceptors(store);

const Root = () => (
  <I18nextProvider i18n={i18next}>
    <ConfigProvider locale={sk_SK}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router history={history}>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </Router>
        </PersistGate>
      </Provider>
    </ConfigProvider>
  </I18nextProvider>
);

export default Root;
