import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import AnonymousContentWrapper from "../../../common/modules/wrappers/AnonymousContentWrapper";
import { ActionProps, RootState } from "../../../common/types";
import { UserTotpDevice } from "../../user/types";
import LoginForm from "../components/LoginForm";
import {
  loginActions,
  selectMaskedUserPhone,
  selectRememberMeToken,
  selectTotpDevices,
  sendTotpCodeViaSmsActions,
  verifyTotpCodeActions
} from "../ducks";
import { LoginBaseRequest } from "../types";

interface StateProps {
  rememberMeToken: string;
  totpDevices: UserTotpDevice[];
  maskedUserPhone: string;
}

interface ActionsMap {
  login: typeof loginActions.request;
  verifyTotpCode: typeof verifyTotpCodeActions.request;
  sendTotpCodeViaSms: typeof sendTotpCodeViaSmsActions.request;
}

type Props = StateProps & ActionProps<ActionsMap>;

const LoginContainer = (props: Props) => {
  const handleLoginFormSubmit = (request: LoginBaseRequest): void => {
    props.actions.login({ email: request.email, password: request.password, rememberMeToken: props.rememberMeToken });
  };

  return (
    <AnonymousContentWrapper>
      <LoginForm
        totpDevices={props.totpDevices}
        maskedUserPhone={props.maskedUserPhone}
        onFormSubmit={props.actions.login}
        onLoginFormSubmit={handleLoginFormSubmit}
        onVerifyTotpCode={props.actions.verifyTotpCode}
        onSendTotpCodeViaSms={props.actions.sendTotpCodeViaSms}
      />
    </AnonymousContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  rememberMeToken: selectRememberMeToken(state),
  totpDevices: selectTotpDevices(state),
  maskedUserPhone: selectMaskedUserPhone(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      login: loginActions.request,
      verifyTotpCode: verifyTotpCodeActions.request,
      sendTotpCodeViaSms: sendTotpCodeViaSmsActions.request
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
