import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { Store } from "redux";
import t, { DEFAULT_LOCALE } from "../../app/i18n";
import { logoutAction, selectIsUserAuthenticated, selectSelectedAccount, selectToken } from "../../modules/auth/ducks";
import { finishRequestAction, startRequestAction } from "../../modules/ducks";
import { HttpStatus } from "../constants";
import { ErrorResponse, FieldConstraintViolation, NormalizedError, RequestMethod } from "../types";
import { createApiRequestIdentifier, showErrorResponseNotification } from "../utils/apiUtils";
import messageUtils from "../utils/messageUtils";
import { getApiBaseUrl } from "../utils/utils";

const apiService = axios.create({
  baseURL: getApiBaseUrl(),
  headers: { "Accept-Language": DEFAULT_LOCALE }
});

export default apiService;

export const createApiServiceInterceptors = (store: Store): void => {
  apiService.interceptors.request.use(async config => {
    if (selectIsUserAuthenticated(store.getState())) {
      config.headers.Authorization = `Bearer ${selectToken(store.getState())}`;
      config.headers["Account-Id"] = selectSelectedAccount(store.getState()).accountId;
    }

    store.dispatch(startRequestAction({ requestIdentifier: parseRequestIdentifier(config) }));

    return config;
  });

  apiService.interceptors.response.use(
    response => {
      store.dispatch(finishRequestAction({ requestIdentifier: parseRequestIdentifier(response.config) }));
      return response;
    },
    async (error: AxiosError): Promise<NormalizedError> => {
      if (error.response) {
        let errorResponse: ErrorResponse;
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        const errorResponseData = error.response.data as ErrorResponse | Blob;
        if (errorResponseData instanceof Blob) {
          errorResponse = await blobToErrorResponse(errorResponseData);
        } else {
          errorResponse = errorResponseData;
        }

        showErrorResponseNotification(errorResponse);

        if (errorResponse.exceptionMessage) {
          console.error("DEBUG: server response exception message -> " + errorResponse.exceptionMessage);
        }

        store.dispatch(
          finishRequestAction({
            requestIdentifier: parseRequestIdentifier(error.config),
            status: errorResponse.status,
            violations: errorResponse.violations
          })
        );

        if (errorResponse.status === HttpStatus.UNAUTHORIZED) {
          store.dispatch(logoutAction());
        }

        return Promise.reject(normalizeError(errorResponse.status, errorResponse.message, errorResponse.violations));
      }

      store.dispatch(finishRequestAction({ requestIdentifier: parseRequestIdentifier(error.config) }));

      if (error.request) {
        // The request was made but no response was received
        messageUtils.errorNotification(t("error.serverUnreachable.title"), t("error.serverUnreachable.text"));
      } else {
        // Some other error occurred
        messageUtils.errorNotification(t("error.unexpected.title"), t("error.unexpected.text"));
      }

      return Promise.reject(normalizeError(null));
    }
  );
};

const parseRequestIdentifier = (config: AxiosRequestConfig): string => {
  return createApiRequestIdentifier(config.url.replace(config.baseURL, ""), config.method as RequestMethod);
};

const normalizeError = (
  status: number,
  message: string = null,
  violations: FieldConstraintViolation[] = null
): NormalizedError => ({
  serverResponded: !!status,
  status,
  message,
  violations
});

const blobToErrorResponse = (blob: Blob): Promise<ErrorResponse> => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(JSON.parse(reader.result as string));
    reader.readAsText(blob);
  });
};
