import imagePlaceholderLogo from "../../assets/images/image-placeholder.svg";
import bank365Logo from "../../assets/images/institutions/365bank.png";
import allianzLogo from "../../assets/images/institutions/allianz.png";
import axaLogo from "../../assets/images/institutions/axa.png";
import colonnadeLogo from "../../assets/images/institutions/colonnade.png";
import csobLogo from "../../assets/images/institutions/csob.png";
import defendLogo from "../../assets/images/institutions/defend.png";
import eicLogo from "../../assets/images/institutions/eic.png";
import generaliLogo from "../../assets/images/institutions/generali.png";
import groupamaLogo from "../../assets/images/institutions/groupama.png";
import komunalnaLogo from "../../assets/images/institutions/komunalna.png";
import kooperativaDssLogo from "../../assets/images/institutions/kooperativa-dss.png";
import kooperativaLogo from "../../assets/images/institutions/kooperativa.png";
import mBankLogo from "../../assets/images/institutions/mbank.png";
import metlifeLogo from "../../assets/images/institutions/metlife.png";
import nnLogo from "../../assets/images/institutions/nn.png";
import oberbankLogo from "../../assets/images/institutions/oberbank.png";
import premiumLogo from "../../assets/images/institutions/premium.png";
import primaLogo from "../../assets/images/institutions/prima.png";
import pssLogo from "../../assets/images/institutions/pss.png";
import slspLogo from "../../assets/images/institutions/slsp.png";
import tatraLogo from "../../assets/images/institutions/tatra.png";
import uniCreditLogo from "../../assets/images/institutions/unicredit.png";
import unionLogo from "../../assets/images/institutions/union.png";
import uniqaLogo from "../../assets/images/institutions/uniqa.png";
import vubGeneraliDssLogo from "../../assets/images/institutions/vub-generali-dss.png";
import vubLogo from "../../assets/images/institutions/vub.png";
import wustenrotLogo from "../../assets/images/institutions/wustenrot.png";
import youPlusLogo from "../../assets/images/institutions/youplus.png";
import { InstitutionEnum } from "./enums";
import { InstitutionBase } from "./types";

export const resolveInstitutionLogo = (institution: InstitutionBase): string => {
  if (institution) {
    switch (institution.institutionEnum) {
      case InstitutionEnum.ALLIANZ:
        return allianzLogo;
      case InstitutionEnum.AXA_NON_LIFE:
      case InstitutionEnum.AXA_LIFE:
        return uniqaLogo;
      case InstitutionEnum.AXA_ASSISTANCE:
        return axaLogo;
      case InstitutionEnum.COLONNADE:
        return colonnadeLogo;
      case InstitutionEnum.CSOB:
      case InstitutionEnum.CSOB_BANK:
        return csobLogo;
      case InstitutionEnum.DEFEND:
        return defendLogo;
      case InstitutionEnum.GENERALI:
        return generaliLogo;
      case InstitutionEnum.GROUPAMA:
        return groupamaLogo;
      case InstitutionEnum.KOMUNALNA:
        return komunalnaLogo;
      case InstitutionEnum.KOOPERATIVA:
        return kooperativaLogo;
      case InstitutionEnum.MET_LIFE:
      case InstitutionEnum.MET_LIFE_INSURANCE:
        return metlifeLogo;
      case InstitutionEnum.NN:
        return nnLogo;
      case InstitutionEnum.PREMIUM:
        return premiumLogo;
      case InstitutionEnum.UNION:
        return unionLogo;
      case InstitutionEnum.UNIQA:
        return uniqaLogo;
      case InstitutionEnum.WUSTENROT:
        return wustenrotLogo;
      case InstitutionEnum.YOUPLUS:
        return youPlusLogo;
      case InstitutionEnum.BANK_365:
        return bank365Logo;
      case InstitutionEnum.MBANK:
        return mBankLogo;
      case InstitutionEnum.OBERBANK:
        return oberbankLogo;
      case InstitutionEnum.PRIMA:
        return primaLogo;
      case InstitutionEnum.PSS:
        return pssLogo;
      case InstitutionEnum.SLSP:
        return slspLogo;
      case InstitutionEnum.TATRA:
        return tatraLogo;
      case InstitutionEnum.UNI_CREDIT:
        return uniCreditLogo;
      case InstitutionEnum.VUB:
        return vubLogo;
      case InstitutionEnum.EIC:
        return eicLogo;
      case InstitutionEnum.KOOPERATIVA_DSS:
        return kooperativaDssLogo;
      case InstitutionEnum.VUB_GENERALI_DSS:
        return vubGeneraliDssLogo;
      default:
        return imagePlaceholderLogo;
    }
  }
  return imagePlaceholderLogo;
};
