import React from "react";
import { Route, Routes } from "react-router-dom";
import { WILDCARD_ROUTE } from "../../common/constants";
import NotFound from "../../common/pages/NotFound/NotFound";
import { Permission } from "../../common/security/authorization/enums";
import PageWithPermission from "../../common/security/authorization/PageWithPermission";
import ContractCreateContainer from "./containers/ContractCreateContainer";
import ContractDetailContainer from "./containers/ContractDetailContainer";
import ContractFormsContainer from "./containers/ContractFormsContainer";
import ContractListContainer from "./containers/ContractListContainer";
import { CONTRACT_ROUTE_PATHS } from "./paths";

const routes = () => (
  <Routes>
    <Route
      index
      element={
        <PageWithPermission
          component={<ContractListContainer key={CONTRACT_ROUTE_PATHS.list.path} />}
          permissions={[
            Permission.INSURANCE_READ,
            Permission.LOAN_READ,
            Permission.INVESTMENT_READ,
            Permission.DEPOSIT_READ,
            Permission.SECOND_PILLAR_READ,
            Permission.THIRD_PILLAR_READ,
            Permission.GENERIC_READ
          ]}
          anyPermission
        />
      }
    />

    <Route
      path={CONTRACT_ROUTE_PATHS.predictedCommissions.path}
      element={
        <PageWithPermission
          component={<ContractListContainer key={CONTRACT_ROUTE_PATHS.predictedCommissions.path} />}
          permissions={[
            Permission.INSURANCE_READ,
            Permission.LOAN_READ,
            Permission.INVESTMENT_READ,
            Permission.DEPOSIT_READ,
            Permission.SECOND_PILLAR_READ,
            Permission.THIRD_PILLAR_READ,
            Permission.GENERIC_READ
          ]}
          anyPermission
        />
      }
    />

    <Route
      path={CONTRACT_ROUTE_PATHS.anniversaryDate.path}
      element={
        <PageWithPermission
          component={<ContractListContainer key={CONTRACT_ROUTE_PATHS.anniversaryDate.path} />}
          permissions={[Permission.INSURANCE_READ, Permission.LOAN_READ]}
          anyPermission
        />
      }
    />

    <Route
      path={CONTRACT_ROUTE_PATHS.unpaid.path}
      element={
        <PageWithPermission
          component={<ContractListContainer key={CONTRACT_ROUTE_PATHS.unpaid.path} />}
          permissions={[Permission.INSURANCE_READ]}
        />
      }
    />

    <Route
      path={CONTRACT_ROUTE_PATHS.detail.path}
      element={
        <PageWithPermission
          component={<ContractDetailContainer />}
          permissions={[
            Permission.INSURANCE_READ,
            Permission.LOAN_READ,
            Permission.INVESTMENT_READ,
            Permission.DEPOSIT_READ,
            Permission.SECOND_PILLAR_READ,
            Permission.THIRD_PILLAR_READ,
            Permission.GENERIC_READ
          ]}
          anyPermission
        />
      }
    />

    <Route
      path={CONTRACT_ROUTE_PATHS.create.path}
      element={
        <PageWithPermission
          component={<ContractCreateContainer />}
          permissions={[
            Permission.INSURANCE_CREATE,
            Permission.LOAN_CREATE,
            Permission.INVESTMENT_CREATE,
            Permission.DEPOSIT_CREATE,
            Permission.SECOND_PILLAR_CREATE,
            Permission.THIRD_PILLAR_CREATE,
            Permission.GENERIC_CREATE
          ]}
          anyPermission
        />
      }
    />

    <Route
      path={CONTRACT_ROUTE_PATHS.forms.path}
      element={
        <PageWithPermission
          component={<ContractFormsContainer />}
          permissions={[
            Permission.INSURANCE_READ,
            Permission.LOAN_READ,
            Permission.DEPOSIT_READ,
            Permission.SECOND_PILLAR_READ,
            Permission.THIRD_PILLAR_READ
          ]}
          anyPermission
        />
      }
    />

    <Route path={WILDCARD_ROUTE} element={<NotFound />} />
  </Routes>
);

export default routes;
