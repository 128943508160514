import { Card, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { generatePath } from "react-router-dom";
import t from "../../../../../../app/i18n";
import ActionTextIcon from "../../../../../../common/components/icons/ActionTextIcon";
import Ellipsis from "../../../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../../../common/constants";
import { paginationTableProps, tableStandardProps } from "../../../../../../common/utils/utils";
import { COMMISSIONS_LEVEL_ROUTE_PATHS } from "../../../paths";
import { CommissionsSettingsLevelBase, CommissionsSettingsLevelFilterPageResult } from "../../../types";
import CommissionsLevelTypeTag from "../../CommissionsLevelTypeTag";

interface Props {
  levelsPage: CommissionsSettingsLevelFilterPageResult;
  onPageChange: (pageNumber: number) => void;
}

const CommissionsLevelTableView = ({ levelsPage, onPageChange }: Props) => {
  const columns: ColumnsType<CommissionsSettingsLevelBase> = [
    {
      key: "type",
      title: t("common.type"),
      width: 110,
      render: (_, record) => <CommissionsLevelTypeTag type={record.type} />
    },
    {
      key: "category",
      title: t("commissions.level.attrs.category"),
      width: 150,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.category}</Ellipsis>
    },
    {
      key: "code",
      title: t("commissions.level.attrs.code"),
      width: 100,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.code}</Ellipsis>
    },
    {
      key: "name",
      title: t("commissions.level.attrs.name"),
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.name}</Ellipsis>
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <ActionTextIcon
          path={generatePath(COMMISSIONS_LEVEL_ROUTE_PATHS.detail.to, { id: record.id })}
          icon="eye"
          color="blue"
          text={t("common.show")}
        />
      )
    }
  ];

  return (
    <Card className="card-box">
      <Table<CommissionsSettingsLevelBase>
        {...tableStandardProps()}
        columns={columns}
        scroll={{ x: TableSizes.SMALL }}
        dataSource={levelsPage.pageData}
        pagination={{
          ...paginationTableProps,
          current: levelsPage.pageIndex + 1,
          pageSize: levelsPage.pageSize,
          total: levelsPage.totalElementsCount,
          onChange: onPageChange
        }}
      />
    </Card>
  );
};

export default CommissionsLevelTableView;
