import { Button, Card, Col, Divider, Form, Input, Row, Select, Space } from "antd";
import { FormInstance, Rule } from "antd/lib/form";
import moment from "moment";
import React, { useEffect, useState } from "react";
import t from "../../../../../app/i18n";
import ActionButton from "../../../../../common/components/buttons/ActionButton";
import ContactsEmailAutoComplete from "../../../../../common/components/form/components/ContactsEmailAutoComplete";
import ContactsPhoneNumberAutoComplete from "../../../../../common/components/form/components/ContactsPhoneNumberAutoComplete";
import LabelWithPopover from "../../../../../common/components/form/labels/LabelWithPopover";
import LabelWithTooltip from "../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../common/constants";
import MarketingConsentSelect from "../../../../../common/modules/contact/MarketingConsentSelect";
import { FieldConstraintViolation } from "../../../../../common/types";
import { formatClientName } from "../../../../../common/utils/formatUtils";
import {
  phoneNumberNormalizeFunction,
  resolveFormValidationError,
  selectStandardProps
} from "../../../../../common/utils/formUtils";
import { parseBirthDateFromPin } from "../../../../../common/utils/utils";
import { validationFunctions, validations } from "../../../../../common/utils/validationUtils";
import ClientDrawerForm from "../../../../client/components/drawers/ClientDrawerForm";
import ClientRepresentativeSelectModal from "../../../../client/components/search/ClientRepresentativeSelectModal";
import ClientSearchInput from "../../../../client/components/search/ClientSearchInput";
import {
  ClientFormStage,
  ClientRepresentativeFunction,
  ClientSearchActionType,
  ClientType
} from "../../../../client/enums";
import { Client, ClientRepresentative, LegalClient, NaturalClient } from "../../../../client/types";
import { useClientSearch } from "../../../../client/utils";
import { ProductFinancialSector } from "../../../../product/enums";
import { MediationClientFormType } from "../../../enums";
import { CreateUpdateFinancialMediation, FinancialMediationFormClients } from "../../../types";
import {
  FINANCIAL_MEDIATION_CHILDREN_SECTORS,
  FINANCIAL_MEDIATION_CLIENTS_WITH_ID_CARD,
  FINANCIAL_MEDIATION_SECONDARY_CLIENT_SECTORS
} from "../../../utils";

interface Props {
  form: FormInstance<CreateUpdateFinancialMediation>;
  formDisabled: boolean;
  sector: ProductFinancialSector;
  clients: FinancialMediationFormClients;
  clientsViolationErrors: Map<MediationClientFormType, FieldConstraintViolation[]>;
  secondaryClientActive: boolean;
  childrenCount: number;
  representative1Active: boolean;
  representative2Active: boolean;
  validateAllFields: boolean;
  onClientChange: (client: Client, type: MediationClientFormType) => void;
  onClientViolationErrorsChange: (violations: FieldConstraintViolation[], type: MediationClientFormType) => void;
  onSecondaryClientActiveToggle: () => void;
  onChildAdd: () => void;
  onChildRemove: () => void;
  onRepresentative1Toggle: () => void;
  onRepresentative2Toggle: () => void;
  onClientFormValuesChange: () => void;
}

interface ClientsStagesState {
  policyHolder: ClientFormStage;
  secondaryClient: ClientFormStage;
  policyHolderRepresentative: ClientFormStage;
  secondaryClientRepresentative: ClientFormStage;
  child1: ClientFormStage;
  child2: ClientFormStage;
  child3: ClientFormStage;
  child4: ClientFormStage;
}

interface ClientRepresentativeSelectData {
  type: MediationClientFormType.POLICY_HOLDER_REPRESENTATIVE | MediationClientFormType.SECONDARY_CLIENT_REPRESENTATIVE;
  client: LegalClient;
}

const noRepeatedClientRule =
  (checkedType: MediationClientFormType): Rule =>
  ({ getFieldsValue }) => ({
    validator: (_, value) => {
      if (value) {
        const formValues = getFieldsValue() as CreateUpdateFinancialMediation;

        return (checkedType !== MediationClientFormType.POLICY_HOLDER && value === formValues.policyHolderIdentifier) ||
          (checkedType !== MediationClientFormType.SECONDARY_CLIENT &&
            value === formValues.secondaryClientIdentifier) ||
          (checkedType !== MediationClientFormType.POLICY_HOLDER_REPRESENTATIVE &&
            value === formValues.policyHolderRepresentativeIdentifier) ||
          (checkedType !== MediationClientFormType.SECONDARY_CLIENT_REPRESENTATIVE &&
            value === formValues.secondaryClientRepresentativeIdentifier) ||
          (checkedType !== MediationClientFormType.CHILD_1 && value === formValues.child1Identifier) ||
          (checkedType !== MediationClientFormType.CHILD_2 && value === formValues.child2Identifier) ||
          (checkedType !== MediationClientFormType.CHILD_3 && value === formValues.child3Identifier) ||
          (checkedType !== MediationClientFormType.CHILD_4 && value === formValues.child4Identifier)
          ? Promise.reject(t("financialMediation.validations.noRepeatedClient"))
          : Promise.resolve();
      }

      return Promise.resolve();
    }
  });

const clientYoungerThan18Rule: Rule = {
  validator: (_, value) =>
    value && validationFunctions.validatePin(value) && moment().diff(parseBirthDateFromPin(value), "years") >= 18
      ? Promise.reject(t("financialMediation.validations.clientYoungerThan18"))
      : Promise.resolve()
};

const FinancialMediationFormClientsSection = ({ form, formDisabled, sector, clients, ...props }: Props) => {
  const clientSearch = useClientSearch();

  const [clientFormOpen, setClientFormOpen] = useState<boolean>(false);
  const [processedClientFormType, setProcessedClientFormType] = useState<MediationClientFormType>();
  const [clientStages, setClientStages] = useState<ClientsStagesState>({
    policyHolder: clients.policyHolder ? ClientFormStage.SELECTED : null,
    secondaryClient: clients.secondaryClient ? ClientFormStage.SELECTED : null,
    policyHolderRepresentative: clients.policyHolderRepresentative ? ClientFormStage.SELECTED : null,
    secondaryClientRepresentative: clients.secondaryClientRepresentative ? ClientFormStage.SELECTED : null,
    child1: clients.child1 ? ClientFormStage.SELECTED : null,
    child2: clients.child2 ? ClientFormStage.SELECTED : null,
    child3: clients.child3 ? ClientFormStage.SELECTED : null,
    child4: clients.child4 ? ClientFormStage.SELECTED : null
  });

  const [representativeSelectData, setRepresentativeSelectData] = useState<ClientRepresentativeSelectData>(null);

  useEffect(() => {
    return () => {
      clientSearch.onResultDelete();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (processedClientFormType && clientSearch.result.keyword === getClientFormIdentifier(processedClientFormType)) {
      if (clientSearch.result.data) {
        setClientFormOpen(true);
        setClientFormStage(ClientFormStage.EXISTING, processedClientFormType);
        if (
          clientSearch.result.data.type === ClientType.NATURAL ||
          clientSearch.result.data.type === ClientType.SELF_EMPLOYED
        ) {
          props.onClientChange(
            {
              ...clientSearch.result.data,
              identityCardNumber: null,
              previousIdentityCardNumber: (clientSearch.result.data as NaturalClient).identityCardNumber
            } as NaturalClient,
            processedClientFormType
          );
        } else {
          props.onClientChange(clientSearch.result.data, processedClientFormType);
        }
      } else {
        setClientFormStage(ClientFormStage.NEW, processedClientFormType);
      }
    }
  }, [clientSearch.result]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClientSearchActionClick = (type: MediationClientFormType, actionType: ClientSearchActionType): void => {
    switch (actionType) {
      case ClientSearchActionType.CREATE:
      case ClientSearchActionType.UPDATE:
        setProcessedClientFormType(type);
        setClientFormOpen(true);
        break;
      case ClientSearchActionType.DELETE:
        if (
          type === MediationClientFormType.POLICY_HOLDER ||
          type === MediationClientFormType.SECONDARY_CLIENT ||
          type === MediationClientFormType.POLICY_HOLDER_REPRESENTATIVE ||
          type === MediationClientFormType.SECONDARY_CLIENT_REPRESENTATIVE
        ) {
          const clientIdentifier = getClientFormIdentifier(type);
          const {
            child1RepresentativeIdentifier,
            child2RepresentativeIdentifier,
            child3RepresentativeIdentifier,
            child4RepresentativeIdentifier
          } = form.getFieldsValue([
            ["child1RepresentativeIdentifier"],
            ["child2RepresentativeIdentifier"],
            ["child3RepresentativeIdentifier"],
            ["child4RepresentativeIdentifier"]
          ]) as CreateUpdateFinancialMediation;

          form.setFieldsValue({
            child1RepresentativeIdentifier:
              clientIdentifier === child1RepresentativeIdentifier ? null : child1RepresentativeIdentifier,
            child2RepresentativeIdentifier:
              clientIdentifier === child2RepresentativeIdentifier ? null : child2RepresentativeIdentifier,
            child3RepresentativeIdentifier:
              clientIdentifier === child3RepresentativeIdentifier ? null : child3RepresentativeIdentifier,
            child4RepresentativeIdentifier:
              clientIdentifier === child4RepresentativeIdentifier ? null : child4RepresentativeIdentifier
          });
        }

        form.setFieldsValue({ [resolveClientFormTypeIdentifierName(type)]: null });
        setClientFormStage(null, type);
        setProcessedClientFormType(null);
        props.onClientViolationErrorsChange(null, type);
        props.onClientChange(null, type);
        break;
    }
  };

  const handleClientSearchSubmit = (value: string, type: MediationClientFormType): void => {
    form
      .validateFields([resolveClientFormTypeIdentifierName(type)])
      .then(() => clientSearch.onSearch({ keyword: value, clientType: resolveClientTypeByClientFormType(type) }))
      .catch(resolveFormValidationError);
  };

  const handleClientSearchChange = (value: string, type: MediationClientFormType): void => {
    if (getClientFormStage(type)) {
      setClientFormStage(null, type);
    }
    if (getClientFromProps(type)) {
      props.onClientChange(null, type);
    }
    if (props.clientsViolationErrors.has(type)) {
      props.onClientViolationErrorsChange(null, type);
    }

    handleClientSearchSubmit(value, type);
  };

  const handleClientFormSubmit = (client: Client, clientFormType: MediationClientFormType): void => {
    setClientFormOpen(false);
    setProcessedClientFormType(null);
    setClientFormStage(ClientFormStage.SELECTED, clientFormType);
    props.onClientViolationErrorsChange(null, clientFormType);
    props.onClientChange(client, clientFormType);
  };

  const handleClientRepresentativeSelect = (clientRepresentative: ClientRepresentative): void => {
    const representative = clientRepresentative.representative;
    const representativeType = representativeSelectData.type;

    setRepresentativeSelectData(null);
    setProcessedClientFormType(representativeType);

    setClientFormOpen(true);
    setClientFormStage(ClientFormStage.EXISTING, representativeType);

    props.onClientChange(
      {
        ...representative,
        identityCardNumber: null,
        previousIdentityCardNumber: representative.identityCardNumber
      } as NaturalClient,
      representativeType
    );

    switch (representativeType) {
      case MediationClientFormType.POLICY_HOLDER_REPRESENTATIVE:
        form.setFieldsValue({
          policyHolderRepresentativeIdentifier: representative.identifier,
          mediationData: { policyHolderData: { representativeFunction: clientRepresentative.function } }
        });
        break;
      case MediationClientFormType.SECONDARY_CLIENT_REPRESENTATIVE:
        form.setFieldsValue({
          secondaryClientRepresentativeIdentifier: representative.identifier,
          mediationData: { secondaryClientData: { representativeFunction: clientRepresentative.function } }
        });
        break;
    }
  };

  const getClientFromProps = (type: MediationClientFormType): Client => {
    switch (type) {
      case MediationClientFormType.POLICY_HOLDER:
        return clients.policyHolder;
      case MediationClientFormType.SECONDARY_CLIENT:
        return clients.secondaryClient;
      case MediationClientFormType.POLICY_HOLDER_REPRESENTATIVE:
        return clients.policyHolderRepresentative;
      case MediationClientFormType.SECONDARY_CLIENT_REPRESENTATIVE:
        return clients.secondaryClientRepresentative;
      case MediationClientFormType.CHILD_1:
        return clients.child1;
      case MediationClientFormType.CHILD_2:
        return clients.child2;
      case MediationClientFormType.CHILD_3:
        return clients.child3;
      case MediationClientFormType.CHILD_4:
        return clients.child4;
      default:
        return null;
    }
  };

  const getClientFormIdentifier = (type: MediationClientFormType): string => {
    const formIdentifier = resolveClientFormTypeIdentifierName(type);
    return formIdentifier ? form.getFieldValue(formIdentifier) : null;
  };

  const getClientFormStage = (type: MediationClientFormType): ClientFormStage => {
    switch (type) {
      case MediationClientFormType.POLICY_HOLDER:
        return clientStages.policyHolder;
      case MediationClientFormType.SECONDARY_CLIENT:
        return clientStages.secondaryClient;
      case MediationClientFormType.POLICY_HOLDER_REPRESENTATIVE:
        return clientStages.policyHolderRepresentative;
      case MediationClientFormType.SECONDARY_CLIENT_REPRESENTATIVE:
        return clientStages.secondaryClientRepresentative;
      case MediationClientFormType.CHILD_1:
        return clientStages.child1;
      case MediationClientFormType.CHILD_2:
        return clientStages.child2;
      case MediationClientFormType.CHILD_3:
        return clientStages.child3;
      case MediationClientFormType.CHILD_4:
        return clientStages.child4;
      default:
        return null;
    }
  };

  const setClientFormStage = (stage: ClientFormStage, type: MediationClientFormType): void => {
    switch (type) {
      case MediationClientFormType.POLICY_HOLDER:
        setClientStages({ ...clientStages, policyHolder: stage });
        break;
      case MediationClientFormType.SECONDARY_CLIENT:
        setClientStages({ ...clientStages, secondaryClient: stage });
        break;
      case MediationClientFormType.POLICY_HOLDER_REPRESENTATIVE:
        setClientStages({ ...clientStages, policyHolderRepresentative: stage });
        break;
      case MediationClientFormType.SECONDARY_CLIENT_REPRESENTATIVE:
        setClientStages({ ...clientStages, secondaryClientRepresentative: stage });
        break;
      case MediationClientFormType.CHILD_1:
        setClientStages({ ...clientStages, child1: stage });
        break;
      case MediationClientFormType.CHILD_2:
        setClientStages({ ...clientStages, child2: stage });
        break;
      case MediationClientFormType.CHILD_3:
        setClientStages({ ...clientStages, child3: stage });
        break;
      case MediationClientFormType.CHILD_4:
        setClientStages({ ...clientStages, child4: stage });
        break;
    }
  };

  const resolveClientFormTypeIdentifierName = (type: MediationClientFormType): string => {
    switch (type) {
      case MediationClientFormType.POLICY_HOLDER:
        return "policyHolderIdentifier";
      case MediationClientFormType.SECONDARY_CLIENT:
        return "secondaryClientIdentifier";
      case MediationClientFormType.POLICY_HOLDER_REPRESENTATIVE:
        return "policyHolderRepresentativeIdentifier";
      case MediationClientFormType.SECONDARY_CLIENT_REPRESENTATIVE:
        return "secondaryClientRepresentativeIdentifier";
      case MediationClientFormType.CHILD_1:
        return "child1Identifier";
      case MediationClientFormType.CHILD_2:
        return "child2Identifier";
      case MediationClientFormType.CHILD_3:
        return "child3Identifier";
      case MediationClientFormType.CHILD_4:
        return "child4Identifier";
      default:
        return null;
    }
  };

  const resolveClientTypeByClientFormType = (type: MediationClientFormType): ClientType => {
    switch (type) {
      case MediationClientFormType.POLICY_HOLDER:
      case MediationClientFormType.SECONDARY_CLIENT:
        return null;
      case MediationClientFormType.POLICY_HOLDER_REPRESENTATIVE:
      case MediationClientFormType.SECONDARY_CLIENT_REPRESENTATIVE:
      case MediationClientFormType.CHILD_1:
      case MediationClientFormType.CHILD_2:
      case MediationClientFormType.CHILD_3:
      case MediationClientFormType.CHILD_4:
        return ClientType.NATURAL;
      default:
        return null;
    }
  };

  const clientSearchProps = {
    processedType: processedClientFormType,
    violationErrors: props.clientsViolationErrors,
    inProgress: clientSearch.inProgress,
    disabled: formDisabled,
    inputColSpan: 5,
    clientNameColSpan: 6,
    onActionClick: handleClientSearchActionClick,
    onFocus: setProcessedClientFormType,
    onSearch: handleClientSearchSubmit,
    onChange: handleClientSearchChange
  };

  const colSpan = 4;

  return (
    <>
      <Card type="inner" className="card-box" title={t("financialMediation.sections.clients")}>
        <Row gutter={rowGutter}>
          <ClientSearchInput<MediationClientFormType>
            {...clientSearchProps}
            formItemProps={{
              name: "policyHolderIdentifier",
              label: (
                <LabelWithTooltip
                  tooltip={t("helpers.pinFormat")}
                  label={t("financialMediation.attrs.policyHolderIdentifier")}
                />
              ),
              rules: [
                validations.notBlank,
                validations.pinOrCrn,
                noRepeatedClientRule(MediationClientFormType.POLICY_HOLDER)
              ],
              validateFirst: true
            }}
            formStage={clientStages.policyHolder}
            formType={MediationClientFormType.POLICY_HOLDER}
            client={clients.policyHolder}
          />

          <Col span={props.secondaryClientActive ? colSpan - 1 : colSpan}>
            <ContactsEmailAutoComplete
              formItemProps={{
                name: ["mediationData", "policyHolderData", "email"],
                label: t("financialMediation.attrs.mediationData.policyHolderData.email"),
                rules: [validations.notBlank, validations.size(1, 254), validations.email]
              }}
              contacts={clients.policyHolder?.contacts}
            />
          </Col>

          <Col span={props.secondaryClientActive ? colSpan - 1 : colSpan}>
            <ContactsPhoneNumberAutoComplete
              formItemProps={{
                name: ["mediationData", "policyHolderData", "phone"],
                label: t("financialMediation.attrs.mediationData.policyHolderData.phone"),
                rules: [validations.notBlank, validations.size(1, 19), validations.mobilePhoneNumber],
                normalize: phoneNumberNormalizeFunction
              }}
              contacts={clients.policyHolder?.contacts}
            />
          </Col>

          <Col span={colSpan}>
            <MarketingConsentSelect
              formItemProps={{
                name: ["mediationData", "policyHolderData", "marketingConsent"],
                label: (
                  <LabelWithPopover
                    label={t("contact.enums.marketingConsent._label")}
                    popoverTitle={t("financialMediation.helpers.marketingConsentDesc.title")}
                    popoverContent={
                      <div style={{ maxWidth: "550px", maxHeight: "450px", overflowY: "scroll" }}>
                        {[1, 2, 3, 4, 5].map(value => (
                          <p key={value}>{t(`financialMediation.helpers.marketingConsentDesc.p${value}`)}</p>
                        ))}
                      </div>
                    }
                  />
                ),
                rules: [validations.notNull]
              }}
            />
          </Col>

          {props.secondaryClientActive && (
            <Col span={colSpan - 1}>
              <Form.Item
                name={["mediationData", "policyHolderData", "clientRelationship"]}
                label={t("financialMediation.attrs.mediationData.policyHolderData.clientRelationship")}
                rules={[validations.size(1, 128)]}
              >
                <Input />
              </Form.Item>
            </Col>
          )}
        </Row>

        {FINANCIAL_MEDIATION_SECONDARY_CLIENT_SECTORS.includes(sector) && (
          <>
            {props.secondaryClientActive && (
              <Row gutter={rowGutter}>
                <ClientSearchInput<MediationClientFormType>
                  {...clientSearchProps}
                  formItemProps={{
                    name: "secondaryClientIdentifier",
                    label: (
                      <LabelWithTooltip
                        tooltip={t("helpers.pinFormat")}
                        label={t("financialMediation.attrs.secondaryClientIdentifier")}
                      />
                    ),
                    rules: [
                      validations.notBlank,
                      validations.pinOrCrn,
                      noRepeatedClientRule(MediationClientFormType.SECONDARY_CLIENT)
                    ],
                    validateFirst: true
                  }}
                  formStage={clientStages.secondaryClient}
                  formType={MediationClientFormType.SECONDARY_CLIENT}
                  client={clients.secondaryClient}
                />

                <Col span={colSpan - 1}>
                  <ContactsEmailAutoComplete
                    formItemProps={{
                      name: ["mediationData", "secondaryClientData", "email"],
                      label: t("financialMediation.attrs.mediationData.secondaryClientData.email"),
                      rules: [validations.notBlank, validations.size(1, 254), validations.email]
                    }}
                    contacts={clients.secondaryClient?.contacts}
                  />
                </Col>

                <Col span={colSpan - 1}>
                  <ContactsPhoneNumberAutoComplete
                    formItemProps={{
                      name: ["mediationData", "secondaryClientData", "phone"],
                      label: t("financialMediation.attrs.mediationData.secondaryClientData.phone"),
                      rules: [validations.notBlank, validations.size(1, 19), validations.mobilePhoneNumber],
                      normalize: phoneNumberNormalizeFunction
                    }}
                    contacts={clients.secondaryClient?.contacts}
                  />
                </Col>

                <Col span={colSpan}>
                  <MarketingConsentSelect
                    formItemProps={{
                      name: ["mediationData", "secondaryClientData", "marketingConsent"],
                      label: (
                        <LabelWithPopover
                          label={t("contact.enums.marketingConsent._label")}
                          popoverTitle={t("financialMediation.helpers.marketingConsentDesc.title")}
                          popoverContent={
                            <div style={{ maxWidth: "550px", maxHeight: "450px", overflowY: "scroll" }}>
                              {[1, 2, 3, 4, 5].map(value => (
                                <p key={value}>{t(`financialMediation.helpers.marketingConsentDesc.p${value}`)}</p>
                              ))}
                            </div>
                          }
                        />
                      ),
                      rules: [validations.notNull]
                    }}
                  />
                </Col>

                <Col span={colSpan - 1}>
                  <Form.Item
                    name={["mediationData", "secondaryClientData", "clientRelationship"]}
                    label={t("financialMediation.attrs.mediationData.secondaryClientData.clientRelationship")}
                    rules={[validations.size(1, 128)]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            )}

            {!formDisabled && (
              <Row gutter={rowGutter} className="margin-bottom-medium">
                <Col span={colSpan}>
                  <Space>
                    <ActionButton
                      icon="plus"
                      label={t("financialMediation.actions.addSecondaryKlient")}
                      disabled={props.secondaryClientActive}
                      onClick={props.onSecondaryClientActiveToggle}
                    />

                    <ActionButton
                      icon="minus"
                      label={t("financialMediation.actions.removeSecondaryKlient")}
                      disabled={!props.secondaryClientActive}
                      onClick={props.onSecondaryClientActiveToggle}
                    />
                  </Space>
                </Col>
              </Row>
            )}
          </>
        )}

        {(clients.policyHolder ||
          (FINANCIAL_MEDIATION_SECONDARY_CLIENT_SECTORS.includes(sector) &&
            props.secondaryClientActive &&
            clients.secondaryClient)) &&
          (!formDisabled || clients.policyHolderRepresentative || clients.secondaryClientRepresentative) && (
            <>
              <Divider orientation="left" className="divider-subheader">
                {t("financialMediation.sections.representatives")}
              </Divider>

              {clients.policyHolder && (
                <>
                  <Row gutter={rowGutter}>
                    <Col span={colSpan * 2}>
                      {clients.policyHolder.type === ClientType.NATURAL ||
                      clients.policyHolder.type === ClientType.SELF_EMPLOYED ? (
                        <Space className="margin-bottom-tiny">
                          <b>{t("financialMediation.attrs.policyHolderRepresentativeNatural")}: </b>
                          {!formDisabled && (
                            <>
                              <ActionButton
                                icon="plus"
                                label={t("common.add")}
                                disabled={props.representative1Active}
                                onClick={props.onRepresentative1Toggle}
                              />

                              <ActionButton
                                icon="minus"
                                label={t("common.remove")}
                                disabled={!props.representative1Active}
                                onClick={props.onRepresentative1Toggle}
                              />
                            </>
                          )}
                        </Space>
                      ) : (
                        <div className="margin-bottom-tiny bold-text">
                          {t("financialMediation.attrs.policyHolderRepresentative")}
                        </div>
                      )}
                    </Col>
                  </Row>

                  {(!formDisabled || clients.policyHolderRepresentative) &&
                    (props.representative1Active || clients.policyHolder.type === ClientType.LEGAL) && (
                      <Row gutter={rowGutter}>
                        {clients.policyHolder.type === ClientType.LEGAL && (
                          <>
                            {!formDisabled && (clients.policyHolder as LegalClient).representatives?.length > 0 && (
                              <Col style={{ paddingTop: "32px" }}>
                                <Button
                                  size="small"
                                  className="secondary-button"
                                  onClick={() =>
                                    setRepresentativeSelectData({
                                      type: MediationClientFormType.POLICY_HOLDER_REPRESENTATIVE,
                                      client: clients.policyHolder as LegalClient
                                    })
                                  }
                                >
                                  {t("financialMediation.actions.selectRepresentative")}
                                </Button>
                              </Col>
                            )}

                            <Col span={colSpan}>
                              <Form.Item
                                name={["mediationData", "policyHolderData", "representativeFunction"]}
                                label={t("financialMediation.helpers.policyHolderRepresentativeFunction")}
                                rules={[validations.notNull]}
                              >
                                <Select
                                  {...selectStandardProps}
                                  options={Object.keys(ClientRepresentativeFunction).map(func => ({
                                    value: func,
                                    label: t("client.enums.representativeFunction." + func)
                                  }))}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}

                        <ClientSearchInput<MediationClientFormType>
                          {...clientSearchProps}
                          formItemProps={{
                            name: "policyHolderRepresentativeIdentifier",
                            label: (
                              <LabelWithTooltip
                                tooltip={t("helpers.pinFormat")}
                                label={t("financialMediation.attrs.policyHolderRepresentativeIdentifier")}
                              />
                            ),
                            rules: [
                              validations.notBlank,
                              validations.pin,
                              noRepeatedClientRule(MediationClientFormType.POLICY_HOLDER_REPRESENTATIVE)
                            ],
                            validateFirst: true
                          }}
                          formStage={getClientFormStage(MediationClientFormType.POLICY_HOLDER_REPRESENTATIVE)}
                          formType={MediationClientFormType.POLICY_HOLDER_REPRESENTATIVE}
                          client={getClientFromProps(MediationClientFormType.POLICY_HOLDER_REPRESENTATIVE)}
                        />
                      </Row>
                    )}
                </>
              )}

              {FINANCIAL_MEDIATION_SECONDARY_CLIENT_SECTORS.includes(sector) &&
                props.secondaryClientActive &&
                clients.secondaryClient && (
                  <div className="margin-top-small">
                    <Row gutter={rowGutter}>
                      <Col span={colSpan * 2}>
                        {clients.secondaryClient.type === ClientType.NATURAL ||
                        clients.secondaryClient.type === ClientType.SELF_EMPLOYED ? (
                          <Space className="margin-bottom-tiny">
                            <b>{t("financialMediation.attrs.secondaryClientRepresentativeNatural")}: </b>
                            {!formDisabled && (
                              <>
                                <ActionButton
                                  icon="plus"
                                  label={t("common.add")}
                                  disabled={props.representative2Active}
                                  onClick={props.onRepresentative2Toggle}
                                />

                                <ActionButton
                                  icon="minus"
                                  label={t("common.remove")}
                                  disabled={!props.representative2Active}
                                  onClick={props.onRepresentative2Toggle}
                                />
                              </>
                            )}
                          </Space>
                        ) : (
                          <div className="margin-bottom-tiny bold-text">
                            {t("financialMediation.attrs.secondaryClientRepresentative")}
                          </div>
                        )}
                      </Col>
                    </Row>

                    {(!formDisabled || clients.secondaryClientRepresentative) &&
                      (props.representative2Active || clients.secondaryClient.type === ClientType.LEGAL) && (
                        <Row gutter={rowGutter}>
                          {clients.secondaryClient.type === ClientType.LEGAL && (
                            <>
                              {!formDisabled &&
                                (clients.secondaryClient as LegalClient).representatives?.length > 0 && (
                                  <Col style={{ paddingTop: "32px" }}>
                                    <Button
                                      size="small"
                                      className="secondary-button"
                                      onClick={() =>
                                        setRepresentativeSelectData({
                                          type: MediationClientFormType.SECONDARY_CLIENT_REPRESENTATIVE,
                                          client: clients.secondaryClient as LegalClient
                                        })
                                      }
                                    >
                                      {t("financialMediation.actions.selectRepresentative")}
                                    </Button>
                                  </Col>
                                )}

                              <Col span={colSpan}>
                                <Form.Item
                                  name={["mediationData", "secondaryClientData", "representativeFunction"]}
                                  label={t("financialMediation.helpers.secondaryClientRepresentativeFunction")}
                                  rules={[validations.notNull]}
                                >
                                  <Select
                                    {...selectStandardProps}
                                    options={Object.keys(ClientRepresentativeFunction).map(func => ({
                                      value: func,
                                      label: t("client.enums.representativeFunction." + func)
                                    }))}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          )}

                          <ClientSearchInput<MediationClientFormType>
                            {...clientSearchProps}
                            formItemProps={{
                              name: "secondaryClientRepresentativeIdentifier",
                              label: (
                                <LabelWithTooltip
                                  tooltip={t("helpers.pinFormat")}
                                  label={t("financialMediation.attrs.secondaryClientRepresentativeIdentifier")}
                                />
                              ),
                              rules: [
                                validations.notBlank,
                                validations.pin,
                                noRepeatedClientRule(MediationClientFormType.SECONDARY_CLIENT_REPRESENTATIVE)
                              ],
                              validateFirst: true
                            }}
                            formStage={getClientFormStage(MediationClientFormType.SECONDARY_CLIENT_REPRESENTATIVE)}
                            formType={MediationClientFormType.SECONDARY_CLIENT_REPRESENTATIVE}
                            client={getClientFromProps(MediationClientFormType.SECONDARY_CLIENT_REPRESENTATIVE)}
                          />
                        </Row>
                      )}
                  </div>
                )}
            </>
          )}

        {FINANCIAL_MEDIATION_CHILDREN_SECTORS.includes(sector) &&
          (clients.policyHolder?.type === ClientType.NATURAL ||
            (FINANCIAL_MEDIATION_SECONDARY_CLIENT_SECTORS.includes(sector) &&
              props.secondaryClientActive &&
              clients.secondaryClient?.type === ClientType.NATURAL)) &&
          (!formDisabled || clients.child1) && (
            <>
              <Divider orientation="left" className="divider-subheader">
                {t("financialMediation.sections.children")}
              </Divider>

              {props.childrenCount > 0 && (
                <>
                  {Array.from({ length: props.childrenCount }, (_, i) => i + 1).map(i => {
                    const childType = `CHILD_${i}` as MediationClientFormType;
                    return (
                      <Row gutter={rowGutter} key={i}>
                        <ClientSearchInput<MediationClientFormType>
                          {...clientSearchProps}
                          formItemProps={{
                            name: `child${i}Identifier`,
                            label: (
                              <LabelWithTooltip
                                tooltip={t("helpers.pinFormat")}
                                label={t(`financialMediation.attrs.child${i}Identifier`)}
                              />
                            ),
                            rules: [
                              validations.notBlank,
                              validations.pin,
                              noRepeatedClientRule(childType),
                              clientYoungerThan18Rule
                            ],
                            validateFirst: true
                          }}
                          formStage={getClientFormStage(childType)}
                          formType={childType}
                          client={getClientFromProps(childType)}
                        />

                        <Col span={5}>
                          <Form.Item
                            name={`child${i}RepresentativeIdentifier`}
                            label={t(`financialMediation.attrs.child${i}RepresentativeIdentifier`)}
                            rules={[validations.notNull]}
                          >
                            <Select
                              {...selectStandardProps}
                              options={[
                                clients.policyHolder,
                                clients.secondaryClient,
                                clients.policyHolderRepresentative,
                                clients.secondaryClientRepresentative
                              ]
                                .filter(c => c?.type === ClientType.NATURAL)
                                .map(c => ({ value: c.identifier, label: formatClientName(c) }))}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={colSpan}>
                          <Form.Item
                            name={["mediationData", "childrenRelationships", i - 1]}
                            label={t("financialMediation.attrs.mediationData.childrenRelationships")}
                            rules={[validations.size(1, 128)]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                    );
                  })}
                </>
              )}

              {!formDisabled && (
                <Row gutter={rowGutter} className="margin-bottom-medium">
                  <Col span={colSpan}>
                    <Space>
                      <ActionButton
                        icon="plus"
                        label={t("common.add")}
                        disabled={props.childrenCount >= 4}
                        onClick={props.onChildAdd}
                      />

                      <ActionButton
                        icon="minus"
                        label={t("common.remove")}
                        disabled={props.childrenCount <= 0}
                        onClick={props.onChildRemove}
                      />
                    </Space>
                  </Col>
                </Row>
              )}
            </>
          )}
      </Card>

      <ClientDrawerForm<MediationClientFormType>
        open={clientFormOpen}
        client={getClientFromProps(processedClientFormType)}
        initialClientType={resolveClientTypeByClientFormType(processedClientFormType)}
        initialIdentifier={getClientFormIdentifier(processedClientFormType)}
        requireIdCardNumber={FINANCIAL_MEDIATION_CLIENTS_WITH_ID_CARD.includes(processedClientFormType)}
        formType={processedClientFormType}
        disabled={formDisabled}
        violationErrors={props.clientsViolationErrors}
        placement="mediation"
        onFormSubmit={handleClientFormSubmit}
        onFormValuesChange={props.onClientFormValuesChange}
      />

      <ClientRepresentativeSelectModal
        open={!!representativeSelectData}
        client={representativeSelectData?.client}
        onSelect={handleClientRepresentativeSelect}
        onFormCancel={() => setRepresentativeSelectData(null)}
      />
    </>
  );
};

export default FinancialMediationFormClientsSection;
