import { Space, Switch, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import LabelWithTooltip from "../../../../common/components/form/labels/LabelWithTooltip";
import AntIcon from "../../../../common/components/icons/AntIcon";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../common/constants";
import { RootState } from "../../../../common/types";
import { getActiveEnvProfile, tableStandardProps } from "../../../../common/utils/utils";
import { selectIsSystemAdmin } from "../../../auth/ducks";
import { updateNotificationSettingsActions } from "../../ducks";
import { NotificationTopicParams } from "../../enums";
import { NotificationSettings } from "../../types";

interface Props {
  settings: NotificationSettings[];
  onUpdate: typeof updateNotificationSettingsActions.request;
}

const NotificationSettingsView = ({ settings, onUpdate }: Props) => {
  const isCurrentUserSystemAdmin = useSelector<RootState, boolean>(selectIsSystemAdmin);

  const handleSubscriptionChange = (notification: NotificationSettings, subscribed: boolean): void => {
    onUpdate([
      {
        optimisticLockVersion: notification.optimisticLockVersion,
        topic: notification.topic,
        subscribed,
        sendAsEmail: !subscribed ? false : notification.sendAsEmail,
        sendAsSms: notification.sendAsSms
      }
    ]);
  };

  const handleSendAsEmailChange = (notification: NotificationSettings, sendAsEmail: boolean): void => {
    onUpdate([
      {
        optimisticLockVersion: notification.optimisticLockVersion,
        topic: notification.topic,
        subscribed: notification.subscribed,
        sendAsEmail,
        sendAsSms: notification.sendAsSms
      }
    ]);
  };

  const columns: ColumnsType<NotificationSettings> = [
    {
      key: "topic",
      title: t("notification.enums.notificationTopic._label"),
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{t("notification.enums.notificationTopic." + record.topic)}</Ellipsis>
    },
    {
      key: "subscription",
      title: (
        <LabelWithTooltip
          label={t("notification.helpers.subscriptionActive")}
          tooltip={t("notification.helpers.subscriptionActiveDesc")}
        />
      ),
      width: 155,
      render: (_, record) => (
        <Space size="small">
          <Switch
            size="small"
            checked={record.subscribed}
            disabled={NotificationTopicParams.get(record.topic).mandatory}
            checkedChildren={<AntIcon type="check" />}
            unCheckedChildren={<AntIcon type="close" />}
            onChange={checked => handleSubscriptionChange(record, checked)}
          />
          {NotificationTopicParams.get(record.topic).mandatory && (
            <LabelWithTooltip tooltip={t("notification.helpers.subscriptionDisabledDesc")} />
          )}
        </Space>
      )
    },
    {
      key: "sendAsEmail",
      title: (
        <LabelWithTooltip
          label={t("notification.helpers.sendAsEmail")}
          tooltip={t("notification.helpers.sendAsEmailDesc")}
        />
      ),
      width: 155,
      render: (_, record) => (
        <Space size="small">
          <Switch
            size="small"
            checked={record.sendAsEmail}
            disabled={
              !NotificationTopicParams.get(record.topic).emailSendable ||
              (NotificationTopicParams.get(record.topic).emailSendable && !record.subscribed) ||
              (NotificationTopicParams.get(record.topic).emailSendableMandatory &&
                !isCurrentUserSystemAdmin &&
                getActiveEnvProfile() === "prod")
            }
            checkedChildren={<AntIcon type="check" />}
            unCheckedChildren={<AntIcon type="close" />}
            onChange={checked => handleSendAsEmailChange(record, checked)}
          />
          {!NotificationTopicParams.get(record.topic).emailSendable && (
            <LabelWithTooltip tooltip={t("notification.helpers.sendAsEmailDisabledDesc")} />
          )}
          {NotificationTopicParams.get(record.topic).emailSendable && !record.subscribed && (
            <LabelWithTooltip tooltip={t("notification.helpers.sendAsEmailSubscriptionInactiveDesc")} />
          )}
          {NotificationTopicParams.get(record.topic).emailSendableMandatory &&
            !isCurrentUserSystemAdmin &&
            getActiveEnvProfile() === "prod" && (
              <LabelWithTooltip tooltip={t("notification.helpers.sendAsEmailMandatoryDesc")} />
            )}
        </Space>
      )
    }
  ];

  return (
    <Table<NotificationSettings>
      {...tableStandardProps()}
      columns={columns}
      scroll={{ x: TableSizes.SMALL }}
      dataSource={settings}
      pagination={false}
    />
  );
};

export default NotificationSettingsView;
