import { Card } from "antd";
import React from "react";
import t from "../../../../../../../app/i18n";
import HtmlView from "../../../../../../../common/components/views/HtmlView";
import {
  formatLocaleCurrency,
  formatLocaleDate,
  formatLocalePercentageNumber
} from "../../../../../../../common/utils/formatUtils";
import { tBoolean } from "../../../../../../../common/utils/translationUtils";
import { InvestmentContract } from "../../../../../types";

interface Props {
  contract: InvestmentContract;
}

const InvestmentContractDataSection = ({ contract }: Props) => (
  <Card type="inner" className="card-box margin-top-medium" title={t("contract.sections.contractData")}>
    <table className="data-table-view">
      <tbody>
        <tr>
          <td>{t("contract.attrs.mediationReportSignDate")}:</td>
          <td>{formatLocaleDate(contract.mediationReportSignDate)}</td>
          <td>{t("contract.attrs.investmentDuration")}:</td>
          <td>{contract.investmentDuration}</td>
          <td>{t("contract.attrs.feeTotalAmount")}:</td>
          <td className="right-align">{formatLocaleCurrency(contract.feeTotalAmount)}</td>
        </tr>
        <tr>
          <td>{t("contract.attrs.signDate")}:</td>
          <td>{formatLocaleDate(contract.signDate)}</td>
          <td>{t("contract.enums.investmentType._label")}:</td>
          <td>{t("contract.enums.investmentType." + contract.investmentType)}</td>
          <td>{t("contract.attrs.managementFee")}:</td>
          <td className="right-align">{formatLocalePercentageNumber(contract.managementFee)}</td>
        </tr>
        <tr>
          <td>{t("contract.attrs.effectiveEndDate")}:</td>
          <td>{formatLocaleDate(contract.effectiveEndDate)}</td>
          <td>{t("contract.enums.investmentRiskLevel._label")}:</td>
          <td>{contract.riskLevel && t("contract.enums.investmentRiskLevel." + contract.riskLevel)}</td>
          <td>{t("contract.attrs.pid")}:</td>
          <td>{contract.pid}</td>
        </tr>
        <tr>
          <td>{t("contract.attrs.cancellationDate")}:</td>
          <td>{formatLocaleDate(contract.cancellationDate)}</td>
          <td>{t("contract.enums.investmentComputedRiskLevel._label")}:</td>
          <td>
            {contract.computedRiskLevel &&
              t("contract.enums.investmentComputedRiskLevel." + contract.computedRiskLevel)}
          </td>
          <td>{t("contract.attrs.transferredFromOtherBroker")}:</td>
          <td>{tBoolean(contract.transferredFromOtherBroker)}</td>
        </tr>
        <tr>
          <td>{t("contract.attrs.monthlyPayment")}:</td>
          <td className="right-align">{formatLocaleCurrency(contract.monthlyPayment)}</td>
          <td>{t("contract.attrs.prepaidTargetAmount")}:</td>
          <td className="right-align">{formatLocaleCurrency(contract.prepaidTargetAmount)}</td>
          <td>{t("contract.attrs.transferredToOtherBroker")}:</td>
          <td>{formatLocaleDate(contract.transferredToOtherBrokerDate)}</td>
        </tr>
        <tr>
          <td>{t("contract.attrs.onetimePayment")}:</td>
          <td className="right-align">{formatLocaleCurrency(contract.onetimePayment)}</td>
          <td>{t("contract.attrs.feePaymentsCount")}:</td>
          <td className="right-align">{contract.feePaymentsCount}</td>
          <td colSpan={2} />
        </tr>
      </tbody>
    </table>

    <table className="data-table-view margin-top-tiny">
      <tbody>
        <tr>
          <td className="nowrap">{t("contract.attrs.offerLabel")}:</td>
          <td>{contract.offerLabel}</td>
        </tr>
        <tr>
          <td className="nowrap">{t("contract.attrs.productInfoLink")}:</td>
          <td>{contract.productInfoLink}</td>
        </tr>
      </tbody>
    </table>

    <table className="data-table-view margin-top-tiny">
      <tbody>
        <tr>
          <td>{t("contract.attrs.note")}:</td>
        </tr>
        <tr>
          <td>
            <HtmlView value={contract.note} />
          </td>
        </tr>
      </tbody>
    </table>
  </Card>
);

export default InvestmentContractDataSection;
