import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import {
  EntityObject,
  NormalizedError,
  PageRequest,
  PageResult,
  RootState,
  TwoLevelEntityIdObject
} from "../../common/types";
import messageUtils from "../../common/utils/messageUtils";
import { replaceInArray } from "../../common/utils/utils";
import { changeRunningRequestKeyAction } from "../ducks";
import api from "./api";
import { JobRecord, JobReducerState, JobSettings, ScheduleJobRequest, UpdateJobSettings } from "./types";

/**
 * ACTIONS
 */
export const getAllJobsActions = createAsyncAction("job/GET_ALL_REQUEST", "job/GET_ALL_SUCCESS", "job/GET_ALL_FAILURE")<
  void,
  JobSettings[],
  NormalizedError
>();

export const updateJobActions = createAsyncAction("job/UPDATE_REQUEST", "job/UPDATE_SUCCESS", "job/UPDATE_FAILURE")<
  EntityObject<UpdateJobSettings>,
  JobSettings[],
  NormalizedError
>();

export const scheduleJobActions = createAsyncAction(
  "job/SCHEDULE_REQUEST",
  "job/SCHEDULE_SUCCESS",
  "job/SCHEDULE_FAILURE"
)<EntityObject<ScheduleJobRequest>, void, NormalizedError>();

export const filterJobRecordsActions = createAsyncAction(
  "job/FILTER_JOB_RECORDS_REQUEST",
  "job/FILTER_JOB_RECORDS_SUCCESS",
  "job/FILTER_JOB_RECORDS_FAILURE"
)<EntityObject<PageRequest>, JobSettings[], NormalizedError>();

export const cancelJobActions = createAsyncAction("job/CANCEL_REQUEST", "job/CANCEL_SUCCESS", "job/CANCEL_FAILURE")<
  TwoLevelEntityIdObject,
  void,
  NormalizedError
>();

export const abortJobActions = createAsyncAction("job/ABORT_REQUEST", "job/ABORT_SUCCESS", "job/ABORT_FAILURE")<
  TwoLevelEntityIdObject,
  void,
  NormalizedError
>();

export const deleteStateJobItemsAction = createAction("job/DELETE_STATE_ITEMS")<void>();

const actions = {
  getAllJobsActions,
  updateJobActions,
  scheduleJobActions,
  filterJobRecordsActions,
  cancelJobActions,
  abortJobActions,
  deleteStateJobItemsAction
};

export type JobAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: JobReducerState = {
  items: []
};

const jobsReducer = createReducer(initialState.items)
  .handleAction(
    [getAllJobsActions.success, updateJobActions.success, filterJobRecordsActions.success],
    (_, { payload }) => payload
  )
  .handleAction([getAllJobsActions.failure, deleteStateJobItemsAction], () => initialState.items);

export const jobReducer = combineReducers<JobReducerState>({ items: jobsReducer });

/**
 * SELECTORS
 */
const selectJob = (state: RootState): JobReducerState => state.job;

export const selectJobs = (state: RootState): JobSettings[] => selectJob(state).items;

/**
 * SAGAS
 */
function* getAllJobs() {
  try {
    const response: AxiosResponse<JobSettings[]> = yield call(api.getJobs);
    yield put(getAllJobsActions.success(response.data));
  } catch (error) {
    yield put(getAllJobsActions.failure(error));
  }
}

function* updateJob({ payload }: ReturnType<typeof updateJobActions.request>) {
  try {
    const response: AxiosResponse<JobSettings> = yield call(api.updateJob, payload);
    let items: JobSettings[] = [...(yield select(selectJobs))];
    items = replaceInArray(
      items,
      item => item.id === response.data.id,
      () => response.data
    );
    yield put(updateJobActions.success(items));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(updateJobActions.failure(error));
  }
}

function* scheduleJob({ payload }: ReturnType<typeof scheduleJobActions.request>) {
  try {
    yield call(api.scheduleJob, payload);
    yield put(scheduleJobActions.success());
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();
    yield delay(2500);
    yield put(getAllJobsActions.request());
  } catch (error) {
    yield put(scheduleJobActions.failure(error));
  }
}

function* filterJobRecords({ payload }: ReturnType<typeof filterJobRecordsActions.request>) {
  try {
    const response: AxiosResponse<PageResult<JobRecord>> = yield call(api.filterJobRecords, payload);
    let items: JobSettings[] = [...(yield select(selectJobs))];
    items = replaceInArray(
      items,
      item => item.id === payload.id,
      current => ({ ...current, records: response.data })
    );
    yield put(filterJobRecordsActions.success(items));
  } catch (error) {
    yield put(filterJobRecordsActions.failure(error));
  }
}

function* cancelJob({ payload }: ReturnType<typeof cancelJobActions.request>) {
  try {
    yield call(api.cancelJob, payload);
    yield put(cancelJobActions.success());
    yield* refreshJobRecords((yield select(selectJobs)).find(job => job.id === payload.id1));
  } catch (error) {
    yield put(cancelJobActions.failure(error));
  }
}

function* abortJob({ payload }: ReturnType<typeof abortJobActions.request>) {
  try {
    yield call(api.abortJob, payload);
    yield put(abortJobActions.success());
    yield* refreshJobRecords((yield select(selectJobs)).find(job => job.id === payload.id1));
  } catch (error) {
    yield put(abortJobActions.failure(error));
  }
}

function* refreshJobRecords(job: JobSettings) {
  yield delay(2000);
  if (job.records) {
    yield put(
      filterJobRecordsActions.request({
        id: job.id,
        object: { pageIndex: job.records.pageIndex, pageSize: job.records.pageSize }
      })
    );
  } else {
    yield put(getAllJobsActions.request());
  }
}

export function* jobSaga() {
  yield takeLatest(getAllJobsActions.request, getAllJobs);
  yield takeLatest(updateJobActions.request, updateJob);
  yield takeLatest(scheduleJobActions.request, scheduleJob);
  yield takeLatest(filterJobRecordsActions.request, filterJobRecords);
  yield takeLatest(cancelJobActions.request, cancelJob);
  yield takeLatest(abortJobActions.request, abortJob);
}
