import { Col, Row } from "antd";
import React from "react";
import { generatePath, Link } from "react-router-dom";
import Ellipsis from "../../../common/components/views/Ellipsis";
import { LinkTargetType } from "../../../common/types";
import { formatAgentAggregatedName } from "../../../common/utils/formatUtils";
import { AGENT_ROUTE_PATHS } from "../paths";
import { AgentBase } from "../types";
import AgentTypeTag from "./AgentTypeTag";

interface Props {
  agent: AgentBase;
  hideIdNumber?: boolean;
  ellipsis?: boolean;
  showLink?: boolean;
  linkColor?: boolean;
  linkTarget?: LinkTargetType;
}

const AgentTypeTagWithName = ({ agent, hideIdNumber, ellipsis, showLink, linkColor, linkTarget }: Props) =>
  agent ? (
    <Row gutter={0}>
      <Col>
        <AgentTypeTag type={agent.type} />
      </Col>
      {showLink ? (
        <Col flex="1 1 0" style={{ minWidth: 0 }}>
          <Ellipsis
            tooltip={hideIdNumber ? agent.aggregatedName : formatAgentAggregatedName(agent)}
            shouldRender={!!ellipsis}
          >
            <Link
              className={linkColor ? "" : "no-link-color"}
              to={generatePath(AGENT_ROUTE_PATHS.detail.to, { id: agent.id })}
              target={linkTarget}
            >
              {hideIdNumber ? agent.aggregatedName : formatAgentAggregatedName(agent)}
            </Link>
          </Ellipsis>
        </Col>
      ) : (
        <Col flex="1 1 0" style={{ minWidth: 0 }}>
          <Ellipsis shouldRender={!!ellipsis}>
            {hideIdNumber ? agent.aggregatedName : formatAgentAggregatedName(agent)}
          </Ellipsis>
        </Col>
      )}
    </Row>
  ) : null;

export default AgentTypeTagWithName;
