import { Card } from "antd";
import React from "react";
import t from "../../../../../../../app/i18n";
import HtmlView from "../../../../../../../common/components/views/HtmlView";
import { formatLocaleCurrency, formatLocaleDate } from "../../../../../../../common/utils/formatUtils";
import { tBoolean } from "../../../../../../../common/utils/translationUtils";
import { ThirdPillarContract } from "../../../../../types";

interface Props {
  contract: ThirdPillarContract;
}

const ThirdPillarContractDataSection = ({ contract }: Props) => (
  <Card type="inner" className="card-box margin-top-medium" title={t("contract.sections.contractData")}>
    <table className="data-table-view">
      <tbody>
        <tr>
          <td>{t("contract.attrs.mediationReportSignDate")}:</td>
          <td>{formatLocaleDate(contract.mediationReportSignDate)}</td>
          <td>{t("contract.attrs.annualPremium")}:</td>
          <td className="right-align">{formatLocaleCurrency(contract.annualPremium)}</td>
          <td>{t("contract.attrs.transferredFromOtherBroker")}:</td>
          <td>{tBoolean(contract.transferredFromOtherBroker)}</td>
        </tr>
        <tr>
          <td>{t("contract.attrs.signDate")}:</td>
          <td>{formatLocaleDate(contract.signDate)}</td>
          <td>{t("contract.attrs.basePremium")}:</td>
          <td className="right-align">{formatLocaleCurrency(contract.basePremium)}</td>
          <td>{t("contract.attrs.transferredToOtherBroker")}:</td>
          <td>{formatLocaleDate(contract.transferredToOtherBrokerDate)}</td>
        </tr>
        <tr>
          <td>{t("contract.attrs.cancellationDate")}:</td>
          <td>{formatLocaleDate(contract.cancellationDate)}</td>
          <td colSpan={2} />
          <td colSpan={2} />
        </tr>
      </tbody>
    </table>

    <table className="data-table-view margin-top-tiny">
      <tbody>
        <tr>
          <td>{t("contract.attrs.note")}:</td>
        </tr>
        <tr>
          <td>
            <HtmlView value={contract.note} />
          </td>
        </tr>
      </tbody>
    </table>
  </Card>
);

export default ThirdPillarContractDataSection;
