import { Card, Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../common/components/icons/PopconfirmDeleteIcon";
import DeactivatedTag from "../../../../common/components/tags/DeactivatedTag";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../common/constants";
import { formatLocaleCurrencyWithMillionLabel } from "../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../common/utils/utils";
import { deleteCoverageLimitActions } from "../../ducks";
import { CoverageLimit, CoverageLimitFilterPageResult } from "../../types";

interface Props {
  limitsPage: CoverageLimitFilterPageResult;
  onPageChange: (pageNumber: number) => void;
  onUpdateClick: (limit: CoverageLimit) => void;
  onDelete: typeof deleteCoverageLimitActions.request;
}

const CoverageLimitTableView = ({ limitsPage, onPageChange, onUpdateClick, onDelete }: Props) => {
  const columns: ColumnsType<CoverageLimit> = [
    {
      key: "institution",
      title: t("common.insuranceInstitution"),
      width: 150,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.institution.name}</Ellipsis>
    },
    {
      key: "health",
      title: t("coverageLimit.attrs.health"),
      width: 115,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{formatLocaleCurrencyWithMillionLabel(record.health)}</Ellipsis>
    },
    {
      key: "property",
      title: t("coverageLimit.attrs.property"),
      width: 115,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{formatLocaleCurrencyWithMillionLabel(record.property)}</Ellipsis>
    },
    {
      key: "coverageLimitEnum",
      title: t("coverageLimit.enums.coverageLimitEnum._label"),
      width: 240,
      ellipsis: { showTitle: false },
      render: (_, record) =>
        record.coverageLimitEnum ? (
          <Ellipsis>{t("coverageLimit.enums.coverageLimitEnum." + record.coverageLimitEnum)}</Ellipsis>
        ) : null
    },
    {
      key: "deactivated",
      align: "center",
      width: 100,
      render: (_, record) => (record.deactivated ? <DeactivatedTag style={{ marginRight: 0 }} /> : null)
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <>
          <ActionTextIcon icon="edit" color="blue" text={t("common.edit")} onClick={() => onUpdateClick(record)} />

          <Divider type="vertical" />

          <Popconfirm
            title={t("coverageLimit.helpers.deleteConfirm")}
            icon={<PopconfirmDeleteIcon />}
            cancelText={t("common.no")}
            okText={t("common.yes")}
            okType="danger"
            onConfirm={() => onDelete({ id: record.id })}
          >
            <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
          </Popconfirm>
        </>
      )
    }
  ];

  return (
    <Card className="card-box">
      <Table<CoverageLimit>
        {...tableStandardProps()}
        columns={columns}
        dataSource={limitsPage.pageData}
        scroll={{ x: TableSizes.MEDIUM }}
        pagination={{
          ...paginationTableProps,
          current: limitsPage.pageIndex + 1,
          pageSize: limitsPage.pageSize,
          total: limitsPage.totalElementsCount,
          onChange: onPageChange
        }}
      />
    </Card>
  );
};

export default CoverageLimitTableView;
