import { green, red } from "@ant-design/colors";
import { Col, Divider, Row, Statistic } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import t from "../../../../../app/i18n";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { RootState } from "../../../../../common/types";
import {
  formatCoverageLimitValues,
  formatLocaleCurrency,
  formatLocaleDate,
  formatPhoneNumber
} from "../../../../../common/utils/formatUtils";
import { licensePlateNormalizeFunction } from "../../../../../common/utils/formUtils";
import { ClientsListDrawerView, createClientsData } from "../../../../client/components/drawers/ClientsListDrawerView";
import { ClientFormType, ClientType } from "../../../../client/enums";
import { Client, NaturalClient, SelfEmployedClient } from "../../../../client/types";
import { PaymentFrequency } from "../../../../contract/enums";
import { parseVehicleModelName } from "../../../../contract/utils";
import { selectVehicleBrandsEnums } from "../../../../enumerations/ducks";
import { VehicleBrandWithModels } from "../../../../enumerations/types";
import { InstitutionEnum } from "../../../../institution/enums";
import { CalcType } from "../../../enums";
import { CalcResult } from "../../types";
import { DEFEND_D2C_COVERAGE } from "../../utils";
import { GapCoverageLimit, GapDuration, VehicleOwnerRelation, VehiclePolicyHolderRelation } from "../../vehicle/enums";
import {
  CrashCalcResultData,
  GapCalcResultData,
  MtplCalcResultData,
  MtplCrashCalcResultData,
  PasCalcResultData,
  VehicleCalc,
  VehicleCalcResultData,
  VehicleCalcVehicleData,
  VehicleFormClients,
  VehicleGenForm
} from "../../vehicle/types";

const SPAN_LABEL = 5;
const SPAN_VALUE = 19;

const getOwnerClient = (ownerRelation: VehicleOwnerRelation, clients: VehicleFormClients): Client => {
  if (ownerRelation === VehicleOwnerRelation.SAME_AS_VEHICLE_HOLDER) {
    return clients.holder;
  } else if (ownerRelation === VehicleOwnerRelation.SAME_AS_POLICY_HOLDER) {
    return clients.policyHolder;
  } else {
    return clients.owner;
  }
};

const renderVehicleInsuranceData = (
  selectedInsurance: CalcResult<VehicleCalcResultData>,
  gapData: { gapDuration?: GapDuration; gapComplicityReinsurance?: boolean; gapCoverageLimit?: GapCoverageLimit }
): React.JSX.Element | undefined => {
  switch (selectedInsurance.calcType) {
    case CalcType.MTPL:
      const mtplInsurance = { ...selectedInsurance } as CalcResult<MtplCalcResultData>;
      return (
        <>
          <Row>
            <Col span={SPAN_LABEL}>{t("calc.vehicle.results.coverageLimit")}</Col>
            <Col span={SPAN_VALUE}>
              {formatCoverageLimitValues(
                mtplInsurance.data.healthCoverageLimit,
                mtplInsurance.data.propertyCoverageLimit
              )}
            </Col>
          </Row>
          <Row>
            {mtplInsurance.data.appliedCoverages.map(coverage => {
              return (
                <React.Fragment key={coverage.type}>
                  <Col span={SPAN_LABEL}>{t("contract.enums.insuranceCoverageType." + coverage.type)}</Col>
                  <Col span={SPAN_VALUE}>
                    <AntIcon type="check-circle-two-tone" twoToneColor={green[5]} />
                  </Col>
                </React.Fragment>
              );
            })}
            {mtplInsurance.data.missingCoverages.map(coverage => {
              return (
                <React.Fragment key={coverage}>
                  <Col span={SPAN_LABEL}>{t("contract.enums.insuranceCoverageType." + coverage)}</Col>
                  <Col span={SPAN_VALUE}>
                    <AntIcon type="close-circle-two-tone" twoToneColor={red[5]} />
                  </Col>
                </React.Fragment>
              );
            })}
          </Row>
        </>
      );
    case CalcType.GAP:
      return (
        <>
          {gapData.gapDuration ? (
            <Row>
              <Col span={SPAN_LABEL}>{t("calc.vehicle.enums.gapDuration._label")}</Col>
              <Col span={SPAN_VALUE}>{t("calc.vehicle.enums.gapDuration." + gapData.gapDuration)}</Col>
            </Row>
          ) : undefined}

          {selectedInsurance.insuranceInstitution.institutionEnum === InstitutionEnum.DEFEND ? (
            <Row>
              <Col span={SPAN_LABEL}>{t("calc.vehicle.results.coverageLimit")}</Col>
              <Col span={SPAN_VALUE}>{t("calc.vehicle.enums.gapCoverageLimit." + gapData.gapCoverageLimit)}</Col>
            </Row>
          ) : undefined}

          {selectedInsurance.coverage !== DEFEND_D2C_COVERAGE ? (
            <Row>
              <Col span={SPAN_LABEL}>{t("calc.vehicle.attrs.generalData.gapComplicityReinsurance")}</Col>
              <Col span={SPAN_VALUE}>
                {gapData.gapComplicityReinsurance ? (
                  <AntIcon type="check-circle-two-tone" twoToneColor={green[5]} />
                ) : (
                  <AntIcon type="close-circle-two-tone" twoToneColor={red[5]} />
                )}
              </Col>
            </Row>
          ) : undefined}
        </>
      );
    case CalcType.PAS:
      const pasInsurance = { ...selectedInsurance } as CalcResult<PasCalcResultData>;
      return (
        <>
          <Row>
            {pasInsurance.data.coverages.map((coverage, index) => {
              return (
                <React.Fragment key={index}>
                  <Col span={SPAN_LABEL}>{coverage.name}</Col>
                  <Col span={SPAN_VALUE}>{coverage.limit}</Col>
                </React.Fragment>
              );
            })}
          </Row>
        </>
      );
    case CalcType.CRASH:
      const crashInsurance = { ...selectedInsurance } as CalcResult<CrashCalcResultData>;
      return (
        <>
          <Row>
            <Col span={SPAN_LABEL}>{t("calc.vehicle.results.complicity")}</Col>
            <Col span={SPAN_VALUE}>{crashInsurance.data.complicity}</Col>
          </Row>
          <Row>
            {crashInsurance.data.appliedCoverages.map(coverage => {
              return (
                <React.Fragment key={coverage.type}>
                  <Col span={SPAN_LABEL}>{t("contract.enums.insuranceCoverageType." + coverage.type)}</Col>
                  <Col span={SPAN_VALUE}>
                    <AntIcon type="check-circle-two-tone" twoToneColor={green[5]} />
                  </Col>
                </React.Fragment>
              );
            })}
            {crashInsurance.data.missingCoverages.map(coverage => {
              return (
                <React.Fragment key={coverage}>
                  <Col span={SPAN_LABEL}>{t("contract.enums.insuranceCoverageType." + coverage)}</Col>
                  <Col span={SPAN_VALUE}>
                    <AntIcon type="close-circle-two-tone" twoToneColor={red[5]} />
                  </Col>
                </React.Fragment>
              );
            })}
          </Row>
        </>
      );
    case CalcType.MTPL_CRASH:
      const mtplCrashInsurance = { ...selectedInsurance } as CalcResult<MtplCrashCalcResultData>;
      return (
        <>
          <Row>
            <Col span={SPAN_LABEL}>{t("calc.vehicle.results.coverageLimit")}</Col>
            <Col span={SPAN_VALUE}>
              {formatCoverageLimitValues(
                mtplCrashInsurance.data.healthCoverageLimit,
                mtplCrashInsurance.data.propertyCoverageLimit
              )}
            </Col>
          </Row>
          <Row>
            <Col span={SPAN_LABEL}>{t("calc.vehicle.results.complicity")}</Col>
            <Col span={SPAN_VALUE}>{mtplCrashInsurance.data.complicity}</Col>
          </Row>
          <Row>
            {mtplCrashInsurance.data.appliedCoverages.map(coverage => {
              return (
                <React.Fragment key={coverage.type}>
                  <Col span={SPAN_LABEL}>{t("contract.enums.insuranceCoverageType." + coverage.type)}</Col>
                  <Col span={SPAN_VALUE}>
                    <AntIcon type="check-circle-two-tone" twoToneColor={green[5]} />
                  </Col>
                </React.Fragment>
              );
            })}
            {mtplCrashInsurance.data.missingCoverages.map(coverage => {
              return (
                <React.Fragment key={coverage}>
                  <Col span={SPAN_LABEL}>{t("contract.enums.insuranceCoverageType." + coverage)}</Col>
                  <Col span={SPAN_VALUE}>
                    <AntIcon type="close-circle-two-tone" twoToneColor={red[5]} />
                  </Col>
                </React.Fragment>
              );
            })}
          </Row>
        </>
      );
    default:
      return undefined;
  }
};

const renderVehicleData = (vehicleData: VehicleCalcVehicleData, vehicleBrand: VehicleBrandWithModels) => {
  return (
    <>
      <Row>
        <Col span={SPAN_LABEL}>{t("calc.vehicle.helpers.vehicleBrandAndModel")}:</Col>
        <Col span={SPAN_VALUE}>
          {parseVehicleModelName({
            customModel: vehicleData.customModel,
            customBrand: vehicleData.customBrand,
            brandName: vehicleBrand.name,
            modelName: vehicleBrand.models.find(model => model.id === vehicleData.modelId).name
          })}
        </Col>
      </Row>
      <Row>
        <Col span={SPAN_LABEL}>{t("calc.vehicle.attrs.vehicleData.licensePlate")}</Col>
        <Col span={SPAN_VALUE}>{licensePlateNormalizeFunction(vehicleData.licensePlate)}</Col>
      </Row>
      <Row>
        <Col span={SPAN_LABEL}>{t("calc.vehicle.attrs.vehicleData.vin")}</Col>
        <Col span={SPAN_VALUE}>{vehicleData.vin}</Col>
      </Row>
    </>
  );
};

export type VehicleCalcSummaryViewData = {
  calcData: VehicleCalc;
  clients: VehicleFormClients;
  formData: VehicleGenForm;
  selectedInsurance: CalcResult<VehicleCalcResultData>;
};

type Props = {
  data: VehicleCalcSummaryViewData;
};

const VehicleCalcSummaryView = ({ data: { selectedInsurance, formData, calcData, clients } }: Props) => {
  const holderIdentityCard =
    clients.holder.type === ClientType.NATURAL || clients.holder.type === ClientType.SELF_EMPLOYED
      ? formData.clientsData.holderIdentityCardNumber
      : undefined;

  const holderClient = holderIdentityCard
    ? { ...(clients.holder as NaturalClient | SelfEmployedClient), identityCardNumber: holderIdentityCard }
    : clients.holder;

  const policyHolderClient =
    calcData.clientsData.policyHolderRelation === VehiclePolicyHolderRelation.SAME_AS_VEHICLE_HOLDER
      ? holderClient
      : clients.policyHolder;

  const ownerClient = getOwnerClient(calcData.clientsData.ownerRelation, {
    ...clients,
    holder: holderClient,
    policyHolder: policyHolderClient
  });

  const vehicleBrandsEnums = useSelector<RootState, VehicleBrandWithModels[]>(selectVehicleBrandsEnums);
  const vehicleBrand = vehicleBrandsEnums.find(brand => brand.id === calcData.vehicleData.brandId);

  const paymentFrequency =
    selectedInsurance.calcType === CalcType.GAP
      ? (selectedInsurance as CalcResult<GapCalcResultData>).data.options.find(
          option =>
            option.complicityReinsurance === formData.generalData.gapComplicityReinsurance &&
            option.paymentFrequency === formData.generalData.gapPaymentFrequency
        ).paymentFrequency
      : calcData.generalData.paymentFrequency;

  return (
    <div>
      <Row>
        <Col flex={1}>
          <Statistic
            className="statistic-small"
            title={t("common.insuranceInstitution")}
            value={selectedInsurance.insuranceInstitution.name}
          />
        </Col>

        {selectedInsurance.coverage ? (
          <Col flex={1}>
            <Statistic
              className="statistic-small"
              title={<span style={{ textTransform: "capitalize" }}>{t("calc.vehicle.sections.coverage")}</span>}
              value={selectedInsurance.coverage}
            />
          </Col>
        ) : undefined}

        <Col flex={4}>
          <Statistic
            className="statistic-small"
            title={t("calc.helpers.summaryData.product")}
            value={t("calc.enums.calcType." + selectedInsurance.calcType).toUpperCase()}
          />
        </Col>
      </Row>

      <Row className="margin-top-small">
        <Col flex={1}>
          <Divider orientation="left">{t("calc.vehicle.attrs.clientsData._label")}</Divider>
          <ClientsListDrawerView
            clientsData={createClientsData([
              {
                client: policyHolderClient,
                type: ClientFormType.POLICY_HOLDER
              },
              {
                client: holderClient,
                type: ClientFormType.HOLDER
              },
              {
                client: ownerClient,
                type: ClientFormType.OWNER
              }
            ])}
          />
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          <Row>
            <Col span={SPAN_LABEL}>{t("calc.vehicle.attrs.clientsData.policyHolderPhone")}</Col>
            <Col span={SPAN_VALUE}>{formatPhoneNumber(formData.clientsData.policyHolderPhone)}</Col>
          </Row>
          <Row>
            <Col span={SPAN_LABEL}>{t("calc.vehicle.attrs.clientsData.policyHolderEmail")}</Col>
            <Col span={SPAN_VALUE}>{formData.clientsData.policyHolderEmail}</Col>
          </Row>
        </Col>
      </Row>

      <Row className="margin-top-small">
        <Col flex={1}>
          <Divider orientation="left">{t("contract.attrs.insurances.vehicle._label")}</Divider>
          {renderVehicleData(calcData.vehicleData, vehicleBrand)}
        </Col>
      </Row>

      <Row className="margin-top-small">
        <Col flex={1}>
          <Divider orientation="left">{t("calc.helpers.summaryData.insuranceAndCoverages")}</Divider>
          {renderVehicleInsuranceData(selectedInsurance, {
            gapDuration: calcData.generalData.gapDuration,
            gapComplicityReinsurance: formData.generalData?.gapComplicityReinsurance,
            gapCoverageLimit: calcData.generalData.gapCoverageLimit
          })}
        </Col>
      </Row>

      <Row className="margin-top-small">
        <Col flex={1}>
          <Divider orientation="left">{t("calc.vehicle.results.additionalInfo")}</Divider>
          <Row>
            <Col span={SPAN_LABEL}>{t("calc.vehicle.attrs.generalData.effectiveBeginningDate")}</Col>
            <Col span={SPAN_VALUE}>{formatLocaleDate(calcData.generalData.effectiveBeginningDate)}</Col>
          </Row>
          <Row>
            <Col span={SPAN_LABEL}>
              {formData.generalData?.gapPaymentFrequency === PaymentFrequency.ONCE
                ? t("calc.vehicle.results.annualPremium")
                : t("contract.attrs.annualPremium")}
            </Col>
            <Col span={SPAN_VALUE}>
              {selectedInsurance.calcType === CalcType.GAP
                ? formatLocaleCurrency(
                    (selectedInsurance as CalcResult<GapCalcResultData>).data.options.find(
                      option =>
                        option.complicityReinsurance === formData.generalData.gapComplicityReinsurance &&
                        option.paymentFrequency === formData.generalData.gapPaymentFrequency
                    ).annualPremium
                  )
                : formatLocaleCurrency(selectedInsurance.data.annualPremium)}
            </Col>
          </Row>

          <Row>
            <Col span={SPAN_LABEL}>{t("contract.enums.paymentFrequency._label")}</Col>
            <Col span={SPAN_VALUE}>{t("contract.enums.paymentFrequency." + paymentFrequency)}</Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default VehicleCalcSummaryView;
