import React from "react";
import AntIcon from "./AntIcon";

interface Props {
  index?: number;
  onClick?(index?: number): void;
}

const DeleteIcon = (props: Props) => (
  <AntIcon type="minus-circle" className="delete-icon" onClick={() => props.onClick?.(props.index)} />
);

export default DeleteIcon;
