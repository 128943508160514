import { Tag } from "antd";
import React from "react";
import t from "../../../app/i18n";
import { InstitutionType } from "../enums";

interface Props {
  type: InstitutionType;
  closable?: boolean;
  style?: React.CSSProperties;
  onClose?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const InstitutionTypeTag = ({ type, closable, style, onClose }: Props) => {
  let color: string;

  switch (type) {
    case InstitutionType.INSURANCE_COMPANY:
      color = "blue";
      break;
    case InstitutionType.BANK:
      color = "green";
      break;
    case InstitutionType.SECURITIES_BROKER:
      color = "purple";
      break;
    case InstitutionType.BUILDING_SAVINGS_BANK:
      color = "cyan";
      break;
    case InstitutionType.MANAGERIAL_PENSION_COMPANY:
      color = "magenta";
      break;
    case InstitutionType.SUPPLEMENTARY_PENSION_COMPANY:
      color = "orange";
      break;
    case InstitutionType.OTHER:
      color = "geekblue";
      break;
    default:
      return null;
  }

  return (
    <Tag color={color} closable={closable} style={{ textAlign: "center", width: "98px", ...style }} onClose={onClose}>
      {t("institution.enums.institutionType." + type)}
    </Tag>
  );
};

export default InstitutionTypeTag;
