import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchPageRequest, SearchPageResult } from "../../../../../common/types";
import { appendSearchParamsToURL } from "../../../../../common/utils/utils";
import { VEHICLE_ENUMS_TAB } from "../../../containers/VehicleEnumsContainer";
import { CertificateVehicleColor, VehicleColorActions } from "../../types";
import VehicleColorForm from "../forms/VehicleColorForm";
import VehicleColorFilterView from "./VehicleColorFilterView";
import VehicleColorTableView from "./VehicleColorTableView";

interface Props {
  colorsCurrentPage: SearchPageResult<CertificateVehicleColor>;
  actions: VehicleColorActions;
}

const VehicleColorTabView = ({ colorsCurrentPage, actions }: Props) => {
  const navigate = useNavigate();

  const [colorFormOpen, setColorFormOpen] = useState<boolean>(false);
  const [colorToUpdate, setColorToUpdate] = useState<CertificateVehicleColor>(null);

  const handleCreateClick = (): void => {
    setColorFormOpen(true);
  };

  const handleUpdateClick = (category: CertificateVehicleColor): void => {
    setColorFormOpen(true);
    setColorToUpdate(category);
  };

  const handleFormCancel = (): void => {
    setColorFormOpen(false);
    setColorToUpdate(null);
  };

  const handleFilterSubmit = (filter: SearchPageRequest): void => {
    navigate(
      appendSearchParamsToURL({
        pageIndex: null,
        keyword: filter.keyword || null,
        tab: VEHICLE_ENUMS_TAB.COLORS
      }),
      { replace: true }
    );
    actions.onFilter({
      pageIndex: 0,
      pageSize: colorsCurrentPage.pageSize,
      keyword: filter.keyword
    });
  };

  const handlePageChange = (pageNumber: number): void => {
    const { pageSize, keyword } = colorsCurrentPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    actions.onFilter({ pageIndex: pageNumber - 1, pageSize, keyword });
  };

  return (
    <div className="margin-top-small">
      <VehicleColorFilterView
        filter={colorsCurrentPage}
        onFilterSubmit={handleFilterSubmit}
        onCreateClick={handleCreateClick}
      />

      <VehicleColorTableView
        colorsCurrentPage={colorsCurrentPage}
        onPageChange={handlePageChange}
        onUpdateClick={handleUpdateClick}
        onDelete={actions.onDelete}
      />

      <VehicleColorForm
        color={colorToUpdate}
        open={colorFormOpen}
        onCreate={actions.onCreate}
        onUpdate={actions.onUpdate}
        onFormCancel={handleFormCancel}
      />
    </div>
  );
};

export default VehicleColorTabView;
