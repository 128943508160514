import { Col, Form, Input, Modal, Row } from "antd";
import React, { useEffect } from "react";
import t from "../../../../app/i18n";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import ItemCreatedUpdatedInfoView from "../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { createLifeInsuranceTariffGroupActions, updateLifeInsuranceTariffGroupActions } from "../../ducks";
import { CreateUpdateLifeInsuranceTariffGroup, LifeInsuranceTariffGroup } from "../../types";

interface Props {
  open: boolean;
  tariffGroup?: LifeInsuranceTariffGroup;
  onCreate: typeof createLifeInsuranceTariffGroupActions.request;
  onUpdate: typeof updateLifeInsuranceTariffGroupActions.request;
  onFormCancel: () => void;
}

const LifeInsuranceTariffGroupForm = ({ open, tariffGroup, onCreate, onUpdate, onFormCancel }: Props) => {
  const [form] = Form.useForm<CreateUpdateLifeInsuranceTariffGroup>();
  useFormErrorHandler(form, "lifeInsuranceTariff.attrs", [
    requests.CREATE_LIFE_INSURANCE_TARIFF_GROUP,
    requests.UPDATE_LIFE_INSURANCE_TARIFF_GROUP
  ]);

  useEffect(() => {
    if (open && tariffGroup) {
      form.setFieldsValue(tariffGroup);
    }
  }, [open, tariffGroup, form]);

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_LIFE_INSURANCE_TARIFF_GROUP, requests.UPDATE_LIFE_INSURANCE_TARIFF_GROUP],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (tariffGroup) {
          onUpdate({ id: tariffGroup.id, object: values });
        } else {
          onCreate(values);
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={t(
        tariffGroup ? "lifeInsuranceTariff.titles.updateTariffGroup" : "lifeInsuranceTariff.titles.createTariffGroup"
      )}
      cancelText={t("common.cancel")}
      okText={t("common.save")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <ItemCreatedUpdatedInfoView className="margin-bottom-small" item={tariffGroup} />

      <Form form={form} layout="vertical" name="lifeInsuranceTariffGroupForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={12} offset={6}>
            <Form.Item
              name="name"
              label={t("lifeInsuranceTariff.attrs.name")}
              rules={[validations.notBlank, validations.size(1, 255)]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default LifeInsuranceTariffGroupForm;
