import { Switch, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { generatePath } from "react-router-dom";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { tableStandardProps } from "../../../../../common/utils/utils";
import { ADMIN_CALC_ROUTE_PATHS } from "../../paths";
import { CalcSettings } from "../../types";

interface Props {
  calcSettingsList: CalcSettings[];
  onCalcEnabledToggle: (settings: CalcSettings) => void;
}

const CalcSettingsListView = ({ calcSettingsList, onCalcEnabledToggle }: Props) => {
  const columns: ColumnsType<CalcSettings> = [
    {
      key: "institution",
      title: t("calc.settings.attrs.institutionId"),
      width: 210,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.institution.name}</Ellipsis>
    },
    {
      key: "enabled",
      title: t("calc.settings.attrs.enabled"),
      align: "center",
      width: 100,
      render: (_, record) => (
        <Switch size="small" checked={record.enabled} onChange={() => onCalcEnabledToggle(record)} />
      )
    },
    {
      key: "actions",
      className: "right-align",
      width: 90,
      fixed: "right",
      render: (_, record) => (
        <ActionTextIcon
          path={generatePath(ADMIN_CALC_ROUTE_PATHS.detail.to, { id: record.id })}
          icon="edit"
          color="blue"
          text={t("common.edit")}
        />
      )
    }
  ];

  return (
    <Table<CalcSettings>
      {...tableStandardProps()}
      columns={columns}
      scroll={{ x: 400 }}
      dataSource={calcSettingsList}
      pagination={false}
    />
  );
};

export default CalcSettingsListView;
