import React from "react";
import t from "../../../../../app/i18n";
import LabelWithPopover from "../../../../../common/components/form/labels/LabelWithPopover";

interface Props {
  label: string;
  popoverTitle: string;
  additionalText?: string;
}

const FloorPopoverLabel = ({ label, popoverTitle, additionalText }: Props) => (
  <LabelWithPopover
    label={label}
    popoverTitle={popoverTitle}
    popoverContent={
      <div style={{ maxWidth: "350px" }}>
        <table className="data-table-view only-first-bold-col">
          <thead>
            <tr>
              <th>{t("calc.realty.helpers.floor.valueHeader")}</th>
              <th>{t("calc.realty.helpers.floor.floorHeader")}</th>
              <th>{t("calc.realty.helpers.floor.overgroundFloorHeader")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>{t("calc.realty.helpers.floor.groundFloor")}</td>
              <td>{t("calc.realty.helpers.floor.overgroundFloor", { value: 1 })}</td>
            </tr>
            {[2, 3, 4, 5].map(value => (
              <tr key={value}>
                <td>{value}</td>
                <td>{t("calc.realty.helpers.floor.floor", { value: value - 1 })}</td>
                <td>{t("calc.realty.helpers.floor.overgroundFloor", { value: value })}</td>
              </tr>
            ))}
            <tr>
              <td>...</td>
              <td>...</td>
              <td>...</td>
            </tr>
          </tbody>
        </table>

        {additionalText && (
          <span>
            <i>{additionalText}</i>
          </span>
        )}
      </div>
    }
  />
);

export default FloorPopoverLabel;
