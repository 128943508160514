import { VehicleMappingType } from "./enums";
import {
  CreateVehicleMapping,
  CreateVehicleMappingFormItem,
  UpdateVehicleMapping,
  UpdateVehicleMappingFormItem,
  VehicleMapping
} from "./types";

export const VEHICLE_TYPES_PATH_MAP = new Map<VehicleMappingType, string>([
  [VehicleMappingType.ALLIANZ, "allianz"],
  [VehicleMappingType.COLONNADE, "colonnade"],
  [VehicleMappingType.CSOB, "csob"],
  [VehicleMappingType.DEFEND, "defend"],
  [VehicleMappingType.GENERALI, "generali"],
  [VehicleMappingType.KOMUNALNA, "komunalna"],
  [VehicleMappingType.KOOPERATIVA, "kooperativa"],
  [VehicleMappingType.UNION_MTPL, "unionMtpl"],
  [VehicleMappingType.UNION_CRASH, "unionCrash"],
  [VehicleMappingType.UNIQA, "uniqa"]
]);
export const VEHICLE_IDENTIFIER_DELIMITER = "<->";

export const parseVehicleMappingsFromIdentifiers = (
  item: CreateVehicleMappingFormItem | UpdateVehicleMappingFormItem
): CreateVehicleMapping | UpdateVehicleMapping => {
  const itemCopy = { ...item };

  Object.values(VehicleMappingType).forEach(type => {
    const typePath = VEHICLE_TYPES_PATH_MAP.get(type);
    const mappingIdentifier = itemCopy[typePath + "Identifier"] as string;
    if (mappingIdentifier) {
      const identifierTokens = mappingIdentifier.split(VEHICLE_IDENTIFIER_DELIMITER);
      itemCopy[typePath + "Id"] = identifierTokens[0];
      itemCopy[typePath + "Name"] = identifierTokens[1];
    } else {
      delete itemCopy[typePath + "Id"];
      delete itemCopy[typePath + "Name"];
    }
    delete itemCopy[typePath + "Identifier"];
  });

  return itemCopy as CreateVehicleMapping | UpdateVehicleMapping;
};

export const parseVehicleMappingsToUpdateFormItems = (...items: VehicleMapping[]): UpdateVehicleMappingFormItem[] => {
  const updateItems = items.map<UpdateVehicleMappingFormItem>(mapping => ({
    ...mapping,
    createdAt: undefined,
    updatedAt: undefined
  }));

  updateItems.forEach(item => {
    Object.values(VehicleMappingType).forEach(type => {
      const idValue = item[VEHICLE_TYPES_PATH_MAP.get(type) + "Id"];
      const nameValue = item[VEHICLE_TYPES_PATH_MAP.get(type) + "Name"];
      item[VEHICLE_TYPES_PATH_MAP.get(type) + "Identifier"] =
        idValue && nameValue ? idValue + VEHICLE_IDENTIFIER_DELIMITER + nameValue : null;
    });
  });

  return updateItems;
};
