import { Tooltip } from "antd";
import { DataNode } from "rc-tree/lib/interface";
import React from "react";
import t from "../../app/i18n";
import AntIcon from "../../common/components/icons/AntIcon";
import {
  categoryPermissionsMap,
  Feature,
  featureAlwaysAllowedPermissions,
  featurePermissionsMap,
  Permission,
  PermissionsCategory,
  permissionsPrerequisitesMap,
  systemAdminOnlyPermissions
} from "../../common/security/authorization/enums";
import { contains, containsAll, containsAny, getAllPermissionPrerequisites } from "../../common/utils/utils";
import { UserAdminView } from "./types";

export const getAvailablePermissions = (isCurrentUserSystemAdmin: boolean, allowedFeatures: Feature[]) =>
  [...categoryPermissionsMap]
    .map(([category, permissions]) => ({
      category,
      permissions: isCurrentUserSystemAdmin
        ? permissions
        : permissions.filter(permission => !systemAdminOnlyPermissions.includes(permission))
    }))
    .map(({ category, permissions }) => ({
      category,
      permissions: permissions.filter(
        permission =>
          featureAlwaysAllowedPermissions.includes(permission) ||
          allowedFeatures.some(feature => featurePermissionsMap.get(feature).includes(permission))
      )
    }))
    .filter(({ permissions }) => permissions.length > 0);

export const buildPermissionTreeNodesView = (
  isCurrentUserSystemAdmin: boolean,
  allowedFeatures: Feature[],
  accountPermissions: Permission[]
): DataNode[] => {
  return getAvailablePermissions(isCurrentUserSystemAdmin, allowedFeatures).map<DataNode>(
    ({ category, permissions }) => ({
      title: t("user.enums.permissionsCategory." + category),
      key: category,
      value: category,
      selectable: false,
      disabled: !containsAny(accountPermissions, ...permissions),
      children: permissions.map<DataNode>(permission => ({
        title: t("user.enums.permission." + permission),
        key: permission,
        value: permission,
        selectable: false,
        disabled: !contains(accountPermissions, permission)
      }))
    })
  );
};

export const buildPermissionTreeNodes = (
  isCurrentUserSystemAdmin: boolean,
  isModifiedUserSystemAdmin: boolean,
  allowedFeatures: Feature[],
  checkedPermissions: Permission[]
): DataNode[] =>
  getAvailablePermissions(isCurrentUserSystemAdmin, allowedFeatures).map<DataNode>(({ category, permissions }) => ({
    title: t("user.enums.permissionsCategory." + category),
    key: category,
    value: category,
    selectable: false,
    checkable: false,
    disabled: !containsAny(checkedPermissions, ...permissions),
    children: permissions.map<DataNode>(permission => {
      const missingPrerequisites = !containsAll(checkedPermissions, ...permissionsPrerequisitesMap.get(permission));
      const missingSystemAdmin =
        categoryPermissionsMap.get(PermissionsCategory.SYSTEM).includes(permission) && !isModifiedUserSystemAdmin;
      const disabled = missingPrerequisites || missingSystemAdmin;
      const title = disabled ? (
        <>
          {t("user.enums.permission." + permission)}
          <Tooltip
            placement="right"
            title={
              <>
                {missingPrerequisites && (
                  <>
                    {t("user.helpers.missingPrerequisites")}:&nbsp;
                    {getAllPermissionPrerequisites(permission)
                      .filter(prerequisite => !contains(checkedPermissions, prerequisite))
                      .map(prerequisite => t("user.enums.permission." + prerequisite))
                      .join(", ")}
                    .&nbsp;
                  </>
                )}
                {missingSystemAdmin && <>{t("user.helpers.missingSystemAdmin")}</>}
              </>
            }
          >
            <span>
              <AntIcon type="question" className="margin-left-tiny cursor-help" />
            </span>
          </Tooltip>
        </>
      ) : (
        t("user.enums.permission." + permission)
      );

      return { title, key: permission, selectable: false, disabled, disableCheckbox: disabled };
    })
  }));

export const hasConfirmedAgentUserAccount = (user: UserAdminView): boolean =>
  user.agentUserRole?.userAccounts.some(userAccount => userAccount.confirmed);

export const hasConfirmedClientUserAccount = (user: UserAdminView): boolean =>
  user.clientUserRole?.userAccounts.some(userAccount => userAccount.confirmed);

export const hasDisabledAgentUserAccountsOnly = (user: UserAdminView): boolean =>
  user.agentUserRole?.userAccounts.every(userAccount => userAccount.disabled);
