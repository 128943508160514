import React from "react";
import { Route, Routes } from "react-router-dom";
import { WILDCARD_ROUTE } from "../../common/constants";
import NotFound from "../../common/pages/NotFound/NotFound";
import { Permission } from "../../common/security/authorization/enums";
import PageWithPermission from "../../common/security/authorization/PageWithPermission";
import RealtyCalcContainer from "./calcs/realty/containers/RealtyCalcContainer";
import TravelCalcContainer from "./calcs/travel/containers/TravelCalcContainer";
import VehicleCalcContainer from "./calcs/vehicle/containers/VehicleCalcContainer";
import CalcDraftListContainer from "./drafts/containers/CalcDraftListContainer";
import { CALC_ROUTE_PATHS } from "./paths";
import CalcRecordsContainer from "./records/containers/CalcRecordsContainer";

const routes = () => (
  <Routes>
    <Route
      path={CALC_ROUTE_PATHS.vehicle.path}
      element={
        <PageWithPermission
          component={<VehicleCalcContainer />}
          permissions={[Permission.MTPL_CALCULATE, Permission.CRASH_CALCULATE]}
          anyPermission
        />
      }
    />

    <Route
      path={CALC_ROUTE_PATHS.travel.path}
      element={<PageWithPermission component={<TravelCalcContainer />} permissions={[Permission.TRAVEL_CALCULATE]} />}
    />

    <Route
      path={CALC_ROUTE_PATHS.realty.path}
      element={<PageWithPermission component={<RealtyCalcContainer />} permissions={[Permission.REALTY_CALCULATE]} />}
    />

    <Route
      path={CALC_ROUTE_PATHS.vehicleDraft.path}
      element={
        <PageWithPermission
          component={<CalcDraftListContainer key={CALC_ROUTE_PATHS.vehicleDraft.path} />}
          permissions={[Permission.MTPL_CALCULATE, Permission.CRASH_CALCULATE]}
          anyPermission
        />
      }
    />

    <Route
      path={CALC_ROUTE_PATHS.realtyDraft.path}
      element={
        <PageWithPermission
          component={<CalcDraftListContainer key={CALC_ROUTE_PATHS.realtyDraft.path} />}
          permissions={[Permission.REALTY_CALCULATE]}
        />
      }
    />

    <Route
      path={CALC_ROUTE_PATHS.travelDraft.path}
      element={
        <PageWithPermission
          component={<CalcDraftListContainer key={CALC_ROUTE_PATHS.travelDraft.path} />}
          permissions={[Permission.TRAVEL_CALCULATE]}
        />
      }
    />

    <Route
      path={CALC_ROUTE_PATHS.records.path}
      element={
        <PageWithPermission
          component={<CalcRecordsContainer />}
          permissions={[
            Permission.ADMIN_MTPL_CALCULATORS,
            Permission.ADMIN_CRASH_CALCULATORS,
            Permission.ADMIN_REALTY_CALCULATORS,
            Permission.ADMIN_TRAVEL_CALCULATORS
          ]}
          anyPermission
        />
      }
    />

    <Route path={WILDCARD_ROUTE} element={<NotFound />} />
  </Routes>
);

export default routes;
