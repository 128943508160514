import React from "react";
import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import { RootState, UUID } from "../../../../common/types";
import { selectVehicleBrandsEnums, VEHICLE_OTHER_MAPPING_NAME } from "../../ducks";
import { VehicleBrandWithModels } from "../../types";

interface Props {
  brandId: UUID;
  otherBrandName?: string;
}

const VehicleBrandEnumName = ({ brandId, otherBrandName }: Props) => {
  const vehicleBrandsEnums = useSelector<RootState, VehicleBrandWithModels[]>(selectVehicleBrandsEnums);

  const brand = vehicleBrandsEnums.find(brand => brand.id === brandId);
  return brand ? (
    brand.name === VEHICLE_OTHER_MAPPING_NAME && otherBrandName ? (
      <>{`${brand.name} (${otherBrandName})`}</>
    ) : (
      <>{brand.name}</>
    )
  ) : (
    <>{t("vehicle.helpers.unknownBrand")}</>
  );
};

export default VehicleBrandEnumName;
