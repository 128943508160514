import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../../../app/i18n";
import ContentWrapper from "../../../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../../../common/types";
import messageUtils from "../../../../../common/utils/messageUtils";
import { ClientFormType } from "../../../../client/enums";
import { LegalClient, NaturalClient, SelfEmployedClient } from "../../../../client/types";
import { createCalcDraftActions, updateCalcDraftActions } from "../../../drafts/ducks";
import { CreateUpdateCalcDraft } from "../../../drafts/types";
import { CalcType } from "../../../enums";
import CalcLoadingModal from "../../components/CalcLoadingModal";
import { CalcDataSource, CalcResult, GenResponse } from "../../types";
import RealtyCalcWrapper from "../components/calc/RealtyCalcWrapper";
import RealtyGenWrapper from "../components/gen/RealtyGenWrapper";
import {
  calculateRealtyActions,
  deleteStateRealtyCalcResultsAction,
  deleteStateRealtyDraftAction,
  deleteStateRealtyGenResultAction,
  deleteStateRealtyInitialCalcGenDataAction,
  generateRealtyActions,
  generateRealtyOfferActions,
  selectRealtyCalcResults,
  selectRealtyDraft,
  selectRealtyGenResult,
  selectRealtyInitialCalcData,
  selectRealtyInitialGenData
} from "../ducks";
import { OperationStage } from "../enums";
import {
  RealtyCalc,
  RealtyCalcDraft,
  RealtyCalcResultData,
  RealtyFormClients,
  RealtyGen,
  RealtyGenForm,
  RealtyOfferType
} from "../types";
import {
  createRealtyCalcObjectFromCalcData,
  createRealtyCalcObjectFromGenData,
  createRealtyFormClientsObjectFromCalcData,
  createRealtyFormClientsObjectFromGenData,
  createRealtyGenFormDataObject
} from "../utils";

interface StateProps {
  initialCalcData: RealtyCalc;
  initialGenData: RealtyGen;
  draft: RealtyCalcDraft;
  calcResults: CalcResult<RealtyCalcResultData>[][];
  genResult: GenResponse;
}

interface ActionsMap {
  calculateRealty: typeof calculateRealtyActions.request;
  generateRealty: typeof generateRealtyActions.request;
  generateRealtyOffer: typeof generateRealtyOfferActions.request;
  createCalcDraft: typeof createCalcDraftActions.request;
  updateCalcDraft: typeof updateCalcDraftActions.request;
  deleteStateRealtyCalcResults: typeof deleteStateRealtyCalcResultsAction;
  deleteStateRealtyGenResult: typeof deleteStateRealtyGenResultAction;
  deleteStateRealtyDraft: typeof deleteStateRealtyDraftAction;
  deleteStateRealtyInitialCalcGenData: typeof deleteStateRealtyInitialCalcGenDataAction;
}

const RealtyCalcContainer = (props: StateProps & ActionProps<ActionsMap>) => {
  const [resetKey, setResetKey] = useState<number>(0);
  const [genStepActive, setGenStepActive] = useState<boolean>(false);
  const [selectedResult, setSelectedResult] = useState<CalcResult<RealtyCalcResultData>>();

  const [clients, setClients] = useState<RealtyFormClients>(
    props.initialCalcData
      ? createRealtyFormClientsObjectFromCalcData(props.initialCalcData)
      : props.initialGenData
        ? createRealtyFormClientsObjectFromGenData(props.initialGenData)
        : props.draft
          ? createRealtyFormClientsObjectFromGenData(props.draft.draftData)
          : {
              policyHolder: null,
              insured: null,
              representative: null,
              vinculation: null
            }
  );

  const [calcDataSource, setCalcDataSource] = useState<CalcDataSource>(
    props.initialCalcData
      ? "calcData"
      : props.initialGenData
        ? "genData"
        : props.draft?.stage === OperationStage.CALCULATE
          ? "calcDraft"
          : props.draft?.stage === OperationStage.GENERATE
            ? "genDraft"
            : "init"
  );

  const [calcData, setCalcData] = useState<RealtyCalc>(
    props.initialCalcData
      ? createRealtyCalcObjectFromCalcData(props.initialCalcData, clients)
      : props.initialGenData
        ? createRealtyCalcObjectFromGenData(props.initialGenData, clients)
        : props.draft
          ? createRealtyCalcObjectFromGenData(props.draft.draftData, clients)
          : null
  );

  const [genData, setGenData] = useState<RealtyGenForm>(
    props.initialGenData
      ? createRealtyGenFormDataObject(props.initialGenData, clients)
      : props.draft?.stage === OperationStage.GENERATE
        ? createRealtyGenFormDataObject(props.draft.draftData, clients)
        : null
  );

  useEffect(() => {
    if (props.draft) {
      messageUtils.infoNotification(t("calc.draft.helpers.draftLoaded"), t("calc.draft.helpers.draftLoadedInfo"), 10);
    }
    if (props.initialCalcData || props.initialGenData) {
      props.actions.deleteStateRealtyInitialCalcGenData();
      messageUtils.infoNotification(
        t("calc.records.helpers.calcDataInitTitle"),
        t("calc.records.helpers.calcDataInit"),
        10
      );
    }

    return () => {
      props.actions.deleteStateRealtyCalcResults();
      props.actions.deleteStateRealtyGenResult();
      props.actions.deleteStateRealtyDraft();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCalculationFormSubmit = (calcData: RealtyCalc): void => {
    setCalcData(calcData);
    props.actions.calculateRealty(calcData);
  };

  const handleClientChange = (
    client: NaturalClient | SelfEmployedClient | LegalClient,
    type: ClientFormType
  ): RealtyFormClients => {
    let updatedClients: RealtyFormClients;
    switch (type) {
      case ClientFormType.POLICY_HOLDER:
        updatedClients = { ...clients, policyHolder: client };
        break;
      case ClientFormType.INSURED:
        updatedClients = { ...clients, insured: client as NaturalClient };
        break;
      case ClientFormType.REPRESENTATIVE:
        updatedClients = { ...clients, representative: client as NaturalClient };
        break;
      case ClientFormType.VINCULATION:
        updatedClients = { ...clients, vinculation: client as LegalClient };
        break;
      default:
        updatedClients = { ...clients };
        break;
    }
    setClients(updatedClients);
    return updatedClients;
  };

  const handleResetCalculatorClick = (): void => {
    Modal.confirm({
      title: t("calc.helpers.resetCalculatorSubmit"),
      okText: t("common.yes"),
      cancelText: t("common.back"),
      onOk: () => {
        setResetKey(resetKey + 1);
        setSelectedResult(null);
        setClients({ policyHolder: null, insured: null, representative: null, vinculation: null });
        setCalcData(null);
        setCalcDataSource("init");
        setGenData(null);

        props.actions.deleteStateRealtyCalcResults();
        props.actions.deleteStateRealtyDraft();
      }
    });
  };

  const handleCalcDataSourceReset = (): void => {
    setCalcDataSource("init");
  };

  const handleGenerateContractClick = (
    calcData: RealtyCalc,
    selectedResult: CalcResult<RealtyCalcResultData>
  ): void => {
    setGenStepActive(true);
    setCalcData(calcData);
    setSelectedResult(selectedResult);
  };

  const handleReturnToCalculationClick = (genData: RealtyGenForm): void => {
    setGenStepActive(false);
    setSelectedResult(null);
    setCalcDataSource("calcData");
    setGenData(genData);
    props.actions.deleteStateRealtyGenResult();
  };

  const handleGenerateOfferClick = (type: RealtyOfferType, calcData: RealtyCalc): void => {
    props.actions.generateRealtyOffer({
      type,
      calcRequest: calcData,
      calcResponse: { results: props.calcResults.flat() },
      clientName: clients.policyHolder.aggregatedName
    });
  };

  const handleSaveDraftClick = (draftData: RealtyGen, overwriteExisting: boolean): void => {
    const draft: CreateUpdateCalcDraft = {
      draftData,
      calcResponse: { results: props.calcResults.flat() },
      clientName: clients.policyHolder.aggregatedName,
      calcType: CalcType.REALTY,
      stage: genStepActive ? OperationStage.GENERATE : OperationStage.CALCULATE
    };

    if (props.draft && overwriteExisting) {
      props.actions.updateCalcDraft({
        id: props.draft.id,
        object: { ...draft, optimisticLockVersion: props.draft.optimisticLockVersion }
      });
    } else {
      props.actions.createCalcDraft(draft);
    }
  };

  return (
    <ContentWrapper>
      {genStepActive ? (
        <RealtyGenWrapper
          initialData={genData}
          genResult={props.genResult}
          calcData={calcData}
          clients={clients}
          calcResults={props.calcResults}
          selectedResult={selectedResult}
          draftId={props.draft?.id}
          onGenerateFormSubmit={props.actions.generateRealty}
          onGenResultDelete={props.actions.deleteStateRealtyGenResult}
          onClientChange={handleClientChange}
          onGenerateOfferClick={type => handleGenerateOfferClick(type, calcData)}
          onSelectedResultChange={setSelectedResult}
          onSaveDraftClick={handleSaveDraftClick}
          onReturnToCalculationClick={handleReturnToCalculationClick}
        />
      ) : (
        <RealtyCalcWrapper
          key={resetKey}
          calcData={calcData}
          calcDataSource={calcDataSource}
          calcResults={props.calcResults}
          policyHolder={clients.policyHolder}
          onCalcResultsDelete={props.actions.deleteStateRealtyCalcResults}
          onCalculationFormSubmit={handleCalculationFormSubmit}
          onClientChange={handleClientChange}
          onGenerateContractClick={handleGenerateContractClick}
          onGenerateOfferClick={handleGenerateOfferClick}
          onSaveDraftClick={(data, overwriteExisting) => handleSaveDraftClick(data as RealtyGen, overwriteExisting)}
          onResetCalculatorClick={handleResetCalculatorClick}
          onCalcDataSourceReset={handleCalcDataSourceReset}
        />
      )}

      <CalcLoadingModal />
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  initialCalcData: selectRealtyInitialCalcData(state),
  initialGenData: selectRealtyInitialGenData(state),
  draft: selectRealtyDraft(state),
  calcResults: selectRealtyCalcResults(state),
  genResult: selectRealtyGenResult(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      calculateRealty: calculateRealtyActions.request,
      generateRealty: generateRealtyActions.request,
      generateRealtyOffer: generateRealtyOfferActions.request,
      createCalcDraft: createCalcDraftActions.request,
      updateCalcDraft: updateCalcDraftActions.request,
      deleteStateRealtyCalcResults: deleteStateRealtyCalcResultsAction,
      deleteStateRealtyGenResult: deleteStateRealtyGenResultAction,
      deleteStateRealtyDraft: deleteStateRealtyDraftAction,
      deleteStateRealtyInitialCalcGenData: deleteStateRealtyInitialCalcGenDataAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(RealtyCalcContainer);
