import { Card, Col, Divider, Form, Input, Row } from "antd";
import { FormInstance } from "antd/lib/form";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import t from "../../../../../../app/i18n";
import LabelWithTooltip from "../../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../../common/constants";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { FieldConstraintViolation, RootState, UUID } from "../../../../../../common/types";
import { resolveFormValidationError } from "../../../../../../common/utils/formUtils";
import { tInterval } from "../../../../../../common/utils/translationUtils";
import { isDefinedValue } from "../../../../../../common/utils/utils";
import { validations } from "../../../../../../common/utils/validationUtils";
import { selectHasPermissions } from "../../../../../auth/ducks";
import ClientDrawerForm from "../../../../../client/components/drawers/ClientDrawerForm";
import ClientSearchInput from "../../../../../client/components/search/ClientSearchInput";
import { ClientFormStage, ClientSearchActionType } from "../../../../../client/enums";
import { Client } from "../../../../../client/types";
import { useClientSearch } from "../../../../../client/utils";
import InstitutionSelect from "../../../../../enumerations/components/form/InstitutionSelect";
import ProductSelect from "../../../../../enumerations/components/form/ProductSelect";
import { selectProductsClassificationEnums } from "../../../../../enumerations/ducks";
import { InstitutionWithProducts } from "../../../../../enumerations/types";
import { InstitutionType } from "../../../../../institution/enums";
import { ProductFinancialSector } from "../../../../../product/enums";
import { CreateUpdateLoanContract, LoanContract } from "../../../../types";
import { isContractVerified } from "../../../../utils";

interface Props {
  initialContract?: LoanContract;
  form: FormInstance<CreateUpdateLoanContract>;
  clients: Client[];
  clientsViolationErrors: Map<number, FieldConstraintViolation[]>;
  onClientChange(client: Client, index: number): void;
  onClientViolationErrorsChange(errorsMap: Map<number, FieldConstraintViolation[]>);
}

const LoanContractFormHeaderSection = ({ initialContract, form, clients, ...props }: Props) => {
  const clientSearch = useClientSearch();

  const productsClassification = useSelector<RootState, InstitutionWithProducts[]>(selectProductsClassificationEnums);

  const hasPrivilegedChangesPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.PRIVILEGED_CHANGES_ON_VERIFIED_LOAN)(state)
  );

  const disablePrivilegedInputs = isContractVerified(initialContract) && !hasPrivilegedChangesPermission;

  const [clientFormOpen, setClientFormOpen] = useState<boolean>(false);
  const [processedClientIndex, setProcessedClientIndex] = useState<number>();
  const [clientStages, setClientStages] = useState<ClientFormStage[]>(
    initialContract?.clients.map(() => ClientFormStage.SELECTED) || []
  );

  useEffect(() => {
    if (
      isDefinedValue(processedClientIndex) &&
      clientSearch.result.keyword === form.getFieldValue(["clientIdentifiers", processedClientIndex])
    ) {
      if (clientSearch.result.data) {
        setClientStage(ClientFormStage.EXISTING, processedClientIndex);
        props.onClientChange(clientSearch.result.data, processedClientIndex);
      } else {
        setClientStage(ClientFormStage.NEW, processedClientIndex);
      }
    }
  }, [clientSearch.result]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInstitutionIdChange = (institutionId: UUID): void => {
    const institution = productsClassification.find(institution => institution.id === institutionId);
    const productId = form.getFieldValue("productId") as UUID;

    if (
      productId &&
      !institution?.productGroups.flatMap(group => group.products).some(product => product.id === productId)
    ) {
      form.setFieldsValue({ productId: null });
    }
  };

  const handleClientSearchActionClick = (index: number, actionType: ClientSearchActionType): void => {
    switch (actionType) {
      case ClientSearchActionType.CREATE:
      case ClientSearchActionType.UPDATE:
        setProcessedClientIndex(index);
        setClientFormOpen(true);
        break;
      case ClientSearchActionType.DELETE:
        const updatedIdentifiers = [...((form.getFieldValue("clientIdentifiers") as string[]) || [])];
        updatedIdentifiers[index] = null;
        form.setFieldsValue({ clientIdentifiers: updatedIdentifiers });

        const updatedClientStages = [...clientStages];
        updatedClientStages[index] = null;
        setClientStages(updatedClientStages);

        deleteClientViolationErrors(index);
        setProcessedClientIndex(null);
        props.onClientChange(null, index);
        break;
    }
  };

  const handleClientSearchSubmit = (value: string, index: number): void => {
    form
      .validateFields([["clientIdentifiers", index]])
      .then(() => {
        if (value) {
          clientSearch.onSearch({ keyword: value });
        }
      })
      .catch(resolveFormValidationError);
  };

  const handleClientSearchChange = (value: string, index: number): void => {
    if (clientStages[index]) {
      setClientStage(null, index);
    }
    if (clients[index]) {
      props.onClientChange(null, index);
    }
    if (props.clientsViolationErrors.has(index)) {
      deleteClientViolationErrors(index);
    }

    handleClientSearchSubmit(value, index);
  };

  const handleClientFormSubmit = (client: Client, index: number): void => {
    setClientFormOpen(false);
    setProcessedClientIndex(null);
    setClientStage(ClientFormStage.SELECTED, index);
    deleteClientViolationErrors(index);
    props.onClientChange(client, index);
  };

  const getClientFormIdentifier = (index: number): string => {
    return ((form.getFieldValue("clientIdentifiers") as string[]) || [])[index];
  };

  const setClientStage = (stage: ClientFormStage, index: number): void => {
    const updatedClientStages = [...clientStages];
    updatedClientStages[index] = stage;
    setClientStages(updatedClientStages);
  };

  const deleteClientViolationErrors = (index: number): void => {
    const updatedViolationsErrors = new Map<number, FieldConstraintViolation[]>([...props.clientsViolationErrors]);
    updatedViolationsErrors.delete(index);
    props.onClientViolationErrorsChange(updatedViolationsErrors);
  };

  const colSpan = 4;
  const clientInputColSpan = 5;
  const clientNameColSpan = 6;

  const clientSearchProps = {
    processedType: processedClientIndex,
    violationErrors: props.clientsViolationErrors,
    inProgress: clientSearch.inProgress,
    inputColSpan: clientInputColSpan,
    clientNameColSpan,
    onActionClick: handleClientSearchActionClick,
    onFocus: setProcessedClientIndex,
    onSearch: handleClientSearchSubmit,
    onChange: handleClientSearchChange
  };

  return (
    <>
      <Card type="inner" className="card-box margin-top-medium" title={t("contract.sections.contractIdentification")}>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="contractNumber"
              label={t("contract.attrs.contractNumber")}
              dependencies={["secondaryContractNumber"]}
              rules={[
                validations.size(1, 64),
                validations.notNullIfOtherNull("secondaryContractNumber", t("contract.attrs.loanNumber")),
                validations.notEqualTo("secondaryContractNumber", t("contract.attrs.loanNumber"))
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="secondaryContractNumber"
              label={t("contract.attrs.loanNumber")}
              dependencies={["contractNumber"]}
              rules={[
                validations.size(1, 64),
                validations.notNullIfOtherNull("contractNumber", t("contract.attrs.contractNumber")),
                validations.notEqualTo("contractNumber", t("contract.attrs.contractNumber"))
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <InstitutionSelect
              formItemProps={{
                name: "institutionId",
                label: t("common.bankInstitution"),
                rules: [validations.notNull]
              }}
              selectProps={{ disabled: disablePrivilegedInputs, onChange: handleInstitutionIdChange }}
              optionsProps={{
                selected: initialContract ? [initialContract.institution] : undefined,
                filterType: InstitutionType.BANK
              }}
            />
          </Col>

          <Col span={colSpan}>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.institutionId !== next.institutionId}>
              {({ getFieldValue }) => {
                const institutionId = getFieldValue("institutionId");
                return (
                  <ProductSelect
                    formItemProps={{
                      name: "productId",
                      label: t("contract.attrs.productId"),
                      rules: [validations.notNull]
                    }}
                    optionsProps={{
                      institutionId,
                      selectedInTree: {
                        selected: initialContract?.product,
                        condition: initialContract?.institution.id === institutionId
                      },
                      hideAll: !!!institutionId,
                      groupByProductGroup: true,
                      filterSectors: [ProductFinancialSector.LOANS_AND_MORTGAGES]
                    }}
                    selectProps={{
                      disabled: disablePrivilegedInputs,
                      placeholder: !!!institutionId ? t("contract.helpers.loanProductPlaceholder") : undefined
                    }}
                  />
                );
              }}
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">{t("contract.sections.clients")}</Divider>

        <Row gutter={rowGutter}>
          {[...Array(2)].map((_, index) => (
            <ClientSearchInput<number>
              {...clientSearchProps}
              key={index}
              formItemProps={{
                name: ["clientIdentifiers", index],
                label: (
                  <LabelWithTooltip
                    tooltip={t("helpers.pinFormat")}
                    label={tInterval("contract.attrs.loanClientIdentifiers_interval", index)}
                  />
                ),
                rules: [
                  index === 0 ? validations.notBlank : validations.none,
                  validations.pinOrCrn,
                  validations.noRepeatedClient("clientIdentifiers")
                ]
              }}
              optional={index > 0}
              formStage={clientStages[index]}
              formType={index}
              client={clients[index]}
            />
          ))}
        </Row>
      </Card>

      <ClientDrawerForm<number>
        open={clientFormOpen}
        client={clients[processedClientIndex]}
        initialIdentifier={getClientFormIdentifier(processedClientIndex)}
        formType={processedClientIndex}
        violationErrors={props.clientsViolationErrors}
        placement="contract"
        onFormSubmit={handleClientFormSubmit}
      />
    </>
  );
};

export default LoanContractFormHeaderSection;
