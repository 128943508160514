import { Tag } from "antd";
import React from "react";
import t from "../../../../app/i18n";
import { CommissionSource } from "../enums";

interface Props {
  source: CommissionSource;
  style?: React.CSSProperties;
}

const CommissionSourceTag = ({ source, style }: Props) => {
  let color: string;

  switch (source) {
    case CommissionSource.COMMISSION_BASE_AMOUNT:
      color = "blue";
      break;
    case CommissionSource.COMMISSION_AMOUNT:
      color = "cyan";
      break;
    default:
      return null;
  }

  return (
    <Tag style={style} color={color}>
      {t("commissions.settings.enums.commissionSource." + source)}
    </Tag>
  );
};

export default CommissionSourceTag;
