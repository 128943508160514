import i18n from "i18next";
import intervalPluralPostProcessor from "i18next-intervalplural-postprocessor";
import moment from "moment-timezone";
import "moment/locale/sk";
import { initReactI18next } from "react-i18next";
import { EnvProfile } from "../../common/types";
import locales from "./locales";

export const DEFAULT_LOCALE = "sk";
export const I18N_NAMESPACE = "cliip";

export const i18nResources = {
  [DEFAULT_LOCALE]: {
    [I18N_NAMESPACE]: locales.sk
  }
};

i18n
  .use(initReactI18next)
  .use(intervalPluralPostProcessor)
  .init({
    defaultNS: I18N_NAMESPACE,
    ns: I18N_NAMESPACE,
    lng: DEFAULT_LOCALE,
    fallbackLng: DEFAULT_LOCALE,
    debug: process.env.NODE_ENV === "development" && (process.env.REACT_APP_ACTIVE_PROFILE as EnvProfile) === "dev",
    resources: i18nResources,
    returnEmptyString: false,
    keySeparator: ".",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n.t as (key: string, options?: object) => string;

moment.locale(DEFAULT_LOCALE);
moment.tz.setDefault("Europe/Bratislava");
moment.fn.toJSON = function () {
  return this.toISOString(true);
};
moment.updateLocale(DEFAULT_LOCALE, {
  longDateFormat: {
    LT: "HH:mm", // "H:mm"
    LTS: "HH:mm:ss", // "H:mm:ss"
    L: "DD.MM.YYYY",
    LL: "D. MMMM YYYY",
    LLL: "D. MMMM YYYY H:mm",
    LLLL: "dddd D. MMMM YYYY H:mm"
  }
});
