import { Layout, Skeleton, Spin } from "antd";
import { Pathname } from "history";
import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import logo from "../../assets/images/logo-cliip-white-color-1000x.png";
import squareLogo from "../../assets/images/logo-clip-1000xsquare-flower-transparent.png";
import { ATTACHMENT_BOUNDARY_PATH_PREFIX, ViewBreakpoints } from "../../common/constants";
import { Role } from "../../common/security/authorization/enums";
import { ActionProps, RootState } from "../../common/types";
import { useGoogleAnalytics } from "../../common/utils/analyticsUtils";
import { useDocumentTitle, useWindowSize } from "../../common/utils/hooksUtils";
import { getActiveEnvProfile } from "../../common/utils/utils";
import { checkAuthStateAction, selectIsUserAuthenticated, selectSelectedAccount } from "../../modules/auth/ducks";
import { AuthSelectedAccount } from "../../modules/auth/types";
import { DASHBOARD_ROUTE_PATHS } from "../../modules/dashboard/paths";
import { selectBrandingPersistData, selectHasActiveRequest, selectRouterLocationPathname } from "../../modules/ducks";
import { refreshEnumerationsAction, selectEnumsLastRefreshTime } from "../../modules/enumerations/ducks";
import { AgentBrandingEnumeration } from "../../modules/enumerations/types";
import {
  FILTER_NOTIFICATIONS_DEFAULT_REQUEST,
  filterHeaderNotificationsActions
} from "../../modules/notifications/ducks";
import BreadcrumbsBox from "../components/layout/BreadcrumbsBox";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import SideMenu from "../components/layout/SideMenu";
import { agentRoleRoutes, attachmentRoutes, clientRoleRoutes } from "../routes";

interface StateProps {
  userAuthenticated: boolean;
  selectedAccount: AuthSelectedAccount;
  showSpinner: boolean;
  enumsLastRefreshTime: string;
  branding: AgentBrandingEnumeration;
}

interface ActionsMap {
  checkAuthState: typeof checkAuthStateAction;
  refreshEnumerations: typeof refreshEnumerationsAction;
  filterHeaderNotifications: typeof filterHeaderNotificationsActions.request;
}

type Props = StateProps & ActionProps<ActionsMap>;

const App = (props: Props) => {
  useGoogleAnalytics();
  useDocumentTitle();

  const routerPathname = useSelector<RootState, Pathname>(selectRouterLocationPathname);
  const isAttachmentView = routerPathname.startsWith(ATTACHMENT_BOUNDARY_PATH_PREFIX);

  useEffect(() => {
    props.actions.checkAuthState();
    if (props.userAuthenticated && props.selectedAccount.role === Role.AGENT) {
      props.actions.refreshEnumerations();
      props.actions.filterHeaderNotifications(FILTER_NOTIFICATIONS_DEFAULT_REQUEST);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const windowSize = useWindowSize();

  if (windowSize.outerWidth < ViewBreakpoints.TABLET) {
    document.querySelector("meta[name=viewport]").setAttribute("content", "width=991");
  } else {
    document
      .querySelector("meta[name=viewport]")
      .setAttribute("content", "width=device-width, initial-scale=1, shrink-to-fit=no");
  }

  return isAttachmentView ? (
    attachmentRoutes
  ) : (
    <Layout>
      {props.userAuthenticated && (
        <Layout.Sider breakpoint="xl" collapsedWidth="54">
          <div className="logo">
            <Link to={DASHBOARD_ROUTE_PATHS.homepage.to} className="logo__link">
              <img
                className="logo__image"
                src={
                  props.branding?.logoResourcePath
                    ? `/images/${props.branding.logoResourcePath}`
                    : windowSize.innerWidth > ViewBreakpoints.DESKTOP
                      ? logo
                      : squareLogo
                }
                alt="Logo"
              />
            </Link>
          </div>
          {props.selectedAccount.role === Role.AGENT && <SideMenu />}
        </Layout.Sider>
      )}

      <Layout
        className={`root-layout ${props.userAuthenticated ? "root-layout--signed" : null} ${getActiveEnvProfile()}`}
      >
        <Header userAuthenticated={props.userAuthenticated} selectedAccountRole={props.selectedAccount.role} />

        <Layout.Content>
          <Spin spinning={props.showSpinner} size="large">
            <div className="main-wrapper">
              {!props.userAuthenticated ||
              (props.selectedAccount.role === Role.AGENT && props.enumsLastRefreshTime) ||
              props.selectedAccount.role === Role.CLIENT ? (
                !props.userAuthenticated || props.selectedAccount.role === Role.AGENT ? (
                  <>
                    <BreadcrumbsBox />
                    {agentRoleRoutes}
                  </>
                ) : (
                  clientRoleRoutes
                )
              ) : (
                <>
                  <Skeleton active paragraph={{ rows: 6 }} />
                  <Skeleton active paragraph={{ rows: 3 }} />
                  <Skeleton active paragraph={{ rows: 2 }} />
                </>
              )}
            </div>
          </Spin>
        </Layout.Content>

        <Footer />
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  userAuthenticated: selectIsUserAuthenticated(state),
  selectedAccount: selectSelectedAccount(state),
  showSpinner: selectHasActiveRequest(state),
  enumsLastRefreshTime: selectEnumsLastRefreshTime(state),
  branding: selectBrandingPersistData(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      checkAuthState: checkAuthStateAction,
      refreshEnumerations: refreshEnumerationsAction,
      filterHeaderNotifications: filterHeaderNotificationsActions.request
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(mapStateToProps, mapDispatchToProps)(App);
