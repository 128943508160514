import { Col, Form, InputNumber, Modal, Row, Select } from "antd";
import React, { useEffect } from "react";
import t from "../../../../../app/i18n";
import InputAddon from "../../../../../common/components/form/addons/InputAddon";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import { ModalSizes, rowGutter } from "../../../../../common/constants";
import { UUID } from "../../../../../common/types";
import {
  inputNumberDecimalStandardProps,
  resolveFormValidationError,
  selectStandardProps,
  selectTagsStandardProps,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import InstitutionSelect from "../../../../enumerations/components/form/InstitutionSelect";
import ProductGroupSelect from "../../../../enumerations/components/form/ProductGroupSelect";
import ProductSelect from "../../../../enumerations/components/form/ProductSelect";
import { ProductFinancialSector } from "../../../../product/enums";
import { PRODUCT_SECTOR_TO_INSTITUTION_TYPE_MAP } from "../../../../product/utils";
import CommissionSourceTag from "../../../settings/components/CommissionSourceTag";
import { CommissionSource, commissionSourceTMap } from "../../../settings/enums";
import { requests } from "../../api";
import { createCommissionsLevelRuleActions, updateCommissionsLevelRuleActions } from "../../ducks";
import { CommissionsSettingsLevelRule, CreateUpdateCommissionsSettingsLevelRule } from "../../types";

interface Props {
  open: boolean;
  rule?: CommissionsSettingsLevelRule;
  levelId: UUID;
  onCreate?: typeof createCommissionsLevelRuleActions.request;
  onUpdate?: typeof updateCommissionsLevelRuleActions.request;
  onFormCancel: () => void;
}

const CommissionsLevelRuleForm = ({ open, rule, levelId, onCreate, onUpdate, onFormCancel }: Props) => {
  const colSpan = 8;

  const [form] = Form.useForm<CreateUpdateCommissionsSettingsLevelRule>();
  useFormErrorHandler(form, "commissions.level.attrs.rule", [
    requests.CREATE_COMMISSIONS_LEVEL_RULE,
    requests.UPDATE_COMMISSIONS_LEVEL_RULE
  ]);

  useEffect(() => {
    if (open && rule) {
      form.setFieldsValue({
        ...rule,
        productGroupId: rule.productGroup?.id,
        productGroup: null,
        institutionId: rule.institution?.id,
        institution: null,
        productId: rule.product?.id,
        product: null
      } as CommissionsSettingsLevelRule & CreateUpdateCommissionsSettingsLevelRule);
    }
  }, [rule, open, form]);

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_COMMISSIONS_LEVEL_RULE, requests.UPDATE_COMMISSIONS_LEVEL_RULE],
    onFormCancel
  );

  const handleSectorChange = (): void => {
    form.setFieldsValue({
      productGroupId: null,
      institutionId: null,
      productId: null
    });
  };

  const handleProductGroupIdChange = (): void => {
    form.setFieldsValue({ productId: null });
  };

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (rule) {
          onUpdate?.({ id1: levelId, id2: rule.id, object: values });
        } else {
          onCreate?.({ id: levelId, object: values });
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.LARGE}
      open={open}
      title={rule ? t("commissions.level.titles.updateRule") : t("commissions.level.titles.createRule")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="commissionsSettingsLevelRuleForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item name="sector" label={t("product.enums.financialSector._label")} rules={[validations.notNull]}>
              <Select
                {...selectStandardProps}
                options={Object.keys(ProductFinancialSector).map(sector => ({
                  value: sector,
                  label: t("product.enums.financialSector." + sector)
                }))}
                onChange={handleSectorChange}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item noStyle shouldUpdate={(prev, next) => prev.sector !== next.sector}>
          {({ getFieldValue }) => {
            const sector = getFieldValue("sector");
            return (
              sector !== ProductFinancialSector.ALL && (
                <Row gutter={rowGutter}>
                  <Col span={colSpan}>
                    <ProductGroupSelect
                      formItemProps={{
                        name: "productGroupId",
                        label: t("commissions.level.attrs.rule.productGroupId"),
                        rules: [validations.none]
                      }}
                      selectProps={{
                        allowClear: true,
                        placeholder: !sector ? t("commissions.level.helpers.rule.productGroupPlaceholder") : null,
                        onChange: handleProductGroupIdChange
                      }}
                      optionsProps={{ filterSectors: [sector] }}
                    />
                  </Col>

                  <Form.Item noStyle shouldUpdate={(prev, next) => prev.productGroupId !== next.productGroupId}>
                    {({ getFieldValue }) => {
                      const productGroupId = getFieldValue("productGroupId");
                      return (
                        <>
                          <Col span={colSpan}>
                            <InstitutionSelect
                              formItemProps={{
                                name: "institutionId",
                                label: t("commissions.level.attrs.rule.institutionId"),
                                rules: [validations.none]
                              }}
                              selectProps={{
                                allowClear: true,
                                placeholder: !productGroupId
                                  ? t("commissions.level.helpers.rule.institutionPlaceholder")
                                  : null
                              }}
                              optionsProps={{
                                hideAll: !!!productGroupId,
                                selected: rule?.institution ? [rule.institution] : null,
                                filterType: PRODUCT_SECTOR_TO_INSTITUTION_TYPE_MAP.get(sector)
                              }}
                            />
                          </Col>

                          <Col span={colSpan}>
                            <ProductSelect
                              formItemProps={{
                                name: "productId",
                                label: t("commissions.level.attrs.rule.productId"),
                                rules: [validations.none]
                              }}
                              selectProps={{
                                allowClear: true,
                                placeholder: !productGroupId
                                  ? t("commissions.level.helpers.rule.productPlaceholder")
                                  : null
                              }}
                              optionsProps={{
                                productGroupId,
                                filterSectors: [sector],
                                groupByProductGroup: true,
                                hideAll: !!!productGroupId
                              }}
                            />
                          </Col>
                        </>
                      );
                    }}
                  </Form.Item>
                </Row>
              )
            );
          }}
        </Form.Item>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="source"
              label={t("commissions.settings.enums.commissionSource._label")}
              rules={[validations.notNull]}
            >
              <Select
                {...selectTagsStandardProps(commissionSourceTMap)}
                options={Object.keys(CommissionSource).map(source => ({
                  value: source,
                  label: <CommissionSourceTag source={CommissionSource[source]} />
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="initialCommissionRate"
              label={t("commissions.level.attrs.rule.initialCommissionRate")}
              rules={[validations.notNull, validations.minNumber(0)]}
            >
              <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="percentage" />} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="subsequentCommissionRate"
              label={t("commissions.level.attrs.rule.subsequentCommissionRate")}
              rules={[validations.notNull, validations.minNumber(0)]}
            >
              <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="percentage" />} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CommissionsLevelRuleForm;
