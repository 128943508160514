import { DataNode } from "antd/lib/tree";
import { DocumentNodeType, DocumentNodeTypeKey } from "./enums";
import { DocumentNodeTree, DocumentNodeTreeViewProps, RootDocumentNodeTree } from "./types";

export const getNodeTypeByIndex = (index: DocumentNodeTypeKey) =>
  index === DocumentNodeTypeKey.GENERAL ? DocumentNodeType.GENERAL : DocumentNodeType.PERSONAL;

export const getCurrentSubTree = (
  nodeTree: RootDocumentNodeTree,
  nodeTypeIndex: DocumentNodeTypeKey,
  treePath?: string
): DocumentNodeTreeViewProps => {
  if (!treePath) {
    return {
      nodes: nodeTree[nodeTypeIndex]
    };
  }

  const pathIDs = treePath.split("|");
  let nodes: DocumentNodeTree[] = nodeTree[nodeTypeIndex];
  let parent: DocumentNodeTree;
  const nodesPath: DocumentNodeTree[] = [];

  pathIDs.forEach((id, index) => {
    const node = nodes.find(node => node.id === id);

    if (index === pathIDs.length - 1) {
      parent = node;
    }

    nodesPath.push(node);
    nodes = node.children;
  });

  return { nodes, parent, breadcrumbs: nodesPath };
};

export const getNodeFoldersTree = (documentNodes: DocumentNodeTree[], omittedNodes: DocumentNodeTree[]): DataNode[] => {
  const result: DataNode[] = [];

  documentNodes.forEach(node => {
    if (node.file || omittedNodes?.some(n => n.id === node.id)) {
      return;
    }

    let children = [];

    if (node.children.length) {
      children = getNodeFoldersTree(node.children, omittedNodes);
    }

    result.push({
      key: node.id,
      title: node.name,
      children: children
    });
  });

  return result;
};
