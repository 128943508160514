import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../../../app/i18n";
import ContentWrapper from "../../../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../../../common/types";
import messageUtils from "../../../../../common/utils/messageUtils";
import { ClientFormType } from "../../../../client/enums";
import { Client } from "../../../../client/types";
import { createCalcDraftActions, updateCalcDraftActions } from "../../../drafts/ducks";
import { CreateUpdateCalcDraft } from "../../../drafts/types";
import { CalcType } from "../../../enums";
import CalcClientNameForm from "../../components/CalcClientNameForm";
import CalcLoadingModal from "../../components/CalcLoadingModal";
import { OfferType } from "../../enums";
import { OperationStage } from "../../realty/enums";
import { CalcDataSource, CalcResult, GenResponse } from "../../types";
import TravelCalcWrapper from "../components/calc/TravelCalcWrapper";
import TravelGenWrapper from "../components/gen/TravelGenWrapper";
import {
  calculateTravelActions,
  deleteStateTravelCalcResultsAction,
  deleteStateTravelDraftAction,
  deleteStateTravelGenResultAction,
  deleteStateTravelInitialCalcGenDataAction,
  generateTravelActions,
  generateTravelOfferActions,
  selectTravelCalcResults,
  selectTravelDraft,
  selectTravelGenResult,
  selectTravelInitialCalcData,
  selectTravelInitialGenData
} from "../ducks";
import {
  TravelCalc,
  TravelCalcDraft,
  TravelCalcResultData,
  TravelFormClients,
  TravelGen,
  TravelGenForm
} from "../types";
import {
  createTravelCalcObjectFromCalcData,
  createTravelCalcObjectFromGenData,
  createTravelFormClientsObject,
  createTravelGenFormDataObject
} from "../utils";

interface StateProps {
  initialCalcData: TravelCalc;
  initialGenData: TravelGen;
  draft: TravelCalcDraft;
  calcResults: CalcResult<TravelCalcResultData>[][];
  genResult: GenResponse;
}

interface ActionsMap {
  calculateTravel: typeof calculateTravelActions.request;
  generateTravel: typeof generateTravelActions.request;
  generateTravelOffer: typeof generateTravelOfferActions.request;
  createCalcDraft: typeof createCalcDraftActions.request;
  updateCalcDraft: typeof updateCalcDraftActions.request;
  deleteStateTravelCalcResults: typeof deleteStateTravelCalcResultsAction;
  deleteStateTravelGenResult: typeof deleteStateTravelGenResultAction;
  deleteStateTravelDraft: typeof deleteStateTravelDraftAction;
  deleteStateTravelInitialCalcGenData: typeof deleteStateTravelInitialCalcGenDataAction;
}

interface DraftDataPartsState {
  draftData: TravelGen;
  overwriteExisting: boolean;
}

const TravelCalcContainer = (props: StateProps & ActionProps<ActionsMap>) => {
  const [resetKey, setResetKey] = useState<number>(0);
  const [genStepActive, setGenStepActive] = useState<boolean>(false);
  const [selectedResult, setSelectedResult] = useState<CalcResult<TravelCalcResultData>>();
  const [clientNameFormType, setClientNameFormType] = useState<"offer" | "draft">();
  const [draftDataParts, setDraftDataParts] = useState<DraftDataPartsState>();

  const [clients, setClients] = useState<TravelFormClients>(
    props.initialGenData
      ? createTravelFormClientsObject(props.initialGenData)
      : props.draft?.stage === OperationStage.GENERATE
        ? createTravelFormClientsObject(props.draft.draftData)
        : {
            policyHolder: null,
            representative: null
          }
  );

  const [calcDataSource, setCalcDataSource] = useState<CalcDataSource>(
    props.initialCalcData
      ? "calcData"
      : props.initialGenData
        ? "genData"
        : props.draft?.stage === OperationStage.CALCULATE
          ? "calcDraft"
          : props.draft?.stage === OperationStage.GENERATE
            ? "genDraft"
            : "init"
  );

  const [calcData, setCalcData] = useState<TravelCalc>(
    props.initialCalcData
      ? createTravelCalcObjectFromCalcData(props.initialCalcData)
      : props.initialGenData
        ? createTravelCalcObjectFromGenData(props.initialGenData)
        : props.draft
          ? createTravelCalcObjectFromGenData(props.draft.draftData)
          : null
  );

  const [genData, setGenData] = useState<TravelGenForm>(
    props.initialGenData
      ? createTravelGenFormDataObject(props.initialGenData, clients)
      : props.draft?.stage === OperationStage.GENERATE
        ? createTravelGenFormDataObject(props.draft.draftData, clients)
        : null
  );

  useEffect(() => {
    if (props.draft) {
      messageUtils.infoNotification(t("calc.draft.helpers.draftLoaded"), t("calc.draft.helpers.draftLoadedInfo"), 10);
    }
    if (props.initialCalcData || props.initialGenData) {
      props.actions.deleteStateTravelInitialCalcGenData();
      messageUtils.infoNotification(
        t("calc.records.helpers.calcDataInitTitle"),
        t("calc.records.helpers.calcDataInit"),
        10
      );
    }
    return () => {
      props.actions.deleteStateTravelCalcResults();
      props.actions.deleteStateTravelGenResult();
      props.actions.deleteStateTravelDraft();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCalculationFormSubmit = (calcData: TravelCalc): void => {
    setCalcData(calcData);
    props.actions.calculateTravel(calcData);
  };

  const handleClientChange = (client: Client, type: ClientFormType): TravelFormClients => {
    let updatedClients: TravelFormClients;
    switch (type) {
      case ClientFormType.POLICY_HOLDER:
        updatedClients = { ...clients, policyHolder: client };
        break;
      case ClientFormType.REPRESENTATIVE:
        updatedClients = { ...clients, representative: client };
        break;
      default:
        updatedClients = { ...clients };
        break;
    }
    setClients(updatedClients);
    return updatedClients;
  };

  const handleResetCalculatorClick = (): void => {
    Modal.confirm({
      title: t("calc.helpers.resetCalculatorSubmit"),
      okText: t("common.yes"),
      cancelText: t("common.back"),
      onOk: () => {
        setResetKey(resetKey + 1);
        setClients({ policyHolder: null, representative: null });
        setCalcDataSource("init");
        setCalcData(null);
        setGenData(null);
        setSelectedResult(null);
        setDraftDataParts(null);

        props.actions.deleteStateTravelCalcResults();
        props.actions.deleteStateTravelDraft();
      }
    });
  };

  const handleCalcDataSourceReset = (): void => {
    setCalcDataSource("init");
  };

  const handleGenerateContractClick = (
    calcData: TravelCalc,
    selectedResult: CalcResult<TravelCalcResultData>
  ): void => {
    setGenStepActive(true);
    setCalcData(calcData);
    setSelectedResult(selectedResult);
  };

  const handleCalcFormGenerateOfferClick = (calcData: TravelCalc): void => {
    setCalcData(calcData);
    setClientNameFormType("offer");
  };

  const handleGenFormGenerateOfferClick = (): void => {
    if (clients.policyHolder) {
      processOfferGeneration(clients.policyHolder.aggregatedName, calcData);
    } else {
      setClientNameFormType("offer");
    }
  };

  const processOfferGeneration = (clientName: string, calcData: TravelCalc): void => {
    props.actions.generateTravelOffer({
      type: OfferType.TRAVEL,
      calcRequest: calcData,
      calcResponse: { results: props.calcResults.flat() },
      clientName
    });
  };

  const handleCalcFormSaveDraftClick = (calcData: TravelCalc, overwriteExisting: boolean): void => {
    setDraftDataParts({ draftData: calcData as TravelGen, overwriteExisting });
    setClientNameFormType("draft");
  };

  const handleGenFormSaveDraftClick = (genData: TravelGen, overwriteExisting: boolean): void => {
    const draftDataParts: DraftDataPartsState = { draftData: genData, overwriteExisting };
    if (clients.policyHolder) {
      processDraftCreateOrUpdate(clients.policyHolder.aggregatedName, draftDataParts);
    } else {
      setDraftDataParts(draftDataParts);
      setClientNameFormType("draft");
    }
  };

  const processDraftCreateOrUpdate = (clientName: string, draftDataParts: DraftDataPartsState): void => {
    const draft: CreateUpdateCalcDraft = {
      draftData: draftDataParts.draftData,
      calcResponse: { results: props.calcResults.flat() },
      clientName,
      calcType: CalcType.TRAVEL,
      stage: genStepActive ? OperationStage.GENERATE : OperationStage.CALCULATE
    };

    if (props.draft && draftDataParts.overwriteExisting) {
      props.actions.updateCalcDraft({
        id: props.draft.id,
        object: { ...draft, optimisticLockVersion: props.draft.optimisticLockVersion }
      });
    } else {
      props.actions.createCalcDraft(draft);
    }
  };

  const handleReturnToCalculationClick = (genData: TravelGenForm): void => {
    setGenStepActive(false);
    setCalcDataSource("calcData");
    setGenData(genData);
    props.actions.deleteStateTravelGenResult();
  };

  const handleSelectedResultChange = (selectedResult: CalcResult<TravelCalcResultData>): void => {
    setSelectedResult(selectedResult);
  };

  const handleClientNameModalOkClick = (clientName: string): void => {
    if (clientNameFormType === "offer") {
      processOfferGeneration(clientName, calcData);
    } else {
      processDraftCreateOrUpdate(clientName, draftDataParts);
    }
    setClientNameFormType(null);
    setDraftDataParts(null);
  };

  const handleClientNameModalCancelClick = (): void => {
    setClientNameFormType(null);
    setDraftDataParts(null);
  };

  return (
    <ContentWrapper>
      {genStepActive ? (
        <TravelGenWrapper
          initialData={genData}
          genResult={props.genResult}
          calcData={calcData}
          clients={clients}
          calcResults={props.calcResults}
          selectedResult={selectedResult}
          draftId={props.draft?.id}
          onGenerateFormSubmit={props.actions.generateTravel}
          onGenResultDelete={props.actions.deleteStateTravelGenResult}
          onClientChange={handleClientChange}
          onGenerateOfferClick={handleGenFormGenerateOfferClick}
          onSaveDraftClick={handleGenFormSaveDraftClick}
          onSelectedResultChange={handleSelectedResultChange}
          onReturnToCalculationClick={handleReturnToCalculationClick}
        />
      ) : (
        <TravelCalcWrapper
          key={resetKey}
          calcData={calcData}
          calcDataSource={calcDataSource}
          calcResults={props.calcResults}
          onCalcResultsDelete={props.actions.deleteStateTravelCalcResults}
          onCalculationFormSubmit={handleCalculationFormSubmit}
          onGenerateContractClick={handleGenerateContractClick}
          onGenerateOfferClick={handleCalcFormGenerateOfferClick}
          onSaveDraftClick={handleCalcFormSaveDraftClick}
          onResetCalculatorClick={handleResetCalculatorClick}
          onCalcDataSourceReset={handleCalcDataSourceReset}
        />
      )}

      <CalcClientNameForm
        openAsOffer={clientNameFormType === "offer"}
        openAsDraft={clientNameFormType === "draft"}
        initialName={props.draft?.clientName}
        onOkClick={handleClientNameModalOkClick}
        onCancelClick={handleClientNameModalCancelClick}
      />

      <CalcLoadingModal />
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  initialCalcData: selectTravelInitialCalcData(state),
  initialGenData: selectTravelInitialGenData(state),
  draft: selectTravelDraft(state),
  calcResults: selectTravelCalcResults(state),
  genResult: selectTravelGenResult(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      calculateTravel: calculateTravelActions.request,
      generateTravel: generateTravelActions.request,
      generateTravelOffer: generateTravelOfferActions.request,
      createCalcDraft: createCalcDraftActions.request,
      updateCalcDraft: updateCalcDraftActions.request,
      deleteStateTravelCalcResults: deleteStateTravelCalcResultsAction,
      deleteStateTravelGenResult: deleteStateTravelGenResultAction,
      deleteStateTravelDraft: deleteStateTravelDraftAction,
      deleteStateTravelInitialCalcGenData: deleteStateTravelInitialCalcGenDataAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(TravelCalcContainer);
