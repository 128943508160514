import { Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../common/types";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import { LifeInsuranceTariffGroup } from "../../../lifeinsurancetariff/types";
import { selectTariffGroupsEnums } from "../../ducks";

interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
}

const LifeInsuranceTariffGroupSelect = ({ formItemProps, selectProps }: Props) => {
  const tariffGroupsEnums = useSelector<RootState, LifeInsuranceTariffGroup[]>(selectTariffGroupsEnums);

  return (
    <Form.Item {...formItemProps}>
      <Select
        {...selectStandardProps}
        {...selectProps}
        options={tariffGroupsEnums.map(group => ({ value: group.id, label: group.name }))}
      />
    </Form.Item>
  );
};

export default LifeInsuranceTariffGroupSelect;
