import t from "../../app/i18n";

export enum InstitutionType {
  INSURANCE_COMPANY = "INSURANCE_COMPANY",
  BANK = "BANK",
  SECURITIES_BROKER = "SECURITIES_BROKER",
  BUILDING_SAVINGS_BANK = "BUILDING_SAVINGS_BANK",
  MANAGERIAL_PENSION_COMPANY = "MANAGERIAL_PENSION_COMPANY",
  SUPPLEMENTARY_PENSION_COMPANY = "SUPPLEMENTARY_PENSION_COMPANY",
  OTHER = "OTHER"
}

export enum InstitutionEnum {
  AGRA = "AGRA",
  ALLIANZ = "ALLIANZ",
  AXA_ASSISTANCE = "AXA_ASSISTANCE",
  AXA_NON_LIFE = "AXA_NON_LIFE",
  AXA_LIFE = "AXA_LIFE",
  COFACE = "COFACE",
  COLONNADE = "COLONNADE",
  CSOB = "CSOB",
  ECP = "ECP",
  EULER = "EULER",
  DEFEND = "DEFEND",
  GENERALI = "GENERALI",
  GENERTEL = "GENERTEL",
  GROUPAMA = "GROUPAMA",
  HDI = "HDI",
  KOMUNALNA = "KOMUNALNA",
  KOOPERATIVA = "KOOPERATIVA",
  MET_LIFE = "MET_LIFE",
  MET_LIFE_INSURANCE = "MET_LIFE_INSURANCE",
  MSIG = "MSIG",
  NN = "NN",
  PREMIUM = "PREMIUM",
  UNION = "UNION",
  UNIQA = "UNIQA",
  WUSTENROT = "WUSTENROT",
  YOUPLUS = "YOUPLUS",
  BANK_365 = "BANK_365",
  CSOB_BANK = "CSOB_BANK",
  MBANK = "MBANK",
  OBERBANK = "OBERBANK",
  OTP = "OTP",
  PRIMA = "PRIMA",
  PSS = "PSS",
  SLSP = "SLSP",
  TATRA = "TATRA",
  UNI_CREDIT = "UNI_CREDIT",
  VUB = "VUB",
  EIC = "EIC",
  WEM = "WEM",
  UNIQA_INVEST = "UNIQA_INVEST",
  IAD = "IAD",
  FINAX = "FINAX",
  ALLIANZ_DSS = "ALLIANZ_DSS",
  KOOPERATIVA_DSS = "KOOPERATIVA_DSS",
  NN_DSS = "NN_DSS",
  UNIQA_DSS = "UNIQA_DSS",
  VUB_GENERALI_DSS = "VUB_GENERALI_DSS",
  NN_DDS = "NN_DDS",
  UNIQA_DDS = "UNIQA_DDS"
}

export const institutionTypeTMap = new Map<InstitutionType, string>(
  Object.values(InstitutionType).map(type => [type, t("institution.enums.institutionType." + type)])
);
