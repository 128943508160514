import { Col, DatePicker, Form, Modal, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import { RootState } from "../../../../common/types";
import {
  datePickerClearableProps,
  mapInstitutionTreeSelectValuesToInstitutionIds,
  mapProductTreeSelectValuesToProductIds,
  resolveFormValidationError,
  useFormErrorHandler
} from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import AgentSelect from "../../../enumerations/components/form/AgentSelect";
import InstitutionTreeSelect from "../../../enumerations/components/form/InstitutionTreeSelect";
import ProductTreeSelect from "../../../enumerations/components/form/ProductTreeSelect";
import { selectInstitutionsEnums, selectProductGroupsEnums } from "../../../enumerations/ducks";
import { InstitutionWithSettings, ProductGroupWithProducts } from "../../../enumerations/types";
import { requests } from "../../api";
import { recalculatePredictedCommissionsActions } from "../../ducks";
import { RecalculatePredictedCommissionsRequest } from "../../types";

interface Props {
  open: boolean;
  onSubmit: typeof recalculatePredictedCommissionsActions.request;
  onFormCancel: () => void;
}

const RecalculatePredictedCommissionsForm = ({ open, onSubmit, onFormCancel }: Props) => {
  const [form] = Form.useForm<RecalculatePredictedCommissionsRequest>();
  useFormErrorHandler(form, "contract.attrs.predictedCommissions", [requests.RECALCULATE_PREDICTED_COMMISSIONS]);

  const institutionsEnums = useSelector<RootState, InstitutionWithSettings[]>(selectInstitutionsEnums);
  const productGroupsEnums = useSelector<RootState, ProductGroupWithProducts[]>(selectProductGroupsEnums);

  const inProgress = useRequestFinishedCallback([requests.RECALCULATE_PREDICTED_COMMISSIONS], onFormCancel);

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values =>
        onSubmit({
          ...values,
          institutionIds: mapInstitutionTreeSelectValuesToInstitutionIds(values.institutionIds, institutionsEnums),
          productIds: mapProductTreeSelectValuesToProductIds(values.productIds, productGroupsEnums)
        })
      )
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={t("contract.helpers.predictedCommissionsRecalculation")}
      cancelText={t("common.cancel")}
      okText={t("common.recalculate")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="recalculatePredictedCommissionsForm">
        <Row gutter={rowGutter}>
          <Col span={12}>
            <InstitutionTreeSelect
              formItemProps={{
                name: "institutionIds",
                label: t("contract.attrs.predictedCommissions.institutionIds")
              }}
            />
          </Col>

          <Col span={12}>
            <ProductTreeSelect
              formItemProps={{
                name: "productIds",
                label: t("contract.attrs.predictedCommissions.productIds")
              }}
            />
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={24}>
            <AgentSelect
              formItemProps={{
                name: "gainerIds",
                label: t("contract.attrs.predictedCommissions.gainerIds")
              }}
              selectProps={{ mode: "multiple", maxTagCount: "responsive", allowClear: true }}
            />
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={12}>
            <Form.Item
              name="createdDateMin"
              label={t("contract.attrs.predictedCommissions.createdDateMin")}
              rules={[validations.none]}
            >
              <DatePicker {...datePickerClearableProps} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="createdDateMax"
              label={t("contract.attrs.predictedCommissions.createdDateMax")}
              rules={[validations.none]}
            >
              <DatePicker {...datePickerClearableProps} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default RecalculatePredictedCommissionsForm;
