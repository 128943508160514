import { Col, Row } from "antd";
import React from "react";
import { generatePath, Link } from "react-router-dom";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { LinkTargetType } from "../../../../common/types";
import { CLIENT_ROUTE_PATHS } from "../../paths";
import { ClientBase } from "../../types";
import ClientTypeTag from "./ClientTypeTag";

interface Props {
  client: ClientBase;
  ellipsis?: boolean;
  showLink?: boolean;
  showLinkColor?: boolean;
  target?: LinkTargetType;
}

const ClientTypeTagWithName = ({ client, ellipsis, showLink, showLinkColor, target }: Props) =>
  client ? (
    <Row gutter={0}>
      <Col>
        <ClientTypeTag type={client.type} style={{ fontWeight: "normal", width: "38px", textAlign: "center" }} />
      </Col>
      {showLink && client.id ? (
        <Col flex="1 1 0" style={{ minWidth: 0 }}>
          <Ellipsis tooltip={client.aggregatedName} shouldRender={!!ellipsis}>
            <Link
              className={showLinkColor ? "" : "no-link-color"}
              to={generatePath(CLIENT_ROUTE_PATHS.detail.to, { id: client.id })}
              target={target}
            >
              {client.aggregatedName}
            </Link>
          </Ellipsis>
        </Col>
      ) : (
        <Col flex="1 1 0" style={{ minWidth: 0 }}>
          <Ellipsis shouldRender={!!ellipsis}>{client.aggregatedName}</Ellipsis>
        </Col>
      )}
    </Row>
  ) : null;

export default ClientTypeTagWithName;
