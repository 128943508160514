import { Tag } from "antd";
import React from "react";
import t from "../../../app/i18n";
import { FinancialMediationVersion } from "../enums";

interface Props {
  version: FinancialMediationVersion;
  style?: React.CSSProperties;
}

const FinancialMediationVersionTag = ({ version, style }: Props) => (
  <Tag color="cyan" style={style}>
    {t("financialMediation.enums.version." + version)}
  </Tag>
);

export default FinancialMediationVersionTag;
