import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { PageSizes } from "../../../../common/constants";
import { ActionProps, RootState } from "../../../../common/types";
import { appendSearchParamsToURL, numberOrZero } from "../../../../common/utils/utils";
import { selectRouterLocationSearch } from "../../../ducks";
import CommissionForm from "../../batch/components/forms/CommissionForm";
import { PostponementReason } from "../../batch/enums";
import { Commission } from "../../batch/types";
import PostponedCommissionsFilterView from "../components/views/PostponedCommissionsFilterView";
import PostponedCommissionsTableView from "../components/views/PostponedCommissionsTableView";
import {
  deleteStatePostponedCommissionsPageAction,
  filterPostponedCommissionsActions,
  resolvePostponedCommissionActions,
  selectPostponedCommissionsPage,
  unresolvePostponedCommissionActions,
  updatePostponedCommissionActions
} from "../ducks";
import { PostponedCommissionsReport } from "../enums";
import { PostponedCommissionsFilterPageRequest, PostponedCommissionsFilterPageResult } from "../types";

interface StateProps {
  urlSearchQuery: string;
  postponedCommissionsPage: PostponedCommissionsFilterPageResult;
}

interface ActionsMap {
  filterPostponedCommissions: typeof filterPostponedCommissionsActions.request;
  updatePostponedCommission: typeof updatePostponedCommissionActions.request;
  resolvePostponedCommission: typeof resolvePostponedCommissionActions.request;
  unresolvePostponedCommission: typeof unresolvePostponedCommissionActions.request;
  deleteStatePostponedCommissionsPage: typeof deleteStatePostponedCommissionsPageAction;
}

const PostponedCommissionListContainer = (props: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();

  const [commissionToUpdate, setCommissionToUpdate] = useState<Commission>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(props.urlSearchQuery);
    props.actions.filterPostponedCommissions({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.LARGE,
      keyword: urlParams.get("keyword"),
      report: PostponedCommissionsReport[urlParams.get("report")],
      postponementReasons: urlParams.getAll("postponementReasons").map(reason => PostponementReason[reason]),
      institutionIds: urlParams.getAll("institutionIds")
    });

    return () => {
      props.actions.deleteStatePostponedCommissionsPage();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleReportSwitch = (filter: PostponedCommissionsFilterPageRequest): void => {
    const params = {
      keyword: filter.keyword || null,
      report: filter.report !== PostponedCommissionsReport.UNRESOLVED ? filter.report : null,
      postponementReasons: filter.postponementReasons,
      institutionIds: filter.institutionIds
    } as PostponedCommissionsFilterPageRequest;

    navigate(appendSearchParamsToURL({ ...params, pageIndex: null }), { replace: true });

    props.actions.filterPostponedCommissions({
      ...params,
      pageIndex: 0,
      pageSize: props.postponedCommissionsPage.pageSize
    });
  };

  const handleFilterSubmit = (filter: PostponedCommissionsFilterPageRequest): void => {
    const params = {
      keyword: filter.keyword || null,
      report:
        props.postponedCommissionsPage.report !== PostponedCommissionsReport.UNRESOLVED
          ? props.postponedCommissionsPage.report
          : null,
      postponementReasons: filter.postponementReasons,
      institutionIds: filter.institutionIds
    } as PostponedCommissionsFilterPageRequest;

    navigate(appendSearchParamsToURL({ ...params, pageIndex: null }), { replace: true });
    props.actions.filterPostponedCommissions({
      ...params,
      pageIndex: 0,
      pageSize: props.postponedCommissionsPage.pageSize
    });
  };

  const handlePageChange = (pageNumber: number): void => {
    const { pageSize, keyword, report, postponementReasons, institutionIds } = props.postponedCommissionsPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    props.actions.filterPostponedCommissions({
      pageIndex: pageNumber - 1,
      pageSize,
      keyword,
      report,
      postponementReasons,
      institutionIds
    });
  };

  return (
    <>
      <PostponedCommissionsFilterView
        filter={props.postponedCommissionsPage}
        onReportSwitch={handleReportSwitch}
        onFilterSubmit={handleFilterSubmit}
      />

      <PostponedCommissionsTableView
        page={props.postponedCommissionsPage}
        onPageChange={handlePageChange}
        onUpdateClick={commission => setCommissionToUpdate(commission)}
        onResolve={props.actions.resolvePostponedCommission}
        onUnresolve={props.actions.unresolvePostponedCommission}
      />

      <CommissionForm
        open={!!commissionToUpdate}
        formType="postponedCommission"
        commission={commissionToUpdate}
        onUpdatePostponed={props.actions.updatePostponedCommission}
        onFormCancel={() => setCommissionToUpdate(null)}
      />
    </>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  urlSearchQuery: selectRouterLocationSearch(state),
  postponedCommissionsPage: selectPostponedCommissionsPage(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterPostponedCommissions: filterPostponedCommissionsActions.request,
      updatePostponedCommission: updatePostponedCommissionActions.request,
      resolvePostponedCommission: resolvePostponedCommissionActions.request,
      unresolvePostponedCommission: unresolvePostponedCommissionActions.request,
      deleteStatePostponedCommissionsPage: deleteStatePostponedCommissionsPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(PostponedCommissionListContainer);
