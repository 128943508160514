import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { EntityIdObject, EntityObject, NormalizedError, RootState } from "../../../common/types";
import { initSearchPageResult } from "../../../common/utils/apiUtils";
import messageUtils from "../../../common/utils/messageUtils";
import { replaceInArray } from "../../../common/utils/utils";
import { changeRunningRequestKeyAction } from "../../ducks";
import api from "./api";
import {
  CertificateFuelType,
  CertificateFuelTypeFilterPageRequest,
  CertificateFuelTypeFilterPageResult,
  CreateUpdateCertificateFuelType,
  VehicleFuelTypeReducerState
} from "./types";

/**
 * ACTIONS
 */
export const filterVehicleFuelTypesActions = createAsyncAction(
  "vehicle-fuel-type/FILTER_REQUEST",
  "vehicle-fuel-type/FILTER_SUCCESS",
  "vehicle-fuel-type/FILTER_FAILURE"
)<CertificateFuelTypeFilterPageRequest, CertificateFuelTypeFilterPageResult, NormalizedError>();

export const createVehicleFuelTypeActions = createAsyncAction(
  "vehicle-fuel-type/CREATE_REQUEST",
  "vehicle-fuel-type/CREATE_SUCCESS",
  "vehicle-fuel-type/CREATE_FAILURE"
)<CreateUpdateCertificateFuelType, CertificateFuelType, NormalizedError>();

export const updateVehicleFuelTypeActions = createAsyncAction(
  "vehicle-fuel-type/UPDATE_REQUEST",
  "vehicle-fuel-type/UPDATE_SUCCESS",
  "vehicle-fuel-type/UPDATE_FAILURE"
)<EntityObject<CreateUpdateCertificateFuelType>, CertificateFuelType, NormalizedError>();

export const deleteVehicleFuelTypeActions = createAsyncAction(
  "vehicle-fuel-type/DELETE_REQUEST",
  "vehicle-fuel-type/DELETE_SUCCESS",
  "vehicle-fuel-type/DELETE_FAILURE"
)<EntityIdObject, void, NormalizedError>();

export const deleteStateVehicleFuelTypesPageAction = createAction("vehicle-fuel-type/DELETE_STATE_LIST")<void>();

const actions = {
  filterVehicleFuelTypesActions,
  createVehicleFuelTypeActions,
  updateVehicleFuelTypeActions,
  deleteVehicleFuelTypeActions,
  deleteStateVehicleFuelTypesPageAction
};

export type VehicleFuelTypeAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: VehicleFuelTypeReducerState = {
  currentPage: {
    ...initSearchPageResult<CertificateFuelType>(),
    fuelTypes: [],
    fuelNameSources: []
  }
};

const currentPageReducer = createReducer(initialState.currentPage)
  .handleAction(filterVehicleFuelTypesActions.success, (_, { payload }) => payload)
  .handleAction(updateVehicleFuelTypeActions.success, (state, { payload }) => ({
    ...state,
    pageData: replaceInArray(
      state.pageData,
      item => item.id === payload.id,
      () => payload
    )
  }))
  .handleAction(
    [filterVehicleFuelTypesActions.failure, deleteStateVehicleFuelTypesPageAction],
    () => initialState.currentPage
  );

export const vehicleFuelTypeReducer = combineReducers<VehicleFuelTypeReducerState>({
  currentPage: currentPageReducer
});

/**
 * SELECTORS
 */
const selectVehicleFuelType = (state: RootState): VehicleFuelTypeReducerState => state.vehicle.fuelType;

export const selectVehicleFuelTypesCurrentPage = (state: RootState): CertificateFuelTypeFilterPageResult =>
  selectVehicleFuelType(state).currentPage;

/**
 * SAGAS
 */
function* filterVehicleFuelTypes({ payload }: ReturnType<typeof filterVehicleFuelTypesActions.request>) {
  try {
    const response: AxiosResponse<CertificateFuelTypeFilterPageResult> = yield call(
      api.filterVehicleFuelTypes,
      payload
    );
    yield put(filterVehicleFuelTypesActions.success(response.data));
  } catch (error) {
    yield put(filterVehicleFuelTypesActions.failure(error));
  }
}

function* createVehicleFuelType({ payload }: ReturnType<typeof createVehicleFuelTypeActions.request>) {
  try {
    const response: AxiosResponse<CertificateFuelType> = yield call(api.createVehicleFuelType, payload);
    yield put(createVehicleFuelTypeActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();
    yield* refreshCurrentVehicleFuelTypePage();
  } catch (error) {
    yield put(createVehicleFuelTypeActions.failure(error));
  }
}

function* updateVehicleFuelType({ payload }: ReturnType<typeof updateVehicleFuelTypeActions.request>) {
  try {
    const response: AxiosResponse<CertificateFuelType> = yield call(api.updateVehicleFuelType, payload);
    yield put(updateVehicleFuelTypeActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(updateVehicleFuelTypeActions.failure(error));
  }
}

function* deleteVehicleFuelType({ payload }: ReturnType<typeof deleteVehicleFuelTypeActions.request>) {
  try {
    yield call(api.deleteVehicleFuelType, payload);
    yield put(deleteVehicleFuelTypeActions.success());
    messageUtils.itemDeletedNotification();
    yield* refreshCurrentVehicleFuelTypePage(true);
  } catch (error) {
    yield put(deleteVehicleFuelTypeActions.failure(error));
  }
}

function* refreshCurrentVehicleFuelTypePage(movePage?: boolean) {
  const currentPage: CertificateFuelTypeFilterPageResult = yield select(selectVehicleFuelTypesCurrentPage);
  yield put(
    filterVehicleFuelTypesActions.request({
      pageIndex: movePage
        ? currentPage.pageElementsCount === 1
          ? Math.max(currentPage.pageIndex - 1, 0)
          : currentPage.pageIndex
        : currentPage.pageIndex,
      pageSize: currentPage.pageSize,
      keyword: currentPage.keyword,
      fuelTypes: currentPage.fuelTypes,
      fuelNameSources: currentPage.fuelNameSources
    })
  );
}

export function* vehicleFuelTypeSaga() {
  yield takeLatest(filterVehicleFuelTypesActions.request, filterVehicleFuelTypes);
  yield takeLatest(createVehicleFuelTypeActions.request, createVehicleFuelType);
  yield takeLatest(updateVehicleFuelTypeActions.request, updateVehicleFuelType);
  yield takeLatest(deleteVehicleFuelTypeActions.request, deleteVehicleFuelType);
}
