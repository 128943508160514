import { Card, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import AntIcon from "../../../common/components/icons/AntIcon";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";
import { Permission } from "../../../common/security/authorization/enums";
import { ActionProps, RootState } from "../../../common/types";
import { formatAgentAggregatedName } from "../../../common/utils/formatUtils";
import { appendSearchParamsToURL } from "../../../common/utils/utils";
import AgentAttachmentsTableView from "../../agent/components/views/detail/AgentAttachmentsTableView";
import AgentBrokerNumberTableView from "../../agent/components/views/detail/AgentBrokerNumberTableView";
import AgentCompetenceTableView from "../../agent/components/views/detail/AgentCompetenceTableView";
import AgentLicenseTableView from "../../agent/components/views/detail/AgentLicenseTableView";
import {
  deleteStateAgentDetailAction,
  downloadAgentAttachmentsAsZipActions,
  getAgentBrokerNumbersActions,
  getAgentCompetencesActions,
  getAgentLicensesActions,
  selectAgentBrokerNumbers,
  selectAgentCompetences,
  selectAgentLicenses,
  uploadAgentAttachmentsActions
} from "../../agent/ducks";
import { AgentBrokerNumber, AgentCompetence, AgentLicense } from "../../agent/types";
import { selectHasPermissions, selectUser } from "../../auth/ducks";
import { selectRouterLocationSearchParam } from "../../ducks";
import NotificationSettingsView from "../../notifications/components/views/NotificationSettingsView";
import {
  deleteStateNotificationSettingsListAction,
  getNotificationSettingsActions,
  selectNotificationSettings,
  updateNotificationSettingsActions
} from "../../notifications/ducks";
import { NotificationSettings } from "../../notifications/types";
import { requests } from "../api";
import UserProfileAgentTabSwitchView from "../components/views/detail/UserProfileAgentTabSwitchView";
import UserProfileUserAccountView from "../components/views/detail/UserProfileUserAccountView";
import UserProfileUserView from "../components/views/detail/UserProfileUserView";
import {
  deleteStateUserProfileAction,
  deleteStateUserTotpDevicesAction,
  deleteStateUserTotpDeviceSecretAction,
  selectUserProfile,
  selectUserTotpDevices,
  selectUserTotpDeviceSecret,
  userCreateUserTotpDeviceActions,
  userCreateUserTotpDeviceSecretActions,
  userDeleteUserTotpDeviceActions,
  userGetUserProfileActions,
  userGetUserTotpDevicesActions,
  userRequestEmailUpdateActions,
  userRequestPhoneUpdateActions,
  userUpdatePasswordActions,
  userUpdatePhoneActions,
  userUpdateUserTotpDeviceActions
} from "../ducks";
import { User, UserProfile, UserTotpDevice, UserTotpDeviceSecret } from "../types";

interface StateProps {
  user: User;
  userProfile: UserProfile;
  totpDeviceSecret: UserTotpDeviceSecret;
  totpDevices: UserTotpDevice[];
  competences: AgentCompetence[];
  licenses: AgentLicense[];
  brokerNumbers: AgentBrokerNumber[];
  notificationSettings: NotificationSettings[];
  hasProfileCompetencePermission: boolean;
  hasProfileLicensePermission: boolean;
  hasProfileBrokerNumberPermission: boolean;
  urlTabKey: string;
  openTotpDeviceFormByDefault: boolean;
}

interface ActionsMap {
  userGetUserProfile: typeof userGetUserProfileActions.request;
  userRequestEmailUpdate: typeof userRequestEmailUpdateActions.request;
  userRequestPhoneUpdate: typeof userRequestPhoneUpdateActions.request;
  userUpdatePhone: typeof userUpdatePhoneActions.request;
  userUpdatePassword: typeof userUpdatePasswordActions.request;
  deleteStateUserProfile: typeof deleteStateUserProfileAction;
  userGetUserTotpDevices: typeof userGetUserTotpDevicesActions.request;
  userCreateUserTotpDeviceSecret: typeof userCreateUserTotpDeviceSecretActions.request;
  userCreateUserTotpDevice: typeof userCreateUserTotpDeviceActions.request;
  userUpdateUserTotpDevice: typeof userUpdateUserTotpDeviceActions.request;
  userDeleteUserTotpDevice: typeof userDeleteUserTotpDeviceActions.request;
  deleteStateUserTotpDeviceSecret: typeof deleteStateUserTotpDeviceSecretAction;
  deleteStateUserTotpDevices: typeof deleteStateUserTotpDevicesAction;
  downloadAgentAttachmentsAsZip: typeof downloadAgentAttachmentsAsZipActions.request;
  uploadAgentAttachments: typeof uploadAgentAttachmentsActions.request;
  getAgentCompetences: typeof getAgentCompetencesActions.request;
  getAgentLicenses: typeof getAgentLicensesActions.request;
  getAgentBrokerNumbers: typeof getAgentBrokerNumbersActions.request;
  deleteStateAgentDetail: typeof deleteStateAgentDetailAction;
  getNotificationSettings: typeof getNotificationSettingsActions.request;
  updateNotificationSettings: typeof updateNotificationSettingsActions.request;
  deleteStateNotificationSettingsList: typeof deleteStateNotificationSettingsListAction;
}

type Props = StateProps & ActionProps<ActionsMap>;

export const TAB = {
  USER: "user",
  ACCOUNT: "account",
  ATTACHMENTS: "attachments",
  COMPETENCES: "competences",
  LICENSES: "licenses",
  BROKER_NUMBERS: "broker-numbers",
  NOTIFICATION_SETTINGS: "notification-settings"
};

const UserProfileContainer = ({
  user,
  userProfile,
  totpDeviceSecret,
  totpDevices,
  competences,
  licenses,
  brokerNumbers,
  notificationSettings,
  hasProfileCompetencePermission,
  hasProfileLicensePermission,
  hasProfileBrokerNumberPermission,
  urlTabKey,
  openTotpDeviceFormByDefault,
  actions
}: Props) => {
  const navigate = useNavigate();

  const [tabKey, setTabKey] = useState<string>(TAB.USER);

  const checkAndSetTabKey = (): void => {
    switch (urlTabKey) {
      case TAB.USER:
      case TAB.ACCOUNT:
      case TAB.ATTACHMENTS:
      case TAB.NOTIFICATION_SETTINGS:
        setTabKey(urlTabKey);
        break;
      case TAB.COMPETENCES:
        setTabKey(hasProfileCompetencePermission ? urlTabKey : TAB.USER);
        break;
      case TAB.LICENSES:
        setTabKey(hasProfileLicensePermission ? urlTabKey : TAB.USER);
        break;
      case TAB.BROKER_NUMBERS:
        setTabKey(hasProfileBrokerNumberPermission ? urlTabKey : TAB.USER);
        break;
      default:
        setTabKey(TAB.USER);
        break;
    }
  };

  useEffect(() => {
    actions.userGetUserProfile({ id: user.id });
    actions.userGetUserTotpDevices({ id: user.id });
    actions.getNotificationSettings();

    checkAndSetTabKey();

    return () => {
      actions.deleteStateUserProfile();
      actions.deleteStateUserTotpDevices();
      actions.deleteStateAgentDetail();
      actions.deleteStateNotificationSettingsList();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (urlTabKey !== tabKey) {
      checkAndSetTabKey();
    }
  }, [urlTabKey]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabKeyChange = (tabKey: string): void => {
    setTabKey(tabKey);
    navigate(appendSearchParamsToURL({ tab: tabKey }), { replace: true });
  };

  return (
    <ContentWrapper>
      <Card>
        <DisplayWrapper itemLoaded={!!userProfile} notFoundCheckRequest={requests.USER_GET_USER_PROFILE}>
          {userProfile && (
            <Tabs
              className="tabs-left-menu"
              tabPosition="left"
              activeKey={tabKey}
              onChange={handleTabKeyChange}
              items={[
                {
                  key: TAB.USER,
                  label: (
                    <span>
                      <AntIcon type="user" />
                      {t("user.titles.userData")}
                    </span>
                  ),
                  children: (
                    <UserProfileUserView
                      user={userProfile}
                      totpDeviceSecret={totpDeviceSecret}
                      totpDevices={totpDevices}
                      openTotpDeviceFormByDefault={openTotpDeviceFormByDefault}
                      userRequestEmailUpdate={actions.userRequestEmailUpdate}
                      userRequestPhoneUpdate={actions.userRequestPhoneUpdate}
                      userUpdatePhone={actions.userUpdatePhone}
                      userUpdatePassword={actions.userUpdatePassword}
                      userCreateUserTotpDeviceSecret={actions.userCreateUserTotpDeviceSecret}
                      userCreateUserTotpDevice={actions.userCreateUserTotpDevice}
                      userUpdateUserTotpDevice={actions.userUpdateUserTotpDevice}
                      userDeleteUserTotpDevice={actions.userDeleteUserTotpDevice}
                      deleteStateUserTotpDeviceSecret={actions.deleteStateUserTotpDeviceSecret}
                    />
                  )
                },
                {
                  key: TAB.ACCOUNT,
                  label: (
                    <span>
                      <AntIcon type="solution" />
                      {t("user.titles.agentData")}
                    </span>
                  ),
                  children: (
                    <UserProfileAgentTabSwitchView
                      userProfile={userProfile}
                      title={t("user.titles.agentData")}
                      component={agentId => (
                        <UserProfileUserAccountView
                          agent={
                            agentId === userProfile.representingAgent?.id
                              ? userProfile.representingAgent
                              : userProfile.agent
                          }
                        />
                      )}
                    />
                  )
                },
                {
                  key: TAB.ATTACHMENTS,
                  label: (
                    <span>
                      <AntIcon type="paper-clip" />
                      {t("user.titles.attachments")}
                    </span>
                  ),
                  children: (
                    <UserProfileAgentTabSwitchView
                      userProfile={userProfile}
                      title={t("user.titles.agentAttachments")}
                      component={agentId => (
                        <AgentAttachmentsTableView
                          agent={
                            agentId === userProfile.representingAgent?.id
                              ? userProfile.representingAgent
                              : userProfile.agent
                          }
                          onDownloadAsZip={actions.downloadAgentAttachmentsAsZip}
                          onUpload={actions.uploadAgentAttachments}
                        />
                      )}
                    />
                  )
                },
                hasProfileCompetencePermission
                  ? {
                      key: TAB.COMPETENCES,
                      label: (
                        <span>
                          <AntIcon type="file-done" />
                          {t("user.titles.competences")}
                        </span>
                      ),
                      children: (
                        <>
                          <h2>
                            {t("user.titles.competences")} |{" "}
                            {formatAgentAggregatedName(userProfile.representingAgent || userProfile.agent)}
                          </h2>
                          <AgentCompetenceTableView
                            agentId={(userProfile.representingAgent || userProfile.agent).id}
                            competences={competences}
                            onGet={actions.getAgentCompetences}
                          />
                        </>
                      )
                    }
                  : null,
                hasProfileLicensePermission
                  ? {
                      key: TAB.LICENSES,
                      label: (
                        <span>
                          <AntIcon type="bank" />
                          {t("user.titles.licenses")}
                        </span>
                      ),
                      children: (
                        <UserProfileAgentTabSwitchView
                          userProfile={userProfile}
                          title={t("user.titles.licenses")}
                          component={agentId => (
                            <AgentLicenseTableView
                              agentId={agentId}
                              licenses={licenses}
                              onGet={actions.getAgentLicenses}
                            />
                          )}
                        />
                      )
                    }
                  : null,
                hasProfileBrokerNumberPermission
                  ? {
                      key: TAB.BROKER_NUMBERS,
                      label: (
                        <span>
                          <AntIcon type="id-card" />
                          {t("user.titles.brokerNumbers")}
                        </span>
                      ),
                      children: (
                        <UserProfileAgentTabSwitchView
                          userProfile={userProfile}
                          title={t("user.titles.brokerNumbers")}
                          component={agentId => (
                            <AgentBrokerNumberTableView
                              agentId={agentId}
                              brokerNumbers={brokerNumbers}
                              onGet={actions.getAgentBrokerNumbers}
                            />
                          )}
                        />
                      )
                    }
                  : null,
                {
                  key: TAB.NOTIFICATION_SETTINGS,
                  label: (
                    <span>
                      <AntIcon type="bell" />
                      {t("user.titles.notifications")}
                    </span>
                  ),
                  children: (
                    <>
                      <h2>{t("user.titles.notificationSettings")}</h2>
                      <NotificationSettingsView
                        settings={notificationSettings.filter(settings => settings.available)}
                        onUpdate={actions.updateNotificationSettings}
                      />
                    </>
                  )
                }
              ]}
            />
          )}
        </DisplayWrapper>
      </Card>
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  user: selectUser(state),
  userProfile: selectUserProfile(state),
  totpDeviceSecret: selectUserTotpDeviceSecret(state),
  totpDevices: selectUserTotpDevices(state),
  competences: selectAgentCompetences(state),
  licenses: selectAgentLicenses(state),
  brokerNumbers: selectAgentBrokerNumbers(state),
  notificationSettings: selectNotificationSettings(state),
  hasProfileCompetencePermission: selectHasPermissions(Permission.PROFILE_COMPETENCE)(state),
  hasProfileLicensePermission: selectHasPermissions(Permission.PROFILE_LICENSE)(state),
  hasProfileBrokerNumberPermission: selectHasPermissions(Permission.PROFILE_BROKER_NUMBER)(state),
  urlTabKey: selectRouterLocationSearchParam(state, "tab") || TAB.USER,
  openTotpDeviceFormByDefault: selectRouterLocationSearchParam(state, "addTotpDevice") === "true" || false
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      userGetUserProfile: userGetUserProfileActions.request,
      userRequestEmailUpdate: userRequestEmailUpdateActions.request,
      userRequestPhoneUpdate: userRequestPhoneUpdateActions.request,
      userUpdatePhone: userUpdatePhoneActions.request,
      userUpdatePassword: userUpdatePasswordActions.request,
      deleteStateUserProfile: deleteStateUserProfileAction,
      userGetUserTotpDevices: userGetUserTotpDevicesActions.request,
      userCreateUserTotpDeviceSecret: userCreateUserTotpDeviceSecretActions.request,
      userCreateUserTotpDevice: userCreateUserTotpDeviceActions.request,
      userUpdateUserTotpDevice: userUpdateUserTotpDeviceActions.request,
      userDeleteUserTotpDevice: userDeleteUserTotpDeviceActions.request,
      deleteStateUserTotpDeviceSecret: deleteStateUserTotpDeviceSecretAction,
      deleteStateUserTotpDevices: deleteStateUserTotpDevicesAction,
      downloadAgentAttachmentsAsZip: downloadAgentAttachmentsAsZipActions.request,
      uploadAgentAttachments: uploadAgentAttachmentsActions.request,
      getAgentCompetences: getAgentCompetencesActions.request,
      getAgentLicenses: getAgentLicensesActions.request,
      getAgentBrokerNumbers: getAgentBrokerNumbersActions.request,
      deleteStateAgentDetail: deleteStateAgentDetailAction,
      getNotificationSettings: getNotificationSettingsActions.request,
      updateNotificationSettings: updateNotificationSettingsActions.request,
      deleteStateNotificationSettingsList: deleteStateNotificationSettingsListAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileContainer);
