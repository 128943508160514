import { Button, Card, Cascader, Col, DatePicker, Divider, Form, Input, Modal, Row, Select, Space } from "antd";
import { FormInstance, Rule } from "antd/lib/form";
import debounce from "lodash/debounce";
import moment from "moment/moment";
import { DefaultOptionType } from "rc-select/lib/Select";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import t from "../../../../app/i18n";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import LabelWithTooltip from "../../../../common/components/form/labels/LabelWithTooltip";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../common/constants";
import AddressForm from "../../../../common/modules/address/AddressForm";
import { UUID } from "../../../../common/types";
import { formatClientName } from "../../../../common/utils/formatUtils";
import {
  datePickerStandardProps,
  licensePlateNormalizeFunction,
  resolveFormValidationError,
  selectStandardProps,
  selectTagsStandardProps,
  useFormErrorHandler
} from "../../../../common/utils/formUtils";
import messageUtils from "../../../../common/utils/messageUtils";
import { removeStringWhiteSpaces } from "../../../../common/utils/utils";
import { regexPatterns, validations } from "../../../../common/utils/validationUtils";
import ClientTypeTag from "../../../client/components/tags/ClientTypeTag";
import { ClientType, clientTypeTMap } from "../../../client/enums";
import { Client, ClientRepresentative, LegalClient } from "../../../client/types";
import { useClientSearch } from "../../../client/utils";
import { InsuranceType } from "../../../contract/enums";
import { VehicleInsurance } from "../../../contract/types";
import AcademicDegreeAfterSelect from "../../../enumerations/components/form/AcademicDegreeAfterSelect";
import AcademicDegreeSelect from "../../../enumerations/components/form/AcademicDegreeSelect";
import CityAutoComplete from "../../../enumerations/components/form/CityAutoComplete";
import InstitutionSelect from "../../../enumerations/components/form/InstitutionSelect";
import { InstitutionType } from "../../../institution/enums";
import { requests } from "../../api";
import { generateContractTerminationActions } from "../../ducks";
import { ContractTerminationCategory, ContractTerminationReason } from "../../enums";
import { GenerateContractTermination } from "../../types";
import {
  clientToContractTerminationClient,
  CONTRACT_TERMINATION_REASONS_MAP,
  INSURANCE_TYPE_TO_TERMINATION_CATEGORY_MAP,
  useContractSearchForTermination
} from "../../utils";

interface Props {
  initialContractNumber: string;
  onGenerateTermination: typeof generateContractTerminationActions.request;
}

interface TerminationClientSectionProps {
  form: FormInstance<GenerateContractTermination>;
  kind: ClientKind;
  sectionLabel: string;
  clientType?: ClientType;
  representatives?: ClientRepresentative[];
  currentFocusedKind: ClientKind;
  searchInProgress: boolean;
  onFocus: (kind: ClientKind) => void;
  onSearch: (pinOrCrn: string, kind: ClientKind) => void;
}

type ClientKind = "policyHolder" | "representative";

const reasonCorrectlySelectedRule: Rule = {
  validator: (_, value) =>
    value && value.length !== 2
      ? Promise.reject(t("contractTermination.validations.reasonCorrectlySelected"))
      : Promise.resolve()
};

const TerminationClientSection = ({
  form,
  kind,
  sectionLabel,
  clientType,
  representatives,
  currentFocusedKind,
  searchInProgress,
  onFocus,
  onSearch
}: TerminationClientSectionProps) => {
  const colSpan = 4;

  const handleRepresentativeChange = (id: UUID): void => {
    form.setFieldsValue({
      representative: clientToContractTerminationClient(representatives?.find(r => r.id === id)?.representative) || {
        type: ClientType.NATURAL
      }
    });
  };

  return (
    <>
      <Divider orientation="left">{sectionLabel}</Divider>

      {representatives?.length > 1 && (
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              label={
                <LabelWithTooltip
                  label={t("contractTermination.attrs.representative._label")}
                  tooltip={t("contractTermination.helpers.representativeDesc")}
                />
              }
            >
              <Select
                {...selectStandardProps}
                allowClear
                options={representatives.map(r => ({
                  value: r.id,
                  label: `${formatClientName(r.representative)} | ${t(
                    "client.enums.representativeFunction." + r.function
                  )}`
                }))}
                onChange={handleRepresentativeChange}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={rowGutter}>
        {clientType ? (
          <HiddenInput name={[kind, "type"]} initialValue={clientType} />
        ) : (
          <Col span={colSpan}>
            <Form.Item name={[kind, "type"]} label={t("client.enums.type._label")} rules={[validations.notNull]}>
              <Select
                {...selectTagsStandardProps(clientTypeTMap)}
                options={Object.values(ClientType).map(type => ({
                  value: type,
                  label: <ClientTypeTag type={ClientType[type]} />
                }))}
              />
            </Form.Item>
          </Col>
        )}

        <Form.Item noStyle shouldUpdate={(prev, next) => prev[kind]?.type !== next[kind]?.type}>
          {({ getFieldValue }) => {
            switch (getFieldValue([kind, "type"]) as ClientType) {
              case ClientType.NATURAL:
                return (
                  <>
                    <Col span={colSpan}>
                      <Form.Item
                        name={[kind, "personalIdentificationNumber"]}
                        label={t(`contractTermination.attrs.${kind}.personalIdentificationNumber`)}
                        rules={[validations.pin]}
                      >
                        <Input.Search
                          loading={kind === currentFocusedKind && searchInProgress}
                          onFocus={() => onFocus(kind)}
                          onSearch={value => onSearch(value, kind)}
                          onChange={e => onSearch(e.target.value, kind)}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <AcademicDegreeSelect
                        formItemProps={{
                          name: [kind, "academicDegree"],
                          label: t(`contractTermination.attrs.${kind}.academicDegree`)
                        }}
                        selectProps={{ allowClear: true }}
                      />
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={[kind, "firstName"]}
                        label={t(`contractTermination.attrs.${kind}.firstName`)}
                        rules={[
                          validations.notBlank,
                          validations.size(1, 255),
                          validations.pattern(regexPatterns.wordRegex)
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={[kind, "lastName"]}
                        label={t(`contractTermination.attrs.${kind}.lastName`)}
                        rules={[
                          validations.notBlank,
                          validations.size(1, 255),
                          validations.pattern(regexPatterns.wordRegex)
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <AcademicDegreeAfterSelect
                        formItemProps={{
                          name: [kind, "academicDegreeAfter"],
                          label: t(`contractTermination.attrs.${kind}.academicDegreeAfter`)
                        }}
                        selectProps={{ allowClear: true }}
                      />
                    </Col>
                  </>
                );
              case ClientType.SELF_EMPLOYED:
              case ClientType.LEGAL:
                return (
                  <>
                    <Col span={colSpan}>
                      <Form.Item
                        name={[kind, "companyRegistrationNumber"]}
                        label={t(`contractTermination.attrs.${kind}.companyRegistrationNumber`)}
                        rules={[validations.notBlank, validations.crn]}
                      >
                        <Input.Search
                          loading={kind === currentFocusedKind && searchInProgress}
                          onFocus={() => onFocus(kind)}
                          onSearch={value => onSearch(value, kind)}
                          onChange={e => onSearch(e.target.value, kind)}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={[kind, "companyName"]}
                        label={t(`contractTermination.attrs.${kind}.companyName`)}
                        rules={[validations.notBlank, validations.size(1, 255)]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </>
                );
            }
          }}
        </Form.Item>
      </Row>

      <AddressForm
        form={form}
        rootNamePath={[kind, "address"]}
        label={t(`contractTermination.attrs.${kind}.address`)}
        required
      />
    </>
  );
};

const ContractTerminationForm = ({ initialContractNumber, onGenerateTermination }: Props) => {
  const [form] = Form.useForm<GenerateContractTermination>();
  useFormErrorHandler(form, "contractTermination.attrs", [requests.GENERATE_CONTRACT_TERMINATION]);

  const contractSearch = useContractSearchForTermination();
  const clientSearch = useClientSearch();

  const [focusedClientKind, setFocusedClientKind] = useState<ClientKind>(null);
  const [representatives, setRepresentatives] = useState<ClientRepresentative[]>([]);
  const [reasonSelectOpen, setReasonSelectOpen] = useState<boolean>(false);

  useEffect(() => {
    if (initialContractNumber) {
      contractSearch.onSearch({ keyword: initialContractNumber });
    }

    return () => {
      contractSearch.onResultDelete();
      clientSearch.onResultDelete();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (contractSearch.result?.contract) {
      const contract = contractSearch.result.contract;

      form.setFieldsValue({
        contractNumber: contract.identifier,
        institutionId: contract.institution.id,
        date: moment(),
        text: null,
        reason: [INSURANCE_TYPE_TO_TERMINATION_CATEGORY_MAP.get(contract.insurances[0].type)],
        city: null
      });
      setClientToForm(contract.policyHolder, "policyHolder");
      setRepresentativesOfClientToForm(contract.policyHolder);
      setReasonSelectOpen(true);
    }
  }, [contractSearch.result]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clientSearch.result?.data) {
      const { keyword, clientType, data } = clientSearch.result;
      if (
        (clientType === ClientType.NATURAL &&
          keyword === form.getFieldValue([focusedClientKind, "personalIdentificationNumber"])) ||
        (clientType !== ClientType.NATURAL &&
          keyword === form.getFieldValue([focusedClientKind, "companyRegistrationNumber"]))
      ) {
        setClientToForm(data, focusedClientKind);
        if (focusedClientKind === "policyHolder") {
          setRepresentativesOfClientToForm(data);
        }
        setFocusedClientKind(null);
      }
    }
  }, [clientSearch.result]); // eslint-disable-line react-hooks/exhaustive-deps

  const setClientToForm = (client: Client, kind: ClientKind): void => {
    if (!client && kind === "representative") {
      form.setFieldsValue({ representative: null });
      form.setFieldsValue({ representative: { type: ClientType.NATURAL } });
    } else {
      form.setFieldsValue({ [kind]: clientToContractTerminationClient(client) });
    }
  };

  const setRepresentativesOfClientToForm = (client: Client): void => {
    setClientToForm(null, "representative");
    setRepresentatives([]);

    if (client.type === ClientType.LEGAL && (client as LegalClient).representatives?.length > 0) {
      const representatives = (client as LegalClient).representatives;
      if (representatives.length === 1) {
        setClientToForm(representatives[0].representative, "representative");
      } else {
        setRepresentatives(representatives);
      }
    }
  };

  const handleClientSearch = (pinOrCrn: string, kind: ClientKind): void => {
    if (pinOrCrn) {
      const type = form.getFieldValue([kind, "type"]) as ClientType;
      form
        .validateFields([
          [kind, type === ClientType.NATURAL ? "personalIdentificationNumber" : "companyRegistrationNumber"]
        ])
        .then(() => clientSearch.onSearch({ keyword: pinOrCrn, clientType: type }))
        .catch(errorInfo => {
          if (focusedClientKind === "policyHolder" && representatives?.length > 0) {
            setRepresentatives([]);
          }
          resolveFormValidationError(errorInfo);
        });
    }
  };

  const handleReasonChange = (reason: ContractTerminationReason): void => {
    if (reason) {
      let text = t(`contractTermination.helpers.terminationText.${reason}`);

      if (CONTRACT_TERMINATION_REASONS_MAP.get(ContractTerminationCategory.VEHICLE).includes(reason)) {
        const insurances = contractSearch.result?.contract?.insurances;
        if (
          insurances?.length === 1 &&
          (insurances[0].type === InsuranceType.MTPL ||
            insurances[0].type === InsuranceType.CRASH ||
            insurances[0].type === InsuranceType.GAP ||
            insurances[0].type === InsuranceType.PAS)
        ) {
          const vehicleInsurance = insurances[0] as VehicleInsurance;
          text +=
            `\n\nEČV: ${licensePlateNormalizeFunction(vehicleInsurance.licensePlate)}\n` +
            `VIN: ${vehicleInsurance.vehicle.vin}\n` +
            `Značka: ${vehicleInsurance.vehicle.model.brand.name}\n` +
            `Model: ${vehicleInsurance.vehicle.model.name}`;
        }
      }

      text += `\n\n${t("contractTermination.helpers.terminationTextSuffix")}: `;

      form.setFieldsValue({ text });
    }
  };

  const handleContractNumberSearchOrChangeDebounced = useMemo(
    () =>
      debounce((value: string): void => {
        const keyword = removeStringWhiteSpaces(value);
        if (keyword?.length >= 1 && keyword.length <= 64) {
          contractSearch.onSearch({ keyword });
        } else if (contractSearch.result) {
          contractSearch.onResultDelete();
        }
      }, 500),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleContractNumberSearchOrChange = useCallback(
    (value: string): void => {
      handleContractNumberSearchOrChangeDebounced(value);
    },
    [handleContractNumberSearchOrChangeDebounced]
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values =>
        onGenerateTermination({
          ...values,
          reason: undefined,
          policyHolder: { ...values.policyHolder, personalIdentificationNumber: undefined },
          representative: values.representative
            ? { ...values.representative, personalIdentificationNumber: undefined }
            : null
        })
      )
      .catch(errorInfo => {
        messageUtils.errorNotification(t("common.error"), t("calc.validations.formError"));
        resolveFormValidationError(errorInfo);
      });
  };

  const handleFormReset = (): void => {
    Modal.confirm({
      title: t("contractTermination.helpers.resetFormConfirm"),
      okText: t("common.yes"),
      cancelText: t("common.back"),
      onOk: () => {
        form.resetFields();
        contractSearch.onResultDelete();
        clientSearch.onResultDelete();
      }
    });
  };

  const colSpan = 4;

  return (
    <Card className="card-box">
      <Form form={form} layout="vertical" name="contractTerminationForm">
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="contractNumber"
              label={t("contractTermination.attrs.contractNumber")}
              rules={[validations.notNull, validations.size(1, 64)]}
            >
              <Input.Search
                loading={contractSearch.inProgress}
                onSearch={handleContractNumberSearchOrChange}
                onChange={e => handleContractNumberSearchOrChange(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <TerminationClientSection
          form={form}
          kind="policyHolder"
          sectionLabel={t("contractTermination.sections.clientData")}
          currentFocusedKind={focusedClientKind}
          searchInProgress={clientSearch.inProgress}
          onFocus={setFocusedClientKind}
          onSearch={handleClientSearch}
        />

        <Form.Item noStyle shouldUpdate={(prev, next) => prev.policyHolder?.type !== next.policyHolder?.type}>
          {({ getFieldValue }) =>
            getFieldValue(["policyHolder", "type"]) === ClientType.LEGAL && (
              <TerminationClientSection
                form={form}
                kind="representative"
                sectionLabel={t("contractTermination.sections.representativeData")}
                clientType={ClientType.NATURAL}
                representatives={representatives}
                currentFocusedKind={focusedClientKind}
                searchInProgress={clientSearch.inProgress}
                onFocus={setFocusedClientKind}
                onSearch={handleClientSearch}
              />
            )
          }
        </Form.Item>

        <Divider orientation="left">{t("contractTermination.sections.generalData")}</Divider>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <InstitutionSelect
              formItemProps={{
                name: "institutionId",
                label: t("contractTermination.attrs.institutionId"),
                rules: [validations.notNull]
              }}
              optionsProps={{
                filterType: InstitutionType.INSURANCE_COMPANY,
                selected: contractSearch.result?.contract ? [contractSearch.result.contract.institution] : null
              }}
            />
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="date"
              label={t("contractTermination.attrs.date")}
              rules={[validations.notNull]}
              initialValue={moment()}
            >
              <DatePicker {...datePickerStandardProps} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <CityAutoComplete
              formItemProps={{
                name: "city",
                label: t("contractTermination.attrs.city"),
                rules: [validations.notBlank, validations.size(1, 64), validations.pattern(regexPatterns.wordRegex)]
              }}
            />
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={8}>
            <Form.Item
              name="reason"
              label={
                <LabelWithTooltip
                  label={t("contractTermination.enums.terminationReason._label")}
                  tooltip={t("contractTermination.helpers.reasonDesc")}
                />
              }
              rules={[reasonCorrectlySelectedRule]}
            >
              <Cascader
                allowClear
                expandTrigger="hover"
                open={reasonSelectOpen}
                options={Object.values(ContractTerminationCategory).map<DefaultOptionType>(category => ({
                  label: t("contractTermination.enums.terminationCategory." + category),
                  value: category,
                  children: CONTRACT_TERMINATION_REASONS_MAP.get(category).map<DefaultOptionType>(reason => ({
                    label: t("contractTermination.enums.terminationReason." + reason),
                    value: reason
                  }))
                }))}
                onChange={value => handleReasonChange(value?.[1])}
                onDropdownVisibleChange={setReasonSelectOpen}
              />
            </Form.Item>
          </Col>

          <Col span={16}>
            <Form.Item
              name="text"
              label={t("contractTermination.attrs.text")}
              rules={[validations.notBlank, validations.size(1, 4096)]}
            >
              <Input.TextArea rows={10} />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Space className="margin-top-medium">
        <Button type="primary" icon={<AntIcon type="download" />} onClick={handleFormSubmit}>
          {t("common.download")}
        </Button>

        <Button icon={<AntIcon type="reload" />} onClick={handleFormReset}>
          {t("contractTermination.actions.resetForm")}
        </Button>
      </Space>
    </Card>
  );
};

export default ContractTerminationForm;
