import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import AntIcon from "../../../common/components/icons/AntIcon";
import { PageSizes } from "../../../common/constants";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState, SearchPageResult } from "../../../common/types";
import { appendSearchParamsToURL, numberOrZero } from "../../../common/utils/utils";
import { FuelType, VehicleCategory } from "../../contract/enums";
import { selectRouterLocationSearch } from "../../ducks";
import VehicleCategoryTabView from "../category/components/views/VehicleCategoryTabView";
import {
  createVehicleCategoryActions,
  deleteStateVehicleCategoriesPageAction,
  deleteVehicleCategoryActions,
  filterVehicleCategoriesActions,
  selectVehicleCategoriesCurrentPage,
  updateVehicleCategoryActions
} from "../category/ducks";
import { CertificateVehicleCategoryFilterPageResult } from "../category/types";
import VehicleColorTabView from "../color/components/views/VehicleColorTabView";
import {
  createVehicleColorActions,
  deleteStateVehicleColorsPageAction,
  deleteVehicleColorActions,
  filterVehicleColorsActions,
  selectVehicleColorsCurrentPage,
  updateVehicleColorActions
} from "../color/ducks";
import { CertificateVehicleColor } from "../color/types";
import VehicleFuelTypeTabView from "../fueltype/components/views/VehicleFuelTypeTabView";
import {
  createVehicleFuelTypeActions,
  deleteStateVehicleFuelTypesPageAction,
  deleteVehicleFuelTypeActions,
  filterVehicleFuelTypesActions,
  selectVehicleFuelTypesCurrentPage,
  updateVehicleFuelTypeActions
} from "../fueltype/ducks";
import { FuelNameSource } from "../fueltype/enums";
import { CertificateFuelTypeFilterPageResult } from "../fueltype/types";

interface StateProps {
  categoriesCurrentPage: CertificateVehicleCategoryFilterPageResult;
  colorsCurrentPage: SearchPageResult<CertificateVehicleColor>;
  fuelTypesCurrentPage: CertificateFuelTypeFilterPageResult;
  urlSearchQuery: string;
}

interface ActionsMap {
  filterVehicleCategories: typeof filterVehicleCategoriesActions.request;
  createVehicleCategory: typeof createVehicleCategoryActions.request;
  updateVehicleCategory: typeof updateVehicleCategoryActions.request;
  deleteVehicleCategory: typeof deleteVehicleCategoryActions.request;
  deleteStateVehicleCategoriesPage: typeof deleteStateVehicleCategoriesPageAction;
  filterVehicleColors: typeof filterVehicleColorsActions.request;
  createVehicleColor: typeof createVehicleColorActions.request;
  updateVehicleColor: typeof updateVehicleColorActions.request;
  deleteVehicleColor: typeof deleteVehicleColorActions.request;
  deleteStateVehicleColorsPage: typeof deleteStateVehicleColorsPageAction;
  filterVehicleFuelTypes: typeof filterVehicleFuelTypesActions.request;
  createVehicleFuelType: typeof createVehicleFuelTypeActions.request;
  updateVehicleFuelType: typeof updateVehicleFuelTypeActions.request;
  deleteVehicleFuelType: typeof deleteVehicleFuelTypeActions.request;
  deleteStateVehicleFuelTypesPage: typeof deleteStateVehicleFuelTypesPageAction;
}

export const VEHICLE_ENUMS_TAB = {
  CATEGORIES: "categories",
  COLORS: "colors",
  FUEL_TYPES: "fuel-types"
};

const VehicleEnumsContainer = ({
  categoriesCurrentPage,
  colorsCurrentPage,
  fuelTypesCurrentPage,
  urlSearchQuery,
  actions
}: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();

  const [tabKey, setTabKey] = useState<string>(VEHICLE_ENUMS_TAB.CATEGORIES);

  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);

    const tab = Object.values(VEHICLE_ENUMS_TAB).includes(urlParams.get("tab"))
      ? urlParams.get("tab")
      : VEHICLE_ENUMS_TAB.CATEGORIES;
    const keyword = urlParams.get("keyword");

    setTabKey(tab);

    switch (tab) {
      case VEHICLE_ENUMS_TAB.CATEGORIES:
        actions.filterVehicleCategories({
          pageIndex: numberOrZero(urlParams.get("pageIndex")),
          pageSize: PageSizes.LARGE,
          keyword: keyword,
          categories: urlParams.getAll("categories").map(categories => VehicleCategory[categories])
        });
        break;
      case VEHICLE_ENUMS_TAB.COLORS:
        actions.filterVehicleColors({
          pageIndex: numberOrZero(urlParams.get("pageIndex")),
          pageSize: PageSizes.LARGE,
          keyword: keyword
        });
        break;
      case VEHICLE_ENUMS_TAB.FUEL_TYPES:
        actions.filterVehicleFuelTypes({
          pageIndex: numberOrZero(urlParams.get("pageIndex")),
          pageSize: PageSizes.LARGE,
          keyword: keyword,
          fuelTypes: urlParams.getAll("fuelTypes").map(fuelTypes => FuelType[fuelTypes]),
          fuelNameSources: urlParams.getAll("fuelNameSources").map(fuelNames => FuelNameSource[fuelNames])
        });
    }

    return () => {
      actions.deleteStateVehicleCategoriesPage();
      actions.deleteStateVehicleColorsPage();
      actions.deleteStateVehicleFuelTypesPage();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabKeyChange = (tab: string): void => {
    navigate(appendSearchParamsToURL({ pageIndex: null, keyword: null, categories: null, fuelTypes: null, tab }), {
      replace: true
    });

    setTabKey(tab);

    switch (tab) {
      case VEHICLE_ENUMS_TAB.CATEGORIES:
        actions.filterVehicleCategories({
          pageIndex: 0,
          pageSize: PageSizes.LARGE,
          keyword: null,
          categories: []
        });
        break;
      case VEHICLE_ENUMS_TAB.COLORS:
        actions.filterVehicleColors({
          pageIndex: 0,
          pageSize: PageSizes.LARGE,
          keyword: null
        });
        break;
      case VEHICLE_ENUMS_TAB.FUEL_TYPES:
        actions.filterVehicleFuelTypes({
          pageIndex: 0,
          pageSize: PageSizes.LARGE,
          keyword: null,
          fuelTypes: [],
          fuelNameSources: []
        });
        break;
    }
  };

  return (
    <ContentWrapper>
      <Tabs
        className="tabs-box"
        activeKey={tabKey}
        onChange={handleTabKeyChange}
        items={[
          {
            key: VEHICLE_ENUMS_TAB.CATEGORIES,
            label: (
              <span>
                <AntIcon type="group" />
                {t("vehicleCategory.titles.pageTitle")}
              </span>
            ),
            children: (
              <VehicleCategoryTabView
                categoriesCurrentPage={categoriesCurrentPage}
                actions={{
                  onFilter: actions.filterVehicleCategories,
                  onCreate: actions.createVehicleCategory,
                  onUpdate: actions.updateVehicleCategory,
                  onDelete: actions.deleteVehicleCategory,
                  onDeleteStatePage: actions.deleteStateVehicleCategoriesPage
                }}
              />
            )
          },
          {
            key: VEHICLE_ENUMS_TAB.COLORS,
            label: (
              <span>
                <AntIcon type="group" />
                {t("vehicleColor.titles.pageTitle")}
              </span>
            ),
            children: (
              <VehicleColorTabView
                colorsCurrentPage={colorsCurrentPage}
                actions={{
                  onFilter: actions.filterVehicleColors,
                  onCreate: actions.createVehicleColor,
                  onUpdate: actions.updateVehicleColor,
                  onDelete: actions.deleteVehicleColor,
                  onDeleteStatePage: actions.deleteStateVehicleColorsPage
                }}
              />
            )
          },
          {
            key: VEHICLE_ENUMS_TAB.FUEL_TYPES,
            label: (
              <span>
                <AntIcon type="group" />
                {t("vehicleFuelType.titles.pageTitle")}
              </span>
            ),
            children: (
              <VehicleFuelTypeTabView
                fuelTypesCurrentPage={fuelTypesCurrentPage}
                actions={{
                  onFilter: actions.filterVehicleFuelTypes,
                  onCreate: actions.createVehicleFuelType,
                  onUpdate: actions.updateVehicleFuelType,
                  onDelete: actions.deleteVehicleFuelType,
                  onDeleteStatePage: actions.deleteStateVehicleFuelTypesPage
                }}
              />
            )
          }
        ]}
      />
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  categoriesCurrentPage: selectVehicleCategoriesCurrentPage(state),
  colorsCurrentPage: selectVehicleColorsCurrentPage(state),
  fuelTypesCurrentPage: selectVehicleFuelTypesCurrentPage(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterVehicleCategories: filterVehicleCategoriesActions.request,
      createVehicleCategory: createVehicleCategoryActions.request,
      updateVehicleCategory: updateVehicleCategoryActions.request,
      deleteVehicleCategory: deleteVehicleCategoryActions.request,
      deleteStateVehicleCategoriesPage: deleteStateVehicleCategoriesPageAction,
      filterVehicleColors: filterVehicleColorsActions.request,
      createVehicleColor: createVehicleColorActions.request,
      updateVehicleColor: updateVehicleColorActions.request,
      deleteVehicleColor: deleteVehicleColorActions.request,
      deleteStateVehicleColorsPage: deleteStateVehicleColorsPageAction,
      filterVehicleFuelTypes: filterVehicleFuelTypesActions.request,
      createVehicleFuelType: createVehicleFuelTypeActions.request,
      updateVehicleFuelType: updateVehicleFuelTypeActions.request,
      deleteVehicleFuelType: deleteVehicleFuelTypeActions.request,
      deleteStateVehicleFuelTypesPage: deleteStateVehicleFuelTypesPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(VehicleEnumsContainer);
