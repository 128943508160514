import React from "react";
import { Route, Routes } from "react-router-dom";
import { WILDCARD_ROUTE } from "../../common/constants";
import NotFound from "../../common/pages/NotFound/NotFound";
import { Permission } from "../../common/security/authorization/enums";
import PageWithPermission from "../../common/security/authorization/PageWithPermission";
import ClientCreateContainer from "./containers/ClientCreateContainer";
import ClientDetailContainer from "./containers/ClientDetailContainer";
import ClientListContainer from "./containers/ClientListContainer";
import { CLIENT_ROUTE_PATHS } from "./paths";

const routes = () => (
  <Routes>
    <Route
      index
      element={<PageWithPermission component={<ClientListContainer />} permissions={[Permission.CLIENT_READ]} />}
    />

    <Route
      path={CLIENT_ROUTE_PATHS.create.path}
      element={<PageWithPermission component={<ClientCreateContainer />} permissions={[Permission.CLIENT_CREATE]} />}
    />

    <Route
      path={CLIENT_ROUTE_PATHS.detail.path}
      element={<PageWithPermission component={<ClientDetailContainer />} permissions={[Permission.CLIENT_READ]} />}
    />

    <Route path={WILDCARD_ROUTE} element={<NotFound />} />
  </Routes>
);

export default routes;
