import { Divider, Dropdown, Modal, Popconfirm } from "antd";
import { MenuItemType } from "antd/lib/menu/hooks/useItems";
import React from "react";
import useMeasure from "react-use-measure";
import { ActionTextIconProps, TableActionsViewActionConfig } from "../../types";
import ActionTextIcon from "../icons/ActionTextIcon";

interface Props {
  actions: TableActionsViewActionConfig[];
  dropdownMaxWidth?: number;
  dropdownAction?: ActionTextIconProps;
  dropdownExtractFirstAction?: boolean;
}

const TableActionsView = ({ actions, dropdownMaxWidth, dropdownAction, dropdownExtractFirstAction }: Props) => {
  const [actionsDivRef, actionsDivMeasures] = useMeasure();

  const inlineActions = actions.map((action, index) => {
    const { confirmDialog, renderOverride, onClick, ...actionTextIconProps } = action;

    return (
      <React.Fragment key={index}>
        {renderOverride ||
          (confirmDialog ? (
            <Popconfirm
              {...{ ...confirmDialog, content: undefined }}
              title={
                <>
                  {confirmDialog.title}
                  {confirmDialog.content && confirmDialog.content}
                </>
              }
              disabled={actionTextIconProps.disabled}
              key={index}
              onConfirm={() => onClick?.()}
            >
              <ActionTextIcon {...actionTextIconProps} />
            </Popconfirm>
          ) : (
            <ActionTextIcon {...actionTextIconProps} key={index} onClick={() => onClick?.()} />
          ))}
        {index + 1 < actions.length && <Divider type="vertical" />}
      </React.Fragment>
    );
  });

  let dropdownActions = null;
  if (dropdownMaxWidth) {
    const handleDropdownItemClick = (index: number) => {
      const { confirmDialog, onClick } = actions[index];
      if (confirmDialog) {
        Modal.confirm({
          ...confirmDialog,
          onOk: () => {
            onClick?.();
          }
        });
      } else {
        onClick?.();
      }
    };

    let firstExtractedAction: React.ReactNode = null;
    if (dropdownExtractFirstAction) {
      const { confirmDialog, renderOverride, onClick, ...actionTextIconProps } = actions[0];

      firstExtractedAction = confirmDialog ? (
        <>
          <Popconfirm
            {...{ ...confirmDialog, content: undefined }}
            title={
              <>
                {confirmDialog.title}
                {confirmDialog.content && confirmDialog.content}
              </>
            }
            disabled={actionTextIconProps.disabled}
            onConfirm={() => onClick?.()}
          >
            <ActionTextIcon {...actionTextIconProps} />
          </Popconfirm>
          <Divider type="vertical" />
        </>
      ) : (
        <>
          <ActionTextIcon {...actionTextIconProps} onClick={() => onClick?.()} />
          <Divider type="vertical" />
        </>
      );
    }

    const { onClick, ...dropdownActionTextIconProps } = dropdownAction;
    dropdownActions = (
      <>
        {firstExtractedAction && firstExtractedAction}
        <Dropdown
          menu={{
            items: (dropdownExtractFirstAction ? actions.slice(1) : actions).map<MenuItemType>((action, index) => {
              const { confirmDialog, renderOverride, onClick, ...actionTextIconProps } = action;
              return {
                key: index,
                label: renderOverride || <ActionTextIcon {...actionTextIconProps} />,
                disabled: actionTextIconProps.disabled
              };
            }),
            onClick: ({ key }) =>
              handleDropdownItemClick(dropdownExtractFirstAction ? parseInt(key) + 1 : parseInt(key))
          }}
          trigger={["click"]}
          placement="bottomRight"
          disabled={dropdownActionTextIconProps.disabled}
        >
          <ActionTextIcon
            {...dropdownActionTextIconProps}
            color={dropdownExtractFirstAction ? actions[0].color : dropdownActionTextIconProps.color}
          />
        </Dropdown>
      </>
    );
  }

  return (
    <div ref={actionsDivRef}>
      {dropdownActions && actionsDivMeasures.width < dropdownMaxWidth ? dropdownActions : inlineActions}
    </div>
  );
};

export default TableActionsView;
