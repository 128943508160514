import { Col, Form, Input, Modal, Row, Select } from "antd";
import React, { useEffect } from "react";
import t from "../../../../../app/i18n";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import ItemCreatedUpdatedInfoView from "../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { ModalSizes, rowGutter } from "../../../../../common/constants";
import {
  resolveFormValidationError,
  selectStandardProps,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { regexPatterns, validations } from "../../../../../common/utils/validationUtils";
import { FuelType } from "../../../../contract/enums";
import { requests } from "../../api";
import { createVehicleFuelTypeActions, updateVehicleFuelTypeActions } from "../../ducks";
import { FuelNameSource } from "../../enums";
import { CertificateFuelType, CreateUpdateCertificateFuelType } from "../../types";

interface Props {
  fuelType?: CertificateFuelType;
  open: boolean;
  onCreate?: typeof createVehicleFuelTypeActions.request;
  onUpdate?: typeof updateVehicleFuelTypeActions.request;
  onFormCancel: () => void;
}

const VehicleFuelTypeForm = ({ fuelType, open, onCreate, onUpdate, onFormCancel }: Props) => {
  const [form] = Form.useForm<CreateUpdateCertificateFuelType>();

  useFormErrorHandler(form, "vehicleFuelType.attrs", [
    requests.CREATE_VEHICLE_FUEL_TYPE,
    requests.UPDATE_VEHICLE_FUEL_TYPE
  ]);

  useEffect(() => {
    if (open && fuelType) {
      form.setFieldsValue(fuelType);
    }
  }, [fuelType, open, form]);

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_VEHICLE_FUEL_TYPE, requests.UPDATE_VEHICLE_FUEL_TYPE],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (fuelType) {
          onUpdate?.({ id: fuelType.id, object: values });
        } else {
          onCreate?.(values);
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={fuelType ? t("vehicleFuelType.titles.updateFuelType") : t("vehicleFuelType.titles.createFuelType")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <ItemCreatedUpdatedInfoView className="margin-bottom-small" item={fuelType} />

      <Form form={form} layout="vertical" name="vehicleFuelTypeForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={8}>
            <Form.Item name="fuelType" label={t("contract.enums.fuelType._label")} rules={[validations.none]}>
              <Select
                {...selectStandardProps}
                allowClear
                options={Object.keys(FuelType).map(fuelType => ({
                  value: fuelType,
                  label: t("contract.enums.fuelType." + fuelType)
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="name"
              label={t("vehicleFuelType.attrs.name")}
              rules={[
                validations.notNull,
                validations.size(1, 255),
                validations.pattern(regexPatterns.vehicleFuelTypeNameRegex)
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="source" label={t("vehicle.enums.fuelNameSource._label")} rules={[validations.notNull]}>
              <Select
                {...selectStandardProps}
                options={Object.keys(FuelNameSource).map(fuelNameSource => ({
                  value: fuelNameSource,
                  label: t("vehicle.enums.fuelNameSource." + fuelNameSource)
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default VehicleFuelTypeForm;
