import cloneDeep from "lodash/cloneDeep";
import { Moment } from "moment";
import { toMoment } from "../../../../common/utils/formUtils";
import { createUpdateContractClientToClient } from "../../../client/utils";
import { FilterFunction } from "../../../enumerations/types";
import { CalcType } from "../../enums";
import {
  TravelCalc,
  TravelCalcInsuredClient,
  TravelFormClients,
  TravelGen,
  TravelGenForm,
  TravelGenFormInsuredClient
} from "./types";

export const countTravelClientsByAge = (calcData: TravelCalc, ageFilter: FilterFunction<number>): number => {
  return calcData?.generalData?.effectiveBeginningDate && calcData.clientsData?.insuredClients
    ? calcData.clientsData.insuredClients
        .map(client => calculateTravelClientAge(client, calcData.generalData.effectiveBeginningDate))
        .filter(ageFilter).length
    : 0;
};

export const calculateTravelClientAge = (
  client: TravelCalcInsuredClient,
  effectiveBeginningDate: string | Moment
): number => {
  return toMoment(effectiveBeginningDate)?.diff(toMoment(client?.birthDate), "years");
};

export const createTravelCalcObjectFromCalcData = (calcData: TravelCalc): TravelCalc => {
  const data = cloneDeep(calcData);

  data.generalData.effectiveBeginningDate = toMoment(calcData.generalData.effectiveBeginningDate);
  data.generalData.effectiveEndDate = toMoment(calcData.generalData.effectiveEndDate);

  data.clientsData.insuredClients = data.clientsData.insuredClients?.map(client => ({
    ...client,
    birthDate: toMoment(client.birthDate)
  }));

  if (data.cancellationData) {
    data.cancellationData.firstPaymentDate = toMoment(calcData.cancellationData.firstPaymentDate);
  }

  if (data.coveragesData?.cancellationData) {
    data.coveragesData.cancellationData.firstPaymentDate = toMoment(
      calcData.coveragesData.cancellationData.firstPaymentDate
    );
  }

  return data;
};

export const createTravelFormClientsObject = (genData: TravelGen): TravelFormClients => ({
  policyHolder: createUpdateContractClientToClient(genData.clientsData.clients[0]),
  representative: createUpdateContractClientToClient(genData.clientsData.clients[1])
});

export const createTravelCalcObjectFromGenData = (genData: TravelGen): TravelCalc => {
  const genDataCopy = cloneDeep(genData);
  const data = {
    type: CalcType.TRAVEL,
    generalData: genDataCopy.generalData,
    clientsData: {
      insuredClientsCount: genDataCopy.clientsData.insuredClientsCount,
      insuredClients: genDataCopy.clientsData.insuredClients
        ? genDataCopy.clientsData.insuredClients.map<TravelCalcInsuredClient>(client => ({
            birthDate: toMoment(client.birthDate),
            discountType: client.discountType
          }))
        : null
    },
    cancellationData: genDataCopy.cancellationData,
    coveragesData: genDataCopy.coveragesData,
    discountsData: genDataCopy.discountsData
  } as TravelCalc & TravelGen;

  if (data.coveragesData) {
    delete data.coveragesData.vehicleAssistanceData;
    delete data.coveragesData.abandonedHouseholdData;
    delete data.coveragesData.petData;
  }

  return createTravelCalcObjectFromCalcData(data);
};

export const createTravelGenFormDataObject = (genData: TravelGen, clients: TravelFormClients): TravelGenForm => {
  return {
    clientsData: {
      policyHolderIdentifier: clients.policyHolder?.identifier,
      policyHolderIsAlsoInsured: genData.clientsData.policyHolderIsAlsoInsured,
      policyHolderEmail: genData.clientsData.policyHolderEmail,
      policyHolderPhone: genData.clientsData.policyHolderPhone,
      policyHolderMarketingConsent: genData.clientsData.policyHolderMarketingConsent,
      representativeIdentifier: clients.representative?.identifier,
      representativeFunction: genData.clientsData.representativeFunction,
      insuredClientForFamilyInsurance: genData.clientsData.insuredClientForFamilyInsurance,
      insuredClients: genData.clientsData.insuredClients.map<TravelGenFormInsuredClient>(client => ({
        birthDate: toMoment(client.birthDate),
        personalIdentificationNumber: client.personalIdentificationNumber,
        firstName: client.firstName,
        lastName: client.lastName,
        academicDegree: client.academicDegree,
        academicDegreeAfter: client.academicDegreeAfter,
        discountIdentifier: client.discountIdentifier
      }))
    },
    coveragesData: genData.coveragesData
      ? {
          vehicleAssistanceData: genData.coveragesData.vehicleAssistanceData,
          abandonedHouseholdData: genData.coveragesData.abandonedHouseholdData,
          petData: genData.coveragesData.petData
        }
      : null,
    financialMediationData: genData.financialMediationData
  } as TravelGenForm;
};
