import { Calendar, Card, Col, Empty, Row, Space, Spin, Tag, Tooltip } from "antd";
import moment, { Moment } from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { rowGutter } from "../../../../../common/constants";
import { RootState } from "../../../../../common/types";
import { formatAggregatedName } from "../../../../../common/utils/formatUtils";
import { momentToIsoDateString, toMoment } from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { AGENT_ROUTE_PATHS } from "../../../../agent/paths";
import { CLIENT_ROUTE_PATHS } from "../../../../client/paths";
import { requests } from "../../../api";
import { getDashboardPersonalDatesActions, selectDashboardPersonalDates } from "../../../ducks";
import { PersonalDatesDashboard } from "../../../types";

export const DashboardPersonalDatesWidget = () => {
  const { t } = useTranslation();

  const personalDates = useSelector<RootState, PersonalDatesDashboard>(selectDashboardPersonalDates);

  const inProgress = useRequestFinishedCallback([requests.GET_DASHBOARD_PERSONAL_DATES]);

  const dispatch = useDispatch();
  const actions = useMemo(
    () => bindActionCreators({ onDatesGet: getDashboardPersonalDatesActions.request }, dispatch),
    [dispatch]
  );

  const [calendarDate, setCalendarDate] = useState<Moment>(moment());

  useEffect(() => {
    if (personalDates?.personalDates) {
      const allDates = Object.keys(personalDates.personalDates).sort();
      const searchedDate = allDates[0].substring(0, 8) + moment().date().toString().padStart(2, "0");
      setCalendarDate(toMoment(allDates.includes(searchedDate) ? searchedDate : allDates[allDates.length - 1]));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePanelChange = (value: Moment): void => {
    actions.onDatesGet({
      minDate: momentToIsoDateString(moment(value).startOf("month")),
      maxDate: momentToIsoDateString(moment(value).endOf("month"))
    });
  };

  const persons = personalDates?.personalDates[momentToIsoDateString(calendarDate)] || [];

  return (
    <Card
      className="card-box dashboard-personal-dates__card"
      size="small"
      loading={!personalDates && inProgress}
      title={t("dashboard.personalDates.card")}
      extra={
        <span className="sub-header-info">
          {t("dashboard.personalDates.todayNameDays")}:{" "}
          {personalDates?.todayNameDays.length > 0 ? personalDates?.todayNameDays.join(", ") : "-"}
        </span>
      }
    >
      <Spin spinning={personalDates && inProgress}>
        {personalDates ? (
          <Row gutter={rowGutter}>
            <Col span={13}>
              <Calendar
                className="dashboard-personal-dates__calendar"
                fullscreen={false}
                mode="month"
                value={calendarDate}
                dateCellRender={date =>
                  personalDates.personalDates[momentToIsoDateString(date)]?.length ? (
                    <div className="dashboard-personal-dates__calendar__date-highlight" />
                  ) : null
                }
                onChange={setCalendarDate}
                onPanelChange={handlePanelChange}
              />
            </Col>

            <Col span={11} className="dashboard-personal-dates__list">
              {persons.length > 0 ? (
                persons.map((person, index) => (
                  <Row key={index} className="dashboard-personal-dates__list__row">
                    <Col span={4}>
                      {person.clientType ? (
                        <Tooltip title={t("dashboard.personalDates.client")}>
                          <Tag
                            className="dashboard-personal-dates__list__row__type-tag"
                            color="blue"
                            icon={<AntIcon type="team" />}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title={t("dashboard.personalDates.agent")}>
                          <Tag
                            className="dashboard-personal-dates__list__row__type-tag"
                            color="purple"
                            icon={<AntIcon type="user-switch" />}
                          />
                        </Tooltip>
                      )}
                    </Col>

                    <Col span={16}>
                      <Ellipsis>
                        <Link
                          className="no-link-color"
                          to={generatePath(
                            person.clientType ? CLIENT_ROUTE_PATHS.detail.to : AGENT_ROUTE_PATHS.detail.to,
                            {
                              id: person.id
                            }
                          )}
                          target="_blank"
                        >
                          {formatAggregatedName(person)}
                        </Link>
                      </Ellipsis>
                    </Col>

                    <Col span={4} className="right-align">
                      <Space size="small">
                        {person.hasNameDay && (
                          <Tooltip title={t("dashboard.personalDates.nameDay")}>
                            <span>
                              <AntIcon
                                className="dashboard-personal-dates__list__row__icon"
                                color="green"
                                type="contact"
                              />
                            </span>
                          </Tooltip>
                        )}

                        {person.hasBirthday && (
                          <Tooltip title={t("dashboard.personalDates.birthday")}>
                            <span>
                              <AntIcon className="dashboard-personal-dates__list__row__icon" color="red" type="gift" />
                            </span>
                          </Tooltip>
                        )}
                      </Space>
                    </Col>
                  </Row>
                ))
              ) : (
                <Empty
                  className="dashboard-empty"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t("dashboard.personalDates.noDates")}
                />
              )}
            </Col>
          </Row>
        ) : (
          <Empty className="dashboard-empty" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Spin>
    </Card>
  );
};
