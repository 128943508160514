import { combineReducers } from "redux";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { NormalizedError, RootState } from "../../../../common/types";
import { GenResponse } from "../types";
import {
  CrashOffer,
  MtplCrashOffer,
  MtplOffer,
  VehicleCalc,
  VehicleCalcDraft,
  VehicleCalcReducerState,
  VehicleCalcResults,
  VehicleGen
} from "./types";

/**
 * ACTIONS
 */
export const calculateVehicleActions = createAsyncAction(
  "calc-vehicle/CALCULATE_REQUEST",
  "calc-vehicle/CALCULATE_SUCCESS",
  "calc-vehicle/CALCULATE_FAILURE"
)<VehicleCalc, VehicleCalcResults, NormalizedError>();

export const generateVehicleActions = createAsyncAction(
  "calc-vehicle/GENERATE_REQUEST",
  "calc-vehicle/GENERATE_SUCCESS",
  "calc-vehicle/GENERATE_FAILURE"
)<VehicleGen, GenResponse, NormalizedError>();

export const generateVehicleOfferActions = createAsyncAction(
  "calc-vehicle/GENERATE_OFFER_REQUEST",
  "calc-vehicle/GENERATE_OFFER_SUCCESS",
  "calc-vehicle/GENERATE_OFFER_FAILURE"
)<MtplOffer | CrashOffer | MtplCrashOffer, void, NormalizedError>();

export const setVehicleCalcResultsAction = createAction("calc-vehicle/SET_CALC_RESULTS")<VehicleCalcResults>();
export const setVehicleDraftAction = createAction("calc-vehicle/SET_DRAFT")<VehicleCalcDraft>();
export const setVehicleInitialCalcDataAction = createAction("calc-vehicle/SET_INITIAL_CALC_DATA")<VehicleCalc>();
export const setVehicleInitialGenDataAction = createAction("calc-vehicle/SET_INITIAL_GEN_DATA")<VehicleGen>();

export const deleteStateVehicleCalcResultsAction = createAction("calc-vehicle/DELETE_STATE_CALC_RESULTS")<void>();
export const deleteStateVehicleGenResultAction = createAction("calc-vehicle/DELETE_STATE_GEN_RESULT")<void>();
export const deleteStateVehicleDraftAction = createAction("calc-vehicle/DELETE_STATE_DRAFT")<void>();
export const deleteStateVehicleInitialCalcGenDataAction = createAction(
  "calc-vehicle/DELETE_STATE_INITIAL_CALC_GEN_DATA"
)<void>();

const actions = {
  calculateVehicleActions,
  generateVehicleActions,
  generateVehicleOfferActions,
  setVehicleCalcResultsAction,
  setVehicleDraftAction,
  setVehicleInitialCalcDataAction,
  setVehicleInitialGenDataAction,
  deleteStateVehicleCalcResultsAction,
  deleteStateVehicleGenResultAction,
  deleteStateVehicleDraftAction,
  deleteStateVehicleInitialCalcGenDataAction
};

export type VehicleCalcAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: VehicleCalcReducerState = {
  calcResults: { mtpl: [], crash: [], mtplCrash: [], gap: [], pas: [] },
  genResult: null,
  draft: null,
  initialCalcData: null,
  initialGenData: null
};

const calcResultsReducer = createReducer(initialState.calcResults)
  .handleAction([calculateVehicleActions.success, setVehicleCalcResultsAction], (_, { payload }) => payload)
  .handleAction([calculateVehicleActions.failure, deleteStateVehicleCalcResultsAction], () => initialState.calcResults);

const genResultReducer = createReducer(initialState.genResult)
  .handleAction(generateVehicleActions.success, (_, { payload }) => payload)
  .handleAction([generateVehicleActions.failure, deleteStateVehicleGenResultAction], () => initialState.genResult);

const draftReducer = createReducer(initialState.draft)
  .handleAction(setVehicleDraftAction, (_, { payload }) => payload)
  .handleAction(deleteStateVehicleDraftAction, () => initialState.draft);

const initialCalcDataReducer = createReducer(initialState.initialCalcData)
  .handleAction(setVehicleInitialCalcDataAction, (_, { payload }) => payload)
  .handleAction(deleteStateVehicleInitialCalcGenDataAction, () => initialState.initialCalcData);

const initialGenDataReducer = createReducer(initialState.initialGenData)
  .handleAction(setVehicleInitialGenDataAction, (_, { payload }) => payload)
  .handleAction(deleteStateVehicleInitialCalcGenDataAction, () => initialState.initialGenData);

export const vehicleCalcReducer = combineReducers<VehicleCalcReducerState>({
  calcResults: calcResultsReducer,
  genResult: genResultReducer,
  draft: draftReducer,
  initialCalcData: initialCalcDataReducer,
  initialGenData: initialGenDataReducer
});

/**
 * SELECTORS
 */
const selectVehicleCalc = (state: RootState): VehicleCalcReducerState => state.calculator.calcs.vehicle;

export const selectVehicleCalcResults = (state: RootState): VehicleCalcResults => selectVehicleCalc(state).calcResults;
export const selectVehicleGenResult = (state: RootState): GenResponse => selectVehicleCalc(state).genResult;
export const selectVehicleDraft = (state: RootState): VehicleCalcDraft => selectVehicleCalc(state).draft;
export const selectVehicleInitialCalcData = (state: RootState): VehicleCalc => selectVehicleCalc(state).initialCalcData;
export const selectVehicleInitialGenData = (state: RootState): VehicleGen => selectVehicleCalc(state).initialGenData;
