import { green, red } from "@ant-design/colors";
import { Divider } from "antd";
import React from "react";
import t from "../../../app/i18n";
import AntIcon from "../../components/icons/AntIcon";
import CopyToClipboardView from "../../components/views/CopyToClipboardView";
import Ellipsis from "../../components/views/Ellipsis";
import { formatLocaleDate, formatPhoneNumber } from "../../utils/formatUtils";
import { ContactType, MarketingConsent } from "../enums";
import { Contact } from "../types";
import ContactTypeIcon from "./ContactTypeIcon";

interface Props {
  contacts: Contact[];
  marketingConsentsAreApplicable?: boolean;
  size?: "normal" | "small";
}

const ContactsView = ({ contacts, marketingConsentsAreApplicable, size = "normal" }: Props) => {
  const resolveContactValue = (contact: Contact): string => {
    switch (contact.type) {
      case ContactType.EMAIL:
        return contact.value;
      case ContactType.PHONE_NUMBER:
        return formatPhoneNumber(contact.value);
      default:
        return contact.value;
    }
  };

  const resolveContactAnchor = (contact: Contact): React.ReactNode => {
    switch (contact.type) {
      case ContactType.EMAIL:
        return <a href={`mailto:${contact.value}`}>{contact.value}</a>;
      case ContactType.PHONE_NUMBER:
        return <a href={`tel:${contact.value}`}>{formatPhoneNumber(contact.value)}</a>;
      default:
        return contact.value;
    }
  };

  return contacts && contacts.length > 0 ? (
    <table className="contacts-table-view">
      <tbody>
        {marketingConsentsAreApplicable && (
          <tr>
            <td colSpan={3} className="sub-header-info">
              {t("contact.helpers.contactDataLabel")}
            </td>
            <td className="sub-header-info">{t("contact.helpers.marketingConsentDataLabel")}</td>
          </tr>
        )}
        {contacts.map((contact, index) => (
          <tr key={index}>
            <td className="type">
              <span className="content">
                <ContactTypeIcon type={contact.type} />
              </span>
            </td>
            <td className="value">
              <CopyToClipboardView
                content={resolveContactValue(contact)}
                wrapper={
                  <Ellipsis
                    tooltip={resolveContactValue(contact)}
                    className={size === "small" ? "content__small" : "content"}
                  >
                    {resolveContactAnchor(contact)}
                  </Ellipsis>
                }
              />
            </td>
            <td className="description">
              <Ellipsis className={size === "small" ? "content__small" : "content"}>{contact.description}</Ellipsis>
            </td>
            {marketingConsentsAreApplicable &&
              (contact.type === ContactType.EMAIL || contact.type === ContactType.PHONE_NUMBER) && (
                <td className="marketing-consent">
                  {contact.marketingConsent === MarketingConsent.GRANTED ? (
                    <span className="content">
                      <AntIcon type="check-circle-two-tone" twoToneColor={green[5]} />
                      <Divider type="vertical" />
                      {formatLocaleDate(contact.marketingConsentStartDate)} -{" "}
                      {formatLocaleDate(contact.marketingConsentEndDate)}
                    </span>
                  ) : (
                    <span className="content">
                      <AntIcon type="close-circle-two-tone" twoToneColor={red[5]} />
                      <Divider type="vertical" />
                      <i>{t("contact.enums.marketingConsent.NOT_GRANTED")}</i>
                    </span>
                  )}
                </td>
              )}
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <span className="sub-header-info dashed">{t("contact.helpers.empty")}</span>
  );
};

export default ContactsView;
