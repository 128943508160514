import { Card } from "antd";
import React from "react";
import { CommissionsBatch } from "../../../../types";
import CommissionsBatchOutputAttachmentsTableView from "../views/CommissionsBatchOutputAttachmentsTableView";

interface Props {
  batch: CommissionsBatch;
}

const CommissionsBatchReviewStep = ({ batch }: Props) => (
  <Card className="card-box">
    <CommissionsBatchOutputAttachmentsTableView batch={batch} />
  </Card>
);

export default CommissionsBatchReviewStep;
