import { Card, Form, Modal, Tabs } from "antd";
import { TablePaginationConfig } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import AntIcon from "../../../common/components/icons/AntIcon";
import BackNavigationArrow from "../../../common/components/views/BackNavigationArrow";
import ItemCreatedUpdatedInfoView from "../../../common/components/views/ItemCreatedUpdatedInfoView";
import { PageSizes } from "../../../common/constants";
import HistoryView from "../../../common/modules/history/HistoryView";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";
import { Permission } from "../../../common/security/authorization/enums";
import { ActionProps, EntityIdObject, RootState } from "../../../common/types";
import { useFormErrorHandler } from "../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../common/utils/hooksUtils";
import { appendSearchParamsToURL, numberOrZero } from "../../../common/utils/utils";
import { selectHasPermissions } from "../../auth/ducks";
import {
  deleteStateContractPageAction,
  filterContractsActions,
  selectCurrentContractsPage
} from "../../contract/ducks";
import { ContractView } from "../../contract/enums";
import { ContractFilterPageResult } from "../../contract/types";
import { selectRouterLocationSearch } from "../../ducks";
import { ClientUserAccountWithUserAgentView } from "../../user/types";
import { requests } from "../api";
import ClientFormBody from "../components/forms/ClientFormBody";
import ClientTypeTag from "../components/tags/ClientTypeTag";
import ClientAttachmentsTableView from "../components/views/detail/ClientAttachmentsTableView";
import ClientDetailView from "../components/views/detail/ClientDetailView";
import ClientUserAccountsView from "../components/views/list/ClientUserAccountsView";
import {
  createUserWithClientUserAccountActions,
  deleteClientActions,
  deleteClientAttachmentActions,
  deleteClientUserAccountActions,
  deleteStateClientDetailAction,
  deleteStateClientUserAccountsAction,
  downloadClientAttachmentsAsZipActions,
  getClientActions,
  getClientUserAccountsActions,
  resendUserConfirmLinkActions,
  selectClientDetail,
  selectClientUserAccounts,
  updateClientActions,
  updateClientUserAccountActions,
  uploadClientAttachmentsActions
} from "../ducks";
import { Client, CreateUpdateClient } from "../types";

interface StateProps {
  client: Client;
  accounts: ClientUserAccountWithUserAgentView[];
  contractsPage: ContractFilterPageResult;
  hasClientUpdatePermission: boolean;
  hasClientAccountsPermission: boolean;
  urlSearchQuery: string;
}

interface ActionsMap {
  getClient: typeof getClientActions.request;
  updateClient: typeof updateClientActions.request;
  deleteClient: typeof deleteClientActions.request;
  deleteStateClientDetail: typeof deleteStateClientDetailAction;
  getClientUserAccounts: typeof getClientUserAccountsActions.request;
  createUserWithClientUserAccount: typeof createUserWithClientUserAccountActions.request;
  resendUserConfirmLink: typeof resendUserConfirmLinkActions.request;
  updateClientUserAccount: typeof updateClientUserAccountActions.request;
  deleteClientUserAccount: typeof deleteClientUserAccountActions.request;
  filterContracts: typeof filterContractsActions.request;
  deleteStateContractPage: typeof deleteStateContractPageAction;
  deleteStateClientUserAccounts: typeof deleteStateClientUserAccountsAction;
  downloadClientAttachmentsAsZip: typeof downloadClientAttachmentsAsZipActions.request;
  uploadClientAttachments: typeof uploadClientAttachmentsActions.request;
  deleteClientAttachment: typeof deleteClientAttachmentActions.request;
}

type Props = StateProps & ActionProps<ActionsMap>;

const TAB = {
  CLIENT: "client",
  HISTORY: "history",
  ACCOUNTS: "accounts",
  ATTACHMENTS: "attachments"
};

const ClientDetailContainer = ({ client, accounts, ...props }: Props) => {
  const { id } = useParams<EntityIdObject>();
  const navigate = useNavigate();

  const [form] = Form.useForm<CreateUpdateClient>();
  useFormErrorHandler(form, "client.attrs", [requests.UPDATE_CLIENT]);

  const [tabKey, setTabKey] = useState<string>(TAB.CLIENT);
  const [updateMode, setUpdateMode] = useState<boolean>(false);

  useRequestFinishedCallback([requests.UPDATE_CLIENT], () => setUpdateMode(false));

  useEffect(() => {
    if (client?.id !== id) {
      props.actions.getClient({ id });
    }

    const urlParams = new URLSearchParams(props.urlSearchQuery);
    props.actions.filterContracts({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.SMALL,
      report: ContractView.INTERNAL_GENERIC_REPORT,
      clientId: id
    });

    const urlTabKey = urlParams.get("tab");
    switch (urlTabKey) {
      case TAB.CLIENT:
      case TAB.HISTORY:
      case TAB.ATTACHMENTS:
        setTabKey(urlTabKey);
        break;
      case TAB.ACCOUNTS:
        setTabKey(props.hasClientAccountsPermission ? urlTabKey : TAB.CLIENT);
        break;
      default:
        setTabKey(TAB.CLIENT);
        break;
    }

    return () => {
      props.actions.deleteStateClientDetail();
      props.actions.deleteStateContractPage();
    };
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabKeyChange = (key: string): void => {
    if (tabKey === TAB.CLIENT && updateMode) {
      Modal.confirm({
        title: t("client.helpers.unsavedChanges"),
        okText: t("client.helpers.unsavedChangesConfirm"),
        cancelText: t("common.back"),
        onOk: () => moveViewToTab(key)
      });
    } else {
      moveViewToTab(key);
    }
  };

  const handleContractsTablePageChange = (pagination: TablePaginationConfig) => {
    const { report, clientId } = props.contractsPage;
    navigate(appendSearchParamsToURL({ pageIndex: pagination.current - 1 }), { replace: true });
    props.actions.filterContracts({
      pageIndex: pagination.current - 1,
      pageSize: pagination.pageSize,
      report,
      clientId
    });
  };

  const handleUpdateModeToggle = (): void => {
    setUpdateMode(!updateMode);
  };

  const handleUpdateFormSubmit = (clientData: CreateUpdateClient): void => {
    props.actions.updateClient({ id: client.id, object: clientData });
  };

  const moveViewToTab = (tabKey: string): void => {
    setTabKey(tabKey);
    navigate(appendSearchParamsToURL({ pageIndex: null, tab: tabKey }), { replace: true });
  };

  return (
    <ContentWrapper>
      <DisplayWrapper itemLoaded={!!client} notFoundCheckRequest={requests.GET_CLIENT}>
        {client && (
          <Card
            className="card-box"
            title={
              <>
                <BackNavigationArrow>
                  <h2 className="left-float">{client.aggregatedName}</h2>
                </BackNavigationArrow>
                <ClientTypeTag style={{ margin: "6px 0 0 8px" }} type={client.type} />
              </>
            }
          >
            <ItemCreatedUpdatedInfoView item={client} className="clear-both margin-bottom-medium" />

            <Tabs
              activeKey={tabKey}
              onChange={handleTabKeyChange}
              items={[
                {
                  key: TAB.CLIENT,
                  label: (
                    <span>
                      <AntIcon type="solution" />
                      {t("client.titles.data")}
                    </span>
                  ),
                  children: updateMode ? (
                    <Form form={form} layout="vertical" name="updateClientForm">
                      <ClientFormBody
                        form={form}
                        client={client}
                        viewType="form"
                        onFormSubmit={handleUpdateFormSubmit}
                        onCancelClick={handleUpdateModeToggle}
                      />
                    </Form>
                  ) : (
                    <ClientDetailView
                      client={client}
                      contractsPage={props.contractsPage}
                      onUpdateClick={handleUpdateModeToggle}
                      onDelete={props.actions.deleteClient}
                      onContractsTablePageChange={handleContractsTablePageChange}
                    />
                  )
                },
                {
                  key: TAB.HISTORY,
                  label: (
                    <span>
                      <AntIcon type="history" />
                      {t("client.titles.history")}
                    </span>
                  ),
                  children: <HistoryView item={client} translationRootPath="client.attrs" type="client" />
                },
                {
                  key: TAB.ATTACHMENTS,
                  label: (
                    <span>
                      <AntIcon type="paper-clip" />
                      {t("client.titles.attachments")}
                    </span>
                  ),
                  children: (
                    <ClientAttachmentsTableView
                      client={client}
                      onDownloadAsZip={props.actions.downloadClientAttachmentsAsZip}
                      onUpload={props.hasClientUpdatePermission ? props.actions.uploadClientAttachments : undefined}
                      onDelete={props.hasClientUpdatePermission ? props.actions.deleteClientAttachment : undefined}
                    />
                  )
                },
                props.hasClientAccountsPermission
                  ? {
                      key: TAB.ACCOUNTS,
                      label: (
                        <span>
                          <AntIcon type="key" />
                          {t("client.titles.accounts")}
                        </span>
                      ),
                      children: (
                        <ClientUserAccountsView
                          client={client}
                          accounts={accounts}
                          onGet={props.actions.getClientUserAccounts}
                          onCreate={props.actions.createUserWithClientUserAccount}
                          onResendConfirmLink={props.actions.resendUserConfirmLink}
                          onUpdate={props.actions.updateClientUserAccount}
                          onDelete={props.actions.deleteClientUserAccount}
                          onDeleteStateClientUserAccounts={props.actions.deleteStateClientUserAccounts}
                        />
                      )
                    }
                  : null
              ]}
            />
          </Card>
        )}
      </DisplayWrapper>
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  client: selectClientDetail(state),
  accounts: selectClientUserAccounts(state),
  contractsPage: selectCurrentContractsPage(state),
  hasClientUpdatePermission: selectHasPermissions(Permission.CLIENT_UPDATE)(state),
  hasClientAccountsPermission: selectHasPermissions(Permission.CLIENT_ACCOUNTS)(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      getClient: getClientActions.request,
      updateClient: updateClientActions.request,
      deleteClient: deleteClientActions.request,
      deleteStateClientDetail: deleteStateClientDetailAction,
      getClientUserAccounts: getClientUserAccountsActions.request,
      createUserWithClientUserAccount: createUserWithClientUserAccountActions.request,
      resendUserConfirmLink: resendUserConfirmLinkActions.request,
      updateClientUserAccount: updateClientUserAccountActions.request,
      deleteClientUserAccount: deleteClientUserAccountActions.request,
      deleteStateClientUserAccounts: deleteStateClientUserAccountsAction,
      filterContracts: filterContractsActions.request,
      deleteStateContractPage: deleteStateContractPageAction,
      downloadClientAttachmentsAsZip: downloadClientAttachmentsAsZipActions.request,
      uploadClientAttachments: uploadClientAttachmentsActions.request,
      deleteClientAttachment: deleteClientAttachmentActions.request
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ClientDetailContainer);
