import React from "react";
import Ellipsis from "../../../../../../common/components/views/Ellipsis";
import ContactTypeIcon from "../../../../../../common/modules/contact/ContactTypeIcon";
import { ContactType } from "../../../../../../common/modules/enums";
import { formatPhoneNumber } from "../../../../../../common/utils/formatUtils";

interface Props {
  email?: string;
  phone?: string;
}

export const DashboardContactFooterView = ({ phone, email }: Props) => (
  <div className="dashboard-contact-footer">
    <p className="dashboard-contact-footer__option">
      {email ? (
        <>
          <ContactTypeIcon type={ContactType.EMAIL} />
          <Ellipsis className="dashboard-contact-footer__option-detail" tooltip={email}>
            <a href={`mailto:${email}`}>{email}</a>
          </Ellipsis>
        </>
      ) : (
        <span>&nbsp;</span>
      )}
    </p>

    <p className="dashboard-contact-footer__option">
      {phone ? (
        <>
          <ContactTypeIcon type={ContactType.PHONE_NUMBER} />
          <Ellipsis className="dashboard-contact-footer__option-detail" tooltip={formatPhoneNumber(phone)}>
            <a href={`tel:${phone}`}>{formatPhoneNumber(phone)}</a>
          </Ellipsis>
        </>
      ) : (
        <span>&nbsp;</span>
      )}
    </p>
  </div>
);
