import { Badge, Dropdown } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { RootState } from "../../../../common/types";
import { selectNotificationsHeaderList } from "../../../../modules/notifications/ducks";
import { NotificationsHeaderList } from "../../../../modules/notifications/types";
import HeaderNotificationDropdown from "./HeaderNotificationDropdown";

const HeaderNotificationView = () => {
  const notificationsList = useSelector<RootState, NotificationsHeaderList>(selectNotificationsHeaderList);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Dropdown
      placement="bottomRight"
      dropdownRender={() => (
        <HeaderNotificationDropdown notificationsList={notificationsList} onDropdownClose={() => setOpen(false)} />
      )}
      overlayClassName="header-dropdown-container header-notification-dropdown"
      open={open}
      onOpenChange={setOpen}
      trigger={["click"]}
    >
      <span className="header__action header-notification-icon">
        <Badge count={notificationsList.totalUnseenCount} className="header-notification-icon__badge" title="">
          <AntIcon type="bell" className="header-notification-icon__badge-icon" />
        </Badge>
      </span>
    </Dropdown>
  );
};

export default HeaderNotificationView;
