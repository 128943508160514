import { Form, Select, Tag } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import { t } from "i18next";
import groupBy from "lodash/groupBy";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { DEFAULT_LOCALE } from "../../../../app/i18n";
import { RootState, UUID } from "../../../../common/types";
import { formatCommissionsLevelName } from "../../../../common/utils/formatUtils";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import { CommissionsSettingsLevelType } from "../../../commissions/level/enums";
import { CommissionsSettingsLevelBase } from "../../../commissions/level/types";
import { selectCommissionsSettingsLevelsEnums } from "../../ducks";
import { FilterFunction } from "../../types";

interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
  optionsProps?: CommissionsLevelOptionsProps;
}

interface CommissionsLevelOptionsProps {
  renderOnlyLevelCodeTag?: boolean;
  filterType?: CommissionsSettingsLevelType;
  filter?: FilterFunction<CommissionsSettingsLevelBase>;
}

const CommissionsLevelSelect = ({ formItemProps, selectProps, optionsProps }: Props) => {
  const commissionsLevelsEnums = useSelector<RootState, CommissionsSettingsLevelBase[]>(
    selectCommissionsSettingsLevelsEnums
  );

  const commissionsLevelToCodeMap = useMemo(
    () => new Map<UUID, string>(commissionsLevelsEnums.map(level => [level.id, level.code])),
    [commissionsLevelsEnums]
  );

  const resolveCommissionsLevelSelectOptions = useCallback((): CommissionsSettingsLevelBase[] => {
    let options = [...commissionsLevelsEnums];

    if (optionsProps?.filterType) {
      options = options.filter(level => level.type === optionsProps.filterType);
    }

    if (optionsProps?.filter) {
      options = options.filter(optionsProps.filter);
    }

    return options;
  }, [commissionsLevelsEnums, optionsProps]);

  const allOptions = resolveCommissionsLevelSelectOptions();

  if (allOptions.some(item => item.category)) {
    const optionGroupsWithCategory = groupBy(
      allOptions.filter(level => level.category),
      level => level.category
    );
    const optionsWithoutCategory = allOptions.filter(level => !level.category);

    return (
      <Form.Item {...formItemProps}>
        <Select
          {...selectStandardProps}
          {...selectProps}
          tagRender={
            optionsProps?.renderOnlyLevelCodeTag
              ? props => (
                  <Tag closable={props.closable} onClose={props.onClose}>
                    {commissionsLevelToCodeMap.get(props.value)}
                  </Tag>
                )
              : undefined
          }
        >
          {Object.keys(optionGroupsWithCategory)
            .sort((a, b) => a.localeCompare(b, DEFAULT_LOCALE, { sensitivity: "accent" }))
            .map((group, index) => (
              <Select.OptGroup label={group} key={index}>
                {optionGroupsWithCategory[group].map(option => (
                  <Select.Option key={option.id} value={option.id}>
                    {formatCommissionsLevelName(option)}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            ))}
          {optionsWithoutCategory.length > 0 && (
            <Select.OptGroup label={t("common.other")}>
              {optionsWithoutCategory.map(option => (
                <Select.Option key={option.id} value={option.id}>
                  {formatCommissionsLevelName(option)}
                </Select.Option>
              ))}
            </Select.OptGroup>
          )}
        </Select>
      </Form.Item>
    );
  }
  return (
    <Form.Item {...formItemProps}>
      <Select
        {...selectStandardProps}
        {...selectProps}
        tagRender={
          optionsProps?.renderOnlyLevelCodeTag
            ? props => (
                <Tag closable={props.closable} onClose={props.onClose}>
                  {commissionsLevelToCodeMap.get(props.value)}
                </Tag>
              )
            : undefined
        }
        options={allOptions.map(option => ({
          value: option.id,
          label: formatCommissionsLevelName(option)
        }))}
      />
    </Form.Item>
  );
};

export default CommissionsLevelSelect;
