import { Card } from "antd";
import React from "react";
import t from "../../../../../../../app/i18n";
import HtmlView from "../../../../../../../common/components/views/HtmlView";
import { formatLocaleDate } from "../../../../../../../common/utils/formatUtils";
import { tBoolean } from "../../../../../../../common/utils/translationUtils";
import { SecondPillarContract } from "../../../../../types";

interface Props {
  contract: SecondPillarContract;
}

const SecondPillarContractDataSection = ({ contract }: Props) => (
  <Card type="inner" className="card-box margin-top-medium" title={t("contract.sections.contractData")}>
    <table className="data-table-view">
      <tbody>
        <tr>
          <td>{t("contract.attrs.mediationReportSignDate")}:</td>
          <td>{formatLocaleDate(contract.mediationReportSignDate)}</td>
          <td>{t("contract.enums.secondPillarCreationType._label")}:</td>
          <td>{t("contract.enums.secondPillarCreationType." + contract.creationType)}</td>
          <td>{t("contract.attrs.transferredFromOtherBroker")}:</td>
          <td>{tBoolean(contract.transferredFromOtherBroker)}</td>
        </tr>
        <tr>
          <td>{t("contract.attrs.signDate")}:</td>
          <td>{formatLocaleDate(contract.signDate)}</td>
          <td>{t("contract.enums.secondPillarFundType._label")}:</td>
          <td>{t("contract.enums.secondPillarFundType." + contract.fundType)}</td>
          <td>{t("contract.attrs.transferredToOtherBroker")}:</td>
          <td>{formatLocaleDate(contract.transferredToOtherBrokerDate)}</td>
        </tr>
        <tr>
          <td>{t("contract.attrs.cancellationDate")}:</td>
          <td>{formatLocaleDate(contract.cancellationDate)}</td>
          <td colSpan={2} />
          <td colSpan={2} />
        </tr>
      </tbody>
    </table>

    <table className="data-table-view margin-top-tiny">
      <tbody>
        <tr>
          <td>{t("contract.attrs.note")}:</td>
        </tr>
        <tr>
          <td>
            <HtmlView value={contract.note} />
          </td>
        </tr>
      </tbody>
    </table>
  </Card>
);

export default SecondPillarContractDataSection;
