import { Card, Cascader, Checkbox, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select } from "antd";
import moment from "moment/moment";
import { DefaultOptionType } from "rc-select/lib/Select";
import React from "react";
import t from "../../../../../../../app/i18n";
import InputAddon from "../../../../../../../common/components/form/addons/InputAddon";
import HiddenCheckbox from "../../../../../../../common/components/form/components/HiddenCheckbox";
import LabelWithTooltip from "../../../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../../../common/constants";
import { formatCoverageLimitValues, formatLocaleCurrency } from "../../../../../../../common/utils/formatUtils";
import {
  datePickerStandardProps,
  disableDatePickerOutOfInterval,
  inputNumberIntegerStandardProps,
  licensePlateNormalizeFunction,
  selectStandardProps,
  toMoment,
  upperCaseStringNormalizeFunction
} from "../../../../../../../common/utils/formUtils";
import { regexPatterns, validations } from "../../../../../../../common/utils/validationUtils";
import { ClientType } from "../../../../../../client/enums";
import { PaymentFrequency } from "../../../../../../contract/enums";
import CityAutoComplete from "../../../../../../enumerations/components/form/CityAutoComplete";
import { InstitutionEnum } from "../../../../../../institution/enums";
import { CalcType, ClientExperience } from "../../../../../enums";
import { CalcResult } from "../../../../types";
import { filterApplicableSuccessResults } from "../../../../utils";
import {
  CrashCalcResultData,
  GapCalcResultData,
  MtplCalcResultData,
  MtplCrashCalcResultData,
  VehicleCalc,
  VehicleCalcResultData,
  VehicleCalcResults,
  VehicleGenGeneralData
} from "../../../types";
import { getVehicleCalcResultsByType, resolveGapCoverageLimitOptions } from "../../../utils";

interface Props {
  policyHolderType: ClientType;
  calcData: VehicleCalc;
  calcResults: VehicleCalcResults;
  selectedResult: CalcResult<VehicleCalcResultData>;
}

const VehicleGenOtherDataSection = ({ policyHolderType, calcData, calcResults, selectedResult }: Props) => {
  const resolveRecommendedResultOptions = (): DefaultOptionType[] => {
    return getVehicleCalcResultsByType(calcResults, calcType)
      .filter(resultsRow => filterApplicableSuccessResults(resultsRow).length > 0)
      .map<DefaultOptionType>(resultsRow => {
        switch (calcType) {
          case CalcType.MTPL: {
            const results = filterApplicableSuccessResults(resultsRow as CalcResult<MtplCalcResultData>[]);
            return {
              label: results[0].coverage
                ? results[0].insuranceInstitution.name
                : results[0].insuranceInstitution.name +
                  ` (${formatCoverageLimitValues(
                    results[0].data.healthCoverageLimit,
                    results[0].data.propertyCoverageLimit
                  )})`,
              value: results[0].insuranceInstitution.institutionEnum,
              children: results[0].coverage
                ? results.map<DefaultOptionType>(result => ({
                    label: `${result.coverage} (${formatCoverageLimitValues(
                      result.data.healthCoverageLimit,
                      result.data.propertyCoverageLimit
                    )})`,
                    value: result.coverage
                  }))
                : undefined
            };
          }
          case CalcType.CRASH: {
            const results = filterApplicableSuccessResults(resultsRow as CalcResult<CrashCalcResultData>[]);
            return {
              label: results[0].insuranceInstitution.name,
              value: results[0].insuranceInstitution.institutionEnum,
              children: results.map<DefaultOptionType>(result => ({
                label: `${t("calc.vehicle.results.complicity")} ${result.data.complicity}`,
                value: result.coverage
              }))
            };
          }
          case CalcType.MTPL_CRASH: {
            const results = filterApplicableSuccessResults(resultsRow as CalcResult<MtplCrashCalcResultData>[]);
            return {
              label: results[0].insuranceInstitution.name,
              value: results[0].insuranceInstitution.institutionEnum,
              children: results.map<DefaultOptionType>(result => ({
                label:
                  (institutionEnum === InstitutionEnum.KOOPERATIVA ? `${result.coverage} | ` : "") +
                  `${result.data.complicity} | ${formatCoverageLimitValues(
                    result.data.healthCoverageLimit,
                    result.data.propertyCoverageLimit
                  )}`,
                value: result.coverage
              }))
            };
          }
          case CalcType.GAP: {
            const results = filterApplicableSuccessResults(resultsRow as CalcResult<GapCalcResultData>[]);
            return {
              label: results[0].insuranceInstitution.name,
              value: results[0].insuranceInstitution.institutionEnum,
              children:
                results[0].insuranceInstitution.institutionEnum === InstitutionEnum.COLONNADE
                  ? [
                      {
                        label: t("calc.vehicle.helpers.gapWithComplicityReinsurance"),
                        value: true
                      },
                      {
                        label: t("calc.vehicle.helpers.gapWithoutComplicityReinsurance"),
                        value: false
                      }
                    ]
                  : results[0].insuranceInstitution.institutionEnum === InstitutionEnum.DEFEND
                    ? resolveGapCoverageLimitOptions(
                        toMoment(calcData.clientsData.holderRegistrationDate)
                      ).map<DefaultOptionType>(coverageLimit => ({
                        label: `${t("calc.vehicle.results.coverageLimit")} ${t(
                          "calc.vehicle.enums.gapCoverageLimit." + coverageLimit
                        )}`,
                        value: coverageLimit
                      }))
                    : null
            };
          }
        }
        return null;
      });
  };

  const { crossSelling } = calcData.generalData;
  const { institutionEnum } = selectedResult.insuranceInstitution;
  const { calcType } = selectedResult;

  const policyHolderIsCompany = policyHolderType === ClientType.SELF_EMPLOYED || policyHolderType === ClientType.LEGAL;
  const mtplDateFrom = toMoment(calcData.generalData.effectiveBeginningDate);
  const mtplDateTo = toMoment(calcData.generalData.effectiveBeginningDate).add(1, "years").subtract(1, "days");

  const colSpan = 4;
  const bigColSpan = 8;

  return (
    <Card type="inner" className="card-box" title={t("calc.vehicle.sections.otherData")}>
      <Row gutter={rowGutter}>
        {(institutionEnum === InstitutionEnum.GENERALI ||
          institutionEnum === InstitutionEnum.KOMUNALNA ||
          institutionEnum === InstitutionEnum.KOOPERATIVA) && (
          <Col span={colSpan}>
            <CityAutoComplete
              formItemProps={{
                name: ["generalData", "signCity"],
                label: t("calc.vehicle.attrs.generalData.signCity"),
                rules: [validations.notBlank, validations.size(1, 64), validations.pattern(regexPatterns.wordRegex)]
              }}
            />
          </Col>
        )}

        {institutionEnum === InstitutionEnum.UNIQA &&
          calcData.clientsData.leasing &&
          (calcType === CalcType.CRASH || calcType === CalcType.MTPL_CRASH) && (
            <Col span={colSpan}>
              <Form.Item
                name={["generalData", "loanNumber"]}
                label={t("calc.vehicle.attrs.generalData.loanNumber")}
                rules={[validations.notBlank, validations.size(1, 64)]}
              >
                <Input />
              </Form.Item>
            </Col>
          )}

        {institutionEnum === InstitutionEnum.UNION && crossSelling.unionHealthContract && (
          <Col span={colSpan + 1}>
            <Form.Item
              name={["generalData", "crossSelling", "unionHealthContractNumber"]}
              label={
                <LabelWithTooltip
                  label={t("calc.vehicle.attrs.generalData.crossSelling.unionHealthContractNumber")}
                  tooltip={t("calc.vehicle.helpers.unionHealthContractNumberDesc")}
                />
              }
              rules={[validations.notBlank, validations.size(4, 15)]}
            >
              <Input />
            </Form.Item>
          </Col>
        )}

        {institutionEnum === InstitutionEnum.WUSTENROT && calcType === CalcType.CRASH && (
          <Col span={colSpan}>
            <Form.Item
              name={["generalData", "childrenUnder15InVehicle"]}
              className="two-line-form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox>{t("calc.vehicle.attrs.generalData.childrenUnder15InVehicle")}</Checkbox>
            </Form.Item>
          </Col>
        )}
      </Row>

      {institutionEnum === InstitutionEnum.ALLIANZ && calcType === CalcType.MTPL_CRASH && (
        <>
          <Divider className="divider-subheader">{t("calc.vehicle.helpers.mtplOtherData")}</Divider>
          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <Form.Item
                name={["generalData", "mtplInsuranceEffectiveBeginningDate"]}
                label={t("calc.vehicle.attrs.generalData.mtplInsuranceEffectiveBeginningDate")}
                rules={[validations.notNull, validations.dateInInterval(mtplDateFrom, mtplDateTo)]}
              >
                <DatePicker
                  {...datePickerStandardProps}
                  disabledDate={current => disableDatePickerOutOfInterval(current, mtplDateFrom, mtplDateTo)}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      {(institutionEnum === InstitutionEnum.COLONNADE || institutionEnum === InstitutionEnum.DEFEND) &&
        calcType === CalcType.GAP && (
          <>
            <Divider className="divider-subheader">{t("calc.vehicle.sections.gapData")}</Divider>

            <Row gutter={rowGutter}>
              {institutionEnum === InstitutionEnum.DEFEND && (
                <HiddenCheckbox
                  name={["generalData", "gapComplicityReinsurance"]}
                  initialValue={(selectedResult.data as GapCalcResultData).options[0].complicityReinsurance}
                />
              )}
              {institutionEnum === InstitutionEnum.COLONNADE && (
                <Col span={colSpan}>
                  <Form.Item
                    name={["generalData", "gapComplicityReinsurance"]}
                    className="form-item-without-label"
                    valuePropName="checked"
                    rules={[validations.none]}
                    initialValue={false}
                  >
                    <Checkbox>{t("calc.vehicle.attrs.generalData.gapComplicityReinsurance")}</Checkbox>
                  </Form.Item>
                </Col>
              )}
              <Col span={colSpan}>
                <Form.Item
                  name={["generalData", "gapPaymentFrequency"]}
                  label={t("calc.vehicle.helpers.gapPaymentFrequencyLabel")}
                  rules={[validations.notNull]}
                >
                  <Select
                    {...selectStandardProps}
                    options={[PaymentFrequency.ONCE, PaymentFrequency.ANNUALLY].map(frequency => ({
                      value: frequency,
                      label: t("contract.enums.paymentFrequency." + frequency)
                    }))}
                  />
                </Form.Item>
              </Col>
              <Form.Item
                noStyle
                shouldUpdate={(prev, next) =>
                  prev.generalData.gapComplicityReinsurance !== next.generalData.gapComplicityReinsurance ||
                  prev.generalData.gapPaymentFrequency !== next.generalData.gapPaymentFrequency
                }
              >
                {({ getFieldValue }) => {
                  const { gapComplicityReinsurance, gapPaymentFrequency } = getFieldValue([
                    "generalData"
                  ]) as VehicleGenGeneralData;
                  const option = (selectedResult.data as GapCalcResultData).options?.find(
                    option =>
                      option.complicityReinsurance === gapComplicityReinsurance &&
                      option.paymentFrequency === gapPaymentFrequency
                  );

                  return (
                    option && (
                      <Col span={colSpan * 3} className="form-item-without-label">
                        {t("calc.vehicle.results.annualPremium")}: <b>{formatLocaleCurrency(option.annualPremium)}</b>
                        {option.paymentFrequency === PaymentFrequency.ANNUALLY && (
                          <>
                            {" "}
                            | {t("calc.vehicle.helpers.gapAnnualPremiumSum")}:{" "}
                            <b>{formatLocaleCurrency(option.premium)}</b>
                          </>
                        )}
                      </Col>
                    )
                  );
                }}
              </Form.Item>
            </Row>
          </>
        )}

      {institutionEnum === InstitutionEnum.CSOB && calcType === CalcType.CRASH && (
        <>
          <Divider className="divider-subheader">{t("calc.vehicle.sections.conflictOfInterestsStatement")}</Divider>

          <Row gutter={rowGutter}>
            <Col span={colSpan * 3}>
              <Form.Item
                name={["generalData", "agentIsClosePersonToClient"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>
                  <LabelWithTooltip
                    label={t("calc.vehicle.attrs.generalData.agentIsClosePersonToClient")}
                    tooltip={t("calc.vehicle.helpers.agentIsClosePersonToClientDesc")}
                  />
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      {institutionEnum === InstitutionEnum.UNIQA && calcData.reinsurancesData.uniqaSidecarMtpl && (
        <>
          <Divider className="divider-subheader">
            {t("calc.vehicle.attrs.reinsurancesData.uniqaSidecarMtplData._label")}
          </Divider>

          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <Form.Item
                name={["reinsurancesData", "uniqaSidecarMtplData", "licensePlate"]}
                label={t("calc.vehicle.attrs.reinsurancesData.uniqaSidecarMtplData.licensePlate")}
                rules={[validations.fullLicensePlate]}
                normalize={licensePlateNormalizeFunction}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item
                name={["reinsurancesData", "uniqaSidecarMtplData", "vin"]}
                label={t("calc.vehicle.attrs.reinsurancesData.uniqaSidecarMtplData.vin")}
                rules={[validations.notBlank, validations.size(3, 17)]}
                normalize={upperCaseStringNormalizeFunction}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item
                name={["reinsurancesData", "uniqaSidecarMtplData", "registrationCertificateNumber"]}
                label={
                  <LabelWithTooltip
                    label={t("calc.vehicle.attrs.reinsurancesData.uniqaSidecarMtplData.registrationCertificateNumber")}
                    tooltip={t("calc.vehicle.helpers.registrationCertificateNumberDesc")}
                  />
                }
                rules={[validations.pattern(regexPatterns.registrationCertificateNumberRegex), validations.length(8)]}
                normalize={upperCaseStringNormalizeFunction}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item
                name={["reinsurancesData", "uniqaSidecarMtplData", "totalWeight"]}
                label={t("calc.vehicle.attrs.reinsurancesData.uniqaSidecarMtplData.totalWeight")}
                rules={[validations.notNull, validations.minNumber(1), validations.maxNumber(750)]}
              >
                <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="weight" />} />
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item
                name={["reinsurancesData", "uniqaSidecarMtplData", "firstRegistrationYear"]}
                label={t("calc.vehicle.attrs.reinsurancesData.uniqaSidecarMtplData.firstRegistrationYear")}
                rules={[validations.notNull, validations.minNumber(1900), validations.maxNumber(moment().year())]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      <Divider className="divider-subheader">{t("calc.vehicle.sections.financialMediationData")}</Divider>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={["financialMediationData", "clientExperience"]}
            label={t("calc.enums.clientExperience._label")}
            rules={[validations.notNull]}
            initialValue={policyHolderIsCompany ? ClientExperience.SUFFICIENT : undefined}
          >
            <Select
              {...selectStandardProps}
              disabled={policyHolderIsCompany}
              options={(policyHolderIsCompany
                ? [ClientExperience.SUFFICIENT]
                : [ClientExperience.NONE, ClientExperience.MINIMAL]
              ).map(experience => ({
                value: experience,
                label: t("calc.enums.clientExperience." + experience)
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={bigColSpan}>
          <Form.Item
            name={["financialMediationData", "otherClientRequirements"]}
            label={t("calc.vehicle.attrs.financialMediationData.otherClientRequirements")}
            rules={[validations.none]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      {(calcType === CalcType.MTPL ||
        calcType === CalcType.CRASH ||
        calcType === CalcType.MTPL_CRASH ||
        calcType === CalcType.GAP) && (
        <Row gutter={rowGutter}>
          <Col span={bigColSpan}>
            <Form.Item
              name={["financialMediationData", "recommendedResult"]}
              label={t("calc.vehicle.attrs.financialMediationData.recommendedResult")}
              rules={[validations.notNull]}
            >
              <Cascader allowClear={false} expandTrigger="hover" options={resolveRecommendedResultOptions()} />
            </Form.Item>
          </Col>

          <Col span={bigColSpan}>
            <Form.Item
              name={["financialMediationData", "recommendationReason"]}
              label={t("calc.vehicle.attrs.financialMediationData.recommendationReason")}
              rules={[validations.none]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={rowGutter}>
        <Col span={bigColSpan}>
          <Form.Item
            name={["financialMediationData", "additionalClientStatement"]}
            label={t("calc.vehicle.attrs.financialMediationData.additionalClientStatement")}
            rules={[validations.none]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={colSpan * 2}>
          <Form.Item
            name={["financialMediationData", "additionalSuitabilityStatement"]}
            label={
              <LabelWithTooltip
                label={t("calc.vehicle.attrs.financialMediationData.additionalSuitabilityStatement")}
                tooltip={t("calc.vehicle.helpers.additionalSuitabilityStatementDesc")}
              />
            }
            rules={[validations.none]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default VehicleGenOtherDataSection;
