import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { EntityIdObject, EntityObject, NormalizedError, RootState } from "../../common/types";
import { initSearchPageResult } from "../../common/utils/apiUtils";
import messageUtils from "../../common/utils/messageUtils";
import { replaceInArray } from "../../common/utils/utils";
import { changeRunningRequestKeyAction } from "../ducks";
import api from "./api";
import {
  ConfigPropertyReducerState,
  CreateUpdateDynamicConfigProperty,
  DynamicConfigProperty,
  DynamicConfigPropertyFilterPageRequest,
  DynamicConfigPropertyFilterPageResult
} from "./types";

/**
 * ACTIONS
 */
export const filterConfigPropertiesActions = createAsyncAction(
  "config-property/FILTER_REQUEST",
  "config-property/FILTER_SUCCESS",
  "config-property/FILTER_FAILURE"
)<DynamicConfigPropertyFilterPageRequest, DynamicConfigPropertyFilterPageResult, NormalizedError>();

export const createConfigPropertyActions = createAsyncAction(
  "config-property/CREATE_REQUEST",
  "config-property/CREATE_SUCCESS",
  "config-property/CREATE_FAILURE"
)<CreateUpdateDynamicConfigProperty, DynamicConfigProperty, NormalizedError>();

export const updateConfigPropertyActions = createAsyncAction(
  "config-property/UPDATE_REQUEST",
  "config-property/UPDATE_SUCCESS",
  "config-property/UPDATE_FAILURE"
)<EntityObject<CreateUpdateDynamicConfigProperty>, DynamicConfigProperty, NormalizedError>();

export const deleteConfigPropertyActions = createAsyncAction(
  "config-property/DELETE_REQUEST",
  "config-property/DELETE_SUCCESS",
  "config-property/DELETE_FAILURE"
)<EntityIdObject, void, NormalizedError>();

export const deleteStateConfigPropertiesPageAction = createAction("config-property/DELETE_STATE_LIST")<void>();

const actions = {
  filterConfigPropertiesActions,
  createConfigPropertyActions,
  updateConfigPropertyActions,
  deleteConfigPropertyActions,
  deleteStateConfigPropertiesPageAction
};

export type ConfigPropertyAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: ConfigPropertyReducerState = {
  currentPage: {
    ...initSearchPageResult<DynamicConfigProperty>(),
    ownedByIds: []
  }
};

const currentPageReducer = createReducer(initialState.currentPage)
  .handleAction(filterConfigPropertiesActions.success, (_, { payload }) => payload)
  .handleAction(updateConfigPropertyActions.success, (state, { payload }) => ({
    ...state,
    pageData: replaceInArray(
      state.pageData,
      item => item.id === payload.id,
      () => payload
    )
  }))
  .handleAction(
    [filterConfigPropertiesActions.failure, deleteStateConfigPropertiesPageAction],
    () => initialState.currentPage
  );

export const configPropertyReducer = combineReducers<ConfigPropertyReducerState>({ currentPage: currentPageReducer });

/**
 * SELECTORS
 */
const selectConfigProperty = (state: RootState): ConfigPropertyReducerState => state.configProperty;

export const selectConfigPropertiesCurrentPage = (state: RootState): DynamicConfigPropertyFilterPageResult =>
  selectConfigProperty(state).currentPage;

/**
 * SAGAS
 */
function* filterConfigProperties({ payload }: ReturnType<typeof filterConfigPropertiesActions.request>) {
  try {
    const response: AxiosResponse<DynamicConfigPropertyFilterPageResult> = yield call(
      api.filterConfigProperties,
      payload
    );
    yield put(filterConfigPropertiesActions.success(response.data));
  } catch (error) {
    yield put(filterConfigPropertiesActions.failure(error));
  }
}

function* createConfigProperty({ payload }: ReturnType<typeof createConfigPropertyActions.request>) {
  try {
    const response: AxiosResponse<DynamicConfigProperty> = yield call(api.createConfigProperty, payload);
    yield put(createConfigPropertyActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();
    yield* refreshCurrentPage();
  } catch (error) {
    yield put(createConfigPropertyActions.failure(error));
  }
}

function* updateConfigProperty({ payload }: ReturnType<typeof updateConfigPropertyActions.request>) {
  try {
    const response: AxiosResponse<DynamicConfigProperty> = yield call(api.updateConfigProperty, payload);
    yield put(updateConfigPropertyActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(updateConfigPropertyActions.failure(error));
  }
}

function* deleteConfigProperty({ payload }: ReturnType<typeof deleteConfigPropertyActions.request>) {
  try {
    yield call(api.deleteConfigProperty, payload);
    yield put(deleteConfigPropertyActions.success());
    messageUtils.itemDeletedNotification();
    yield* refreshCurrentPage(true);
  } catch (error) {
    yield put(deleteConfigPropertyActions.failure(error));
  }
}

function* refreshCurrentPage(movePage?: boolean) {
  const currentPage: DynamicConfigPropertyFilterPageResult = yield select(selectConfigPropertiesCurrentPage);
  yield put(
    filterConfigPropertiesActions.request({
      pageIndex: movePage
        ? currentPage.pageElementsCount === 1
          ? Math.max(currentPage.pageIndex - 1, 0)
          : currentPage.pageIndex
        : currentPage.pageIndex,
      pageSize: currentPage.pageSize,
      keyword: currentPage.keyword,
      ownedByIds: currentPage.ownedByIds
    })
  );
}

export function* configPropertySaga() {
  yield takeLatest(filterConfigPropertiesActions.request, filterConfigProperties);
  yield takeLatest(createConfigPropertyActions.request, createConfigProperty);
  yield takeLatest(updateConfigPropertyActions.request, updateConfigProperty);
  yield takeLatest(deleteConfigPropertyActions.request, deleteConfigProperty);
}
