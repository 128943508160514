import { Tag, Tooltip } from "antd";
import React from "react";
import t from "../../../app/i18n";
import { Status } from "../../types";
import AntIcon from "../icons/AntIcon";

interface Props {
  status: Status;
  tooltip?: string;
  onClick?(status: Status): void;
}

const StatusTag = ({ status, tooltip, onClick }: Props) => {
  let icon;

  switch (status) {
    case "success":
      icon = <AntIcon type="check-circle" />;
      break;
    case "processing":
      icon = <AntIcon type="sync" spin />;
      break;
    case "warning":
      icon = <AntIcon type="exclamation" />;
      break;
    case "error":
      icon = <AntIcon type="close-circle" />;
      break;
  }

  const tag = (
    <Tag color={status} icon={icon} className={onClick ? "cursor-pointer" : null} onClick={() => onClick?.(status)}>
      {t("common.status." + status)}
    </Tag>
  );

  return tooltip ? <Tooltip title={tooltip}>{tag}</Tooltip> : tag;
};

export default StatusTag;
