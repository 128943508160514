import { Button, Card, Checkbox, Col, DatePicker, Form, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../common/constants";
import { RootState } from "../../../../common/types";
import {
  datePickerClearableProps,
  disableDatePickerFuture,
  mapInstitutionTreeSelectValuesToInstitutionIds,
  mapProductTreeSelectValuesToProductIds,
  momentToIsoDateString,
  selectStandardProps,
  toMoment,
  toMomentArray
} from "../../../../common/utils/formUtils";
import { validations } from "../../../../common/utils/validationUtils";
import AgentSelect from "../../../enumerations/components/form/AgentSelect";
import CommissionsLevelSelect from "../../../enumerations/components/form/CommissionsLevelSelect";
import InstitutionTreeSelect from "../../../enumerations/components/form/InstitutionTreeSelect";
import ProductTreeSelect from "../../../enumerations/components/form/ProductTreeSelect";
import { selectInstitutionsEnums, selectProductGroupsEnums } from "../../../enumerations/ducks";
import { InstitutionWithSettings, ProductGroupWithProducts } from "../../../enumerations/types";
import { PointsReportType } from "../enums";
import { PointsReportFilterRequest, PointsReportFilterResult } from "../types";

interface Props {
  filter: PointsReportFilterResult;
  onFilterSubmit: (filter: PointsReportFilterRequest) => void;
}

const PointsReportFilterView = ({ filter, onFilterSubmit }: Props) => {
  const [form] = Form.useForm<PointsReportFilterRequest>();

  const institutionsEnums = useSelector<RootState, InstitutionWithSettings[]>(selectInstitutionsEnums);
  const productGroupsEnums = useSelector<RootState, ProductGroupWithProducts[]>(selectProductGroupsEnums);

  useEffect(() => {
    if (filter) {
      form.setFieldsValue({
        periodDateRange: toMomentArray([filter.periodStartDate, filter.periodEndDate]),
        institutionIds: filter.institutionIds || [],
        productIds: filter.productIds || [],
        commissionsSettingsLevelIds: filter.commissionsSettingsLevelIds || [],
        agentCreatedAtDateMin: toMoment(filter.agentCreatedAtDateMin),
        reportType: filter.reportType,
        rootAgentId: filter.rootAgentId,
        onlyDirectSubordinates: filter.onlyDirectSubordinates,
        includeDeactivated: filter.includeDeactivated,
        includeRepresentatives: filter.includeRepresentatives,
        includeNonGainers: filter.includeNonGainers,
        includeWithoutPoints: filter.includeWithoutPoints
      });
    }
  }, [filter, form]);

  const handleFilterSubmit = (filter: PointsReportFilterRequest): void => {
    const periodStartDate = toMoment(filter.periodDateRange?.[0])?.startOf("month");
    const periodEndDate = toMoment(filter.periodDateRange?.[1])?.endOf("month");
    const today = moment();

    const processedFilter = {
      ...filter,
      periodStartDate: momentToIsoDateString(periodStartDate),
      periodEndDate: momentToIsoDateString(
        periodStartDate && (periodEndDate?.isBefore(today, "day") ? periodEndDate : today)
      ),
      institutionIds: mapInstitutionTreeSelectValuesToInstitutionIds(filter.institutionIds, institutionsEnums),
      productIds: mapProductTreeSelectValuesToProductIds(filter.productIds, productGroupsEnums),
      agentCreatedAtDateMin: momentToIsoDateString(toMoment(filter.agentCreatedAtDateMin))
    } as PointsReportFilterRequest;

    delete processedFilter.periodDateRange;

    onFilterSubmit(processedFilter);
  };

  const handleFilterClear = (): void => {
    form.resetFields();
    handleFilterSubmit(form.getFieldsValue());
  };

  const smallColSpan = 3;
  const colSpan = 4;
  const bigColSpan = 5;

  return (
    <Card
      className="card-box"
      title={<h2>{t("commissions.points.titles.report")}</h2>}
      extra={<ActionTextIcon icon="reload" color="red" onClick={handleFilterClear} text={t("common.resetFilter")} />}
    >
      <Form form={form} layout="vertical" onFinish={handleFilterSubmit} name="pointsReportForm">
        <Row gutter={rowGutter} justify="center">
          <Col flex="1650px">
            <Row gutter={rowGutter} justify="start">
              <Col span={bigColSpan}>
                <Form.Item
                  name="periodDateRange"
                  label={t("commissions.points.filter.periodDateRange")}
                  rules={[validations.none]}
                >
                  <DatePicker.RangePicker
                    format="MMMM YYYY"
                    disabledDate={current => disableDatePickerFuture(current)}
                    placeholder={[t("common.from"), t("common.to")]}
                    picker="month"
                  />
                </Form.Item>
              </Col>

              <Col span={colSpan}>
                <InstitutionTreeSelect
                  formItemProps={{
                    name: "institutionIds",
                    label: t("commissions.points.filter.institutionIds")
                  }}
                />
              </Col>

              <Col span={colSpan}>
                <ProductTreeSelect
                  formItemProps={{
                    name: "productIds",
                    label: t("commissions.points.filter.productIds")
                  }}
                />
              </Col>

              <Col span={colSpan}>
                <CommissionsLevelSelect
                  formItemProps={{
                    name: "commissionsSettingsLevelIds",
                    label: t("agent.filter.commissionsSettingsLevelIds"),
                    rules: [validations.none]
                  }}
                  selectProps={{
                    allowClear: true,
                    mode: "multiple",
                    maxTagCount: "responsive"
                  }}
                  optionsProps={{ renderOnlyLevelCodeTag: true }}
                />
              </Col>

              <Col span={smallColSpan}>
                <Form.Item
                  name="agentCreatedAtDateMin"
                  label={t("agent.filter.agentCreatedAtDateMin")}
                  rules={[validations.notFuture]}
                >
                  <DatePicker
                    {...datePickerClearableProps}
                    disabledDate={current => disableDatePickerFuture(current)}
                  />
                </Form.Item>
              </Col>

              <Col span={colSpan}>
                <Form.Item
                  name="reportType"
                  label={t("commissions.points.enums.reportType._label")}
                  rules={[validations.notNull]}
                >
                  <Select
                    {...selectStandardProps}
                    options={Object.keys(PointsReportType).map(type => ({
                      value: type,
                      label: t("commissions.points.enums.reportType." + type)
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={rowGutter} justify="start">
              <Col span={bigColSpan}>
                <AgentSelect
                  formItemProps={{
                    name: "rootAgentId",
                    label: t("agent.filter.rootAgentId")
                  }}
                  selectProps={{ allowClear: true }}
                />
              </Col>

              <Col span={smallColSpan}>
                <Form.Item
                  name="onlyDirectSubordinates"
                  className="form-item-without-label"
                  valuePropName="checked"
                  rules={[validations.none]}
                  initialValue={false}
                >
                  <Checkbox>{t("agent.filter.onlyDirectSubordinates")}</Checkbox>
                </Form.Item>
              </Col>

              <Col span={smallColSpan}>
                <Form.Item
                  name="includeDeactivated"
                  className="form-item-without-label"
                  valuePropName="checked"
                  rules={[validations.none]}
                  initialValue={false}
                >
                  <Checkbox>{t("agent.filter.includeDeactivated")}</Checkbox>
                </Form.Item>
              </Col>

              <Col span={smallColSpan}>
                <Form.Item
                  name="includeRepresentatives"
                  className="form-item-without-label"
                  valuePropName="checked"
                  rules={[validations.none]}
                  initialValue={false}
                >
                  <Checkbox>{t("agent.filter.includeRepresentatives")}</Checkbox>
                </Form.Item>
              </Col>

              <Col span={smallColSpan}>
                <Form.Item
                  name="includeNonGainers"
                  className="form-item-without-label"
                  valuePropName="checked"
                  rules={[validations.none]}
                  initialValue={false}
                >
                  <Checkbox>{t("agent.filter.includeNonGainers")}</Checkbox>
                </Form.Item>
              </Col>

              <Col span={colSpan}>
                <Form.Item
                  name="includeWithoutPoints"
                  className="form-item-without-label"
                  valuePropName="checked"
                  rules={[validations.none]}
                  initialValue={false}
                >
                  <Checkbox>{t("commissions.points.filter.includeWithoutPoints")}</Checkbox>
                </Form.Item>
              </Col>

              <Col span={smallColSpan} className="no-title-space right-align">
                <Form.Item>
                  <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                    {t("common.filter")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default PointsReportFilterView;
