import { green, red } from "@ant-design/colors";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import t from "../../../../../app/i18n";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import InfoIcon from "../../../../../common/components/icons/InfoIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import TableActionsView from "../../../../../common/components/views/TableActionsView";
import { PageSizes, TableSizes } from "../../../../../common/constants";
import { UUID } from "../../../../../common/types";
import { formatLocaleDate } from "../../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../common/utils/utils";
import {
  createAgentLicenseActions,
  deleteAgentLicenseActions,
  getAgentLicensesActions,
  updateAgentLicenseActions
} from "../../../ducks";
import { AgentLicense } from "../../../types";
import AgentLicenseForm from "../../forms/AgentLicenseForm";

interface Props {
  agentId: UUID;
  defaultLicenseValue?: string;
  licenses: AgentLicense[];
  showAllColumns?: boolean;
  showActions?: boolean;
  onGet: typeof getAgentLicensesActions.request;
  onCreate?: typeof createAgentLicenseActions.request;
  onUpdate?: typeof updateAgentLicenseActions.request;
  onDelete?: typeof deleteAgentLicenseActions.request;
}

const AgentLicenseTableView = ({
  agentId,
  defaultLicenseValue,
  licenses,
  showAllColumns,
  showActions,
  ...props
}: Props) => {
  const [licenseFormOpen, setLicenseFormOpen] = useState<boolean>(false);
  const [licenseToUpdate, setLicenseToUpdate] = useState<AgentLicense>();

  const today = moment();

  useEffect(() => {
    props.onGet({ id: agentId });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLicenseUpdateClick = (license: AgentLicense): void => {
    setLicenseToUpdate(license);
    setLicenseFormOpen(true);
  };

  const handleLicenseFormCancel = (): void => {
    setLicenseToUpdate(null);
    setLicenseFormOpen(false);
  };

  const columns: ColumnsType<AgentLicense> = [
    {
      key: "sector",
      title: t("agent.enums.financialSector._label"),
      width: 170,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{t("agent.enums.financialSector." + record.sector)}</Ellipsis>
    },
    {
      key: "value",
      title: t("agent.license.attrs.value"),
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.value}</Ellipsis>
    },
    {
      key: "registrationProposalDate",
      title: t("agent.license.helpers.registrationProposalDateShort"),
      width: 100,
      render: (_, record) => formatLocaleDate(record.registrationProposalDate)
    },
    {
      key: "registrationDate",
      title: t("agent.license.helpers.registrationDateShort"),
      width: 100,
      render: (_, record) => formatLocaleDate(record.registrationDate)
    },
    {
      key: "deletionProposalDate",
      title: t("agent.license.helpers.deletionProposalDateShort"),
      width: 100,
      render: (_, record) => formatLocaleDate(record.deletionProposalDate)
    },
    {
      key: "deletionDate",
      title: t("agent.license.helpers.deletionDateShort"),
      width: 100,
      render: (_, record) => formatLocaleDate(record.deletionDate)
    },
    {
      key: "active",
      title: t("agent.license.helpers.active"),
      align: "center",
      width: 70,
      render: (_, record) =>
        (record.registrationDate || record.registrationProposalDate
          ? today.isSameOrAfter(record.registrationDate || record.registrationProposalDate, "day")
          : true) &&
        (record.deletionDate || record.deletionProposalDate
          ? today.isSameOrBefore(record.deletionDate || record.deletionProposalDate, "day")
          : true) ? (
          <AntIcon type="check-circle-two-tone" twoToneColor={green[5]} />
        ) : (
          <AntIcon type="close-circle-two-tone" twoToneColor={red[5]} />
        )
    }
  ];

  if (showAllColumns) {
    columns.push({
      key: "note",
      align: "center",
      width: 40,
      render: (_, record) =>
        record.note ? <InfoIcon title={t("agent.license.attrs.note")} tooltip={record.note} /> : null
    });

    if (showActions) {
      columns.push({
        key: "actions",
        align: "right",
        fixed: "right",
        width: 180,
        render: (_, record) => (
          <TableActionsView
            actions={[
              {
                color: "blue",
                text: t("common.edit"),
                icon: "edit",
                onClick: () => handleLicenseUpdateClick(record)
              },
              {
                color: "red",
                text: t("common.delete"),
                icon: "delete",
                confirmDialog: {
                  icon: <PopconfirmDeleteIcon />,
                  title: t("agent.license.helpers.deleteConfirm"),
                  okType: "danger",
                  okText: t("common.yes"),
                  cancelText: t("common.no")
                },
                onClick: () => props.onDelete?.({ id1: agentId, id2: record.id })
              }
            ]}
          />
        )
      });
    }
  }

  return (
    <>
      <Table<AgentLicense>
        {...tableStandardProps()}
        columns={columns}
        scroll={{ x: showAllColumns ? TableSizes.LARGE : TableSizes.MEDIUM }}
        dataSource={licenses}
        pagination={{ ...paginationTableProps, pageSize: PageSizes.LARGE, total: licenses.length }}
      />

      {showActions && (
        <>
          <div className={licenses.length > 0 ? "table-footer-margin" : "margin-top-small"}>
            <Button type="primary" size="small" icon={<AntIcon type="plus" />} onClick={() => setLicenseFormOpen(true)}>
              {t("common.add")}
            </Button>
          </div>

          <AgentLicenseForm
            open={licenseFormOpen}
            agentId={agentId}
            defaultLicenseValue={defaultLicenseValue}
            license={licenseToUpdate}
            onCreate={props.onCreate}
            onUpdate={props.onUpdate}
            onFormCancel={handleLicenseFormCancel}
          />
        </>
      )}
    </>
  );
};

export default AgentLicenseTableView;
