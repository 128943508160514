import { Tag } from "antd";
import React from "react";
import t from "../../../../../../app/i18n";
import { DashboardNoticePosition } from "../../../../enums";

interface Props {
  position: DashboardNoticePosition;
  closable?: boolean;
  style?: React.CSSProperties;
  onClose?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const DashboardNoticePositionTag = ({ position, closable, style, onClose }: Props) => {
  let color: string;

  switch (position) {
    case DashboardNoticePosition.VEHICLE_CALC:
      color = "cyan";
      break;
    case DashboardNoticePosition.REALTY_CALC:
      color = "red";
      break;
    case DashboardNoticePosition.TRAVEL_CALC:
      color = "orange";
      break;
    default:
      return null;
  }

  return (
    <Tag color={color} closable={closable} style={{ width: "74px", textAlign: "center", ...style }} onClose={onClose}>
      {t("dashboard.notices.enums.position." + position)}
    </Tag>
  );
};
