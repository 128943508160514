import { Table } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import React from "react";
import { generatePath, Link } from "react-router-dom";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../../common/constants";
import { formatLocaleCurrency, formatLocaleDate } from "../../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../common/utils/utils";
import ContractAmountInfoPopover from "../../../../contract/components/ContractAmountInfoPopover";
import ContractStatusTag from "../../../../contract/components/ContractStatusTag";
import { CONTRACT_ROUTE_PATHS } from "../../../../contract/paths";
import { ContractFilterPageResult, ContractList } from "../../../../contract/types";

interface Props {
  contractsPage: ContractFilterPageResult;
  onPageChange: (pagination: TablePaginationConfig) => void;
}

const ClientContractsTableView = (props: Props) => {
  const columns: ColumnsType<ContractList> = [
    {
      key: "effectiveBeginningOrSignDate",
      title: (
        <>
          {t("contract.attrs.effectiveBeginningDateShort")}
          <br />
          {t("contract.attrs.signDate")}
        </>
      ),
      width: 140,
      render: (_, record) => formatLocaleDate(record.effectiveBeginningDate || record.signDate)
    },
    {
      key: "contractNumbers",
      title: t("contract.attrs.contractNumber"),
      width: 140,
      ellipsis: { showTitle: false },
      render: (_, record) => (
        <>
          {record.contractNumber && (
            <>
              <Ellipsis tooltip={record.contractNumber}>
                <Link className="no-link-color" to={generatePath(CONTRACT_ROUTE_PATHS.detail.to, { id: record.id })}>
                  {record.contractNumber}
                </Link>
              </Ellipsis>
              <br />
            </>
          )}
          {record.secondaryContractNumber && (
            <Ellipsis tooltip={record.secondaryContractNumber}>
              <Link className="no-link-color" to={generatePath(CONTRACT_ROUTE_PATHS.detail.to, { id: record.id })}>
                {record.secondaryContractNumber}
              </Link>
            </Ellipsis>
          )}
        </>
      )
    },
    {
      key: "institution",
      width: 120,
      title: t("contract.attrs.institution"),
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.institution.name}</Ellipsis>
    },
    {
      key: "product",
      title: t("contract.attrs.product"),
      width: 160,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.product.name}</Ellipsis>
    },
    {
      key: "annualPremiumOrApprovedAmount",
      title: <ContractAmountInfoPopover />,
      align: "right",
      width: 120,
      render: (_, record) =>
        formatLocaleCurrency(record.annualPremium || record.approvedAmount || record.targetAmount || record.tradeAmount)
    },
    {
      key: "status",
      title: t("contract.enums.status._label"),
      align: "center",
      width: 130,
      render: (_, record) => <ContractStatusTag status={record.status} style={{ marginRight: 0 }} />
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 90,
      render: (_, record) => (
        <ActionTextIcon
          path={generatePath(CONTRACT_ROUTE_PATHS.detail.to, { id: record.id })}
          icon="eye"
          color="blue"
          text={t("common.show")}
        />
      )
    }
  ];

  return (
    <Table<ContractList>
      {...tableStandardProps()}
      columns={columns}
      scroll={{ x: TableSizes.MEDIUM }}
      dataSource={props.contractsPage.pageData}
      pagination={{
        ...paginationTableProps,
        current: props.contractsPage.pageIndex + 1,
        pageSize: props.contractsPage.pageSize,
        total: props.contractsPage.totalElementsCount
      }}
      onChange={props.onPageChange}
    />
  );
};

export default ClientContractsTableView;
