import { Form } from "antd";
import { FormInstance } from "antd/lib/form";
import React from "react";
import t from "../../../../../app/i18n";
import AddressForm from "../../../../../common/modules/address/AddressForm";
import { validations } from "../../../../../common/utils/validationUtils";
import { ClientType } from "../../../enums";
import { CreateUpdateClient } from "../../../types";
import { resolveClientAddressLabel } from "../../../utils";

interface Props {
  form: FormInstance<CreateUpdateClient>;
  clientType: ClientType;
}

const ClientAddressesFormPart = (props: Props) => (
  <>
    <AddressForm
      form={props.form}
      rootNamePath={["address"]}
      label={resolveClientAddressLabel(props.clientType)}
      required
    />

    <Form.Item
      noStyle
      shouldUpdate={(prev, next) => prev.correspondenceAddressEnabled !== next.correspondenceAddressEnabled}
    >
      {({ getFieldValue }) => (
        <AddressForm
          form={props.form}
          rootNamePath={["correspondenceAddress"]}
          label={t("client.attrs.correspondenceAddress")}
          switchProps={{
            enabled: getFieldValue("correspondenceAddressEnabled"),
            formItemProps: {
              name: "correspondenceAddressEnabled",
              rules: [validations.none]
            }
          }}
        />
      )}
    </Form.Item>
  </>
);

export default ClientAddressesFormPart;
