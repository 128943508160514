import { AutoComplete, Card, Checkbox, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select } from "antd";
import { Rule } from "antd/lib/form";
import { RcFile } from "antd/lib/upload";
import isEqual from "lodash/isEqual";
import moment from "moment";
import React from "react";
import t from "../../../../../../../app/i18n";
import InputAddon from "../../../../../../../common/components/form/addons/InputAddon";
import HiddenInput from "../../../../../../../common/components/form/components/HiddenInput";
import LabelWithTooltip from "../../../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../../../common/constants";
import { formatLicensePlate } from "../../../../../../../common/utils/formatUtils";
import {
  datePickerStandardProps,
  disableDatePickerFuture,
  disableDatePickerOutOfInterval,
  inputNumberIntegerStandardProps,
  selectStandardProps,
  upperCaseStringNormalizeFunction
} from "../../../../../../../common/utils/formUtils";
import { contains } from "../../../../../../../common/utils/utils";
import { regexPatterns, validations } from "../../../../../../../common/utils/validationUtils";
import { InsuranceCoverageType } from "../../../../../../contract/enums";
import VehicleColorSelect from "../../../../../../enumerations/components/form/VehicleColorSelect";
import VehicleBrandEnumName from "../../../../../../enumerations/components/view/VehicleBrandEnumName";
import VehicleModelEnumName from "../../../../../../enumerations/components/view/VehicleModelEnumName";
import { InstitutionEnum } from "../../../../../../institution/enums";
import { CalcType } from "../../../../../enums";
import CalcAttachmentUpload from "../../../../components/CalcAttachmentUpload";
import { CalcAttachmentType } from "../../../../enums";
import { CalcResult } from "../../../../types";
import { VehicleEquipmentType, VehiclePriceSource, VehicleSecurityFeature } from "../../../enums";
import { CrashCalcResultData, VehicleCalc, VehicleCalcResultData, VehicleEquipmentInfo } from "../../../types";
import { M1_N1, SELF_MOVING, SPECS_MAP } from "../../../utils";

interface Props {
  calcData: VehicleCalc;
  selectedResult: CalcResult<VehicleCalcResultData>;
  onCalcAttachmentChange: (file: RcFile, type: CalcAttachmentType) => void;
}

const damagedAllowedRule = (calcType: CalcType, institutionEnum: InstitutionEnum): Rule => ({
  validator: (_, value) =>
    value && calcType === CalcType.MTPL_CRASH && institutionEnum === InstitutionEnum.KOOPERATIVA
      ? Promise.reject(t("calc.vehicle.validations.damagedNotAllowed"))
      : Promise.resolve()
});

const allKeysAvailableRule = (calcType: CalcType, institutionEnum: InstitutionEnum): Rule => ({
  validator: (_, value) =>
    !value && calcType === CalcType.MTPL_CRASH && institutionEnum === InstitutionEnum.KOOPERATIVA
      ? Promise.reject(t("calc.vehicle.validations.allKeysShouldBeAvailable"))
      : Promise.resolve()
});

const allServiceInspectionsPassedRule: Rule = {
  validator: (_, value) =>
    value ? Promise.resolve() : Promise.reject(t("calc.vehicle.validations.allServiceInspectionsShouldBePassed"))
};

const VehicleGenVehicleDataSection = ({ calcData, selectedResult, onCalcAttachmentChange }: Props) => {
  const { vehicleData } = calcData;
  const { calcType } = selectedResult;
  const { institutionEnum } = selectedResult.insuranceInstitution;

  const priceFromKpasKoopPriceList = !!(selectedResult.data as CrashCalcResultData).vehiclePriceFromKpasKoopPriceList;
  const newVehicleInKpasKoop =
    vehicleData.newVehicle &&
    (institutionEnum === InstitutionEnum.KOMUNALNA || institutionEnum === InstitutionEnum.KOOPERATIVA);

  const colSpan = 4;

  return (
    <Card type="inner" className="card-box" title={t("calc.vehicle.sections.additionalVehicleData")}>
      <div className="sub-header-info normal-font-size margin-bottom-small">
        <span>{t("calc.vehicle.sections.vehicleData")}: </span>
        <VehicleBrandEnumName brandId={vehicleData.brandId} otherBrandName={vehicleData.customBrand} />
        &nbsp;
        <VehicleModelEnumName
          brandId={vehicleData.brandId}
          modelId={vehicleData.modelId}
          otherModelName={vehicleData.customModel}
        />
        ,&nbsp;
        {t("calc.vehicle.attrs.vehicleData.licensePlate")} -{" "}
        {vehicleData.licensePlate ? formatLicensePlate(vehicleData.licensePlate) : t("common.notSpecified")}
        ,&nbsp;
        {t("calc.vehicle.attrs.vehicleData.vin")} - {vehicleData.vin}
      </div>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={["vehicleData", "registrationCertificatePresent"]}
            className="form-item-without-label"
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox>{t("calc.vehicle.attrs.vehicleData.registrationCertificatePresent")}</Checkbox>
          </Form.Item>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) =>
            prev.vehicleData.registrationCertificatePresent !== next.vehicleData.registrationCertificatePresent
          }
        >
          {({ getFieldValue }) =>
            getFieldValue(["vehicleData", "registrationCertificatePresent"]) && (
              <Col span={colSpan}>
                <Form.Item
                  name={["vehicleData", "registrationCertificateNumber"]}
                  label={
                    <LabelWithTooltip
                      label={t("calc.vehicle.attrs.vehicleData.registrationCertificateNumber")}
                      tooltip={t("calc.vehicle.helpers.registrationCertificateNumberDesc")}
                    />
                  }
                  rules={[
                    validations.notBlank,
                    validations.pattern(regexPatterns.registrationCertificateNumberRegex),
                    validations.length(8)
                  ]}
                  normalize={upperCaseStringNormalizeFunction}
                >
                  {vehicleData.previousRegistrationCertificateNumber ? (
                    <AutoComplete
                      options={[
                        {
                          value: vehicleData.previousRegistrationCertificateNumber,
                          label: (
                            <>
                              <span className="sub-header-info">
                                {t("calc.vehicle.attrs.vehicleData.previousRegistrationCertificateNumber")}
                              </span>
                              <br />
                              {vehicleData.previousRegistrationCertificateNumber}
                            </>
                          )
                        }
                      ]}
                    />
                  ) : (
                    <Input />
                  )}
                </Form.Item>
              </Col>
            )
          }
        </Form.Item>
      </Row>

      {calcType === CalcType.MTPL || calcType === CalcType.CRASH || calcType === CalcType.MTPL_CRASH ? (
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name={["vehicleData", "damaged"]}
              className="form-item-without-label"
              valuePropName="checked"
              rules={[damagedAllowedRule(calcType, institutionEnum)]}
              initialValue={false}
            >
              <Checkbox>{t("calc.vehicle.attrs.vehicleData.damaged")}</Checkbox>
            </Form.Item>
          </Col>

          <Form.Item noStyle shouldUpdate={(prev, next) => prev.vehicleData.damaged !== next.vehicleData.damaged}>
            {({ getFieldValue }) =>
              getFieldValue(["vehicleData", "damaged"]) && (
                <Col span={colSpan}>
                  <Form.Item
                    name={["vehicleData", "damageDescription"]}
                    label={t("calc.vehicle.attrs.vehicleData.damageDescription")}
                    rules={[validations.notBlank, validations.size(1, 255)]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              )
            }
          </Form.Item>
        </Row>
      ) : null}

      {calcType === CalcType.MTPL_CRASH && institutionEnum === InstitutionEnum.KOOPERATIVA && (
        <>
          <Row gutter={rowGutter}>
            <Col span={colSpan * 3}>
              <Form.Item
                name={["vehicleData", "allKeysAvailable"]}
                valuePropName="checked"
                rules={[allKeysAvailableRule(calcType, institutionEnum)]}
                initialValue={false}
              >
                <Checkbox>{t("calc.vehicle.attrs.vehicleData.allKeysAvailable")}</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          {selectedResult.data.appliedCoverages.some(
            coverage => coverage.type === InsuranceCoverageType.KOOP_EXTENDED_WARRANTY
          ) && (
            <Row gutter={rowGutter}>
              <Col span={colSpan * 3}>
                <Form.Item
                  name={["vehicleData", "allServiceInspectionsPassed"]}
                  valuePropName="checked"
                  rules={[allServiceInspectionsPassedRule]}
                  initialValue={false}
                >
                  <Checkbox>{t("calc.vehicle.attrs.vehicleData.allServiceInspectionsPassed")}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          )}
        </>
      )}

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <VehicleColorSelect
            formItemProps={{
              name: ["vehicleData", "colorId"],
              label: t("calc.vehicle.attrs.vehicleData.colorId"),
              rules: [validations.notNull],
              initialValue: vehicleData.colorId
            }}
          />
        </Col>

        {SELF_MOVING.includes(vehicleData.category) && (
          <>
            {(institutionEnum === InstitutionEnum.CSOB ||
              institutionEnum === InstitutionEnum.GENERALI ||
              institutionEnum === InstitutionEnum.KOMUNALNA) &&
              calcType === CalcType.CRASH && (
                <Col span={colSpan}>
                  <Form.Item
                    name={["vehicleData", "key", "numberOfPieces"]}
                    label={t("calc.vehicle.attrs.vehicleData.key.numberOfPieces")}
                    rules={[validations.notNull, validations.minNumber(1), validations.maxNumber(100)]}
                  >
                    <InputNumber />
                  </Form.Item>
                </Col>
              )}

            {(institutionEnum === InstitutionEnum.GENERALI || institutionEnum === InstitutionEnum.KOMUNALNA) &&
              calcType === CalcType.CRASH && (
                <Col span={colSpan}>
                  <Form.Item
                    name={["vehicleData", "key", "numberOfCopies"]}
                    label={t("calc.vehicle.attrs.vehicleData.key.numberOfCopies")}
                    rules={[validations.notNull, validations.minNumber(0), validations.maxNumber(100)]}
                  >
                    <InputNumber />
                  </Form.Item>
                </Col>
              )}
          </>
        )}

        {(calcType === CalcType.CRASH || calcType === CalcType.MTPL_CRASH) &&
          (institutionEnum === InstitutionEnum.KOMUNALNA || institutionEnum === InstitutionEnum.KOOPERATIVA) && (
            <Col span={colSpan}>
              <Form.Item
                name={["vehicleData", "priceSource"]}
                label={t("calc.vehicle.enums.vehiclePriceSource._label")}
                rules={[validations.notNull]}
                initialValue={
                  priceFromKpasKoopPriceList
                    ? VehiclePriceSource.KPAS_KOOP_PRICE_LIST
                    : newVehicleInKpasKoop
                      ? VehiclePriceSource.KPAS_KOOP_INVOICE
                      : undefined
                }
              >
                <Select
                  {...selectStandardProps}
                  disabled={priceFromKpasKoopPriceList || newVehicleInKpasKoop}
                  options={(priceFromKpasKoopPriceList
                    ? [VehiclePriceSource.KPAS_KOOP_PRICE_LIST]
                    : newVehicleInKpasKoop
                      ? [VehiclePriceSource.KPAS_KOOP_INVOICE]
                      : [VehiclePriceSource.KPAS_KOOP_INVOICE, VehiclePriceSource.KPAS_KOOP_SLOVEXPERTA]
                  ).map(source => ({
                    value: source,
                    label: t("calc.vehicle.enums.vehiclePriceSource." + source)
                  }))}
                />
              </Form.Item>
            </Col>
          )}

        {(calcType === CalcType.CRASH || calcType === CalcType.MTPL_CRASH) &&
          (institutionEnum === InstitutionEnum.KOMUNALNA || institutionEnum === InstitutionEnum.KOOPERATIVA) && (
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.vehicleData.priceSource !== next.vehicleData.priceSource}
            >
              {({ getFieldValue }) => {
                const priceSource = getFieldValue(["vehicleData", "priceSource"]) as VehiclePriceSource;

                if (priceSource === VehiclePriceSource.KPAS_KOOP_INVOICE) {
                  return (
                    <Col span={colSpan}>
                      <Form.Item
                        name={["vehicleData", "invoiceIssueDate"]}
                        label={t("calc.vehicle.attrs.vehicleData.invoiceIssueDate")}
                        rules={[validations.notNull, validations.notFuture]}
                      >
                        <DatePicker {...datePickerStandardProps} disabledDate={disableDatePickerFuture} />
                      </Form.Item>
                    </Col>
                  );
                }

                if (priceSource === VehiclePriceSource.KPAS_KOOP_SLOVEXPERTA) {
                  return (
                    <>
                      <Col span={colSpan}>
                        <Form.Item
                          name={["vehicleData", "slovexpertaIssuerName"]}
                          label={t("calc.vehicle.attrs.vehicleData.slovexpertaIssuerName")}
                          rules={[validations.notBlank, validations.size(1, 64)]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={colSpan}>
                        <Form.Item
                          name={["vehicleData", "slovexpertaIssueDate"]}
                          label={t("calc.vehicle.attrs.vehicleData.slovexpertaIssueDate")}
                          rules={[validations.notNull, validations.notFuture]}
                        >
                          <DatePicker {...datePickerStandardProps} disabledDate={disableDatePickerFuture} />
                        </Form.Item>
                      </Col>
                    </>
                  );
                }

                return null;
              }}
            </Form.Item>
          )}

        {institutionEnum === InstitutionEnum.ALLIANZ &&
          calcData.type === CalcType.MTPL &&
          SPECS_MAP.get("odometer").includes(vehicleData.category) && (
            <Col span={colSpan}>
              <Form.Item
                name={["vehicleData", "odometer"]}
                label={t("calc.vehicle.attrs.vehicleData.odometer")}
                rules={[validations.notNull, validations.minNumber(0)]}
              >
                <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="distance" />} />
              </Form.Item>
            </Col>
          )}

        {institutionEnum === InstitutionEnum.COLONNADE && calcType === CalcType.GAP && (
          <Col span={colSpan}>
            <Form.Item
              name={["vehicleData", "purchaseDate"]}
              label={
                <LabelWithTooltip
                  label={t("calc.vehicle.attrs.vehicleData.purchaseDate")}
                  tooltip={t("calc.vehicle.helpers.purchaseDateDesc")}
                />
              }
              rules={[validations.notNull, validations.dateInInterval(moment().subtract(6, "month"), moment())]}
            >
              <DatePicker
                {...datePickerStandardProps}
                disabledDate={current =>
                  disableDatePickerOutOfInterval(current, moment().subtract(6, "month"), moment())
                }
              />
            </Form.Item>
          </Col>
        )}

        {calcType === CalcType.CRASH && institutionEnum === InstitutionEnum.WUSTENROT && (
          <Col span={colSpan}>
            <Form.Item
              name={["vehicleData", "odometerPerYear"]}
              label={t("calc.vehicle.attrs.vehicleData.odometerPerYear")}
              rules={[validations.minNumber(0)]}
            >
              <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="distance" />} />
            </Form.Item>
          </Col>
        )}
      </Row>

      {institutionEnum === InstitutionEnum.UNION &&
        calcType === CalcType.CRASH &&
        contains(M1_N1, calcData.vehicleData.category) && (
          <>
            <Divider orientation="left" className="divider-subheader">
              {t("calc.vehicle.sections.vehicleEquipment")}
            </Divider>

            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => isEqual(prev.vehicleData.equipment, next.vehicleData.equipment)}
            >
              {({ getFieldValue }) => {
                const equipment = getFieldValue(["vehicleData", "equipment"]) || ([] as VehicleEquipmentInfo[]);
                const otherEquipmentSelected = equipment.find(e => e.type === VehicleEquipmentType.OTHER && e.selected);

                return Object.values(VehicleEquipmentType).map((type, index) => (
                  <Row gutter={rowGutter} key={index}>
                    <HiddenInput name={["vehicleData", "equipment", index, "type"]} initialValue={type} />

                    <Col span={colSpan}>
                      <Form.Item
                        name={["vehicleData", "equipment", index, "selected"]}
                        className={
                          type === VehicleEquipmentType.OTHER && otherEquipmentSelected
                            ? "form-item-without-label"
                            : undefined
                        }
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox>{t("calc.vehicle.enums.vehicleEquipmentType." + type)}</Checkbox>
                      </Form.Item>
                    </Col>

                    {type === VehicleEquipmentType.OTHER && otherEquipmentSelected && (
                      <Col span={colSpan}>
                        <Form.Item
                          name={["vehicleData", "equipment", index, "description"]}
                          label={t("calc.vehicle.attrs.vehicleData.equipment.description")}
                          rules={[validations.notBlank, validations.size(1, 255)]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                ));
              }}
            </Form.Item>
          </>
        )}

      {calcType === CalcType.CRASH &&
        vehicleData.security
          .filter(security => security.selected)
          .find(
            s => institutionEnum === InstitutionEnum.KOMUNALNA && s.feature === VehicleSecurityFeature.MECHANICAL
          ) && (
          <>
            <Divider orientation="left" className="divider-subheader">
              {t("calc.vehicle.sections.vehicleSecurity")}
            </Divider>

            {vehicleData.security.map((security, index) => {
              const kpasNumberOfKeys =
                institutionEnum === InstitutionEnum.KOMUNALNA && security.feature === VehicleSecurityFeature.MECHANICAL;

              return (
                <Row gutter={rowGutter} key={index}>
                  <HiddenInput name={["vehicleData", "security", index, "feature"]} initialValue={security.feature} />

                  {security.selected && kpasNumberOfKeys && (
                    <>
                      <Col span={colSpan} className="form-item-without-label">
                        {t("calc.vehicle.enums.vehicleSecurityFeature." + security.feature)}:
                      </Col>

                      <Col span={colSpan + 1}>
                        <Form.Item
                          name={["vehicleData", "security", index, "numberOfKeysOrControllers"]}
                          label={t("calc.vehicle.attrs.vehicleData.security.numberOfKeysOrControllers")}
                          rules={[validations.notNull, validations.minNumber(0), validations.maxNumber(100)]}
                        >
                          <InputNumber />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              );
            })}
          </>
        )}

      {(institutionEnum === InstitutionEnum.KOOPERATIVA || institutionEnum === InstitutionEnum.KOMUNALNA) &&
        (calcType === CalcType.CRASH || calcType === CalcType.MTPL_CRASH) && (
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.vehicleData.registrationCertificatePresent !== next.vehicleData.registrationCertificatePresent
            }
          >
            {({ getFieldValue }) =>
              (getFieldValue(["vehicleData", "registrationCertificatePresent"]) || vehicleData.newVehicle) && (
                <>
                  <Divider orientation="left" className="divider-subheader">
                    {t("calc.vehicle.sections.vehicleAttachments")}
                  </Divider>

                  <Row gutter={rowGutter}>
                    {getFieldValue(["vehicleData", "registrationCertificatePresent"]) && (
                      <Col span={colSpan * 2}>
                        <CalcAttachmentUpload
                          type={CalcAttachmentType.REGISTRATION_CERTIFICATE}
                          onChange={onCalcAttachmentChange}
                        />
                      </Col>
                    )}

                    {vehicleData.newVehicle && (
                      <>
                        <Col span={colSpan * 2}>
                          <CalcAttachmentUpload
                            type={CalcAttachmentType.VEHICLE_INVOICE}
                            onChange={onCalcAttachmentChange}
                          />
                        </Col>

                        {institutionEnum === InstitutionEnum.KOMUNALNA && (
                          <Col span={colSpan * 2}>
                            <CalcAttachmentUpload
                              type={CalcAttachmentType.ACCEPTANCE_PROTOCOL}
                              onChange={onCalcAttachmentChange}
                            />
                          </Col>
                        )}
                      </>
                    )}

                    {!vehicleData.newVehicle && institutionEnum === InstitutionEnum.KOOPERATIVA && (
                      <Form.Item
                        noStyle
                        shouldUpdate={(prev, next) => prev.vehicleData.priceSource !== next.vehicleData.priceSource}
                      >
                        {({ getFieldValue }) =>
                          getFieldValue(["vehicleData", "priceSource"]) === VehiclePriceSource.KPAS_KOOP_INVOICE && (
                            <Col span={colSpan * 2}>
                              <CalcAttachmentUpload
                                type={CalcAttachmentType.VEHICLE_INVOICE}
                                onChange={onCalcAttachmentChange}
                              />
                            </Col>
                          )
                        }
                      </Form.Item>
                    )}
                  </Row>
                </>
              )
            }
          </Form.Item>
        )}

      {calcType === CalcType.CRASH && institutionEnum === InstitutionEnum.CSOB && (
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) =>
            prev.vehicleData.registrationCertificatePresent !== next.vehicleData.registrationCertificatePresent
          }
        >
          {({ getFieldValue }) => {
            const registrationCertificatePresent = getFieldValue(["vehicleData", "registrationCertificatePresent"]);
            return (
              (vehicleData.newVehicle || registrationCertificatePresent) && (
                <>
                  <Divider orientation="left" className="divider-subheader">
                    {t("calc.vehicle.sections.vehicleAttachments")}
                  </Divider>

                  <Row gutter={rowGutter}>
                    {vehicleData.newVehicle && (
                      <Col span={colSpan * 2}>
                        <CalcAttachmentUpload
                          type={CalcAttachmentType.VEHICLE_INVOICE}
                          onChange={onCalcAttachmentChange}
                        />
                      </Col>
                    )}

                    {registrationCertificatePresent && (
                      <Col span={colSpan * 2}>
                        <CalcAttachmentUpload
                          type={CalcAttachmentType.REGISTRATION_CERTIFICATE}
                          onChange={onCalcAttachmentChange}
                        />
                      </Col>
                    )}
                  </Row>
                </>
              )
            );
          }}
        </Form.Item>
      )}
    </Card>
  );
};

export default VehicleGenVehicleDataSection;
