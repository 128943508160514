import {
  Alert,
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin
} from "antd";
import { DataSourceItemObject } from "antd/lib/auto-complete";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FormInstance, Rule } from "antd/lib/form";
import classNames from "classnames";
import debounce from "lodash/debounce";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import t from "../../../../../../../app/i18n";
import InputAddon from "../../../../../../../common/components/form/addons/InputAddon";
import HiddenCheckbox from "../../../../../../../common/components/form/components/HiddenCheckbox";
import HiddenInput from "../../../../../../../common/components/form/components/HiddenInput";
import LabelWithTooltip from "../../../../../../../common/components/form/labels/LabelWithTooltip";
import AntIcon from "../../../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../../../common/constants";
import ComponentWithPermission from "../../../../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../../../common/types";
import {
  formatIntegerLocaleCurrencyWithNullAsZero,
  formatLocaleDate,
  formatLocaleNumber
} from "../../../../../../../common/utils/formatUtils";
import {
  datePickerStandardProps,
  disableDatePickerPast,
  disableDatePickerPresentAndFuture,
  inputNumberIntegerStandardProps,
  licensePlateNormalizeFunction,
  momentToIsoDateString,
  resolveFormValidationError,
  selectStandardProps,
  toMoment,
  upperCaseStringNormalizeFunction
} from "../../../../../../../common/utils/formUtils";
import messageUtils from "../../../../../../../common/utils/messageUtils";
import { isDefinedValue, removeStringWhiteSpaces } from "../../../../../../../common/utils/utils";
import { validationFunctions, validations } from "../../../../../../../common/utils/validationUtils";
import { Bodywork, FuelType, Transmission, VehicleCategory } from "../../../../../../contract/enums";
import { Vehicle } from "../../../../../../contract/types";
import VehicleBrandSelect from "../../../../../../enumerations/components/form/VehicleBrandSelect";
import VehicleModelSelect from "../../../../../../enumerations/components/form/VehicleModelSelect";
import {
  selectVehicleOtherBrandsIdsEnums,
  selectVehicleOtherModelsIdsEnums
} from "../../../../../../enumerations/ducks";
import { AutocompleteRequestOrigin, AutocompleteVehiclesBy } from "../../../../../../vehicle/autocomplete/enums";
import { useVehicleAutocomplete } from "../../../../../../vehicle/autocomplete/utils";
import { useVehiclePriceFetch } from "../../../../../../vehicle/price/utils";
import { CalcType } from "../../../../../enums";
import { downloadCardReaderActions } from "../../../../ducks";
import { VehicleImportCountry, VehiclePurpose, VehicleSecurityFeature } from "../../../enums";
import { VehicleCalcForm } from "../../../types";
import { CALC_OMITTED_CATEGORIES, M1_N1, REINSURANCES_MAP, SPECS_MAP } from "../../../utils";

interface Props {
  form: FormInstance<VehicleCalcForm>;
  onDownloadCardReader: typeof downloadCardReaderActions.request;
}

const AUTOCOMPLETE_MAX_SIZE = 7;

const licensePlateLengthRule: Rule = {
  validator: (_, value) =>
    value && removeStringWhiteSpaces(value).length !== 6
      ? Promise.reject(t("validation.length", { len: 6 }))
      : Promise.resolve()
};

const VehicleCalcVehicleDataSection = ({ form, ...props }: Props) => {
  const vehicleAutocomplete = useVehicleAutocomplete();
  const vehiclePriceFetch = useVehiclePriceFetch();

  const otherBrandsIds = useSelector<RootState, string[]>(selectVehicleOtherBrandsIdsEnums);
  const otherModelIds = useSelector<RootState, string[]>(selectVehicleOtherModelsIdsEnums);

  const [autocompleteFocusItem, setAutocompleteFocusItem] = useState<AutocompleteVehiclesBy>();

  useEffect(() => {
    if (
      vehicleAutocomplete.result.data.length === 1 &&
      vehicleAutocomplete.result.keyword ===
        (vehicleAutocomplete.result.by === AutocompleteVehiclesBy.VIN
          ? vehicleAutocomplete.result.data[0]?.vin
          : vehicleAutocomplete.result.data[0]?.licensePlate)
    ) {
      setVehicleDataToForm(vehicleAutocomplete.result.data[0], vehicleAutocomplete.result.by);
    }
  }, [vehicleAutocomplete.result]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isDefinedValue(vehiclePriceFetch.result)) {
      if (vehiclePriceFetch.result === 0) {
        messageUtils.warnNotification(t("common.warning"), t("calc.vehicle.helpers.vehiclePriceNoResponseDesc"));
        form.setFieldsValue({ vehicleData: { price: null } });
      } else {
        form.setFieldsValue({ vehicleData: { price: vehiclePriceFetch.result } });
        messageUtils.infoNotification(
          t("calc.vehicle.helpers.vehiclePriceResponseTitle"),
          t("calc.vehicle.helpers.vehiclePriceResponseDesc", {
            price: formatIntegerLocaleCurrencyWithNullAsZero(vehiclePriceFetch.result)
          })
        );
      }
      vehiclePriceFetch.onResultDelete();
    }
  }, [vehiclePriceFetch.result]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNewVehicleChange = (event: CheckboxChangeEvent): void => {
    const { type, vehicleData } = form.getFieldsValue([["type"], ["vehicleData"]]) as VehicleCalcForm;
    form.setFieldsValue({
      vehicleData: {
        firstRegistrationDate: null,
        price: type !== CalcType.MTPL || event.target.checked ? vehicleData.price : null,
        registrationCertificatePresent: !event.target.checked
      }
    });
  };

  const handleBrandIdChange = (): void => {
    form.setFieldsValue({ vehicleData: { modelId: null } });
  };

  const handleCategoryChange = (category: VehicleCategory): void => {
    const { type, vehicleData, reinsurancesData } = form.getFieldsValue([
      ["type"],
      ["vehicleData"],
      ["reinsurancesData"]
    ]) as VehicleCalcForm;

    form.setFieldsValue({
      vehicleData: {
        fuelType: SPECS_MAP.get("fuelType").includes(category) ? vehicleData.fuelType : undefined,
        transmission: SPECS_MAP.get("transmission").includes(category) ? vehicleData.transmission : undefined,
        bodywork: SPECS_MAP.get("bodywork").includes(category) ? vehicleData.bodywork : undefined,
        seatsNumber: SPECS_MAP.get("seatsNumber").includes(category) ? vehicleData.seatsNumber : undefined,
        doorsNumber: SPECS_MAP.get("doorsNumber").includes(category) ? vehicleData.doorsNumber : undefined,
        engineDisplacement: SPECS_MAP.get("engineDisplacement").includes(category)
          ? vehicleData.engineDisplacement
          : undefined,
        enginePower: SPECS_MAP.get("enginePower").includes(category) ? vehicleData.enginePower : undefined,
        odometer: SPECS_MAP.get("odometer").includes(category) ? vehicleData.odometer : undefined,
        steeringWheelOnTheRight: SPECS_MAP.get("steeringWheelOnTheRight").includes(category)
          ? vehicleData.steeringWheelOnTheRight
          : false,
        emergencyBrakingSystem: SPECS_MAP.get("emergencyBrakingSystem").includes(category)
          ? vehicleData.emergencyBrakingSystem
          : false,
        parkingAssistant: SPECS_MAP.get("parkingAssistant").includes(category) ? vehicleData.parkingAssistant : false,
        price: type === CalcType.MTPL && !M1_N1.includes(category) ? undefined : vehicleData.price
      },
      reinsurancesData: {
        extendedAssistance: REINSURANCES_MAP.get("extendedAssistance").includes(category)
          ? reinsurancesData.extendedAssistance
          : false,
        glass: REINSURANCES_MAP.get("glass").includes(category) ? reinsurancesData.glass : false,
        animal: REINSURANCES_MAP.get("animal").includes(category) ? reinsurancesData.animal : false,
        element: REINSURANCES_MAP.get("element").includes(category) ? reinsurancesData.element : false,
        theftAndVandalism: REINSURANCES_MAP.get("theftAndVandalism").includes(category)
          ? reinsurancesData.theftAndVandalism
          : false,
        injury: REINSURANCES_MAP.get("injury").includes(category) ? reinsurancesData.injury : false,
        gap: REINSURANCES_MAP.get("gap").includes(category) ? reinsurancesData.gap : false,
        replacementVehicle: REINSURANCES_MAP.get("replacementVehicle").includes(category)
          ? reinsurancesData.replacementVehicle
          : false,
        generaliAbroadVehicleRepair: REINSURANCES_MAP.get("generaliAbroadVehicleRepair").includes(category)
          ? reinsurancesData.generaliAbroadVehicleRepair
          : false,
        koopExtendedWarranty: REINSURANCES_MAP.get("koopExtendedWarranty").includes(category)
          ? reinsurancesData.koopExtendedWarranty
          : false,
        uniqaSmallDamage: REINSURANCES_MAP.get("uniqaSmallDamage").includes(category)
          ? reinsurancesData.uniqaSmallDamage
          : false,
        uniqaTotalDamage: REINSURANCES_MAP.get("uniqaTotalDamage").includes(category)
          ? reinsurancesData.uniqaTotalDamage
          : false,
        uniqaLawyerAssistance: REINSURANCES_MAP.get("uniqaLawyerAssistance").includes(category)
          ? reinsurancesData.uniqaLawyerAssistance
          : false,
        uniqaSidecarMtpl: REINSURANCES_MAP.get("uniqaSidecarMtpl").includes(category)
          ? reinsurancesData.uniqaSidecarMtpl
          : false,
        uniqaChildrenInjury: REINSURANCES_MAP.get("uniqaChildrenInjury").includes(category)
          ? reinsurancesData.uniqaChildrenInjury
          : false,
        uniqaUnlimitedTow: REINSURANCES_MAP.get("uniqaUnlimitedTow").includes(category)
          ? reinsurancesData.uniqaUnlimitedTow
          : false
      }
    });
  };

  const handleFuelTypeChange = (fuelType: FuelType): void => {
    const { vehicleData } = form.getFieldsValue([["vehicleData"]]) as VehicleCalcForm;
    form.setFieldsValue({
      vehicleData: {
        engineDisplacement: fuelType !== FuelType.BEV ? vehicleData.engineDisplacement : undefined
      }
    });
  };

  const handleVehiclePriceRequestSubmit = (): void => {
    form
      .validateFields([
        ["vehicleData", "vin"],
        ["vehicleData", "category"],
        ["vehicleData", "firstRegistrationDate"],
        ["vehicleData", "engineDisplacement"],
        ["vehicleData", "enginePower"],
        ["vehicleData", "totalWeight"],
        ["vehicleData", "fuelType"],
        ["vehicleData", "transmission"],
        ["vehicleData", "brandId"],
        ["vehicleData", "modelId"],
        ["vehicleData", "customBrand"],
        ["vehicleData", "customModel"]
      ])
      .then(values => {
        if (!values.vehicleData.vin) {
          messageUtils.errorNotification(t("common.error"), t("calc.vehicle.validations.vehiclePriceRequestError"), 10);
        } else {
          vehiclePriceFetch.onGetPrice({
            vin: values.vehicleData.vin,
            firstRegistrationDate: momentToIsoDateString(values.vehicleData.firstRegistrationDate as Moment),
            brandId: values.vehicleData.brandId,
            modelId: values.vehicleData.modelId,
            customBrand: values.vehicleData.customBrand,
            customModel: values.vehicleData.customModel,
            category: values.vehicleData.category,
            engineDisplacement: values.vehicleData.engineDisplacement,
            enginePower: values.vehicleData.enginePower,
            fuelType: values.vehicleData.fuelType,
            transmission: values.vehicleData.transmission,
            totalWeight: values.vehicleData.totalWeight
          });
        }
      })
      .catch(resolveFormValidationError);
  };

  const handleVehicleAutocompleteSearchDebounced = useMemo(
    () =>
      debounce((value: string, focusedItem: AutocompleteVehiclesBy): void => {
        const keyword = removeStringWhiteSpaces(value);
        if (keyword?.length >= 5 && validationFunctions.validateSearchKeyword(keyword)) {
          vehicleAutocomplete.onSearch({
            keyword: keyword.toUpperCase(),
            maxResultSize: AUTOCOMPLETE_MAX_SIZE,
            by: focusedItem,
            origin: AutocompleteRequestOrigin.CALC
          });
        } else if (vehicleAutocomplete.result.data) {
          vehicleAutocomplete.onResultDelete();
        }
      }, 500),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleVehicleAutocompleteSearch = useCallback(
    (value: string, focusedItem: AutocompleteVehiclesBy): void => {
      handleVehicleAutocompleteSearchDebounced(value, focusedItem);
    },
    [handleVehicleAutocompleteSearchDebounced]
  );

  const handleVehicleAutocompleteSelect = (value: string, focusedItem: AutocompleteVehiclesBy): void => {
    setVehicleDataToForm(
      vehicleAutocomplete.result.data.find(vehicle =>
        focusedItem === AutocompleteVehiclesBy.VIN
          ? vehicle.vin === value
          : vehicle.licensePlate === removeStringWhiteSpaces(value)
      ),
      focusedItem
    );
  };

  const resolveVehicleAutocompleteOptions = (focusedItem: AutocompleteVehiclesBy): DataSourceItemObject[] => {
    return focusedItem === vehicleAutocomplete.result.by
      ? vehicleAutocomplete.result.data.map<DataSourceItemObject>(vehicle => ({
          value:
            focusedItem === AutocompleteVehiclesBy.VIN
              ? vehicle.vin
              : licensePlateNormalizeFunction(vehicle.licensePlate),
          text:
            focusedItem === AutocompleteVehiclesBy.VIN
              ? vehicle.vin
              : licensePlateNormalizeFunction(vehicle.licensePlate)
        }))
      : [];
  };

  const setVehicleDataToForm = (vehicle: Vehicle, focusedItem: AutocompleteVehiclesBy): void => {
    const { vehicleData } = form.getFieldsValue([["vehicleData"]]) as VehicleCalcForm;

    const brandId = vehicle.model?.brand?.id || null;
    const modelId = brandId && vehicle.model ? vehicle.model.id : null;
    const firstRegistrationDate = toMoment(vehicle.firstRegistrationDate);
    const newVehicle = !!firstRegistrationDate && firstRegistrationDate.isSameOrAfter(moment(), "day");
    const licensePlate =
      focusedItem === AutocompleteVehiclesBy.VIN && vehicleData.licensePlate
        ? vehicleData.licensePlate
        : licensePlateNormalizeFunction(vehicle.licensePlate);

    Modal.confirm({
      title: t("calc.vehicle.helpers.vehicleFound"),
      content: (
        <>
          <table className="data-table-view">
            <tbody>
              <tr>
                <td>{t("common.vehicle")}:</td>
                <td>
                  <b>
                    {`${vehicle.customBrand || vehicle.model?.brand?.name || ""} ${
                      vehicle.customModel || vehicle.model?.name || ""
                    }`}
                  </b>
                </td>
              </tr>

              <tr>
                <td>{t("calc.vehicle.attrs.vehicleData.licensePlate")}:</td>
                <td>
                  <b>{licensePlateNormalizeFunction(vehicle.licensePlate)}</b>
                </td>
              </tr>

              <tr>
                <td>{t("calc.vehicle.attrs.vehicleData.vin")}:</td>
                <td>
                  <b>{vehicle.vin}</b>
                </td>
              </tr>

              <tr>
                <td>{t("calc.vehicle.sections.otherData")}:</td>
                <td>
                  {[
                    vehicle.category ? t("contract.enums.vehicleCategory." + vehicle.category) : null,
                    formatLocaleDate(firstRegistrationDate),
                    isDefinedValue(vehicle.engineDisplacement)
                      ? `${formatLocaleNumber(vehicle.engineDisplacement)} ${t("common.sign.engineDisplacement")}`
                      : null,
                    isDefinedValue(vehicle.enginePower)
                      ? `${formatLocaleNumber(vehicle.enginePower)} ${t("common.sign.enginePower")}`
                      : null,
                    vehicle.fuelType ? t("contract.enums.fuelType." + vehicle.fuelType) : null,
                    isDefinedValue(vehicle.totalWeight)
                      ? `${formatLocaleNumber(vehicle.totalWeight)} ${t("common.sign.weight")}`
                      : null
                  ]
                    .filter(v => !!v)
                    .join(", ")}
                </td>
              </tr>
            </tbody>
          </table>

          {focusedItem === AutocompleteVehiclesBy.VIN &&
          vehicleData.licensePlate &&
          removeStringWhiteSpaces(vehicleData.licensePlate) !== vehicle.licensePlate ? (
            <Alert
              className="margin-top-small margin-right-medium"
              type="warning"
              showIcon
              message={t("calc.vehicle.helpers.twoPlatesFound", { licensePlate: vehicleData.licensePlate })}
            />
          ) : (
            <Alert
              className="margin-top-small margin-right-medium"
              type="info"
              showIcon
              message={t("calc.vehicle.helpers.vehicleFillCheck")}
            />
          )}
        </>
      ),
      width: 630,
      okText: t("common.yes"),
      cancelText: t("common.no"),
      onOk: () => {
        form.setFieldsValue({
          vehicleData: {
            licensePlate,
            vin: vehicle.vin,
            newVehicle,
            firstRegistrationDate,
            brandId,
            modelId,
            customBrand: vehicle.customBrand,
            customModel: vehicle.customModel,
            category: CALC_OMITTED_CATEGORIES.includes(vehicle.category) ? null : vehicle.category,
            engineDisplacement: vehicle.engineDisplacement,
            enginePower: vehicle.enginePower,
            fuelType: vehicle.fuelType,
            transmission: vehicle.transmission,
            bodywork: vehicle.bodywork,
            purpose: null,
            totalWeight: vehicle.totalWeight,
            seatsNumber: vehicle.seatsNumber,
            doorsNumber: vehicle.doorsNumber,
            odometer: null,
            imported: false,
            importCountry: null,
            steeringWheelOnTheRight: false,
            emergencyBrakingSystem: false,
            parkingAssistant: false,
            price: null,
            generalPrice: null,
            buyingPrice: null,
            security: vehicleData.security.map(value => ({
              ...value,
              selected: false
            })),
            registrationCertificatePresent: !newVehicle,
            registrationCertificateNumber: null,
            previousRegistrationCertificateNumber: vehicle.registrationCertificateNumber,
            colorId: vehicle.color?.id
          }
        });
      },
      afterClose: () => {
        vehicleAutocomplete.onResultDelete();
      }
    });
  };

  const colSpan = 4;
  const twoColumnColSpan = 12;
  const threeColumnColSpan = 8;

  return (
    <Card
      type="inner"
      className="card-box card-box--inner-extra"
      title={t("calc.vehicle.sections.vehicleData")}
      extra={
        <ComponentWithPermission permissions={[Permission.CARD_READER]}>
          <Button
            size="small"
            className="secondary-button"
            icon={<AntIcon type="download" />}
            onClick={() => props.onDownloadCardReader()}
          >
            {t("calc.helpers.downloadCardReader")}
          </Button>
        </ComponentWithPermission>
      }
    >
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Spin
            spinning={vehicleAutocomplete.inProgress && autocompleteFocusItem === AutocompleteVehiclesBy.LICENSE_PLATE}
          >
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.vehicleData.purpose !== next.vehicleData.purpose}>
              {({ getFieldValue }) => {
                const purpose = getFieldValue(["vehicleData", "purpose"]);
                return (
                  <Form.Item
                    name={["vehicleData", "licensePlate"]}
                    label={
                      <LabelWithTooltip
                        label={t("calc.vehicle.attrs.vehicleData.licensePlate")}
                        tooltip={t("calc.vehicle.helpers.licensePlateDesc")}
                      />
                    }
                    dependencies={[["vehicleData", "purpose"]]}
                    rules={
                      !purpose
                        ? [validations.licensePlate]
                        : purpose === VehiclePurpose.VETERAN_CAR_H
                          ? [validations.licensePlate, licensePlateLengthRule]
                          : [validations.fullLicensePlate]
                    }
                    normalize={licensePlateNormalizeFunction}
                  >
                    <AutoComplete
                      dropdownMatchSelectWidth={false}
                      onFocus={() => setAutocompleteFocusItem(AutocompleteVehiclesBy.LICENSE_PLATE)}
                      onSearch={value => handleVehicleAutocompleteSearch(value, AutocompleteVehiclesBy.LICENSE_PLATE)}
                      onSelect={value => handleVehicleAutocompleteSelect(value, AutocompleteVehiclesBy.LICENSE_PLATE)}
                      options={resolveVehicleAutocompleteOptions(AutocompleteVehiclesBy.LICENSE_PLATE)}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Spin>
        </Col>

        <Col span={colSpan}>
          <Spin spinning={vehicleAutocomplete.inProgress && autocompleteFocusItem === AutocompleteVehiclesBy.VIN}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.vehicleData.newVehicle !== next.vehicleData.newVehicle}
            >
              {({ getFieldValue }) => (
                <Form.Item
                  name={["vehicleData", "vin"]}
                  label={t("calc.vehicle.attrs.vehicleData.vin")}
                  rules={[
                    getFieldValue(["vehicleData", "newVehicle"]) ? validations.none : validations.notBlank,
                    validations.size(3, 17)
                  ]}
                  normalize={upperCaseStringNormalizeFunction}
                >
                  <AutoComplete
                    dropdownMatchSelectWidth={false}
                    onFocus={() => setAutocompleteFocusItem(AutocompleteVehiclesBy.VIN)}
                    onSearch={value => handleVehicleAutocompleteSearch(value, AutocompleteVehiclesBy.VIN)}
                    onSelect={value => handleVehicleAutocompleteSelect(value, AutocompleteVehiclesBy.VIN)}
                    options={resolveVehicleAutocompleteOptions(AutocompleteVehiclesBy.VIN)}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Spin>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={["vehicleData", "newVehicle"]}
            valuePropName="checked"
            className="form-item-without-label"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox onChange={handleNewVehicleChange}>{t("calc.vehicle.attrs.vehicleData.newVehicle")}</Checkbox>
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.vehicleData.newVehicle !== next.vehicleData.newVehicle}>
            {({ getFieldValue }) => {
              const newVehicle = getFieldValue(["vehicleData", "newVehicle"]);
              return (
                <Form.Item
                  name={["vehicleData", "firstRegistrationDate"]}
                  label={t("calc.vehicle.attrs.vehicleData.firstRegistrationDate")}
                  rules={[validations.notNull, newVehicle ? validations.notPast : validations.notPresentAndFuture]}
                >
                  <DatePicker
                    {...datePickerStandardProps}
                    disabledDate={newVehicle ? disableDatePickerPast : disableDatePickerPresentAndFuture}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <VehicleBrandSelect
            formItemProps={{
              name: ["vehicleData", "brandId"],
              label: (
                <LabelWithTooltip
                  label={t("calc.vehicle.attrs.vehicleData.brandId")}
                  tooltip={t("calc.vehicle.helpers.vehicleBrandDesc")}
                />
              ),
              rules: [validations.notNull]
            }}
            selectProps={{
              onChange: handleBrandIdChange
            }}
          />
        </Col>

        <Col span={colSpan}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.vehicleData.brandId !== next.vehicleData.brandId}>
            {({ getFieldValue }) => (
              <VehicleModelSelect
                formItemProps={{
                  name: ["vehicleData", "modelId"],
                  label: (
                    <LabelWithTooltip
                      label={t("calc.vehicle.attrs.vehicleData.modelId")}
                      tooltip={t("calc.vehicle.helpers.vehicleModelDesc")}
                    />
                  ),
                  rules: [validations.notNull]
                }}
                brandId={getFieldValue(["vehicleData", "brandId"])}
                selectProps={{
                  placeholder: t("calc.vehicle.helpers.modelSelectPlaceholder")
                }}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        noStyle
        shouldUpdate={(prev, next) =>
          prev.vehicleData.brandId !== next.vehicleData.brandId || prev.vehicleData.modelId !== next.vehicleData.modelId
        }
      >
        {({ getFieldValue }) => {
          const brandId = getFieldValue(["vehicleData", "brandId"]);
          const modelId = getFieldValue(["vehicleData", "modelId"]);
          const showCustomBrandInput = otherBrandsIds.includes(brandId);
          const showCustomModelInput = otherModelIds.includes(modelId);
          return (
            (showCustomBrandInput || showCustomModelInput) && (
              <Row gutter={rowGutter}>
                {showCustomBrandInput && (
                  <Col span={colSpan} offset={colSpan * 4}>
                    <Form.Item
                      name={["vehicleData", "customBrand"]}
                      label={t("calc.vehicle.attrs.vehicleData.customBrand")}
                      rules={[validations.notNull, validations.size(1, 255)]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                )}
                {showCustomModelInput && (
                  <Col span={colSpan} offset={showCustomBrandInput ? 0 : colSpan * 5}>
                    <Form.Item
                      name={["vehicleData", "customModel"]}
                      label={t("calc.vehicle.attrs.vehicleData.customModel")}
                      rules={[validations.notNull, validations.size(1, 255)]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            )
          );
        }}
      </Form.Item>
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={["vehicleData", "category"]}
            label={t("contract.enums.vehicleCategory._label")}
            rules={[validations.notNull]}
          >
            <Select
              {...selectStandardProps}
              options={Object.keys(VehicleCategory)
                .filter(category => !CALC_OMITTED_CATEGORIES.includes(category as VehicleCategory))
                .map(category => ({ value: category, label: t("contract.enums.vehicleCategory." + category) }))}
              onChange={handleCategoryChange}
            />
          </Form.Item>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) =>
            prev.vehicleData.category !== next.vehicleData.category ||
            prev.vehicleData.fuelType !== next.vehicleData.fuelType
          }
        >
          {({ getFieldValue }) => {
            const category = getFieldValue(["vehicleData", "category"]);
            const fuelType = getFieldValue(["vehicleData", "fuelType"]);
            return (
              <>
                <Col span={colSpan}>
                  <Form.Item
                    name={["vehicleData", "engineDisplacement"]}
                    label={t("calc.vehicle.attrs.vehicleData.engineDisplacement")}
                    rules={[validations.minNumber(1)]}
                  >
                    <InputNumber
                      {...inputNumberIntegerStandardProps}
                      addonAfter={<InputAddon type="engineDisplacement" />}
                      disabled={!SPECS_MAP.get("engineDisplacement").includes(category) || fuelType === FuelType.BEV}
                    />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={["vehicleData", "enginePower"]}
                    label={t("calc.vehicle.attrs.vehicleData.enginePower")}
                    rules={[validations.minNumber(1)]}
                  >
                    <InputNumber
                      {...inputNumberIntegerStandardProps}
                      addonAfter={<InputAddon type="enginePower" />}
                      disabled={!SPECS_MAP.get("enginePower").includes(category)}
                    />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={["vehicleData", "fuelType"]}
                    label={t("contract.enums.fuelType._label")}
                    rules={[validations.none]}
                  >
                    <Select
                      {...selectStandardProps}
                      allowClear
                      disabled={!SPECS_MAP.get("fuelType").includes(category)}
                      options={Object.keys(FuelType).map(type => ({
                        value: type,
                        label: t("contract.enums.fuelType." + type)
                      }))}
                      onChange={handleFuelTypeChange}
                    />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={["vehicleData", "transmission"]}
                    label={t("contract.enums.transmission._label")}
                    rules={[validations.none]}
                  >
                    <Select
                      {...selectStandardProps}
                      allowClear
                      disabled={!SPECS_MAP.get("transmission").includes(category)}
                      options={Object.keys(Transmission).map(transmission => ({
                        value: transmission,
                        label: t("contract.enums.transmission." + transmission)
                      }))}
                    />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={["vehicleData", "bodywork"]}
                    label={t("calc.vehicle.attrs.vehicleData.bodywork")}
                    rules={[validations.none]}
                  >
                    <Select
                      {...selectStandardProps}
                      allowClear
                      disabled={!SPECS_MAP.get("bodywork").includes(category)}
                      options={Object.keys(Bodywork).map(bodywork => ({
                        value: bodywork,
                        label: t("contract.enums.bodywork." + bodywork)
                      }))}
                    />
                  </Form.Item>
                </Col>
              </>
            );
          }}
        </Form.Item>
      </Row>
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={["vehicleData", "purpose"]}
            label={t("calc.vehicle.enums.vehiclePurpose._label")}
            rules={[validations.notNull]}
          >
            <Select
              {...selectStandardProps}
              options={Object.keys(VehiclePurpose).map(purpose => ({
                value: purpose,
                label: t("calc.vehicle.enums.vehiclePurpose." + purpose)
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={["vehicleData", "totalWeight"]}
            label={t("calc.vehicle.attrs.vehicleData.totalWeight")}
            rules={[validations.notNull, validations.minNumber(1)]}
          >
            <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="weight" />} />
          </Form.Item>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) =>
            prev.vehicleData.category !== next.vehicleData.category || prev.type !== next.type
          }
        >
          {({ getFieldValue }) => {
            const category = getFieldValue(["vehicleData", "category"]);
            const calcType = getFieldValue(["type"]);
            return (
              <>
                <Col span={colSpan}>
                  <Form.Item
                    name={["vehicleData", "seatsNumber"]}
                    label={t("calc.vehicle.attrs.vehicleData.seatsNumber")}
                    rules={[validations.minNumber(0)]}
                  >
                    <InputNumber disabled={!SPECS_MAP.get("seatsNumber").includes(category)} />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={["vehicleData", "doorsNumber"]}
                    label={t("calc.vehicle.attrs.vehicleData.doorsNumber")}
                    rules={[validations.minNumber(0)]}
                  >
                    <InputNumber disabled={!SPECS_MAP.get("doorsNumber").includes(category)} />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={["vehicleData", "odometer"]}
                    label={t("calc.vehicle.attrs.vehicleData.odometer")}
                    rules={[validations.minNumber(0)]}
                  >
                    <InputNumber
                      {...inputNumberIntegerStandardProps}
                      addonAfter={<InputAddon type="distance" />}
                      disabled={calcType === CalcType.MTPL || !SPECS_MAP.get("odometer").includes(category)}
                    />
                  </Form.Item>
                </Col>
              </>
            );
          }}
        </Form.Item>
      </Row>

      <Row gutter={rowGutter}>
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) =>
            prev.vehicleData.category !== next.vehicleData.category ||
            prev.type !== next.type ||
            prev.vehicleData.imported !== next.vehicleData.imported
          }
        >
          {({ getFieldValue }) => {
            const category = getFieldValue(["vehicleData", "category"]);
            const calcType = getFieldValue(["type"]);
            const importedChecked = getFieldValue(["vehicleData", "imported"]);
            return (
              <>
                <Col span={colSpan}>
                  <Form.Item
                    name={["vehicleData", "steeringWheelOnTheRight"]}
                    valuePropName="checked"
                    className={classNames({ "two-line-form-item-without-label": importedChecked })}
                    rules={[validations.none]}
                    initialValue={false}
                  >
                    <Checkbox
                      disabled={
                        calcType === CalcType.CRASH ||
                        calcType === CalcType.MTPL_CRASH ||
                        !SPECS_MAP.get("steeringWheelOnTheRight").includes(category)
                      }
                    >
                      {t("calc.vehicle.attrs.vehicleData.steeringWheelOnTheRight")}
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={["vehicleData", "emergencyBrakingSystem"]}
                    valuePropName="checked"
                    className={classNames({ "two-line-form-item-without-label": importedChecked })}
                    rules={[validations.none]}
                    initialValue={false}
                  >
                    <Checkbox
                      disabled={
                        calcType === CalcType.CRASH ||
                        calcType === CalcType.MTPL_CRASH ||
                        !SPECS_MAP.get("emergencyBrakingSystem").includes(category)
                      }
                    >
                      <LabelWithTooltip
                        label={t("calc.vehicle.attrs.vehicleData.emergencyBrakingSystem")}
                        tooltip={t("calc.vehicle.helpers.emergencyBrakingSystemDesc")}
                      />
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={["vehicleData", "parkingAssistant"]}
                    valuePropName="checked"
                    className={classNames({ "two-line-form-item-without-label": importedChecked })}
                    rules={[validations.none]}
                    initialValue={false}
                  >
                    <Checkbox
                      disabled={
                        calcType === CalcType.CRASH ||
                        calcType === CalcType.MTPL_CRASH ||
                        !SPECS_MAP.get("parkingAssistant").includes(category)
                      }
                    >
                      <LabelWithTooltip
                        label={t("calc.vehicle.attrs.vehicleData.parkingAssistant")}
                        tooltip={t("calc.vehicle.helpers.parkingAssistantDesc")}
                      />
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={["vehicleData", "imported"]}
                    valuePropName="checked"
                    className={classNames({ "two-line-form-item-without-label": importedChecked })}
                    rules={[validations.none]}
                    initialValue={false}
                  >
                    <Checkbox>{t("calc.vehicle.attrs.vehicleData.imported")}</Checkbox>
                  </Form.Item>
                </Col>

                {importedChecked && (
                  <Col span={colSpan}>
                    <Form.Item
                      name={["vehicleData", "importCountry"]}
                      label={t("calc.vehicle.enums.vehicleImportCountry._label")}
                      rules={[validations.notNull]}
                    >
                      <Select
                        {...selectStandardProps}
                        options={Object.keys(VehicleImportCountry).map(country => ({
                          value: country,
                          label: t("calc.vehicle.enums.vehicleImportCountry." + country)
                        }))}
                      />
                    </Form.Item>
                  </Col>
                )}
              </>
            );
          }}
        </Form.Item>
      </Row>
      <Row gutter={rowGutter * 2}>
        <Col span={twoColumnColSpan}>
          <Divider className="divider-subheader">{t("calc.vehicle.sections.vehiclePrice")}</Divider>

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.type !== next.type ||
              prev.vehicleData.category !== next.vehicleData.category ||
              prev.vehicleData.newVehicle !== next.vehicleData.newVehicle ||
              prev.vehicleData.price !== next.vehicleData.price
            }
          >
            {({ getFieldValue }) => {
              const calcType = getFieldValue(["type"]);
              const category = getFieldValue(["vehicleData", "category"]);
              const newVehicle = getFieldValue(["vehicleData", "newVehicle"]);
              const price = getFieldValue(["vehicleData", "price"]);
              return (
                <>
                  <Row gutter={rowGutter}>
                    <Col span={24}>
                      <Button
                        size="small"
                        icon={<AntIcon type="euro" />}
                        className="secondary-button margin-bottom-small"
                        loading={vehiclePriceFetch.inProgress}
                        onClick={handleVehiclePriceRequestSubmit}
                        disabled={calcType === CalcType.MTPL || !M1_N1.includes(category)}
                      >
                        {t("calc.vehicle.actions.getVehiclePrice")}
                      </Button>
                    </Col>
                  </Row>

                  <Row gutter={rowGutter}>
                    <Col span={threeColumnColSpan}>
                      <Form.Item
                        name={["vehicleData", "price"]}
                        label={
                          <LabelWithTooltip
                            label={t("calc.vehicle.attrs.vehicleData.price")}
                            tooltip={t("calc.vehicle.helpers.priceDesc")}
                          />
                        }
                        rules={[validations.minNumber(1)]}
                      >
                        <InputNumber
                          {...inputNumberIntegerStandardProps}
                          addonAfter={<InputAddon type="euro" />}
                          disabled={calcType === CalcType.MTPL && (!newVehicle || !M1_N1.includes(category))}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={threeColumnColSpan}>
                      <Form.Item
                        name={["vehicleData", "generalPrice"]}
                        label={
                          <LabelWithTooltip
                            label={t("calc.vehicle.attrs.vehicleData.generalPrice")}
                            tooltip={t("calc.vehicle.helpers.generalPriceDesc")}
                          />
                        }
                        rules={[
                          validations.minNumber(1),
                          validations.maxNumber(price, t("calc.vehicle.attrs.vehicleData.price"))
                        ]}
                      >
                        <InputNumber
                          {...inputNumberIntegerStandardProps}
                          addonAfter={<InputAddon type="euro" />}
                          disabled={calcType === CalcType.MTPL}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={threeColumnColSpan}>
                      <Form.Item
                        name={["vehicleData", "buyingPrice"]}
                        label={t("calc.vehicle.attrs.vehicleData.buyingPrice")}
                        rules={[
                          validations.minNumber(1),
                          validations.maxNumber(price, t("calc.vehicle.attrs.vehicleData.price"))
                        ]}
                      >
                        <InputNumber
                          {...inputNumberIntegerStandardProps}
                          addonAfter={<InputAddon type="euro" />}
                          disabled={calcType === CalcType.MTPL}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={twoColumnColSpan}>
          <Divider className="divider-subheader">{t("calc.vehicle.sections.vehicleSecurity")}</Divider>

          <Row gutter={rowGutter} className="margin-top-large">
            {Object.values(VehicleSecurityFeature).map((feature, index) => (
              <Col span={threeColumnColSpan} key={index}>
                <HiddenInput name={["vehicleData", "security", index, "feature"]} initialValue={feature} />

                <Form.Item
                  name={["vehicleData", "security", index, "selected"]}
                  valuePropName="checked"
                  rules={[validations.none]}
                  initialValue={false}
                >
                  <Checkbox>{t("calc.vehicle.enums.vehicleSecurityFeature." + feature)}</Checkbox>
                </Form.Item>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <HiddenCheckbox name={["vehicleData", "registrationCertificatePresent"]} initialValue={false} />
      <HiddenInput name={["vehicleData", "registrationCertificateNumber"]} />
      <HiddenInput name={["vehicleData", "previousRegistrationCertificateNumber"]} />
      <HiddenInput name={["vehicleData", "colorId"]} />
    </Card>
  );
};

export default VehicleCalcVehicleDataSection;
