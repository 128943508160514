import { Tag } from "antd";
import React from "react";
import t from "../../../../app/i18n";
import { CommissionKind } from "../enums";

interface Props {
  kind: CommissionKind;
}

const CommissionKindTag = ({ kind }: Props) => {
  let color: string;

  switch (kind) {
    case CommissionKind.INITIAL:
      color = "geekblue";
      break;
    case CommissionKind.SUBSEQUENT:
      color = "purple";
      break;
    default:
      return null;
  }

  return (
    <Tag color={color} style={{ textAlign: "center", width: "74px", marginRight: 0 }}>
      {t("commissions.batch.enums.commissionKind." + kind)}
    </Tag>
  );
};

export default CommissionKindTag;
