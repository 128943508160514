import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { NormalizedError, RootState } from "../../../common/types";
import { initPageResult } from "../../../common/utils/apiUtils";
import { openBlobFile } from "../../../common/utils/utils";
import dashboardApi from "../../dashboard/api";
import {
  DashboardNotice,
  DashboardNoticeFilterPageRequest,
  DashboardNoticeFilterPageResult
} from "../../dashboard/types";
import { CalcType } from "../enums";
import api from "./api";
import { OfferType } from "./enums";
import {
  calculateRealtyActions,
  generateRealtyActions,
  generateRealtyOfferActions,
  RealtyCalcAction,
  realtyCalcReducer
} from "./realty/ducks";
import { RealtyCalcResultData } from "./realty/types";
import {
  calculateTravelActions,
  generateTravelActions,
  generateTravelOfferActions,
  TravelCalcAction,
  travelCalcReducer
} from "./travel/ducks";
import { TravelCalcResultData } from "./travel/types";
import {
  CalcAttachment,
  CalcAttachmentInfo,
  CalcResponse,
  CalcResultData,
  CalcsReducerState,
  GenResponse
} from "./types";
import { sortAndGroupCalcResults } from "./utils";
import {
  calculateVehicleActions,
  generateVehicleActions,
  generateVehicleOfferActions,
  VehicleCalcAction,
  vehicleCalcReducer
} from "./vehicle/ducks";
import { VehicleCalcResultData } from "./vehicle/types";
import { sortAndGroupVehicleCalcResults } from "./vehicle/utils";

/**
 * ACTIONS
 */
export const downloadCardReaderActions = createAsyncAction(
  "calc/DOWNLOAD_CARD_READER_REQUEST",
  "calc/DOWNLOAD_CARD_READER_SUCCESS",
  "calc/DOWNLOAD_CARD_READER_FAILURE"
)<void, void, NormalizedError>();

export const filterCalcNoticesActions = createAsyncAction(
  "calc/FILTER_NOTICES_REQUEST",
  "calc/FILTER_NOTICES_SUCCESS",
  "calc/FILTER_NOTICES_FAILURE"
)<DashboardNoticeFilterPageRequest, DashboardNoticeFilterPageResult, NormalizedError>();

export const deleteStateCalcNoticesPageAction = createAction("calc/DELETE_STATE_NOTICES_LIST")<void>();

const actions = {
  downloadCardReaderActions,
  filterCalcNoticesActions,
  deleteStateCalcNoticesPageAction
};

export type CalcAction = ActionType<typeof actions> | RealtyCalcAction | TravelCalcAction | VehicleCalcAction;

/**
 * REDUCERS
 */
const initialState: Partial<CalcsReducerState> = {
  noticesPage: { ...initPageResult<DashboardNotice>(), position: null, onlyUnclosed: true }
};

const noticesPageReducer = createReducer(initialState.noticesPage)
  .handleAction(filterCalcNoticesActions.success, (_, { payload }) => payload)
  .handleAction([filterCalcNoticesActions.failure, deleteStateCalcNoticesPageAction], () => initialState.noticesPage);

export const calcsReducer = combineReducers<CalcsReducerState>({
  vehicle: vehicleCalcReducer,
  realty: realtyCalcReducer,
  travel: travelCalcReducer,
  noticesPage: noticesPageReducer
});

/**
 * SELECTORS
 */
const selectCalcs = (state: RootState): CalcsReducerState => state.calculator.calcs;

export const selectCalcNoticesPage = (state: RootState): DashboardNoticeFilterPageResult =>
  selectCalcs(state).noticesPage;

/**
 * SAGAS
 */
function* calculate({
  payload
}:
  | ReturnType<typeof calculateVehicleActions.request>
  | ReturnType<typeof calculateTravelActions.request>
  | ReturnType<typeof calculateRealtyActions.request>) {
  try {
    const response: AxiosResponse<CalcResponse<CalcResultData>> = yield call(api.calculate, payload);
    switch (payload.type) {
      case CalcType.MTPL:
      case CalcType.CRASH:
      case CalcType.MTPL_CRASH:
      case CalcType.GAP:
      case CalcType.PAS:
        yield put(
          calculateVehicleActions.success(
            sortAndGroupVehicleCalcResults(response.data as CalcResponse<VehicleCalcResultData>)
          )
        );
        break;
      case CalcType.TRAVEL:
        yield put(
          calculateTravelActions.success(
            sortAndGroupCalcResults<TravelCalcResultData>(response.data as CalcResponse<TravelCalcResultData>)
          )
        );
        break;
      case CalcType.REALTY:
        yield put(
          calculateRealtyActions.success(
            sortAndGroupCalcResults<RealtyCalcResultData>(response.data as CalcResponse<RealtyCalcResultData>)
          )
        );
        break;
    }
  } catch (error) {
    switch (payload.type) {
      case CalcType.MTPL:
      case CalcType.CRASH:
      case CalcType.MTPL_CRASH:
      case CalcType.GAP:
      case CalcType.PAS:
        yield put(calculateVehicleActions.failure(error));
        break;
      case CalcType.TRAVEL:
        yield put(calculateTravelActions.failure(error));
        break;
      case CalcType.REALTY:
        yield put(calculateRealtyActions.failure(error));
        break;
    }
  }
}

function* generate({
  payload
}:
  | ReturnType<typeof generateVehicleActions.request>
  | ReturnType<typeof generateTravelActions.request>
  | ReturnType<typeof generateRealtyActions.request>) {
  try {
    let attachmentInfos: CalcAttachmentInfo[];

    if (payload.attachmentsToUpload?.size > 0) {
      const formData = new FormData();
      payload.attachmentsToUpload.forEach((file, type) => {
        formData.append("files", file);
        formData.append("types", type);
      });
      const uploadResponse: AxiosResponse<CalcAttachment[]> = yield call(api.uploadCalcAttachments, formData);
      attachmentInfos = uploadResponse.data.map<CalcAttachmentInfo>(a => ({ id: a.id, type: a.type }));
    }

    const response: AxiosResponse<GenResponse> = yield call(api.generate, {
      ...payload,
      attachments: attachmentInfos,
      attachmentsToUpload: undefined
    });

    switch (payload.type) {
      case CalcType.MTPL:
      case CalcType.CRASH:
      case CalcType.MTPL_CRASH:
      case CalcType.GAP:
      case CalcType.PAS:
        yield put(generateVehicleActions.success(response.data));
        break;
      case CalcType.TRAVEL:
        yield put(generateTravelActions.success(response.data));
        break;
      case CalcType.REALTY:
        yield put(generateRealtyActions.success(response.data));
        break;
    }
  } catch (error) {
    switch (payload.type) {
      case CalcType.MTPL:
      case CalcType.CRASH:
      case CalcType.MTPL_CRASH:
      case CalcType.GAP:
      case CalcType.PAS:
        yield put(generateVehicleActions.failure(error));
        break;
      case CalcType.TRAVEL:
        yield put(generateTravelActions.failure(error));
        break;
      case CalcType.REALTY:
        yield put(generateRealtyActions.failure(error));
        break;
    }
  }
}

function* generateOffer({
  payload
}:
  | ReturnType<typeof generateVehicleOfferActions.request>
  | ReturnType<typeof generateTravelOfferActions.request>
  | ReturnType<typeof generateRealtyOfferActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.generateOffer, payload);
    openBlobFile(response, true);
    switch (payload.type) {
      case OfferType.MTPL:
      case OfferType.CRASH:
      case OfferType.MTPL_CRASH:
        yield put(generateVehicleOfferActions.success());
        break;
      case OfferType.TRAVEL:
        yield put(generateTravelOfferActions.success());
        break;
      case OfferType.REALTY:
      case OfferType.REALTY_RISKS:
        yield put(generateRealtyOfferActions.success());
        break;
    }
  } catch (error) {
    switch (payload.type) {
      case OfferType.MTPL:
      case OfferType.CRASH:
      case OfferType.MTPL_CRASH:
        yield put(generateVehicleOfferActions.failure(error));
        break;
      case OfferType.TRAVEL:
        yield put(generateTravelOfferActions.failure(error));
        break;
      case OfferType.REALTY:
      case OfferType.REALTY_RISKS:
        yield put(generateRealtyOfferActions.failure(error));
        break;
    }
  }
}

function* downloadCardReader() {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadCardReader);
    openBlobFile(response, true);
    yield put(downloadCardReaderActions.success());
  } catch (error) {
    yield put(downloadCardReaderActions.failure(error));
  }
}

function* filterCalcNotices({ payload }: ReturnType<typeof filterCalcNoticesActions.request>) {
  try {
    const response: AxiosResponse<DashboardNoticeFilterPageResult> = yield call(
      dashboardApi.filterDashboardNotices,
      payload
    );
    yield put(filterCalcNoticesActions.success(response.data));
  } catch (error) {
    yield put(filterCalcNoticesActions.failure(error));
  }
}

export function* calcsSaga() {
  yield takeLatest(calculateVehicleActions.request, calculate);
  yield takeLatest(generateVehicleActions.request, generate);
  yield takeLatest(generateVehicleOfferActions.request, generateOffer);

  yield takeLatest(calculateTravelActions.request, calculate);
  yield takeLatest(generateTravelActions.request, generate);
  yield takeLatest(generateTravelOfferActions.request, generateOffer);

  yield takeLatest(calculateRealtyActions.request, calculate);
  yield takeLatest(generateRealtyActions.request, generate);
  yield takeLatest(generateRealtyOfferActions.request, generateOffer);

  yield takeLatest(downloadCardReaderActions.request, downloadCardReader);
  yield takeLatest(filterCalcNoticesActions.request, filterCalcNotices);
}
