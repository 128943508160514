import { Button, Card, Col, Form, Row, Select } from "antd";
import React, { useEffect } from "react";
import t from "../../../../../app/i18n";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../common/constants";
import { selectTagsStandardProps } from "../../../../../common/utils/formUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import AgentSelect from "../../../../enumerations/components/form/AgentSelect";
import InstitutionSelect from "../../../../enumerations/components/form/InstitutionSelect";
import { InstitutionType } from "../../../../institution/enums";
import { CalcType, calcTypeTMap } from "../../../enums";
import CalcTypeTag from "../../../records/components/CalcTypeTag";
import { CalcBlacklistFilterPageRequest, CalcBlacklistFilterPageResult } from "../../types";

interface Props {
  filter: CalcBlacklistFilterPageResult;
  onFilterSubmit: (filter: CalcBlacklistFilterPageRequest) => void;
  onCreateClick: () => void;
}

const CalcBlacklistFilterView = ({ filter, onFilterSubmit, onCreateClick }: Props) => {
  const [form] = Form.useForm<CalcBlacklistFilterPageRequest>();
  const colSpan = 6;

  useEffect(() => {
    form.setFieldsValue({
      calcType: filter.calcType,
      institutionIds: filter.institutionIds || [],
      agentIds: filter.agentIds || []
    });
  }, [filter, form]);

  return (
    <Card
      className="card-box"
      title={<h2>{t("calc.blacklist.titles.pageTitle")}</h2>}
      extra={
        <Button type="primary" icon={<AntIcon type="plus" />} onClick={onCreateClick}>
          {t("calc.blacklist.actions.createBlacklist")}
        </Button>
      }
    >
      <Form form={form} onFinish={onFilterSubmit} layout="vertical" name="calcBlacklistSearchForm">
        <Row gutter={rowGutter} justify="center">
          <Col span={colSpan}>
            <Form.Item name="calcType" label={t("calc.enums.calcType._label")} rules={[validations.none]}>
              <Select
                {...selectTagsStandardProps(calcTypeTMap)}
                allowClear
                options={Object.keys(CalcType).map(calcType => ({
                  value: calcType,
                  label: <CalcTypeTag type={calcType as CalcType} />
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <InstitutionSelect
              formItemProps={{
                name: "institutionIds",
                label: t("common.insuranceInstitutions"),
                rules: [validations.none]
              }}
              selectProps={{ mode: "multiple", maxTagCount: "responsive", allowClear: true }}
              optionsProps={{
                includeDeactivated: true,
                filterType: InstitutionType.INSURANCE_COMPANY
              }}
            />
          </Col>

          <Col span={colSpan}>
            <AgentSelect
              formItemProps={{
                name: "agentIds",
                label: t("common.agents"),
                rules: [validations.none]
              }}
              selectProps={{ mode: "multiple", maxTagCount: "responsive", allowClear: true }}
            />
          </Col>

          <Col flex="110px" className="no-title-space">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default CalcBlacklistFilterView;
