import { Form, TreeSelect, TreeSelectProps } from "antd";
import { FormItemProps } from "antd/lib/form";
import groupBy from "lodash/groupBy";
import { DataNode } from "rc-tree-select/lib/interface";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import { RootState } from "../../../../common/types";
import { treeNodeFilterFunction } from "../../../../common/utils/formUtils";
import { InstitutionType } from "../../../institution/enums";
import { selectInstitutionsEnums } from "../../ducks";
import { InstitutionWithSettings } from "../../types";

interface Props {
  formItemProps: FormItemProps;
  selectProps?: TreeSelectProps<string>;
}

const InstitutionTreeSelect = ({ formItemProps, selectProps }: Props) => {
  const institutionsEnums = useSelector<RootState, InstitutionWithSettings[]>(selectInstitutionsEnums);

  const institutionsTree = useMemo(() => {
    const institutionGroups = groupBy(
      institutionsEnums.filter(i => !i.settings.deactivated),
      i => i.type
    );
    return Object.keys(institutionGroups)
      .sort((a, b) => Object.keys(InstitutionType).indexOf(a) - Object.keys(InstitutionType).indexOf(b))
      .map<DataNode>(type => ({
        value: type,
        title: t("institution.helpers.optGroup." + type),
        children: institutionGroups[type].map<DataNode>(institution => ({
          value: institution.id,
          title: institution.name
        }))
      }));
  }, [institutionsEnums]);

  return (
    <Form.Item {...formItemProps}>
      <TreeSelect
        showCheckedStrategy={TreeSelect.SHOW_PARENT}
        treeCheckable
        maxTagCount="responsive"
        dropdownMatchSelectWidth={false}
        showSearch
        allowClear
        filterTreeNode={treeNodeFilterFunction}
        treeData={institutionsTree}
        {...selectProps}
      />
    </Form.Item>
  );
};

export default InstitutionTreeSelect;
