import { Card } from "antd";
import { TablePaginationConfig } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { Pathname } from "history";
import isEqual from "lodash/isEqual";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { PageSizes } from "../../../common/constants";
import { ExportFileType, OrderDirection } from "../../../common/enums";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";
import { ActionProps, RootState } from "../../../common/types";
import { sortOrderToOrderDirection } from "../../../common/utils/formUtils";
import { appendSearchParamsToURL, numberOrZero } from "../../../common/utils/utils";
import { selectRouterLocationPathname, selectRouterLocationSearch } from "../../ducks";
import { selectEnums } from "../../enumerations/ducks";
import { Enumerations } from "../../enumerations/types";
import RecalculatePredictedCommissionsForm from "../components/forms/RecalculatePredictedCommissionsForm";
import ContractListFilterView from "../components/views/list/ContractListFilterView";
import ContractListTableView from "../components/views/list/ContractListTableView";
import {
  changeContractVerificationStatusActions,
  deleteStateContractPageAction,
  downloadContractsExportActions,
  filterContractsActions,
  recalculatePredictedCommissionsActions,
  selectCurrentContractsPage
} from "../ducks";
import { ContractOrderByColumn, ContractStatus, ContractVerificationStatus, ContractView } from "../enums";
import { ContractFilterPageRequest, ContractFilterPageResult, ContractList } from "../types";

interface StateProps {
  currentContractsPage: ContractFilterPageResult;
  enumerations: Enumerations;
  urlSearchQuery: string;
  locationPathname: Pathname;
}

interface ActionsMap {
  filterContracts: typeof filterContractsActions.request;
  downloadContractsExport: typeof downloadContractsExportActions.request;
  recalculatePredictedCommissions: typeof recalculatePredictedCommissionsActions.request;
  changeContractVerificationStatus: typeof changeContractVerificationStatusActions.request;
  deleteStateContractPage: typeof deleteStateContractPageAction;
}

const ContractListContainer = ({
  currentContractsPage,
  enumerations,
  urlSearchQuery,
  locationPathname,
  actions
}: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();

  const [recalculatePredictedCommissionsModalOpen, setRecalculatePredictedCommissionsModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    const { productGroups, institutions, subordinateAgents, affiliatePartners } = enumerations;
    const products = productGroups.map(productGroup => productGroup.products).flat();

    const locationPathnameParts = locationPathname.split("/");
    const lastPathnamePart = locationPathnameParts[locationPathnameParts.length - 1];
    let report: ContractView;
    switch (lastPathnamePart) {
      case "predicted-commissions":
        report = ContractView.INTERNAL_PREDICTED_COMMISSIONS_REPORT;
        break;
      case "anniversary-date":
        report = ContractView.INTERNAL_ANNIVERSARY_DATE_REPORT;
        break;
      case "unpaid":
        report = ContractView.INTERNAL_UNPAID_REPORT;
        break;
      default:
        report = ContractView.INTERNAL_GENERIC_REPORT;
        break;
    }

    const urlParams = new URLSearchParams(urlSearchQuery);
    const initialFilter: ContractFilterPageRequest = {
      report,
      orderBy:
        urlParams.getAll("orderBy").length > 0
          ? urlParams.getAll("orderBy").map(column => ContractOrderByColumn[column])
          : getDefaultOrderBy(report),
      orderDirections:
        urlParams.getAll("orderDirections").length > 0
          ? urlParams.getAll("orderDirections").map(direction => OrderDirection[direction])
          : getDefaultOrderDirections(report),
      keyword: urlParams.get("keyword"),
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: urlParams.get("pageSize") ? parseInt(urlParams.get("pageSize")) : PageSizes.HUGE,
      institutionIds: urlParams
        .getAll("institutionIds")
        .filter(id => institutions.some(i => i.id === id && !i.settings.deactivated)),
      productIds: urlParams.getAll("productIds").filter(id => products.some(p => p.id === id)),
      signerIds: urlParams.getAll("signerIds").filter(id => subordinateAgents.some(a => a.id === id)),
      managerIds: urlParams.getAll("managerIds").filter(id => subordinateAgents.some(a => a.id === id)),
      gainerIds: urlParams.getAll("gainerIds").filter(id => subordinateAgents.some(a => a.id === id)),
      affiliatePartnerIds: urlParams
        .getAll("affiliatePartnerIds")
        .filter(id => affiliatePartners.some(ap => ap.id === id)),
      statuses: urlParams.getAll("statuses").map(status => ContractStatus[status]),
      verificationStatuses: urlParams.getAll("verificationStatuses").map(status => ContractVerificationStatus[status]),
      createdAtMin: urlParams.get("createdAtMin"),
      createdAtMax: urlParams.get("createdAtMax"),
      mediationReportSignDateMin: urlParams.get("mediationReportSignDateMin"),
      mediationReportSignDateMax: urlParams.get("mediationReportSignDateMax"),
      effectiveBeginningDateOrSignDateMin: urlParams.get("effectiveBeginningDateOrSignDateMin"),
      effectiveBeginningDateOrSignDateMax: urlParams.get("effectiveBeginningDateOrSignDateMax"),
      effectiveEndDateOrLoanMaturityDateMin: urlParams.get("effectiveEndDateOrLoanMaturityDateMin"),
      effectiveEndDateOrLoanMaturityDateMax: urlParams.get("effectiveEndDateOrLoanMaturityDateMax"),
      cancellationDateMin: urlParams.get("cancellationDateMin"),
      cancellationDateMax: urlParams.get("cancellationDateMax"),
      transferredFromOtherBroker: urlParams.get("transferredFromOtherBroker") === "true" || null,
      transferredToOtherBrokerDateMin: urlParams.get("transferredToOtherBrokerDateMin"),
      transferredToOtherBrokerDateMax: urlParams.get("transferredToOtherBrokerDateMax"),
      annualPremiumOrApprovedAmountMin:
        urlParams.get("annualPremiumOrApprovedAmountMin") &&
        parseFloat(urlParams.get("annualPremiumOrApprovedAmountMin")),
      annualPremiumOrApprovedAmountMax:
        urlParams.get("annualPremiumOrApprovedAmountMax") &&
        parseFloat(urlParams.get("annualPremiumOrApprovedAmountMax")),
      vehicleFirstRegistrationYearMin:
        urlParams.get("vehicleFirstRegistrationYearMin") && parseInt(urlParams.get("vehicleFirstRegistrationYearMin")),
      vehicleFirstRegistrationYearMax:
        urlParams.get("vehicleFirstRegistrationYearMax") && parseInt(urlParams.get("vehicleFirstRegistrationYearMax"))
    };

    actions.filterContracts(initialFilter);

    return () => {
      actions.deleteStateContractPage();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getDefaultOrderBy = (report: ContractView): ContractOrderByColumn[] => {
    return report === ContractView.INTERNAL_ANNIVERSARY_DATE_REPORT
      ? [ContractOrderByColumn.LAST_CONTRACT_CANCELLATION_OR_CONTACT_CLIENT_DATE]
      : [ContractOrderByColumn.CREATED_AT];
  };

  const getDefaultOrderDirections = (report: ContractView): OrderDirection[] => {
    return report === ContractView.INTERNAL_ANNIVERSARY_DATE_REPORT ? [OrderDirection.ASC] : [OrderDirection.DESC];
  };

  const mapFilterResultToFilterRequest = (result: ContractFilterPageResult): ContractFilterPageRequest => {
    const newResult = { ...result };

    delete newResult.pageData;
    delete newResult.totalElementsCount;
    delete newResult.pageElementsCount;
    delete newResult.pagesCount;
    delete newResult.isFirst;
    delete newResult.isLast;

    return newResult;
  };

  const appendSearchParamsToLink = (filter: ContractFilterPageRequest): void => {
    const shouldSerializeOrderIntoUrl =
      !isEqual(filter.orderBy, getDefaultOrderBy(filter.report)) ||
      !isEqual(filter.orderDirections, getDefaultOrderDirections(filter.report));

    const urlParams = {
      ...filter,
      pageSize: filter.pageSize !== PageSizes.HUGE ? filter.pageSize : null,
      report: null,
      orderBy: shouldSerializeOrderIntoUrl ? filter.orderBy : null,
      orderDirections: shouldSerializeOrderIntoUrl ? filter.orderDirections : null,
      transferredFromOtherBroker: filter.transferredFromOtherBroker || null
    } as ContractFilterPageRequest;
    navigate(appendSearchParamsToURL(urlParams), { replace: true });
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ContractList> | SorterResult<ContractList>[]
  ): void => {
    const orderBy = [];
    const orderDirections = [];
    if (Array.isArray(sorter)) {
      sorter
        .filter(item => item.order)
        .forEach(item => {
          const order = ContractOrderByColumn[item.field.toString()];
          if (orderBy.indexOf(order) === -1) {
            orderBy.push(order);
            orderDirections.push(sortOrderToOrderDirection(item.order));
          }
        });
    } else if (sorter.order) {
      orderBy.push(ContractOrderByColumn[sorter.field.toString()]);
      orderDirections.push(sortOrderToOrderDirection(sorter.order));
    }

    const filter = {
      ...mapFilterResultToFilterRequest(currentContractsPage),
      pageIndex: pagination.current - 1,
      pageSize: pagination.pageSize,
      orderBy,
      orderDirections
    };

    appendSearchParamsToLink(filter);
    actions.filterContracts(filter);
  };

  const handleFilterSubmit = (filter: ContractFilterPageRequest): void => {
    const filterRequest = {
      ...mapFilterResultToFilterRequest(currentContractsPage),
      ...filter,
      keyword: filter.keyword || null,
      pageIndex: 0
    };

    appendSearchParamsToLink({ ...filterRequest, pageIndex: null });
    actions.filterContracts(filterRequest);
  };

  const handleExportClick = (exportFileType: ExportFileType): void => {
    actions.downloadContractsExport({
      ...mapFilterResultToFilterRequest(currentContractsPage),
      exportFileType
    });
  };

  return (
    <DisplayWrapper itemLoaded={!!currentContractsPage.report}>
      <ContractListFilterView
        contractsPage={currentContractsPage}
        onFilterSubmit={handleFilterSubmit}
        onRecalculatePredictedCommissionsClick={() => setRecalculatePredictedCommissionsModalOpen(true)}
      />
      <Card>
        <ContractListTableView
          contractsPage={currentContractsPage}
          onTableChange={handleTableChange}
          onVerificationStatusChange={actions.changeContractVerificationStatus}
          onExportClick={handleExportClick}
        />
      </Card>
      <RecalculatePredictedCommissionsForm
        open={recalculatePredictedCommissionsModalOpen}
        onSubmit={actions.recalculatePredictedCommissions}
        onFormCancel={() => setRecalculatePredictedCommissionsModalOpen(false)}
      />
    </DisplayWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  currentContractsPage: selectCurrentContractsPage(state),
  enumerations: selectEnums(state),
  urlSearchQuery: selectRouterLocationSearch(state),
  locationPathname: selectRouterLocationPathname(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterContracts: filterContractsActions.request,
      downloadContractsExport: downloadContractsExportActions.request,
      recalculatePredictedCommissions: recalculatePredictedCommissionsActions.request,
      changeContractVerificationStatus: changeContractVerificationStatusActions.request,
      deleteStateContractPage: deleteStateContractPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ContractListContainer);
