import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { appendSearchParamsToURL } from "../../../../../common/utils/utils";
import { VEHICLE_ENUMS_TAB } from "../../../containers/VehicleEnumsContainer";
import {
  CertificateVehicleCategory,
  CertificateVehicleCategoryFilterPageRequest,
  CertificateVehicleCategoryFilterPageResult,
  VehicleCategoryActions
} from "../../types";
import VehicleCategoryForm from "../forms/VehicleCategoryForm";
import VehicleCategoryFilterView from "./VehicleCategoryFilterView";
import VehicleCategoryTableView from "./VehicleCategoryTableView";

interface Props {
  categoriesCurrentPage: CertificateVehicleCategoryFilterPageResult;
  actions: VehicleCategoryActions;
}

const VehicleCategoryTabView = ({ categoriesCurrentPage, actions }: Props) => {
  const navigate = useNavigate();

  const [categoryFormOpen, setCategoryFormOpen] = useState<boolean>(false);
  const [categoryToUpdate, setCategoryToUpdate] = useState<CertificateVehicleCategory>(null);

  const handleCreateClick = (): void => {
    setCategoryFormOpen(true);
  };

  const handleUpdateClick = (category: CertificateVehicleCategory): void => {
    setCategoryFormOpen(true);
    setCategoryToUpdate(category);
  };

  const handleFormCancel = (): void => {
    setCategoryFormOpen(false);
    setCategoryToUpdate(null);
  };

  const handleFilterSubmit = (filter: CertificateVehicleCategoryFilterPageRequest): void => {
    navigate(
      appendSearchParamsToURL({
        pageIndex: null,
        keyword: filter.keyword || null,
        categories: filter.categories,
        tab: VEHICLE_ENUMS_TAB.CATEGORIES
      }),
      { replace: true }
    );

    actions.onFilter({
      pageIndex: 0,
      pageSize: categoriesCurrentPage.pageSize,
      keyword: filter.keyword,
      categories: filter.categories
    });
  };

  const handlePageChange = (pageNumber: number): void => {
    const { pageSize, keyword, categories } = categoriesCurrentPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    actions.onFilter({ pageIndex: pageNumber - 1, pageSize, keyword, categories });
  };

  return (
    <div className="margin-top-small">
      <VehicleCategoryFilterView
        filter={categoriesCurrentPage}
        onFilterSubmit={handleFilterSubmit}
        onCreateClick={handleCreateClick}
      />

      <VehicleCategoryTableView
        categoriesCurrentPage={categoriesCurrentPage}
        onPageChange={handlePageChange}
        onUpdateClick={handleUpdateClick}
        onDelete={actions.onDelete}
      />

      <VehicleCategoryForm
        category={categoryToUpdate}
        open={categoryFormOpen}
        onCreate={actions.onCreate}
        onUpdate={actions.onUpdate}
        onFormCancel={handleFormCancel}
      />
    </div>
  );
};

export default VehicleCategoryTabView;
