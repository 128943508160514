import { FormInstance } from "antd/lib/form";
import t, { DEFAULT_LOCALE } from "../../../app/i18n";
import { HttpStatus } from "../../../common/constants";
import { ErrorResponse } from "../../../common/types";
import { showErrorResponseNotification } from "../../../common/utils/apiUtils";
import { setErrorsToForm } from "../../../common/utils/formUtils";
import { ClientFormType } from "../../client/enums";
import { processClientsDataViolations } from "../../client/utils";
import { CalcType } from "../enums";
import { RealtyGen } from "./realty/types";
import { TravelGen } from "./travel/types";
import { CalcResponse, CalcResult, CalcResultData, GenResponseViolations, StepInfo } from "./types";
import { VehicleGen } from "./vehicle/types";

export const DEFEND_D2C_COVERAGE = "D2C";

export const sortAndGroupCalcResults = <T extends CalcResultData>(calcResponse: CalcResponse<T>): CalcResult<T>[][] => {
  let sortedResults: CalcResult<T>[][] = [];

  calcResponse.results.forEach(result => {
    const index = sortedResults.findIndex(items => items[0].insuranceInstitution.id === result.insuranceInstitution.id);
    if (index === -1) {
      sortedResults.push([result as CalcResult<T>]);
    } else {
      sortedResults[index].push(result as CalcResult<T>);
    }
  });

  const rowsWithoutError: CalcResult<T>[][] = [];
  const rowsWithError: CalcResult<T>[][] = [];
  sortedResults.forEach(resultsRow => {
    if (resultsRow.some(result => result.error)) {
      rowsWithError.push(resultsRow);
    } else {
      rowsWithoutError.push(resultsRow);
    }
  });

  sortResultGroupsByInstitutionName<T>(rowsWithoutError);
  sortResultGroupsByInstitutionName<T>(rowsWithError);
  sortedResults = [...rowsWithoutError, ...rowsWithError];

  sortResultsWithinGroupsByCoverageIndex<T>(sortedResults);

  return sortedResults;
};

export const checkIfStepsAreSuccessful = <T>(steps: StepInfo<T>[], ...checkedSteps: T[]): boolean => {
  return checkedSteps.every(checkedStep => steps.some(step => step.step === checkedStep && step.successful));
};

export const filterApplicableResults = <T extends CalcResultData = CalcResultData>(
  results: CalcResult<T>[]
): CalcResult<T>[] => {
  return results.filter(result => isApplicableResult(result));
};

export const isApplicableResult = <T extends CalcResultData = CalcResultData>(result: CalcResult<T>): boolean => {
  return !result.data || !result.data.notApplicable;
};

export const filterApplicableSuccessResults = <T extends CalcResultData = CalcResultData>(
  results: CalcResult<T>[]
): CalcResult<T>[] => {
  return results.filter(result => isApplicableSuccessResult(result));
};

export const isApplicableSuccessResult = <T extends CalcResultData = CalcResultData>(
  result: CalcResult<T>
): boolean => {
  return result.data && !result.data.notApplicable;
};

export const sortResultGroupsByInstitutionName = <T extends CalcResultData>(resultGroups: CalcResult<T>[][]): void => {
  resultGroups.sort((a, b) =>
    a[0].insuranceInstitution.name.localeCompare(b[0].insuranceInstitution.name, DEFAULT_LOCALE, {
      sensitivity: "accent"
    })
  );
};

export const sortResultGroupsByAnnualPremium = <T extends CalcResultData>(resultGroups: CalcResult<T>[][]): void => {
  resultGroups.sort((a, b) => {
    const result1 = a.find(result => result.data && !result.data.notApplicable);
    const result2 = b.find(result => result.data && !result.data.notApplicable);
    return !result1 ? 1 : !result2 ? -1 : result1.data.annualPremium - result2.data.annualPremium;
  });
};

export const sortResultsWithinGroupsByCoverageIndex = <T extends CalcResultData>(
  resultGroups: CalcResult<T>[][]
): void => {
  for (let index = 0; index < resultGroups.length; index++) {
    resultGroups[index] = resultGroups[index].sort((a, b) => a.coverageOrderIndex - b.coverageOrderIndex);
  }
};

export const processGenResultError = (
  error: ErrorResponse,
  clientsIndicesMap: Map<ClientFormType, number>,
  form: FormInstance,
  translationRootPath: string
): GenResponseViolations => {
  if (error.status === HttpStatus.UNPROCESSABLE_ENTITY && error.violations) {
    const clientsViolations = processClientsDataViolations(error.violations, clientsIndicesMap, "clientsData.clients");
    const notificationViolations = error.violations.filter(
      violation => !violation.fieldPath.startsWith("clientsData.clients")
    );

    if (clientsViolations.size > 0) {
      notificationViolations.unshift({
        fieldPath: "clientsData._label",
        errors: [t("calc.validations.clientsViolationError")],
        fieldValue: null
      });
    }

    setErrorsToForm(form, translationRootPath, error.violations);

    return { clientsViolations, notificationViolations };
  }

  showErrorResponseNotification(error);
  return null;
};

export const resolveClientFormTypeIdentifierName = (type: ClientFormType): string => {
  switch (type) {
    case ClientFormType.INSURED:
      return "insuredClientIdentifier";
    case ClientFormType.POLICY_HOLDER:
      return "policyHolderIdentifier";
    case ClientFormType.REPRESENTATIVE:
      return "representativeIdentifier";
    case ClientFormType.VINCULATION:
      return "vinculationClientIdentifier";
    case ClientFormType.HOLDER:
      return "holderIdentifier";
    case ClientFormType.OWNER:
      return "ownerIdentifier";
    case ClientFormType.CO_DEBTOR:
      return "coDebtorIdentifier";
    default:
      return null;
  }
};

export const isRealtyGenData = (
  calcData: Partial<TravelGen> | Partial<VehicleGen> | Partial<RealtyGen>
): calcData is RealtyGen => {
  return calcData.type === CalcType.REALTY;
};

export const isTravelGenData = (
  calcData: Partial<TravelGen> | Partial<VehicleGen> | Partial<RealtyGen>
): calcData is TravelGen => {
  return calcData.type === CalcType.TRAVEL;
};

export const isVehicleGenData = (
  calcData: Partial<TravelGen> | Partial<VehicleGen> | Partial<RealtyGen>
): calcData is VehicleGen => {
  return (
    calcData.type === CalcType.MTPL ||
    calcData.type === CalcType.MTPL_CRASH ||
    calcData.type === CalcType.GAP ||
    calcData.type === CalcType.CRASH ||
    calcData.type === CalcType.PAS
  );
};
