import { Col, Form, Input, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import t from "../../../../../app/i18n";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import { ModalSizes, rowGutter } from "../../../../../common/constants";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { requests } from "../../api";
import {
  createCommissionsBatchActions,
  getCommissionsBatchNamePrefixActions,
  updateCommissionsBatchActions
} from "../../ducks";
import { CommissionsBatch, CreateUpdateCommissionsBatch } from "../../types";

interface Props {
  batch: CommissionsBatch;
  open: boolean;
  namePrefix?: string;
  onNamePrefixGet?: typeof getCommissionsBatchNamePrefixActions.request;
  onCreate?: typeof createCommissionsBatchActions.request;
  onUpdate: typeof updateCommissionsBatchActions.request;
  onFormCancel: () => void;
}

const CommissionsBatchForm = ({
  batch,
  open,
  namePrefix,
  onNamePrefixGet,
  onCreate,
  onUpdate,
  onFormCancel
}: Props) => {
  const [form] = Form.useForm<CreateUpdateCommissionsBatch>();
  useFormErrorHandler(form, "commissions.batch.attrs", [
    requests.CREATE_COMMISSIONS_BATCH,
    requests.UPDATE_COMMISSIONS_BATCH
  ]);

  const [updatingNamePrefix, setUpdatingNamePrefix] = useState<string>(null);

  useEffect(() => {
    if (open) {
      if (batch) {
        setUpdatingNamePrefix(batch.name.substring(0, 10));
        form.setFieldsValue({
          optimisticLockVersion: batch.optimisticLockVersion,
          name: batch.name.substring(11)
        });
      } else {
        onNamePrefixGet?.();
      }
    } else {
      setUpdatingNamePrefix(null);
    }
  }, [open, batch, form]); // eslint-disable-line react-hooks/exhaustive-deps

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_COMMISSIONS_BATCH, requests.UPDATE_COMMISSIONS_BATCH],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (batch) {
          onUpdate({
            id: batch.id,
            object: { ...values, name: values.name ? updatingNamePrefix + "-" + values.name : updatingNamePrefix }
          });
        } else {
          onCreate?.({ ...values, name: values.name ? namePrefix + "-" + values.name : namePrefix });
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={batch ? t("commissions.batch.titles.updateBatch") : t("commissions.batch.titles.createBatch")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="commissionsBatchForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={16} offset={4}>
            <Form.Item name="name" label={t("commissions.batch.attrs.name")} rules={[validations.size(1, 64)]}>
              <Input
                addonBefore={batch ? updatingNamePrefix : namePrefix}
                placeholder={t("commissions.batch.helpers.batchNamePlaceholder")}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CommissionsBatchForm;
