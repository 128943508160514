import { Button, Col, Input, Modal, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import t from "../../../../app/i18n";
import AntIcon from "../../../../common/components/icons/AntIcon";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { ModalSizes, rowGutter, TableSizes } from "../../../../common/constants";
import {
  formatAgentName,
  formatLocaleCurrency,
  formatLocaleCurrencyWithNullAsZero
} from "../../../../common/utils/formatUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { paginationStandardProps, tableStandardProps } from "../../../../common/utils/utils";
import { CommissionsBatchOutputAttachment } from "../../batch/types";
import { removeBatchNameCustomSuffix } from "../../batch/utils";
import {
  SpecialCommissionBase,
  SpecialCommissionsFilterPageRequest,
  SpecialCommissionsFilterPageResult
} from "../../special/types";
import { requests } from "../api";

interface Props {
  commissionsOutput: CommissionsBatchOutputAttachment;
  commissionsPage: SpecialCommissionsFilterPageResult<SpecialCommissionBase>;
  onClose: () => void;
  onAfterClose: () => void;
  onFilterSubmit: (filter: SpecialCommissionsFilterPageRequest) => void;
  onPageChange: (pageNumber: number) => void;
}

const OutputSpecialCommissionTableView = ({
  commissionsOutput,
  commissionsPage,
  onClose,
  onAfterClose,
  onFilterSubmit,
  onPageChange
}: Props) => {
  const columns: ColumnsType<SpecialCommissionBase> = [
    {
      key: "code",
      title: t("commissions.special.attrs.code"),
      width: 100,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.code}</Ellipsis>
    },
    {
      key: "name",
      title: t("commissions.special.attrs.name"),
      width: 300,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.name}</Ellipsis>
    },
    {
      key: "payer",
      title: t("commissions.special.attrs.payer"),
      width: 200,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{formatAgentName(record.payer)}</Ellipsis>
    },
    {
      key: "payee",
      title: t("commissions.special.attrs.payee"),
      width: 200,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{formatAgentName(record.payee)}</Ellipsis>
    },
    {
      key: "amount",
      title: t("commissions.special.attrs.commissionAmount"),
      width: 100,
      align: "right",
      render: (_, record) =>
        formatLocaleCurrency(
          record.payee.id === commissionsOutput.gainer.id ? record.commissionAmount : -record.commissionAmount
        )
    }
  ];

  const inProgress = useRequestFinishedCallback([requests.FILTER_OUTPUT_SPECIAL_COMMISSIONS]);

  const handleSearchSubmit = (keyword: string): void => {
    onFilterSubmit({
      pageIndex: 0,
      pageSize: commissionsPage.pageSize,
      report: commissionsPage.report,
      code: keyword || null
    });
  };

  return (
    <Modal
      width={ModalSizes.HUGE}
      title={
        commissionsOutput
          ? t("user.titles.outputSpecialCommissions") + " " + removeBatchNameCustomSuffix(commissionsOutput.batch)
          : t("user.titles.outputSpecialCommissions")
      }
      open={!!commissionsOutput}
      maskClosable={false}
      footer={
        <Button onClick={onClose} icon={<AntIcon type="close" />}>
          {t("common.close")}
        </Button>
      }
      onCancel={onClose}
      afterClose={onAfterClose}
      destroyOnClose
    >
      <Row gutter={rowGutter}>
        <Col span={24} className="center-align">
          <Input.Search
            style={{ width: "300px" }}
            className="margin-bottom-tiny"
            enterButton
            allowClear
            placeholder={t("commissions.batch.helpers.specialCommissionCodePlaceholder")}
            onSearch={handleSearchSubmit}
          />
        </Col>
      </Row>
      <Row gutter={rowGutter}>
        <Col span={24}>
          <div className={commissionsPage.totalElementsCount > 0 ? "table-header-margin" : "margin-bottom-small"}>
            <b>{t("commissions.special.helpers.filteredCommissionAmountsSum")}: </b>
            {formatLocaleCurrencyWithNullAsZero(commissionsPage.commissionAmountsSum)}
          </div>
          <Table<SpecialCommissionBase>
            {...tableStandardProps()}
            columns={columns}
            scroll={{ x: TableSizes.MEDIUM }}
            dataSource={commissionsPage.pageData}
            pagination={{
              ...paginationStandardProps,
              position: ["topRight"],
              current: commissionsPage.pageIndex + 1,
              pageSize: commissionsPage.pageSize,
              total: commissionsPage.totalElementsCount,
              onChange: onPageChange
            }}
            loading={inProgress}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default OutputSpecialCommissionTableView;
