import { Card, Form } from "antd";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import React, { useEffect, useState } from "react";
import t from "../../../../../../app/i18n";
import FieldViolationsView from "../../../../../../common/components/views/FieldViolationsView";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { FieldConstraintViolation, UUID } from "../../../../../../common/types";
import { resolveFormValidationError, setErrorsToForm } from "../../../../../../common/utils/formUtils";
import { useScrollToTopOnLoad } from "../../../../../../common/utils/hooksUtils";
import messageUtils from "../../../../../../common/utils/messageUtils";
import { CalcValidationGroup, ClientFormType, ClientType } from "../../../../../client/enums";
import {
  CreateUpdateContractClient,
  CreateUpdateNaturalClient,
  LegalClient,
  NaturalClient,
  SelfEmployedClient
} from "../../../../../client/types";
import {
  clientToCreateUpdateContractClient,
  processClientsDataViolations,
  useClientsValidation,
  useClientValidation
} from "../../../../../client/utils";
import { InstitutionEnum } from "../../../../../institution/enums";
import { CalcType, ClientExperience } from "../../../../enums";
import CalcGenNavigation from "../../../components/CalcGenNavigation";
import CalcGenResultModal from "../../../components/CalcGenResultModal";
import CalcSummaryModal from "../../../components/summary/CalcSummaryModal";
import { CalcResult, GenResponse } from "../../../types";
import { processGenResultError } from "../../../utils";
import { deleteStateRealtyGenResultAction, generateRealtyActions } from "../../ducks";
import {
  RealtyCalc,
  RealtyCalcEquipmentReinsurance,
  RealtyCalcResultData,
  RealtyFormClients,
  RealtyGen,
  RealtyGenForm,
  RealtyOfferType
} from "../../types";
import RealtyCalcResults from "../result/RealtyCalcResults";
import RealtyGenClientsDataSection from "./sections/RealtyGenClientsDataSection";
import RealtyGenOtherDataSection from "./sections/RealtyGenOtherDataSection";

interface Props {
  initialData: RealtyGenForm;
  genResult: GenResponse;
  calcData: RealtyCalc;
  clients: RealtyFormClients;
  calcResults: CalcResult<RealtyCalcResultData>[][];
  selectedResult: CalcResult<RealtyCalcResultData>;
  draftId: UUID;
  onGenerateFormSubmit: typeof generateRealtyActions.request;
  onGenResultDelete: typeof deleteStateRealtyGenResultAction;
  onClientChange: (client: NaturalClient | SelfEmployedClient | LegalClient, type: ClientFormType) => RealtyFormClients;
  onGenerateOfferClick: (type: RealtyOfferType) => void;
  onSelectedResultChange: (selectedResult: CalcResult<RealtyCalcResultData>) => void;
  onSaveDraftClick: (genData: RealtyGen, overwriteExisting: boolean) => void;
  onReturnToCalculationClick: (genFormData: RealtyGenForm) => void;
}

const RealtyGenWrapper = (props: Props) => {
  useScrollToTopOnLoad();

  const [form] = Form.useForm<RealtyGenForm>();
  const clientValidation = useClientValidation();
  const clientsValidation = useClientsValidation();

  const [resultsOpen, setResultsOpen] = useState<boolean>(false);
  const [summaryOpen, setSummaryOpen] = useState<boolean>(false);
  const [clientsViolationErrors, setClientsViolationErrors] = useState<Map<ClientFormType, FieldConstraintViolation[]>>(
    new Map()
  );

  useEffect(() => {
    if (props.initialData) {
      const formData = cloneDeep(props.initialData);

      if (formData.realtyInsuranceData) {
        formData.realtyInsuranceData = {
          ...formData.realtyInsuranceData,
          garageReinsurance: formData.realtyInsuranceData.garageReinsurance
            ? {
                ...formData.realtyInsuranceData.garageReinsurance,
                underConstruction: !!formData.realtyInsuranceData.garageReinsurance.underConstruction
              }
            : null
        };
      }

      if (props.calcData.householdInsuranceData) {
        formData.householdInsuranceData = {
          ...formData.householdInsuranceData,
          equipmentReinsurances: (
            props.calcData.householdInsuranceData.equipmentReinsurances || []
          ).map<RealtyCalcEquipmentReinsurance>((e, i) => ({
            type: e.type,
            insuranceAmount: e.insuranceAmount,
            manufacturer: formData.householdInsuranceData?.equipmentReinsurances?.[i]?.manufacturer,
            serialNumber: formData.householdInsuranceData?.equipmentReinsurances?.[i]?.serialNumber,
            otherDesc: formData.householdInsuranceData?.equipmentReinsurances?.[i]?.otherDesc
          }))
        };
      }

      const policyHolder = props.clients.policyHolder;

      if (policyHolder?.type !== ClientType.NATURAL) {
        formData.clientsData.policyHolderIsAlsoInsured = true;
      }

      if (policyHolder?.type !== ClientType.LEGAL) {
        delete formData.clientsData.representativeIdentifier;
        delete formData.clientsData.representativeFunction;
        if (props.clients.representative) {
          props.onClientChange(null, ClientFormType.REPRESENTATIVE);
        }
      }

      formData.financialMediationData = {
        ...formData.financialMediationData,
        clientExperience:
          policyHolder.type === ClientType.SELF_EMPLOYED || policyHolder.type === ClientType.LEGAL
            ? ClientExperience.SUFFICIENT
            : formData.financialMediationData.clientExperience === ClientExperience.SUFFICIENT
              ? null
              : formData.financialMediationData.clientExperience,
        ownedByClient: !!formData.financialMediationData.ownedByClient,
        recommendedResult: null
      };

      form.setFieldsValue(formData);

      const clients = createContractClientsArray(props.clients);
      if (clients.length > 1) {
        clientsValidation.onValidate({
          prefix: "clientsData.clients",
          clients,
          validationGroup: CalcValidationGroup.GENERATE
        });
      }
    } else {
      const policyHolder = props.calcData.clientsData.clients[props.calcData.clientsData.policyHolderIndex]
        .client as CreateUpdateNaturalClient;

      form.setFieldsValue({
        clientsData: {
          policyHolderIdentityCardNumber:
            policyHolder.identityCardNumber !== policyHolder.previousIdentityCardNumber
              ? policyHolder.identityCardNumber
              : null
        },
        householdInsuranceData: props.calcData.householdInsuranceData
          ? { equipmentReinsurances: props.calcData.householdInsuranceData.equipmentReinsurances || [] }
          : null
      });
    }

    return () => {
      clientValidation.onErrorResponseDelete();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const violations =
      clientValidation.errorResponse?.violations?.length > 0
        ? clientValidation.errorResponse.violations
        : clientsValidation.errorResponse?.violations?.length > 0
          ? clientsValidation.errorResponse.violations
          : null;
    if (violations) {
      const clientsIndicesMap = createClientsIndicesMap(props.clients);
      setClientsViolationErrors(
        new Map([
          ...clientsViolationErrors,
          ...processClientsDataViolations(violations, clientsIndicesMap, "clientsData.clients")
        ])
      );

      const policyHolderIdentityCardNumberViolations = violations
        .filter(
          violation =>
            violation.fieldPath ===
            `clientsData.clients[${clientsIndicesMap.get(ClientFormType.POLICY_HOLDER)}].client.identityCardNumber`
        )
        .map(violation => ({ ...violation, fieldPath: "clientsData.policyHolderIdentityCardNumber" }));

      if (policyHolderIdentityCardNumberViolations?.length) {
        setErrorsToForm(form, "calc.realty.attrs", policyHolderIdentityCardNumberViolations);
      }
    }
  }, [clientValidation.errorResponse, clientsValidation.errorResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.genResult?.error) {
      const policyHolderIndex = createClientsIndicesMap(props.clients).get(ClientFormType.POLICY_HOLDER);

      const violations = processGenResultError(
        {
          ...props.genResult.error,
          violations: props.genResult.error.violations?.map(violation =>
            violation.fieldPath === `clientsData.clients[${policyHolderIndex}].client.identityCardNumber`
              ? { ...violation, fieldPath: "clientsData.policyHolderIdentityCardNumber" }
              : violation
          )
        },
        createClientsIndicesMap(props.clients),
        form,
        "calc.realty.attrs"
      );

      if (violations) {
        messageUtils.errorNotification(
          props.genResult.error.message,
          <FieldViolationsView violations={violations.notificationViolations} rootPath="calc.realty.attrs" />,
          10
        );
        setClientsViolationErrors(violations.clientsViolations);
      }
    }
  }, [props.genResult]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClientChange = (client: NaturalClient | SelfEmployedClient | LegalClient, type: ClientFormType): void => {
    const clients = props.onClientChange(client, type);
    if (client) {
      clientValidation.onValidate({
        prefix: `clientsData.clients[${createClientsIndicesMap(clients).get(type)}]`,
        client: clientToCreateUpdateContractClient(client),
        validationGroup: CalcValidationGroup.GENERATE
      });
    }
  };

  const handleClientViolationErrorsChange = (violations: FieldConstraintViolation[], type: ClientFormType): void => {
    const updatedViolationsErrors = new Map<ClientFormType, FieldConstraintViolation[]>([...clientsViolationErrors]);
    if (violations?.length > 0) {
      updatedViolationsErrors.set(type, violations);
    } else {
      updatedViolationsErrors.delete(type);
    }
    setClientsViolationErrors(updatedViolationsErrors);
  };

  const handleGenerateFormSubmit = (): void => {
    form
      .validateFields()
      .then(() => {
        if (checkHasClientErrors()) {
          messageUtils.errorNotification(t("common.error"), t("calc.validations.formError"));
        } else {
          setSummaryOpen(true);
        }
      })
      .catch(errorInfo => {
        messageUtils.errorNotification(t("common.error"), t("calc.validations.formError"));
        resolveFormValidationError(errorInfo);
      });
  };

  const handleGenerateFormApprovalsSubmit = (): void => {
    setSummaryOpen(false);
    form
      .validateFields()
      .then(() => {
        if (checkHasClientErrors()) {
          messageUtils.errorNotification(t("common.error"), t("calc.validations.formError"));
        } else {
          props.onGenerateFormSubmit(processAndGetGenFormData());
        }
      })
      .catch(errorInfo => {
        messageUtils.errorNotification(t("common.error"), t("calc.validations.formError"));
        resolveFormValidationError(errorInfo);
      });
  };

  const handleResultsSaveDraftClick = (overwriteExisting: boolean): void => {
    setResultsOpen(false);
    props.onSaveDraftClick(processAndGetGenFormData(), overwriteExisting);
  };

  const handleResultsGenerateOfferClick = (type: RealtyOfferType): void => {
    setResultsOpen(false);
    props.onGenerateOfferClick(type);
  };

  const handleResultsGenerateContractClick = (result: CalcResult<RealtyCalcResultData>): void => {
    props.onSelectedResultChange(result);
    setResultsOpen(false);
  };

  const handleReturnToCalculationClick = (): void => {
    props.onReturnToCalculationClick(form.getFieldsValue());
  };

  const checkHasClientErrors = (): boolean => {
    const { policyHolder, insured, representative, vinculation } = props.clients;
    const policyHolderIsAlsoInsured = form.getFieldValue(["clientsData", "policyHolderIsAlsoInsured"]);

    if (!policyHolder || clientsViolationErrors.has(ClientFormType.POLICY_HOLDER)) {
      return true;
    }

    if (!policyHolderIsAlsoInsured && (!insured || clientsViolationErrors.has(ClientFormType.INSURED))) {
      return true;
    }

    if (
      policyHolder.type === ClientType.LEGAL &&
      (!representative || clientsViolationErrors.has(ClientFormType.REPRESENTATIVE))
    ) {
      return true;
    }

    // noinspection RedundantIfStatementJS
    if (
      props.calcData.generalInsuranceData.vinculation &&
      (!vinculation || clientsViolationErrors.has(ClientFormType.VINCULATION))
    ) {
      return true;
    }

    return false;
  };

  const processAndGetGenFormData = (): RealtyGen => {
    const clientsIndices = createClientsIndicesMap(props.clients);
    const values = form.getFieldsValue();

    const recommendedResultData = (values.financialMediationData.recommendedResult || []) as string[];
    const institutionResults = props.calcResults.find(
      resultsRow =>
        resultsRow.length > 0 &&
        resultsRow[0].insuranceInstitution.institutionEnum === InstitutionEnum[recommendedResultData[0]]
    );
    const recommendedResult =
      recommendedResultData.length > 1
        ? institutionResults?.find(result => result.coverage === recommendedResultData[1])
        : institutionResults?.[0];

    return {
      type: CalcType.REALTY,
      calcResult: props.selectedResult.data,
      calcResponse: { results: props.calcResults.flat() },
      coverage: props.selectedResult.coverage,
      insuranceInstitutionId: props.selectedResult.insuranceInstitution.id,
      draftId: props.draftId,
      ...merge(
        {},
        {
          ...props.calcData,
          householdInsuranceData: props.calcData.householdInsuranceData
            ? { ...props.calcData.householdInsuranceData, equipmentReinsurances: [] }
            : null
        },
        {
          ...values,
          clientsData: {
            ...props.calcData.clientsData,
            clients: createContractClientsArray(props.clients),
            policyHolderIndex: clientsIndices.get(ClientFormType.POLICY_HOLDER),
            insuredClientIndex: clientsIndices.get(ClientFormType.INSURED),
            representativeIndex: clientsIndices.get(ClientFormType.REPRESENTATIVE),
            representativeFunction: values.clientsData.representativeFunction,
            vinculationClientIndex: clientsIndices.get(ClientFormType.VINCULATION),
            policyHolderEmail: values.clientsData.policyHolderEmail,
            policyHolderPhone: values.clientsData.policyHolderPhone,
            policyHolderMarketingConsent: values.clientsData.policyHolderMarketingConsent
          },
          financialMediationData: { ...values.financialMediationData, recommendedResult }
        } as RealtyGen
      )
    };
  };

  const createClientsIndicesMap = (clients: RealtyFormClients): Map<ClientFormType, number> => {
    const { policyHolder, insured, representative, vinculation } = clients;
    const policyHolderIsAlsoInsured = form.getFieldValue(["clientsData", "policyHolderIsAlsoInsured"]);
    const indices = new Map<ClientFormType, number>();
    let index = 0;

    indices.set(ClientFormType.POLICY_HOLDER, index++);

    if (policyHolderIsAlsoInsured) {
      indices.set(ClientFormType.INSURED, index - 1);
    } else if (policyHolder.type === ClientType.NATURAL && insured) {
      indices.set(ClientFormType.INSURED, index++);
    }

    if (policyHolder.type === ClientType.LEGAL && representative) {
      indices.set(ClientFormType.REPRESENTATIVE, index++);
    }

    if (props.calcData.generalInsuranceData.vinculation && vinculation) {
      indices.set(ClientFormType.VINCULATION, index);
    }

    return indices;
  };

  const createContractClientsArray = (clients: RealtyFormClients): CreateUpdateContractClient[] => {
    const { policyHolder, insured, representative, vinculation } = clients;
    const policyHolderIsAlsoInsured = form.getFieldValue(["clientsData", "policyHolderIsAlsoInsured"]);
    const createUpdateClients: CreateUpdateContractClient[] = [];

    createUpdateClients.push(
      clientToCreateUpdateContractClient({
        ...policyHolder,
        identityCardNumber: form.getFieldValue(["clientsData", "policyHolderIdentityCardNumber"])
      } as NaturalClient)
    );

    if (!policyHolderIsAlsoInsured && policyHolder.type === ClientType.NATURAL && insured) {
      createUpdateClients.push(clientToCreateUpdateContractClient(insured));
    }

    if (policyHolder.type === ClientType.LEGAL && representative) {
      createUpdateClients.push(clientToCreateUpdateContractClient(representative));
    }

    if (props.calcData.generalInsuranceData.vinculation && vinculation) {
      createUpdateClients.push(clientToCreateUpdateContractClient(vinculation));
    }

    return createUpdateClients;
  };

  const resolvePageTitleFirstLine = (): string => {
    const { selectedResult } = props;
    return `${t("calc.realty.titles.contractGeneration")} ${selectedResult.insuranceInstitution.name}${
      selectedResult.coverage ? `, ${t("calc.realty.sections.coverage")} ${selectedResult.coverage}` : ""
    }`;
  };

  const resolvePageTitleSecondLine = (): string => {
    const { calcData } = props;

    const insurancesTranslations = [];
    if (calcData.realtyInsuranceData) {
      insurancesTranslations.push(t("calc.realty.sections.realtyInsurance"));
    }
    if (calcData.householdInsuranceData) {
      insurancesTranslations.push(t("calc.realty.sections.householdInsurance"));
    }

    return `${t("calc.realty.enums.buildingType." + calcData.generalBuildingData.type)} - ${insurancesTranslations.join(
      ", "
    )}`;
  };

  return (
    <>
      <Card
        className="card-box card-box--no-body"
        title={
          <>
            <h2>{resolvePageTitleFirstLine()}</h2>
            <h4 style={{ marginBottom: 0 }}>{resolvePageTitleSecondLine()}</h4>
          </>
        }
      />

      <Form form={form} layout="vertical" name="realtyGenForm">
        <RealtyGenClientsDataSection
          form={form}
          vinculation={props.calcData.generalInsuranceData.vinculation}
          selectedInstitutionEnum={props.selectedResult.insuranceInstitution.institutionEnum}
          clients={props.clients}
          clientsViolationErrors={clientsViolationErrors}
          onClientChange={handleClientChange}
          onClientViolationErrorsChange={handleClientViolationErrorsChange}
        />

        <RealtyGenOtherDataSection
          form={form}
          calcData={props.calcData}
          policyHolder={props.clients.policyHolder}
          calcResults={props.calcResults}
          selectedResult={props.selectedResult}
        />
      </Form>

      <RealtyCalcResults
        open={resultsOpen}
        calcResults={props.calcResults}
        selectedResult={props.selectedResult}
        onClose={() => setResultsOpen(false)}
        onSaveDraftClick={handleResultsSaveDraftClick}
        onGenerateOfferClick={handleResultsGenerateOfferClick}
        onGenerateContractClick={handleResultsGenerateContractClick}
      />

      <CalcGenNavigation
        requiredPermission={Permission.REALTY_GENERATE_CONTRACT}
        onFormSubmit={handleGenerateFormSubmit}
        onShowResultsClick={() => setResultsOpen(true)}
        onReturnToCalculationClick={handleReturnToCalculationClick}
      />

      <CalcSummaryModal
        open={summaryOpen}
        data={{
          calcData: props.calcData,
          formData: form.getFieldsValue(),
          selectedInsurance: props.selectedResult,
          clients: props.clients
        }}
        onOkClick={handleGenerateFormApprovalsSubmit}
        onCancelClick={() => setSummaryOpen(false)}
      />

      <CalcGenResultModal result={props.genResult} showDraftStep={!!props.draftId} onCancel={props.onGenResultDelete} />
    </>
  );
};

export default RealtyGenWrapper;
