import { Card } from "antd";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import BackNavigationArrow from "../../../common/components/views/BackNavigationArrow";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../common/types";
import InstitutionForm from "../components/forms/InstitutionForm";
import { createInstitutionActions } from "../ducks";

interface ActionsMap {
  createInstitution: typeof createInstitutionActions.request;
}

const InstitutionCreateContainer = (props: ActionProps<ActionsMap>) => (
  <ContentWrapper>
    <Card
      className="card-box"
      title={
        <BackNavigationArrow>
          <h2 className="margin-bottom-large">{t("institution.titles.new")}</h2>
        </BackNavigationArrow>
      }
    >
      <InstitutionForm onCreate={props.actions.createInstitution} />
    </Card>
  </ContentWrapper>
);

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      createInstitution: createInstitutionActions.request
    },
    dispatch
  )
});

export default connect<{}, ActionProps<ActionsMap>, {}, RootState>(
  null,
  mapDispatchToProps
)(InstitutionCreateContainer);
