import { Breadcrumb } from "antd";
import { MenuItemType } from "antd/lib/menu/hooks/useItems";
import { Pathname } from "history";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ConditionalWrapper from "../../../common/modules/wrappers/ConditionalWrapper";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { Permission } from "../../../common/security/authorization/enums";
import { RootState, UUID } from "../../../common/types";
import {
  PagesMap,
  replaceUrlPlaceholdersWithUuids,
  replaceUrlUuidsWithPlaceholders
} from "../../../common/utils/navigationUtils";
import { hasAnyPermission } from "../../../common/utils/utils";
import { selectIsUserAuthenticated, selectPermissions } from "../../../modules/auth/ducks";
import { selectNotFoundPageRendered, selectRouterLocationPathname } from "../../../modules/ducks";

const renderBreadcrumb = (
  path: string,
  crumbs: React.ReactNode[],
  uuids: UUID[],
  accountPermissions: Permission[]
): void => {
  const page = PagesMap.get(path);
  if (!page || page.hideBreadcrumbs) {
    return;
  }

  const outputPath = replaceUrlPlaceholdersWithUuids(path, uuids);

  if (page.parent) {
    renderBreadcrumb(page.parent, crumbs, uuids, accountPermissions);
  }

  const siblings = page.siblings
    ? page.siblings.filter(
        sibling => !!!sibling.permissions || hasAnyPermission(accountPermissions, sibling.permissions)
      )
    : [];

  if (siblings.length > 0) {
    const siblingsItems = siblings.map<MenuItemType>(sibling => {
      const siblingPage = PagesMap.get(sibling.path);
      const siblingPath = replaceUrlPlaceholdersWithUuids(sibling.path, uuids);
      return {
        key: siblingPath,
        label: <Link to={siblingPath}>{siblingPage.render || siblingPage.title}</Link>
      };
    });
    siblingsItems.unshift({ key: outputPath, label: <Link to={outputPath}>{page.render || page.title}</Link> });

    crumbs.push(
      <Breadcrumb.Item menu={{ items: siblingsItems }} key={outputPath}>
        {page.render || page.title}
      </Breadcrumb.Item>
    );
  } else {
    crumbs.push(
      <Breadcrumb.Item key={outputPath}>
        <Link to={outputPath}>{page.render || page.title}</Link>
      </Breadcrumb.Item>
    );
  }
};

const BreadcrumbsBox = () => {
  const accountPermissions = useSelector<RootState, Permission[]>(selectPermissions);
  const locationPathname = useSelector<RootState, Pathname>(selectRouterLocationPathname);
  const notFoundPageRendered = useSelector<RootState, boolean>(selectNotFoundPageRendered);
  const isUserAuthenticated = useSelector<RootState, boolean>(selectIsUserAuthenticated);

  if (!notFoundPageRendered && isUserAuthenticated) {
    const currentPage = replaceUrlUuidsWithPlaceholders(locationPathname);
    const crumbs = [];
    renderBreadcrumb(currentPage.path, crumbs, currentPage.uuids, accountPermissions);

    return (
      <ConditionalWrapper
        condition={!!PagesMap.get(currentPage.path)?.wrappedStyle}
        wrapper={children => (
          <ContentWrapper wide={PagesMap.get(currentPage.path)?.wrappedStyle === "wide"}>{children}</ContentWrapper>
        )}
        children={<Breadcrumb className="breadcrumbs-box">{crumbs}</Breadcrumb>}
      />
    );
  }

  return null;
};

export default BreadcrumbsBox;
