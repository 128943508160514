import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../../../app/i18n";
import ContentWrapper from "../../../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../../../common/types";
import messageUtils from "../../../../../common/utils/messageUtils";
import { ClientFormType } from "../../../../client/enums";
import { Client } from "../../../../client/types";
import { VehicleInsurance } from "../../../../contract/types";
import { createCalcDraftActions, updateCalcDraftActions } from "../../../drafts/ducks";
import { CreateUpdateCalcDraft } from "../../../drafts/types";
import CalcLoadingModal from "../../components/CalcLoadingModal";
import { downloadCardReaderActions } from "../../ducks";
import { OfferType } from "../../enums";
import { OperationStage } from "../../realty/enums";
import { CalcDataSource, CalcResult, GenResponse } from "../../types";
import VehicleCalcWrapper from "../components/calc/VehicleCalcWrapper";
import VehicleGenWrapper from "../components/gen/VehicleGenWrapper";
import {
  calculateVehicleActions,
  deleteStateVehicleCalcResultsAction,
  deleteStateVehicleDraftAction,
  deleteStateVehicleGenResultAction,
  deleteStateVehicleInitialCalcGenDataAction,
  generateVehicleActions,
  generateVehicleOfferActions,
  selectVehicleCalcResults,
  selectVehicleDraft,
  selectVehicleGenResult,
  selectVehicleInitialCalcData,
  selectVehicleInitialGenData
} from "../ducks";
import { VehicleOwnerRelation, VehiclePolicyHolderRelation } from "../enums";
import {
  VehicleCalc,
  VehicleCalcDraft,
  VehicleCalcResultData,
  VehicleCalcResults,
  VehicleFormClients,
  VehicleGen,
  VehicleGenForm,
  VehicleOfferType
} from "../types";
import {
  createVehicleCalcObjectFromCalcData,
  createVehicleCalcObjectFromGenData,
  createVehicleFormClientsObjectFromCalcData,
  createVehicleFormClientsObjectFromGenData,
  createVehicleGenFormDataObject
} from "../utils";

interface StateProps {
  initialCalcData: VehicleCalc;
  initialGenData: VehicleGen;
  draft: VehicleCalcDraft;
  calcResults: VehicleCalcResults;
  genResult: GenResponse;
}

interface ActionsMap {
  calculateVehicle: typeof calculateVehicleActions.request;
  generateVehicle: typeof generateVehicleActions.request;
  generateVehicleOffer: typeof generateVehicleOfferActions.request;
  downloadCardReader: typeof downloadCardReaderActions.request;
  createCalcDraft: typeof createCalcDraftActions.request;
  updateCalcDraft: typeof updateCalcDraftActions.request;
  deleteStateVehicleCalcResults: typeof deleteStateVehicleCalcResultsAction;
  deleteStateVehicleGenResult: typeof deleteStateVehicleGenResultAction;
  deleteStateVehicleDraft: typeof deleteStateVehicleDraftAction;
  deleteStateVehicleInitialCalcGenData: typeof deleteStateVehicleInitialCalcGenDataAction;
}

const VehicleCalcContainer = (props: StateProps & ActionProps<ActionsMap>) => {
  const [resetKey, setResetKey] = useState<number>(0);
  const [genStepActive, setGenStepActive] = useState<boolean>(false);
  const [selectedResult, setSelectedResult] = useState<CalcResult<VehicleCalcResultData>>();

  const [selectedVehicleVin, setSelectedVehicleVin] = useState<string>(
    props.initialGenData
      ? props.initialGenData.vehicleData.vin
      : props.draft?.stage === OperationStage.GENERATE
        ? props.draft?.draftData.vehicleData.vin
        : null
  );

  const [clients, setClients] = useState<VehicleFormClients>(
    props.initialCalcData
      ? createVehicleFormClientsObjectFromCalcData(props.initialCalcData)
      : props.initialGenData
        ? createVehicleFormClientsObjectFromGenData(props.initialGenData)
        : props.draft
          ? createVehicleFormClientsObjectFromGenData(props.draft.draftData)
          : {
              holder: null,
              policyHolder: null,
              owner: null,
              representative: null
            }
  );

  const [calcDataSource, setCalcDataSource] = useState<CalcDataSource>(
    props.initialCalcData
      ? "calcData"
      : props.initialGenData
        ? "genData"
        : props.draft?.stage === OperationStage.CALCULATE
          ? "calcDraft"
          : props.draft?.stage === OperationStage.GENERATE
            ? "genDraft"
            : "init"
  );

  const [calcData, setCalcData] = useState<VehicleCalc>(
    props.initialCalcData
      ? createVehicleCalcObjectFromCalcData(props.initialCalcData)
      : props.initialGenData
        ? createVehicleCalcObjectFromGenData(props.initialGenData)
        : props.draft
          ? createVehicleCalcObjectFromGenData(props.draft.draftData)
          : null
  );

  const [genData, setGenData] = useState<VehicleGenForm>(
    props.initialGenData
      ? createVehicleGenFormDataObject(props.initialGenData, clients)
      : props.draft?.stage === OperationStage.GENERATE
        ? createVehicleGenFormDataObject(props.draft.draftData, clients)
        : null
  );

  useEffect(() => {
    if (props.draft) {
      messageUtils.infoNotification(t("calc.draft.helpers.draftLoaded"), t("calc.draft.helpers.draftLoadedInfo"), 10);
    }
    if (props.initialCalcData || props.initialGenData) {
      props.actions.deleteStateVehicleInitialCalcGenData();
      messageUtils.infoNotification(
        t("calc.records.helpers.calcDataInitTitle"),
        t("calc.records.helpers.calcDataInit"),
        10
      );
    }
    return () => {
      props.actions.deleteStateVehicleCalcResults();
      props.actions.deleteStateVehicleGenResult();
      props.actions.deleteStateVehicleDraft();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClientChange = (client: Client, type: ClientFormType): VehicleFormClients => {
    let updatedClients: VehicleFormClients;
    switch (type) {
      case ClientFormType.HOLDER:
        updatedClients = { ...clients, holder: client };
        break;
      case ClientFormType.POLICY_HOLDER:
        updatedClients = { ...clients, policyHolder: client };
        break;
      case ClientFormType.OWNER:
        updatedClients = { ...clients, owner: client };
        break;
      case ClientFormType.REPRESENTATIVE:
        updatedClients = { ...clients, representative: client };
        break;
      default:
        updatedClients = { ...clients };
        break;
    }
    setClients(updatedClients);
    return updatedClients;
  };

  const handleResetCalculatorClick = (): void => {
    Modal.confirm({
      title: t("calc.helpers.resetCalculatorSubmit"),
      okText: t("common.yes"),
      cancelText: t("common.back"),
      onOk: () => {
        setResetKey(resetKey + 1);
        setClients({ holder: null, policyHolder: null, owner: null, representative: null });
        setCalcDataSource("init");
        setCalcData(null);
        setGenData(null);
        setSelectedResult(null);
        props.actions.deleteStateVehicleCalcResults();
        props.actions.deleteStateVehicleDraft();
      }
    });
  };

  const handleCalcDataSourceReset = (): void => {
    setCalcDataSource("init");
  };

  const handleCalculationFormSubmit = (calcData: VehicleCalc): void => {
    setCalcData(calcData);
    props.actions.deleteStateVehicleCalcResults();
    props.actions.calculateVehicle(calcData);
  };

  const handleGenerateContractClick = (
    calcData: VehicleCalc,
    selectedResult: CalcResult<VehicleCalcResultData>
  ): void => {
    setGenStepActive(true);
    setCalcData(calcData);
    setSelectedResult(selectedResult);
  };

  const handleGenerateOfferClick = (type: VehicleOfferType, calcRequest?: VehicleCalc): void => {
    const { mtpl, crash, mtplCrash, gap, pas } = props.calcResults;
    props.actions.generateVehicleOffer({
      type,
      calcRequest: calcRequest ? calcRequest : calcData,
      calcResponse: {
        results:
          type === OfferType.MTPL
            ? mtpl.flat()
            : type === OfferType.CRASH
              ? [...crash.flat(), ...gap.flat(), ...pas.flat()]
              : [...mtplCrash.flat(), ...gap.flat(), ...pas.flat()]
      }
    });
  };

  const handleSaveDraftClick = (data: VehicleCalc | VehicleGen, overwriteExisting: boolean): void => {
    const { mtpl, crash, mtplCrash, gap, pas } = props.calcResults;
    const draft: CreateUpdateCalcDraft = {
      draftData: data,
      calcResponse: { results: [...mtpl.flat(), ...crash.flat(), ...mtplCrash.flat(), ...gap.flat(), ...pas.flat()] },
      clientName: clients.holder.aggregatedName,
      calcType: data.type,
      stage: genStepActive ? OperationStage.GENERATE : OperationStage.CALCULATE
    };

    if (props.draft && overwriteExisting) {
      props.actions.updateCalcDraft({
        id: props.draft.id,
        object: { optimisticLockVersion: props.draft.optimisticLockVersion, ...draft }
      });
    } else {
      props.actions.createCalcDraft(draft);
    }
  };

  const handleReturnToCalculationClick = (genFormData: VehicleGenForm, contractCreated?: boolean): void => {
    if (contractCreated && props.genResult?.contract) {
      const { contract } = props.genResult;
      const insurance = contract.insurances[0] as VehicleInsurance;
      const { policyHolderRelation, ownerRelation } = calcData.clientsData;

      setGenStepActive(false);
      setSelectedResult(null);
      setCalcDataSource("init");
      setGenData({
        ...genFormData,
        clientsData: {
          ...genFormData.clientsData,
          representativeIdentifier: null,
          representativeFunction: null
        }
      });
      setClients({
        holder: contract.clients[insurance.vehicleHolderIndex],
        policyHolder:
          policyHolderRelation === VehiclePolicyHolderRelation.DIFFERENT_FROM_VEHICLE_HOLDER
            ? contract.clients[contract.policyHolderIndex]
            : null,
        owner:
          ownerRelation === VehicleOwnerRelation.DIFFERENT_FROM_BOTH
            ? contract.clients[insurance.vehicleOwnerIndex]
            : null,
        representative: null
      });
    } else {
      setGenStepActive(false);
      setSelectedResult(null);
      setCalcDataSource("calcData");
      setGenData(genFormData);
    }

    props.actions.deleteStateVehicleGenResult();
  };

  return (
    <ContentWrapper>
      {genStepActive ? (
        <VehicleGenWrapper
          initialData={genData}
          genResult={props.genResult}
          calcData={calcData}
          clients={clients}
          calcResults={props.calcResults}
          selectedResult={selectedResult}
          draftId={props.draft?.id}
          selectedVehicleVin={selectedVehicleVin}
          onGenerateFormSubmit={props.actions.generateVehicle}
          onGenResultDelete={props.actions.deleteStateVehicleGenResult}
          onGenerateOfferClick={handleGenerateOfferClick}
          onSaveDraftClick={handleSaveDraftClick}
          onSelectedResultChange={setSelectedResult}
          onReturnToCalculationClick={handleReturnToCalculationClick}
          onClientChange={handleClientChange}
          onSelectedVehicleVinSet={setSelectedVehicleVin}
        />
      ) : (
        <VehicleCalcWrapper
          key={resetKey}
          calcData={calcData}
          calcDataSource={calcDataSource}
          calcResults={props.calcResults}
          holder={clients.holder}
          onCalcResultsDelete={props.actions.deleteStateVehicleCalcResults}
          onDownloadCardReader={props.actions.downloadCardReader}
          onCalculationFormSubmit={handleCalculationFormSubmit}
          onGenerateContractClick={handleGenerateContractClick}
          onGenerateOfferClick={handleGenerateOfferClick}
          onSaveDraftClick={handleSaveDraftClick}
          onClientChange={handleClientChange}
          onResetCalculatorClick={handleResetCalculatorClick}
          onCalcDataSourceReset={handleCalcDataSourceReset}
        />
      )}

      <CalcLoadingModal />
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  initialCalcData: selectVehicleInitialCalcData(state),
  initialGenData: selectVehicleInitialGenData(state),
  draft: selectVehicleDraft(state),
  calcResults: selectVehicleCalcResults(state),
  genResult: selectVehicleGenResult(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      calculateVehicle: calculateVehicleActions.request,
      generateVehicle: generateVehicleActions.request,
      generateVehicleOffer: generateVehicleOfferActions.request,
      downloadCardReader: downloadCardReaderActions.request,
      createCalcDraft: createCalcDraftActions.request,
      updateCalcDraft: updateCalcDraftActions.request,
      deleteStateVehicleCalcResults: deleteStateVehicleCalcResultsAction,
      deleteStateVehicleGenResult: deleteStateVehicleGenResultAction,
      deleteStateVehicleDraft: deleteStateVehicleDraftAction,
      deleteStateVehicleInitialCalcGenData: deleteStateVehicleInitialCalcGenDataAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(VehicleCalcContainer);
