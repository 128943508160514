import React from "react";
import { Route, Routes } from "react-router";
import { WILDCARD_ROUTE } from "../../common/constants";
import NotFound from "../../common/pages/NotFound/NotFound";
import { Permission } from "../../common/security/authorization/enums";
import PageWithPermission from "../../common/security/authorization/PageWithPermission";
import InstitutionCreateContainer from "./containers/InstitutionCreateContainer";
import InstitutionDetailContainer from "./containers/InstitutionDetailContainer";
import InstitutionListContainer from "./containers/InstitutionListContainer";
import { INSTITUTION_ROUTE_PATHS } from "./paths";

const routes = () => (
  <Routes>
    <Route
      index
      element={
        <PageWithPermission component={<InstitutionListContainer />} permissions={[Permission.ADMIN_ENUMERATIONS]} />
      }
    />

    <Route
      path={INSTITUTION_ROUTE_PATHS.create.path}
      element={
        <PageWithPermission
          component={<InstitutionCreateContainer />}
          permissions={[Permission.ADMIN_GLOBAL_ENUMERATIONS]}
        />
      }
    />

    <Route
      path={INSTITUTION_ROUTE_PATHS.detail.path}
      element={
        <PageWithPermission
          component={<InstitutionDetailContainer />}
          permissions={[Permission.ADMIN_GLOBAL_ENUMERATIONS]}
        />
      }
    />

    <Route path={WILDCARD_ROUTE} element={<NotFound />} />
  </Routes>
);

export default routes;
