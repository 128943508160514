import cronParser from "cron-parser";
import { filesize } from "filesize";
import ibanUtils from "iban";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import moment, { Moment } from "moment";
import t, { DEFAULT_LOCALE } from "../../app/i18n";
import { AgentBase } from "../../modules/agent/types";
import { PlaceOfInsurance } from "../../modules/calculator/calcs/realty/types";
import { ClientBase } from "../../modules/client/types";
import { CommissionsSettingsLevelBase } from "../../modules/commissions/level/types";
import { CoverageLimitBase } from "../../modules/coveragelimit/types";
import { LifeInsuranceTariffBase } from "../../modules/lifeinsurancetariff/types";
import { ProductBase } from "../../modules/product/types";
import { Address } from "../modules/types";
import { ALL_WHITE_SPACES_PATTERN, insertToString, isDefinedValue } from "./utils";

export const DATE_REGEX = new RegExp("^\\d{4}-\\d{2}-\\d{2}$");
export const DATE_TIME_REGEX = new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?$");

export const formatIban = (iban: string): string | null => (iban ? ibanUtils.printFormat(iban, " ") : null);

export const formatPhoneNumber = (phone: string): string =>
  phone && isValidPhoneNumber(phone, "SK") ? parsePhoneNumber(phone, "SK").formatInternational() : phone;

export const formatLocalePhoneNumber = (phone: string): string =>
  phone && isValidPhoneNumber(phone, "SK") ? parsePhoneNumber(phone, "SK").formatNational() : phone;

export const formatLocaleNumber = (input: number): string | null =>
  input ? Intl.NumberFormat(DEFAULT_LOCALE).format(input) : null;

export const formatLocaleNumberWithNullAsZero = (input: number): string =>
  Intl.NumberFormat(DEFAULT_LOCALE).format(input || 0);

export const formatLocaleCurrency = (input: number): string | null => {
  return isDefinedValue(input)
    ? Intl.NumberFormat(DEFAULT_LOCALE, {
        style: "currency",
        currency: "EUR"
      }).format(input)
    : null;
};

export const formatLocaleCurrencyWithNullAsZero = (input: number): string => {
  return Intl.NumberFormat(DEFAULT_LOCALE, { style: "currency", currency: "EUR" }).format(input || 0);
};

export const formatIntegerLocaleCurrency = (input: number): string => {
  return isDefinedValue(input)
    ? Intl.NumberFormat(DEFAULT_LOCALE, {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(input)
    : null;
};

export const formatIntegerLocaleCurrencyWithNullAsZero = (input: number): string => {
  return Intl.NumberFormat(DEFAULT_LOCALE, {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(input || 0);
};

export const formatLocaleCurrencyWithMillionLabel = (input: number): string => {
  return (
    Intl.NumberFormat(DEFAULT_LOCALE, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(input) + " mil €"
  );
};

export const formatLocalePercentageNumber = (input: number, fractionDigits: number = 2): string | null => {
  return isDefinedValue(input)
    ? Intl.NumberFormat(DEFAULT_LOCALE, {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
      }).format(input) + " %"
    : null;
};

export const formatLocalePercentageNumberWithNullAsZero = (input: number, fractionDigits: number = 2): string => {
  return (
    Intl.NumberFormat(DEFAULT_LOCALE, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits
    }).format(input || 0) + " %"
  );
};

export const formatLocaleNettoPoints = (input: number): string =>
  isDefinedValue(input)
    ? Intl.NumberFormat(DEFAULT_LOCALE, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(input) + " PB"
    : null;

export const formatLocaleNettoPointsWithNullAsZero = (input: number): string =>
  Intl.NumberFormat(DEFAULT_LOCALE, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(input || 0) + " PB";

export const formatLocaleBruttoPoints = (input: number): string =>
  isDefinedValue(input)
    ? Intl.NumberFormat(DEFAULT_LOCALE, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(input) + " BB"
    : null;

export const formatLocaleBruttoPointsWithNullAsZero = (input: number): string =>
  Intl.NumberFormat(DEFAULT_LOCALE, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(input || 0) + " BB";

export const formatLocaleDate = (input: string | Moment): string | null => {
  return input ? moment(input).format("L") : null;
};

export const formatLocaleDateTime = (input: string | Moment): string | null => {
  return input ? moment(input).format("L LTS") : null;
};

export const formatFileSize = (size: number): string => filesize(size, { locale: DEFAULT_LOCALE }) as string;

export const formatLicensePlate = (input: string): string => {
  if (input) {
    input = input.replace(ALL_WHITE_SPACES_PATTERN, "");
    input = input.length > 2 ? insertToString(input, " ", 2) : input;
    input = input.length > 6 ? insertToString(input, " ", 6) : input;
    return input.toUpperCase();
  }
  return input;
};

export const formatAddress = (address: Address, showCountry: boolean = true): string => {
  return address
    ? (address.street && address.street.length > 0 ? address.street : address.city) +
        (address.descriptiveNumber && address.descriptiveNumber.length > 0
          ? ` ${address.descriptiveNumber}` +
            (address.orientationNumber && address.orientationNumber.length > 0 ? `/${address.orientationNumber}` : "")
          : ` ${address.orientationNumber}`) +
        `, ${address.zipCode} ${address.city}` +
        (showCountry ? `, ${address.country}` : "")
    : null;
};

export const formatPlaceOfInsurance = (place: PlaceOfInsurance, showCountry?: boolean): string => {
  return place
    ? (place.street && place.street.length > 0 ? place.street : place.city) +
        (place.descriptiveNumber && place.descriptiveNumber.length > 0
          ? ` ${place.descriptiveNumber}` +
            (place.orientationNumber && place.orientationNumber.length > 0 ? `/${place.orientationNumber}` : "")
          : place.orientationNumber && place.orientationNumber.length > 0
            ? ` ${place.orientationNumber}`
            : "") +
        (place.parcelNumber1
          ? place.parcelNumber2
            ? ` (${t("address.attrs.parcelNumber")} ${place.parcelNumber1}, ${place.parcelNumber2})`
            : ` (${t("address.attrs.parcelNumber")} ${place.parcelNumber1})`
          : "") +
        `, ${place.zipCode} ${place.city}` +
        (place.cityDistrict ? ` - ${place.cityDistrict}` : "") +
        (showCountry ? `, ${place.country}` : "")
    : null;
};

export const formatAggregatedName = (entity: {
  academicDegree: string;
  firstName: string;
  lastName: string;
  academicDegreeAfter: string;
}): string => {
  let aggregatedName = "";
  if (entity.academicDegree) {
    aggregatedName += `${entity.academicDegree} `;
  }

  aggregatedName += `${entity.firstName || ""} ${entity.lastName || ""}`;

  if (entity.academicDegreeAfter) {
    aggregatedName += `, ${entity.academicDegreeAfter}`;
  }

  return aggregatedName;
};

export const formatAgentStructureIdNumber = (agent: AgentBase): string => {
  return agent?.structureIdNumber ? agent.structureIdNumber.toString().padStart(3, "0") : null;
};

export const formatAgentShortIdNumber = (agent: AgentBase): string => {
  return agent ? agent.idNumber.toString().padStart(5, "0") : null;
};

export const formatAgentIdNumber = (agent: AgentBase): string => {
  return agent
    ? agent.structureIdNumber
      ? agent.structureIdNumber.toString().padStart(3, "0") + agent.idNumber.toString().padStart(5, "0")
      : agent.idNumber.toString().padStart(5, "0")
    : null;
};

export const formatAgentName = (agent: AgentBase, showDeactivatedLabel: boolean = true): string => {
  return agent
    ? `${agent.displayName} (${(agent.tipper ? "T | " : "") + formatAgentIdNumber(agent)})` +
        (agent.deactivated && showDeactivatedLabel ? ` | ${t("agent.helpers.deactivatedAgentShort")}` : "")
    : null;
};

export const formatAgentAggregatedName = (agent: AgentBase): string => {
  return agent ? `${agent.aggregatedName} (${formatAgentIdNumber(agent) + (agent.tipper ? " | T" : "")})` : null;
};

export const formatClientName = (client: ClientBase): string => {
  return client ? `${client.aggregatedName} (${client.identifier})` : null;
};

export const formatProductName = (product: ProductBase): string => {
  return product
    ? product.deactivated
      ? `${product.name} (${t("product.helpers.deactivated")})`
      : product.name
    : null;
};

export const formatCoverageLimit = (coverageLimit: CoverageLimitBase): string => {
  return coverageLimit
    ? formatLocaleCurrencyWithMillionLabel(coverageLimit.health) +
        " / " +
        formatLocaleCurrencyWithMillionLabel(coverageLimit.property)
    : null;
};

export const formatCoverageLimitValues = (health: number, property: number): string => {
  return isDefinedValue(health) && isDefinedValue(property)
    ? formatLocaleCurrencyWithMillionLabel(health) + " / " + formatLocaleCurrencyWithMillionLabel(property)
    : null;
};

export const formatLifeInsuranceTariff = (tariff: LifeInsuranceTariffBase): string =>
  tariff ? `${tariff.code} - ${tariff.name}` : null;

export const formatCommissionsLevelName = (level: CommissionsSettingsLevelBase): string =>
  level ? level.code + " - " + level.name : null;

export const formatCron = (value: string, nextDatesCount: number = 1): string => {
  if (value) {
    try {
      const expression = cronParser.parseExpression(value, { tz: "Europe/Bratislava" });
      const results = [];
      for (let i = 0; i < nextDatesCount; i++) {
        if (expression.hasNext()) {
          results.push(formatLocaleDateTime(expression.next().toISOString()));
        }
      }
      return results.join(" | ");
    } catch (e) {
      return null;
    }
  }
  return null;
};
