import { Col, Form, Modal, Row, Select } from "antd";
import React, { useEffect } from "react";
import t from "../../../../../app/i18n";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import ItemCreatedUpdatedInfoView from "../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { ModalSizes, rowGutter } from "../../../../../common/constants";
import {
  resolveFormValidationError,
  selectTagsStandardProps,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import AgentSelect from "../../../../enumerations/components/form/AgentSelect";
import InstitutionSelect from "../../../../enumerations/components/form/InstitutionSelect";
import { InstitutionType } from "../../../../institution/enums";
import { CalcType, calcTypeTMap } from "../../../enums";
import CalcTypeTag from "../../../records/components/CalcTypeTag";
import { requests } from "../../api";
import { createCalcBlacklistActions, updateCalcBlacklistActions } from "../../ducks";
import { CalcBlacklist, CreateUpdateCalcBlacklist } from "../../types";

interface Props {
  calcBlacklist?: CalcBlacklist;
  open: boolean;
  onCreate?: typeof createCalcBlacklistActions.request;
  onUpdate?: typeof updateCalcBlacklistActions.request;
  onFormCancel: () => void;
}

const CalcBlacklistForm = ({ calcBlacklist, open, onCreate, onUpdate, onFormCancel }: Props) => {
  const [form] = Form.useForm<CreateUpdateCalcBlacklist>();

  useFormErrorHandler(form, "calc.blacklist.attrs", [requests.CREATE_CALC_BLACKLIST, requests.UPDATE_CALC_BLACKLIST]);

  useEffect(() => {
    if (open && calcBlacklist) {
      form.setFieldsValue({
        optimisticLockVersion: calcBlacklist.optimisticLockVersion,
        calcType: calcBlacklist.calcType,
        agentId: calcBlacklist.agent.id,
        institutionId: calcBlacklist.institution.id
      });
    }
  }, [calcBlacklist, open, form]);

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_CALC_BLACKLIST, requests.UPDATE_CALC_BLACKLIST],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (calcBlacklist) {
          onUpdate?.({
            id: calcBlacklist.id,
            object: values
          });
        } else {
          onCreate?.(values);
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={
        calcBlacklist ? t("calc.blacklist.titles.updateCalcBlacklist") : t("calc.blacklist.titles.createCalcBlacklist")
      }
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <ItemCreatedUpdatedInfoView className="margin-bottom-small" item={calcBlacklist} />

      <Form form={form} layout="vertical" name="calcBlacklistForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={8}>
            <Form.Item name="calcType" label={t("calc.enums.calcType._label")} rules={[validations.notNull]}>
              <Select
                {...selectTagsStandardProps(calcTypeTMap)}
                options={Object.keys(CalcType).map(type => ({
                  value: type,
                  label: <CalcTypeTag type={type as CalcType} />
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <InstitutionSelect
              formItemProps={{
                name: "institutionId",
                label: t("calc.blacklist.attrs.institutionId"),
                rules: [validations.notNull]
              }}
              optionsProps={{
                includeDeactivated: true,
                filterType: InstitutionType.INSURANCE_COMPANY
              }}
            />
          </Col>

          <Col span={8}>
            <AgentSelect
              formItemProps={{
                name: "agentId",
                label: t("calc.blacklist.attrs.agentId"),
                rules: [validations.notNull]
              }}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CalcBlacklistForm;
