import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import DisplayWrapper from "../../../../common/modules/wrappers/DisplayWrapper";
import { ActionProps, EntityIdObject, RootState } from "../../../../common/types";
import { requests } from "../api";
import CommissionsBatchDetailView from "../components/views/detail/CommissionsBatchDetailView";
import {
  calculateCommissionsBatchPaymentToBePaidActions,
  changeCommissionsBatchStepActions,
  createCommissionActions,
  createCommissionsUnitActions,
  deleteCommissionActions,
  deleteCommissionsBatchActions,
  deleteCommissionsBatchAttachmentActions,
  deleteCommissionsUnitActions,
  deleteStateCommissionsBatchDetailAction,
  deleteStateCommissionsBatchPaymentToBePaidAction,
  deleteStateCommissionsPageAction,
  downloadCommissionsBatchAttachmentsAsZipActions,
  filterCommissionsActions,
  generateCommissionsBatchPaymentOrderActions,
  getCommissionsBatchActions,
  postponeCommissionActions,
  recalculateCommissionsForContractActions,
  replaceCommissionsBatchAttachmentActions,
  selectBatchDetail,
  selectBatchPaymentToBePaid,
  selectCommissionsPage,
  tryToIncludeCommissionActions,
  updateCalculatedCommissionsActions,
  updateCommissionActions,
  updateCommissionsBatchActions,
  updateCommissionsUnitActions,
  uploadCommissionsBatchAttachmentActions
} from "../ducks";
import { CommissionsBatch, CommissionsFilterPageResult } from "../types";

interface StateProps {
  batch: CommissionsBatch;
  paymentToBePaid: number;
  commissionsPage: CommissionsFilterPageResult;
}

interface ActionsMap {
  getCommissionsBatch: typeof getCommissionsBatchActions.request;
  calculateCommissionsBatchPaymentToBePaid: typeof calculateCommissionsBatchPaymentToBePaidActions.request;
  deleteStateCommissionsBatchPaymentToBePaid: typeof deleteStateCommissionsBatchPaymentToBePaidAction;
  generateCommissionsBatchPaymentOrder: typeof generateCommissionsBatchPaymentOrderActions.request;
  downloadCommissionsBatchAttachmentsAsZip: typeof downloadCommissionsBatchAttachmentsAsZipActions.request;
  updateCommissionsBatch: typeof updateCommissionsBatchActions.request;
  deleteCommissionsBatch: typeof deleteCommissionsBatchActions.request;
  changeCommissionsBatchStep: typeof changeCommissionsBatchStepActions.request;
  createCommissionsUnit: typeof createCommissionsUnitActions.request;
  updateCommissionsUnit: typeof updateCommissionsUnitActions.request;
  deleteCommissionsUnit: typeof deleteCommissionsUnitActions.request;
  uploadCommissionsBatchAttachment: typeof uploadCommissionsBatchAttachmentActions.request;
  replaceCommissionsBatchAttachment: typeof replaceCommissionsBatchAttachmentActions.request;
  deleteCommissionsBatchAttachment: typeof deleteCommissionsBatchAttachmentActions.request;
  filterCommissions: typeof filterCommissionsActions.request;
  createCommission: typeof createCommissionActions.request;
  updateCommission: typeof updateCommissionActions.request;
  postponeCommission: typeof postponeCommissionActions.request;
  tryToIncludeCommission: typeof tryToIncludeCommissionActions.request;
  recalculateCommissionsForContract: typeof recalculateCommissionsForContractActions.request;
  updateCalculatedCommissions: typeof updateCalculatedCommissionsActions.request;
  deleteCommission: typeof deleteCommissionActions.request;
  deleteStateCommissionsBatchDetail: typeof deleteStateCommissionsBatchDetailAction;
  deleteStateCommissionsPage: typeof deleteStateCommissionsPageAction;
}

const CommissionsBatchDetailContainer = (props: StateProps & ActionProps<ActionsMap>) => {
  const { id } = useParams<EntityIdObject>();

  useEffect(() => {
    props.actions.getCommissionsBatch({ id });
    return () => {
      props.actions.deleteStateCommissionsBatchDetail();
      props.actions.deleteStateCommissionsPage();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DisplayWrapper itemLoaded={!!props.batch} notFoundCheckRequest={requests.GET_COMMISSIONS_BATCH}>
      <CommissionsBatchDetailView
        batch={props.batch}
        paymentToBePaid={props.paymentToBePaid}
        commissionsPage={props.commissionsPage}
        batchActions={{
          onCalculatePaymentToBePaid: props.actions.calculateCommissionsBatchPaymentToBePaid,
          onDeleteStatePaymentToBePaid: props.actions.deleteStateCommissionsBatchPaymentToBePaid,
          onGeneratePaymentOrder: props.actions.generateCommissionsBatchPaymentOrder,
          onDownloadAttachmentsAsZip: props.actions.downloadCommissionsBatchAttachmentsAsZip,
          onUpdate: props.actions.updateCommissionsBatch,
          onDelete: props.actions.deleteCommissionsBatch,
          onStepChange: props.actions.changeCommissionsBatchStep
        }}
        unitActions={{
          onCreate: props.actions.createCommissionsUnit,
          onUpdate: props.actions.updateCommissionsUnit,
          onDelete: props.actions.deleteCommissionsUnit
        }}
        attachmentActions={{
          onUpload: props.actions.uploadCommissionsBatchAttachment,
          onReplace: props.actions.replaceCommissionsBatchAttachment,
          onDelete: props.actions.deleteCommissionsBatchAttachment
        }}
        commissionsActions={{
          onFilter: props.actions.filterCommissions,
          onCreate: props.actions.createCommission,
          onUpdate: props.actions.updateCommission,
          onPostpone: props.actions.postponeCommission,
          onTryToInclude: props.actions.tryToIncludeCommission,
          onRecalculateForContract: props.actions.recalculateCommissionsForContract,
          onCalculationsUpdate: props.actions.updateCalculatedCommissions,
          onDelete: props.actions.deleteCommission,
          onDeleteStateCommissionsPage: props.actions.deleteStateCommissionsPage
        }}
      />
    </DisplayWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  batch: selectBatchDetail(state),
  paymentToBePaid: selectBatchPaymentToBePaid(state),
  commissionsPage: selectCommissionsPage(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      getCommissionsBatch: getCommissionsBatchActions.request,
      calculateCommissionsBatchPaymentToBePaid: calculateCommissionsBatchPaymentToBePaidActions.request,
      deleteStateCommissionsBatchPaymentToBePaid: deleteStateCommissionsBatchPaymentToBePaidAction,
      generateCommissionsBatchPaymentOrder: generateCommissionsBatchPaymentOrderActions.request,
      downloadCommissionsBatchAttachmentsAsZip: downloadCommissionsBatchAttachmentsAsZipActions.request,
      updateCommissionsBatch: updateCommissionsBatchActions.request,
      deleteCommissionsBatch: deleteCommissionsBatchActions.request,
      changeCommissionsBatchStep: changeCommissionsBatchStepActions.request,
      createCommissionsUnit: createCommissionsUnitActions.request,
      updateCommissionsUnit: updateCommissionsUnitActions.request,
      deleteCommissionsUnit: deleteCommissionsUnitActions.request,
      uploadCommissionsBatchAttachment: uploadCommissionsBatchAttachmentActions.request,
      replaceCommissionsBatchAttachment: replaceCommissionsBatchAttachmentActions.request,
      deleteCommissionsBatchAttachment: deleteCommissionsBatchAttachmentActions.request,
      filterCommissions: filterCommissionsActions.request,
      createCommission: createCommissionActions.request,
      updateCommission: updateCommissionActions.request,
      postponeCommission: postponeCommissionActions.request,
      tryToIncludeCommission: tryToIncludeCommissionActions.request,
      recalculateCommissionsForContract: recalculateCommissionsForContractActions.request,
      updateCalculatedCommissions: updateCalculatedCommissionsActions.request,
      deleteCommission: deleteCommissionActions.request,
      deleteStateCommissionsBatchDetail: deleteStateCommissionsBatchDetailAction,
      deleteStateCommissionsPage: deleteStateCommissionsPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(CommissionsBatchDetailContainer);
