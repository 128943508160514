import { PaginationConfig } from "antd/lib/pagination";
import { TablePaginationConfig } from "antd/lib/table";
import { TableProps } from "antd/lib/table/Table";
import { RcFile } from "antd/lib/upload";
import { AxiosResponse } from "axios";
import Big from "big.js";
import classNames from "classnames";
import { saveAs } from "file-saver";
import moment, { Moment } from "moment";
import { HTMLAttributeAnchorTarget } from "react";
import { isMobile } from "react-device-detect";
import t from "../../app/i18n";
import { ContentType, SlovakHolidays } from "../constants";
import { Country, CountryTwoLetterCode } from "../modules/enums";
import { StorageFile } from "../modules/types";
import { Permission, permissionsPrerequisitesMap } from "../security/authorization/enums";
import { AntIconType, EnvProfile } from "../types";

export const ALL_WHITE_SPACES_PATTERN = new RegExp(/\s/, "g");
export const ATTACHMENT_VIEWER_ID = "attachment-viewer";

/**
 * Function determines whether the project is in localhost DEV mode (project was started with command "npm start" and not as build)
 * with "dev" active profile.
 * @return <i>true</i> if project is in localhost DEV mode, <i>false</i> otherwise
 */
export const isLocalhostDevMode = (): boolean =>
  process.env.NODE_ENV === "development" && getActiveEnvProfile() === "dev";

export const getApiBaseUrl = (): string => process.env.REACT_APP_API_BASE_URL;

export const getActiveEnvProfile = (): EnvProfile => process.env.REACT_APP_ACTIVE_PROFILE as EnvProfile;

export const getGoogleAnalyticsKey = (): string => process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;

export const getGoogleMapsApiKey = (): string => process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

/**
 * Returns first part of input pathname (e.g. input string '/abc/xyz' returns '/abc' in lower case).
 * @param pathname - pathname to be parsed
 * @return first part of input pathname
 */
export const parseFirstPartOfPathname = (pathname: string): string => "/" + pathname.split("/")[1].toLowerCase();

/**
 * Check requested permission.
 * @param accountPermissions - permissions of checked account
 * @param checked - permission to be checked
 * @return <i>true</i> if checked permission is present in account permissions, <i>false</i> otherwise
 */
export const hasPermission = (accountPermissions: Permission[], checked: Permission): boolean => {
  return accountPermissions && accountPermissions.includes(checked);
};

/**
 * Check if all permissions are present in account permissions.
 * @param accountPermissions - permissions of checked account
 * @param checked - permissions to be checked
 * @return <i>true</i> if all checked permissions are present in account permissions, <i>false</i> otherwise
 */
export const hasAllPermissions = (accountPermissions: Permission[], checked: Permission[]): boolean => {
  return accountPermissions && checked.every(perm => perm && accountPermissions.includes(perm));
};

/**
 * Check if any of given permissions is present in account permissions.
 * @param accountPermissions - permissions of checked account
 * @param checked - permissions to be checked
 * @return <i>true</i> if any of checked permissions is present in account permissions, <i>false</i> otherwise
 */
export const hasAnyPermission = (accountPermissions: Permission[], checked: Permission[]): boolean => {
  return accountPermissions && checked.some(perm => perm && accountPermissions.includes(perm));
};

export const getAllPermissionPrerequisites = (permission: Permission): Permission[] => {
  const parsePrerequisites = (permission: Permission, prerequisites: Set<Permission>): void => {
    permissionsPrerequisitesMap.get(permission).forEach(prerequisite => {
      prerequisites.add(prerequisite);
      parsePrerequisites(prerequisite, prerequisites);
    });
  };

  const prerequisitesSet = new Set<Permission>();
  parsePrerequisites(permission, prerequisitesSet);
  return Array.from(prerequisitesSet).reverse();
};

export const appendSearchParamsToURL = (searchParams: object): string => {
  const outputSearch = new URLSearchParams(window.location.search);
  Object.keys(searchParams).forEach(param => {
    if (isDefinedValue(searchParams[param])) {
      if (outputSearch.has(param)) {
        outputSearch.delete(param);
      }
      if (Array.isArray(searchParams[param]) && searchParams[param].length > 1) {
        searchParams[param].forEach((value, key) => {
          outputSearch.append(param, searchParams[param][key]);
        });
      } else if (searchParams[param].length !== 0) {
        outputSearch.set(param, searchParams[param]);
      }
    } else {
      outputSearch.delete(param);
    }
  });

  const outputSearchString = outputSearch.toString();
  return outputSearchString.length > 0 ? window.location.pathname + "?" + outputSearchString : window.location.pathname;
};

export const isJson = (content: string): boolean => {
  try {
    JSON.parse(content);
  } catch (e) {
    return false;
  }
  return true;
};

export const isDefinedValue = (value: any): boolean => value !== undefined && value !== null;

export const isNumberValue = (value: any): boolean => isDefinedValue(value) && value !== "" && !isNaN(value);

export const numberOrZero = (value: any): number => (isNumberValue(value) ? value : 0);

export const valueToString = (value: number | string): string => {
  return value !== null && value !== undefined
    ? typeof value === "number"
      ? value.toString()
      : value
    : (value as string);
};

export const valueToInt = (value: number | string): number => {
  // @ts-ignore
  const parsedValue = parseInt(value);
  return isNaN(parsedValue) ? null : parsedValue;
};

export const valueToFloat = (value: number | string): number => {
  // @ts-ignore
  const parsedValue = parseFloat(value);
  return isNaN(parsedValue) ? null : parsedValue;
};

export const bigToFloat = (value: Big): number => parseFloat(value.round(2, 1).valueOf());

export const tryToExtractBankNameFromIban = (iban: string): string | null => {
  if (!iban || iban.length < 4) {
    return null;
  }
  switch (removeStringWhiteSpaces(iban).substring(4, 8)) {
    case "0200":
      return "Všeobecná úverová banka, a.s.";
    case "0900":
      return "Slovenská sporiteľňa, a.s.";
    case "1100":
      return "Tatra banka, a.s.";
    case "1111":
      return "UniCredit Bank Czech Republic and Slovakia, a.s., pobočka zahraničnej banky";
    case "5200":
      return "OTP Banka Slovensko, a.s.";
    case "5600":
      return "Prima banka Slovensko, a.s.";
    case "7500":
      return "Československá obchodná banka, a.s.";
    case "8130":
      return "Citibank Europe plc, pobočka zahraničnej banky";
    case "6500":
      return "365.bank, a.s.";
    default:
      return null;
  }
};

export const saveTextFile = (content: string, filename: string): void => {
  saveAs(
    new Blob([content], { type: "text/plain;charset=utf-8" }),
    filename.endsWith(".txt") ? filename : filename + ".txt"
  );
};

export const openBlobFile = (response: AxiosResponse<Blob>, forceSave?: boolean): void => {
  let fileName: string;
  if (response.headers["content-disposition"]) {
    const disposition: string = response.headers["content-disposition"];
    const keyword = 'filename="';
    fileName = disposition.substring(
      disposition.indexOf(keyword) + keyword.length,
      disposition.indexOf('"', disposition.indexOf(keyword) + keyword.length)
    );
  } else {
    fileName = "download";
  }

  const contentType = response.headers["content-type"] as string;
  const file = new File([response.data], fileName, { type: contentType });

  if (
    forceSave ||
    contentType === "text/csv" ||
    (contentType !== "application/pdf" && !contentType.startsWith("image") && !contentType.startsWith("text")) ||
    isMobile
  ) {
    saveAs(file, fileName);
  } else {
    const fileUrl = URL.createObjectURL(file);
    document.getElementById(ATTACHMENT_VIEWER_ID).setAttribute("src", fileUrl);
  }
};

export const contains = <T = any>(array: T[], item: T): boolean => array.includes(item);

export const containsAny = <T = any>(array: T[], ...items: T[]): boolean => items.some(item => array.includes(item));

export const containsAll = <T = any>(array: T[], ...items: T[]): boolean => items.every(item => array.includes(item));

/**
 * Replaces item in input array.
 * @param array - array to be modified
 * @param selector - function that identifies item to be replaced
 * @param newValue - value to be added to array
 */
export const replaceInArray = <T = any>(
  array: T[],
  selector: (item: T) => boolean,
  newValue: (current: T) => T
): T[] => {
  const idx = array.findIndex(selector);
  return idx === -1 ? array : [...array.slice(0, idx), newValue(array[idx]), ...array.slice(idx + 1)];
};

/**
 * Removes item from input array.
 * @param array - array to be modified
 * @param selector - function that identifies item to be replaced
 */
export const removeFromArray = <T = any>(array: T[], selector: (item: T) => boolean): T[] => {
  const idx = array.findIndex(selector);
  return idx === -1 ? array : [...array.slice(0, idx), ...array.slice(idx + 1)];
};

export const distinctArray = <T = any>(array: T[]): T[] => {
  return isNotEmptyArray(array) ? array.filter((value, index) => array.indexOf(value) === index) : array;
};

export const isNotEmptyArray = <T = any>(array: T[]): boolean => array && array.length > 0;

export const insertToString = (modifiedValue: string, insertedValue: string, index: number): string => {
  return modifiedValue.slice(0, index) + insertedValue + modifiedValue.slice(index);
};

export const removeStringWhiteSpaces = (value: string): string =>
  value ? value.replace(ALL_WHITE_SPACES_PATTERN, "") : value;

export const createLinkHref = (link: string): string =>
  link ? (link.startsWith("http") ? link : "http://" + link) : null;

export const getCountryByTwoLetterCode = (countryCode: string): Country =>
  countryCode
    ? Country[Object.entries(CountryTwoLetterCode).find(entry => entry[1] === countryCode.toUpperCase())?.[0]]
    : null;

export const parseBirthDateFromPin = (pin: string): Moment => {
  const y = pin.substring(0, 2);
  const year =
    pin.length === 9 ? parseInt("19" + y) : parseInt((moment().year() - parseInt(y) < 2000 ? "19" : "20") + y);

  const m = parseInt(pin.substring(2, 4));
  const month = m > 50 ? m - 51 : m - 1;

  const day = parseInt(pin.substring(4, 6));

  return moment().year(year).month(month).date(day).startOf("day");
};

export const getClosestPreviousWorkDay = (inputDate: Moment): Moment => {
  let date = moment(inputDate);
  while (isHolidayOrWeekend(date)) {
    date = date.subtract(1, "day");
  }
  return date;
};

export const isHolidayOrWeekend = (date: Moment): boolean => {
  if (date.day() === 6 || date.day() === 0) {
    return true;
  }

  if (SlovakHolidays.some(holiday => holiday.d === date.date() && holiday.m === date.month() + 1)) {
    return true;
  }

  if (date.month() === 2 || date.month() === 3) {
    let m;
    let n;
    if (date.year() <= 1699) {
      m = 22;
      n = 2;
    } else if (date.year() <= 1799) {
      m = 23;
      n = 3;
    } else if (date.year() <= 1899) {
      m = 23;
      n = 4;
    } else if (date.year() <= 2099) {
      m = 24;
      n = 5;
    } else if (date.year() <= 2199) {
      m = 24;
      n = 6;
    } else if (date.year() <= 2299) {
      m = 25;
      n = 0;
    } else if (date.year() <= 2399) {
      m = 26;
      n = 1;
    } else if (date.year() <= 2499) {
      m = 25;
      n = 1;
    } else {
      console.error("Unsupported year for Easter date calculation.");
      return false;
    }

    const a = date.year() % 19;
    const b = date.year() % 4;
    const c = date.year() % 7;
    const d = (m + 19 * a) % 30;
    const e = (n + 2 * b + 4 * c + 6 * d) % 7;
    const marchEasterSunday = 22 + d + e;
    let aprilEasterSunday = d + e - 9;

    let easterFriday;
    let easterMonday;

    if (marchEasterSunday > 31) {
      if (aprilEasterSunday === 26) {
        aprilEasterSunday = 19;
      } else if (aprilEasterSunday === 25 && d === 28 && a > 10) {
        aprilEasterSunday = 18;
      }
      easterFriday = moment(date).month(3).date(aprilEasterSunday).subtract(2, "day");
      easterMonday = moment(date).month(3).date(aprilEasterSunday).add(1, "day");
    } else {
      easterFriday = moment(date).month(2).date(marchEasterSunday).subtract(2, "day");
      easterMonday = moment(date).month(2).date(marchEasterSunday).add(1, "day");
    }

    return date.isSame(easterFriday, "day") || date.isSame(easterMonday, "day");
  }

  return false;
};

export const paginationStandardProps: PaginationConfig = {
  showTotal: (total, [from, to]) => t("common.pagination", { from, to, total }),
  showQuickJumper: true,
  showSizeChanger: false
};

export const paginationTableProps: TablePaginationConfig = {
  ...paginationStandardProps,
  position: ["topRight", "bottomRight"]
};

export const tableStandardProps = (
  stripped: boolean = true,
  noHeaderBackground: boolean = false,
  additionalClassName?: string
): TableProps<any> => ({
  size: "small",
  className: classNames(
    "standard-table",
    { stripped: stripped },
    { "no-header-background": noHeaderBackground },
    additionalClassName
  ),
  rowKey: "id",
  rowClassName: (record, index) => stripped && (index % 2 === 0 ? "table-row-light" : "table-row-dark"),
  locale: { emptyText: t("common.noData") }
});

export const openUrl = (url: string, target: HTMLAttributeAnchorTarget = "_self"): void => {
  window.open(url, target, "noopener, noreferrer");
};

export const generateRandomToken = (length: number): string => {
  const randomNumbers = new Uint8Array(length / 2);
  window.crypto.getRandomValues(randomNumbers);
  return Array.from(randomNumbers, dec => dec.toString(16).padStart(2, "0")).join("");
};

export const resolveFileIconType = (contentType: string): AntIconType => {
  switch (contentType) {
    case ContentType.RAR:
    case ContentType.ZIP:
      return "file-zip";
    case ContentType.PDF:
      return "file-pdf";
    case ContentType.DOCX:
      return "file-word";
    case ContentType.XLSX:
      return "file-excel";
    case ContentType.PPTX:
      return "file-ppt";
    default:
      if (!contentType) {
        return "file-unknown";
      } else if (contentType.startsWith("image") || contentType.startsWith("video")) {
        return "file-image";
      } else if (contentType.startsWith("text")) {
        return "file-text";
      }
      return "file";
  }
};

export const stripAccents = (str: string): string => {
  return str?.normalize("NFD").replace(/\p{Diacritic}/gu, "");
};

export const storageFileToRcFile = (file: StorageFile): Partial<RcFile> => {
  return {
    name: file.filename,
    uid: file.id,
    type: file.contentType,
    size: file.size
  };
};
