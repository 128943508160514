import { Card, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { generatePath } from "react-router-dom";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../../common/constants";
import { formatAgentIdNumber, formatLocaleCurrency } from "../../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../common/utils/utils";
import AgentTypeTagWithName from "../../../../agent/components/AgentTypeTagWithName";
import { AGENT_ROUTE_PATHS } from "../../../../agent/paths";
import { BailAccountBalance, BailAccountsReportFilterPageResult } from "../../types";

interface Props {
  reportPage: BailAccountsReportFilterPageResult;
  onPageChange: (pageNumber: number) => void;
}

const BailAccountsReportTableView = ({ reportPage, onPageChange }: Props) => {
  const columns: ColumnsType<BailAccountBalance> = [
    {
      key: "agentName",
      title: t("common.agent"),
      width: 220,
      ellipsis: { showTitle: false },
      render: (_, record) => <AgentTypeTagWithName agent={record.agent} hideIdNumber ellipsis showLink />
    },
    {
      key: "idNumber",
      title: t("agent.attrs.idNumber"),
      width: 100,
      render: (_, record) => formatAgentIdNumber(record.agent)
    },
    {
      key: "commissionsSettingsLevel",
      title: t("agent.helpers.commissionsSettingsLevel"),
      width: 100,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.agent.commissionsSettings.commissionsSettingsLevel?.code}</Ellipsis>
    },
    {
      key: "targetAmount",
      title: t("commissions.bailAccount.attrs.targetAmount"),
      align: "right",
      width: 100,
      render: (_, record) => formatLocaleCurrency(record.targetAmount)
    },
    {
      key: "balance",
      title: t("commissions.bailAccount.helpers.balance"),
      align: "right",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <span className={record.targetAmount && record.balance >= record.targetAmount ? "green-text" : null}>
          {formatLocaleCurrency(record.balance)}
        </span>
      )
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 90,
      render: (_, record) => (
        <ActionTextIcon
          path={generatePath(AGENT_ROUTE_PATHS.commissions.to, { id: record.agent.id }) + "?tab=bail-account"}
          icon="eye"
          color="blue"
          text={t("common.show")}
        />
      )
    }
  ];

  return (
    <Card className="card-box">
      <Table<BailAccountBalance>
        {...tableStandardProps()}
        columns={columns}
        rowKey={record => record.agent.id}
        scroll={{ x: TableSizes.MEDIUM }}
        dataSource={reportPage.pageData}
        pagination={{
          ...paginationTableProps,
          current: reportPage.pageIndex + 1,
          pageSize: reportPage.pageSize,
          total: reportPage.totalElementsCount,
          onChange: onPageChange
        }}
      />
    </Card>
  );
};

export default BailAccountsReportTableView;
