import React from "react";
import { Route, Routes } from "react-router";
import { WILDCARD_ROUTE } from "../../../common/constants";
import NotFound from "../../../common/pages/NotFound/NotFound";
import { Permission } from "../../../common/security/authorization/enums";
import PageWithPermission from "../../../common/security/authorization/PageWithPermission";
import CalcBlacklistContainer from "./containers/CalcBlacklistContainer";

const routes = () => (
  <Routes>
    <Route
      index
      element={
        <PageWithPermission
          component={<CalcBlacklistContainer />}
          permissions={[Permission.ADMIN_GLOBAL_ENUMERATIONS]}
        />
      }
    />

    <Route path={WILDCARD_ROUTE} element={<NotFound />} />
  </Routes>
);

export default routes;
