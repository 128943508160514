import { Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { generatePath } from "react-router-dom";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../../common/constants";
import { SearchPageResult } from "../../../../../common/types";
import { formatPhoneNumber } from "../../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../common/utils/utils";
import { ADMIN_USER_ROUTE_PATHS } from "../../../paths";
import { UserAdminView } from "../../../types";
import { hasConfirmedAgentUserAccount, hasDisabledAgentUserAccountsOnly } from "../../../utils";

interface Props {
  usersCurrentPage: SearchPageResult<UserAdminView>;
  onPageChange: (pageNumber: number) => void;
}

const AdminUserListTableView = (props: Props) => {
  const columns: ColumnsType<UserAdminView> = [
    {
      key: "name",
      title: t("user.attrs.name"),
      width: 200,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.name}</Ellipsis>
    },
    {
      key: "email",
      title: t("user.attrs.email"),
      width: 200,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.email || record.emailToConfirm}</Ellipsis>
    },
    {
      key: "phone",
      title: t("user.attrs.phone"),
      width: 160,
      ellipsis: { showTitle: false },
      render: (_, record) => (
        <Ellipsis>
          {formatPhoneNumber(hasConfirmedAgentUserAccount(record) ? record.phone : record.phoneToConfirm)}
        </Ellipsis>
      )
    },
    {
      key: "tags",
      width: 500,
      render: (_, record) => (
        <Space>
          {record.locked && (
            <Tag color="red" style={{ marginRight: 0 }}>
              {t("user.helpers.lockedUser")}
            </Tag>
          )}
          {hasDisabledAgentUserAccountsOnly(record) && <Tag color="purple">{t("user.helpers.disabledUser")}</Tag>}
          {!hasConfirmedAgentUserAccount(record) && <Tag color="orange">{t("user.helpers.unconfirmedUser")}</Tag>}
          {record.agentUserRole.systemAdmin && <Tag color="cyan">{t("user.attrs.systemAdmin")}</Tag>}
        </Space>
      )
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 90,
      render: (_, record) => (
        <ActionTextIcon
          path={generatePath(ADMIN_USER_ROUTE_PATHS.detail.to, { id: record.id })}
          icon="eye"
          color="blue"
          text={t("common.show")}
        />
      )
    }
  ];

  return (
    <Table<UserAdminView>
      {...tableStandardProps()}
      columns={columns}
      dataSource={props.usersCurrentPage.pageData}
      scroll={{ x: TableSizes.LARGE }}
      pagination={{
        ...paginationTableProps,
        current: props.usersCurrentPage.pageIndex + 1,
        pageSize: props.usersCurrentPage.pageSize,
        total: props.usersCurrentPage.totalElementsCount,
        onChange: props.onPageChange
      }}
    />
  );
};

export default AdminUserListTableView;
