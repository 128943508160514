import { Col, Form, Input, Modal, Row } from "antd";
import React, { useEffect } from "react";
import t from "../../../../app/i18n";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { createAffiliatePartnerActions, updateAffiliatePartnerActions } from "../../ducks";
import { AffiliatePartner, CreateUpdateAffiliatePartner } from "../../types";

interface Props {
  affiliatePartner?: AffiliatePartner;
  open: boolean;
  onCreate?: typeof createAffiliatePartnerActions.request;
  onUpdate?: typeof updateAffiliatePartnerActions.request;
  onFormCancel: () => void;
}

const AffiliatePartnerForm = ({ affiliatePartner, open, onCreate, onUpdate, onFormCancel }: Props) => {
  const [form] = Form.useForm<CreateUpdateAffiliatePartner>();
  useFormErrorHandler(form, "affiliatePartner.attrs", [
    requests.CREATE_AFFILIATE_PARTNER,
    requests.UPDATE_AFFILIATE_PARTNER
  ]);

  useEffect(() => {
    if (open && affiliatePartner) {
      form.setFieldsValue({ ...affiliatePartner });
    }
  }, [open, affiliatePartner, form]);

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_AFFILIATE_PARTNER, requests.UPDATE_AFFILIATE_PARTNER],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (affiliatePartner) {
          onUpdate?.({ id: affiliatePartner.id, object: values });
        } else {
          onCreate?.(values);
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.SMALL}
      open={open}
      title={affiliatePartner ? t("affiliatePartner.titles.updatePartner") : t("affiliatePartner.titles.createPartner")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="affiliatePartnerForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item
              name="name"
              label={t("affiliatePartner.attrs.name")}
              rules={[validations.notBlank, validations.size(1, 64)]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AffiliatePartnerForm;
