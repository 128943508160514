import { Layout } from "antd";
import React from "react";
import { Role } from "../../../common/security/authorization/enums";
import { getActiveEnvProfile } from "../../../common/utils/utils";
import HeaderMenu from "./HeaderMenu";

interface Props {
  userAuthenticated: boolean;
  selectedAccountRole: Role;
}

const Header = (props: Props) => (
  <Layout.Header className="header__wrapper">
    <div className="header__left-side">
      {getActiveEnvProfile() !== "prod" && (
        <span className={"environment--" + getActiveEnvProfile()}>{getActiveEnvProfile().toUpperCase()}</span>
      )}
    </div>

    {props.userAuthenticated && <HeaderMenu selectedAccountRole={props.selectedAccountRole} />}
  </Layout.Header>
);

export default Header;
