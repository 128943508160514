import { Button, Col, Popconfirm, Row, Tooltip } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import t from "../../../app/i18n";
import { StorageFile } from "../../modules/types";
import ConditionalWrapper from "../../modules/wrappers/ConditionalWrapper";
import { LinkTargetType } from "../../types";
import AntIcon from "../icons/AntIcon";
import FileTypeIcon from "../icons/FileTypeIcon";
import PopconfirmDeleteIcon from "../icons/PopconfirmDeleteIcon";
import Ellipsis from "./Ellipsis";

interface Props {
  file: StorageFile;
  orientation: "horizontal" | "vertical";
  iconSize: number;
  linkPath: string;
  linkTarget: LinkTargetType;
  onDelete?: () => void;
}

const FileThumbnailCardView = ({ file, orientation, iconSize, linkPath, linkTarget, onDelete }: Props) => (
  <Tooltip title={file.filename} placement="bottom">
    {orientation === "horizontal" ? (
      <div className="file-thumbnail">
        {onDelete && (
          <Popconfirm
            title={t("common.deleteAttachmentConfirm")}
            icon={<PopconfirmDeleteIcon />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="danger"
            onConfirm={onDelete}
          >
            <Button
              size="small"
              type="dashed"
              icon={<AntIcon type="delete" />}
              danger
              className="file-thumbnail-delete-btn"
            />
          </Popconfirm>
        )}
        <ConditionalWrapper
          condition={!!linkPath}
          wrapper={children => (
            <Link to={linkPath} target={linkTarget} className="file-thumbnail-link">
              {children}
            </Link>
          )}
        >
          <div className="file-thumbnail-content">
            <Row gutter={0}>
              <Col span={24}>
                <FileTypeIcon
                  contentType={file.contentType}
                  className="margin-bottom-tiny"
                  style={{ fontSize: `${iconSize}px` }}
                />
              </Col>
            </Row>
            <Row gutter={0}>
              <Col span={24}>
                <Ellipsis shouldRenderTooltip={false}>{file.filename}</Ellipsis>
              </Col>
            </Row>
          </div>
        </ConditionalWrapper>
      </div>
    ) : (
      <div className="file-thumbnail">
        {onDelete && (
          <Popconfirm
            title={t("common.deleteAttachmentConfirm")}
            icon={<PopconfirmDeleteIcon />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="danger"
            onConfirm={onDelete}
          >
            <Button
              size="small"
              type="dashed"
              icon={<AntIcon type="delete" />}
              danger
              className="file-thumbnail-delete-btn"
            />
          </Popconfirm>
        )}
        <ConditionalWrapper
          condition={!!linkPath}
          wrapper={children => (
            <Link to={linkPath} target={linkTarget} className="file-thumbnail-link">
              {children}
            </Link>
          )}
        >
          <Row gutter={0} align="middle" className="file-thumbnail-content">
            <Col flex={`${iconSize + 8}px`}>
              <FileTypeIcon
                contentType={file.contentType}
                className="margin-right-tiny"
                style={{ fontSize: `${iconSize}px` }}
              />
            </Col>
            <Col flex="1 1 0" style={{ minWidth: 0 }}>
              <Ellipsis shouldRenderTooltip={false}>{file.filename}</Ellipsis>
            </Col>
          </Row>
        </ConditionalWrapper>
      </div>
    )}
  </Tooltip>
);

export default FileThumbnailCardView;
