import { Popover } from "antd";
import React from "react";
import t from "../../../../../app/i18n";
import AntIcon from "../../../../../common/components/icons/AntIcon";

const CommissionsSettingsRuleHelperView = () => (
  <Popover
    title={t("commissions.settings.helpers.rules.title")}
    content={
      <ol>
        {[...Array(6)].map((_, index) => (
          <li key={index}>{t("commissions.settings.helpers.rules.option" + (index + 1))}</li>
        ))}
      </ol>
    }
  >
    <span>
      <AntIcon type="question" className="cursor-help" style={{ fontSize: "20px" }} />
    </span>
  </Popover>
);

export default CommissionsSettingsRuleHelperView;
