import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { PageSizes } from "../../../../common/constants";
import ContentWrapper from "../../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../../common/types";
import { appendSearchParamsToURL, numberOrZero } from "../../../../common/utils/utils";
import { selectRouterLocationSearch } from "../../../ducks";
import CommissionsBatchForm from "../components/forms/CommissionsBatchForm";
import CommissionsBatchFilterView from "../components/views/list/CommissionsBatchFilterView";
import CommissionsBatchTableView from "../components/views/list/CommissionsBatchTableView";
import {
  createCommissionsBatchActions,
  deleteCommissionsBatchActions,
  deleteStateCommissionsBatchesPageAction,
  deleteStateCommissionsBatchNamePrefixAction,
  filterCommissionsBatchesActions,
  getCommissionsBatchNamePrefixActions,
  selectBatchesPage,
  selectBatchNamePrefix,
  updateCommissionsBatchActions
} from "../ducks";
import { CommissionsBatchStep } from "../enums";
import { CommissionsBatch, CommissionsBatchFilterPageRequest, CommissionsBatchFilterPageResult } from "../types";

interface StateProps {
  urlSearchQuery: string;
  batchesPage: CommissionsBatchFilterPageResult;
  batchNamePrefix: string;
}

interface ActionsMap {
  filterCommissionsBatches: typeof filterCommissionsBatchesActions.request;
  getCommissionsBatchNamePrefix: typeof getCommissionsBatchNamePrefixActions.request;
  createCommissionsBatch: typeof createCommissionsBatchActions.request;
  updateCommissionsBatch: typeof updateCommissionsBatchActions.request;
  deleteCommissionsBatch: typeof deleteCommissionsBatchActions.request;
  deleteStateCommissionsBatchesPage: typeof deleteStateCommissionsBatchesPageAction;
  deleteStateCommissionsBatchNamePrefix: typeof deleteStateCommissionsBatchNamePrefixAction;
}

const CommissionsBatchListContainer = (props: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();

  const [batchFormOpen, setBatchFormOpen] = useState<boolean>(false);
  const [batchToUpdate, setBatchToUpdate] = useState<CommissionsBatch>();

  useEffect(() => {
    const urlParams = new URLSearchParams(props.urlSearchQuery);
    props.actions.filterCommissionsBatches({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.LARGE,
      keyword: urlParams.get("keyword"),
      steps: urlParams.getAll("steps") as CommissionsBatchStep[]
    });
    return () => {
      props.actions.deleteStateCommissionsBatchesPage();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterSubmit = (filter: CommissionsBatchFilterPageRequest): void => {
    navigate(appendSearchParamsToURL({ ...filter, pageIndex: null, keyword: filter.keyword || null }), {
      replace: true
    });
    props.actions.filterCommissionsBatches({ pageIndex: 0, pageSize: props.batchesPage.pageSize, ...filter });
  };

  const handleTablePageChange = (pageNumber: number): void => {
    const { keyword, steps } = props.batchesPage;

    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    props.actions.filterCommissionsBatches({ pageIndex: pageNumber - 1, pageSize: PageSizes.LARGE, keyword, steps });
  };

  const handleCreateClick = (): void => {
    setBatchFormOpen(true);
  };

  const handleUpdateClick = (batch: CommissionsBatch): void => {
    setBatchFormOpen(true);
    setBatchToUpdate(batch);
  };

  const handleFormCancel = (): void => {
    setBatchFormOpen(false);
    setBatchToUpdate(null);
    props.actions.deleteStateCommissionsBatchNamePrefix();
  };

  return (
    <ContentWrapper>
      <CommissionsBatchFilterView
        filter={props.batchesPage}
        onFilterSubmit={handleFilterSubmit}
        onCreateClick={handleCreateClick}
      />

      <CommissionsBatchTableView
        batchesPage={props.batchesPage}
        onUpdateClick={handleUpdateClick}
        onDelete={props.actions.deleteCommissionsBatch}
        onPageChange={handleTablePageChange}
      />

      <CommissionsBatchForm
        batch={batchToUpdate}
        open={batchFormOpen}
        namePrefix={props.batchNamePrefix}
        onNamePrefixGet={props.actions.getCommissionsBatchNamePrefix}
        onCreate={props.actions.createCommissionsBatch}
        onUpdate={props.actions.updateCommissionsBatch}
        onFormCancel={handleFormCancel}
      />
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  urlSearchQuery: selectRouterLocationSearch(state),
  batchesPage: selectBatchesPage(state),
  batchNamePrefix: selectBatchNamePrefix(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterCommissionsBatches: filterCommissionsBatchesActions.request,
      getCommissionsBatchNamePrefix: getCommissionsBatchNamePrefixActions.request,
      createCommissionsBatch: createCommissionsBatchActions.request,
      updateCommissionsBatch: updateCommissionsBatchActions.request,
      deleteCommissionsBatch: deleteCommissionsBatchActions.request,
      deleteStateCommissionsBatchesPage: deleteStateCommissionsBatchesPageAction,
      deleteStateCommissionsBatchNamePrefix: deleteStateCommissionsBatchNamePrefixAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(CommissionsBatchListContainer);
