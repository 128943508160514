import { Card, Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import logo from "../../../assets/images/logo-cliip-1000x-transparent.png";
import { selectBrandingPersistData } from "../../../modules/ducks";
import { AgentBrandingEnumeration } from "../../../modules/enumerations/types";
import { RootState } from "../../types";

interface Props {
  children: React.ReactNode;
}

const AnonymousContentWrapper = (props: Props) => {
  const branding = useSelector<RootState, AgentBrandingEnumeration>(selectBrandingPersistData);

  const logoUrl = branding?.logoResourcePath ? `/images/${branding.logoResourcePath}` : logo;

  return (
    <div className="unsigned-wrapper">
      <img className="app-logo" src={logoUrl} alt="App Logo" />
      <Row justify="center">
        <Col flex="500px">
          <Card>{props.children}</Card>
        </Col>
      </Row>
    </div>
  );
};

export default AnonymousContentWrapper;
