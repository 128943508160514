import { Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { RcFile } from "antd/lib/upload";
import classNames from "classnames";
import React from "react";
import { generatePath } from "react-router-dom";
import t from "../../../../../../app/i18n";
import ActionTextIcon from "../../../../../../common/components/icons/ActionTextIcon";
import FileTypeIconWithFilename from "../../../../../../common/components/icons/FileTypeIconWithFilename";
import PopconfirmDeleteIcon from "../../../../../../common/components/icons/PopconfirmDeleteIcon";
import UploadDragger from "../../../../../../common/components/views/UploadDragger";
import { PageSizes, TableSizes } from "../../../../../../common/constants";
import { formatFileSize, formatLocaleDateTime } from "../../../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../../common/utils/utils";
import { deleteCommissionsLevelAttachmentActions, uploadCommissionsLevelAttachmentsActions } from "../../../ducks";
import { COMMISSIONS_LEVEL_ROUTE_PATHS } from "../../../paths";
import { CommissionsSettingsLevel, CommissionsSettingsLevelAttachment } from "../../../types";

interface Props {
  level: CommissionsSettingsLevel;
  onUpload?: typeof uploadCommissionsLevelAttachmentsActions.request;
  onDelete?: typeof deleteCommissionsLevelAttachmentActions.request;
}

const CommissionsLevelAttachmentsTableView = ({ level, onUpload, onDelete }: Props) => {
  const handleAttachmentsUpload = (file: RcFile, fileList: RcFile[]): boolean => {
    if (fileList.length > 0) {
      const formData = new FormData();
      fileList.forEach(file => formData.append("files", file));
      fileList.splice(0, fileList.length);

      onUpload({ id: level.id, object: formData });
    }

    return false;
  };

  const columns: ColumnsType<CommissionsSettingsLevelAttachment> = [
    {
      key: "createdAt",
      title: t("common.createdAt"),
      width: 150,
      render: (_, record) => formatLocaleDateTime(record.createdAt)
    },
    {
      key: "filename",
      title: t("common.filename"),
      ellipsis: { showTitle: false },
      render: (_, record) => (
        <FileTypeIconWithFilename contentType={record.file.contentType} filename={record.file.filename} ellipsis />
      )
    },
    {
      key: "size",
      title: t("common.filesize"),
      width: 100,
      render: (_, record) => formatFileSize(record.file.size)
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <>
          <ActionTextIcon
            path={generatePath(COMMISSIONS_LEVEL_ROUTE_PATHS.attachment.to, { id1: level.id, id2: record.id })}
            target="_blank"
            icon="export"
            color="blue"
            text={t("common.open")}
          />

          {onDelete && (
            <>
              <Divider type="vertical" />

              <Popconfirm
                title={t("common.deleteAttachmentConfirm")}
                icon={<PopconfirmDeleteIcon />}
                okText={t("common.yes")}
                cancelText={t("common.no")}
                okType="danger"
                onConfirm={() => onDelete({ id1: level.id, id2: record.id })}
              >
                <span>
                  <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
                </span>
              </Popconfirm>
            </>
          )}
        </>
      )
    }
  ];

  return (
    <>
      <Table<CommissionsSettingsLevelAttachment>
        {...tableStandardProps()}
        columns={columns}
        scroll={{ x: TableSizes.SMALL }}
        dataSource={level.attachments}
        pagination={{ ...paginationTableProps, pageSize: PageSizes.SMALL, total: level.attachments.length }}
      />

      {onUpload && (
        <div
          className={classNames(
            "margin-bottom-small",
            level.attachments.length > 0 ? "table-footer-margin" : "margin-top-small"
          )}
        >
          <UploadDragger multiple showUploadList={false} beforeUpload={handleAttachmentsUpload} />
        </div>
      )}
    </>
  );
};

export default CommissionsLevelAttachmentsTableView;
