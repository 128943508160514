import { Button, Popconfirm, Space } from "antd";
import React from "react";
import t from "../../../../../app/i18n";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import {
  formatLocaleCurrencyWithNullAsZero,
  formatLocalePercentageNumberWithNullAsZero
} from "../../../../../common/utils/formatUtils";
import { BailAccountSettings } from "../../types";

interface Props {
  settings: BailAccountSettings;
  showActions: boolean;
  noSettingsLabel: string;
  onCreateUpdateClick?: () => void;
  onDeleteClick?: () => void;
}

const BailAccountSettingsView = ({
  settings,
  showActions,
  noSettingsLabel,
  onCreateUpdateClick,
  onDeleteClick
}: Props) =>
  settings ? (
    <>
      <table className="data-table-view">
        <tbody>
          <tr>
            <td>{t("commissions.bailAccount.attrs.targetAmount")}:</td>
            <td className="right-align">
              <b>{formatLocaleCurrencyWithNullAsZero(settings.targetAmount)}</b>
            </td>
          </tr>
          <tr>
            <td>{t("commissions.bailAccount.attrs.rate")}:</td>
            <td className="right-align">
              <b>{formatLocalePercentageNumberWithNullAsZero(settings.rate)}</b>
            </td>
          </tr>
        </tbody>
      </table>

      {showActions && (
        <Space className="margin-top-medium">
          <Button type="primary" size="small" icon={<AntIcon type="edit" />} onClick={onCreateUpdateClick}>
            {t("common.edit")}
          </Button>

          <Popconfirm
            title={t("commissions.bailAccount.helpers.deleteSettingsConfirm")}
            icon={<PopconfirmDeleteIcon />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="danger"
            onConfirm={onDeleteClick}
          >
            <Button size="small" icon={<AntIcon type="delete" />} danger>
              {t("common.delete")}
            </Button>
          </Popconfirm>
        </Space>
      )}
    </>
  ) : (
    <>
      <div>
        <i>{noSettingsLabel}</i>
      </div>
      {showActions && (
        <Button
          className="margin-top-medium"
          type="primary"
          size="small"
          icon={<AntIcon type="plus" />}
          onClick={onCreateUpdateClick}
        >
          {t("commissions.bailAccount.actions.createSettings")}
        </Button>
      )}
    </>
  );

export default BailAccountSettingsView;
