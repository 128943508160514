import { combineReducers } from "redux";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { NormalizedError, RootState } from "../../../../common/types";
import { CalcResult, GenResponse } from "../types";
import {
  RealtyCalc,
  RealtyCalcDraft,
  RealtyCalcReducerState,
  RealtyCalcResultData,
  RealtyGen,
  RealtyOffer
} from "./types";

/**
 * ACTIONS
 */
export const calculateRealtyActions = createAsyncAction(
  "calc-realty/CALCULATE_REQUEST",
  "calc-realty/CALCULATE_SUCCESS",
  "calc-realty/CALCULATE_FAILURE"
)<RealtyCalc, CalcResult<RealtyCalcResultData>[][], NormalizedError>();

export const generateRealtyActions = createAsyncAction(
  "calc-realty/GENERATE_REQUEST",
  "calc-realty/GENERATE_SUCCESS",
  "calc-realty/GENERATE_FAILURE"
)<RealtyGen, GenResponse, NormalizedError>();

export const generateRealtyOfferActions = createAsyncAction(
  "calc-realty/GENERATE_OFFER_REQUEST",
  "calc-realty/GENERATE_OFFER_SUCCESS",
  "calc-realty/GENERATE_OFFER_FAILURE"
)<RealtyOffer, void, NormalizedError>();

export const setRealtyCalcResultsAction =
  createAction("calc-realty/SET_CALC_RESULTS")<CalcResult<RealtyCalcResultData>[][]>();
export const setRealtyDraftAction = createAction("calc-realty/SET_DRAFT")<RealtyCalcDraft>();
export const setRealtyInitialCalcDataAction = createAction("calc-realty/SET_INITIAL_CALC_DATA")<RealtyCalc>();
export const setRealtyInitialGenDataAction = createAction("calc-realty/SET_INITIAL_GEN_DATA")<RealtyGen>();

export const deleteStateRealtyCalcResultsAction = createAction("calc-realty/DELETE_STATE_CALC_RESULTS")<void>();
export const deleteStateRealtyGenResultAction = createAction("calc-realty/DELETE_STATE_GEN_RESULT")<void>();
export const deleteStateRealtyDraftAction = createAction("calc-realty/DELETE_STATE_DRAFT")<void>();
export const deleteStateRealtyInitialCalcGenDataAction = createAction(
  "calc-realty/DELETE_STATE_INITIAL_CALC_GEN_DATA"
)<void>();

const actions = {
  calculateRealtyActions,
  generateRealtyActions,
  generateRealtyOfferActions,
  setRealtyCalcResultsAction,
  setRealtyDraftAction,
  setRealtyInitialCalcDataAction,
  setRealtyInitialGenDataAction,
  deleteStateRealtyCalcResultsAction,
  deleteStateRealtyGenResultAction,
  deleteStateRealtyDraftAction,
  deleteStateRealtyInitialCalcGenDataAction
};

export type RealtyCalcAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: RealtyCalcReducerState = {
  calcResults: [],
  genResult: null,
  draft: null,
  initialCalcData: null,
  initialGenData: null
};

const calcResultsReducer = createReducer(initialState.calcResults)
  .handleAction([calculateRealtyActions.success, setRealtyCalcResultsAction], (_, { payload }) => payload)
  .handleAction([calculateRealtyActions.failure, deleteStateRealtyCalcResultsAction], () => initialState.calcResults);

const genResultReducer = createReducer(initialState.genResult)
  .handleAction(generateRealtyActions.success, (_, { payload }) => payload)
  .handleAction([generateRealtyActions.failure, deleteStateRealtyGenResultAction], () => initialState.genResult);

const draftReducer = createReducer(initialState.draft)
  .handleAction(setRealtyDraftAction, (_, { payload }) => payload)
  .handleAction(deleteStateRealtyDraftAction, () => initialState.draft);

const initialCalcDataReducer = createReducer(initialState.initialCalcData)
  .handleAction(setRealtyInitialCalcDataAction, (_, { payload }) => payload)
  .handleAction(deleteStateRealtyInitialCalcGenDataAction, () => initialState.initialCalcData);

const initialGenDataReducer = createReducer(initialState.initialGenData)
  .handleAction(setRealtyInitialGenDataAction, (_, { payload }) => payload)
  .handleAction(deleteStateRealtyInitialCalcGenDataAction, () => initialState.initialGenData);

export const realtyCalcReducer = combineReducers<RealtyCalcReducerState>({
  calcResults: calcResultsReducer,
  genResult: genResultReducer,
  draft: draftReducer,
  initialCalcData: initialCalcDataReducer,
  initialGenData: initialGenDataReducer
});

/**
 * SELECTORS
 */
const selectRealtyCalc = (state: RootState): RealtyCalcReducerState => state.calculator.calcs.realty;

export const selectRealtyCalcResults = (state: RootState): CalcResult<RealtyCalcResultData>[][] =>
  selectRealtyCalc(state).calcResults;
export const selectRealtyGenResult = (state: RootState): GenResponse => selectRealtyCalc(state).genResult;
export const selectRealtyDraft = (state: RootState): RealtyCalcDraft => selectRealtyCalc(state).draft;
export const selectRealtyInitialCalcData = (state: RootState): RealtyCalc => selectRealtyCalc(state).initialCalcData;
export const selectRealtyInitialGenData = (state: RootState): RealtyGen => selectRealtyCalc(state).initialGenData;
