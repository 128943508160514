import { Card, Col, Input, Row, Space, Tree } from "antd";
import { DataNode } from "rc-tree/lib/interface";
import React, { useEffect, useState } from "react";
import t from "../../../../../app/i18n";
import ActionButton from "../../../../../common/components/buttons/ActionButton";
import { accessTreePathDelimiter } from "../../../../../common/constants";
import { UUID } from "../../../../../common/types";
import { formatAgentIdNumber } from "../../../../../common/utils/formatUtils";
import messageUtils from "../../../../../common/utils/messageUtils";
import { tInterval } from "../../../../../common/utils/translationUtils";
import { isNotEmptyArray, stripAccents } from "../../../../../common/utils/utils";
import { AgentTree, AgentWithSubordinates } from "../../../types";
import AgentTreeNodeTitleView from "./AgentTreeNodeTitleView";

interface Props {
  agentTree: AgentTree;
}

const AgentTreeView = ({ agentTree }: Props) => {
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(false);
  const [sanitizedSearchKeyword, setSanitizedSearchKeyword] = useState<string>();

  useEffect(() => {
    if (agentTree) {
      setExpandedKeys([agentTree.agent.id]);
      setAutoExpandParent(false);
      setSanitizedSearchKeyword(null);
    }
  }, [agentTree]);

  const handleTreeExpand = (treeExpandedKeys: (string | number)[]): void => {
    setExpandedKeys(treeExpandedKeys as string[]);
    setAutoExpandParent(false);
  };

  const handleExpandTreeClick = (): void => {
    const aggregateAgentIds = (agents: AgentWithSubordinates[]): string[] =>
      [].concat(
        ...agents.map(agent =>
          agent.subordinates && agent.subordinates.length > 0
            ? [agent.id, ...aggregateAgentIds(agent.subordinates)]
            : [agent.id]
        )
      );

    setExpandedKeys(aggregateAgentIds([agentTree.agent]));
  };

  const handleCollapseTreeClick = (): void => {
    setExpandedKeys([agentTree.agent.id]);
  };

  const handleSearchSubmit = (keyword: string): void => {
    if (keyword?.trim()) {
      const sanitize = (str: string): string => stripAccents(str)?.toLowerCase()?.trim();

      const sanitizedKeyword = sanitize(keyword);

      const findAgentIdsByKeyword = (agents: AgentWithSubordinates[]): string[] =>
        [].concat(
          ...agents.map(agent => {
            const results: UUID[] = [];

            if (
              sanitize(agent.aggregatedName).indexOf(sanitizedKeyword) > -1 ||
              sanitize(formatAgentIdNumber(agent))?.indexOf(sanitizedKeyword) > -1 ||
              sanitize(agent.identifier)?.indexOf(sanitizedKeyword) > -1 ||
              sanitize(agent.commissionsSettings.commissionsSettingsLevel?.code)?.indexOf(sanitizedKeyword) > -1
            ) {
              const treePathIds = agent.accessTreePath.split(accessTreePathDelimiter);
              if (treePathIds[treePathIds.length - 2]) {
                results.push(treePathIds[treePathIds.length - 2]);
              }
            }

            if (agent.subordinates && agent.subordinates.length > 0) {
              results.push(...findAgentIdsByKeyword(agent.subordinates));
            }

            return results;
          })
        );

      const foundIds = findAgentIdsByKeyword([agentTree.agent]);
      const distinctIds = [...new Set(foundIds)];

      messageUtils.successMessage(tInterval("agent.helpers.treeSearchResultMessage_interval", foundIds.length));
      setExpandedKeys(distinctIds.length > 0 ? distinctIds : [agentTree.agent.id]);
      setAutoExpandParent(true);
      setSanitizedSearchKeyword(sanitizedKeyword);
    } else {
      setExpandedKeys([agentTree.agent.id]);
      setAutoExpandParent(false);
      setSanitizedSearchKeyword(null);
    }
  };

  const buildTreeNodes = (agents: AgentWithSubordinates[]): DataNode[] => {
    return agents.map<DataNode>(agent => ({
      title: <AgentTreeNodeTitleView agent={agent} sanitizedSearchKeyword={sanitizedSearchKeyword} />,
      key: agent.id,
      value: agent.id,
      selectable: false,
      children: isNotEmptyArray(agent.subordinates) ? buildTreeNodes(agent.subordinates) : undefined
    }));
  };

  return (
    <Card className="card-box">
      <Row gutter={0}>
        <Col>
          <Space size="small" className="margin-bottom-medium">
            <ActionButton icon="arrows" label={t("agent.actions.expandTree")} onClick={handleExpandTreeClick} />
            <ActionButton icon="shrink" label={t("agent.actions.collapseTree")} onClick={handleCollapseTreeClick} />
          </Space>
        </Col>
        <Col flex="auto" className="right-align">
          <Input.Search
            style={{ width: "340px" }}
            enterButton
            allowClear
            placeholder={t("agent.helpers.treeSearchHint")}
            onSearch={handleSearchSubmit}
          />
        </Col>
      </Row>

      <div className="agent-tree-wrapper">
        <Tree
          className="agent-tree"
          showLine={{ showLeafIcon: false }}
          treeData={buildTreeNodes([agentTree.agent])}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          onExpand={handleTreeExpand}
        />
      </div>
    </Card>
  );
};

export default AgentTreeView;
