export enum VehicleMappingType {
  ALLIANZ = "ALLIANZ",
  COLONNADE = "COLONNADE",
  CSOB = "CSOB",
  DEFEND = "DEFEND",
  GENERALI = "GENERALI",
  KOMUNALNA = "KOMUNALNA",
  KOOPERATIVA = "KOOPERATIVA",
  UNION_MTPL = "UNION_MTPL",
  UNION_CRASH = "UNION_CRASH",
  UNIQA = "UNIQA"
}
