import { Card, Result } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import AntIcon from "../../../common/components/icons/AntIcon";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../common/types";
import { selectRouterLocationSearch } from "../../ducks";
import { getPartnerSsoAuthRedirectUrlActions } from "../ducks";

interface StateProps {
  urlSearchQuery: string;
}

interface ActionsMap {
  getPartnerSsoAuthRedirectUrl: typeof getPartnerSsoAuthRedirectUrlActions.request;
}

const PartnerSsoOAuthRedirectContainer = ({ urlSearchQuery, actions }: StateProps & ActionProps<ActionsMap>) => {
  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);
    actions.getPartnerSsoAuthRedirectUrl({
      clientId: urlParams.get("client_id"),
      responseType: urlParams.get("response_type"),
      redirectUrl: urlParams.get("redirect_uri"),
      state: urlParams.get("state"),
      codeChallenge: urlParams.get("code_challenge"),
      codeChallengeMethod: urlParams.get("code_challenge_method")
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContentWrapper>
      <Card className="card-box" title={<h2>{t("partnerSso.titles.ssoPage")}</h2>}>
        <Result
          status="info"
          icon={<AntIcon type="loading" />}
          title={t("partnerSso.titles.automaticAuthentication")}
        />
      </Card>
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      getPartnerSsoAuthRedirectUrl: getPartnerSsoAuthRedirectUrlActions.request
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(PartnerSsoOAuthRedirectContainer);
