import { AutoComplete, Empty, Spin } from "antd";
import { BaseOptionType } from "antd/lib/select";
import debounce from "lodash/debounce";
import { BaseSelectRef } from "rc-select";
import { DefaultOptionType } from "rc-select/lib/Select";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { pageRulePermissionsMap, Permission } from "../../../../common/security/authorization/enums";
import { RootState } from "../../../../common/types";
import { formatAgentName } from "../../../../common/utils/formatUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { PagesMap } from "../../../../common/utils/navigationUtils";
import { hasAllPermissions, hasAnyPermission, stripAccents } from "../../../../common/utils/utils";
import { AGENT_ROUTE_PATHS } from "../../../../modules/agent/paths";
import { selectPermissions } from "../../../../modules/auth/ducks";
import { CLIENT_ROUTE_PATHS } from "../../../../modules/client/paths";
import { CONTRACT_ROUTE_PATHS } from "../../../../modules/contract/paths";
import { requests } from "../../../../modules/globalsearch/api";
import {
  deleteStateSearchResultAction,
  searchGloballyActions,
  selectGlobalSearchResult
} from "../../../../modules/globalsearch/ducks";
import { GlobalSearchType } from "../../../../modules/globalsearch/enums";
import { GlobalSearchResult } from "../../../../modules/globalsearch/types";
import { NavigationGlobalSearchMap } from "../../../../modules/globalsearch/utils";
import { ADMIN_USER_ROUTE_PATHS } from "../../../../modules/user/paths";
import t from "../../../i18n";

const HeaderGlobalSearch = () => {
  const inputRef = useRef<BaseSelectRef>();

  const navigate = useNavigate();

  const [autocompleteValue, setAutocompleteValue] = useState<string>();
  const [autocompleteOptions, setAutocompleteOptions] = useState<DefaultOptionType[]>([]);

  const searchResult = useSelector<RootState, GlobalSearchResult>(selectGlobalSearchResult);
  const accountPermissions = useSelector<RootState, Permission[]>(selectPermissions);

  const inProgress = useRequestFinishedCallback([requests.SEARCH_GLOBALLY]);

  const availablePages = useMemo(
    () =>
      NavigationGlobalSearchMap.filter(item => {
        if (item.config) {
          return hasAllPermissions(accountPermissions, item.config.permissions);
        } else {
          const permissionsObject = pageRulePermissionsMap.get(item.path);
          return (
            !permissionsObject ||
            (permissionsObject.isAnyPermissionRequired
              ? hasAnyPermission(accountPermissions, permissionsObject.permissions)
              : hasAllPermissions(accountPermissions, permissionsObject.permissions))
          );
        }
      }),
    [accountPermissions]
  );

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          searchGlobally: searchGloballyActions.request,
          deleteStateSearchResult: deleteStateSearchResultAction
        },
        dispatch
      ),
    [dispatch]
  );

  useEffect(() => {
    const result: DefaultOptionType[] = [];

    if (searchResult.clients.length > 0) {
      result.push({
        label: t("globalSearch.category.clients"),
        options: searchResult.clients.map(client => ({
          value: client.id,
          type: GlobalSearchType.CLIENT,
          label: (
            <span className="header-search-text">
              <span>{client.aggregatedName}</span>
              <br />
              <span className="header-search-subtext">{client.identifier}</span>
            </span>
          )
        }))
      });
    }

    if (searchResult.contracts.length > 0) {
      result.push({
        label: t("globalSearch.category.contracts"),
        options: searchResult.contracts.map(contract => ({
          value: contract.id,
          type: GlobalSearchType.CONTRACT,
          label: (
            <span className="header-search-text">
              <span>
                {contract.contractNumber || contract.secondaryContractNumber} | {contract.policyHolder.aggregatedName}
              </span>
              <br />
              <span className="header-search-subtext">
                {contract.product.name} | {contract.institution.name}
              </span>
            </span>
          )
        }))
      });
    }

    if (searchResult.agents.length > 0) {
      result.push({
        label: t("globalSearch.category.agents"),
        options: searchResult.agents.map(agent => ({
          value: agent.id,
          type: GlobalSearchType.AGENT,
          label: (
            <span className="header-search-text">
              <span>{formatAgentName(agent)}</span>
              <br />
              <span className="header-search-subtext">{agent.identifier}</span>
            </span>
          )
        }))
      });
    }

    if (searchResult.users.length > 0) {
      result.push({
        label: t("globalSearch.category.users"),
        options: searchResult.users.map(user => ({
          value: user.id,
          type: GlobalSearchType.USER,
          label: (
            <span className="header-search-text">
              <span>{user.name}</span>
              <br />
              <span className="header-search-subtext">{user.email || user.emailToConfirm}</span>
            </span>
          )
        }))
      });
    }

    const strippedKeyword = stripAccents(searchResult.keyword)?.toLowerCase();
    const foundPages = availablePages.filter(page => page.keyword.includes(strippedKeyword));

    if (foundPages.length > 0) {
      result.push({
        label: t("globalSearch.category.navigation"),
        options: foundPages.map(page => ({
          value: page.path,
          type: GlobalSearchType.NAVIGATION,
          label: (
            <span className="header-search-text">
              <span>{page.config?.title || PagesMap.get(page.path)?.title}</span>
            </span>
          )
        }))
      });
    }

    setAutocompleteOptions(result);
  }, [searchResult]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchSectionClick = (): void => {
    inputRef.current?.focus();
  };

  const handleAutocompleteSearchDebounced = useMemo(
    () =>
      debounce((keyword: string): void => {
        if (keyword?.length >= 3) {
          actions.searchGlobally({ keyword });
        } else {
          setAutocompleteOptions([]);
        }
      }, 500),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleAutocompleteSearch = useCallback(
    (value: string): void => {
      handleAutocompleteSearchDebounced(value);
    },
    [handleAutocompleteSearchDebounced]
  );

  const handleAutocompleteSelect = (value: string, option: BaseOptionType): void => {
    switch (option.type) {
      case GlobalSearchType.CLIENT:
        navigate(generatePath(CLIENT_ROUTE_PATHS.detail.to, { id: value }));
        break;
      case GlobalSearchType.CONTRACT:
        navigate(generatePath(CONTRACT_ROUTE_PATHS.detail.to, { id: value }));
        break;
      case GlobalSearchType.AGENT:
        navigate(generatePath(AGENT_ROUTE_PATHS.detail.to, { id: value }));
        break;
      case GlobalSearchType.USER:
        navigate(generatePath(ADMIN_USER_ROUTE_PATHS.detail.to, { id: value }));
        break;
      case GlobalSearchType.NAVIGATION:
        navigate(generatePath(value));
        break;
    }

    handleAutocompleteClear();
  };

  const handleAutocompleteClear = (): void => {
    setAutocompleteOptions([]);
    setAutocompleteValue(null);
    actions.deleteStateSearchResult();
  };

  return (
    <div className="header-global-search" onClick={handleSearchSectionClick}>
      <AntIcon type="search" className="cursor-pointer search-icon" />
      <AutoComplete
        allowClear
        ref={inputRef}
        className="search-input"
        size="small"
        value={autocompleteValue}
        options={autocompleteOptions}
        placeholder={t("globalSearch.placeholder")}
        dropdownMatchSelectWidth={false}
        notFoundContent={
          inProgress ? (
            <div className="center-align margin-large">
              <Spin spinning size="large" />
            </div>
          ) : (
            <Empty description={t("globalSearch.noData")} />
          )
        }
        getPopupContainer={trigger => trigger.parentElement}
        onChange={setAutocompleteValue}
        onSearch={handleAutocompleteSearch}
        onSelect={handleAutocompleteSelect}
        onClear={handleAutocompleteClear}
      />
    </div>
  );
};

export default HeaderGlobalSearch;
