import { Button, Col, Form, Input, Popconfirm, Row, Select } from "antd";
import React, { useEffect } from "react";
import t from "../../../../app/i18n";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import AntIcon from "../../../../common/components/icons/AntIcon";
import PopconfirmDeleteIcon from "../../../../common/components/icons/PopconfirmDeleteIcon";
import ItemCreatedUpdatedInfoView from "../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { rowGutter } from "../../../../common/constants";
import AddressForm from "../../../../common/modules/address/AddressForm";
import {
  selectStandardProps,
  selectTagsStandardProps,
  upperCaseStringNormalizeFunction,
  useFormErrorHandler
} from "../../../../common/utils/formUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { createInstitutionActions, deleteInstitutionActions, updateInstitutionActions } from "../../ducks";
import { InstitutionEnum, InstitutionType, institutionTypeTMap } from "../../enums";
import { CreateUpdateInstitution, Institution } from "../../types";
import InstitutionTypeTag from "../InstitutionTypeTag";

interface Props {
  institution?: Institution;
  onCreate?: typeof createInstitutionActions.request;
  onUpdate?: typeof updateInstitutionActions.request;
  onDelete?: typeof deleteInstitutionActions.request;
}

const InstitutionForm = ({ institution, onCreate, onUpdate, onDelete }: Props) => {
  const [form] = Form.useForm<CreateUpdateInstitution>();
  useFormErrorHandler(form, "institution.attrs", [
    institution ? requests.UPDATE_INSTITUTION : requests.CREATE_INSTITUTION
  ]);

  useEffect(() => institution && form.setFieldsValue(institution), [institution, form]);

  const handleFormFinish = (values: CreateUpdateInstitution): void => {
    institution ? onUpdate({ id: institution.id, object: values }) : onCreate(values);
  };

  const handleDeleteClick = (): void => {
    onDelete({ id: institution.id });
  };

  const colSpan = 6;
  const colSpanSmall = 3;

  return (
    <>
      <ItemCreatedUpdatedInfoView item={institution} className="margin-bottom-medium" />

      <Form form={form} layout="vertical" onFinish={handleFormFinish} name="institutionUpdateForm">
        {institution && <HiddenInput name="optimisticLockVersion" />}

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item name="type" label={t("institution.enums.institutionType._label")} rules={[validations.notNull]}>
              <Select
                {...selectTagsStandardProps(institutionTypeTMap)}
                disabled={!!institution}
                tagRender={props => (
                  <InstitutionTypeTag
                    type={InstitutionType[props.value as string]}
                    closable={props.closable}
                    onClose={props.onClose}
                  />
                )}
                options={Object.keys(InstitutionType).map(type => ({
                  value: type,
                  label: <InstitutionTypeTag type={InstitutionType[type]} />
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="institutionEnum"
              label={t("institution.enums.institutionEnum._label")}
              rules={[validations.none]}
            >
              <Select
                {...selectStandardProps}
                allowClear
                options={Object.keys(InstitutionEnum).map(institutionEnum => ({
                  value: institutionEnum,
                  label: t("institution.enums.institutionEnum." + institutionEnum)
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="name"
              label={t("institution.attrs.name")}
              rules={[validations.notBlank, validations.size(1, 64)]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan * 2}>
            <Form.Item
              name="officialName"
              label={t("institution.attrs.officialName")}
              rules={[validations.notBlank, validations.size(1, 1024)]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="companyRegistrationNumber"
              label={t("institution.attrs.companyRegistrationNumber")}
              rules={[validations.notBlank, validations.crn]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item noStyle shouldUpdate={(prev, next) => prev.type !== next.type}>
          {({ getFieldValue }) =>
            getFieldValue("type") === InstitutionType.BANK && (
              <Row gutter={rowGutter}>
                <Col span={colSpanSmall}>
                  <Form.Item
                    name="bankCode"
                    label={t("institution.attrs.bankCode")}
                    rules={[validations.notBlank, validations.length(4), validations.numeric]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={colSpanSmall}>
                  <Form.Item
                    name="bankSwift"
                    label={t("institution.attrs.bankSwift")}
                    rules={[validations.notBlank, validations.size(4, 8)]}
                    normalize={upperCaseStringNormalizeFunction}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={colSpanSmall}>
                  <Form.Item
                    name="bankAcronym"
                    label={t("institution.attrs.bankAcronym")}
                    rules={[validations.notBlank, validations.max(10)]}
                    normalize={upperCaseStringNormalizeFunction}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            )
          }
        </Form.Item>

        <Row gutter={rowGutter}>
          <Col span={24}>
            <AddressForm form={form} rootNamePath={["address"]} label={t("institution.attrs.address")} />
          </Col>
        </Row>

        <div className="margin-top-medium">
          <Button className="margin-right-tiny" type="primary" htmlType="submit" icon={<AntIcon type="save" />}>
            {t("common.save")}
          </Button>

          {institution && (
            <Popconfirm
              title={t("institution.titles.deleteConfirm")}
              icon={<PopconfirmDeleteIcon />}
              okText={t("common.yes")}
              cancelText={t("common.no")}
              okType="danger"
              onConfirm={handleDeleteClick}
            >
              <Button icon={<AntIcon type="delete" />} danger>
                {t("common.delete")}
              </Button>
            </Popconfirm>
          )}
        </div>
      </Form>
    </>
  );
};

export default InstitutionForm;
