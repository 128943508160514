import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Row } from "antd";
import React, { useEffect } from "react";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../common/constants";
import {
  datePickerClearableProps,
  disableDatePickerFuture,
  momentToIsoDateString,
  toMoment
} from "../../../../../common/utils/formUtils";
import { validationConstants, validations } from "../../../../../common/utils/validationUtils";
import AgentSelect from "../../../../enumerations/components/form/AgentSelect";
import CommissionsLevelSelect from "../../../../enumerations/components/form/CommissionsLevelSelect";
import { AgentFilterPageRequest, AgentFilterPageResult } from "../../../types";

interface Props {
  filter: AgentFilterPageResult;
  onFilterSubmit: (filter: AgentFilterPageRequest) => void;
}

const AgentListFilterView = ({ filter, onFilterSubmit }: Props) => {
  const [form] = Form.useForm<AgentFilterPageRequest>();

  useEffect(() => {
    if (filter) {
      form.setFieldsValue({
        keyword: filter.keyword,
        commissionsSettingsLevelIds: filter.commissionsSettingsLevelIds || [],
        agentCreatedAtDateMin: toMoment(filter.agentCreatedAtDateMin),
        rootAgentId: filter.rootAgentId,
        onlyDirectSubordinates: filter.onlyDirectSubordinates,
        includeDeactivated: filter.includeDeactivated,
        includeRepresentatives: filter.includeRepresentatives,
        includeNonGainers: filter.includeNonGainers
      });
    }
  }, [filter, form]);

  const handleFilterSubmit = (filter: AgentFilterPageRequest): void => {
    onFilterSubmit({
      ...filter,
      agentCreatedAtDateMin: momentToIsoDateString(toMoment(filter.agentCreatedAtDateMin))
    });
  };

  const handleFilterClear = (): void => {
    form.resetFields();
    handleFilterSubmit(form.getFieldsValue());
  };

  const colSpan = 4;

  return (
    <Card
      className="card-box"
      title={<h2>{t("agent.titles.list")}</h2>}
      extra={<ActionTextIcon icon="reload" color="red" onClick={handleFilterClear} text={t("common.resetFilter")} />}
    >
      <Form form={form} layout="vertical" onFinish={handleFilterSubmit} name="agentFilterForm">
        <Row gutter={rowGutter} justify="start">
          <Col span={colSpan + 1}>
            <Form.Item
              name="keyword"
              label={t("common.searchKeyword")}
              rules={[
                validations.size(
                  validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
                  validationConstants.SEARCH_KEYWORD_MAX_LENGTH
                )
              ]}
            >
              <Input allowClear placeholder={t("agent.helpers.searchHint")} />
            </Form.Item>
          </Col>

          <Col span={colSpan * 2}>
            <CommissionsLevelSelect
              formItemProps={{
                name: "commissionsSettingsLevelIds",
                label: t("agent.filter.commissionsSettingsLevelIds"),
                rules: [validations.none]
              }}
              selectProps={{
                allowClear: true,
                mode: "multiple",
                maxTagCount: "responsive"
              }}
              optionsProps={{ renderOnlyLevelCodeTag: true }}
            />
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="agentCreatedAtDateMin"
              label={t("agent.filter.agentCreatedAtDateMin")}
              rules={[validations.notFuture]}
            >
              <DatePicker {...datePickerClearableProps} disabledDate={disableDatePickerFuture} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter} justify="start">
          <Col span={colSpan + 1}>
            <AgentSelect
              formItemProps={{
                name: "rootAgentId",
                label: t("agent.filter.rootAgentId"),
                rules: [validations.none]
              }}
              selectProps={{ allowClear: true }}
            />
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="onlyDirectSubordinates"
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox>{t("agent.filter.onlyDirectSubordinates")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="includeDeactivated"
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue
            >
              <Checkbox>{t("agent.filter.includeDeactivated")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="includeRepresentatives"
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue
            >
              <Checkbox>{t("agent.filter.includeRepresentatives")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="includeNonGainers"
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue
            >
              <Checkbox>{t("agent.filter.includeNonGainers")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpan - 1} className="no-title-space right-align">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default AgentListFilterView;
