import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { selectIsUserAuthenticated } from "../../../modules/auth/ducks";
import { DASHBOARD_ROUTE_PATHS } from "../../../modules/dashboard/paths";
import { RootState } from "../../types";

interface Props {
  component: ReactElement;
}

const AnonymousWrapper = ({ component }: Props) => {
  const isUserAuthenticated = useSelector<RootState, boolean>(selectIsUserAuthenticated);

  return isUserAuthenticated ? <Navigate replace to={DASHBOARD_ROUTE_PATHS.homepage.to} /> : component;
};

export default AnonymousWrapper;
