import { Col, Form, Input, Modal, Row, Space } from "antd";
import React from "react";
import t from "../../../../app/i18n";
import ActionButton from "../../../../common/components/buttons/ActionButton";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { assignFinancialMediationActions } from "../../ducks";
import { FinancialMediationStatus } from "../../enums";
import { AssignFinancialMediation, FinancialMediation } from "../../types";

interface Props {
  open: boolean;
  financialMediation: FinancialMediation;
  onAssign: typeof assignFinancialMediationActions.request;
  onFormCancel: () => void;
}

const FinancialMediationAssignForm = ({ open, financialMediation, onAssign, onFormCancel }: Props) => {
  const [form] = Form.useForm<AssignFinancialMediation>();
  useFormErrorHandler(form, "financialMediation.attrs.assign", [requests.ASSIGN_FINANCIAL_MEDIATION]);

  const inProgress = useRequestFinishedCallback([requests.ASSIGN_FINANCIAL_MEDIATION], onFormCancel);

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values =>
        onAssign({
          id: financialMediation.id,
          object: {
            optimisticLockVersion: financialMediation.optimisticLockVersion,
            status: FinancialMediationStatus.ASSIGNED_TO_CONTRACT,
            contractNumbers: values.contractNumbers
          }
        })
      )
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={t("financialMediation.titles.assign")}
      cancelText={t("common.cancel")}
      okText={t("common.assign")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="financialMediationAssignForm">
        <Form.List name="contractNumbers" initialValue={[""]}>
          {(fields, { add, remove }) => (
            <>
              <Row gutter={rowGutter}>
                {fields.map(field => (
                  <Col span={6} key={field.key}>
                    <Form.Item
                      name={[field.name]}
                      label={t("financialMediation.helpers.contractNumberOrder", { number: field.name + 1 })}
                      rules={[
                        validations.notBlank,
                        validations.size(1, 64),
                        validations.noRepeatedValue("contractNumbers")
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                ))}
              </Row>

              <Space className="margin-bottom-medium">
                <ActionButton icon="plus" label={t("common.add")} disabled={fields.length >= 8} onClick={() => add()} />

                <ActionButton
                  icon="minus"
                  label={t("common.remove")}
                  disabled={fields.length <= 1}
                  onClick={() => remove(fields.length - 1)}
                />
              </Space>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default FinancialMediationAssignForm;
