import React from "react";
import { Role } from "../../../common/security/authorization/enums";
import HeaderNotificationView from "../header/notification/HeaderNotificationView";
import HeaderGlobalSearch from "../header/search/HeaderGlobalSearch";
import HeaderUserMenuView from "../header/user/HeaderUserMenuView";

interface Props {
  selectedAccountRole: Role;
}

const HeaderMenu = (props: Props) => (
  <>
    {props.selectedAccountRole === Role.AGENT && <HeaderGlobalSearch />}
    <div className="header__right-side">
      {props.selectedAccountRole === Role.AGENT && <HeaderNotificationView />}
      <HeaderUserMenuView />
    </div>
  </>
);

export default HeaderMenu;
