import React from "react";
import { Route, Routes } from "react-router-dom";
import { WILDCARD_ROUTE } from "../../common/constants";
import NotFound from "../../common/pages/NotFound/NotFound";
import AuthenticatedWrapper from "../../common/security/authentication/AuthenticatedWrapper";
import LoginWrapper from "../../common/security/authentication/LoginWrapper";
import LoginContainer from "./containers/LoginContainer";

const routes = () => (
  <Routes>
    <Route index element={<LoginWrapper component={<LoginContainer />} />} />
    <Route path={WILDCARD_ROUTE} element={<AuthenticatedWrapper component={<NotFound />} />} />
  </Routes>
);

export default routes;
