import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import AnonymousContentWrapper from "../../../common/modules/wrappers/AnonymousContentWrapper";
import { ActionProps, EntityIdObject, RootState } from "../../../common/types";
import { DASHBOARD_ROUTE_PATHS } from "../../dashboard/paths";
import { selectRouterLocationSearchParam } from "../../ducks";
import ConfirmUserForm from "../components/forms/ConfirmUserForm";
import { userConfirmUserActions, userSendTotpCodeViaSmsActions } from "../ducks";
import { ConfirmUser } from "../types";

interface StateProps {
  token: string;
  name: string;
  phone: string;
  requirePassword: boolean;
}

interface ActionsMap {
  sendTotpCodeViaSms: typeof userSendTotpCodeViaSmsActions.request;
  confirmUser: typeof userConfirmUserActions.request;
}

type Props = StateProps & ActionProps<ActionsMap>;

const ConfirmUserContainer = (props: Props) => {
  const { id } = useParams<EntityIdObject>();
  const navigate = useNavigate();

  useEffect(() => {
    const { token, name } = props;
    if (!token || !name) {
      navigate(DASHBOARD_ROUTE_PATHS.homepage.to, { replace: true });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSendTotpCodeViaSmsClick = (): void => {
    props.actions.sendTotpCodeViaSms({ id, object: { token: props.token } });
  };

  const handleConfirmUserFormSubmit = (user: ConfirmUser): void => {
    props.actions.confirmUser({ id, object: user });
  };

  return (
    <AnonymousContentWrapper>
      <ConfirmUserForm
        confirmUserParams={{
          token: props.token,
          name: props.name,
          phone: props.phone,
          requirePassword: props.requirePassword
        }}
        onSendTotpCodeViaSmsClick={handleSendTotpCodeViaSmsClick}
        onFormSubmit={handleConfirmUserFormSubmit}
      />
    </AnonymousContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  token: selectRouterLocationSearchParam(state, "token"),
  name: selectRouterLocationSearchParam(state, "name"),
  phone: selectRouterLocationSearchParam(state, "phone"),
  requirePassword: selectRouterLocationSearchParam(state, "requirePassword") === "true"
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      sendTotpCodeViaSms: userSendTotpCodeViaSmsActions.request,
      confirmUser: userConfirmUserActions.request
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmUserContainer);
