import { Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import t from "../../../../../app/i18n";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import TableActionsView from "../../../../../common/components/views/TableActionsView";
import { TableSizes } from "../../../../../common/constants";
import { formatLocaleDateTime } from "../../../../../common/utils/formatUtils";
import { tableStandardProps } from "../../../../../common/utils/utils";
import { UserProfile, UserTotpDevice } from "../../../types";

interface Props {
  user: UserProfile;
  totpDevices: UserTotpDevice[];
  onCreateClick: () => void;
  onUpdateClick: (totpDevice: UserTotpDevice) => void;
  onDeleteClick: (totpDevice: UserTotpDevice) => void;
}

const UserTotpDeviceTableView = ({ user, totpDevices, ...actions }: Props) => {
  const columns: ColumnsType<UserTotpDevice> = [
    {
      key: "createdAt",
      title: t("user.totpDevice.attrs.createdAt"),
      width: 160,
      render: (_, record) => formatLocaleDateTime(record.createdAt)
    },
    {
      key: "name",
      title: t("user.totpDevice.attrs.name"),
      width: 255,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.name}</Ellipsis>
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 185,
      render: (_, record) => (
        <TableActionsView
          actions={[
            {
              color: "blue",
              text: t("common.edit"),
              icon: "edit",
              onClick: () => actions.onUpdateClick(record)
            },
            {
              color: "red",
              text: t("common.remove"),
              icon: "delete",
              onClick: () => actions.onDeleteClick(record)
            }
          ]}
        />
      )
    }
  ];

  return (
    <>
      <Table<UserTotpDevice>
        {...tableStandardProps()}
        columns={columns}
        scroll={{ x: TableSizes.SMALL }}
        dataSource={totpDevices}
        pagination={false}
      />

      <Button
        type="primary"
        size="small"
        icon={<AntIcon type="plus" />}
        className="margin-top-small"
        onClick={actions.onCreateClick}
      >
        {t("common.add")}
      </Button>
    </>
  );
};

export default UserTotpDeviceTableView;
