import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { ActionProps, EntityIdObject, RootState } from "../../../common/types";
import { DASHBOARD_ROUTE_PATHS } from "../../dashboard/paths";
import { selectRouterLocationSearchParam } from "../../ducks";
import { userUpdateEmailActions } from "../ducks";

interface StateProps {
  token: string;
}

interface ActionsMap {
  updateUserEmail: typeof userUpdateEmailActions.request;
}

type Props = StateProps & ActionProps<ActionsMap>;

const UpdateEmailContainer = (props: Props) => {
  const { id } = useParams<EntityIdObject>();
  const navigate = useNavigate();

  useEffect(() => {
    const { token } = props;
    if (!token) {
      navigate(DASHBOARD_ROUTE_PATHS.homepage.to, { replace: true });
    }
    props.actions.updateUserEmail({
      id,
      object: { token }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // TODO zrejme by bolo dobre zobrazit aspon nejaky napis na stranke
  return null;
};

const mapStateToProps = (state: RootState): StateProps => ({
  token: selectRouterLocationSearchParam(state, "token")
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      updateUserEmail: userUpdateEmailActions.request
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(UpdateEmailContainer);
