import { Col, Form, Input, Modal, Row } from "antd";
import React, { useEffect } from "react";
import t from "../../../../app/i18n";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { userUpdateUserTotpDeviceActions } from "../../ducks";
import { UpdateUserTotpDevice, UserProfile, UserTotpDevice } from "../../types";

interface Props {
  user: UserProfile;
  totpDevice: UserTotpDevice;
  onUpdate: typeof userUpdateUserTotpDeviceActions.request;
  onFormCancel: () => void;
}

const UpdateUserTotpDeviceForm = ({ user, totpDevice, onUpdate, onFormCancel }: Props) => {
  const [form] = Form.useForm<UpdateUserTotpDevice>();
  useFormErrorHandler(form, "user.totpDevice.attrs", [requests.USER_UPDATE_USER_TOTP_DEVICE]);

  useEffect(() => {
    if (totpDevice) {
      form.setFieldsValue({
        optimisticLockVersion: totpDevice.optimisticLockVersion,
        name: totpDevice.name
      });
    }
  }, [totpDevice, form]);

  const inProgress = useRequestFinishedCallback([requests.USER_UPDATE_USER_TOTP_DEVICE], onFormCancel);

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        onUpdate({ id1: user.id, id2: totpDevice.id, object: values });
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.SMALL}
      open={!!totpDevice}
      title={t("user.totpDevice.titles.update")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="updateUserTotpDeviceForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item
              name="name"
              label={t("user.totpDevice.attrs.name")}
              rules={[validations.notNull, validations.size(1, 64)]}
            >
              <Input placeholder={t("user.totpDevice.helpers.nameHint")} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default UpdateUserTotpDeviceForm;
