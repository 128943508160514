import { Tag } from "antd";
import React from "react";
import t from "../../../../app/i18n";

const NotFinishedBatchTag = () => (
  <Tag color="orange" style={{ marginRight: 0 }}>
    {t("commissions.batch.helpers.notFinishedBatch")}
  </Tag>
);

export default NotFinishedBatchTag;
