import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { EntityObject, NormalizedError, RootState } from "../../../common/types";
import { initSearchPageResult } from "../../../common/utils/apiUtils";
import messageUtils from "../../../common/utils/messageUtils";
import { contains, openBlobFile, replaceInArray } from "../../../common/utils/utils";
import { changeRunningRequestKeyAction } from "../../ducks";
import api from "./api";
import {
  CreateVehicleMapping,
  UpdateVehicleMappingList,
  VehicleBrandAndModelReducerState,
  VehicleBrandMappingFilterPageResult,
  VehicleMapping,
  VehicleMappingData,
  VehicleMappingFilterPageRequest,
  VehicleMappingTypesRequest,
  VehicleModelMappingFilterPageResult
} from "./types";

/**
 * ACTIONS
 */
export const getVehicleMappingsActions = createAsyncAction(
  "vehicle-mapping/GET_REQUEST",
  "vehicle-mapping/GET_SUCCESS",
  "vehicle-mapping/GET_FAILURE"
)<VehicleMappingTypesRequest, VehicleMappingData[], NormalizedError>();

export const remapVehicleMappingsActions = createAsyncAction(
  "vehicle-mapping/REMAP_REQUEST",
  "vehicle-mapping/REMAP_SUCCESS",
  "vehicle-mapping/REMAP_FAILURE"
)<VehicleMappingTypesRequest, void, NormalizedError>();

export const mapVehicleMappingsUpdatesActions = createAsyncAction(
  "vehicle-mapping/UPDATE_REQUEST",
  "vehicle-mapping/UPDATE_SUCCESS",
  "vehicle-mapping/UPDATE_FAILURE"
)<VehicleMappingTypesRequest, void, NormalizedError>();

export const importVehicleMappingsActions = createAsyncAction(
  "vehicle-mapping/IMPORT_REQUEST",
  "vehicle-mapping/IMPORT_SUCCESS",
  "vehicle-mapping/IMPORT_FAILURE"
)<FormData, void, NormalizedError>();

export const exportVehicleMappingsActions = createAsyncAction(
  "vehicle-mapping/EXPORT_REQUEST",
  "vehicle-mapping/EXPORT_SUCCESS",
  "vehicle-mapping/EXPORT_FAILURE"
)<void, void, NormalizedError>();

export const filterVehicleBrandMappingsActions = createAsyncAction(
  "vehicle-brand/FILTER_REQUEST",
  "vehicle-brand/FILTER_SUCCESS",
  "vehicle-brand/FILTER_FAILURE"
)<VehicleMappingFilterPageRequest, VehicleBrandMappingFilterPageResult, NormalizedError>();

export const createVehicleBrandMappingActions = createAsyncAction(
  "vehicle-brand/CREATE_REQUEST",
  "vehicle-brand/CREATE_SUCCESS",
  "vehicle-brand/CREATE_FAILURE"
)<CreateVehicleMapping, VehicleMapping, NormalizedError>();

export const updateVehicleBrandMappingsActions = createAsyncAction(
  "vehicle-brand/UPDATE_REQUEST",
  "vehicle-brand/UPDATE_SUCCESS",
  "vehicle-brand/UPDATE_FAILURE"
)<UpdateVehicleMappingList, VehicleBrandMappingFilterPageResult, NormalizedError>();

export const filterVehicleModelMappingsActions = createAsyncAction(
  "vehicle-model/FILTER_REQUEST",
  "vehicle-model/FILTER_SUCCESS",
  "vehicle-model/FILTER_FAILURE"
)<EntityObject<VehicleMappingFilterPageRequest>, VehicleBrandMappingFilterPageResult, NormalizedError>();

export const createVehicleModelMappingActions = createAsyncAction(
  "vehicle-model/CREATE_REQUEST",
  "vehicle-model/CREATE_SUCCESS",
  "vehicle-model/CREATE_FAILURE"
)<EntityObject<CreateVehicleMapping>, VehicleMapping, NormalizedError>();

export const updateVehicleModelMappingsActions = createAsyncAction(
  "vehicle-model/UPDATE_REQUEST",
  "vehicle-model/UPDATE_SUCCESS",
  "vehicle-model/UPDATE_FAILURE"
)<EntityObject<UpdateVehicleMappingList>, VehicleBrandMappingFilterPageResult, NormalizedError>();

export const deleteStateVehicleMappingsDataAction = createAction("vehicle-mapping/DELETE_STATE_DATA")<void>();

const actions = {
  getVehicleMappingsActions,
  remapVehicleMappingsActions,
  mapVehicleMappingsUpdatesActions,
  importVehicleMappingsActions,
  exportVehicleMappingsActions,
  filterVehicleBrandMappingsActions,
  createVehicleBrandMappingActions,
  updateVehicleBrandMappingsActions,
  filterVehicleModelMappingsActions,
  createVehicleModelMappingActions,
  updateVehicleModelMappingsActions,
  deleteStateVehicleMappingsDataAction
};

export type VehicleBrandAndModelAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: VehicleBrandAndModelReducerState = {
  mappings: [],
  currentBrandsPage: {
    ...initSearchPageResult<VehicleMapping>(),
    queriedMappings: [],
    excludeMapped: false
  },
  currentModelsPage: {
    ...initSearchPageResult<VehicleMapping>(),
    queriedMappings: [],
    excludeMapped: false,
    brand: null
  }
};

const mappingsReducer = createReducer(initialState.mappings)
  .handleAction(getVehicleMappingsActions.success, (_, { payload }) => payload)
  .handleAction([getVehicleMappingsActions.failure, deleteStateVehicleMappingsDataAction], () => initialState.mappings);

const currentBrandsPageReducer = createReducer(initialState.currentBrandsPage)
  .handleAction(
    [filterVehicleBrandMappingsActions.success, updateVehicleBrandMappingsActions.success],
    (_, { payload }) => payload
  )
  .handleAction(
    [filterVehicleBrandMappingsActions.failure, deleteStateVehicleMappingsDataAction],
    () => initialState.currentBrandsPage
  );

const currentModelsPageReducer = createReducer(initialState.currentModelsPage)
  .handleAction(
    [filterVehicleModelMappingsActions.success, updateVehicleModelMappingsActions.success],
    (_, { payload }) => payload
  )
  .handleAction(
    [filterVehicleModelMappingsActions.failure, deleteStateVehicleMappingsDataAction],
    () => initialState.currentModelsPage
  );

export const vehicleBrandAndModelReducer = combineReducers<VehicleBrandAndModelReducerState>({
  mappings: mappingsReducer,
  currentBrandsPage: currentBrandsPageReducer,
  currentModelsPage: currentModelsPageReducer
});

/**
 * SELECTORS
 */
const selectVehicleBrandAndModel = (state: RootState): VehicleBrandAndModelReducerState => state.vehicle.brandAndModel;

export const selectVehicleMappings = (state: RootState): VehicleMappingData[] =>
  selectVehicleBrandAndModel(state).mappings;
export const selectVehicleBrandsCurrentPage = (state: RootState): VehicleBrandMappingFilterPageResult =>
  selectVehicleBrandAndModel(state).currentBrandsPage;
export const selectVehicleModelsCurrentPage = (state: RootState): VehicleModelMappingFilterPageResult =>
  selectVehicleBrandAndModel(state).currentModelsPage;

/**
 * SAGAS
 */
function* getVehicleMappings({ payload }: ReturnType<typeof getVehicleMappingsActions.request>) {
  try {
    const response: AxiosResponse<VehicleMappingData[]> = yield call(api.getVehicleMappings, payload);

    const previousMappings = [];
    const queriedMappings = payload.queriedMappings ? payload.queriedMappings : [];
    ([...(yield select(selectVehicleMappings))] as VehicleMappingData[]).forEach(mapping => {
      if (!contains(queriedMappings, mapping.type)) {
        previousMappings.push(mapping);
      }
    });

    yield put(getVehicleMappingsActions.success([...previousMappings, ...response.data]));
  } catch (error) {
    yield put(getVehicleMappingsActions.failure(error));
  }
}

function* remapVehicleMappings({ payload }: ReturnType<typeof remapVehicleMappingsActions.request>) {
  try {
    yield call(api.remapVehicleMappings, payload);
    yield put(remapVehicleMappingsActions.success());
    yield put(getVehicleMappingsActions.request({ queriedMappings: payload.queriedMappings }));
  } catch (error) {
    yield put(remapVehicleMappingsActions.failure(error));
  }
}

function* mapVehicleMappingsUpdates({ payload }: ReturnType<typeof mapVehicleMappingsUpdatesActions.request>) {
  try {
    yield call(api.mapVehicleMappingsUpdates, payload);
    yield call(api.unmapObsoleteVehicleMappings, payload);
    yield put(mapVehicleMappingsUpdatesActions.success());
    yield put(getVehicleMappingsActions.request({ queriedMappings: payload.queriedMappings }));
  } catch (error) {
    yield put(mapVehicleMappingsUpdatesActions.failure(error));
  }
}

function* importVehicleMappings({ payload }: ReturnType<typeof importVehicleMappingsActions.request>) {
  try {
    yield call(api.importVehicleMappings, payload);
    yield put(importVehicleMappingsActions.success());
  } catch (error) {
    yield put(importVehicleMappingsActions.failure(error));
  }
}

function* exportVehicleMappings() {
  try {
    const response: AxiosResponse<Blob> = yield call(api.exportVehicleMappings);
    openBlobFile(response, true);
    yield put(exportVehicleMappingsActions.success());
  } catch (error) {
    yield put(exportVehicleMappingsActions.failure(error));
  }
}

function* filterVehicleBrands({ payload }: ReturnType<typeof filterVehicleBrandMappingsActions.request>) {
  try {
    const response: AxiosResponse<VehicleBrandMappingFilterPageResult> = yield call(api.filterVehicleBrands, payload);
    yield put(filterVehicleBrandMappingsActions.success(response.data));
  } catch (error) {
    yield put(filterVehicleBrandMappingsActions.failure(error));
  }
}

function* createVehicleBrand({ payload }: ReturnType<typeof createVehicleBrandMappingActions.request>) {
  try {
    const response: AxiosResponse<VehicleMapping> = yield call(api.createVehicleBrandMapping, payload);
    yield put(createVehicleBrandMappingActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();

    const currentPage: VehicleBrandMappingFilterPageResult = yield { ...select(selectVehicleBrandsCurrentPage) };
    yield put(
      filterVehicleBrandMappingsActions.request({
        keyword: currentPage.keyword,
        pageSize: currentPage.pageSize,
        pageIndex: currentPage.pageIndex,
        queriedMappings: currentPage.queriedMappings,
        excludeMapped: currentPage.excludeMapped
      })
    );
  } catch (error) {
    yield put(createVehicleBrandMappingActions.failure(error));
  }
}

function* updateVehicleBrands({ payload }: ReturnType<typeof updateVehicleBrandMappingsActions.request>) {
  try {
    const response: AxiosResponse<VehicleMapping[]> = yield call(api.updateVehicleBrandMappings, payload);

    const currentPage: VehicleBrandMappingFilterPageResult = yield { ...select(selectVehicleBrandsCurrentPage) };
    let pageData = [...currentPage.pageData];
    response.data.forEach(
      updatedItem =>
        (pageData = replaceInArray(
          pageData,
          item => item.id === updatedItem.id,
          () => updatedItem
        ))
    );
    yield put(updateVehicleBrandMappingsActions.success({ ...currentPage, pageData }));

    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(updateVehicleBrandMappingsActions.failure(error));
  }
}

function* filterVehicleModels({ payload }: ReturnType<typeof filterVehicleModelMappingsActions.request>) {
  try {
    const response: AxiosResponse<VehicleBrandMappingFilterPageResult> = yield call(api.filterVehicleModels, payload);
    yield put(filterVehicleModelMappingsActions.success(response.data));
  } catch (error) {
    yield put(filterVehicleModelMappingsActions.failure(error));
  }
}

function* createVehicleModel({ payload }: ReturnType<typeof createVehicleModelMappingActions.request>) {
  try {
    const response: AxiosResponse<VehicleMapping> = yield call(api.createVehicleModelMapping, payload);
    yield put(createVehicleModelMappingActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();

    const currentPage: VehicleModelMappingFilterPageResult = yield { ...select(selectVehicleModelsCurrentPage) };
    yield put(
      filterVehicleModelMappingsActions.request({
        id: currentPage.brand.id,
        object: {
          keyword: currentPage.keyword,
          pageSize: currentPage.pageSize,
          pageIndex: currentPage.pageIndex,
          queriedMappings: currentPage.queriedMappings,
          excludeMapped: currentPage.excludeMapped
        }
      })
    );
  } catch (error) {
    yield put(createVehicleModelMappingActions.failure(error));
  }
}

function* updateVehicleModels({ payload }: ReturnType<typeof updateVehicleModelMappingsActions.request>) {
  try {
    const response: AxiosResponse<VehicleMapping[]> = yield call(api.updateVehicleModelMappings, payload);

    const currentPage: VehicleModelMappingFilterPageResult = yield { ...select(selectVehicleModelsCurrentPage) };
    let pageData = [...currentPage.pageData];
    response.data.forEach(
      updatedItem =>
        (pageData = replaceInArray(
          pageData,
          item => item.id === updatedItem.id,
          () => updatedItem
        ))
    );
    yield put(updateVehicleModelMappingsActions.success({ ...currentPage, pageData }));

    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(updateVehicleModelMappingsActions.failure(error));
  }
}

export function* vehicleBrandAndModelSaga() {
  yield takeLatest(getVehicleMappingsActions.request, getVehicleMappings);
  yield takeLatest(remapVehicleMappingsActions.request, remapVehicleMappings);
  yield takeLatest(mapVehicleMappingsUpdatesActions.request, mapVehicleMappingsUpdates);
  yield takeLatest(importVehicleMappingsActions.request, importVehicleMappings);
  yield takeLatest(exportVehicleMappingsActions.request, exportVehicleMappings);

  yield takeLatest(filterVehicleBrandMappingsActions.request, filterVehicleBrands);
  yield takeLatest(createVehicleBrandMappingActions.request, createVehicleBrand);
  yield takeLatest(updateVehicleBrandMappingsActions.request, updateVehicleBrands);

  yield takeLatest(filterVehicleModelMappingsActions.request, filterVehicleModels);
  yield takeLatest(createVehicleModelMappingActions.request, createVehicleModel);
  yield takeLatest(updateVehicleModelMappingsActions.request, updateVehicleModels);
}
