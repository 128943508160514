import { Avatar, Divider, Dropdown } from "antd";
import { ItemType, MenuItemType } from "antd/lib/menu/hooks/useItems";
import { Location } from "history";
import { MenuInfo } from "rc-menu/lib/interface";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { Permission } from "../../../../common/security/authorization/enums";
import { RootState, UUID } from "../../../../common/types";
import { formatAgentAggregatedName } from "../../../../common/utils/formatUtils";
import { createMenuItem } from "../../../../common/utils/menuUtils";
import { hasPermission } from "../../../../common/utils/utils";
import AgentProfilePictureView from "../../../../modules/agent/components/views/profilepicture/AgentProfilePictureView";
import {
  hasUserMultipleAccounts,
  logoutAction,
  selectUser,
  selectUserAccount,
  switchSelectedAccountAction
} from "../../../../modules/auth/ducks";
import { selectRouterLocation } from "../../../../modules/ducks";
import { CURRENT_USER_ROUTE_PATHS } from "../../../../modules/user/paths";
import { User, UserAccount } from "../../../../modules/user/types";
import t from "../../../i18n";

const MENU_KEY = {
  LOGOUT: "logout",
  PROFILE: "profile",
  COMMISSIONS: "commissions"
};

const HeaderUserMenuView = () => {
  const user = useSelector<RootState, User>(selectUser);
  const userAccount = useSelector<RootState, UserAccount>(selectUserAccount);
  const hasMultipleAccounts = useSelector<RootState, boolean>(hasUserMultipleAccounts);
  const routerLocation = useSelector<RootState, Location>(selectRouterLocation);

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          onLogout: logoutAction,
          onSelectedAccountSwitch: switchSelectedAccountAction
        },
        dispatch
      ),
    [dispatch]
  );

  const handleMenuClick = (event: MenuInfo) => {
    const { key } = event;

    switch (key) {
      case MENU_KEY.LOGOUT:
        actions.onLogout();
        break;
      case MENU_KEY.PROFILE:
      case MENU_KEY.COMMISSIONS:
        break;
      default:
        if (key !== userAccount?.id) {
          actions.onSelectedAccountSwitch(key as UUID);
        }
        break;
    }
  };

  const userMenuItems: ItemType[] = [];

  if (userAccount?.agent) {
    userMenuItems.push(
      createMenuItem({
        path: CURRENT_USER_ROUTE_PATHS.profile.to,
        key: MENU_KEY.PROFILE,
        label: t("navigation.header.user.profile"),
        icon: <AntIcon type="user" />,
        permissions: userAccount?.permissions,
        routerLocation
      })
    );
    if (hasPermission(userAccount.permissions, Permission.PROFILE_COMMISSIONS)) {
      userMenuItems.push(
        createMenuItem({
          path: CURRENT_USER_ROUTE_PATHS.commissions.to,
          key: MENU_KEY.COMMISSIONS,
          label: t("navigation.header.user.commissions"),
          icon: <AntIcon type="euro" />,
          permissions: userAccount.permissions,
          routerLocation
        })
      );
    }
    userMenuItems.push({ type: "divider", key: "user-dropdown-divider-1" });
  }

  if (hasMultipleAccounts) {
    let path = "";
    const orderedAccounts = userAccount?.agent
      ? user.agentUserRole.userAccounts
          ?.filter(account => account.confirmed && !account.disabled)
          .sort((a, b) => a.agent.accessTreePath?.localeCompare(b.agent.accessTreePath))
          .map<MenuItemType>((account, index) => {
            let isLast = false;
            if (index === 0) {
              path = account.agent.accessTreePath;
            } else if (!account.agent.accessTreePath?.startsWith(path)) {
              isLast = true;
              path = account.agent.accessTreePath;
            }

            return {
              key: account.id,
              label: (
                <>
                  {isLast && account.agent.accessTreePath ? <Divider className="menu-item-divider" /> : undefined}
                  {account.representingAgent ? (
                    <>
                      <span>{formatAgentAggregatedName(account.agent)}</span>
                      <br />
                      <span className="representing-agent-name">
                        {formatAgentAggregatedName(account.representingAgent)}
                      </span>
                    </>
                  ) : (
                    formatAgentAggregatedName(account.agent)
                  )}
                </>
              )
            };
          })
      : user.clientUserRole?.userAccounts
          ?.filter(account => account.confirmed && !account.disabled)
          .map<MenuItemType>(account => ({ key: account.id, label: account.clients[0].aggregatedName }));

    userMenuItems.push(
      {
        type: "group",
        key: "user-dropdown-group-accounts",
        label: t("navigation.header.user.accounts"),
        children: orderedAccounts
      },
      { type: "divider", key: "user-dropdown-divider-2" }
    );
  }

  userMenuItems.push({ key: MENU_KEY.LOGOUT, label: t("navigation.header.logout"), icon: <AntIcon type="logout" /> });

  return (
    <Dropdown
      trigger={["click"]}
      overlayClassName="header-dropdown-container header-user-menu-dropdown"
      menu={{ items: userMenuItems, selectedKeys: [userAccount?.id], onClick: handleMenuClick }}
    >
      <div className="header__action header__account">
        {userAccount?.agent?.profilePicture ? (
          <AgentProfilePictureView
            className="header__account-profile-picture"
            agent={userAccount.agent}
            width={38}
            preview={false}
            showActions={false}
          />
        ) : (
          <Avatar className="header__account-avatar" size="small" icon={<AntIcon type="user" />} />
        )}

        <div className="header__account-box">
          <span className="header__account-name">{user?.name}</span>
          <span className="header__account-name">
            {formatAgentAggregatedName(userAccount?.agent) || userAccount?.clients?.[0].aggregatedName}
          </span>
        </div>
      </div>
    </Dropdown>
  );
};

export default HeaderUserMenuView;
