import { Button, Card, Col, DatePicker, Divider, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import t from "../../../../../app/i18n";
import InputAddon from "../../../../../common/components/form/addons/InputAddon";
import LabelWithPopover from "../../../../../common/components/form/labels/LabelWithPopover";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { ModalSizes, rowGutter } from "../../../../../common/constants";
import ComponentWithPermission from "../../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../common/types";
import { formatAgentName, formatLocaleCurrency, formatLocaleDate } from "../../../../../common/utils/formatUtils";
import {
  datePickerDefaultRanges,
  datePickerDefaultRangesUntilToday,
  disableDatePickerFuture,
  getDatePickerFormat,
  inputNumberIntegerStandardProps,
  mapInstitutionTreeSelectValuesToInstitutionIds,
  mapProductTreeSelectValuesToProductIds,
  momentToIsoDateString,
  resolveFormValidationError,
  selectTagsStandardProps,
  toMomentArray
} from "../../../../../common/utils/formUtils";
import { validationConstants, validations } from "../../../../../common/utils/validationUtils";
import { AffiliatePartnerBase } from "../../../../affiliate/types";
import { AgentType } from "../../../../agent/enums";
import AffiliatePartnerSelect from "../../../../enumerations/components/form/AffiliatePartnerSelect";
import AgentSelect from "../../../../enumerations/components/form/AgentSelect";
import InstitutionTreeSelect from "../../../../enumerations/components/form/InstitutionTreeSelect";
import ProductTreeSelect from "../../../../enumerations/components/form/ProductTreeSelect";
import {
  selectAffiliatePartnersEnums,
  selectInstitutionsEnums,
  selectProductGroupsEnums,
  selectSubordinateAgentsEnums
} from "../../../../enumerations/ducks";
import { AgentEnumeration, InstitutionWithSettings, ProductGroupWithProducts } from "../../../../enumerations/types";
import {
  ContractStatus,
  contractStatusTMap,
  ContractVerificationStatus,
  contractVerificationStatusTMap,
  ContractView
} from "../../../enums";
import { ContractFilterPageRequest, ContractFilterPageResult } from "../../../types";
import ContractStatusTag from "../../ContractStatusTag";
import ContractVerificationStatusTag from "../../ContractVerificationStatusTag";

interface Props {
  contractsPage: ContractFilterPageResult;
  onFilterSubmit: (filter: ContractFilterPageRequest) => void;
  onRecalculatePredictedCommissionsClick: () => void;
}

const ContractListFilterView = ({ contractsPage, onFilterSubmit, onRecalculatePredictedCommissionsClick }: Props) => {
  const [form] = Form.useForm<ContractFilterPageRequest>();

  const subordinateAgentsEnums = useSelector<RootState, AgentEnumeration[]>(selectSubordinateAgentsEnums);
  const institutionsEnums = useSelector<RootState, InstitutionWithSettings[]>(selectInstitutionsEnums);
  const productGroupsEnums = useSelector<RootState, ProductGroupWithProducts[]>(selectProductGroupsEnums);
  const affiliatePartnersEnums = useSelector<RootState, AffiliatePartnerBase[]>(selectAffiliatePartnersEnums);

  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [filterModalText, setFilterModalText] = useState<string>();

  useEffect(() => {
    setFilterModalText(modalFilterToText({ ...contractsPage }));
  }, [contractsPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        const processedValues = { ...values };

        onFilterSubmit({
          ...processedValues,
          institutionIds: mapInstitutionTreeSelectValuesToInstitutionIds(
            processedValues.institutionIds,
            institutionsEnums
          ),
          productIds: mapProductTreeSelectValuesToProductIds(processedValues.productIds, productGroupsEnums),
          createdAtMin: momentToIsoDateString(processedValues.createdAtRange?.[0]),
          createdAtMax: momentToIsoDateString(processedValues.createdAtRange?.[1]),
          createdAtRange: undefined,
          mediationReportSignDateMin: momentToIsoDateString(processedValues.mediationReportSignDateRange?.[0]),
          mediationReportSignDateMax: momentToIsoDateString(processedValues.mediationReportSignDateRange?.[1]),
          mediationReportSignDateRange: undefined,
          effectiveBeginningDateOrSignDateMin: momentToIsoDateString(
            processedValues.effectiveBeginningDateOrSignDateRange?.[0]
          ),
          effectiveBeginningDateOrSignDateMax: momentToIsoDateString(
            processedValues.effectiveBeginningDateOrSignDateRange?.[1]
          ),
          effectiveBeginningDateOrSignDateRange: undefined,
          effectiveEndDateOrLoanMaturityDateMin: momentToIsoDateString(
            processedValues.effectiveEndDateOrLoanMaturityDateRange?.[0]
          ),
          effectiveEndDateOrLoanMaturityDateMax: momentToIsoDateString(
            processedValues.effectiveEndDateOrLoanMaturityDateRange?.[1]
          ),
          effectiveEndDateOrLoanMaturityDateRange: undefined,
          cancellationDateMin: momentToIsoDateString(processedValues.cancellationDateRange?.[0]),
          cancellationDateMax: momentToIsoDateString(processedValues.cancellationDateRange?.[1]),
          cancellationDateRange: undefined,
          transferredFromOtherBroker: processedValues.transferredFromOtherBroker,
          transferredToOtherBrokerDateMin: momentToIsoDateString(
            processedValues.transferredToOtherBrokerDateRange?.[0]
          ),
          transferredToOtherBrokerDateMax: momentToIsoDateString(
            processedValues.transferredToOtherBrokerDateRange?.[1]
          ),
          transferredToOtherBrokerDateRange: undefined,
          vehicleFirstRegistrationYearMin: processedValues.vehicleFirstRegistrationYearRange?.[0]?.year(),
          vehicleFirstRegistrationYearMax: processedValues.vehicleFirstRegistrationYearRange?.[1]?.year(),
          vehicleFirstRegistrationYearRange: undefined
        });
        setFilterModalOpen(false);
      })
      .catch(resolveFormValidationError);
  };

  const handleModalCancel = (): void => {
    form.resetFields([
      ["createdAtRange"],
      ["mediationReportSignDateRange"],
      ["effectiveBeginningDateOrSignDateRange"],
      ["effectiveEndDateOrLoanMaturityDateRange"],
      ["cancellationDateRange"],
      ["transferredFromOtherBroker"],
      ["transferredToOtherBrokerDateRange"],
      ["annualPremiumOrApprovedAmountMin"],
      ["annualPremiumOrApprovedAmountMax"],
      ["vehicleFirstRegistrationYearRange"],
      ["affiliatePartnerIds"]
    ]);
    setFilterModalOpen(false);
  };

  const clearModalFilterFields = (): void => {
    form.setFieldsValue({
      signerIds: [],
      managerIds: [],
      createdAtRange: undefined,
      mediationReportSignDateRange: undefined,
      effectiveBeginningDateOrSignDateRange: undefined,
      effectiveEndDateOrLoanMaturityDateRange: undefined,
      cancellationDateRange: undefined,
      transferredFromOtherBroker: false,
      transferredToOtherBrokerDateRange: undefined,
      annualPremiumOrApprovedAmountMin: null,
      annualPremiumOrApprovedAmountMax: null,
      vehicleFirstRegistrationYearRange: undefined,
      affiliatePartnerIds: []
    } as ContractFilterPageRequest);
  };

  const clearMainFilterFields = (): void => {
    form.setFieldsValue({
      keyword: undefined,
      institutionIds: [],
      productIds: [],
      gainerIds: [],
      statuses: [],
      verificationStatuses: []
    } as ContractFilterPageRequest);
  };

  const handleModalFilterClear = (): void => {
    clearModalFilterFields();
    handleFormSubmit();
  };

  const handleFilterClear = (): void => {
    clearModalFilterFields();
    clearMainFilterFields();
    handleFormSubmit();
  };

  const modalFilterToText = (filter: ContractFilterPageRequest): string => {
    const formatDateRange = (label: string, min: string, max: string): string => {
      return `${label}: ${formatLocaleDate(min)} ~ ${formatLocaleDate(max)}`;
    };
    const params = [];

    if (filter.signerIds?.length > 0) {
      params.push(
        `${t("contract.filter.signerIds")}: ${filter.signerIds
          .map(id => subordinateAgentsEnums.find(agent => agent.id === id))
          .filter(agent => !!agent)
          .map(agent => formatAgentName(agent))
          .join(", ")}`
      );
    }

    if (filter.managerIds?.length > 0) {
      params.push(
        `${t("contract.filter.managerIds")}: ${filter.managerIds
          .map(id => subordinateAgentsEnums.find(agent => agent.id === id))
          .filter(agent => !!agent)
          .map(agent => formatAgentName(agent))
          .join(", ")}`
      );
    }

    if (filter.createdAtMin && filter.createdAtMax) {
      params.push(formatDateRange(t("contract.filter.createdAtRange"), filter.createdAtMin, filter.createdAtMax));
    }

    if (filter.mediationReportSignDateMin && filter.mediationReportSignDateMax) {
      params.push(
        formatDateRange(
          t("contract.filter.mediationReportSignDateRange"),
          filter.mediationReportSignDateMin,
          filter.mediationReportSignDateMax
        )
      );
    }

    if (filter.effectiveBeginningDateOrSignDateMin && filter.effectiveBeginningDateOrSignDateMax) {
      params.push(
        formatDateRange(
          t("contract.filter.effectiveBeginningDateOrSignDateRange"),
          filter.effectiveBeginningDateOrSignDateMin,
          filter.effectiveBeginningDateOrSignDateMax
        )
      );
    }

    if (filter.effectiveEndDateOrLoanMaturityDateMin && filter.effectiveEndDateOrLoanMaturityDateMax) {
      params.push(
        formatDateRange(
          t("contract.filter.effectiveEndDateOrLoanMaturityDateRange"),
          filter.effectiveEndDateOrLoanMaturityDateMin,
          filter.effectiveEndDateOrLoanMaturityDateMax
        )
      );
    }

    if (filter.cancellationDateMin && filter.cancellationDateMax) {
      params.push(
        formatDateRange(
          t("contract.filter.cancellationDateRange"),
          filter.cancellationDateMin,
          filter.cancellationDateMax
        )
      );
    }

    if (filter.transferredFromOtherBroker) {
      params.push(t("contract.filter.transferredFromOtherBroker"));
    }

    if (filter.transferredToOtherBrokerDateMin && filter.transferredToOtherBrokerDateMax) {
      params.push(
        formatDateRange(
          t("contract.filter.transferredToOtherBrokerDateRange"),
          filter.transferredToOtherBrokerDateMin,
          filter.transferredToOtherBrokerDateMax
        )
      );
    }

    if (filter.vehicleFirstRegistrationYearMin && filter.vehicleFirstRegistrationYearMax) {
      params.push(
        `${t("contract.filter.vehicleFirstRegistrationYearRange")}: ${filter.vehicleFirstRegistrationYearMin} ~ ${
          filter.vehicleFirstRegistrationYearMax
        }`
      );
    }

    if (filter.annualPremiumOrApprovedAmountMin && filter.annualPremiumOrApprovedAmountMax) {
      params.push(
        `${t("contract.filter.annualPremiumOrApprovedAmountRange")}: ${formatLocaleCurrency(
          filter.annualPremiumOrApprovedAmountMin
        )} ~ ${formatLocaleCurrency(filter.annualPremiumOrApprovedAmountMax)}`
      );
    } else if (filter.annualPremiumOrApprovedAmountMin) {
      params.push(
        `${t("contract.filter.attrs.annualPremiumOrApprovedAmountMin")}: ${formatLocaleCurrency(
          filter.annualPremiumOrApprovedAmountMin
        )}`
      );
    } else if (filter.annualPremiumOrApprovedAmountMax) {
      params.push(
        `${t("contract.filter.attrs.annualPremiumOrApprovedAmountMax")}: ${formatLocaleCurrency(
          filter.annualPremiumOrApprovedAmountMax
        )}`
      );
    }

    if (filter.affiliatePartnerIds?.length > 0) {
      params.push(
        `${t("contract.filter.affiliatePartners")}: ${filter.affiliatePartnerIds
          .map(id => affiliatePartnersEnums.find(ap => ap.id === id)?.name)
          .join(", ")}`
      );
    }

    return params.join(" | ");
  };

  const colSpan = 4;
  const smallColSpan = 3;

  return (
    <>
      <Card
        className="card-box"
        title={<h2>{t("contract.titles.list")}</h2>}
        extra={
          <>
            {contractsPage.report === ContractView.INTERNAL_PREDICTED_COMMISSIONS_REPORT && (
              <ComponentWithPermission permissions={[Permission.COMMISSIONS_MANAGE]}>
                <>
                  <ActionTextIcon
                    icon="euro"
                    color="orange"
                    onClick={onRecalculatePredictedCommissionsClick}
                    text={t("contract.actions.recalculatePredictedCommissions")}
                  />

                  <Divider type="vertical" />
                </>
              </ComponentWithPermission>
            )}

            <ActionTextIcon icon="reload" color="red" onClick={handleFilterClear} text={t("common.resetFilter")} />
          </>
        }
      >
        <Form form={form} name="contractsFilterForm" layout="vertical">
          <Row gutter={rowGutter} justify="center">
            <Col span={smallColSpan}>
              <Form.Item
                name="keyword"
                label={
                  <LabelWithPopover
                    label={t("contract.filter.keyword")}
                    popoverTitle={t("contract.filter.helpers.keywordPopoverTitle")}
                    popoverContent={
                      <>
                        <ul>
                          <li>{t("contract.filter.helpers.keywordPopoverSearchBy1")}</li>
                          <li>{t("contract.filter.helpers.keywordPopoverSearchBy2")}</li>
                          <li>{t("contract.filter.helpers.keywordPopoverSearchBy3")}</li>
                          <li>{t("contract.filter.helpers.keywordPopoverSearchBy4")}</li>
                        </ul>
                      </>
                    }
                  />
                }
                rules={[
                  validations.size(
                    validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
                    validationConstants.SEARCH_KEYWORD_MAX_LENGTH
                  )
                ]}
                initialValue={contractsPage.keyword}
              >
                <Input allowClear />
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <InstitutionTreeSelect
                formItemProps={{
                  name: "institutionIds",
                  label: t("contract.filter.institutions"),
                  initialValue: contractsPage.institutionIds || []
                }}
              />
            </Col>

            <Col span={colSpan}>
              <ProductTreeSelect
                formItemProps={{
                  name: "productIds",
                  label: t("contract.filter.products"),
                  initialValue: contractsPage.productIds || []
                }}
              />
            </Col>

            <Col span={colSpan}>
              <AgentSelect
                formItemProps={{
                  name: "gainerIds",
                  label: t("contract.filter.gainerIds"),
                  initialValue: contractsPage.gainerIds || []
                }}
                selectProps={{ mode: "multiple", maxTagCount: "responsive", allowClear: true }}
                optionsProps={{ extendedFilter: { form } }}
              />
            </Col>

            <Col span={smallColSpan}>
              <Form.Item
                name="statuses"
                label={t("contract.filter.statuses")}
                initialValue={contractsPage.statuses || []}
              >
                <Select
                  {...selectTagsStandardProps(contractStatusTMap)}
                  allowClear
                  mode="multiple"
                  maxTagCount="responsive"
                  tagRender={props => (
                    <ContractStatusTag
                      status={ContractStatus[props.value as string]}
                      closable={props.closable}
                      onClose={props.onClose}
                    />
                  )}
                  options={Object.keys(ContractStatus).map(status => ({
                    value: status,
                    label: <ContractStatusTag status={ContractStatus[status]} />
                  }))}
                />
              </Form.Item>
            </Col>

            <Col span={smallColSpan}>
              <Form.Item
                name="verificationStatuses"
                label={t("contract.filter.verificationStatus")}
                initialValue={contractsPage.verificationStatuses || []}
              >
                <Select
                  {...selectTagsStandardProps(contractVerificationStatusTMap)}
                  allowClear
                  mode="multiple"
                  maxTagCount="responsive"
                  tagRender={props => (
                    <ContractVerificationStatusTag
                      status={ContractVerificationStatus[props.value as string]}
                      closable={props.closable}
                      onClose={props.onClose}
                    />
                  )}
                  options={Object.keys(ContractVerificationStatus).map(status => ({
                    value: status,
                    label: <ContractVerificationStatusTag status={ContractVerificationStatus[status]} />
                  }))}
                />
              </Form.Item>
            </Col>

            <Col flex="110px" className="no-title-space">
              <Form.Item>
                <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />} onClick={handleFormSubmit}>
                  {t("contract.filter.submit")}
                </Button>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={24} className="extended-filter-action">
              <ActionTextIcon
                icon="edit"
                color="blue"
                text={t("contract.filter.extended")}
                onClick={() => setFilterModalOpen(true)}
              />
              {filterModalText && (
                <>
                  <span className="margin-left-tiny margin-right-tiny">{filterModalText}</span>
                  <ActionTextIcon
                    icon="delete"
                    color="red"
                    onClick={handleModalFilterClear}
                    text={t("common.cancel")}
                  />
                </>
              )}
            </Col>
          </Row>

          <Modal
            width={ModalSizes.MEDIUM}
            open={filterModalOpen}
            title={t("contract.filter.extended")}
            okText={t("contract.filter.submit")}
            okButtonProps={{ icon: <AntIcon type="search" /> }}
            cancelText={t("common.cancel")}
            maskClosable={false}
            forceRender
            onOk={handleFormSubmit}
            onCancel={handleModalCancel}
          >
            <Row gutter={rowGutter}>
              <Col span={12}>
                <AgentSelect
                  formItemProps={{
                    name: "signerIds",
                    label: t("contract.filter.signerIds"),
                    initialValue: contractsPage.signerIds || []
                  }}
                  selectProps={{
                    mode: "multiple",
                    maxTagCount: "responsive",
                    allowClear: true,
                    style: { width: "318px" }
                  }}
                  optionsProps={{
                    filter: agent => agent.type !== AgentType.LEGAL,
                    extendedFilter: { form }
                  }}
                />
              </Col>

              <Col span={12}>
                <AgentSelect
                  formItemProps={{
                    name: "managerIds",
                    label: t("contract.filter.managerIds"),
                    initialValue: contractsPage.managerIds || []
                  }}
                  selectProps={{
                    mode: "multiple",
                    maxTagCount: "responsive",
                    allowClear: true,
                    style: { width: "318px" }
                  }}
                  optionsProps={{
                    filter: agent => agent.type !== AgentType.LEGAL,
                    extendedFilter: { form }
                  }}
                />
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={12}>
                <Form.Item
                  name="createdAtRange"
                  label={t("contract.filter.createdAtRange")}
                  initialValue={toMomentArray([contractsPage.createdAtMin, contractsPage.createdAtMax])}
                >
                  <DatePicker.RangePicker
                    format={getDatePickerFormat()}
                    disabledDate={current => disableDatePickerFuture(current)}
                    ranges={datePickerDefaultRangesUntilToday}
                    placeholder={[t("common.from"), t("common.to")]}
                    style={{ width: "318px" }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="mediationReportSignDateRange"
                  label={t("contract.filter.mediationReportSignDateRange")}
                  initialValue={toMomentArray([
                    contractsPage.mediationReportSignDateMin,
                    contractsPage.mediationReportSignDateMax
                  ])}
                >
                  <DatePicker.RangePicker
                    format={getDatePickerFormat()}
                    ranges={datePickerDefaultRanges}
                    placeholder={[t("common.from"), t("common.to")]}
                    style={{ width: "318px" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={12}>
                <Form.Item
                  name="effectiveBeginningDateOrSignDateRange"
                  label={t("contract.filter.effectiveBeginningDateOrSignDateRange")}
                  initialValue={toMomentArray([
                    contractsPage.effectiveBeginningDateOrSignDateMin,
                    contractsPage.effectiveBeginningDateOrSignDateMax
                  ])}
                >
                  <DatePicker.RangePicker
                    format={getDatePickerFormat()}
                    ranges={datePickerDefaultRanges}
                    placeholder={[t("common.from"), t("common.to")]}
                    style={{ width: "318px" }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="effectiveEndDateOrLoanMaturityDateRange"
                  label={t("contract.filter.effectiveEndDateOrLoanMaturityDateRange")}
                  initialValue={toMomentArray([
                    contractsPage.effectiveEndDateOrLoanMaturityDateMin,
                    contractsPage.effectiveEndDateOrLoanMaturityDateMax
                  ])}
                >
                  <DatePicker.RangePicker
                    format={getDatePickerFormat()}
                    ranges={datePickerDefaultRanges}
                    placeholder={[t("common.from"), t("common.to")]}
                    style={{ width: "318px" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={12}>
                <Form.Item
                  name="cancellationDateRange"
                  label={t("contract.filter.cancellationDateRange")}
                  initialValue={toMomentArray([contractsPage.cancellationDateMin, contractsPage.cancellationDateMax])}
                >
                  <DatePicker.RangePicker
                    format={getDatePickerFormat()}
                    ranges={datePickerDefaultRanges}
                    placeholder={[t("common.from"), t("common.to")]}
                    style={{ width: "318px" }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <AffiliatePartnerSelect
                  formItemProps={{
                    name: "affiliatePartnerIds",
                    label: t("contract.filter.affiliatePartners"),
                    initialValue: contractsPage.affiliatePartnerIds || []
                  }}
                  selectProps={{
                    mode: "multiple",
                    maxTagCount: "responsive",
                    allowClear: true,
                    style: { width: "318px" }
                  }}
                />
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={12}>
                <Form.Item
                  name="transferredFromOtherBroker"
                  valuePropName="checked"
                  className="margin-top-medium"
                  rules={[validations.none]}
                  initialValue={!!contractsPage.transferredFromOtherBroker}
                >
                  <Checkbox>{t("contract.filter.transferredFromOtherBroker")}</Checkbox>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="transferredToOtherBrokerDateRange"
                  label={t("contract.filter.transferredToOtherBrokerDateRange")}
                  initialValue={toMomentArray([
                    contractsPage.transferredToOtherBrokerDateMin,
                    contractsPage.transferredToOtherBrokerDateMax
                  ])}
                >
                  <DatePicker.RangePicker
                    format={getDatePickerFormat()}
                    ranges={datePickerDefaultRanges}
                    placeholder={[t("common.from"), t("common.to")]}
                    style={{ width: "318px" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={12}>
                <Form.Item
                  name="vehicleFirstRegistrationYearRange"
                  label={t("contract.filter.vehicleFirstRegistrationYearRange")}
                  initialValue={
                    contractsPage.vehicleFirstRegistrationYearMin &&
                    contractsPage.vehicleFirstRegistrationYearMax && [
                      moment().year(contractsPage.vehicleFirstRegistrationYearMin),
                      moment().year(contractsPage.vehicleFirstRegistrationYearMax)
                    ]
                  }
                >
                  <DatePicker.RangePicker
                    picker="year"
                    disabledDate={current => disableDatePickerFuture(current)}
                    defaultPickerValue={[moment().subtract(12, "year"), moment().subtract(6, "year")]}
                    placeholder={[t("common.from"), t("common.to")]}
                    style={{ width: "318px" }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label={t("contract.filter.annualPremiumOrApprovedAmountRange")}>
                  <Row gutter={rowGutter}>
                    <Col span={12}>
                      <Form.Item
                        name="annualPremiumOrApprovedAmountMin"
                        rules={[validations.minNumber(1)]}
                        initialValue={contractsPage.annualPremiumOrApprovedAmountMin}
                      >
                        <InputNumber
                          {...inputNumberIntegerStandardProps}
                          addonAfter={<InputAddon type="euro" />}
                          placeholder={t("common.from")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prev, next) =>
                          prev.annualPremiumOrApprovedAmountMin !== next.annualPremiumOrApprovedAmountMin
                        }
                      >
                        {({ getFieldValue }) => {
                          const min = getFieldValue(["annualPremiumOrApprovedAmountMin"]) || 1;
                          return (
                            <Form.Item
                              name="annualPremiumOrApprovedAmountMax"
                              rules={[validations.minNumber(min)]}
                              initialValue={contractsPage.annualPremiumOrApprovedAmountMax}
                            >
                              <InputNumber
                                {...inputNumberIntegerStandardProps}
                                addonAfter={<InputAddon type="euro" />}
                                placeholder={t("common.to")}
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Modal>
        </Form>
      </Card>
    </>
  );
};

export default ContractListFilterView;
