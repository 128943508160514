import { Tag } from "antd";
import React from "react";
import t from "../../../app/i18n";
import { AgentType } from "../enums";

interface Props {
  type: AgentType;
  style?: React.CSSProperties;
}

const AgentTypeTag = ({ type, style }: Props) => {
  let color: string;

  switch (type) {
    case AgentType.NATURAL:
      color = "blue";
      break;
    case AgentType.SELF_EMPLOYED:
      color = "gold";
      break;
    case AgentType.LEGAL:
      color = "purple";
      break;
    default:
      return null;
  }

  return (
    <Tag style={{ fontWeight: "normal", width: "36px", textAlign: "center", ...style }} color={color}>
      {t("agent.enums.type." + type)}
    </Tag>
  );
};

export default AgentTypeTag;
