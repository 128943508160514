import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect } from "react";
import t from "../../../../../../app/i18n";
import AntIcon from "../../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../../common/constants";
import ComponentWithPermission from "../../../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { selectTagsStandardProps } from "../../../../../../common/utils/formUtils";
import { validationConstants, validations } from "../../../../../../common/utils/validationUtils";
import { CommissionsSettingsLevelType, commissionsSettingsLevelTypeTMap } from "../../../enums";
import { CommissionsSettingsLevelFilterPageRequest, CommissionsSettingsLevelFilterPageResult } from "../../../types";
import CommissionsLevelTypeTag from "../../CommissionsLevelTypeTag";

interface Props {
  currentFilter: CommissionsSettingsLevelFilterPageResult;
  onFilterSubmit: (filter: CommissionsSettingsLevelFilterPageRequest) => void;
  onCreateClick: () => void;
}

const CommissionsLevelFilterView = ({ currentFilter, onFilterSubmit, onCreateClick }: Props) => {
  const [form] = Form.useForm<CommissionsSettingsLevelFilterPageRequest>();
  const colSpan = 6;

  useEffect(() => {
    form.setFieldsValue({
      keyword: currentFilter.keyword,
      type: currentFilter.type
    });
  }, [currentFilter, form]);

  return (
    <Card
      className="card-box"
      title={<h2>{t("commissions.level.titles.list")}</h2>}
      extra={
        <ComponentWithPermission permissions={[Permission.TOP_AGENT_COMMISSIONS_MANAGE]}>
          <Button type="primary" icon={<AntIcon type="plus" />} onClick={onCreateClick}>
            {t("commissions.level.actions.createLevel")}
          </Button>
        </ComponentWithPermission>
      }
    >
      <Form form={form} onFinish={onFilterSubmit} layout="vertical" name="commissionsLevelSearchForm">
        <Row gutter={rowGutter} justify="center">
          <Col span={colSpan}>
            <Form.Item
              name="keyword"
              label={t("common.search")}
              rules={[
                validations.size(
                  validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
                  validationConstants.SEARCH_KEYWORD_MAX_LENGTH
                )
              ]}
            >
              <Input allowClear placeholder={t("commissions.level.helpers.searchHint")} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item name="type" label={t("commissions.level.enums.type._label")} rules={[validations.none]}>
              <Select
                {...selectTagsStandardProps(commissionsSettingsLevelTypeTMap)}
                allowClear
                tagRender={props => (
                  <CommissionsLevelTypeTag
                    type={CommissionsSettingsLevelType[props.value as string]}
                    closable={props.closable}
                    onClose={props.onClose}
                  />
                )}
                options={Object.keys(CommissionsSettingsLevelType).map(type => ({
                  value: type,
                  label: <CommissionsLevelTypeTag type={CommissionsSettingsLevelType[type]} />
                }))}
              />
            </Form.Item>
          </Col>

          <Col flex="110px" className="no-title-space">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default CommissionsLevelFilterView;
