import { Avatar, Card } from "antd";
import Meta from "antd/lib/card/Meta";
import React from "react";
import { useSelector } from "react-redux";
import UserOutlined from "../../../../../../assets/images/UserOutlined.svg";
import Ellipsis from "../../../../../../common/components/views/Ellipsis";
import { RootState } from "../../../../../../common/types";
import { getApiBaseUrl } from "../../../../../../common/utils/utils";
import { selectToken, selectUserAccount } from "../../../../../auth/ducks";
import { UserAccount } from "../../../../../user/types";
import { deleteDashboardContactActions } from "../../../../ducks";
import { DashboardContact, InternalDashboardContact } from "../../../../types";
import { DashboardContactActionsView } from "./DashboardContactActionsView";
import { DashboardContactFooterView } from "./DashboardContactFooterView";

interface Props {
  contact: InternalDashboardContact;
  showActions: boolean;
  onUpdate: (contact: DashboardContact) => void;
  onDelete: typeof deleteDashboardContactActions.request;
}

export const InternalDashboardContactView = ({ contact, showActions, onUpdate, onDelete }: Props) => {
  const token = useSelector<RootState, string>(selectToken);
  const userAccount = useSelector<RootState, UserAccount>(selectUserAccount);

  return (
    <Card
      key={contact.id}
      className="dashboard-contacts__card"
      actions={[<DashboardContactFooterView email={contact.agent.email} phone={contact.agent.phone} />]}
    >
      <Meta
        avatar={
          <Avatar
            src={
              contact.agent.profilePicture
                ? `${getApiBaseUrl()}/agents/${contact.agent.id}/profile-picture?auth=${token}&accountId=${
                    userAccount?.id
                  }`
                : UserOutlined
            }
            className="dashboard-contacts__avatar"
          />
        }
        title={<Ellipsis>{contact.agent.aggregatedName}</Ellipsis>}
        description={<Ellipsis>{contact.position}</Ellipsis>}
      />

      {showActions && (
        <DashboardContactActionsView contact={contact as DashboardContact} onUpdate={onUpdate} onDelete={onDelete} />
      )}
    </Card>
  );
};
