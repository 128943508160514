import { Card, Col, Divider, Form, Input, Row } from "antd";
import { FormInstance } from "antd/lib/form";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import LabelWithTooltip from "../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../common/constants";
import { Permission } from "../../../../common/security/authorization/enums";
import { FieldConstraintViolation, RootState, UUID } from "../../../../common/types";
import { resolveFormValidationError } from "../../../../common/utils/formUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { selectHasPermissions } from "../../../auth/ducks";
import ClientDrawerForm from "../../../client/components/drawers/ClientDrawerForm";
import ClientSearchInput from "../../../client/components/search/ClientSearchInput";
import { ClientFormStage, ClientSearchActionType } from "../../../client/enums";
import { Client } from "../../../client/types";
import { useClientSearch } from "../../../client/utils";
import InstitutionSelect from "../../../enumerations/components/form/InstitutionSelect";
import ProductSelect from "../../../enumerations/components/form/ProductSelect";
import { selectProductsClassificationEnums } from "../../../enumerations/ducks";
import { InstitutionWithProducts } from "../../../enumerations/types";
import { ProductFinancialSector } from "../../../product/enums";
import { PRODUCT_SECTOR_TO_INSTITUTION_TYPE_MAP } from "../../../product/utils";
import {
  Contract,
  CreateUpdateDepositContract,
  CreateUpdateGenericContract,
  CreateUpdateInvestmentContract,
  CreateUpdateSecondPillarContract,
  CreateUpdateThirdPillarContract
} from "../../types";
import { isContractVerified } from "../../utils";

interface Props {
  initialContract?: Contract;
  financialSector: ProductFinancialSector;
  privilegedChangesPermission: Permission;
  form:
    | FormInstance<CreateUpdateInvestmentContract>
    | FormInstance<CreateUpdateDepositContract>
    | FormInstance<CreateUpdateSecondPillarContract>
    | FormInstance<CreateUpdateThirdPillarContract>
    | FormInstance<CreateUpdateGenericContract>;
  client: Client;
  clientViolationErrors: Map<number, FieldConstraintViolation[]>;
  onClientChange: (client: Client) => void;
  onClientViolationErrorsDelete: () => void;
}

const ContractFormHeaderSection = ({
  initialContract,
  financialSector,
  privilegedChangesPermission,
  form,
  client,
  ...props
}: Props) => {
  const clientSearch = useClientSearch();

  const productsClassification = useSelector<RootState, InstitutionWithProducts[]>(selectProductsClassificationEnums);

  const [clientFormOpen, setClientFormOpen] = useState<boolean>(false);
  const [clientProcessed, setClientProcessed] = useState<boolean>();
  const [clientStage, setClientStage] = useState<ClientFormStage>(
    initialContract?.clients[0] ? ClientFormStage.SELECTED : null
  );

  const hasPrivilegedChangesPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(privilegedChangesPermission)(state)
  );

  const disablePrivilegedInputs = isContractVerified(initialContract) && !hasPrivilegedChangesPermission;

  useEffect(() => {
    if (clientProcessed && clientSearch.result.keyword === form.getFieldValue(["clientIdentifiers", 0])) {
      if (clientSearch.result.data) {
        setClientStage(ClientFormStage.EXISTING);
        props.onClientChange(clientSearch.result.data);
      } else {
        setClientStage(ClientFormStage.NEW);
      }
    }
  }, [clientSearch.result]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInstitutionIdChange = (institutionId: UUID): void => {
    const institution = productsClassification.find(institution => institution.id === institutionId);
    const productId = form.getFieldValue("productId") as UUID;

    if (
      productId &&
      !institution?.productGroups.flatMap(group => group.products).some(product => product.id === productId)
    ) {
      form.setFieldsValue({ productId: null });
    }
  };

  const handleClientSearchActionClick = (index: number, actionType: ClientSearchActionType): void => {
    switch (actionType) {
      case ClientSearchActionType.CREATE:
      case ClientSearchActionType.UPDATE:
        setClientProcessed(true);
        setClientFormOpen(true);
        break;
      case ClientSearchActionType.DELETE:
        const updatedIdentifiers = [...((form.getFieldValue("clientIdentifiers") as string[]) || [])];
        updatedIdentifiers[index] = null;
        form.setFieldsValue({ clientIdentifiers: updatedIdentifiers });

        setClientStage(null);
        setClientProcessed(false);

        props.onClientViolationErrorsDelete();
        props.onClientChange(null);
        break;
    }
  };

  const handleClientSearchSubmit = (value: string): void => {
    form
      .validateFields([["clientIdentifiers", 0]])
      .then(() => {
        if (value) {
          clientSearch.onSearch({ keyword: value });
        }
      })
      .catch(resolveFormValidationError);
  };

  const handleClientSearchChange = (value: string): void => {
    if (clientStage) {
      setClientStage(null);
    }
    if (client) {
      props.onClientChange(null);
    }
    if (props.clientViolationErrors) {
      props.onClientViolationErrorsDelete();
    }

    handleClientSearchSubmit(value);
  };

  const handleClientFormSubmit = (client: Client): void => {
    setClientFormOpen(false);
    setClientProcessed(false);
    setClientStage(ClientFormStage.SELECTED);
    props.onClientViolationErrorsDelete();
    props.onClientChange(client);
  };

  const getClientFormIdentifier = (): string => {
    return ((form.getFieldValue("clientIdentifiers") as string[]) || [])[0];
  };

  const colSpan = 4;

  return (
    <>
      <Card type="inner" className="card-box margin-top-medium" title={t("contract.sections.contractIdentification")}>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="contractNumber"
              label={t("contract.attrs.contractNumber")}
              dependencies={["secondaryContractNumber"]}
              rules={[
                validations.size(1, 64),
                validations.notNullIfOtherNull("secondaryContractNumber", t("contract.attrs.otherContractNumber")),
                validations.notEqualTo("secondaryContractNumber", t("contract.attrs.otherContractNumber"))
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="secondaryContractNumber"
              label={t("contract.attrs.otherContractNumber")}
              dependencies={["contractNumber"]}
              rules={[
                validations.size(1, 64),
                validations.notNullIfOtherNull("contractNumber", t("contract.attrs.contractNumber")),
                validations.notEqualTo("contractNumber", t("contract.attrs.contractNumber"))
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <InstitutionSelect
              formItemProps={{
                name: "institutionId",
                label: t("common.institution"),
                rules: [validations.notNull]
              }}
              selectProps={{ disabled: disablePrivilegedInputs, onChange: handleInstitutionIdChange }}
              optionsProps={{
                selected: initialContract ? [initialContract.institution] : undefined,
                filterType: PRODUCT_SECTOR_TO_INSTITUTION_TYPE_MAP.get(financialSector)
              }}
            />
          </Col>

          <Col span={colSpan}>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.institutionId !== next.institutionId}>
              {({ getFieldValue }) => {
                const institutionId = getFieldValue("institutionId");
                return (
                  <ProductSelect
                    formItemProps={{
                      name: "productId",
                      label: t("contract.attrs.productId"),
                      rules: [validations.notNull]
                    }}
                    optionsProps={{
                      institutionId,
                      selectedInTree: {
                        selected: initialContract?.product,
                        condition: initialContract?.institution.id === institutionId
                      },
                      hideAll: !!!institutionId,
                      groupByProductGroup: true,
                      filterSectors: [financialSector]
                    }}
                    selectProps={{
                      disabled: disablePrivilegedInputs,
                      placeholder: !!!institutionId ? t("contract.helpers.commonProductPlaceholder") : undefined
                    }}
                  />
                );
              }}
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">{t("contract.sections.clients")}</Divider>

        <Row gutter={rowGutter}>
          <ClientSearchInput<number>
            formItemProps={{
              name: ["clientIdentifiers", 0],
              label: (
                <LabelWithTooltip tooltip={t("helpers.pinFormat")} label={t("contract.attrs.policyHolderIdentifier")} />
              ),
              rules: [validations.notBlank, validations.pinOrCrn]
            }}
            formStage={clientStage}
            formType={0}
            processedType={0}
            client={client}
            violationErrors={props.clientViolationErrors}
            inProgress={clientSearch.inProgress}
            inputColSpan={5}
            clientNameColSpan={6}
            onActionClick={handleClientSearchActionClick}
            onFocus={() => setClientProcessed(true)}
            onSearch={handleClientSearchSubmit}
            onChange={handleClientSearchChange}
          />
        </Row>
      </Card>

      <ClientDrawerForm<number>
        open={clientFormOpen}
        client={client}
        initialIdentifier={getClientFormIdentifier()}
        formType={0}
        violationErrors={props.clientViolationErrors}
        placement="contract"
        onFormSubmit={handleClientFormSubmit}
      />
    </>
  );
};

export default ContractFormHeaderSection;
