import { Col, Row } from "antd";
import React from "react";
import { loadContractToCalculatorActions } from "../../../../ducks";
import { ContractDetailViewProps, InsuranceContract } from "../../../../types";
import ContractAgentsSection from "../ContractAgentsSection";
import ContractAttachmentsSection from "../ContractAttachmentsSection";
import InsuranceContractDataSection from "./sections/InsuranceContractDataSection";
import InsuranceContractHeaderSection from "./sections/InsuranceContractHeaderSection";
import InsuranceContractInsurancesSection from "./sections/InsuranceContractInsurancesSection";

const InsuranceContractDetailView = ({
  contract,
  ...props
}: ContractDetailViewProps<InsuranceContract> & {
  onLoadContractToCalculator: typeof loadContractToCalculatorActions;
}) => (
  <Row className="margin-top-small">
    <Col span={24}>
      <InsuranceContractHeaderSection
        contract={contract}
        onVerificationStatusChange={props.onVerificationStatusChange}
        onExternalViewGet={props.onExternalViewGet}
        onDelete={props.onDelete}
        onUpdateClick={props.onUpdateClick}
        onContractToCalculatorLoad={props.onLoadContractToCalculator}
      />

      <InsuranceContractInsurancesSection contract={contract} />

      <InsuranceContractDataSection contract={contract} />

      <ContractAgentsSection contract={contract} />

      <ContractAttachmentsSection
        contract={contract}
        onDownloadAsZip={props.onDownloadAsZip}
        onUpload={props.onAttachmentsUpload}
        onDelete={props.onAttachmentDelete}
      />
    </Col>
  </Row>
);

export default InsuranceContractDetailView;
