import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { NormalizedError, RootState } from "../../../common/types";
import { initAutocompleteResult } from "../../../common/utils/apiUtils";
import { Vehicle } from "../../contract/types";
import api from "./api";
import { VehicleAutocompleteReducerState, VehicleAutocompleteRequest, VehicleAutocompleteResult } from "./types";

/**
 * ACTIONS
 */
export const autocompleteVehiclesActions = createAsyncAction(
  "vehicle-autocomplete/GET_REQUEST",
  "vehicle-autocomplete/GET_SUCCESS",
  "vehicle-autocomplete/GET_FAILURE"
)<VehicleAutocompleteRequest, VehicleAutocompleteResult, NormalizedError>();

export const deleteStateVehicleAutocompleteResultAction = createAction(
  "vehicle-autocomplete/DELETE_STATE_RESULT"
)<void>();

const actions = {
  autocompleteVehiclesActions,
  deleteStateVehicleAutocompleteResultAction
};

export type VehicleAutocompleteAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: VehicleAutocompleteReducerState = {
  result: {
    ...initAutocompleteResult<Vehicle>(),
    by: null
  }
};

const resultReducer = createReducer(initialState.result)
  .handleAction(autocompleteVehiclesActions.success, (_, { payload }) => payload)
  .handleAction(
    [autocompleteVehiclesActions.failure, deleteStateVehicleAutocompleteResultAction],
    () => initialState.result
  );

export const vehicleAutocompleteReducer = combineReducers<VehicleAutocompleteReducerState>({
  result: resultReducer
});

/**
 * SELECTORS
 */
const selectVehicleAutocomplete = (state: RootState): VehicleAutocompleteReducerState => state.vehicle.autocomplete;

export const selectVehicleAutocompleteResult = (state: RootState): VehicleAutocompleteResult =>
  selectVehicleAutocomplete(state).result;

/**
 * SAGAS
 */
function* autocompleteVehicles({ payload }: ReturnType<typeof autocompleteVehiclesActions.request>) {
  try {
    const response: AxiosResponse<VehicleAutocompleteResult> = yield call(api.autocompleteVehicles, payload);
    yield put(autocompleteVehiclesActions.success(response.data));
  } catch (error) {
    yield put(autocompleteVehiclesActions.failure(error));
  }
}

export function* vehicleAutocompleteSaga() {
  yield takeLatest(autocompleteVehiclesActions.request, autocompleteVehicles);
}
