import { Tag } from "antd";
import React from "react";
import t from "../../../../app/i18n";
import { ClientType } from "../../enums";

interface Props {
  type: ClientType;
  closable?: boolean;
  style?: React.CSSProperties;
  onClose?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const ClientTypeTag = ({ type, closable, style, onClose }: Props) => {
  let color: string;

  switch (type) {
    case ClientType.NATURAL:
      color = "blue";
      break;
    case ClientType.SELF_EMPLOYED:
      color = "gold";
      break;
    case ClientType.LEGAL:
      color = "purple";
      break;
    default:
      return null;
  }

  return (
    <Tag color={color} closable={closable} style={style} onClose={onClose}>
      {t("client.enums.type." + type)}
    </Tag>
  );
};

export default ClientTypeTag;
