import { GA4React } from "ga-4-react";
import { GA4ReactResolveInterface } from "ga-4-react/dist/models/gtagModels";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { resolvePageTitle } from "./navigationUtils";
import { getGoogleAnalyticsKey, isLocalhostDevMode } from "./utils";

if (getGoogleAnalyticsKey()) {
  new GA4React(getGoogleAnalyticsKey(), { debug_mode: isLocalhostDevMode() }, [], 5000).initialize().then(
    () => {
      if (isLocalhostDevMode()) {
        console.log("Google Analytics successfully initialized.");
      }
    },
    error => console.error("Error occurred during Google Analytics initialization.", error)
  );
}

export const getGoogleAnalytics = (): GA4ReactResolveInterface | null =>
  GA4React.isInitialized() ? (GA4React.getGA4React() as GA4ReactResolveInterface) : null;

export const useGoogleAnalytics = (): void => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (getGoogleAnalyticsKey()) {
      getGoogleAnalytics()?.pageview(pathname, window.location.href, resolvePageTitle(pathname));
    }
  }, [pathname]);
};
