import { Button, Card, Checkbox, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect } from "react";
import { generatePath, Link } from "react-router-dom";
import t from "../../../../app/i18n";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../common/constants";
import ComponentWithPermission from "../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../common/security/authorization/enums";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import { validationConstants, validations } from "../../../../common/utils/validationUtils";
import { ProductFinancialSector } from "../../../product/enums";
import { FINANCIAL_MEDIATION_ROUTE_PATHS } from "../../paths";
import { FinancialMediationFilterPageRequest, FinancialMediationFilterPageResult } from "../../types";

interface Props {
  currentFilter: FinancialMediationFilterPageResult;
  onFilterSubmit: (filter: FinancialMediationFilterPageRequest) => void;
}

const FinancialMediationFilterView = (props: Props) => {
  const [form] = Form.useForm<FinancialMediationFilterPageRequest>();

  const colSpan = 6;

  useEffect(() => {
    form.setFieldsValue({
      keyword: props.currentFilter.keyword,
      sectors: props.currentFilter.sectors || [],
      includeAssigned: !!props.currentFilter.includeAssigned
    });
  }, [props.currentFilter, form]);

  return (
    <Card
      className="card-box"
      title={<h2>{t("financialMediation.titles.list")}</h2>}
      extra={
        <ComponentWithPermission
          permissions={[
            Permission.INSURANCE_CREATE,
            Permission.LOAN_CREATE,
            Permission.DEPOSIT_CREATE,
            Permission.SECOND_PILLAR_CREATE,
            Permission.THIRD_PILLAR_CREATE
          ]}
          anyPermissionPresent
        >
          <Link to={generatePath(FINANCIAL_MEDIATION_ROUTE_PATHS.create.to)}>
            <Button type="primary" icon={<AntIcon type="plus" />}>
              {t("financialMediation.actions.createMediation")}
            </Button>
          </Link>
        </ComponentWithPermission>
      }
    >
      <Form form={form} onFinish={props.onFilterSubmit} layout="vertical" name="financialMediationFilterForm">
        <Row gutter={rowGutter} justify="center">
          <Col span={colSpan}>
            <Form.Item
              name="keyword"
              label={t("common.searchKeyword")}
              rules={[
                validations.size(
                  validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
                  validationConstants.SEARCH_KEYWORD_MAX_LENGTH
                )
              ]}
            >
              <Input allowClear placeholder={t("financialMediation.helpers.searchHint")} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item name="sectors" label={t("product.enums.financialSector._label")} rules={[validations.none]}>
              <Select
                {...selectStandardProps}
                allowClear
                mode="multiple"
                maxTagCount="responsive"
                options={Object.keys(ProductFinancialSector)
                  .filter(
                    sector =>
                      sector !== ProductFinancialSector.CAPITAL_MARKET &&
                      sector !== ProductFinancialSector.OTHERS &&
                      sector !== ProductFinancialSector.ALL
                  )
                  .map(sector => ({
                    value: sector,
                    label: t("product.enums.financialSector." + sector)
                  }))}
              />
            </Form.Item>
          </Col>

          <Col span={colSpan - 2}>
            <Form.Item
              name="includeAssigned"
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={true}
            >
              <Checkbox>{t("financialMediation.filter.includeAssigned")}</Checkbox>
            </Form.Item>
          </Col>

          <Col flex="110px" className="no-title-space">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default FinancialMediationFilterView;
