import { Tag } from "antd";
import React from "react";
import t from "../../../app/i18n";

interface Props {
  tipper: boolean;
  style?: React.CSSProperties;
}

const AgentTipperTag = ({ tipper, style }: Props) =>
  tipper && (
    <Tag color="geekblue" style={style}>
      {t("agent.attrs.tipper")}
    </Tag>
  );

export default AgentTipperTag;
