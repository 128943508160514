import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import {
  EntityIdObject,
  EntityObject,
  NormalizedError,
  RootState,
  TwoLevelEntityIdObject,
  TwoLevelEntityObject
} from "../../../common/types";
import messageUtils from "../../../common/utils/messageUtils";
import { openBlobFile, removeFromArray, replaceInArray } from "../../../common/utils/utils";
import { changeRunningRequestKeyAction } from "../../ducks";
import { getEnumerationsActions } from "../../enumerations/ducks";
import {
  createBailAccountSettingsActions,
  deleteBailAccountSettingsActions,
  updateBailAccountSettingsActions
} from "../bailaccount/ducks";
import {
  createSpecialCommissionsRuleActions,
  deleteSpecialCommissionsRuleActions,
  updateSpecialCommissionsRuleActions
} from "../special/ducks";
import api from "./api";
import {
  CommissionsSettings,
  CommissionsSettingsAgentProfile,
  CommissionsSettingsAttachment,
  CommissionsSettingsReducerState,
  CommissionsSettingsRule,
  CreateCommissionsSettingsRule,
  UpdateCommissionsSettings,
  UpdateCommissionsSettingsRule
} from "./types";
import { sortCommissionsSettingsRules, sortCommissionsSettingsSpecialRules } from "./utils";

/**
 * ACTIONS
 */
export const getCommissionsSettingsActions = createAsyncAction(
  "commissions-settings/GET_REQUEST",
  "commissions-settings/GET_SUCCESS",
  "commissions-settings/GET_FAILURE"
)<EntityIdObject, CommissionsSettings | CommissionsSettingsAgentProfile, NormalizedError>();

export const updateCommissionsSettingsActions = createAsyncAction(
  "commissions-settings/UPDATE_REQUEST",
  "commissions-settings/UPDATE_SUCCESS",
  "commissions-settings/UPDATE_FAILURE"
)<EntityObject<UpdateCommissionsSettings>, CommissionsSettings, NormalizedError>();

export const deleteStateCommissionsSettingsAction = createAction(
  "commissions-settings/DELETE_STATE_AGENT_SETTINGS"
)<void>();

export const createCommissionsSettingsRuleActions = createAsyncAction(
  "commissions-settings-rule/CREATE_REQUEST",
  "commissions-settings-rule/CREATE_SUCCESS",
  "commissions-settings-rule/CREATE_FAILURE"
)<EntityObject<CreateCommissionsSettingsRule>, CommissionsSettingsRule, NormalizedError>();

export const updateCommissionsSettingsRuleActions = createAsyncAction(
  "commissions-settings-rule/UPDATE_REQUEST",
  "commissions-settings-rule/UPDATE_SUCCESS",
  "commissions-settings-rule/UPDATE_FAILURE"
)<TwoLevelEntityObject<UpdateCommissionsSettingsRule>, CommissionsSettingsRule, NormalizedError>();

export const deleteCommissionsSettingsRuleActions = createAsyncAction(
  "commissions-settings-rule/DELETE_REQUEST",
  "commissions-settings-rule/DELETE_SUCCESS",
  "commissions-settings-rule/DELETE_FAILURE"
)<TwoLevelEntityIdObject, EntityIdObject, NormalizedError>();

export const uploadCommissionsSettingsAttachmentsActions = createAsyncAction(
  "commissions-settings-attachment/UPLOAD_REQUEST",
  "commissions-settings-attachment/UPLOAD_SUCCESS",
  "commissions-settings-attachment/UPLOAD_FAILURE"
)<EntityObject<FormData>, CommissionsSettingsAttachment[], NormalizedError>();

export const downloadCommissionsSettingsAttachmentActions = createAsyncAction(
  "commissions-settings-attachment/DOWNLOAD_REQUEST",
  "commissions-settings-attachment/DOWNLOAD_SUCCESS",
  "commissions-settings-attachment/DOWNLOAD_FAILURE"
)<TwoLevelEntityIdObject, void, NormalizedError>();

export const deleteCommissionsSettingsAttachmentActions = createAsyncAction(
  "commissions-settings-attachment/DELETE_REQUEST",
  "commissions-settings-attachment/DELETE_SUCCESS",
  "commissions-settings-attachment/DELETE_FAILURE"
)<TwoLevelEntityIdObject, EntityIdObject, NormalizedError>();

const actions = {
  getCommissionsSettingsActions,
  updateCommissionsSettingsActions,
  deleteStateCommissionsSettingsAction,
  createCommissionsSettingsRuleActions,
  updateCommissionsSettingsRuleActions,
  deleteCommissionsSettingsRuleActions,
  uploadCommissionsSettingsAttachmentsActions,
  downloadCommissionsSettingsAttachmentActions,
  deleteCommissionsSettingsAttachmentActions
};

export type CommissionSettingsAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: CommissionsSettingsReducerState = {
  settingsData: null
};

const agentSettingsReducer = createReducer(initialState.settingsData)
  .handleAction(
    [getCommissionsSettingsActions.success, updateCommissionsSettingsActions.success],
    (_, { payload }) => ({
      ...payload,
      rules: sortCommissionsSettingsRules<CommissionsSettingsRule>((payload as CommissionsSettings).rules || [])
    })
  )
  .handleAction(createCommissionsSettingsRuleActions.success, (state, { payload }) => ({
    ...state,
    rules: sortCommissionsSettingsRules<CommissionsSettingsRule>([...(state as CommissionsSettings).rules, payload])
  }))
  .handleAction(updateCommissionsSettingsRuleActions.success, (state, { payload }) => ({
    ...state,
    rules: replaceInArray(
      (state as CommissionsSettings).rules,
      item => item.id === payload.id,
      () => payload
    )
  }))
  .handleAction(deleteCommissionsSettingsRuleActions.success, (state, { payload }) => ({
    ...state,
    rules: removeFromArray((state as CommissionsSettings).rules, item => item.id === payload.id)
  }))
  .handleAction(
    [createBailAccountSettingsActions.success, updateBailAccountSettingsActions.success],
    (state, { payload }) => ({ ...state, bailAccountSettings: payload })
  )
  .handleAction(deleteBailAccountSettingsActions.success, state => ({ ...state, bailAccountSettings: null }))
  .handleAction(createSpecialCommissionsRuleActions.success, (state, { payload }) => ({
    ...state,
    specialRules: sortCommissionsSettingsSpecialRules([...(state as CommissionsSettings).specialRules, payload])
  }))
  .handleAction(updateSpecialCommissionsRuleActions.success, (state, { payload }) => ({
    ...state,
    specialRules: sortCommissionsSettingsSpecialRules(
      replaceInArray(
        (state as CommissionsSettings).specialRules,
        item => item.id === payload.id,
        () => payload
      )
    )
  }))
  .handleAction(deleteSpecialCommissionsRuleActions.success, (state, { payload }) => ({
    ...state,
    specialRules: removeFromArray((state as CommissionsSettings).specialRules, item => item.id === payload.id)
  }))
  .handleAction(uploadCommissionsSettingsAttachmentsActions.success, (state, { payload }) => ({
    ...state,
    attachments: payload
  }))
  .handleAction(deleteCommissionsSettingsAttachmentActions.success, (state, { payload }) => ({
    ...state,
    attachments: removeFromArray(state.attachments, item => item.id === payload.id)
  }))
  .handleAction(
    [getCommissionsSettingsActions.failure, deleteStateCommissionsSettingsAction],
    () => initialState.settingsData
  );

export const commissionsSettingsReducer = combineReducers<CommissionsSettingsReducerState>({
  settingsData: agentSettingsReducer
});

/**
 * SELECTORS
 */
const selectCommissionsSettings = (state: RootState): CommissionsSettingsReducerState => state.commissions.settings;

export const selectCommissionsSettingsData = (
  state: RootState
): CommissionsSettings | CommissionsSettingsAgentProfile => selectCommissionsSettings(state).settingsData;

/**
 * SAGAS
 */
function* getCommissionsSettings({ payload }: ReturnType<typeof getCommissionsSettingsActions.request>) {
  try {
    const response: AxiosResponse<CommissionsSettings | CommissionsSettingsAgentProfile> = yield call(
      api.getCommissionsSettings,
      payload
    );
    yield put(getCommissionsSettingsActions.success(response.data));
  } catch (error) {
    yield put(getCommissionsSettingsActions.failure(error));
  }
}

function* updateCommissionsSettings({ payload }: ReturnType<typeof updateCommissionsSettingsActions.request>) {
  try {
    const response: AxiosResponse<CommissionsSettings> = yield call(api.updateCommissionsSettings, payload);
    const previousSettings: CommissionsSettings = yield select(selectCommissionsSettingsData);

    yield put(updateCommissionsSettingsActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();

    if (previousSettings.includeInBulkPayment !== response.data.includeInBulkPayment) {
      yield put(getEnumerationsActions.request());
    }
  } catch (error) {
    yield put(updateCommissionsSettingsActions.failure(error));
  }
}

function* createCommissionsSettingsRule({ payload }: ReturnType<typeof createCommissionsSettingsRuleActions.request>) {
  try {
    const response: AxiosResponse<CommissionsSettingsRule> = yield call(api.createCommissionsSettingsRule, payload);
    yield put(createCommissionsSettingsRuleActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();
  } catch (error) {
    yield put(createCommissionsSettingsRuleActions.failure(error));
  }
}

function* updateCommissionsSettingsRule({ payload }: ReturnType<typeof updateCommissionsSettingsRuleActions.request>) {
  try {
    const response: AxiosResponse<CommissionsSettingsRule> = yield call(api.updateCommissionsSettingsRule, payload);
    yield put(updateCommissionsSettingsRuleActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(updateCommissionsSettingsRuleActions.failure(error));
  }
}

function* deleteCommissionsSettingsRule({ payload }: ReturnType<typeof deleteCommissionsSettingsRuleActions.request>) {
  try {
    yield call(api.deleteCommissionsSettingsRule, payload);
    yield put(deleteCommissionsSettingsRuleActions.success({ id: payload.id2 }));
  } catch (error) {
    yield put(deleteCommissionsSettingsRuleActions.failure(error));
  }
}

function* uploadCommissionsSettingsAttachments({
  payload
}: ReturnType<typeof uploadCommissionsSettingsAttachmentsActions.request>) {
  try {
    const response: AxiosResponse<CommissionsSettingsAttachment[]> = yield call(
      api.uploadCommissionsSettingsAttachments,
      payload
    );
    yield put(uploadCommissionsSettingsAttachmentsActions.success(response.data));
  } catch (error) {
    yield put(uploadCommissionsSettingsAttachmentsActions.failure(error));
  }
}

function* downloadCommissionsSettingsAttachment({
  payload
}: ReturnType<typeof downloadCommissionsSettingsAttachmentActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadCommissionsSettingsAttachment, payload);
    openBlobFile(response);
    yield put(downloadCommissionsSettingsAttachmentActions.success());
  } catch (error) {
    yield put(downloadCommissionsSettingsAttachmentActions.failure(error));
  }
}

function* deleteCommissionsSettingsAttachment({
  payload
}: ReturnType<typeof deleteCommissionsSettingsAttachmentActions.request>) {
  try {
    yield call(api.deleteCommissionsSettingsAttachment, payload);
    yield put(deleteCommissionsSettingsAttachmentActions.success({ id: payload.id2 }));
  } catch (error) {
    yield put(deleteCommissionsSettingsAttachmentActions.failure(error));
  }
}

export function* commissionsSettingsSaga() {
  yield takeLatest(getCommissionsSettingsActions.request, getCommissionsSettings);
  yield takeLatest(updateCommissionsSettingsActions.request, updateCommissionsSettings);
  yield takeLatest(createCommissionsSettingsRuleActions.request, createCommissionsSettingsRule);
  yield takeLatest(updateCommissionsSettingsRuleActions.request, updateCommissionsSettingsRule);
  yield takeLatest(deleteCommissionsSettingsRuleActions.request, deleteCommissionsSettingsRule);
  yield takeLatest(uploadCommissionsSettingsAttachmentsActions.request, uploadCommissionsSettingsAttachments);
  yield takeLatest(downloadCommissionsSettingsAttachmentActions.request, downloadCommissionsSettingsAttachment);
  yield takeLatest(deleteCommissionsSettingsAttachmentActions.request, deleteCommissionsSettingsAttachment);
}
