import { Form, Radio } from "antd";
import { FormItemProps } from "antd/lib/form";
import { RadioGroupProps } from "antd/lib/radio";
import React from "react";
import t from "../../../../app/i18n";

interface Props {
  formItemProps: FormItemProps;
  radioGroupProps?: RadioGroupProps;
  useAgreementLabels?: boolean;
  trueLabel?: React.ReactNode;
  falseLabel?: React.ReactNode;
}

const BooleanRadioFormItem = ({ formItemProps, radioGroupProps, useAgreementLabels, trueLabel, falseLabel }: Props) => (
  <Form.Item {...formItemProps}>
    <Radio.Group
      {...radioGroupProps}
      options={[
        { label: trueLabel || (useAgreementLabels ? t("common.agree") : t("common.yes")), value: true },
        { label: falseLabel || (useAgreementLabels ? t("common.disagree") : t("common.no")), value: false }
      ]}
    />
  </Form.Item>
);

export default BooleanRadioFormItem;
