import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { push } from "redux-first-history";
import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { EntityIdObject, NormalizedError, RootState } from "../../../common/types";
import { initPageResult } from "../../../common/utils/apiUtils";
import {
  setRealtyCalcResultsAction,
  setRealtyInitialCalcDataAction,
  setRealtyInitialGenDataAction
} from "../calcs/realty/ducks";
import { RealtyCalc, RealtyCalcResultData, RealtyGen } from "../calcs/realty/types";
import {
  setTravelCalcResultsAction,
  setTravelInitialCalcDataAction,
  setTravelInitialGenDataAction
} from "../calcs/travel/ducks";
import { TravelCalc, TravelCalcResultData, TravelGen } from "../calcs/travel/types";
import { CalcResponse } from "../calcs/types";
import { sortAndGroupCalcResults } from "../calcs/utils";
import {
  setVehicleCalcResultsAction,
  setVehicleInitialCalcDataAction,
  setVehicleInitialGenDataAction
} from "../calcs/vehicle/ducks";
import { VehicleCalc, VehicleCalcResultData, VehicleGen } from "../calcs/vehicle/types";
import { sortAndGroupVehicleCalcResults } from "../calcs/vehicle/utils";
import { CalcType } from "../enums";
import { CALC_ROUTE_PATHS } from "../paths";
import api from "./api";
import { OperationType } from "./enums";
import {
  CalcRecord,
  CalcRecordFilterPageRequest,
  CalcRecordFilterPageResult,
  CalcRecordList,
  CalcRecordReducerState
} from "./types";

/**
 * ACTIONS
 */
export const filterCalcRecordsActions = createAsyncAction(
  "calc-record/FILTER_REQUEST",
  "calc-record/FILTER_SUCCESS",
  "calc-record/FILTER_FAILURE"
)<CalcRecordFilterPageRequest, CalcRecordFilterPageResult, NormalizedError>();

export const getCalcRecordRequestResponseActions = createAsyncAction(
  "calc-record/GET_REQUEST_RESPONSE_REQUEST",
  "calc-record/GET_REQUEST_RESPONSE_SUCCESS",
  "calc-record/GET_REQUEST_RESPONSE_FAILURE"
)<EntityIdObject, CalcRecord, NormalizedError>();

export const getCalcRecordWebservicesLogsActions = createAsyncAction(
  "calc-record/GET_WEBSERVICES_LOGS_REQUEST",
  "calc-record/GET_WEBSERVICES_LOGS_SUCCESS",
  "calc-record/GET_WEBSERVICES_LOGS_FAILURE"
)<EntityIdObject, CalcRecord, NormalizedError>();

export const getCalcRecordStackTracesLogsActions = createAsyncAction(
  "calc-record/GET_STACK_TRACES_LOGS_REQUEST",
  "calc-record/GET_STACK_TRACES_LOGS_SUCCESS",
  "calc-record/GET_STACK_TRACES_LOGS_FAILURE"
)<EntityIdObject, CalcRecord, NormalizedError>();

export const getCalcRecordAndRedirectToCalculatorActions = createAsyncAction(
  "calc-record/GET_AND_REDIRECT_REQUEST",
  "calc-record/GET_AND_REDIRECT_SUCCESS",
  "calc-record/GET_AND_REDIRECT_FAILURE"
)<EntityIdObject, void, NormalizedError>();

export const deleteStateCalcRecordsPageAction = createAction("calc-record/DELETE_STATE_LIST")<void>();
export const deleteStateCalcRecordDetailAction = createAction("calc-record/DELETE_STATE_DETAIL")<void>();

const actions = {
  filterCalcRecordsActions,
  getCalcRecordRequestResponseActions,
  getCalcRecordWebservicesLogsActions,
  getCalcRecordStackTracesLogsActions,
  getCalcRecordAndRedirectToCalculatorActions,
  deleteStateCalcRecordsPageAction,
  deleteStateCalcRecordDetailAction
};

export type CalcRecordAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: CalcRecordReducerState = {
  currentPage: {
    ...initPageResult<CalcRecordList>(),
    operationType: null,
    calcType: null,
    userId: null
  },
  calcRecordDetail: null
};

const currentPageReducer = createReducer(initialState.currentPage)
  .handleAction(filterCalcRecordsActions.success, (_, { payload }) => payload)
  .handleAction([filterCalcRecordsActions.failure, deleteStateCalcRecordsPageAction], () => initialState.currentPage);

const calcRecordDetailReducer = createReducer(initialState.calcRecordDetail)
  .handleAction(
    [
      getCalcRecordRequestResponseActions.success,
      getCalcRecordWebservicesLogsActions.success,
      getCalcRecordStackTracesLogsActions.success
    ],
    (_, { payload }) => payload
  )
  .handleAction(
    [
      getCalcRecordRequestResponseActions.failure,
      getCalcRecordWebservicesLogsActions.failure,
      getCalcRecordStackTracesLogsActions.failure,
      deleteStateCalcRecordDetailAction
    ],
    () => initialState.calcRecordDetail
  );

export const calcRecordsReducer = combineReducers<CalcRecordReducerState>({
  currentPage: currentPageReducer,
  calcRecordDetail: calcRecordDetailReducer
});

/**
 * SELECTORS
 */
const selectCalcRecords = (state: RootState): CalcRecordReducerState => state.calculator.records;

export const selectCalcRecordsCurrentPage = (state: RootState): CalcRecordFilterPageResult =>
  selectCalcRecords(state).currentPage;
export const selectCalcRecordDetail = (state: RootState): CalcRecord => selectCalcRecords(state).calcRecordDetail;

/**
 * SAGAS
 */
function* filterCalcRecords({ payload }: ReturnType<typeof filterCalcRecordsActions.request>) {
  try {
    const response: AxiosResponse<CalcRecordFilterPageResult> = yield call(api.filterCalcRecords, payload);
    yield put(filterCalcRecordsActions.success(response.data));
  } catch (error) {
    yield put(filterCalcRecordsActions.failure(error));
  }
}

function* getCalcRecordRequestResponse({ payload }: ReturnType<typeof getCalcRecordRequestResponseActions.request>) {
  try {
    const response: AxiosResponse<CalcRecord> = yield call(api.getCalcRecordRequestResponse, payload);
    yield put(getCalcRecordRequestResponseActions.success(response.data));
  } catch (error) {
    yield put(getCalcRecordRequestResponseActions.failure(error));
  }
}

function* getCalcRecordWebservicesLogs({ payload }: ReturnType<typeof getCalcRecordWebservicesLogsActions.request>) {
  try {
    const response: AxiosResponse<CalcRecord> = yield call(api.getCalcRecordWebservicesLogs, payload);
    yield put(getCalcRecordWebservicesLogsActions.success(response.data));
  } catch (error) {
    yield put(getCalcRecordWebservicesLogsActions.failure(error));
  }
}

function* getCalcRecordStackTracesLogs({ payload }: ReturnType<typeof getCalcRecordStackTracesLogsActions.request>) {
  try {
    const response: AxiosResponse<CalcRecord> = yield call(api.getCalcRecordStackTracesLogs, payload);
    yield put(getCalcRecordStackTracesLogsActions.success(response.data));
  } catch (error) {
    yield put(getCalcRecordStackTracesLogsActions.failure(error));
  }
}

function* getCalcRecordAndRedirectToCalculator({
  payload
}: ReturnType<typeof getCalcRecordAndRedirectToCalculatorActions.request>) {
  try {
    const response: AxiosResponse<CalcRecord> = yield call(api.getCalcRecordRequestResponse, payload);
    yield put(getCalcRecordAndRedirectToCalculatorActions.success());

    switch (response.data.calcType) {
      case CalcType.MTPL:
      case CalcType.CRASH:
      case CalcType.MTPL_CRASH:
      case CalcType.GAP:
      case CalcType.PAS:
        if (response.data.operationType === OperationType.CALCULATE) {
          yield put(setVehicleInitialCalcDataAction(response.data.request as VehicleCalc));
          yield put(
            setVehicleCalcResultsAction(
              sortAndGroupVehicleCalcResults(response.data.response as CalcResponse<VehicleCalcResultData>)
            )
          );
        } else {
          yield put(setVehicleInitialGenDataAction(response.data.request as VehicleGen));
        }
        yield put(push(CALC_ROUTE_PATHS.vehicle.to));
        break;
      case CalcType.TRAVEL:
        if (response.data.operationType === OperationType.CALCULATE) {
          yield put(setTravelInitialCalcDataAction(response.data.request as TravelCalc));
          yield put(
            setTravelCalcResultsAction(
              sortAndGroupCalcResults<TravelCalcResultData>(
                response.data.response as CalcResponse<TravelCalcResultData>
              )
            )
          );
        } else {
          yield put(setTravelInitialGenDataAction(response.data.request as TravelGen));
        }
        yield put(push(CALC_ROUTE_PATHS.travel.to));
        break;
      case CalcType.REALTY:
        if (response.data.operationType === OperationType.CALCULATE) {
          yield put(setRealtyInitialCalcDataAction(response.data.request as RealtyCalc));
          yield put(
            setRealtyCalcResultsAction(
              sortAndGroupCalcResults<RealtyCalcResultData>(
                response.data.response as CalcResponse<RealtyCalcResultData>
              )
            )
          );
        } else {
          yield put(setRealtyInitialGenDataAction(response.data.request as RealtyGen));
        }
        yield put(push(CALC_ROUTE_PATHS.realty.to));
        break;
    }
  } catch (error) {
    yield put(getCalcRecordAndRedirectToCalculatorActions.failure(error));
  }
}

export function* calcRecordsSaga() {
  yield takeLatest(filterCalcRecordsActions.request, filterCalcRecords);
  yield takeLatest(getCalcRecordRequestResponseActions.request, getCalcRecordRequestResponse);
  yield takeLatest(getCalcRecordWebservicesLogsActions.request, getCalcRecordWebservicesLogs);
  yield takeLatest(getCalcRecordStackTracesLogsActions.request, getCalcRecordStackTracesLogs);
  yield takeLatest(getCalcRecordAndRedirectToCalculatorActions.request, getCalcRecordAndRedirectToCalculator);
}
