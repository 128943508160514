import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Card, Modal, Tabs } from "antd";
import { RcFile } from "antd/lib/upload";
import React, { useEffect, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import UploadDragger from "../../../common/components/views/UploadDragger";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { Permission } from "../../../common/security/authorization/enums";
import { ActionProps, RootState, UUID } from "../../../common/types";
import { selectHasPermissions } from "../../auth/ducks";
import DocumentNodeMoveForm from "../components/forms/DocumentNodeMoveForm";
import DocumentNodeNameForm from "../components/forms/DocumentNodeNameForm";
import DocumentNodeBreadcrumbsView from "../components/views/DocumentNodeBreadcrumbsView";
import DocumentNodeListView from "../components/views/DocumentNodeListView";
import DocumentNodeToolbarMenuView from "../components/views/DocumentNodeToolbarMenuView";
import {
  createDocumentNodeActions,
  deleteDocumentNodesActions,
  deleteStateDocumentNodeTreeAction,
  downloadDocumentNodeActions,
  getDocumentNodeTreeActions,
  moveDocumentNodesActions,
  renameDocumentNodeActions,
  selectDocumentNodeTree,
  uploadDocumentNodesActions
} from "../ducks";
import { DocumentNodeTypeKey } from "../enums";
import {
  DocumentNodeMoveFormProps,
  DocumentNodeNameFormProps,
  DocumentNodeTree,
  DocumentNodeTreeViewProps,
  RootDocumentNodeTree
} from "../types";
import { getCurrentSubTree, getNodeFoldersTree, getNodeTypeByIndex } from "../utils";

const { confirm } = Modal;

interface StateProps {
  documentTree: RootDocumentNodeTree;
}

interface ActionMap {
  createDocumentNode: typeof createDocumentNodeActions.request;
  uploadDocumentNodes: typeof uploadDocumentNodesActions.request;
  moveDocumentNodes: typeof moveDocumentNodesActions.request;
  renameDocumentNode: typeof renameDocumentNodeActions.request;
  deleteDocumentNodes: typeof deleteDocumentNodesActions.request;
  getDocumentNodeTree: typeof getDocumentNodeTreeActions.request;
  downloadDocumentNode: typeof downloadDocumentNodeActions.request;
  deleteStateDocumentNodeTree: typeof deleteStateDocumentNodeTreeAction;
}

const DocumentNodeContainer = ({ documentTree, actions }: StateProps & ActionProps<ActionMap>) => {
  const hasNodesAdminPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.ADMIN_DOCUMENT_NODES)(state)
  );

  const [currentNodeTree, setCurrentNodeTree] = useState<DocumentNodeTreeViewProps[]>([{ nodes: [] }, { nodes: [] }]);
  const [currentNodeTypeIndex, setCurrentNodeTypeIndex] = useState<DocumentNodeTypeKey>(DocumentNodeTypeKey.GENERAL);
  const [selectedNodes, setSelectedNodes] = useState<DocumentNodeTree[]>([]);

  const [nameFormProps, setNameFormProps] = useState<DocumentNodeNameFormProps>({ open: false, type: null });
  const [moveFormProps, setMoveFormProps] = useState<DocumentNodeMoveFormProps>({
    open: false,
    nodes: [],
    treeData: []
  });

  const hasActionsPermission = useMemo(() => {
    return currentNodeTypeIndex === DocumentNodeTypeKey.GENERAL ? hasNodesAdminPermission : true;
  }, [currentNodeTypeIndex, hasNodesAdminPermission]);

  useEffect(() => {
    actions.getDocumentNodeTree();

    return () => {
      actions.deleteStateDocumentNodeTree();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newActualNodeTree = [...currentNodeTree];
    newActualNodeTree[currentNodeTypeIndex] = currentNodeTree[currentNodeTypeIndex].parent
      ? getCurrentSubTree(documentTree, currentNodeTypeIndex, currentNodeTree[currentNodeTypeIndex].parent.treePath)
      : getCurrentSubTree(documentTree, currentNodeTypeIndex);

    setCurrentNodeTree(newActualNodeTree);
    setSelectedNodes([]);
  }, [documentTree, currentNodeTypeIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabKeyChange = (key: string): void => {
    setSelectedNodes([]);
    setCurrentNodeTypeIndex(parseInt(key, 10));
  };

  const handleCreateFolderClick = (): void => {
    setNameFormProps({ open: true, type: "createFolder" });
  };

  const handleFolderCreate = (name: string): void => {
    actions.createDocumentNode({
      name,
      type: getNodeTypeByIndex(currentNodeTypeIndex),
      parentId: currentNodeTree[currentNodeTypeIndex]?.parent?.id
    });
  };

  const handleRenameNodeClick = (node: DocumentNodeTree): void => {
    setNameFormProps({ open: true, type: "renameNode", node: node });
  };

  const handleNodeRename = (node: DocumentNodeTree, name: string): void => {
    actions.renameDocumentNode({
      id: node.id,
      object: {
        optimisticLockVersion: node.optimisticLockVersion,
        name,
        type: node.type,
        parentId: currentNodeTree[currentNodeTypeIndex]?.parent?.id
      }
    });
  };

  const handleNameFormCancel = (): void => {
    setNameFormProps({ open: false, type: null, node: null });
  };

  const handleMoveNodesClick = (nodesToMove?: DocumentNodeTree[]): void => {
    const nodeType = getNodeTypeByIndex(currentNodeTypeIndex);

    setMoveFormProps({
      open: true,
      nodes: nodesToMove ?? selectedNodes,
      treeData: [
        {
          title: t("documentNode.enums." + nodeType),
          key: nodeType,
          children: getNodeFoldersTree(documentTree[currentNodeTypeIndex], nodesToMove ?? selectedNodes)
        }
      ]
    });
  };

  const handleNodesMove = (parentId?: UUID): void => {
    actions.moveDocumentNodes({ nodes: moveFormProps.nodes, parentId });
    setSelectedNodes([]);
  };

  const handleMoveFormCancel = (): void => {
    setMoveFormProps({ open: false, nodes: [], treeData: [] });
  };

  const handleDeleteNodesClick = (nodesToDelete?: DocumentNodeTree[]): void => {
    confirm({
      title: t("documentNode.helpers.deleteConfirmTitle"),
      icon: <ExclamationCircleOutlined />,
      content: t("documentNode.helpers.deleteConfirmDesc", {
        fileNames: (nodesToDelete ?? selectedNodes)
          .filter(node => currentNodeTree[currentNodeTypeIndex].nodes.some(n => n.id === node.id))
          .map(node => `'${node.name}'`)
          .join(", ")
      }),
      okText: t("common.yes"),
      okType: "danger",
      cancelText: t("common.cancel"),
      onOk: () => {
        handleNodesDelete(nodesToDelete ?? selectedNodes);
      }
    });
  };

  const handleNodesDelete = (nodesToDelete: DocumentNodeTree[]): void => {
    actions.deleteDocumentNodes(nodesToDelete.map(node => ({ id: node.id })));
    setSelectedNodes([]);
  };

  const handleFilesUpload = (_: RcFile, fileList: RcFile[]): boolean => {
    if (fileList.length > 0) {
      const formData = new FormData();

      if (currentNodeTree[currentNodeTypeIndex].parent) {
        formData.append("parentNodeId", currentNodeTree[currentNodeTypeIndex].parent.id);
      }

      formData.append("type", getNodeTypeByIndex(currentNodeTypeIndex));
      fileList.forEach(file => formData.append("files", file));
      fileList.splice(0, fileList.length);

      actions.uploadDocumentNodes(formData);
    }

    return false;
  };

  const handleNodeOpen = (tree: DocumentNodeTreeViewProps): void => {
    const newActualNodeTree = [...currentNodeTree];
    newActualNodeTree[currentNodeTypeIndex] = tree;

    setCurrentNodeTree(newActualNodeTree);
  };

  const handleFileDownload = (node: DocumentNodeTree): void => {
    actions.downloadDocumentNode({ id: node.id });
  };

  const handleBreadcrumbClick = (breadcrumb?: DocumentNodeTree): void => {
    const newActualNodeTree = [...currentNodeTree];
    newActualNodeTree[currentNodeTypeIndex] = getCurrentSubTree(
      documentTree,
      currentNodeTypeIndex,
      breadcrumb?.treePath
    );

    setCurrentNodeTree(newActualNodeTree);
  };

  return (
    <ContentWrapper>
      <Card id="documents" title={<h2>{t("documentNode.titles.list")}</h2>} className="documents card-box">
        <DocumentNodeToolbarMenuView
          hasPermission={hasActionsPermission}
          anyNodeSelected={selectedNodes.length > 0}
          onFilesUpload={handleFilesUpload}
          onMoveNodesClick={handleMoveNodesClick}
          onCreateFolderClick={handleCreateFolderClick}
          onDeleteNodesClick={handleDeleteNodesClick}
        />
        <div className="documents__main-wrapper">
          <div className="documents__navigation">
            <span className="documents__tabs-title">{t("documentNode.navigation.title")}</span>

            <DocumentNodeBreadcrumbsView
              breadcrumbs={currentNodeTree[currentNodeTypeIndex]?.breadcrumbs}
              treeNodeType={getNodeTypeByIndex(currentNodeTypeIndex)}
              onClick={handleBreadcrumbClick}
            />
          </div>

          <Tabs
            className="documents__tabs"
            tabPosition="left"
            onChange={handleTabKeyChange}
            items={currentNodeTree.map((node, index) => ({
              key: index.toString(),
              label: t("documentNode.enums." + getNodeTypeByIndex(index as DocumentNodeTypeKey)),
              children: (
                <div>
                  <DocumentNodeListView
                    hasPermission={hasActionsPermission}
                    currentNode={node}
                    currentNodeTree={currentNodeTree}
                    currentNodeTypeIndex={currentNodeTypeIndex}
                    documentTree={documentTree}
                    selectedNodes={selectedNodes}
                    onNodesSelect={setSelectedNodes}
                    onNodeOpen={handleNodeOpen}
                    onFileDownload={handleFileDownload}
                    onRenameNodeClick={handleRenameNodeClick}
                    onMoveNodesClick={handleMoveNodesClick}
                    onDeleteNodesClick={handleDeleteNodesClick}
                  />

                  {hasActionsPermission ? (
                    <div className="margin-top-large margin-bottom-small margin-left-small margin-right-small">
                      <UploadDragger multiple showUploadList={false} beforeUpload={handleFilesUpload} />
                    </div>
                  ) : null}
                </div>
              )
            }))}
          />
        </div>
      </Card>

      <DocumentNodeMoveForm
        formProps={moveFormProps}
        onNodesMove={handleNodesMove}
        onFormCancel={handleMoveFormCancel}
      />

      <DocumentNodeNameForm
        formProps={nameFormProps}
        onFolderCreate={handleFolderCreate}
        onNodeRename={handleNodeRename}
        onFormCancel={handleNameFormCancel}
      />
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  documentTree: selectDocumentNodeTree(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionMap> => ({
  actions: bindActionCreators(
    {
      createDocumentNode: createDocumentNodeActions.request,
      uploadDocumentNodes: uploadDocumentNodesActions.request,
      moveDocumentNodes: moveDocumentNodesActions.request,
      renameDocumentNode: renameDocumentNodeActions.request,
      deleteDocumentNodes: deleteDocumentNodesActions.request,
      getDocumentNodeTree: getDocumentNodeTreeActions.request,
      downloadDocumentNode: downloadDocumentNodeActions.request,
      deleteStateDocumentNodeTree: deleteStateDocumentNodeTreeAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(DocumentNodeContainer);
