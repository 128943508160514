import { Form, Input, Modal } from "antd";
import React, { useEffect } from "react";
import t from "../../../../app/i18n";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import { ModalSizes } from "../../../../common/constants";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { userUpdatePasswordActions } from "../../ducks";
import { UserProfile, UserUpdatePassword } from "../../types";

interface Props {
  open: boolean;
  user: UserProfile;
  onFormSubmit: typeof userUpdatePasswordActions.request;
  onFormCancel: () => void;
}

const UpdatePasswordForm = ({ open, user, onFormSubmit, onFormCancel }: Props) => {
  const [form] = Form.useForm<UserUpdatePassword>();
  useFormErrorHandler(form, "user.attrs", [requests.USER_UPDATE_PASSWORD]);

  useEffect(() => {
    if (open && user) {
      form.setFieldsValue({
        optimisticLockVersion: user.optimisticLockVersion,
        previousPassword: null,
        newPassword: null,
        newPasswordRepeat: null
      });
    }
  }, [open, user, form]);

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        onFormSubmit({
          id: user.id,
          object: values
        });
      })
      .catch(resolveFormValidationError);
  };

  const inProgress = useRequestFinishedCallback([requests.USER_UPDATE_PASSWORD], onFormCancel);

  return (
    <Modal
      width={ModalSizes.SMALL}
      open={open}
      title={t("user.titles.updatePassword")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="updatePasswordForm">
        <HiddenInput name="optimisticLockVersion" />

        <Form.Item name="previousPassword" label={t("user.attrs.previousPassword")} rules={[validations.notBlank]}>
          <Input.Password />
        </Form.Item>

        <Form.Item name="newPassword" label={t("user.attrs.newPassword")} rules={validations.sufficientPassword}>
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="newPasswordRepeat"
          label={t("user.attrs.newPasswordRepeat")}
          rules={[...validations.sufficientPassword, validations.repeatedPassword("newPassword")]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdatePasswordForm;
