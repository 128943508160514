import { message, notification } from "antd";
import { MessageType } from "antd/lib/message";
import { NotificationPlacement } from "antd/lib/notification";
import { ReactNode } from "react";
import t from "../../app/i18n";

const defaultMessageDuration: number = 5;
const defaultNotificationPlacement: NotificationPlacement = "topRight";
const defaultNotificationTopPosition: number = 74;

const infoMessage = (content: string, duration: number = defaultMessageDuration): MessageType =>
  message.info(content, duration);
const loadingMessage = (content: string, duration: number = defaultMessageDuration): MessageType =>
  message.loading(content, duration);
const successMessage = (content: string, duration: number = defaultMessageDuration): MessageType =>
  message.success(content, duration);
const warnMessage = (content: string, duration: number = defaultMessageDuration): MessageType =>
  message.warn(content, duration);
const errorMessage = (content: string, duration: number = defaultMessageDuration): MessageType =>
  message.error(content, duration);

const infoNotification = (
  message: string,
  description: string | ReactNode,
  duration: number = defaultMessageDuration,
  placement: NotificationPlacement = defaultNotificationPlacement,
  top: number = defaultNotificationTopPosition
): void => notification.info({ message, description, duration, placement, top });

const successNotification = (
  message: string,
  description: string | ReactNode,
  duration: number = defaultMessageDuration,
  placement: NotificationPlacement = defaultNotificationPlacement,
  top: number = defaultNotificationTopPosition
): void => notification.success({ message, description, duration, placement, top });

const warnNotification = (
  message: string,
  description: string | ReactNode,
  duration: number = defaultMessageDuration,
  placement: NotificationPlacement = defaultNotificationPlacement,
  top: number = defaultNotificationTopPosition
): void => notification.warn({ message, description, duration, placement, top });

const errorNotification = (
  message: string,
  description: string | ReactNode,
  duration: number = defaultMessageDuration,
  placement: NotificationPlacement = defaultNotificationPlacement,
  top: number = defaultNotificationTopPosition
): void => notification.error({ message, description, duration, placement, top });

const itemCreatedNotification = (): void =>
  successNotification(t("common.operationSuccess"), t("common.createOperationSuccess"));

const itemUpdatedNotification = (): void =>
  successNotification(t("common.operationSuccess"), t("common.updateOperationSuccess"));

const itemDeletedNotification = (): void =>
  successNotification(t("common.operationSuccess"), t("common.deleteOperationSuccess"));

const messageUtils = {
  infoMessage,
  loadingMessage,
  successMessage,
  warnMessage,
  errorMessage,
  infoNotification,
  successNotification,
  warnNotification,
  errorNotification,
  itemCreatedNotification,
  itemUpdatedNotification,
  itemDeletedNotification
};

export default messageUtils;
