import { Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState, UUID } from "../../../../common/types";
import { formatAgentName } from "../../../../common/utils/formatUtils";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import { selectTopAgentsEnums } from "../../ducks";
import { AgentEnumeration, FilterFunction } from "../../types";

interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
  optionsProps?: AgentOptionsProps;
}

interface AgentOptionsProps {
  filter?: FilterFunction<AgentEnumeration>;
  onChange?: (agent: AgentEnumeration) => void;
}

const TopAgentSelect = ({ formItemProps, selectProps, optionsProps }: Props) => {
  const topAgentEnums = useSelector<RootState, AgentEnumeration[]>(selectTopAgentsEnums);

  const resolveAgentSelectOptions = useCallback((): AgentEnumeration[] => {
    let options = [...topAgentEnums];

    if (optionsProps?.filter) {
      options = options.filter(optionsProps.filter);
    }

    return options;
  }, [topAgentEnums, optionsProps]);

  const onChange = (agentId: UUID, option: any): void => {
    selectProps?.onChange?.(agentId, option);
    optionsProps?.onChange?.(topAgentEnums.find(agent => agent.id === agentId));
  };

  return (
    <Form.Item {...formItemProps}>
      <Select
        {...selectStandardProps}
        {...selectProps}
        onChange={onChange}
        options={resolveAgentSelectOptions().map(agent => ({
          value: agent.id,
          label: formatAgentName(agent)
        }))}
      />
    </Form.Item>
  );
};

export default TopAgentSelect;
