import { Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import React from "react";
import { useSelector } from "react-redux";
import { DEFAULT_LOCALE } from "../../../../app/i18n";
import { RootState, UUID } from "../../../../common/types";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import { ProductFinancialSector } from "../../../product/enums";
import { ProductBase, ProductGroupBase } from "../../../product/types";
import { selectProductGroupsEnums, selectProductsClassificationEnums } from "../../ducks";
import { FilterFunction, InstitutionWithProducts, ProductGroupWithProducts } from "../../types";

interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
  optionsProps?: ProductGroupOptionsProps;
}

interface ProductGroupOptionsProps {
  selected?: ProductGroupBase;
  selectedProduct?: ProductBase;
  institutionId?: UUID;
  hideAll?: boolean;
  initialValuesSelected?: boolean;
  filterSectors?: ProductFinancialSector[];
  filter?: FilterFunction<ProductGroupBase>;
}

const ProductGroupSelect = ({ formItemProps, selectProps, optionsProps }: Props) => {
  const productsClassification = useSelector<RootState, InstitutionWithProducts[]>(selectProductsClassificationEnums);
  const productGroupsClassification = useSelector<RootState, ProductGroupWithProducts[]>(selectProductGroupsEnums);

  const resolveProductGroupSelectOptions = (): ProductGroupBase[] => {
    if (optionsProps?.hideAll) {
      return [];
    }

    let groups: ProductGroupWithProducts[];
    if (optionsProps?.institutionId) {
      const institutions = optionsProps?.institutionId
        ? productsClassification.filter(institution => institution.id === optionsProps.institutionId)
        : productsClassification;
      groups = institutions.flatMap(institution => institution.productGroups);
    } else {
      groups = [...productGroupsClassification];
    }

    if (optionsProps?.filterSectors?.length > 0) {
      groups = groups.filter(group =>
        group.products.some(product => optionsProps.filterSectors.includes(product.sector))
      );
    }

    if (optionsProps?.filter) {
      groups = groups.filter(optionsProps.filter);
    }

    if (
      optionsProps?.selected &&
      optionsProps.initialValuesSelected &&
      !groups.some(group => group.id === optionsProps.selected.id)
    ) {
      groups.unshift({ ...optionsProps.selected, products: [] });
    }

    if (
      optionsProps?.selectedProduct &&
      optionsProps.initialValuesSelected &&
      !groups.some(group => group.products.some(product => product.id === optionsProps.selectedProduct.id))
    ) {
      const group = productsClassification
        .flatMap(institution => institution.productGroups)
        .find(group => group.products.some(product => product.id === optionsProps.selectedProduct.id));
      if (group) {
        groups.push(group);
      }
    }

    const distinctGroups = [];
    groups.forEach(group => {
      if (distinctGroups.every(distinctGroup => distinctGroup.id !== group.id)) {
        distinctGroups.push(group);
      }
    });

    return distinctGroups.sort((a, b) => a.name.localeCompare(b.name, DEFAULT_LOCALE, { sensitivity: "accent" }));
  };

  return (
    <Form.Item {...formItemProps}>
      <Select
        {...selectStandardProps}
        {...selectProps}
        options={resolveProductGroupSelectOptions().map(g => ({ value: g.id, label: g.name }))}
      />
    </Form.Item>
  );
};

export default ProductGroupSelect;
