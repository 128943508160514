import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import {
  EntityIdObject,
  EntityObject,
  NormalizedError,
  RootState,
  SearchPageRequest,
  SearchPageResult
} from "../../common/types";
import { initSearchPageResult } from "../../common/utils/apiUtils";
import messageUtils from "../../common/utils/messageUtils";
import { replaceInArray } from "../../common/utils/utils";
import { changeRunningRequestKeyAction } from "../ducks";
import { getEnumerationsActions } from "../enumerations/ducks";
import api from "./api";
import {
  CreateUpdateProduct,
  CreateUpdateProductGroup,
  Product,
  ProductFilterPageRequest,
  ProductFilterPageResult,
  ProductGroup,
  ProductReducerState
} from "./types";

/**
 * ACTIONS
 */
export const filterProductGroupsActions = createAsyncAction(
  "product-group/FILTER_REQUEST",
  "product-group/FILTER_SUCCESS",
  "product-group/FILTER_FAILURE"
)<SearchPageRequest, SearchPageResult<ProductGroup>, NormalizedError>();

export const createProductGroupActions = createAsyncAction(
  "product-group/CREATE_REQUEST",
  "product-group/CREATE_SUCCESS",
  "product-group/CREATE_FAILURE"
)<CreateUpdateProductGroup, ProductGroup, NormalizedError>();

export const updateProductGroupActions = createAsyncAction(
  "product-group/UPDATE_REQUEST",
  "product-group/UPDATE_SUCCESS",
  "product-group/UPDATE_FAILURE"
)<EntityObject<CreateUpdateProductGroup>, ProductGroup, NormalizedError>();

export const deleteProductGroupActions = createAsyncAction(
  "product-group/DELETE_REQUEST",
  "product-group/DELETE_SUCCESS",
  "product-group/DELETE_FAILURE"
)<EntityIdObject, void, NormalizedError>();

export const deleteStateProductGroupsPageAction = createAction("product-group/DELETE_STATE_LIST")<void>();

export const filterProductsActions = createAsyncAction(
  "product/FILTER_REQUEST",
  "product/FILTER_SUCCESS",
  "product/FILTER_FAILURE"
)<ProductFilterPageRequest, ProductFilterPageResult, NormalizedError>();

export const createProductActions = createAsyncAction(
  "product/CREATE_REQUEST",
  "product/CREATE_SUCCESS",
  "product/CREATE_FAILURE"
)<CreateUpdateProduct, Product, NormalizedError>();

export const updateProductActions = createAsyncAction(
  "product/UPDATE_REQUEST",
  "product/UPDATE_SUCCESS",
  "product/UPDATE_FAILURE"
)<EntityObject<CreateUpdateProduct>, Product, NormalizedError>();

export const deleteProductActions = createAsyncAction(
  "product/DELETE_REQUEST",
  "product/DELETE_SUCCESS",
  "product/DELETE_FAILURE"
)<EntityIdObject, void, NormalizedError>();

export const deleteStateProductsPageAction = createAction("product/DELETE_STATE_LIST")<void>();

const actions = {
  filterProductGroupsActions,
  createProductGroupActions,
  updateProductGroupActions,
  deleteProductGroupActions,
  deleteStateProductGroupsPageAction,
  filterProductsActions,
  createProductActions,
  updateProductActions,
  deleteProductActions,
  deleteStateProductsPageAction
};

export type ProductAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: ProductReducerState = {
  productGroupsPage: initSearchPageResult<ProductGroup>(),
  productsPage: {
    ...initSearchPageResult<Product>(),
    sectors: [],
    groupIds: []
  }
};

const productGroupsPageReducer = createReducer(initialState.productGroupsPage)
  .handleAction(filterProductGroupsActions.success, (_, { payload }) => payload)
  .handleAction(updateProductGroupActions.success, (state, { payload }) => ({
    ...state,
    pageData: replaceInArray(
      state.pageData,
      item => item.id === payload.id,
      () => payload
    )
  }))
  .handleAction(
    [filterProductGroupsActions.failure, deleteStateProductGroupsPageAction],
    () => initialState.productGroupsPage
  );

const productsPageReducer = createReducer(initialState.productsPage)
  .handleAction(filterProductsActions.success, (_, { payload }) => payload)
  .handleAction(updateProductActions.success, (state, { payload }) => ({
    ...state,
    pageData: replaceInArray(
      state.pageData,
      item => item.id === payload.id,
      () => payload
    )
  }))
  .handleAction([filterProductsActions.failure, deleteStateProductsPageAction], () => initialState.productsPage);

export const productReducer = combineReducers<ProductReducerState>({
  productGroupsPage: productGroupsPageReducer,
  productsPage: productsPageReducer
});

/**
 * SELECTORS
 */
const selectProduct = (state: RootState): ProductReducerState => state.product;

export const selectProductGroupsPage = (state: RootState): SearchPageResult<ProductGroup> =>
  selectProduct(state).productGroupsPage;
export const selectProductsPage = (state: RootState): ProductFilterPageResult => selectProduct(state).productsPage;

/**
 * SAGAS
 */
function* filterProductGroups({ payload }: ReturnType<typeof filterProductGroupsActions.request>) {
  try {
    const response: AxiosResponse<SearchPageResult<ProductGroup>> = yield call(api.filterProductGroups, payload);
    yield put(filterProductGroupsActions.success(response.data));
  } catch (error) {
    yield put(filterProductGroupsActions.failure(error));
  }
}

function* createProductGroup({ payload }: ReturnType<typeof createProductGroupActions.request>) {
  try {
    const response: AxiosResponse<ProductGroup> = yield call(api.createProductGroup, payload);
    yield put(createProductGroupActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();

    const currentPage: SearchPageResult<ProductGroup> = yield select(selectProductGroupsPage);
    yield put(
      filterProductGroupsActions.request({
        pageIndex: currentPage.pageIndex,
        pageSize: currentPage.pageSize,
        keyword: currentPage.keyword
      })
    );
  } catch (error) {
    yield put(createProductGroupActions.failure(error));
  }
}

function* updateProductGroup({ payload }: ReturnType<typeof updateProductGroupActions.request>) {
  try {
    const response: AxiosResponse<ProductGroup> = yield call(api.updateProductGroup, payload);
    yield put(updateProductGroupActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(updateProductGroupActions.failure(error));
  }
}

function* deleteProductGroup({ payload }: ReturnType<typeof deleteProductGroupActions.request>) {
  try {
    yield call(api.deleteProductGroup, payload);
    yield put(deleteProductGroupActions.success());
    yield put(getEnumerationsActions.request());
    messageUtils.itemDeletedNotification();

    const currentPage: SearchPageResult<ProductGroup> = yield select(selectProductGroupsPage);
    yield put(
      filterProductGroupsActions.request({
        pageIndex: currentPage.pageElementsCount === 1 ? Math.max(currentPage.pageIndex - 1, 0) : currentPage.pageIndex,
        pageSize: currentPage.pageSize,
        keyword: currentPage.keyword
      })
    );
  } catch (error) {
    yield put(deleteProductGroupActions.failure(error));
  }
}

function* filterProducts({ payload }: ReturnType<typeof filterProductsActions.request>) {
  try {
    const response: AxiosResponse<ProductFilterPageResult> = yield call(api.filterProducts, payload);
    yield put(filterProductsActions.success(response.data));
  } catch (error) {
    yield put(filterProductsActions.failure(error));
  }
}

function* createProduct({ payload }: ReturnType<typeof createProductActions.request>) {
  try {
    const response: AxiosResponse<Product> = yield call(api.createProduct, payload);
    yield put(createProductActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();

    const currentPage: ProductFilterPageResult = yield select(selectProductsPage);
    yield put(
      filterProductsActions.request({
        pageIndex: currentPage.pageIndex,
        pageSize: currentPage.pageSize,
        keyword: currentPage.keyword,
        sectors: currentPage.sectors,
        groupIds: currentPage.groupIds
      })
    );
  } catch (error) {
    yield put(createProductActions.failure(error));
  }
}

function* updateProduct({ payload }: ReturnType<typeof updateProductActions.request>) {
  try {
    const response: AxiosResponse<Product> = yield call(api.updateProduct, payload);
    yield put(updateProductActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(updateProductActions.failure(error));
  }
}

function* deleteProduct({ payload }: ReturnType<typeof deleteProductActions.request>) {
  try {
    yield call(api.deleteProduct, payload);
    yield put(deleteProductActions.success());
    yield put(getEnumerationsActions.request());
    yield put(changeRunningRequestKeyAction());

    const currentPage: ProductFilterPageResult = yield select(selectProductsPage);
    yield put(
      filterProductsActions.request({
        pageIndex: currentPage.pageElementsCount === 1 ? Math.max(currentPage.pageIndex - 1, 0) : currentPage.pageIndex,
        pageSize: currentPage.pageSize,
        keyword: currentPage.keyword,
        sectors: currentPage.sectors,
        groupIds: currentPage.groupIds
      })
    );
  } catch (error) {
    yield put(deleteProductActions.failure(error));
  }
}

export function* productSaga() {
  yield takeLatest(filterProductGroupsActions.request, filterProductGroups);
  yield takeLatest(createProductGroupActions.request, createProductGroup);
  yield takeLatest(updateProductGroupActions.request, updateProductGroup);
  yield takeLatest(deleteProductGroupActions.request, deleteProductGroup);
  yield takeLatest(filterProductsActions.request, filterProducts);
  yield takeLatest(createProductActions.request, createProduct);
  yield takeLatest(updateProductActions.request, updateProduct);
  yield takeLatest(deleteProductActions.request, deleteProduct);
}
