import { Tooltip } from "antd";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import t from "../../../app/i18n";
import messageUtils from "../../utils/messageUtils";
import AntIcon from "../icons/AntIcon";

interface Props {
  content: string;
  wrapper?: React.ReactElement | string;
}

const CopyToClipboardView = ({ content, wrapper }: Props) => {
  return content ? (
    <span className="copy-to-clipboard-view">
      {wrapper ?? content}&nbsp;
      <CopyToClipboard text={content} onCopy={() => messageUtils.infoMessage(t("common.clipboardCopied"))}>
        <Tooltip title={t("common.copy")} mouseEnterDelay={0.5}>
          <span>
            <AntIcon type="copy" />
          </span>
        </Tooltip>
      </CopyToClipboard>
    </span>
  ) : null;
};

export default CopyToClipboardView;
