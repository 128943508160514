import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect } from "react";
import t from "../../../../../app/i18n";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../common/constants";
import { selectStandardProps } from "../../../../../common/utils/formUtils";
import { validationConstants, validations } from "../../../../../common/utils/validationUtils";
import { FuelType } from "../../../../contract/enums";
import { FuelNameSource } from "../../enums";
import { CertificateFuelTypeFilterPageRequest, CertificateFuelTypeFilterPageResult } from "../../types";

interface Props {
  filter: CertificateFuelTypeFilterPageResult;
  onFilterSubmit: (filter: CertificateFuelTypeFilterPageRequest) => void;
  onCreateClick: () => void;
}

const VehicleFuelTypeFilterView = ({ filter, onFilterSubmit, onCreateClick }: Props) => {
  const [form] = Form.useForm<CertificateFuelTypeFilterPageRequest>();
  const colSpan = 6;

  useEffect(() => {
    form.setFieldsValue({
      keyword: filter.keyword,
      fuelTypes: filter.fuelTypes || [],
      fuelNameSources: filter.fuelNameSources || []
    });
  }, [filter, form]);

  return (
    <Card
      className="card-box"
      title={<h2>{t("vehicleFuelType.titles.pageTitle")}</h2>}
      extra={
        <Button type="primary" icon={<AntIcon type="plus" />} onClick={onCreateClick}>
          {t("vehicleFuelType.actions.createFuelType")}
        </Button>
      }
    >
      <Form form={form} onFinish={onFilterSubmit} layout="vertical" name="vehicleFuelTypeSearchForm">
        <Row gutter={rowGutter} justify="center">
          <Col span={colSpan}>
            <Form.Item
              name="keyword"
              label={t("common.search")}
              rules={[
                validations.size(
                  validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
                  validationConstants.SEARCH_KEYWORD_MAX_LENGTH
                )
              ]}
            >
              <Input allowClear placeholder={t("vehicleFuelType.helpers.filterSearchPlaceholder")} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item name="fuelTypes" label={t("contract.enums.fuelType._label")} rules={[validations.none]}>
              <Select
                {...selectStandardProps}
                mode="multiple"
                maxTagCount="responsive"
                allowClear
                options={Object.keys(FuelType).map(fuelType => ({
                  value: fuelType,
                  label: t("contract.enums.fuelType." + fuelType)
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="fuelNameSources"
              label={t("vehicle.enums.fuelNameSource._label")}
              rules={[validations.none]}
            >
              <Select
                {...selectStandardProps}
                mode="multiple"
                maxTagCount="responsive"
                allowClear
                options={Object.keys(FuelNameSource).map(fuelNameSource => ({
                  value: fuelNameSource,
                  label: t("vehicle.enums.fuelNameSource." + fuelNameSource)
                }))}
              />
            </Form.Item>
          </Col>

          <Col flex="110px" className="no-title-space">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default VehicleFuelTypeFilterView;
