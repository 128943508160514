import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import AntIcon from "../../../common/components/icons/AntIcon";
import { PageSizes } from "../../../common/constants";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState, SearchPageRequest, SearchPageResult } from "../../../common/types";
import { appendSearchParamsToURL, numberOrZero } from "../../../common/utils/utils";
import { selectRouterLocationSearch } from "../../ducks";
import LifeInsuranceTariffForm from "../components/forms/LifeInsuranceTariffForm";
import LifeInsuranceTariffGroupForm from "../components/forms/LifeInsuranceTariffGroupForm";
import LifeInsuranceTariffFilterView from "../components/views/LifeInsuranceTariffFilterView";
import LifeInsuranceTariffGroupFilterView from "../components/views/LifeInsuranceTariffGroupFilterView";
import LifeInsuranceTariffGroupTableView from "../components/views/LifeInsuranceTariffGroupTableView";
import LifeInsuranceTariffTableView from "../components/views/LifeInsuranceTariffTableView";
import {
  createLifeInsuranceTariffActions,
  createLifeInsuranceTariffGroupActions,
  deleteLifeInsuranceTariffActions,
  deleteLifeInsuranceTariffGroupActions,
  deleteStateLifeInsuranceTariffGroupsPageAction,
  deleteStateLifeInsuranceTariffsPageAction,
  filterLifeInsuranceTariffGroupsActions,
  filterLifeInsuranceTariffsActions,
  selectLifeInsuranceTariffGroupsPage,
  selectLifeInsuranceTariffsPage,
  updateLifeInsuranceTariffActions,
  updateLifeInsuranceTariffGroupActions
} from "../ducks";
import {
  LifeInsuranceTariff,
  LifeInsuranceTariffFilterPageRequest,
  LifeInsuranceTariffFilterPageResult,
  LifeInsuranceTariffGroup
} from "../types";

interface StateProps {
  urlSearchQuery: string;
  tariffGroupsPage: SearchPageResult<LifeInsuranceTariffGroup>;
  tariffsPage: LifeInsuranceTariffFilterPageResult;
}

interface ActionsMap {
  filterLifeInsuranceTariffGroups: typeof filterLifeInsuranceTariffGroupsActions.request;
  createLifeInsuranceTariffGroup: typeof createLifeInsuranceTariffGroupActions.request;
  updateLifeInsuranceTariffGroup: typeof updateLifeInsuranceTariffGroupActions.request;
  deleteLifeInsuranceTariffGroup: typeof deleteLifeInsuranceTariffGroupActions.request;
  deleteStateLifeInsuranceTariffGroupsPage: typeof deleteStateLifeInsuranceTariffGroupsPageAction;
  filterLifeInsuranceTariff: typeof filterLifeInsuranceTariffsActions.request;
  createLifeInsuranceTariff: typeof createLifeInsuranceTariffActions.request;
  updateLifeInsuranceTariff: typeof updateLifeInsuranceTariffActions.request;
  deleteLifeInsuranceTariff: typeof deleteLifeInsuranceTariffActions.request;
  deleteStateLifeInsuranceTariffsPage: typeof deleteStateLifeInsuranceTariffsPageAction;
}

const TAB = {
  TARIFF_GROUPS: "tariff-groups",
  TARIFFS: "tariffs"
};

const LifeInsuranceTariffsContainer = (props: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();

  const [tabKey, setTabKey] = useState<string>(TAB.TARIFFS);

  const [tariffGroupFormOpen, setTariffGroupFormOpen] = useState<boolean>(false);
  const [tariffGroupToUpdate, setTariffGroupToUpdate] = useState<LifeInsuranceTariffGroup>();

  const [tariffFormOpen, setTariffFormOpen] = useState<boolean>(false);
  const [tariffToUpdate, setTariffToUpdate] = useState<LifeInsuranceTariff>();

  useEffect(() => {
    const urlParams = new URLSearchParams(props.urlSearchQuery);

    const tab = Object.values(TAB).includes(urlParams.get("tab")) ? urlParams.get("tab") : TAB.TARIFFS;
    setTabKey(tab);

    switch (tab) {
      case TAB.TARIFF_GROUPS:
        props.actions.filterLifeInsuranceTariffGroups({
          pageIndex: numberOrZero(urlParams.get("pageIndex")),
          pageSize: PageSizes.LARGE,
          keyword: urlParams.get("keyword")
        });
        break;
      case TAB.TARIFFS:
        props.actions.filterLifeInsuranceTariff({
          pageIndex: numberOrZero(urlParams.get("pageIndex")),
          pageSize: PageSizes.LARGE,
          keyword: urlParams.get("keyword"),
          groupIds: urlParams.getAll("groupIds")
        });
        break;
    }
    return () => {
      props.actions.deleteStateLifeInsuranceTariffGroupsPage();
      props.actions.deleteStateLifeInsuranceTariffsPage();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabKeyChange = (tab: string): void => {
    navigate(appendSearchParamsToURL({ pageIndex: null, keyword: null, groupIds: null, tab }), { replace: true });
    setTabKey(tab);

    switch (tab) {
      case TAB.TARIFF_GROUPS:
        props.actions.filterLifeInsuranceTariffGroups({
          pageIndex: 0,
          pageSize: PageSizes.LARGE,
          keyword: null
        });
        break;
      case TAB.TARIFFS:
        props.actions.filterLifeInsuranceTariff({
          pageIndex: 0,
          pageSize: PageSizes.LARGE,
          keyword: null,
          groupIds: []
        });
        break;
    }
  };

  const handleTariffGroupsFilterSubmit = (filter: SearchPageRequest): void => {
    const tabParam = new URLSearchParams(props.urlSearchQuery).get("tab");
    navigate(
      appendSearchParamsToURL({
        pageIndex: null,
        keyword: !!filter.keyword ? filter.keyword : null,
        tab: !!tabParam ? tabParam : null
      }),
      { replace: true }
    );
    props.actions.filterLifeInsuranceTariffGroups({
      pageIndex: 0,
      pageSize: props.tariffGroupsPage.pageSize,
      keyword: filter.keyword
    });
  };

  const handleTariffGroupsPageChange = (pageNumber: number): void => {
    const { pageSize, keyword } = props.tariffGroupsPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    props.actions.filterLifeInsuranceTariffGroups({ pageIndex: pageNumber - 1, pageSize, keyword });
  };

  const handleTariffGroupCreateClick = (): void => {
    setTariffGroupFormOpen(true);
  };

  const handleTariffGroupUpdateClick = (group: LifeInsuranceTariffGroup): void => {
    setTariffGroupFormOpen(true);
    setTariffGroupToUpdate(group);
  };

  const handleTariffGroupFormCancel = (): void => {
    setTariffGroupFormOpen(false);
    setTariffGroupToUpdate(null);
  };

  const handleTariffsFilterSubmit = (filter: LifeInsuranceTariffFilterPageRequest): void => {
    const tabParam = new URLSearchParams(props.urlSearchQuery).get("tab");
    navigate(
      appendSearchParamsToURL({
        ...filter,
        pageIndex: null,
        keyword: !!filter.keyword ? filter.keyword : null,
        tab: !!tabParam ? tabParam : null
      }),
      { replace: true }
    );
    props.actions.filterLifeInsuranceTariff({
      pageIndex: 0,
      pageSize: props.tariffsPage.pageSize,
      keyword: filter.keyword,
      groupIds: filter.groupIds
    });
  };

  const handleTariffsPageChange = (pageNumber: number): void => {
    const { pageSize, keyword, groupIds } = props.tariffsPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    props.actions.filterLifeInsuranceTariff({
      pageIndex: pageNumber - 1,
      pageSize,
      keyword,
      groupIds
    });
  };

  const handleTariffCreateClick = (): void => {
    setTariffFormOpen(true);
  };

  const handleTariffUpdateClick = (tariff: LifeInsuranceTariff): void => {
    setTariffFormOpen(true);
    setTariffToUpdate(tariff);
  };

  const handleTariffFormCancel = (): void => {
    setTariffFormOpen(false);
    setTariffToUpdate(null);
  };

  return (
    <ContentWrapper>
      <Tabs
        className="tabs-box"
        activeKey={tabKey}
        onChange={handleTabKeyChange}
        items={[
          {
            key: TAB.TARIFF_GROUPS,
            label: (
              <span>
                <AntIcon type="group" />
                {t("lifeInsuranceTariff.titles.tariffGroups")}
              </span>
            ),
            children: (
              <div className="margin-top-small">
                <LifeInsuranceTariffGroupFilterView
                  currentFilter={props.tariffGroupsPage}
                  onFilterSubmit={handleTariffGroupsFilterSubmit}
                  onCreateClick={handleTariffGroupCreateClick}
                />

                <LifeInsuranceTariffGroupTableView
                  tariffGroupsPage={props.tariffGroupsPage}
                  onPageChange={handleTariffGroupsPageChange}
                  onUpdateClick={handleTariffGroupUpdateClick}
                  onDelete={props.actions.deleteLifeInsuranceTariffGroup}
                />

                <LifeInsuranceTariffGroupForm
                  open={tariffGroupFormOpen}
                  tariffGroup={tariffGroupToUpdate}
                  onCreate={props.actions.createLifeInsuranceTariffGroup}
                  onUpdate={props.actions.updateLifeInsuranceTariffGroup}
                  onFormCancel={handleTariffGroupFormCancel}
                />
              </div>
            )
          },
          {
            key: TAB.TARIFFS,
            label: (
              <span>
                <AntIcon type="unordered-list" />
                {t("lifeInsuranceTariff.titles.tariffs")}
              </span>
            ),
            children: (
              <div className="margin-top-small">
                <LifeInsuranceTariffFilterView
                  currentFilter={props.tariffsPage}
                  onFilterSubmit={handleTariffsFilterSubmit}
                  onCreateClick={handleTariffCreateClick}
                />

                <LifeInsuranceTariffTableView
                  tariffsPage={props.tariffsPage}
                  onPageChange={handleTariffsPageChange}
                  onUpdateClick={handleTariffUpdateClick}
                  onDelete={props.actions.deleteLifeInsuranceTariff}
                />

                <LifeInsuranceTariffForm
                  open={tariffFormOpen}
                  tariff={tariffToUpdate}
                  onCreate={props.actions.createLifeInsuranceTariff}
                  onUpdate={props.actions.updateLifeInsuranceTariff}
                  onFormCancel={handleTariffFormCancel}
                />
              </div>
            )
          }
        ]}
      />
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  urlSearchQuery: selectRouterLocationSearch(state),
  tariffGroupsPage: selectLifeInsuranceTariffGroupsPage(state),
  tariffsPage: selectLifeInsuranceTariffsPage(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterLifeInsuranceTariffGroups: filterLifeInsuranceTariffGroupsActions.request,
      createLifeInsuranceTariffGroup: createLifeInsuranceTariffGroupActions.request,
      updateLifeInsuranceTariffGroup: updateLifeInsuranceTariffGroupActions.request,
      deleteLifeInsuranceTariffGroup: deleteLifeInsuranceTariffGroupActions.request,
      deleteStateLifeInsuranceTariffGroupsPage: deleteStateLifeInsuranceTariffGroupsPageAction,
      filterLifeInsuranceTariff: filterLifeInsuranceTariffsActions.request,
      createLifeInsuranceTariff: createLifeInsuranceTariffActions.request,
      updateLifeInsuranceTariff: updateLifeInsuranceTariffActions.request,
      deleteLifeInsuranceTariff: deleteLifeInsuranceTariffActions.request,
      deleteStateLifeInsuranceTariffsPage: deleteStateLifeInsuranceTariffsPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(LifeInsuranceTariffsContainer);
