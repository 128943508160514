import { Button, Checkbox, Col, Divider, Form, Input, List, Modal, Popconfirm, Row, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { useEffect, useState } from "react";
import t from "../../../../../app/i18n";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import { PageSizes, rowGutter } from "../../../../../common/constants";
import { RootArrayFormObject, UUID } from "../../../../../common/types";
import { selectStandardProps, useFormErrorHandler } from "../../../../../common/utils/formUtils";
import messageUtils from "../../../../../common/utils/messageUtils";
import { contains, paginationStandardProps, removeFromArray, replaceInArray } from "../../../../../common/utils/utils";
import { validationConstants, validationFunctions, validations } from "../../../../../common/utils/validationUtils";
import { requests } from "../../api";
import {
  createVehicleBrandMappingActions,
  filterVehicleBrandMappingsActions,
  updateVehicleBrandMappingsActions
} from "../../ducks";
import { VehicleMappingType } from "../../enums";
import {
  UpdateVehicleMapping,
  UpdateVehicleMappingFormItem,
  VehicleBrandMappingFilterPageResult,
  VehicleMapping,
  VehicleMappingData,
  VehicleMappingFilterPageRequest
} from "../../types";
import {
  parseVehicleMappingsFromIdentifiers,
  parseVehicleMappingsToUpdateFormItems,
  VEHICLE_IDENTIFIER_DELIMITER,
  VEHICLE_TYPES_PATH_MAP
} from "../../utils";
import VehicleBrandCreateMappingModal from "./VehicleBrandCreateMappingModal";

interface Props {
  selectedMappingTypes: VehicleMappingType[];
  mappingData: VehicleMappingData[];
  currentPage: VehicleBrandMappingFilterPageResult;
  onFilter: typeof filterVehicleBrandMappingsActions.request;
  onCreate: typeof createVehicleBrandMappingActions.request;
  onUpdate: typeof updateVehicleBrandMappingsActions.request;
  onShowModelsClick: (brandMappingId: UUID) => void;
}

const VehicleBrandMappingsListForm = ({ selectedMappingTypes, mappingData, currentPage, ...props }: Props) => {
  const [form] = Form.useForm<RootArrayFormObject<UpdateVehicleMappingFormItem>>();
  useFormErrorHandler(form, "vehicle.attrs", [requests.UPDATE_VEHICLE_BRAND_MAPPINGS]);

  const [createMappingFormOpen, setCreateMappingFormOpen] = useState<boolean>(false);
  const [updatedRowsIds, setUpdatedRowsIds] = useState<UUID[]>([]);

  useEffect(() => {
    props.onFilter({
      keyword: currentPage.keyword,
      pageIndex: 0,
      pageSize: PageSizes.SMALL,
      queriedMappings: selectedMappingTypes,
      excludeMapped: currentPage.excludeMapped
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    form.setFieldsValue({
      items: parseVehicleMappingsToUpdateFormItems(...currentPage.pageData)
    });
    setUpdatedRowsIds([]);
  }, [currentPage, form]);

  const handleFormFinish = (values: RootArrayFormObject<UpdateVehicleMappingFormItem>): void => {
    if (updatedRowsIds.length > 0) {
      props.onUpdate({
        mappings: values.items
          .filter(mapping => contains(updatedRowsIds, mapping.id))
          .map<UpdateVehicleMapping>(mapping => parseVehicleMappingsFromIdentifiers(mapping) as UpdateVehicleMapping)
      });
    } else {
      messageUtils.warnMessage(t("vehicle.validations.noChanges"));
    }
  };

  const handleSearchSubmit = (keyword: string): void => {
    if (validationFunctions.validateSearchKeyword(keyword)) {
      checkUnsavedChangesBeforePageChange({
        keyword,
        pageIndex: 0,
        pageSize: currentPage.pageSize,
        queriedMappings: selectedMappingTypes,
        excludeMapped: currentPage.excludeMapped
      });
    } else {
      messageUtils.errorMessage(
        t("validation.size", {
          min: validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
          max: validationConstants.SEARCH_KEYWORD_MAX_LENGTH
        })
      );
    }
  };

  const handleExcludeMappedChange = (e: CheckboxChangeEvent): void => {
    checkUnsavedChangesBeforePageChange({
      keyword: currentPage.keyword,
      pageIndex: 0,
      pageSize: currentPage.pageSize,
      queriedMappings: selectedMappingTypes,
      excludeMapped: e.target.checked
    });
  };

  const handleListPageChange = (pageNumber: number): void => {
    checkUnsavedChangesBeforePageChange({
      keyword: currentPage.keyword,
      pageIndex: pageNumber - 1,
      pageSize: currentPage.pageSize,
      queriedMappings: selectedMappingTypes,
      excludeMapped: currentPage.excludeMapped
    });
  };

  const handleMappingRowChange = (id: UUID): void => {
    if (!contains(updatedRowsIds, id)) {
      setUpdatedRowsIds([...updatedRowsIds, id]);
    }
  };

  const handleRowResetClick = (rowId: UUID): void => {
    const row = currentPage.pageData.find(row => row.id === rowId);
    if (row) {
      const allRows = form.getFieldsValue().items;
      form.setFieldsValue({
        items: replaceInArray(
          allRows,
          item => item.id === rowId,
          () => parseVehicleMappingsToUpdateFormItems(row)[0]
        )
      });
      setUpdatedRowsIds(removeFromArray(updatedRowsIds, stateRowId => stateRowId === rowId));
    }
  };

  const checkUnsavedChangesBeforePageChange = (request: VehicleMappingFilterPageRequest): void => {
    if (updatedRowsIds.length > 0) {
      Modal.confirm({
        title: t("common.unsavedChanges"),
        okText: t("common.continue"),
        cancelText: t("common.back"),
        onOk: () => {
          props.onFilter(request);
        }
      });
    } else {
      props.onFilter(request);
    }
  };

  return (
    <>
      <Divider orientation="left">{t("vehicle.titles.brandsMapping")}</Divider>

      <Row gutter={rowGutter} className="margin-bottom-small">
        <Col span={16}>
          <Input.Search
            style={{ width: "350px" }}
            enterButton
            allowClear
            defaultValue={currentPage.keyword}
            placeholder={t("vehicle.helpers.searchBrandHint")}
            onSearch={handleSearchSubmit}
          />

          <span className="margin-left-small">
            <Checkbox
              style={{ marginTop: "4px" }}
              checked={currentPage.excludeMapped}
              onChange={handleExcludeMappedChange}
            >
              {t("vehicle.actions.excludeMapped")}
            </Checkbox>
          </span>
        </Col>

        <Col span={8} className="right-align">
          <Button
            className="secondary-button"
            icon={<AntIcon type="plus" />}
            onClick={() => setCreateMappingFormOpen(true)}
          >
            {t("vehicle.actions.createBrand")}
          </Button>
        </Col>
      </Row>

      <Form form={form} layout="vertical" onFinish={handleFormFinish} name="vehicleBrandMappingsListForm">
        <List<VehicleMapping>
          itemLayout="vertical"
          dataSource={currentPage.pageData}
          pagination={{
            ...paginationStandardProps,
            current: currentPage.pageIndex + 1,
            pageSize: currentPage.pageSize,
            total: currentPage.totalElementsCount,
            onChange: handleListPageChange
          }}
          renderItem={(item, rowIndex) => (
            <List.Item key={rowIndex}>
              <HiddenInput name={["items", rowIndex, "id"]} />
              <HiddenInput name={["items", rowIndex, "optimisticLockVersion"]} />

              <Row
                gutter={rowGutter}
                className={"vehicle-mappings-row" + (contains(updatedRowsIds, item.id) ? " edited-row" : "")}
              >
                <Col span={4}>
                  <Form.Item
                    name={["items", rowIndex, "name"]}
                    label={t("vehicle.attrs.name")}
                    rules={[validations.notBlank]}
                  >
                    <Input size="small" onChange={() => handleMappingRowChange(item.id)} />
                  </Form.Item>
                </Col>

                <Col span={16}>
                  <Row gutter={rowGutter}>
                    {Object.values(VehicleMappingType).map((type, index) => {
                      const typePath = VEHICLE_TYPES_PATH_MAP.get(type);
                      const mapping = mappingData.find(mapping => mapping.type === type);

                      return (
                        <React.Fragment key={index}>
                          <HiddenInput name={["items", rowIndex, `${typePath}Id`]} />
                          <HiddenInput name={["items", rowIndex, `${typePath}Name`]} />

                          {contains(selectedMappingTypes, type) ? (
                            <Col span={6} key={index}>
                              <Form.Item
                                name={["items", rowIndex, `${typePath}Identifier`]}
                                label={t(`vehicle.attrs.${typePath}Identifier`)}
                                rules={[validations.none]}
                              >
                                <Select
                                  {...selectStandardProps}
                                  allowClear
                                  size="small"
                                  options={(mapping && mapping.brands ? mapping.brands : []).map(item => ({
                                    value: item.id + VEHICLE_IDENTIFIER_DELIMITER + item.name,
                                    label: `${item.name} (${item.id})`
                                  }))}
                                  onChange={() => handleMappingRowChange(item.id)}
                                />
                              </Form.Item>
                            </Col>
                          ) : (
                            <HiddenInput name={["items", rowIndex, `${typePath}Identifier`]} />
                          )}
                        </React.Fragment>
                      );
                    })}
                  </Row>
                </Col>

                <Col span={4} className="right-align">
                  <Button
                    size="small"
                    icon={<AntIcon type="ordered-list" />}
                    className="secondary-button margin-bottom-tiny"
                    style={{ width: "100px" }}
                    onClick={() => props.onShowModelsClick(item.id)}
                  >
                    {t("vehicle.actions.showModels")}
                  </Button>
                  <br />

                  <Button
                    size="small"
                    icon={<AntIcon type="reload" />}
                    className="margin-bottom-tiny"
                    style={{ width: "100px" }}
                    onClick={() => handleRowResetClick(item.id)}
                  >
                    {t("common.reset")}
                  </Button>
                  <br />

                  <Popconfirm
                    title={t("vehicle.titles.brandDelete")}
                    icon={<PopconfirmDeleteIcon />}
                    okText={t("common.yes")}
                    cancelText={t("common.no")}
                    okType="danger"
                    disabled
                  >
                    <Button
                      size="small"
                      className="margin-bottom-tiny"
                      disabled
                      danger
                      icon={<AntIcon type="delete" />}
                      style={{ width: "100px" }}
                    >
                      {t("common.delete")}
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            </List.Item>
          )}
        />

        <Button className="margin-top-medium" type="primary" htmlType="submit" icon={<AntIcon type="save" />}>
          {t("common.save")}
        </Button>
      </Form>

      <VehicleBrandCreateMappingModal
        open={createMappingFormOpen}
        selectedMappingTypes={selectedMappingTypes}
        mappingData={mappingData}
        onCreate={props.onCreate}
        onFormCancel={() => setCreateMappingFormOpen(false)}
      />
    </>
  );
};

export default VehicleBrandMappingsListForm;
