import { Col, Divider, Row } from "antd";
import React from "react";
import t from "../../../../../app/i18n";
import ItemCreatedUpdatedInfoView from "../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { rowGutter } from "../../../../../common/constants";
import ContactsView from "../../../../../common/modules/contact/ContactsView";
import { formatAddress, formatAgentIdNumber } from "../../../../../common/utils/formatUtils";
import AgentActiveTag from "../../../../agent/components/AgentActiveTag";
import AgentCanBeGainerTag from "../../../../agent/components/AgentCanBeGainerTag";
import AgentTipperTag from "../../../../agent/components/AgentTipperTag";
import LegalAgentDataView from "../../../../agent/components/views/detail/agent/LegalAgentDataView";
import NaturalAgentDataView from "../../../../agent/components/views/detail/agent/NaturalAgentDataView";
import SelfEmployedAgentDataView from "../../../../agent/components/views/detail/agent/SelfEmployedAgentDataView";
import AgentProfilePictureView from "../../../../agent/components/views/profilepicture/AgentProfilePictureView";
import { AgentType } from "../../../../agent/enums";
import { Agent, LegalAgent, NaturalAgent, SelfEmployedAgent } from "../../../../agent/types";

interface Props {
  agent: Agent;
}

const UserProfileUserAccountView = ({ agent }: Props) => {
  if (agent) {
    let dataView;

    switch (agent.type) {
      case AgentType.NATURAL:
        dataView = <NaturalAgentDataView agent={agent as NaturalAgent} />;
        break;
      case AgentType.SELF_EMPLOYED:
        dataView = <SelfEmployedAgentDataView agent={agent as SelfEmployedAgent} />;
        break;
      case AgentType.LEGAL:
        dataView = <LegalAgentDataView agent={agent as LegalAgent} />;
        break;
    }

    return (
      <>
        <ItemCreatedUpdatedInfoView item={agent} className="margin-bottom-tiny" />

        <Divider orientation="left">{t("agent.titles.basicData")}</Divider>

        <Row gutter={rowGutter}>
          <Col span={18}>
            <AgentTipperTag tipper={agent.tipper} />
            <AgentCanBeGainerTag canBeGainer={agent.canBeGainer} />
            <AgentActiveTag agentDeactivated={agent.deactivated} />

            <table className="data-table-view margin-top-small">
              <tbody>
                <tr>
                  <td>{t("agent.attrs.idNumber")}:</td>
                  <td colSpan={3}>{formatAgentIdNumber(agent)}</td>
                </tr>
                {dataView}
              </tbody>
            </table>
          </Col>

          <Col span={6} className="center-align">
            <AgentProfilePictureView agent={agent} width={200} showActions />
          </Col>
        </Row>

        <Divider orientation="left">{t("agent.titles.addresses")}</Divider>

        <table className="data-table-view">
          <tbody>
            <tr>
              <td>{agent.type === AgentType.LEGAL ? t("agent.attrs.addressLongPO") : t("agent.attrs.addressLong")}:</td>
              <td>{formatAddress(agent.address)}</td>
            </tr>
            <tr>
              <td>{t("agent.attrs.serviceAddress")}:</td>
              <td>{formatAddress(agent.serviceAddress)}</td>
            </tr>
            <tr>
              <td>{t("agent.attrs.correspondenceAddress")}:</td>
              <td>{formatAddress(agent.correspondenceAddress)}</td>
            </tr>
          </tbody>
        </table>

        <Divider orientation="left">{t("agent.titles.contacts")}</Divider>

        <ContactsView contacts={agent.contacts} />
      </>
    );
  }

  return null;
};

export default UserProfileUserAccountView;
