import React from "react";
import t from "../../../../../../app/i18n";
import TableActionsView from "../../../../../../common/components/views/TableActionsView";
import { createDuplicatedCommissionActions, deleteDuplicatedCommissionActions } from "../../../../postponed/ducks";
import { PostponedCommissionsReport } from "../../../../postponed/enums";
import { Commission, CommissionsBatchBase } from "../../../types";

interface Props {
  record: Commission;
  batch: CommissionsBatchBase;
  report: PostponedCommissionsReport;
  onCreateDuplicate: typeof createDuplicatedCommissionActions.request;
  onUpdateClick: (commission: Commission) => void;
  onDeleteDuplicate: typeof deleteDuplicatedCommissionActions.request;
}

const BatchPostponedCommissionActionsView = ({ record, batch, report, ...props }: Props) => {
  return (
    <TableActionsView
      actions={
        report === PostponedCommissionsReport.UNRESOLVED_INCLUDED_IN_BATCH
          ? [
              {
                color: "orange",
                icon: "branch",
                text: t("common.exclude"),
                disabled: batch.stepChangeInProgress,
                onClick: () => props.onDeleteDuplicate({ id1: batch.id, id2: record.id })
              },
              {
                color: "blue",
                icon: "edit",
                text: t("common.edit"),
                disabled: batch.stepChangeInProgress,
                onClick: () => props.onUpdateClick?.(record)
              }
            ]
          : [
              {
                color: "green",
                icon: "pull-request",
                text: t("common.include"),
                disabled: batch.stepChangeInProgress,
                onClick: () =>
                  props.onCreateDuplicate({
                    id: batch.id,
                    object: { postponedId: record.id, postponedOptimisticLockVersion: record.optimisticLockVersion }
                  })
              }
            ]
      }
    />
  );
};

export default BatchPostponedCommissionActionsView;
