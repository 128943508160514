import React from "react";
import { Routes } from "react-router";
import { Route } from "react-router-dom";
import { WILDCARD_ROUTE } from "../../common/constants";
import NotFound from "../../common/pages/NotFound/NotFound";
import AnonymousWrapper from "../../common/security/authentication/AnonymousWrapper";
import AuthenticatedWrapper from "../../common/security/authentication/AuthenticatedWrapper";
import { Permission } from "../../common/security/authorization/enums";
import PageWithPermission from "../../common/security/authorization/PageWithPermission";
import AdminUserCreateContainer from "./containers/AdminUserCreateContainer";
import AdminUserDetailContainer from "./containers/AdminUserDetailContainer";
import AdminUserListContainer from "./containers/AdminUserListContainer";
import ConfirmUserContainer from "./containers/ConfirmUserContainer";
import RequestPasswordResetContainer from "./containers/RequestPasswordResetContainer";
import ResetPasswordContainer from "./containers/ResetPasswordContainer";
import UpdateEmailContainer from "./containers/UpdateEmailContainer";
import UserCommissionsContainer from "./containers/UserCommissionsContainer";
import UserProfileContainer from "./containers/UserProfileContainer";
import { ADMIN_USER_ROUTE_PATHS, CURRENT_USER_ROUTE_PATHS, USER_ROUTE_PATHS } from "./paths";

export const AdminUserRoutes = () => (
  <Routes>
    <Route
      index
      element={<PageWithPermission component={<AdminUserListContainer />} permissions={[Permission.ADMIN_USERS]} />}
    />

    <Route
      path={ADMIN_USER_ROUTE_PATHS.create.path}
      element={<PageWithPermission component={<AdminUserCreateContainer />} permissions={[Permission.ADMIN_USERS]} />}
    />

    <Route
      path={ADMIN_USER_ROUTE_PATHS.detail.path}
      element={<PageWithPermission component={<AdminUserDetailContainer />} permissions={[Permission.ADMIN_USERS]} />}
    />

    <Route path={WILDCARD_ROUTE} element={<NotFound />} />
  </Routes>
);

export const CurrentUserRoutes = () => (
  <Routes>
    <Route path={CURRENT_USER_ROUTE_PATHS.profile.path} element={<UserProfileContainer />} />

    <Route
      path={CURRENT_USER_ROUTE_PATHS.commissions.path}
      element={
        <PageWithPermission component={<UserCommissionsContainer />} permissions={[Permission.PROFILE_COMMISSIONS]} />
      }
    />

    <Route path={WILDCARD_ROUTE} element={<NotFound />} />
  </Routes>
);

export const UserRoutes = () => (
  <Routes>
    <Route path={USER_ROUTE_PATHS.confirm.path} element={<ConfirmUserContainer />} />

    <Route path={USER_ROUTE_PATHS.updateEmail.path} element={<UpdateEmailContainer />} />

    <Route
      path={USER_ROUTE_PATHS.requestPasswordReset.path}
      element={<AnonymousWrapper component={<RequestPasswordResetContainer />} />}
    />

    <Route path={USER_ROUTE_PATHS.resetPassword.path} element={<ResetPasswordContainer />} />

    <Route path={WILDCARD_ROUTE} element={<AuthenticatedWrapper component={<NotFound />} />} />
  </Routes>
);
