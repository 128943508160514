import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";
import { ActionProps, EntityIdObject, RootState } from "../../../common/types";
import { requests } from "../api";
import FinancialMediationForm from "../components/forms/FinancialMediationForm";
import {
  assignFinancialMediationActions,
  changeFinancialMediationVersionActions,
  deleteFinancialMediationActions,
  deleteStateFinancialMediationDetailAction,
  getFinancialMediationActions,
  selectFinancialMediationDetail,
  updateFinancialMediationActions
} from "../ducks";
import { FinancialMediation } from "../types";

interface StateProps {
  financialMediation: FinancialMediation;
}

interface ActionsMap {
  getFinancialMediation: typeof getFinancialMediationActions.request;
  updateFinancialMediation: typeof updateFinancialMediationActions.request;
  deleteFinancialMediation: typeof deleteFinancialMediationActions.request;
  changeFinancialMediationVersion: typeof changeFinancialMediationVersionActions.request;
  assignFinancialMediation: typeof assignFinancialMediationActions.request;
  deleteStateFinancialMediationDetail: typeof deleteStateFinancialMediationDetailAction;
}

const FinancialMediationDetailContainer = ({ financialMediation, actions }: StateProps & ActionProps<ActionsMap>) => {
  const { id } = useParams<EntityIdObject>();

  useEffect(() => {
    if (!financialMediation) {
      actions.getFinancialMediation({ id });
    }
    return () => {
      actions.deleteStateFinancialMediationDetail();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContentWrapper>
      <DisplayWrapper itemLoaded={!!financialMediation} notFoundCheckRequest={requests.GET_FINANCIAL_MEDIATION}>
        <FinancialMediationForm
          financialMediation={financialMediation}
          onUpdate={actions.updateFinancialMediation}
          onDelete={actions.deleteFinancialMediation}
          onChangeVersion={actions.changeFinancialMediationVersion}
          onAssign={actions.assignFinancialMediation}
        />
      </DisplayWrapper>
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  financialMediation: selectFinancialMediationDetail(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      getFinancialMediation: getFinancialMediationActions.request,
      updateFinancialMediation: updateFinancialMediationActions.request,
      deleteFinancialMediation: deleteFinancialMediationActions.request,
      changeFinancialMediationVersion: changeFinancialMediationVersionActions.request,
      assignFinancialMediation: assignFinancialMediationActions.request,
      deleteStateFinancialMediationDetail: deleteStateFinancialMediationDetailAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(FinancialMediationDetailContainer);
