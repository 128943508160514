import { Card, Form } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import React, { useEffect, useState } from "react";
import t from "../../../../../../app/i18n";
import FieldViolationsView from "../../../../../../common/components/views/FieldViolationsView";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { FieldConstraintViolation, UUID } from "../../../../../../common/types";
import { resolveFormValidationError, toMoment } from "../../../../../../common/utils/formUtils";
import { useScrollToTopOnLoad } from "../../../../../../common/utils/hooksUtils";
import messageUtils from "../../../../../../common/utils/messageUtils";
import { removeStringWhiteSpaces } from "../../../../../../common/utils/utils";
import { CalcValidationGroup, ClientFormType, ClientType } from "../../../../../client/enums";
import { Client, CreateUpdateContractClient, NaturalClient } from "../../../../../client/types";
import {
  clientToCreateUpdateContractClient,
  processClientsDataViolations,
  useClientsValidation,
  useClientValidation
} from "../../../../../client/utils";
import { InstitutionEnum } from "../../../../../institution/enums";
import { CalcType, ClientExperience } from "../../../../enums";
import CalcGenNavigation from "../../../components/CalcGenNavigation";
import CalcGenResultModal from "../../../components/CalcGenResultModal";
import CalcSummaryModal from "../../../components/summary/CalcSummaryModal";
import { CalcResult, GenResponse } from "../../../types";
import { filterApplicableSuccessResults, processGenResultError } from "../../../utils";
import { deleteStateTravelGenResultAction, generateTravelActions } from "../../ducks";
import { TravelInsuranceType } from "../../enums";
import {
  TravelCalc,
  TravelCalcResultData,
  TravelFormClients,
  TravelGen,
  TravelGenForm,
  TravelGenFormInsuredClient
} from "../../types";
import TravelCalcResults from "../result/TravelCalcResults";
import TravelGenClientsDataSection from "./sections/TravelGenClientsDataSection";
import TravelGenOtherDataSection from "./sections/TravelGenOtherDataSection";

interface Props {
  initialData: TravelGenForm;
  genResult: GenResponse;
  calcData: TravelCalc;
  clients: TravelFormClients;
  calcResults: CalcResult<TravelCalcResultData>[][];
  selectedResult: CalcResult<TravelCalcResultData>;
  draftId: UUID;
  onGenerateFormSubmit: typeof generateTravelActions.request;
  onGenResultDelete: typeof deleteStateTravelGenResultAction;
  onClientChange: (client: Client, type: ClientFormType) => TravelFormClients;
  onGenerateOfferClick: () => void;
  onSaveDraftClick: (genData: TravelGen, overwriteExisting: boolean) => void;
  onSelectedResultChange: (selectedResult: CalcResult<TravelCalcResultData>) => void;
  onReturnToCalculationClick: (genFormData: TravelGenForm) => void;
}

const TravelGenWrapper = (props: Props) => {
  useScrollToTopOnLoad();

  const [form] = Form.useForm<TravelGenForm>();
  const clientValidation = useClientValidation();
  const clientsValidation = useClientsValidation();

  const [resultsOpen, setResultsOpen] = useState<boolean>(false);
  const [summaryOpen, setSummaryOpen] = useState<boolean>(false);
  const [clientsViolationErrors, setClientsViolationErrors] = useState<Map<ClientFormType, FieldConstraintViolation[]>>(
    new Map()
  );

  useEffect(() => {
    if (props.initialData) {
      const formData = cloneDeep(props.initialData);
      const { financialMediationData } = formData;
      const { policyHolder, representative } = props.clients;

      if (policyHolder?.type !== ClientType.LEGAL) {
        delete formData.clientsData.representativeIdentifier;
        delete formData.clientsData.representativeFunction;
        if (representative) {
          props.onClientChange(null, ClientFormType.REPRESENTATIVE);
        }
      }

      if (props.calcData.clientsData.insuredClients) {
        formData.clientsData.insuredClients = props.calcData.clientsData.insuredClients.map((client, index) => ({
          ...formData.clientsData.insuredClients[index],
          birthDate: client.birthDate
        }));
      }

      if (formData.clientsData.insuredClientForFamilyInsurance) {
        formData.clientsData.insuredClientForFamilyInsurance = {
          ...formData.clientsData.insuredClientForFamilyInsurance,
          birthDate: toMoment(formData.clientsData.insuredClientForFamilyInsurance.birthDate)
        };
      }

      formData.financialMediationData = {
        ...financialMediationData,
        clientExperience:
          policyHolder?.type === ClientType.SELF_EMPLOYED || policyHolder?.type === ClientType.LEGAL
            ? ClientExperience.SUFFICIENT
            : financialMediationData.clientExperience === ClientExperience.SUFFICIENT
              ? null
              : financialMediationData.clientExperience,
        recommendedResult: null
      };

      form.setFieldsValue(formData);

      const clients = createContractClientsArray(props.clients);
      if (clients.length > 0) {
        clientsValidation.onValidate({
          prefix: "clientsData.clients",
          clients,
          validationGroup: CalcValidationGroup.GENERATE
        });
      }
    } else {
      const { insuredClients } = props.calcData.clientsData;
      if (insuredClients) {
        form.setFieldsValue({
          clientsData: {
            insuredClients: insuredClients.map<Partial<TravelGenFormInsuredClient>>(client => ({
              birthDate: client.birthDate
            }))
          }
        });
      }
    }
    return () => {
      clientValidation.onErrorResponseDelete();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const violations =
      clientValidation.errorResponse?.violations?.length > 0
        ? clientValidation.errorResponse.violations
        : clientsValidation.errorResponse?.violations?.length > 0
          ? clientsValidation.errorResponse.violations
          : null;
    if (violations) {
      setClientsViolationErrors(
        new Map([
          ...clientsViolationErrors,
          ...processClientsDataViolations(violations, createClientsIndicesMap(props.clients), "clientsData.clients")
        ])
      );
    }
  }, [clientValidation.errorResponse, clientsValidation.errorResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.genResult?.error) {
      const violations = processGenResultError(
        props.genResult.error,
        createClientsIndicesMap(props.clients),
        form,
        "calc.travel.attrs"
      );

      if (violations) {
        messageUtils.errorNotification(
          props.genResult.error.message,
          <FieldViolationsView violations={violations.notificationViolations} rootPath="calc.travel.attrs" />,
          10
        );
        setClientsViolationErrors(violations.clientsViolations);
      }
    }
  }, [props.genResult]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGenerateFormSubmit = (): void => {
    form
      .validateFields()
      .then(() => {
        if (checkHasClientsError()) {
          messageUtils.errorNotification(t("common.error"), t("calc.validations.formError"));
        } else {
          setSummaryOpen(true);
        }
      })
      .catch(errorInfo => {
        messageUtils.errorNotification(t("common.error"), t("calc.validations.formError"));
        resolveFormValidationError(errorInfo);
      });
  };

  const handleGenerateFormApprovalsSubmit = (): void => {
    setSummaryOpen(false);
    form
      .validateFields()
      .then(() => {
        if (checkHasClientsError()) {
          messageUtils.errorNotification(t("common.error"), t("calc.validations.formError"));
        } else {
          props.onGenerateFormSubmit(processAndGetGenFormData());
        }
      })
      .catch(errorInfo => {
        messageUtils.errorNotification(t("common.error"), t("calc.validations.formError"));
        resolveFormValidationError(errorInfo);
      });
  };

  const handleReturnToCalculationClick = (): void => {
    props.onReturnToCalculationClick(form.getFieldsValue());
  };

  const handleResultsSaveDraftClick = (overwriteExisting: boolean): void => {
    setResultsOpen(false);
    props.onSaveDraftClick(processAndGetGenFormData(), overwriteExisting);
  };

  const handleResultsGenerateOfferClick = (): void => {
    setResultsOpen(false);
    props.onGenerateOfferClick();
  };

  const handleResultsGenerateContractClick = (result: CalcResult<TravelCalcResultData>): void => {
    props.onSelectedResultChange(result);
    setResultsOpen(false);
  };

  const handlePolicyHolderIsAlsoInsuredChange = (event: CheckboxChangeEvent): void => {
    setFirstInsuredClientData(event.target.checked ? (props.clients.policyHolder as NaturalClient) : null);
  };

  const handleClientChange = (client: Client, type: ClientFormType): void => {
    const clients = props.onClientChange(client, type);

    if (client) {
      clientValidation.onValidate({
        prefix: `clientsData.clients[${createClientsIndicesMap(clients).get(type)}]`,
        client: clientToCreateUpdateContractClient(client),
        validationGroup: CalcValidationGroup.GENERATE
      });

      if (type === ClientFormType.POLICY_HOLDER) {
        if (client.type === ClientType.SELF_EMPLOYED || client.type === ClientType.LEGAL) {
          form.setFieldsValue({ financialMediationData: { clientExperience: ClientExperience.SUFFICIENT } });
        } else if (form.getFieldValue(["financialMediationData", "clientExperience"]) === ClientExperience.SUFFICIENT) {
          form.setFieldsValue({ financialMediationData: { clientExperience: null } });
        }
      }
    }

    if (type === ClientFormType.POLICY_HOLDER && form.getFieldValue(["clientsData", "policyHolderIsAlsoInsured"])) {
      if (client?.type !== ClientType.NATURAL) {
        setFirstInsuredClientData(null);
        form.setFieldsValue({ clientsData: { policyHolderIsAlsoInsured: false } });
        form.validateFields(["clientsData", "policyHolderIsAlsoInsured"]);
      } else {
        setFirstInsuredClientData(client as NaturalClient);
      }
    }
  };

  const handleClientViolationErrorsChange = (violations: FieldConstraintViolation[], type: ClientFormType): void => {
    const updatedViolationsErrors = new Map<ClientFormType, FieldConstraintViolation[]>([...clientsViolationErrors]);
    if (violations?.length > 0) {
      updatedViolationsErrors.set(type, violations);
    } else {
      updatedViolationsErrors.delete(type);
    }
    setClientsViolationErrors(updatedViolationsErrors);
  };

  const setFirstInsuredClientData = (client: NaturalClient): void => {
    const insuredClients = [
      ...(form.getFieldValue(["clientsData", "insuredClients"]) || [])
    ] as TravelGenFormInsuredClient[];

    if (client) {
      insuredClients[0] = {
        firstName: client.firstName,
        lastName: client.lastName,
        academicDegree: client.academicDegree,
        academicDegreeAfter: client.academicDegreeAfter,
        personalIdentificationNumber: client.personalIdentificationNumber,
        birthDate:
          props.calcData.generalData.insuranceType === TravelInsuranceType.CANCELLATION
            ? toMoment(client.birthDate)
            : insuredClients[0].birthDate,
        discountIdentifier: null
      };
    } else {
      insuredClients[0] = {
        firstName: null,
        lastName: null,
        academicDegree: null,
        academicDegreeAfter: null,
        personalIdentificationNumber: null,
        birthDate:
          props.calcData.generalData.insuranceType === TravelInsuranceType.CANCELLATION
            ? null
            : insuredClients[0].birthDate,
        discountIdentifier: null
      };
    }

    form.setFieldsValue({ clientsData: { insuredClients } });
  };

  const processAndGetGenFormData = (): TravelGen => {
    const values = form.getFieldsValue();
    const { calcData, clients } = props;
    const axaAssistanceYearFamilyInsurance =
      props.selectedResult.insuranceInstitution.institutionEnum === InstitutionEnum.AXA_ASSISTANCE &&
      props.calcData.generalData.insuranceType === TravelInsuranceType.YEAR &&
      props.calcData.discountsData?.familyInsurance;

    const recommendedResultData = (values.financialMediationData.recommendedResult || []) as string[];
    const institutionResults = props.calcResults
      .filter(resultsRow => filterApplicableSuccessResults(resultsRow).length > 0)
      .map(resultsRow => filterApplicableSuccessResults<TravelCalcResultData>(resultsRow))
      .find(
        resultsRow =>
          resultsRow.length > 0 &&
          resultsRow[0].insuranceInstitution.institutionEnum === InstitutionEnum[recommendedResultData[0]]
      );
    const recommendedResult =
      recommendedResultData.length > 1
        ? institutionResults?.find(result => result.coverage === recommendedResultData[1])
        : institutionResults?.[0];

    return {
      type: CalcType.TRAVEL,
      calcResult: props.selectedResult.data,
      coverage: props.selectedResult.coverage,
      calcResponse: { results: props.calcResults.flat() },
      insuranceInstitutionId: props.selectedResult.insuranceInstitution.id,
      draftId: props.draftId,
      ...calcData,
      clientsData: {
        ...calcData.clientsData,
        clients: createContractClientsArray(clients),
        policyHolderEmail: values.clientsData.policyHolderEmail,
        policyHolderPhone: values.clientsData.policyHolderPhone,
        policyHolderMarketingConsent: values.clientsData.policyHolderMarketingConsent,
        policyHolderIsAlsoInsured: axaAssistanceYearFamilyInsurance
          ? false
          : values.clientsData.policyHolderIsAlsoInsured,
        representativeFunction: values.clientsData.representativeFunction,
        insuredClients: values.clientsData.insuredClients.map((formClient, index) => ({
          ...(calcData.clientsData.insuredClients ? calcData.clientsData.insuredClients[index] : {}),
          ...formClient
        })),
        insuredClientForFamilyInsurance: axaAssistanceYearFamilyInsurance
          ? clients.policyHolder.type === ClientType.NATURAL
            ? {
                birthDate: (clients.policyHolder as NaturalClient).birthDate,
                firstName: (clients.policyHolder as NaturalClient).firstName,
                lastName: (clients.policyHolder as NaturalClient).lastName
              }
            : values.clientsData.insuredClientForFamilyInsurance
          : null
      },
      coveragesData:
        calcData.generalData.insuranceType !== TravelInsuranceType.CANCELLATION
          ? merge(
              {},
              calcData.coveragesData,
              values.coveragesData
                ? {
                    ...values.coveragesData,
                    vehicleAssistanceData: values.coveragesData.vehicleAssistanceData
                      ? {
                          ...values.coveragesData.vehicleAssistanceData,
                          licensePlate: removeStringWhiteSpaces(values.coveragesData.vehicleAssistanceData.licensePlate)
                        }
                      : null
                  }
                : null
            )
          : null,
      financialMediationData: { ...values.financialMediationData, recommendedResult }
    };
  };

  const checkHasClientsError = (): boolean => {
    const { policyHolder, representative } = props.clients;

    if (!policyHolder || clientsViolationErrors.has(ClientFormType.POLICY_HOLDER)) {
      return true;
    }

    // noinspection RedundantIfStatementJS
    if (
      policyHolder.type === ClientType.LEGAL &&
      (!representative || clientsViolationErrors.has(ClientFormType.REPRESENTATIVE))
    ) {
      return true;
    }

    return false;
  };

  const createClientsIndicesMap = (clients: TravelFormClients): Map<ClientFormType, number> => {
    const { policyHolder, representative } = clients;
    const indices = new Map<ClientFormType, number>();
    let index = 0;

    if (policyHolder) {
      indices.set(ClientFormType.POLICY_HOLDER, index++);

      if (policyHolder.type === ClientType.LEGAL && representative) {
        indices.set(ClientFormType.REPRESENTATIVE, index);
      }
    }

    return indices;
  };

  const createContractClientsArray = (clients: TravelFormClients): CreateUpdateContractClient[] => {
    const { policyHolder, representative } = clients;
    const createUpdateClients: CreateUpdateContractClient[] = [];

    if (policyHolder) {
      createUpdateClients.push(clientToCreateUpdateContractClient(policyHolder));

      if (policyHolder.type === ClientType.LEGAL && representative) {
        createUpdateClients.push(clientToCreateUpdateContractClient(representative));
      }
    }

    return createUpdateClients;
  };

  const resolvePageTitle = (): string => {
    const { calcData, selectedResult } = props;
    return `${t("calc.travel.titles.contractGeneration")} - 
    ${t("calc.travel.titles.insuranceType." + calcData.generalData.insuranceType)}, 
    ${selectedResult.insuranceInstitution.name}${
      selectedResult.coverage ? `, ${t("calc.travel.sections.coverage")} ${selectedResult.coverage}` : ""
    }`;
  };

  return (
    <>
      <Card className="card-box card-box--no-body" title={<h2>{resolvePageTitle()}</h2>} />

      <Form form={form} layout="vertical" name="travelGenForm">
        <TravelGenClientsDataSection
          form={form}
          calcData={props.calcData}
          clients={props.clients}
          clientsViolationErrors={clientsViolationErrors}
          selectedInstitutionEnum={props.selectedResult.insuranceInstitution.institutionEnum}
          onPolicyHolderIsAlsoInsuredChange={handlePolicyHolderIsAlsoInsuredChange}
          onClientChange={handleClientChange}
          onClientViolationErrorsChange={handleClientViolationErrorsChange}
        />

        <TravelGenOtherDataSection
          form={form}
          policyHolderType={props.clients.policyHolder?.type}
          calcData={props.calcData}
          calcResults={props.calcResults}
          selectedInstitutionEnum={props.selectedResult.insuranceInstitution.institutionEnum}
        />
      </Form>

      <TravelCalcResults
        open={resultsOpen}
        insuranceType={props.calcData.generalData.insuranceType}
        calcResults={props.calcResults}
        selectedResult={props.selectedResult}
        onClose={() => setResultsOpen(false)}
        onSaveDraftClick={handleResultsSaveDraftClick}
        onGenerateOfferClick={handleResultsGenerateOfferClick}
        onGenerateContractClick={handleResultsGenerateContractClick}
      />

      <CalcGenNavigation
        requiredPermission={Permission.TRAVEL_GENERATE_CONTRACT}
        onFormSubmit={handleGenerateFormSubmit}
        onShowResultsClick={() => setResultsOpen(true)}
        onReturnToCalculationClick={handleReturnToCalculationClick}
      />

      <CalcSummaryModal
        open={summaryOpen}
        data={{
          calcData: props.calcData,
          formData: form.getFieldsValue(),
          selectedInsurance: props.selectedResult,
          clients: props.clients
        }}
        onOkClick={handleGenerateFormApprovalsSubmit}
        onCancelClick={() => setSummaryOpen(false)}
      />

      <CalcGenResultModal result={props.genResult} showDraftStep={!!props.draftId} onCancel={props.onGenResultDelete} />
    </>
  );
};

export default TravelGenWrapper;
