import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment, { Moment } from "moment";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../common/constants";
import { Permission } from "../../../../common/security/authorization/enums";
import { RootState } from "../../../../common/types";
import {
  datePickerClearableProps,
  disableDatePickerFuture,
  disableDatePickerOutOfInterval,
  disableDatePickerOutOfMaxDate,
  selectStandardProps,
  toMoment
} from "../../../../common/utils/formUtils";
import { validationConstants, validations } from "../../../../common/utils/validationUtils";
import { selectIsSystemAdmin, selectPermissions } from "../../../auth/ducks";
import { markAllPageNotificationsAsSeenActions } from "../../ducks";
import { NotificationFilterPageRequest, NotificationFilterPageResult } from "../../types";
import { getAvailableNotificationTopics } from "../../utils";

interface Props {
  currentFilter: NotificationFilterPageResult;
  onFilterSubmit: (filter: NotificationFilterPageRequest) => void;
  onMarkAllAsSeen: typeof markAllPageNotificationsAsSeenActions.request;
}

const NotificationPageSearchView = ({ currentFilter, onFilterSubmit, onMarkAllAsSeen }: Props) => {
  const [form] = Form.useForm<NotificationFilterPageRequest>();
  const permissions = useSelector<RootState, Permission[]>(selectPermissions);
  const isCurrentUserSystemAdmin = useSelector<RootState, boolean>(selectIsSystemAdmin);

  const availableTopics = useMemo(
    () => getAvailableNotificationTopics(permissions, isCurrentUserSystemAdmin),
    [permissions, isCurrentUserSystemAdmin]
  );

  const colSpan = 4;

  useEffect(() => {
    form.setFieldsValue({
      ...currentFilter,
      topics: currentFilter.topics ? currentFilter.topics.filter(topic => availableTopics.includes(topic)) : [],
      sentAtMin: toMoment(currentFilter.sentAtMin),
      sentAtMax: toMoment(currentFilter.sentAtMax)
    });
  }, [currentFilter, form, availableTopics]);

  return (
    <Card
      className="card-box"
      title={<h2>{t("notification.titles.pageTitle")}</h2>}
      extra={
        <Button
          className="secondary-button"
          size="small"
          icon={<AntIcon type="eye" />}
          onClick={() => onMarkAllAsSeen()}
        >
          {t("notification.actions.markAllAsSeen")}
        </Button>
      }
    >
      <Form form={form} onFinish={onFilterSubmit} layout="vertical" name="notificationSearchForm">
        <Row gutter={rowGutter} justify="center">
          <Col span={colSpan}>
            <Form.Item
              name="keyword"
              label={t("common.search")}
              rules={[
                validations.size(
                  validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
                  validationConstants.SEARCH_KEYWORD_MAX_LENGTH
                )
              ]}
            >
              <Input allowClear placeholder={t("notification.helpers.searchHint")} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="topics"
              label={t("notification.enums.notificationTopic._label")}
              rules={[validations.none]}
            >
              <Select
                {...selectStandardProps}
                mode="multiple"
                maxTagCount="responsive"
                allowClear
                options={availableTopics.map(topic => ({
                  value: topic,
                  label: t("notification.enums.notificationTopic." + topic)
                }))}
              />
            </Form.Item>
          </Col>

          <Form.Item noStyle shouldUpdate={(prev, next) => prev.sentAtMax !== next.sentAtMax}>
            {({ getFieldValue }) => {
              const sentAtMax = getFieldValue("sentAtMax") as Moment;

              return (
                <Col span={colSpan}>
                  <Form.Item
                    name="sentAtMin"
                    label={t("notification.filter.sentAtMin")}
                    rules={[
                      sentAtMax
                        ? validations.notAfter(sentAtMax, t("notification.filter.sentAtMax"))
                        : validations.notFuture
                    ]}
                  >
                    <DatePicker
                      {...datePickerClearableProps}
                      disabledDate={current =>
                        sentAtMax ? disableDatePickerOutOfMaxDate(current, sentAtMax) : disableDatePickerFuture(current)
                      }
                    />
                  </Form.Item>
                </Col>
              );
            }}
          </Form.Item>

          <Form.Item noStyle shouldUpdate={(prev, next) => prev.sentAtMin !== next.sentAtMin}>
            {({ getFieldValue }) => {
              const sentAtMin = getFieldValue("sentAtMin") as Moment;

              return (
                <Col span={colSpan}>
                  <Form.Item
                    name="sentAtMax"
                    label={t("notification.filter.sentAtMax")}
                    rules={[
                      validations.notFuture,
                      ...(sentAtMin ? [validations.notBefore(sentAtMin, t("notification.filter.sentAtMin"))] : [])
                    ]}
                  >
                    <DatePicker
                      {...datePickerClearableProps}
                      disabledDate={current =>
                        sentAtMin
                          ? disableDatePickerOutOfInterval(current, sentAtMin, moment())
                          : disableDatePickerFuture(current)
                      }
                    />
                  </Form.Item>
                </Col>
              );
            }}
          </Form.Item>

          <Col span={colSpan}>
            <Form.Item
              name="onlyUnseen"
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox>{t("notification.filter.onlyUnseen")}</Checkbox>
            </Form.Item>
          </Col>

          <Col flex="110px" className="no-title-space">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default NotificationPageSearchView;
