import { Col, Form, Input, Modal, Row } from "antd";
import React, { useEffect } from "react";
import t from "../../../../../app/i18n";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import { ModalSizes, rowGutter } from "../../../../../common/constants";
import { UUID } from "../../../../../common/types";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { postponeCommissionActions } from "../../ducks";
import { Commission, PostponeCommission } from "../../types";

interface Props {
  commission: Commission;
  batchId: UUID;
  open: boolean;
  onPostpone: typeof postponeCommissionActions.request;
  onFormCancel: () => void;
}

const CommissionPostponeForm = ({ commission, batchId, open, onPostpone, onFormCancel }: Props) => {
  const [form] = Form.useForm<PostponeCommission>();
  useFormErrorHandler(form, "commissions.batch.attrs.commission", [requests.POSTPONE_COMMISSION]);

  const colSpan = 24;

  useEffect(() => {
    if (open && commission) {
      form.setFieldsValue({ optimisticLockVersion: commission.optimisticLockVersion });
    }
  }, [open, commission, form]);

  const inProgress = useRequestFinishedCallback([requests.POSTPONE_COMMISSION], onFormCancel);

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => onPostpone({ id1: batchId, id2: commission.id, object: values }))
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={t("commissions.batch.titles.postponeCommission")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="commissionPostponeForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="manualPostponementReason"
              label={t("commissions.batch.attrs.commission.manualPostponementReason")}
              rules={[validations.notBlank, validations.size(1, 255)]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CommissionPostponeForm;
