import React from "react";
import { Route, Routes } from "react-router";
import { WILDCARD_ROUTE } from "../../common/constants";
import NotFound from "../../common/pages/NotFound/NotFound";
import { Permission } from "../../common/security/authorization/enums";
import PageWithPermission from "../../common/security/authorization/PageWithPermission";
import LifeInsuranceTariffsContainer from "./containers/LifeInsuranceTariffsContainer";

const routes = () => (
  <Routes>
    <Route
      index
      element={
        <PageWithPermission
          component={<LifeInsuranceTariffsContainer />}
          permissions={[Permission.ADMIN_GLOBAL_ENUMERATIONS]}
        />
      }
    />

    <Route path={WILDCARD_ROUTE} element={<NotFound />} />
  </Routes>
);

export default routes;
