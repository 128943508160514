import { Card, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { generatePath } from "react-router-dom";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../common/constants";
import { formatLocaleDateTime } from "../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../common/utils/utils";
import ClientTypeTagWithName from "../../../client/components/tags/ClientTypeTagWithName";
import { FINANCIAL_MEDIATION_ROUTE_PATHS } from "../../paths";
import { FinancialMediationFilterPageResult, FinancialMediationList } from "../../types";
import FinancialMediationStatusTag from "../FinancialMediationStatusTag";
import FinancialMediationVersionTag from "../FinancialMediationVersionTag";

interface Props {
  mediationsPage: FinancialMediationFilterPageResult;
  onPageChange: (pageNumber: number) => void;
}

const FinancialMediationTableView = ({ mediationsPage, onPageChange }: Props) => {
  const columns: ColumnsType<FinancialMediationList> = [
    {
      key: "createdAt",
      title: t("common.createdAt"),
      width: 150,
      render: (_, record) => formatLocaleDateTime(record.createdAt)
    },
    {
      key: "updatedAt",
      title: t("common.updatedAt"),
      width: 150,
      render: (_, record) => formatLocaleDateTime(record.updatedAt)
    },
    {
      key: "sector",
      title: t("product.enums.financialSector._label"),
      width: 140,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{t("product.enums.financialSector." + record.sector)}</Ellipsis>
    },
    {
      key: "policyHolder",
      title: t("financialMediation.attrs.policyHolder"),
      width: 205,
      render: (_, record) => <ClientTypeTagWithName client={record.policyHolder} ellipsis showLink />
    },
    {
      key: "secondaryClient",
      title: t("financialMediation.attrs.secondaryClient"),
      width: 205,
      render: (_, record) => <ClientTypeTagWithName client={record.secondaryClient} ellipsis showLink />
    },
    {
      key: "version",
      title: t("financialMediation.filter.version"),
      align: "center",
      width: 80,
      render: (_, record) => <FinancialMediationVersionTag version={record.version} style={{ marginRight: 0 }} />
    },
    {
      key: "finished",
      title: t("financialMediation.filter.status"),
      align: "center",
      width: 130,
      render: (_, record) => <FinancialMediationStatusTag status={record.status} style={{ marginRight: 0 }} />
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 90,
      render: (_, record) => (
        <ActionTextIcon
          path={generatePath(FINANCIAL_MEDIATION_ROUTE_PATHS.detail.to, { id: record.id })}
          icon="eye"
          color="blue"
          text={t("common.show")}
        />
      )
    }
  ];

  return (
    <Card className="card-box">
      <Table<FinancialMediationList>
        {...tableStandardProps()}
        columns={columns}
        scroll={{ x: TableSizes.LARGE }}
        dataSource={mediationsPage.pageData}
        pagination={{
          ...paginationTableProps,
          current: mediationsPage.pageIndex + 1,
          pageSize: mediationsPage.pageSize,
          total: mediationsPage.totalElementsCount,
          onChange: onPageChange
        }}
      />
    </Card>
  );
};

export default FinancialMediationTableView;
