import { Col, Form, Input, Row, Switch } from "antd";
import { FormInstance, FormItemProps } from "antd/lib/form";
import classNames from "classnames";
import get from "lodash/get";
import { FieldData } from "rc-field-form/lib/interface";
import React, { useEffect, useState } from "react";
import t from "../../../app/i18n";
import CountrySelect from "../../../modules/enumerations/components/form/CountrySelect";
import { rowGutter } from "../../constants";
import { regexPatterns, validations } from "../../utils/validationUtils";
import { Country } from "../enums";
import { Address } from "../types";
import AddressAutocomplete from "./AddressAutocomplete";

interface Props {
  form: FormInstance;
  rootNamePath: (string | number)[];
  label: string;
  required?: boolean;
  switchProps?: AddressSwitchProps;
}

interface AddressSwitchProps {
  enabled: boolean;
  onEnabledChange?: (enabled: boolean) => void;
  formItemProps?: FormItemProps;
}

const AddressForm = ({ form, rootNamePath, label, required, switchProps }: Props) => {
  const [streetFilled, setStreetFilled] = useState<boolean>();

  useEffect(() => {
    form.validateFields([[...rootNamePath, streetFilled ? "descriptiveNumber" : "orientationNumber"]]);
  }, [streetFilled]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEnabledSwitchChange = (checked: boolean): void => {
    if (!checked) {
      form.resetFields([
        [...rootNamePath, "street"],
        [...rootNamePath, "descriptiveNumber"],
        [...rootNamePath, "orientationNumber"],
        [...rootNamePath, "city"],
        [...rootNamePath, "zipCode"],
        [...rootNamePath, "country"]
      ]);
    }
    switchProps.onEnabledChange?.(checked);
  };

  const handleAddressAutocompleteFind = (address: Address & { cityDistrict: string }): void => {
    form.setFields(
      Object.keys(address)
        .filter(key => key !== "cityDistrict")
        .map<FieldData>(key => ({ name: [...rootNamePath, key], value: address[key] }))
    );
  };

  const colSpan = 4;
  const smallColSpan = 3;

  const switchButton = switchProps ? (
    <span className="margin-left-tiny">
      {switchProps.formItemProps ? (
        <Form.Item {...switchProps.formItemProps} valuePropName="checked" noStyle>
          <Switch size="small" onChange={handleEnabledSwitchChange} />
        </Form.Item>
      ) : (
        <Switch size="small" checked={switchProps.enabled} onChange={handleEnabledSwitchChange} />
      )}
    </span>
  ) : null;

  return (
    <>
      <Row gutter={rowGutter} className="margin-bottom-tiny">
        <Col className={classNames("bold-text", { "form-item-required": required })}>
          {label} {switchButton}
        </Col>
        <Col>
          <AddressAutocomplete
            hide={switchProps ? !switchProps.enabled : undefined}
            onAddressFind={handleAddressAutocompleteFind}
          />
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={[...rootNamePath, "street"]}
            label={t("address.attrs.street")}
            rules={[validations.size(1, 255), validations.pattern(regexPatterns.streetRegex)]}
          >
            <Input
              disabled={switchProps ? !switchProps.enabled : undefined}
              onChange={e => setStreetFilled(!!e.target.value)}
            />
          </Form.Item>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => get(prev, rootNamePath)?.street !== get(next, rootNamePath)?.street}
        >
          {({ getFieldValue }) => {
            const hasStreet = !!getFieldValue([...rootNamePath, "street"]);
            return (
              <>
                <Col span={smallColSpan}>
                  <Form.Item
                    name={[...rootNamePath, "descriptiveNumber"]}
                    label={t("address.attrs.descriptiveNumber")}
                    rules={[
                      hasStreet ? validations.none : validations.notBlank,
                      validations.size(1, 64),
                      validations.pattern(regexPatterns.streetNumberRegex)
                    ]}
                  >
                    <Input disabled={switchProps ? !switchProps.enabled : undefined} />
                  </Form.Item>
                </Col>

                <Col span={smallColSpan}>
                  <Form.Item
                    name={[...rootNamePath, "orientationNumber"]}
                    label={t("address.attrs.orientationNumber")}
                    rules={[
                      hasStreet ? validations.notBlank : validations.none,
                      validations.size(1, 64),
                      validations.pattern(regexPatterns.streetNumberRegex)
                    ]}
                  >
                    <Input disabled={switchProps ? !switchProps.enabled : undefined} />
                  </Form.Item>
                </Col>
              </>
            );
          }}
        </Form.Item>

        <Col span={colSpan}>
          <Form.Item
            name={[...rootNamePath, "city"]}
            label={t("address.attrs.city")}
            rules={[validations.notBlank, validations.size(1, 64), validations.pattern(regexPatterns.wordRegex)]}
          >
            <Input disabled={switchProps ? !switchProps.enabled : undefined} />
          </Form.Item>
        </Col>

        <Col span={smallColSpan}>
          <Form.Item
            name={[...rootNamePath, "zipCode"]}
            label={t("address.attrs.zipCode")}
            rules={[validations.notBlank, validations.length(5), validations.numeric]}
          >
            <Input disabled={switchProps ? !switchProps.enabled : undefined} />
          </Form.Item>
        </Col>

        <Col span={smallColSpan}>
          <CountrySelect
            formItemProps={{
              name: [...rootNamePath, "country"],
              label: t("address.enums.country._label"),
              rules: [validations.notNull],
              initialValue: Country.SVK
            }}
            selectProps={{ disabled: switchProps ? !switchProps.enabled : undefined }}
          />
        </Col>
      </Row>
    </>
  );
};

export default AddressForm;
