import { Card, Col, Row } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../../app/i18n";
import BackNavigationArrow from "../../../../common/components/views/BackNavigationArrow";
import { PageSizes } from "../../../../common/constants";
import DisplayWrapper from "../../../../common/modules/wrappers/DisplayWrapper";
import { ActionProps, EntityIdObject, RootState } from "../../../../common/types";
import { appendSearchParamsToURL, numberOrZero } from "../../../../common/utils/utils";
import { selectRouterLocationSearch } from "../../../ducks";
import BatchManualBailAccountMovementsTableView from "../components/views/BatchManualBailAccountMovementsTableView";
import BatchManualBailAccountMovementsFilterView from "../components/views/filter/BatchManualBailAccountMovementsFilterView";
import {
  associateBailAccountMovementToBatchActions,
  deleteStateManualBailAccountMovementsPageAction,
  disassociateBailAccountMovementFromBatchActions,
  filterManualBailAccountMovementsActions,
  getCommissionsBatchActions,
  selectManualBailAccountMovementsPage
} from "../ducks";
import { ManualBailAccountMovementsReport } from "../enums";
import { ManualBailAccountMovementsFilterPageResult } from "../types";

interface StateProps {
  movementsPage: ManualBailAccountMovementsFilterPageResult;
  urlSearchQuery: string;
}

interface ActionsMap {
  filterManualBailAccountMovements: typeof filterManualBailAccountMovementsActions.request;
  associateMovementToBatch: typeof associateBailAccountMovementToBatchActions.request;
  disassociateMovementFromBatch: typeof disassociateBailAccountMovementFromBatchActions.request;
  deleteStateMovementsPage: typeof deleteStateManualBailAccountMovementsPageAction;
}

const CommissionsBatchManualBailAccountMovementsContainer = (props: StateProps & ActionProps<ActionsMap>) => {
  const { id } = useParams<EntityIdObject>();
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(props.urlSearchQuery);
    const report =
      ManualBailAccountMovementsReport[urlParams.get("report")] || ManualBailAccountMovementsReport.ASSOCIATED;

    props.actions.filterManualBailAccountMovements({
      id,
      object: {
        pageIndex: numberOrZero(urlParams.get("pageIndex")),
        pageSize: PageSizes.HUGE,
        report
      }
    });

    return () => {
      props.actions.deleteStateMovementsPage();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleReportSwitch = (report: ManualBailAccountMovementsReport): void => {
    const params = {
      report: report !== ManualBailAccountMovementsReport.ASSOCIATED ? report : null
    };

    navigate(appendSearchParamsToURL({ ...params, pageIndex: null }), { replace: true });
    props.actions.filterManualBailAccountMovements({
      id: props.movementsPage.batch.id,
      object: { ...params, pageIndex: 0, pageSize: props.movementsPage.pageSize }
    });
  };

  const handleTablePageChange = (pageNumber: number): void => {
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    props.actions.filterManualBailAccountMovements({
      id: props.movementsPage.batch.id,
      object: { pageIndex: pageNumber - 1, pageSize: props.movementsPage.pageSize, report: props.movementsPage.report }
    });
  };

  return (
    <DisplayWrapper itemLoaded={!!props.movementsPage.batch}>
      <Card
        className="card-box"
        title={
          <BackNavigationArrow>
            <h2>{t("commissions.batch.titles.manageManualBailAccountMovements")}</h2>
            <span className="sub-header-info normal-font-size">
              {props.movementsPage.batch &&
                t("commissions.batch.titles.batchDetail", { name: props.movementsPage.batch.name })}
            </span>
          </BackNavigationArrow>
        }
      >
        <Row justify="center">
          <Col flex="1000px">
            <BatchManualBailAccountMovementsFilterView
              filter={props.movementsPage}
              onReportSwitch={handleReportSwitch}
            />
          </Col>
        </Row>
      </Card>

      <Card className="card-box">
        <BatchManualBailAccountMovementsTableView
          movementsPage={props.movementsPage}
          onPageChange={handleTablePageChange}
          onAssociateToBatch={props.actions.associateMovementToBatch}
          onDisassociateFromBatch={props.actions.disassociateMovementFromBatch}
        />
      </Card>
    </DisplayWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  movementsPage: selectManualBailAccountMovementsPage(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterManualBailAccountMovements: filterManualBailAccountMovementsActions.request,
      getCommissionsBatch: getCommissionsBatchActions.request,
      associateMovementToBatch: associateBailAccountMovementToBatchActions.request,
      disassociateMovementFromBatch: disassociateBailAccountMovementFromBatchActions.request,
      deleteStateMovementsPage: deleteStateManualBailAccountMovementsPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(CommissionsBatchManualBailAccountMovementsContainer);
