import { ApiRequest, RequestMethod } from "../types";
import { createApiRequestIdentifier } from "../utils/apiUtils";
import { regexPatterns } from "../utils/validationUtils";

export class ApiRequestAdapter implements ApiRequest {
  readonly url: string;
  readonly method: RequestMethod;
  readonly identifier: string;
  readonly identifierRegExp: RegExp;

  constructor(url: string, method: RequestMethod) {
    this.url = url;
    this.method = method;
    this.identifier = createApiRequestIdentifier(url, method);
    this.identifierRegExp = regexPatterns.urlPathParamRegex.test(url)
      ? new RegExp("^" + this.identifier.replace(regexPatterns.urlPathParamRegex, regexPatterns.uuidRegex.source) + "$")
      : null;
  }

  public static create = (url: string, method: RequestMethod): ApiRequest => {
    return new ApiRequestAdapter(url, method);
  };

  getParametrizedUrl(...params: string[]): string {
    if (params?.length) {
      let url = this.url;
      params.forEach((param, index) => (url = url.replace(`{${index}}`, param)));
      return url;
    }
    return this.url;
  }

  compareIdentifier(comparedIdentifier: string): boolean {
    return this.hasParamsInUrl()
      ? this.identifierRegExp.test(comparedIdentifier)
      : this.identifier === comparedIdentifier;
  }

  hasParamsInUrl(): boolean {
    return !!this.identifierRegExp;
  }
}
