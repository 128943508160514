import { green, grey, orange, red, volcano, yellow } from "@ant-design/colors";
import { Popover, Tag } from "antd";
import React from "react";
import t from "../../../app/i18n";
import ActionTextIcon from "../../../common/components/icons/ActionTextIcon";
import { ContractVerificationStatus } from "../enums";

interface Props {
  status: ContractVerificationStatus;
  note?: string;
  closable?: boolean;
  style?: React.CSSProperties;
  onNoteChangeClick?: () => void;
  onSendClick?: () => void;
  onClose?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const ContractVerificationStatusTag = ({
  status,
  note,
  closable,
  style,
  onNoteChangeClick,
  onSendClick,
  onClose
}: Props) => {
  let tagColor: string;
  let fontColor = "#fff";

  switch (status) {
    case ContractVerificationStatus.TYPED:
      tagColor = yellow[3];
      fontColor = yellow[9];
      break;
    case ContractVerificationStatus.SENT:
      tagColor = yellow[5];
      fontColor = yellow[9];
      break;
    case ContractVerificationStatus.INTERNAL_INTERVENTION:
      tagColor = volcano[5];
      break;
    case ContractVerificationStatus.EXTERNAL_INTERVENTION:
      tagColor = red[5];
      break;
    case ContractVerificationStatus.NOT_ACCEPTED:
      tagColor = grey[9];
      break;
    case ContractVerificationStatus.VERIFIED:
      tagColor = orange[5];
      break;
    case ContractVerificationStatus.PAID:
      tagColor = green[5];
      break;
  }

  const tag = (
    <Tag color={tagColor} closable={closable} style={{ color: fontColor, ...style }} onClose={onClose}>
      {t("contract.enums.verificationStatus." + status)}
    </Tag>
  );

  if (
    (status === ContractVerificationStatus.SENT ||
      status === ContractVerificationStatus.INTERNAL_INTERVENTION ||
      status === ContractVerificationStatus.EXTERNAL_INTERVENTION) &&
    (note || onNoteChangeClick)
  ) {
    return (
      <Popover
        title={t("contract.helpers.verificationStatusAdditionalInfo")}
        content={
          <>
            <span>{note}</span>
            {onNoteChangeClick && (
              <div className="margin-top-tiny right-align">
                <ActionTextIcon color="green" text={t("common.editNote")} icon="edit" onClick={onNoteChangeClick} />
              </div>
            )}
            {onSendClick && (
              <div className="margin-top-tiny right-align">
                <ActionTextIcon
                  color="green"
                  text={t("contract.actions.sendToBackoffice")}
                  icon="file-done"
                  onClick={onSendClick}
                />
              </div>
            )}
          </>
        }
        overlayStyle={{ maxWidth: "400px" }}
      >
        {tag}
      </Popover>
    );
  } else if (onSendClick) {
    return (
      <Popover
        content={
          <ActionTextIcon
            color="green"
            text={t("contract.actions.sendToBackoffice")}
            icon="file-done"
            onClick={onSendClick}
          />
        }
      >
        {tag}
      </Popover>
    );
  }

  return tag;
};

export default ContractVerificationStatusTag;
