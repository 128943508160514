import { DatePicker, Form } from "antd";
import { NamePath } from "rc-field-form/lib/interface";
import React from "react";
import { validations } from "../../../utils/validationUtils";

interface Props {
  name: NamePath;
  initialValue?: string;
}

const HiddenDatePicker = ({ name, initialValue }: Props) => (
  <Form.Item name={name} rules={[validations.none]} initialValue={initialValue} hidden noStyle>
    <DatePicker style={{ visibility: "hidden" }} />
  </Form.Item>
);

export default HiddenDatePicker;
