import { Alert, Checkbox, Col, Divider, Form, Modal, Row, Tree } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import LabelWithTooltip from "../../../../common/components/form/labels/LabelWithTooltip";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import { Feature, Permission } from "../../../../common/security/authorization/enums";
import { AntTreeStrictCheckedProps, RootState } from "../../../../common/types";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import messageUtils from "../../../../common/utils/messageUtils";
import { containsAll, getAllPermissionPrerequisites } from "../../../../common/utils/utils";
import { validations } from "../../../../common/utils/validationUtils";
import { AgentType } from "../../../agent/enums";
import { selectIsSystemAdmin } from "../../../auth/ducks";
import AgentSelect from "../../../enumerations/components/form/AgentSelect";
import { selectTopAgentAllowedFeaturesEnums } from "../../../enumerations/ducks";
import { AgentEnumeration } from "../../../enumerations/types";
import { requests } from "../../api";
import { adminAddAgentUserAccountActions } from "../../ducks";
import { AdminCreateUpdateAgentUserAccount, UserAdminView } from "../../types";
import { buildPermissionTreeNodes, hasConfirmedAgentUserAccount } from "../../utils";
import DefaultPermissionsFormPart from "./DefaultPermissionsFormPart";

interface Props {
  open: boolean;
  user: UserAdminView;
  onFormSubmit: typeof adminAddAgentUserAccountActions.request;
  onFormCancel: () => void;
}

const AdminCreateUserAccountForm = ({ open, user, onFormSubmit, onFormCancel }: Props) => {
  const isCurrentUserSystemAdmin = useSelector<RootState, boolean>(selectIsSystemAdmin);
  const topAgentAllowedFeatures = useSelector<RootState, Feature[]>(selectTopAgentAllowedFeaturesEnums);

  const [form] = Form.useForm<AdminCreateUpdateAgentUserAccount>();
  useFormErrorHandler(form, "user.attrs", [requests.ADMIN_ADD_AGENT_USER_ACCOUNT]);

  const [selectedAgent, setSelectedAgent] = useState<AgentEnumeration>();
  const [formCheckedPermissions, setFormCheckedPermissions] = useState<Permission[]>([]);

  const handleFormCancel = (): void => {
    onFormCancel();
    setFormCheckedPermissions([]);
  };

  const inProgress = useRequestFinishedCallback([requests.ADMIN_ADD_AGENT_USER_ACCOUNT], handleFormCancel);

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (formCheckedPermissions.length === 0) {
          messageUtils.errorMessage(t("user.helpers.noPermissions"));
        } else {
          onFormSubmit({
            id: user.id,
            object: { ...values, permissions: formCheckedPermissions }
          });
        }
      })
      .catch(resolveFormValidationError);
  };

  const handlePermissionTreeCheck = (checked: (string | number)[] | AntTreeStrictCheckedProps): void => {
    const checkedPermissions = (checked as AntTreeStrictCheckedProps).checked.map(checked => Permission[checked]);
    setFormCheckedPermissions(
      checkedPermissions.filter(permission =>
        containsAll(checkedPermissions, ...getAllPermissionPrerequisites(permission))
      )
    );
  };

  const handleAgentChange = (agent: AgentEnumeration): void => {
    form.setFieldsValue({ representingAgentId: null });
    setSelectedAgent(agent);
  };

  const colSpan = 8;

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={t("user.titles.addAccount")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={handleFormCancel}
    >
      <Form form={form} layout="vertical" name="agentUserAccountCreateForm">
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <AgentSelect
              formItemProps={{
                name: "agentId",
                label: t("user.attrs.agentId"),
                rules: [validations.notNull]
              }}
              optionsProps={{ onChange: handleAgentChange }}
            />
          </Col>

          <Col span={colSpan}>
            <AgentSelect
              formItemProps={{
                name: "representingAgentId",
                label: (
                  <LabelWithTooltip
                    label={t("user.attrs.representingAgentId")}
                    tooltip={t("user.helpers.representingAgentInfo")}
                  />
                ),
                rules: [selectedAgent?.type === AgentType.LEGAL ? validations.notNull : validations.none]
              }}
              selectProps={{ allowClear: true, disabled: selectedAgent?.type !== AgentType.LEGAL }}
              optionsProps={
                selectedAgent && {
                  filter: agentOption => selectedAgent.representatives?.some(repr => repr.id === agentOption.id)
                }
              }
            />
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="disabled"
              className="form-item-without-label"
              valuePropName="checked"
              initialValue={false}
              rules={[validations.none]}
            >
              <Checkbox>{t("user.attrs.disabled")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left" className="divider-subheader">
          {t("user.titles.permissionsSettings")}
        </Divider>

        <DefaultPermissionsFormPart
          allowedFeatures={topAgentAllowedFeatures}
          onPermissionsSet={setFormCheckedPermissions}
        />

        <Row gutter={rowGutter}>
          <Col span={colSpan * 3}>
            <Form.Item label={t("user.attrs.permissionsLabel")} required>
              <Tree
                showLine={{ showLeafIcon: false }}
                checkStrictly
                checkable
                checkedKeys={{ checked: formCheckedPermissions, halfChecked: [] }}
                treeData={buildPermissionTreeNodes(
                  isCurrentUserSystemAdmin,
                  user?.agentUserRole.systemAdmin,
                  topAgentAllowedFeatures,
                  formCheckedPermissions
                )}
                onCheck={handlePermissionTreeCheck}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {user && hasConfirmedAgentUserAccount(user) && (
        <Alert message={t("user.helpers.updateInfo")} type="info" showIcon />
      )}
    </Modal>
  );
};

export default AdminCreateUserAccountForm;
