import { Button, Divider } from "antd";
import React, { useEffect, useState } from "react";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import ItemCreatedUpdatedInfoView from "../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { formatPhoneNumber } from "../../../../../common/utils/formatUtils";
import {
  deleteStateUserTotpDeviceSecretAction,
  userCreateUserTotpDeviceActions,
  userCreateUserTotpDeviceSecretActions,
  userDeleteUserTotpDeviceActions,
  userRequestEmailUpdateActions,
  userRequestPhoneUpdateActions,
  userUpdatePasswordActions,
  userUpdatePhoneActions,
  userUpdateUserTotpDeviceActions
} from "../../../ducks";
import { UserProfile, UserTotpDevice, UserTotpDeviceSecret } from "../../../types";
import CreateUserTotpDeviceForm from "../../forms/CreateUserTotpDeviceForm";
import DeleteUserTotpDeviceForm from "../../forms/DeleteUserTotpDeviceForm";
import PhoneUpdateForm from "../../forms/PhoneUpdateForm";
import RequestEmailUpdateForm from "../../forms/RequestEmailUpdateForm";
import UpdatePasswordForm from "../../forms/UpdatePasswordForm";
import UpdateUserTotpDeviceForm from "../../forms/UpdateUserTotpDeviceForm";
import UserTotpDeviceTableView from "./UserTotpDeviceTableView";

interface Props {
  user: UserProfile;
  totpDeviceSecret: UserTotpDeviceSecret;
  totpDevices: UserTotpDevice[];
  openTotpDeviceFormByDefault: boolean;
  userRequestEmailUpdate: typeof userRequestEmailUpdateActions.request;
  userRequestPhoneUpdate: typeof userRequestPhoneUpdateActions.request;
  userUpdatePhone: typeof userUpdatePhoneActions.request;
  userUpdatePassword: typeof userUpdatePasswordActions.request;
  userCreateUserTotpDeviceSecret: typeof userCreateUserTotpDeviceSecretActions.request;
  userCreateUserTotpDevice: typeof userCreateUserTotpDeviceActions.request;
  userUpdateUserTotpDevice: typeof userUpdateUserTotpDeviceActions.request;
  userDeleteUserTotpDevice: typeof userDeleteUserTotpDeviceActions.request;
  deleteStateUserTotpDeviceSecret: typeof deleteStateUserTotpDeviceSecretAction;
}

const UserProfileUserView = ({
  user,
  totpDeviceSecret,
  totpDevices,
  openTotpDeviceFormByDefault,
  ...actions
}: Props) => {
  const [requestEmailUpdateFormOpen, setRequestEmailUpdateFormOpen] = useState<boolean>(false);
  const [requestPhoneUpdateFormOpen, setRequestPhoneUpdateFormOpen] = useState<boolean>(false);
  const [updatePasswordFormOpen, setUpdatePasswordFormOpen] = useState<boolean>(false);
  const [createTotpDeviceFormOpen, setCreateTotpDeviceFormOpen] = useState<boolean>(false);
  const [totpDeviceToUpdate, setTotpDeviceToUpdate] = useState<UserTotpDevice>(null);
  const [totpDeviceToDelete, setTotpDeviceToDelete] = useState<UserTotpDevice>(null);

  useEffect(() => {
    if (openTotpDeviceFormByDefault) {
      setCreateTotpDeviceFormOpen(true);
    }
  }, [openTotpDeviceFormByDefault]);

  return (
    <>
      <h2>
        {t("user.titles.userData")} | {user.name}
      </h2>

      <ItemCreatedUpdatedInfoView item={user} />

      <Divider orientation="left" className="divider-subheader">
        {t("user.titles.basicData")}
      </Divider>

      <table className="data-table-view">
        <tbody>
          <tr>
            <td>{t("user.attrs.name")}:</td>
            <td>{user.name}</td>
          </tr>

          <tr>
            <td>{t("user.attrs.email")}:</td>
            <td>{user.email}</td>
            <td>
              <ActionTextIcon
                icon="edit"
                color="blue"
                text={t("user.actions.updateEmail")}
                onClick={() => setRequestEmailUpdateFormOpen(true)}
              />
            </td>
          </tr>
          <tr>
            <td>{t("user.attrs.phone")}:</td>
            <td>{formatPhoneNumber(user.phone)}</td>
            <td>
              <ActionTextIcon
                icon="edit"
                color="blue"
                text={t("user.actions.updatePhone")}
                onClick={() => setRequestPhoneUpdateFormOpen(true)}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <Button
        icon={<AntIcon type="key" />}
        type="primary"
        className="margin-top-tiny margin-bottom-small"
        onClick={() => setUpdatePasswordFormOpen(true)}
      >
        {t("user.actions.changePassword")}
      </Button>

      <Divider orientation="left" className="divider-subheader">
        {t("user.totpDevice.titles.list")}
      </Divider>

      <UserTotpDeviceTableView
        user={user}
        totpDevices={totpDevices}
        onCreateClick={() => setCreateTotpDeviceFormOpen(true)}
        onUpdateClick={setTotpDeviceToUpdate}
        onDeleteClick={setTotpDeviceToDelete}
      />

      <RequestEmailUpdateForm
        open={requestEmailUpdateFormOpen}
        user={user}
        onFormSubmit={actions.userRequestEmailUpdate}
        onFormCancel={() => setRequestEmailUpdateFormOpen(false)}
      />

      <PhoneUpdateForm
        open={requestPhoneUpdateFormOpen}
        user={user}
        onRequestPhoneUpdateFormSubmit={actions.userRequestPhoneUpdate}
        onUpdatePhoneFormSubmit={actions.userUpdatePhone}
        onFormCancel={() => setRequestPhoneUpdateFormOpen(false)}
      />

      <UpdatePasswordForm
        open={updatePasswordFormOpen}
        user={user}
        onFormSubmit={actions.userUpdatePassword}
        onFormCancel={() => setUpdatePasswordFormOpen(false)}
      />

      <CreateUserTotpDeviceForm
        open={createTotpDeviceFormOpen}
        user={user}
        totpDeviceSecret={totpDeviceSecret}
        onCreateSecret={actions.userCreateUserTotpDeviceSecret}
        onCreate={actions.userCreateUserTotpDevice}
        onFormCancel={() => {
          setCreateTotpDeviceFormOpen(false);
          actions.deleteStateUserTotpDeviceSecret();
        }}
      />

      <UpdateUserTotpDeviceForm
        user={user}
        totpDevice={totpDeviceToUpdate}
        onUpdate={actions.userUpdateUserTotpDevice}
        onFormCancel={() => setTotpDeviceToUpdate(null)}
      />

      <DeleteUserTotpDeviceForm
        user={user}
        totpDevice={totpDeviceToDelete}
        onDelete={actions.userDeleteUserTotpDevice}
        onFormCancel={() => setTotpDeviceToDelete(null)}
      />
    </>
  );
};

export default UserProfileUserView;
