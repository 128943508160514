import React, { CSSProperties } from "react";
import t from "../../../app/i18n";
import { UserBase } from "../../../modules/user/types";
import { formatLocaleDateTime } from "../../utils/formatUtils";

interface Props {
  item: ItemProps;
  className?: string;
  style?: CSSProperties;
}

interface ItemProps {
  createdAt?: string;
  createdBy?: UserBase;
  updatedAt?: string;
}

const ItemCreatedUpdatedInfoView = ({ item, className, style }: Props) =>
  item ? (
    <div className={"sub-header-info " + (className ? className : "")} style={style}>
      {item.createdAt && (
        <>
          <span>{t("common.createdAt")}:</span> {formatLocaleDateTime(item.createdAt)}
        </>
      )}
      {item.createdBy && ` - ${item.createdBy?.name}`}
      {item.updatedAt && (
        <>
          {" "}
          | <span>{t("common.updatedAt")}:</span> {formatLocaleDateTime(item.updatedAt)}
        </>
      )}
    </div>
  ) : null;

export default ItemCreatedUpdatedInfoView;
