import { Modal } from "antd";
import { AxiosResponse } from "axios";
import { Pathname } from "history";
import { generatePath } from "react-router-dom";
import { combineReducers } from "redux";
import { replace } from "redux-first-history";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import t from "../../app/i18n";
import {
  EntityIdObject,
  EntityObject,
  NormalizedError,
  RootState,
  SearchPageRequest,
  SearchPageResult,
  TwoLevelEntityIdObject,
  TwoLevelEntityObject
} from "../../common/types";
import { initSearchPageResult } from "../../common/utils/apiUtils";
import messageUtils from "../../common/utils/messageUtils";
import { removeFromArray, replaceInArray } from "../../common/utils/utils";
import {
  deleteProfilePictureActions,
  updateProfilePictureActions,
  uploadAgentAttachmentsActions
} from "../agent/ducks";
import { AUTH_ROUTE_PATHS } from "../auth/paths";
import { changeRunningRequestKeyAction, selectRouterLocationPathname } from "../ducks";
import { getEnumerationsActions } from "../enumerations/ducks";
import api from "./api";
import { ADMIN_USER_ROUTE_PATHS } from "./paths";
import {
  AdminCreateUpdateAgentUserAccount,
  AdminCreateUserWithAgentUserAccounts,
  AdminUpdateUser,
  ConfirmUser,
  CreateUserTotpDevice,
  CreateUserTotpDeviceSecret,
  DeleteUserTotpDevice,
  GetUsersByAgentRequest,
  PasswordReset,
  RequestPasswordReset,
  RequestUserEmailUpdate,
  RequestUserPhoneUpdate,
  UpdateUserEmail,
  UpdateUserPhone,
  UpdateUserTotpDevice,
  UserAccount,
  UserAdminView,
  UserProfile,
  UserReducerState,
  UserSendTotpCodeRequest,
  UserTotpDevice,
  UserTotpDeviceSecret,
  UserUpdatePassword
} from "./types";

/**
 * ACTIONS
 */
export const adminFilterUsersActions = createAsyncAction(
  "user/ADMIN_FILTER_REQUEST",
  "user/ADMIN_FILTER_SUCCESS",
  "user/ADMIN_FILTER_FAILURE"
)<SearchPageRequest, SearchPageResult<UserAdminView>, NormalizedError>();

export const adminGetUsersByAgentActions = createAsyncAction(
  "user/ADMIN_GET_BY_AGENT_REQUEST",
  "user/ADMIN_GET_BY_AGENT_SUCCESS",
  "user/ADMIN_GET_BY_AGENT_FAILURE"
)<GetUsersByAgentRequest, UserAdminView[], NormalizedError>();

export const adminCreateUserWithMultipleAgentUserAccountsActions = createAsyncAction(
  "user/ADMIN_CREATE_REQUEST",
  "user/ADMIN_CREATE_SUCCESS",
  "user/ADMIN_CREATE_FAILURE"
)<AdminCreateUserWithAgentUserAccounts, UserAdminView, NormalizedError>();

export const adminCreateUserWithSingleAgentUserAccountActions = createAsyncAction(
  "user/ADMIN_CREATE_OR_UPDATE_REQUEST",
  "user/ADMIN_CREATE_OR_UPDATE_SUCCESS",
  "user/ADMIN_CREATE_OR_UPDATE_FAILURE"
)<AdminCreateUserWithAgentUserAccounts, UserAdminView, NormalizedError>();

export const adminGetUserActions = createAsyncAction(
  "user/ADMIN_GET_REQUEST",
  "user/ADMIN_GET_SUCCESS",
  "user/ADMIN_GET_FAILURE"
)<EntityIdObject, UserAdminView, NormalizedError>();

export const userSendTotpCodeViaSmsActions = createAsyncAction(
  "user/USER_SEND_TOTP_CODE_REQUEST",
  "user/USER_SEND_TOTP_CODE_SUCCESS",
  "user/USER_SEND_TOTP_CODE_FAILURE"
)<EntityObject<UserSendTotpCodeRequest>, void, NormalizedError>();

export const userConfirmUserActions = createAsyncAction(
  "user/USER_CONFIRM_REQUEST",
  "user/USER_CONFIRM_SUCCESS",
  "user/USER_CONFIRM_FAILURE"
)<EntityObject<ConfirmUser>, void, NormalizedError>();

export const adminUpdateUserActions = createAsyncAction(
  "user/ADMIN_UPDATE_REQUEST",
  "user/ADMIN_UPDATE_SUCCESS",
  "user/ADMIN_UPDATE_FAILURE"
)<EntityObject<AdminUpdateUser>, UserAdminView, NormalizedError>();

export const adminResendUserConfirmLinkActions = createAsyncAction(
  "user/ADMIN_RESEND_CONFIRM_LINK_REQUEST",
  "user/ADMIN_RESEND_CONFIRM_LINK_SUCEESS",
  "user/ADMIN_RESEND_CONFIRM_LINK_FAILURE"
)<EntityIdObject, UserAdminView, NormalizedError>();

export const userGetUserProfileActions = createAsyncAction(
  "user/USER_GET_PROFILE_REQUEST",
  "user/USER_GET_PROFILE_SUCCESS",
  "user/USER_GET_PROFILE_FAILURE"
)<EntityIdObject, UserProfile, NormalizedError>();

export const userUpdatePasswordActions = createAsyncAction(
  "user/USER_UPDATE_PASSWORD_REQUEST",
  "user/USER_UPDATE_PASSWORD_SUCCESS",
  "user/USER_UPDATE_PASSWORD_FAILURE"
)<EntityObject<UserUpdatePassword>, UserProfile, NormalizedError>();

export const userRequestEmailUpdateActions = createAsyncAction(
  "user/USER_REQUEST_EMAIL_UPDATE_REQUEST",
  "user/USER_REQUEST_EMAIL_UPDATE_SUCCESS",
  "user/USER_REQUEST_EMAIL_UPDATE_FAILURE"
)<EntityObject<RequestUserEmailUpdate>, UserProfile, NormalizedError>();

export const userUpdateEmailActions = createAsyncAction(
  "user/USER_UPDATE_EMAIL_REQUEST",
  "user/USER_UPDATE_EMAIL_SUCCESS",
  "user/USER_UPDATE_EMAIL_FAILURE"
)<EntityObject<UpdateUserEmail>, void, NormalizedError>();

export const userRequestPhoneUpdateActions = createAsyncAction(
  "user/USER_REQUEST_PHONE_UPDATE_REQUEST",
  "user/USER_REQUEST_PHONE_UPDATE_SUCCESS",
  "user/USER_REQUEST_PHONE_UPDATE_FAILURE"
)<EntityObject<RequestUserPhoneUpdate>, UserProfile, NormalizedError>();

export const userUpdatePhoneActions = createAsyncAction(
  "user/USER_UPDATE_PHONE_REQUEST",
  "user/USER_UPDATE_PHONE_SUCCESS",
  "user/USER_UPDATE_PHONE_FAILURE"
)<EntityObject<UpdateUserPhone>, UserProfile, NormalizedError>();

export const requestPasswordResetActions = createAsyncAction(
  "user/USER_REQUEST_PASSWORD_RESET_REQUEST",
  "user/USER_REQUEST_PASSWORD_RESET_SUCCESS",
  "user/USER_REQUEST_PASSWORD_RESET_FAILURE"
)<RequestPasswordReset, void, NormalizedError>();

export const resetPasswordActions = createAsyncAction(
  "user/RESET_PASSWORD_REQUEST",
  "user/RESET_PASSWORD_SUCCESS",
  "user/RESET_PASSWORD_FAILURE"
)<EntityObject<PasswordReset>, void, NormalizedError>();

export const adminAddAgentUserAccountActions = createAsyncAction(
  "user/ADMIN_ADD_AGENT_ACCOUNT_REQUEST",
  "user/ADMIN_ADD_AGENT_ACCOUNT_SUCCESS",
  "user/ADMIN_ADD_AGENT_ACCOUNT_FAILURE"
)<EntityObject<AdminCreateUpdateAgentUserAccount>, UserAccount, NormalizedError>();

export const adminUpdateAgentUserAccountActions = createAsyncAction(
  "user/ADMIN_UPDATE_AGENT_ACCOUNT_REQUEST",
  "user/ADMIN_UPDATE_AGENT_ACCOUNT_SUCCESS",
  "user/ADMIN_UPDATE_AGENT_ACCOUNT_FAILURE"
)<TwoLevelEntityObject<AdminCreateUpdateAgentUserAccount>, UserAccount, NormalizedError>();

export const adminDeleteAgentUserAccountActions = createAsyncAction(
  "user/ADMIN_DELETE_AGENT_ACCOUNT_REQUEST",
  "user/ADMIN_DELETE_AGENT_ACCOUNT_SUCCESS",
  "user/ADMIN_DELETE_AGENT_ACCOUNT_FAILURE"
)<TwoLevelEntityIdObject, TwoLevelEntityIdObject, NormalizedError>();

export const userGetUserTotpDevicesActions = createAsyncAction(
  "user-totp-device/GET_REQUEST",
  "user-totp-device/GET_SUCCESS",
  "user-totp-device/GET_FAILURE"
)<EntityIdObject, UserTotpDevice[], NormalizedError>();

export const userCreateUserTotpDeviceSecretActions = createAsyncAction(
  "user-totp-device/CREATE_SECRET_REQUEST",
  "user-totp-device/CREATE_SECRET_SUCCESS",
  "user-totp-device/CREATE_SECRET_FAILURE"
)<EntityObject<CreateUserTotpDeviceSecret>, UserTotpDeviceSecret, NormalizedError>();

export const userCreateUserTotpDeviceActions = createAsyncAction(
  "user-totp-device/CREATE_REQUEST",
  "user-totp-device/CREATE_SUCCESS",
  "user-totp-device/CREATE_FAILURE"
)<EntityObject<CreateUserTotpDevice>, UserTotpDevice, NormalizedError>();

export const userUpdateUserTotpDeviceActions = createAsyncAction(
  "user-totp-device/UPDATE_REQUEST",
  "user-totp-device/UPDATE_SUCCESS",
  "user-totp-device/UPDATE_FAILURE"
)<TwoLevelEntityObject<UpdateUserTotpDevice>, UserTotpDevice, NormalizedError>();

export const userDeleteUserTotpDeviceActions = createAsyncAction(
  "user-totp-device/DELETE_REQUEST",
  "user-totp-device/DELETE_SUCCESS",
  "user-totp-device/DELETE_FAILURE"
)<TwoLevelEntityObject<DeleteUserTotpDevice>, TwoLevelEntityIdObject, NormalizedError>();

export const deleteStateAdminUsersPageAction = createAction("user/DELETE_STATE_LIST")<void>();
export const deleteStateAdminUserDetailAction = createAction("user/DELETE_STATE_DETAIL")<void>();
export const deleteStateAdminUsersByAgentAction = createAction("user/DELETE_STATE_USERS_BY_AGENT")<void>();
export const deleteStateUserProfileAction = createAction("user/DELETE_STATE_PROFILE_DETAIL")<void>();
export const deleteStateUserTotpDeviceSecretAction = createAction("user-totp-device/DELETE_STATE_SECRET")<void>();
export const deleteStateUserTotpDevicesAction = createAction("user-totp-device/DELETE_STATE_LIST")<void>();

const actions = {
  adminFilterUsersActions,
  adminGetUsersByAgentActions,
  adminCreateUserWithMultipleAgentUserAccountsActions,
  adminCreateUserWithSingleAgentUserAccountActions,
  adminGetUserActions,
  userSendTotpCodeViaSmsActions,
  userConfirmUserActions,
  adminUpdateUserActions,
  adminResendUserConfirmLinkActions,
  userGetUserProfileActions,
  userUpdatePasswordActions,
  userRequestEmailUpdateActions,
  userUpdateEmailActions,
  userRequestPhoneUpdateActions,
  userUpdatePhoneActions,
  requestPasswordResetActions,
  resetPasswordActions,
  adminAddAgentUserAccountActions,
  adminUpdateAgentUserAccountActions,
  adminDeleteAgentUserAccountActions,
  deleteStateAdminUsersPageAction,
  deleteStateAdminUserDetailAction,
  deleteStateAdminUsersByAgentAction,
  deleteStateUserProfileAction,
  userGetUserTotpDevicesActions,
  userCreateUserTotpDeviceSecretActions,
  userCreateUserTotpDeviceActions,
  userUpdateUserTotpDeviceActions,
  userDeleteUserTotpDeviceActions,
  deleteStateUserTotpDeviceSecretAction,
  deleteStateUserTotpDevicesAction
};

export type UserAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: UserReducerState = {
  adminUsersCurrentPage: initSearchPageResult<UserAdminView>(),
  adminUserDetail: null,
  adminUsersByAgent: [],
  userProfile: null,
  userTotpDeviceSecret: null,
  userTotpDevices: []
};

const adminUsersCurrentPageReducer = createReducer(initialState.adminUsersCurrentPage)
  .handleAction(adminFilterUsersActions.success, (_, { payload }) => payload)
  .handleAction(
    [adminFilterUsersActions.failure, deleteStateAdminUsersPageAction],
    () => initialState.adminUsersCurrentPage
  );

const adminUserDetailReducer = createReducer(initialState.adminUserDetail)
  .handleAction(
    [adminGetUserActions.success, adminCreateUserWithMultipleAgentUserAccountsActions.success],
    (_, { payload }) => payload
  )
  .handleAction([adminUpdateUserActions.success, adminResendUserConfirmLinkActions.success], (state, { payload }) =>
    state ? payload : state
  )
  .handleAction([adminGetUserActions.failure, deleteStateAdminUserDetailAction], () => initialState.adminUserDetail);

const adminUsersByAgentReducer = createReducer(initialState.adminUsersByAgent)
  .handleAction(adminGetUsersByAgentActions.success, (_, { payload }) => payload)
  .handleAction(adminCreateUserWithSingleAgentUserAccountActions.success, (state, { payload }) => {
    if (state.find(item => item.id === payload.id)) {
      return replaceInArray(
        state,
        item => item.id === payload.id,
        () => payload
      );
    } else {
      return [payload, ...state];
    }
  })
  .handleAction([adminUpdateUserActions.success, adminResendUserConfirmLinkActions.success], (state, { payload }) =>
    replaceInArray(
      state,
      item => item.id === payload.id,
      () => payload
    )
  )
  .handleAction(adminDeleteAgentUserAccountActions.success, (state, { payload }) => {
    if (state.find(item => item.id === payload.id1)?.agentUserRole?.userAccounts?.length > 1) {
      return replaceInArray(
        state,
        item => item.id === payload.id1,
        user => ({
          ...user,
          agentUserRole: {
            ...user.agentUserRole,
            userAccounts: removeFromArray(user.agentUserRole.userAccounts, item => item.id === payload.id2)
          }
        })
      );
    } else {
      return removeFromArray(state, item => item.id === payload.id1);
    }
  })
  .handleAction(
    [adminGetUsersByAgentActions.failure, deleteStateAdminUsersByAgentAction],
    () => initialState.adminUsersByAgent
  );

const userProfileReducer = createReducer(initialState.userProfile)
  .handleAction(
    [
      userGetUserProfileActions.success,
      userUpdatePasswordActions.success,
      userRequestEmailUpdateActions.success,
      userRequestPhoneUpdateActions.success,
      userUpdatePhoneActions.success
    ],
    (_, { payload }) => payload
  )
  .handleAction([updateProfilePictureActions.success, deleteProfilePictureActions.success], (state, { payload }) =>
    state?.agent
      ? {
          ...state,
          agent: state.agent.id === payload.id ? { ...state.agent, profilePicture: payload.object } : state.agent,
          representingAgent:
            state.representingAgent?.id === payload.id
              ? { ...state.representingAgent, profilePicture: payload.object }
              : state.representingAgent
        }
      : state
  )
  .handleAction(uploadAgentAttachmentsActions.success, (state, { payload }) =>
    state?.agent
      ? {
          ...state,
          agent: state.agent.id === payload.id ? { ...state.agent, attachments: payload.object } : state.agent,
          representingAgent:
            state.representingAgent?.id === payload.id
              ? { ...state.representingAgent, attachments: payload.object }
              : state.representingAgent
        }
      : state
  )
  .handleAction([userGetUserProfileActions.failure, deleteStateUserProfileAction], () => initialState.userProfile);

const userTotpDeviceSecretReducer = createReducer(initialState.userTotpDeviceSecret)
  .handleAction(userCreateUserTotpDeviceSecretActions.success, (_, { payload }) => payload)
  .handleAction(
    [userCreateUserTotpDeviceSecretActions.failure, deleteStateUserTotpDeviceSecretAction],
    () => initialState.userTotpDeviceSecret
  );

const userTotpDevicesReducer = createReducer(initialState.userTotpDevices)
  .handleAction(userGetUserTotpDevicesActions.success, (_, { payload }) => payload)
  .handleAction(userCreateUserTotpDeviceActions.success, (state, { payload }) => [...state, payload])
  .handleAction(userUpdateUserTotpDeviceActions.success, (state, { payload }) =>
    replaceInArray(
      state,
      item => item.id === payload.id,
      () => payload
    )
  )
  .handleAction(userDeleteUserTotpDeviceActions.success, (state, { payload }) =>
    removeFromArray(state, item => item.id === payload.id2)
  )
  .handleAction(
    [userGetUserTotpDevicesActions.failure, deleteStateUserTotpDevicesAction],
    () => initialState.userTotpDevices
  );

export const userReducer = combineReducers<UserReducerState>({
  adminUsersCurrentPage: adminUsersCurrentPageReducer,
  adminUserDetail: adminUserDetailReducer,
  adminUsersByAgent: adminUsersByAgentReducer,
  userProfile: userProfileReducer,
  userTotpDeviceSecret: userTotpDeviceSecretReducer,
  userTotpDevices: userTotpDevicesReducer
});

/**
 * SELECTORS
 */
const selectUser = (state: RootState): UserReducerState => state.user;

export const selectAdminUsersCurrentPage = (state: RootState): SearchPageResult<UserAdminView> =>
  selectUser(state).adminUsersCurrentPage;
export const selectAdminUserDetail = (state: RootState): UserAdminView => selectUser(state).adminUserDetail;
export const selectAdminUsersByAgent = (state: RootState): UserAdminView[] => selectUser(state).adminUsersByAgent;
export const selectUserProfile = (state: RootState): UserProfile => selectUser(state).userProfile;

export const selectUserTotpDeviceSecret = (state: RootState): UserTotpDeviceSecret =>
  selectUser(state).userTotpDeviceSecret;
export const selectUserTotpDevices = (state: RootState): UserTotpDevice[] => selectUser(state).userTotpDevices;

/**
 * SAGAS
 */
function* adminFilterUsers({ payload }: ReturnType<typeof adminFilterUsersActions.request>) {
  try {
    const response: AxiosResponse<SearchPageResult<UserAdminView>> = yield call(api.adminFilterUsers, payload);
    yield put(adminFilterUsersActions.success(response.data));
  } catch (error) {
    yield put(adminFilterUsersActions.failure(error));
  }
}

function* adminGetUsersByAgent({ payload }: ReturnType<typeof adminGetUsersByAgentActions.request>) {
  try {
    const response: AxiosResponse<UserAdminView[]> = yield call(api.adminGetUsersByAgent, payload);
    yield put(adminGetUsersByAgentActions.success(response.data));
  } catch (error) {
    yield put(adminGetUsersByAgentActions.failure(error));
  }
}

function* adminCreateUserWithMultipleAgentUserAccounts({
  payload
}: ReturnType<typeof adminCreateUserWithMultipleAgentUserAccountsActions.request>) {
  try {
    const response: AxiosResponse<UserAdminView> = yield call(api.adminCreateUserWithAgentUserAccounts, payload);
    yield put(adminCreateUserWithMultipleAgentUserAccountsActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(replace(generatePath(ADMIN_USER_ROUTE_PATHS.detail.to, { id: response.data.id })));
    messageUtils.itemCreatedNotification();
  } catch (error) {
    yield put(adminCreateUserWithMultipleAgentUserAccountsActions.failure(error));
  }
}

function* adminCreateUserWithSingleAgentUserAccount({
  payload
}: ReturnType<typeof adminCreateUserWithSingleAgentUserAccountActions.request>) {
  try {
    const response: AxiosResponse<UserAdminView> = yield call(api.adminCreateUserWithAgentUserAccounts, payload);
    yield put(adminCreateUserWithSingleAgentUserAccountActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();
  } catch (error) {
    yield put(adminCreateUserWithSingleAgentUserAccountActions.failure(error));
  }
}

function* adminGetUser({ payload }: ReturnType<typeof adminGetUserActions.request>) {
  try {
    const response: AxiosResponse<UserAdminView> = yield call(api.adminGetUser, payload);
    yield put(adminGetUserActions.success(response.data));
  } catch (error) {
    yield put(adminGetUserActions.failure(error));
  }
}

function* userSendTotpCodeViaSms({ payload }: ReturnType<typeof userSendTotpCodeViaSmsActions.request>) {
  try {
    yield call(api.userSendTotpCodeViaSms, payload);
    yield put(userSendTotpCodeViaSmsActions.success());
    messageUtils.successNotification(t("common.operationSuccess"), t("user.helpers.sendTotpCodeViaSmsSuccess"));
    yield put(changeRunningRequestKeyAction());
  } catch (error) {
    yield put(userSendTotpCodeViaSmsActions.failure(error));
  }
}

function* userConfirmUser({ payload }: ReturnType<typeof userConfirmUserActions.request>) {
  try {
    yield call(api.userConfirmUser, payload);
    yield put(userConfirmUserActions.success());
    yield new Promise<void>(resolve =>
      Modal.success({
        title: t("common.operationSuccess"),
        content: t("user.helpers.userConfirmationSuccess"),
        okText: t("user.actions.continueToLogin"),
        onOk: () => resolve()
      })
    );
    yield put(replace(AUTH_ROUTE_PATHS.login.to));
  } catch (error) {
    yield put(userConfirmUserActions.failure(error));
  }
}

function* adminUpdateUser({ payload }: ReturnType<typeof adminUpdateUserActions.request>) {
  try {
    const response: AxiosResponse<UserAdminView> = yield call(api.adminUpdateUser, payload);
    yield put(adminUpdateUserActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(adminUpdateUserActions.failure(error));
  }
}

function* adminResendUserConfirmLink({ payload }: ReturnType<typeof adminResendUserConfirmLinkActions.request>) {
  try {
    const response: AxiosResponse<UserAdminView> = yield call(api.adminResendUserConfirmLink, payload);
    yield put(adminResendUserConfirmLinkActions.success(response.data));
    messageUtils.successNotification(t("common.operationSuccess"), t("user.helpers.resendConfirmationLinkSuccess"));
  } catch (error) {
    yield put(adminResendUserConfirmLinkActions.failure(error));
  }
}

function* userGetUserProfile({ payload }: ReturnType<typeof userGetUserProfileActions.request>) {
  try {
    const response: AxiosResponse<UserProfile> = yield call(api.userGetUserProfile, payload);
    yield put(userGetUserProfileActions.success(response.data));
  } catch (error) {
    yield put(userGetUserProfileActions.failure(error));
  }
}

function* userUpdatePassword({ payload }: ReturnType<typeof userUpdatePasswordActions.request>) {
  try {
    const response: AxiosResponse<UserProfile> = yield call(api.userUpdatePassword, payload);
    yield put(userUpdatePasswordActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.successNotification(t("common.operationSuccess"), t("user.helpers.passwordUpdateSuccess"));
  } catch (error) {
    yield put(userUpdatePasswordActions.failure(error));
  }
}

function* userRequestEmailUpdate({ payload }: ReturnType<typeof userRequestEmailUpdateActions.request>) {
  try {
    const response: AxiosResponse<UserProfile> = yield call(api.userRequestEmailUpdate, payload);
    yield put(userRequestEmailUpdateActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    yield new Promise<void>(resolve =>
      Modal.success({
        title: t("common.operationSuccess"),
        content: t("user.helpers.emailUpdateRequestSuccess"),
        okText: t("user.actions.closeWindow"),
        onOk: () => resolve()
      })
    );
  } catch (error) {
    yield put(userRequestEmailUpdateActions.failure(error));
  }
}

function* userUpdateEmail({ payload }: ReturnType<typeof userUpdateEmailActions.request>) {
  try {
    yield call(api.userUpdateEmail, payload);
    yield put(userUpdateEmailActions.success());
    yield new Promise<void>(resolve =>
      Modal.success({
        title: t("common.operationSuccess"),
        content: t("user.helpers.emailUpdateSuccess"),
        okText: t("user.actions.continueToLogin"),
        onOk: () => resolve()
      })
    );
    yield put(replace(AUTH_ROUTE_PATHS.login.to));
  } catch (error) {
    yield put(userUpdateEmailActions.failure(error));
  }
}

function* userRequestPhoneUpdate({ payload }: ReturnType<typeof userRequestPhoneUpdateActions.request>) {
  try {
    const response: AxiosResponse<UserProfile> = yield call(api.userRequestPhoneUpdate, payload);
    yield put(userRequestPhoneUpdateActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.successNotification(t("common.operationSuccess"), t("user.helpers.sendTotpCodeViaSmsSuccess"));
  } catch (error) {
    yield put(userRequestPhoneUpdateActions.failure(error));
  }
}

function* userUpdatePhone({ payload }: ReturnType<typeof userUpdatePhoneActions.request>) {
  try {
    const response: AxiosResponse<UserProfile> = yield call(api.userUpdatePhone, payload);
    yield put(userUpdatePhoneActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    yield new Promise<void>(resolve =>
      Modal.success({
        title: t("common.operationSuccess"),
        content: t("user.helpers.phoneUpdateSuccess"),
        okText: t("user.actions.closeWindow"),
        onOk: () => resolve()
      })
    );
  } catch (error) {
    yield put(userUpdatePhoneActions.failure(error));
  }
}

function* requestPasswordReset({ payload }: ReturnType<typeof requestPasswordResetActions.request>) {
  try {
    yield call(api.requestPasswordReset, payload);
    yield put(requestPasswordResetActions.success());
    yield new Promise<void>(resolve =>
      Modal.info({
        title: t("common.operationSuccess"),
        content: t("user.helpers.requestPasswordResetSuccess"),
        okText: t("user.actions.closeWindow"),
        onOk: () => resolve()
      })
    );
  } catch (error) {
    yield put(requestPasswordResetActions.failure(error));
  }
}

function* resetPassword({ payload }: ReturnType<typeof resetPasswordActions.request>) {
  try {
    yield call(api.resetPassword, payload);
    yield put(resetPasswordActions.success());
    yield new Promise<void>(resolve =>
      Modal.success({
        title: t("common.operationSuccess"),
        content: t("user.helpers.resetPasswordSuccess"),
        okText: t("user.actions.continueToLogin"),
        onOk: () => resolve()
      })
    );
    yield put(replace(AUTH_ROUTE_PATHS.login.to));
  } catch (error) {
    yield put(resetPasswordActions.failure(error));
  }
}

function* adminAddAgentUserAccount({ payload }: ReturnType<typeof adminAddAgentUserAccountActions.request>) {
  try {
    const response: AxiosResponse<UserAccount> = yield call(api.adminAddAgentUserAccount, payload);
    yield put(adminAddAgentUserAccountActions.success(response.data));
    yield put(adminGetUserActions.request({ id: payload.id }));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemCreatedNotification();
  } catch (error) {
    yield put(adminAddAgentUserAccountActions.failure(error));
  }
}

function* adminUpdateAgentUserAccount({ payload }: ReturnType<typeof adminUpdateAgentUserAccountActions.request>) {
  try {
    const response: AxiosResponse<UserAccount> = yield call(api.adminUpdateAgentUserAccount, payload);
    yield put(adminUpdateAgentUserAccountActions.success(response.data));

    const location: Pathname = yield select(selectRouterLocationPathname);
    if (location.startsWith(ADMIN_USER_ROUTE_PATHS.list.to)) {
      yield put(adminGetUserActions.request({ id: payload.id1 }));
    } else {
      yield put(adminGetUsersByAgentActions.request({ agentId: response.data.agent.id }));
    }

    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(adminUpdateAgentUserAccountActions.failure(error));
  }
}

function* adminDeleteAgentUserAccount({ payload }: ReturnType<typeof adminDeleteAgentUserAccountActions.request>) {
  try {
    yield call(api.adminDeleteAgentUserAccount, payload);
    yield put(adminDeleteAgentUserAccountActions.success(payload));

    const location: Pathname = yield select(selectRouterLocationPathname);
    if (location.startsWith(ADMIN_USER_ROUTE_PATHS.list.to)) {
      const adminUserDetail: UserAdminView = yield select(selectAdminUserDetail);
      if (adminUserDetail.agentUserRole.userAccounts.length === 1) {
        yield put(replace(ADMIN_USER_ROUTE_PATHS.list.to));
      } else {
        yield put(adminGetUserActions.request({ id: payload.id1 }));
      }
    }

    messageUtils.itemDeletedNotification();
  } catch (error) {
    yield put(adminDeleteAgentUserAccountActions.failure(error));
  }
}

function* userGetUserTotpDevices({ payload }: ReturnType<typeof userGetUserTotpDevicesActions.request>) {
  try {
    const response: AxiosResponse<UserTotpDevice[]> = yield call(api.userGetUserTotpDevices, payload);
    yield put(userGetUserTotpDevicesActions.success(response.data));
  } catch (error) {
    yield put(userGetUserTotpDevicesActions.failure(error));
  }
}

function* userCreateUserTotpDeviceSecret({
  payload
}: ReturnType<typeof userCreateUserTotpDeviceSecretActions.request>) {
  try {
    const response: AxiosResponse<UserTotpDeviceSecret> = yield call(api.userCreateUserTotpDeviceSecret, payload);
    yield put(userCreateUserTotpDeviceSecretActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
  } catch (error) {
    yield put(userCreateUserTotpDeviceSecretActions.failure(error));
  }
}

function* userCreateUserTotpDevice({ payload }: ReturnType<typeof userCreateUserTotpDeviceActions.request>) {
  try {
    const response: AxiosResponse<UserTotpDevice> = yield call(api.userCreateUserTotpDevice, payload);
    yield put(userCreateUserTotpDeviceActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
  } catch (error) {
    yield put(userCreateUserTotpDeviceActions.failure(error));
  }
}

function* userUpdateUserTotpDevice({ payload }: ReturnType<typeof userUpdateUserTotpDeviceActions.request>) {
  try {
    const response: AxiosResponse<UserTotpDevice> = yield call(api.userUpdateUserTotpDevice, payload);
    yield put(userUpdateUserTotpDeviceActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(userUpdateUserTotpDeviceActions.failure(error));
  }
}

function* userDeleteUserTotpDevice({ payload }: ReturnType<typeof userDeleteUserTotpDeviceActions.request>) {
  try {
    yield call(api.userDeleteUserTotpDevice, payload);
    yield put(userDeleteUserTotpDeviceActions.success({ id1: payload.id1, id2: payload.id2 }));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemDeletedNotification();
  } catch (error) {
    yield put(userDeleteUserTotpDeviceActions.failure(error));
  }
}

export function* userSaga() {
  yield takeLatest(adminFilterUsersActions.request, adminFilterUsers);
  yield takeLatest(adminGetUsersByAgentActions.request, adminGetUsersByAgent);
  yield takeLatest(
    adminCreateUserWithMultipleAgentUserAccountsActions.request,
    adminCreateUserWithMultipleAgentUserAccounts
  );
  yield takeLatest(adminCreateUserWithSingleAgentUserAccountActions.request, adminCreateUserWithSingleAgentUserAccount);
  yield takeLatest(adminGetUserActions.request, adminGetUser);
  yield takeLatest(userSendTotpCodeViaSmsActions.request, userSendTotpCodeViaSms);
  yield takeLatest(userConfirmUserActions.request, userConfirmUser);
  yield takeLatest(adminUpdateUserActions.request, adminUpdateUser);
  yield takeLatest(adminResendUserConfirmLinkActions.request, adminResendUserConfirmLink);
  yield takeLatest(userGetUserProfileActions.request, userGetUserProfile);
  yield takeLatest(userUpdatePasswordActions.request, userUpdatePassword);
  yield takeLatest(userRequestEmailUpdateActions.request, userRequestEmailUpdate);
  yield takeLatest(userUpdateEmailActions.request, userUpdateEmail);
  yield takeLatest(userRequestPhoneUpdateActions.request, userRequestPhoneUpdate);
  yield takeLatest(userUpdatePhoneActions.request, userUpdatePhone);
  yield takeLatest(requestPasswordResetActions.request, requestPasswordReset);
  yield takeLatest(resetPasswordActions.request, resetPassword);
  yield takeLatest(adminAddAgentUserAccountActions.request, adminAddAgentUserAccount);
  yield takeLatest(adminUpdateAgentUserAccountActions.request, adminUpdateAgentUserAccount);
  yield takeLatest(adminDeleteAgentUserAccountActions.request, adminDeleteAgentUserAccount);
  yield takeLatest(userGetUserTotpDevicesActions.request, userGetUserTotpDevices);
  yield takeLatest(userCreateUserTotpDeviceSecretActions.request, userCreateUserTotpDeviceSecret);
  yield takeLatest(userCreateUserTotpDeviceActions.request, userCreateUserTotpDevice);
  yield takeLatest(userUpdateUserTotpDeviceActions.request, userUpdateUserTotpDevice);
  yield takeLatest(userDeleteUserTotpDeviceActions.request, userDeleteUserTotpDevice);
}
