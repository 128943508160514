import { Alert, Col, Form, Input, Row, Select } from "antd";
import { FormInstance, Rule } from "antd/lib/form";
import React, { useEffect, useState } from "react";
import t from "../../../../../app/i18n";
import ActionButton from "../../../../../common/components/buttons/ActionButton";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import DeleteIcon from "../../../../../common/components/icons/DeleteIcon";
import { rowGutter } from "../../../../../common/constants";
import { resolveFormValidationError, selectStandardProps } from "../../../../../common/utils/formUtils";
import { isDefinedValue } from "../../../../../common/utils/utils";
import { validations } from "../../../../../common/utils/validationUtils";
import { ClientRepresentativeFunction, ClientType } from "../../../enums";
import { CLIENT_ROUTE_PATHS } from "../../../paths";
import {
  ClientBase,
  CreateUpdateClient,
  CreateUpdateClientRepresentative,
  CreateUpdateLegalClient,
  LegalClient
} from "../../../types";
import { useClientSearch } from "../../../utils";
import ClientTypeTagWithName from "../../tags/ClientTypeTagWithName";

interface Props {
  form: FormInstance<CreateUpdateClient>;
  initialClient?: LegalClient;
  colSpan: number;
}

const noRepeatedRepresentative: Rule = ({ getFieldValue }) => ({
  validator: (_, value) =>
    value &&
    (getFieldValue(["representatives"]) || []).map(r => r.representativePin).filter(pin => pin === value).length > 1
      ? Promise.reject(t("client.validations.duplicateRepresentative"))
      : Promise.resolve()
});

const ClientRepresentativesFormPart = ({ form, initialClient, colSpan }: Props) => {
  const [selectedSearchIndex, setSelectedSearchIndex] = useState<number>();
  const [representatives, setRepresentatives] = useState<(ClientBase | boolean)[]>(
    initialClient?.representatives ? initialClient.representatives.map(r => r.representative) : []
  );

  const clientSearch = useClientSearch();

  useEffect(() => {
    if (
      isDefinedValue(selectedSearchIndex) &&
      clientSearch.result.keyword === form.getFieldValue(["representatives", selectedSearchIndex, "representativePin"])
    ) {
      const updatedRepresentatives = [...representatives];

      if (clientSearch.result.data) {
        updatedRepresentatives[selectedSearchIndex] = clientSearch.result.data;
        const formValue = ([...form.getFieldValue(["representatives"])] || []) as CreateUpdateClientRepresentative[];
        formValue[selectedSearchIndex].representativeId = clientSearch.result.data.id;
        form.setFieldsValue({ representatives: formValue } as CreateUpdateLegalClient);
      } else {
        updatedRepresentatives[selectedSearchIndex] = true;
      }

      setRepresentatives(updatedRepresentatives);
    }
  }, [clientSearch.result]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      clientSearch.onResultDelete();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClientSearchSubmit = (value: string, index: number): void => {
    form
      .validateFields([["representatives", index, "representativePin"]])
      .then(() => clientSearch.onSearch({ clientType: ClientType.NATURAL, keyword: value }))
      .catch(resolveFormValidationError);
  };

  const handleClientSearchChange = (value: string, index: number): void => {
    if (representatives[index]) {
      const updatedRepresentatives = [...representatives];
      updatedRepresentatives[index] = null;
      setRepresentatives(updatedRepresentatives);

      const formValue = ([...form.getFieldValue(["representatives"])] || []) as CreateUpdateClientRepresentative[];
      formValue[index].id = null;
      formValue[index].optimisticLockVersion = null;
      formValue[index].representativeId = null;
      form.setFieldsValue({ representatives: formValue } as CreateUpdateLegalClient);
    }

    handleClientSearchSubmit(value, index);
  };

  const handleRowDelete = (index: number, remove: (index: number | number[]) => void): void => {
    const updatedRepresentatives = [...representatives];
    updatedRepresentatives.splice(index, 1);
    setRepresentatives(updatedRepresentatives);
    remove(index);
  };

  return (
    <Form.List name={["representatives"]}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(field => (
            <React.Fragment key={field.key}>
              <HiddenInput name={[field.name, "id"]} />
              <HiddenInput name={[field.name, "optimisticLockVersion"]} />
              <HiddenInput name={[field.name, "representativeId"]} />

              <Row gutter={rowGutter}>
                <Col span={colSpan}>
                  <Form.Item
                    {...field}
                    name={[field.name, "function"]}
                    label={t("client.enums.representativeFunction._label")}
                    rules={[validations.notNull]}
                  >
                    <Select
                      {...selectStandardProps}
                      options={Object.keys(ClientRepresentativeFunction).map(func => ({
                        value: func,
                        label: t("client.enums.representativeFunction." + func)
                      }))}
                    />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    {...field}
                    name={[field.name, "representativePin"]}
                    label={t("client.attrs.representatives.representativePin")}
                    rules={[validations.notBlank, validations.pin, noRepeatedRepresentative]}
                    validateFirst
                  >
                    <Input.Search
                      loading={clientSearch.inProgress && selectedSearchIndex === field.name}
                      onFocus={() => setSelectedSearchIndex(field.name)}
                      onSearch={value => handleClientSearchSubmit(value, field.name)}
                      onChange={event => handleClientSearchChange(event.target.value, field.name)}
                    />
                  </Form.Item>
                </Col>

                <Col span={colSpan + 3}>
                  {representatives[field.name] &&
                    (representatives[field.name] === true ? (
                      <Alert
                        showIcon
                        type="warning"
                        message={
                          <span>
                            {t("client.helpers.representativeNotFound")}
                            <span style={{ paddingLeft: "10px" }}>
                              <ActionTextIcon
                                path={CLIENT_ROUTE_PATHS.create.to}
                                target="_blank"
                                icon="user-add"
                                color="blue"
                                text={t("client.actions.createRepresentative")}
                              />
                            </span>
                          </span>
                        }
                      />
                    ) : (
                      <div style={{ marginTop: "32px" }}>
                        <ClientTypeTagWithName
                          client={representatives[field.name] as ClientBase}
                          showLink
                          target="_blank"
                        />
                      </div>
                    ))}
                </Col>

                <Col span={1}>
                  <DeleteIcon onClick={() => handleRowDelete(field.name, remove)} />
                </Col>
              </Row>
            </React.Fragment>
          ))}

          <ActionButton
            icon="plus"
            label={t("client.actions.addRepresentative")}
            onClick={() => add({ function: null, representativePin: null })}
          />
        </>
      )}
    </Form.List>
  );
};

export default ClientRepresentativesFormPart;
