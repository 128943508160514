import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import t from "../../app/i18n";
import { EntityIdObject, NormalizedError, RootState, TwoLevelEntityIdObject } from "../../common/types";
import messageUtils from "../../common/utils/messageUtils";
import { openBlobFile } from "../../common/utils/utils";
import { changeRunningRequestKeyAction } from "../ducks";
import api from "./api";
import {
  BugReport,
  BugReportAttachment,
  BugReportReducerState,
  CreateAutomaticFrontendErrorLog,
  CreateUserReportedBug
} from "./types";

/**
 * ACTIONS
 */
export const createAutomaticFrontendErrorLogBugReportActions = createAsyncAction(
  "bug-report/CREATE_AUTOMATIC_FRONTEND_ERROR_LOG_REQUEST",
  "bug-report/CREATE_AUTOMATIC_FRONTEND_ERROR_LOG_SUCCESS",
  "bug-report/CREATE_AUTOMATIC_FRONTEND_ERROR_LOG_FAILURE"
)<CreateAutomaticFrontendErrorLog, void, NormalizedError>();

export const createUserReportedBugReportActions = createAsyncAction(
  "bug-report/CREATE_BUG_REPORT_REQUEST",
  "bug-report/CREATE_BUG_REPORT_SUCCESS",
  "bug-report/CREATE_BUG_REPORT_FAILURE"
)<CreateUserReportedBug, BugReport, NormalizedError>();

export const getBugReportActions = createAsyncAction(
  "bug-report/GET_BUG_REPORT_REQUEST",
  "bug-report/GET_BUG_REPORT_SUCCESS",
  "bug-report/GET_BUG_REPORT_FAILURE"
)<EntityIdObject, BugReport, NormalizedError>();

export const downloadBugReportAttachmentActions = createAsyncAction(
  "bug-report/DOWNLOAD_BUG_REPORT_ATTACHMENT_REQUEST",
  "bug-report/DOWNLOAD_BUG_REPORT_ATTACHMENT_SUCCESS",
  "bug-report/DOWNLOAD_BUG_REPORT_ATTACHMENT_FAILURE"
)<TwoLevelEntityIdObject, void, NormalizedError>();

export const deleteStateBugReportDetailAction = createAction("bug-report/DELETE_STATE_DETAIL")<void>();

const actions = {
  createAutomaticFrontendErrorLogBugReportActions,
  createUserReportedBugReportActions,
  getBugReportActions,
  downloadBugReportAttachmentActions,
  deleteStateBugReportDetailAction
};

export type BugReportAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: BugReportReducerState = {
  bugReportDetail: null
};

const bugReportDetailReducer = createReducer(initialState.bugReportDetail)
  .handleAction(getBugReportActions.success, (_, { payload }) => payload)
  .handleAction([getBugReportActions.failure, deleteStateBugReportDetailAction], () => initialState.bugReportDetail);

export const bugReportReducer = combineReducers<BugReportReducerState>({
  bugReportDetail: bugReportDetailReducer
});

/**
 * SELECTORS
 */
const selectBugReport = (state: RootState): BugReportReducerState => state.bugReport;

export const selectBugReportDetail = (state: RootState): BugReport => selectBugReport(state).bugReportDetail;

/**
 * SAGAS
 */
function* createAutomaticFrontendErrorLogBugReport({
  payload
}: ReturnType<typeof createAutomaticFrontendErrorLogBugReportActions.request>) {
  try {
    yield call(api.createAutomaticFrontendErrorLogBugReport, payload);
    yield put(createAutomaticFrontendErrorLogBugReportActions.success());
  } catch (error) {
    yield put(createAutomaticFrontendErrorLogBugReportActions.failure(error));
  }
}

function* createUserReportedBugReport({ payload }: ReturnType<typeof createUserReportedBugReportActions.request>) {
  try {
    const createBugReportResponse: AxiosResponse<BugReport> = yield call(api.createUserReportedBugReport, {
      title: payload.title,
      content: payload.content
    });

    let addAttachmentsResponse: AxiosResponse<BugReportAttachment[]> = null;
    if (payload.files.get("files")) {
      addAttachmentsResponse = yield call(api.addBugReportAttachments, {
        id: createBugReportResponse.data.id,
        object: payload.files
      });
    }

    yield put(
      createUserReportedBugReportActions.success({
        ...createBugReportResponse.data,
        attachments: addAttachmentsResponse?.data || []
      })
    );
    yield put(changeRunningRequestKeyAction());

    messageUtils.successNotification(t("common.operationSuccess"), t("bugReport.helpers.reportCreated"));
  } catch (error) {
    yield put(createUserReportedBugReportActions.failure(error));
  }
}

function* getBugReport({ payload }: ReturnType<typeof getBugReportActions.request>) {
  try {
    const response: AxiosResponse<BugReport> = yield call(api.getBugReport, payload);
    yield put(getBugReportActions.success(response.data));
  } catch (error) {
    yield put(getBugReportActions.failure(error));
  }
}

function* downloadBugReportAttachment({ payload }: ReturnType<typeof downloadBugReportAttachmentActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadBugReportAttachment, payload);
    openBlobFile(response);
    yield put(downloadBugReportAttachmentActions.success());
  } catch (error) {
    yield put(downloadBugReportAttachmentActions.failure(error));
  }
}

export function* bugReportSaga() {
  yield takeLatest(createAutomaticFrontendErrorLogBugReportActions.request, createAutomaticFrontendErrorLogBugReport);
  yield takeLatest(createUserReportedBugReportActions.request, createUserReportedBugReport);
  yield takeLatest(getBugReportActions.request, getBugReport);
  yield takeLatest(downloadBugReportAttachmentActions.request, downloadBugReportAttachment);
}
