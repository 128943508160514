import { Checkbox, Col, Divider, Form, Input, Modal, Row, Tree } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import LabelWithTooltip from "../../../../common/components/form/labels/LabelWithTooltip";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import { Feature, Permission, systemAdminOnlyPermissions } from "../../../../common/security/authorization/enums";
import { AntTreeStrictCheckedProps, RootState } from "../../../../common/types";
import {
  phoneNumberNormalizeFunction,
  resolveFormValidationError,
  useFormErrorHandler
} from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import messageUtils from "../../../../common/utils/messageUtils";
import { containsAll, getAllPermissionPrerequisites } from "../../../../common/utils/utils";
import { regexPatterns, validations } from "../../../../common/utils/validationUtils";
import { selectIsSystemAdmin } from "../../../auth/ducks";
import AgentSelect from "../../../enumerations/components/form/AgentSelect";
import { selectTopAgentAllowedFeaturesEnums } from "../../../enumerations/ducks";
import { requests } from "../../../user/api";
import DefaultPermissionsFormPart from "../../../user/components/forms/DefaultPermissionsFormPart";
import { adminCreateUserWithSingleAgentUserAccountActions } from "../../../user/ducks";
import { AdminCreateUserWithAgentUserAccounts } from "../../../user/types";
import { buildPermissionTreeNodes } from "../../../user/utils";
import { AgentType } from "../../enums";
import { Agent, LegalAgent } from "../../types";

interface Props {
  open: boolean;
  agent: Agent;
  onFormSubmit: typeof adminCreateUserWithSingleAgentUserAccountActions.request;
  onFormCancel: () => void;
}

const AdminCreateUserForAgentForm = ({ open, agent, onFormSubmit, onFormCancel }: Props) => {
  const isCurrentUserSystemAdmin = useSelector<RootState, boolean>(selectIsSystemAdmin);
  const topAgentAllowedFeatures = useSelector<RootState, Feature[]>(selectTopAgentAllowedFeaturesEnums);

  const [form] = Form.useForm<AdminCreateUserWithAgentUserAccounts>();
  useFormErrorHandler(form, "user.attrs", [
    requests.ADMIN_CREATE_USER_WITH_AGENT_USER_ACCOUNTS,
    requests.ADMIN_UPDATE_USER,
    requests.ADMIN_ADD_AGENT_USER_ACCOUNT
  ]);

  const [selectedPermissions, setSelectedPermissions] = useState<Permission[]>([]);

  const handleFormCancel = (): void => {
    setSelectedPermissions([]);
    onFormCancel();
  };

  const inProgress = useRequestFinishedCallback(
    [
      requests.ADMIN_CREATE_USER_WITH_AGENT_USER_ACCOUNTS,
      requests.ADMIN_UPDATE_USER,
      requests.ADMIN_ADD_AGENT_USER_ACCOUNT
    ],
    handleFormCancel
  );

  const handlePermissionTreeCheck = (checked: (string | number)[] | AntTreeStrictCheckedProps): void => {
    const checkedPermissions = (checked as AntTreeStrictCheckedProps).checked.map(checked => Permission[checked]);
    setSelectedPermissions(
      checkedPermissions.filter(permission =>
        containsAll(checkedPermissions, ...getAllPermissionPrerequisites(permission))
      )
    );
  };

  const handleSystemAdminChange = (event: CheckboxChangeEvent): void => {
    if (!event.target.checked) {
      setSelectedPermissions(
        selectedPermissions.filter(permission => !systemAdminOnlyPermissions.includes(permission))
      );
    }
  };

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (selectedPermissions.length === 0) {
          messageUtils.errorMessage(t("user.helpers.noPermissions"));
        } else {
          onFormSubmit({
            ...values,
            accounts: values.accounts.map(acc => ({ ...acc, agentId: agent.id, permissions: selectedPermissions }))
          });
        }
      })
      .catch(resolveFormValidationError);
  };

  const colSpan = 8;

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={t("user.titles.createUser")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={handleFormCancel}
    >
      <Form form={form} layout="vertical" name="adminCreateUserForAgentForm" onFinish={handleFormSubmit}>
        <Divider orientation="left" style={{ marginTop: 0 }}>
          {t("user.titles.basicData")}
        </Divider>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="name"
              label={t("user.attrs.name")}
              rules={[validations.notBlank, validations.size(1, 255), validations.pattern(regexPatterns.nameRegex)]}
            >
              <Input placeholder={t("user.helpers.userNamePlaceholder")} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="email"
              label={t("user.attrs.email")}
              rules={[validations.notBlank, validations.size(1, 254), validations.email]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="phone"
              label={t("user.attrs.phone")}
              rules={[validations.notBlank, validations.size(1, 19), validations.mobilePhoneNumber]}
              normalize={phoneNumberNormalizeFunction}
            >
              <Input />
            </Form.Item>
          </Col>

          {isCurrentUserSystemAdmin && (
            <Col span={colSpan}>
              <Form.Item
                name="systemAdmin"
                className="form-item-without-label"
                valuePropName="checked"
                initialValue={false}
                rules={[validations.none]}
              >
                <Checkbox onChange={handleSystemAdminChange}>{t("user.attrs.systemAdmin")}</Checkbox>
              </Form.Item>
            </Col>
          )}
        </Row>

        <Divider orientation="left">{t("user.titles.account")}</Divider>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <AgentSelect
              formItemProps={{
                name: ["accounts", 0, "representingAgentId"],
                label: (
                  <LabelWithTooltip
                    label={t("user.attrs.representingAgentId")}
                    tooltip={t("user.helpers.representingAgentInfo")}
                  />
                ),
                rules: [agent.type === AgentType.LEGAL ? validations.notNull : validations.none]
              }}
              selectProps={{
                allowClear: true,
                disabled: agent.type !== AgentType.LEGAL
              }}
              optionsProps={{
                filter: agentOption => (agent as LegalAgent).representatives?.some(repr => repr.id === agentOption.id)
              }}
            />
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name={["accounts", 0, "disabled"]}
              className="form-item-without-label"
              valuePropName="checked"
              initialValue={false}
              rules={[validations.none]}
            >
              <Checkbox>{t("user.attrs.disabled")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left" className="divider-subheader">
          {t("user.titles.permissionsSettings")}
        </Divider>

        <DefaultPermissionsFormPart
          allowedFeatures={topAgentAllowedFeatures}
          onPermissionsSet={setSelectedPermissions}
        />

        <Row gutter={rowGutter}>
          <Col span={colSpan * 2}>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.systemAdmin !== next.systemAdmin}>
              {({ getFieldValue }) => (
                <Form.Item label={t("user.attrs.permissionsLabel")} required>
                  <Tree
                    showLine={{ showLeafIcon: false }}
                    checkStrictly
                    checkable
                    checkedKeys={{ checked: selectedPermissions, halfChecked: [] }}
                    treeData={buildPermissionTreeNodes(
                      isCurrentUserSystemAdmin,
                      getFieldValue("systemAdmin"),
                      topAgentAllowedFeatures,
                      selectedPermissions
                    )}
                    onCheck={handlePermissionTreeCheck}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AdminCreateUserForAgentForm;
