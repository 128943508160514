import React from "react";
import t from "../../../../app/i18n";

interface Props {
  type:
    | "area"
    | "distance"
    | "engineDisplacement"
    | "enginePower"
    | "euro"
    | "million"
    | "nettoPoints"
    | "percentage"
    | "piece"
    | "volume"
    | "weight";
}

const InputAddon = ({ type }: Props) => {
  switch (type) {
    case "area":
      return (
        <span>
          m<sup>2</sup>
        </span>
      );
    case "engineDisplacement":
      return (
        <span>
          cm<sup>3</sup>
        </span>
      );
    case "percentage":
      return <span>%</span>;
    case "volume":
      return (
        <span>
          m<sup>3</sup>
        </span>
      );
    default:
      return <span>{t("common.sign." + type)}</span>;
  }
};

export default InputAddon;
