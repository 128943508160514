import { Tabs } from "antd";
import React, { useState } from "react";
import { UUID } from "../../../../../common/types";
import { formatAgentAggregatedName } from "../../../../../common/utils/formatUtils";
import { UserProfile } from "../../../types";

interface Props {
  userProfile: UserProfile;
  title: string;
  component: (agentId: UUID) => JSX.Element;
}

const UserProfileAgentTabSwitchView = ({ userProfile, title, component }: Props) => {
  const [agentId, setAgentId] = useState<UUID>(userProfile.agent.id);

  return userProfile.representingAgent ? (
    <>
      <h2>{title}</h2>
      <Tabs
        activeKey={agentId}
        onChange={setAgentId}
        destroyInactiveTabPane
        items={[
          {
            key: userProfile.agent.id,
            label: formatAgentAggregatedName(userProfile.agent),
            children: component(agentId)
          },
          {
            key: userProfile.representingAgent.id,
            label: formatAgentAggregatedName(userProfile.representingAgent),
            children: component(agentId)
          }
        ]}
      />
    </>
  ) : (
    <>
      <h2>
        {title} | {formatAgentAggregatedName(userProfile.agent)}
      </h2>
      {component(agentId)}
    </>
  );
};

export default UserProfileAgentTabSwitchView;
