import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { selectIsUserAuthenticated } from "../../../modules/auth/ducks";
import { AUTH_ROUTE_PATHS } from "../../../modules/auth/paths";
import { DASHBOARD_ROUTE_PATHS } from "../../../modules/dashboard/paths";
import { AUTH_REDIRECT_SEARCH_PARAM } from "../../constants";
import { RootState } from "../../types";

interface Props {
  component: ReactElement;
}

const AuthenticatedWrapper = ({ component }: Props) => {
  const { pathname, search } = useLocation();
  const isUserAuthenticated = useSelector<RootState, boolean>(selectIsUserAuthenticated);

  const redirectParam =
    pathname && pathname !== DASHBOARD_ROUTE_PATHS.homepage.to
      ? `?${AUTH_REDIRECT_SEARCH_PARAM}=${encodeURIComponent(pathname + (search || ""))}`
      : "";

  return isUserAuthenticated ? (
    component
  ) : (
    <Navigate replace to={AUTH_ROUTE_PATHS.login.to + redirectParam} state={{ redirect: redirectParam }} />
  );
};

export default AuthenticatedWrapper;
