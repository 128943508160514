import { Col, Form, Modal, Row, Select } from "antd";
import React from "react";
import t from "../../../../app/i18n";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import { UUID } from "../../../../common/types";
import { formatClientName } from "../../../../common/utils/formatUtils";
import { resolveFormValidationError, selectStandardProps } from "../../../../common/utils/formUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { ClientRepresentative, LegalClient } from "../../types";

interface Props {
  open: boolean;
  client: LegalClient;
  onSelect: (clientRepresentative: ClientRepresentative) => void;
  onFormCancel: () => void;
}

const ClientRepresentativeSelectModal = ({ open, client, onSelect, onFormCancel }: Props) => {
  const [form] = Form.useForm<{ representativeId: UUID }>();

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        onSelect(client.representatives.find(r => r.id === values.representativeId));
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.SMALL}
      open={open}
      title={t("client.helpers.representativeSelect", { clientName: client?.aggregatedName })}
      okText={t("common.prefill")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="clientRepresentativeSelectForm">
        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item
              name="representativeId"
              label={t("calc.helpers.representativeSelectIdentifier")}
              rules={[validations.notNull]}
            >
              <Select
                {...selectStandardProps}
                options={(client?.representatives || []).map(r => ({
                  value: r.id,
                  label: `${formatClientName(r.representative)} | ${t(
                    "client.enums.representativeFunction." + r.function
                  )}`
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ClientRepresentativeSelectModal;
