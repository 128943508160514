import { Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import groupBy from "lodash/groupBy";
import React from "react";
import { useSelector } from "react-redux";
import t, { DEFAULT_LOCALE } from "../../../../app/i18n/index";
import { RootState, UUID } from "../../../../common/types";
import { formatLifeInsuranceTariff } from "../../../../common/utils/formatUtils";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import { LifeInsuranceTariffBase } from "../../../lifeinsurancetariff/types";
import { selectTariffsClassificationEnums } from "../../ducks";
import { FilterFunction, ProductWithLifeInsuranceTariffs } from "../../types";

interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
  optionsProps?: TariffOptionsProps;
}

interface TariffOptionsProps {
  productId?: UUID;
  hideAll?: boolean;
  groupByPriority?: boolean;
  filter?: FilterFunction<LifeInsuranceTariffBase>;
}

const LifeInsuranceTariffSelect = ({ formItemProps, selectProps, optionsProps }: Props) => {
  const productsWithTariffs = useSelector<RootState, ProductWithLifeInsuranceTariffs[]>(
    selectTariffsClassificationEnums
  );

  const resolveTariffSelectOptions = (): LifeInsuranceTariffBase[] => {
    if (optionsProps?.hideAll) {
      return [];
    }

    let tariffs = optionsProps?.productId
      ? productsWithTariffs.find(product => product.id === optionsProps.productId)?.tariffs || []
      : productsWithTariffs.flatMap(product => product.tariffs);

    if (optionsProps?.filter) {
      tariffs = tariffs.filter(optionsProps.filter);
    }

    return tariffs.sort((a, b) => a.name.localeCompare(b.name, DEFAULT_LOCALE, { sensitivity: "accent" }));
  };

  if (optionsProps?.groupByPriority) {
    const groups = groupBy(resolveTariffSelectOptions(), tariff => tariff.primaryTariff);
    const primaryTariffs = groups["true"];
    const secondaryTariffs = groups["false"];

    if (primaryTariffs?.length > 0 && secondaryTariffs?.length > 0) {
      return (
        <Form.Item {...formItemProps}>
          <Select {...selectStandardProps} {...selectProps}>
            <Select.OptGroup key={1} label={t("lifeInsuranceTariff.helpers.primaryTariffs")}>
              {primaryTariffs.map(tariff => (
                <Select.Option key={tariff.id} value={tariff.id}>
                  {formatLifeInsuranceTariff(tariff)}
                </Select.Option>
              ))}
            </Select.OptGroup>
            <Select.OptGroup key={2} label={t("lifeInsuranceTariff.helpers.secondaryTariffs")}>
              {secondaryTariffs.map(tariff => (
                <Select.Option key={tariff.id} value={tariff.id}>
                  {formatLifeInsuranceTariff(tariff)}
                </Select.Option>
              ))}
            </Select.OptGroup>
          </Select>
        </Form.Item>
      );
    }
  }

  return (
    <Form.Item {...formItemProps}>
      <Select
        {...selectStandardProps}
        {...selectProps}
        options={resolveTariffSelectOptions().map(tariff => ({
          value: tariff.id,
          label: formatLifeInsuranceTariff(tariff)
        }))}
      />
    </Form.Item>
  );
};

export default LifeInsuranceTariffSelect;
