import React from "react";
import t from "../../../../app/i18n";
import { FinancialSector } from "../../../../modules/agent/enums";
import { AgentBase } from "../../../../modules/agent/types";
import { ClientBase } from "../../../../modules/client/types";
import { InstitutionBase } from "../../../../modules/institution/types";
import { ProductFinancialSector } from "../../../../modules/product/enums";
import AntIcon from "../../../components/icons/AntIcon";
import HtmlView from "../../../components/views/HtmlView";
import {
  formatAgentAggregatedName,
  formatIban,
  formatLocaleCurrency,
  formatLocaleDate,
  formatLocaleDateTime,
  formatLocaleNumber,
  formatLocalePercentageNumber,
  formatPhoneNumber
} from "../../../utils/formatUtils";
import { isDefinedValue } from "../../../utils/utils";

export const EmptyHistoryItem = () => <span className="sub-header-info dashed">{t("history.helpers.noValue")}</span>;

export const BooleanHistoryItem = (value: boolean) => (value ? <AntIcon type="check" /> : <AntIcon type="close" />);

export const DateHistoryItem = (value: string) => (value ? <span>{formatLocaleDate(value)}</span> : EmptyHistoryItem());

export const DateTimeHistoryItem = (value: string) =>
  value ? <span>{formatLocaleDateTime(value)}</span> : EmptyHistoryItem();

export const NumberHistoryItem = (value: number) =>
  isDefinedValue(value) ? <span>{formatLocaleNumber(value)}</span> : EmptyHistoryItem();

export const PercentageHistoryItem = (value: number) =>
  isDefinedValue(value) ? <span>{formatLocalePercentageNumber(value)}</span> : EmptyHistoryItem();

export const CurrencyHistoryItem = (value: number) =>
  isDefinedValue(value) ? <span>{formatLocaleCurrency(value)}</span> : EmptyHistoryItem();

export const AreaHistoryItem = (value: number) =>
  isDefinedValue(value) ? (
    <span>
      {value} m<sup>2</sup>
    </span>
  ) : (
    EmptyHistoryItem()
  );

export const IbanHistoryItem = (value: string) => (value ? <span>{formatIban(value)}</span> : EmptyHistoryItem());

export const PhoneHistoryItem = (value: string) =>
  value ? <span>{formatPhoneNumber(value)}</span> : EmptyHistoryItem();

export const NoteHistoryItem = (value: string) =>
  !value || value === "<p><br></p>" ? EmptyHistoryItem() : <HtmlView value={value} />;

export const ProductFinancialSectorHistoryItem = (value: ProductFinancialSector) =>
  value ? <span>{t("product.enums.financialSector." + value)}</span> : EmptyHistoryItem();

export const ProductFinancialSectorsHistoryItem = (values: ProductFinancialSector[]) =>
  values ? (
    <span>{values.map(value => t("product.enums.financialSector." + value)).join(", ")}</span>
  ) : (
    EmptyHistoryItem()
  );

export const FinancialSectorHistoryItem = (value: FinancialSector) =>
  value ? <span>{t("agent.enums.financialSector." + value)}</span> : EmptyHistoryItem();

export const FinancialSectorsHistoryItem = (values: FinancialSector[]) =>
  values ? (
    <span>{values.map(value => t("agent.enums.financialSector." + value)).join(", ")}</span>
  ) : (
    EmptyHistoryItem()
  );

export const AgentHistoryItem = (value: AgentBase) =>
  value ? <span>{formatAgentAggregatedName(value)}</span> : EmptyHistoryItem();

export const ClientHistoryItem = (value: ClientBase) =>
  value ? <span>{value.aggregatedName}</span> : EmptyHistoryItem();

export const InstitutionHistoryItem = (value: InstitutionBase) =>
  value ? <span>{value.name}</span> : EmptyHistoryItem();
