import { Button, Card, Col, Form, Input, Row } from "antd";
import React, { useEffect } from "react";
import t from "../../../../app/i18n";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../common/constants";
import { SearchPageRequest, SearchPageResult } from "../../../../common/types";
import { validationConstants, validations } from "../../../../common/utils/validationUtils";
import { LifeInsuranceTariffGroup } from "../../types";

interface Props {
  currentFilter: SearchPageResult<LifeInsuranceTariffGroup>;
  onFilterSubmit: (filter: SearchPageRequest) => void;
  onCreateClick: () => void;
}

const LifeInsuranceTariffGroupFilterView = (props: Props) => {
  const [form] = Form.useForm<SearchPageRequest>();

  const colSpan = 6;

  useEffect(() => {
    form.setFieldsValue({
      keyword: props.currentFilter.keyword
    });
  }, [props.currentFilter, form]);

  return (
    <Card
      className="card-box"
      title={<h2>{t("lifeInsuranceTariff.titles.tariffGroupsAdmin")}</h2>}
      extra={
        <Button type="primary" icon={<AntIcon type="plus" />} onClick={props.onCreateClick}>
          {t("lifeInsuranceTariff.actions.createTariffGroup")}
        </Button>
      }
    >
      <Form form={form} onFinish={props.onFilterSubmit} layout="vertical" name="lifeInsuranceTariffGroupSearchForm">
        <Row gutter={rowGutter} justify="center">
          <Col span={colSpan}>
            <Form.Item
              name="keyword"
              label={t("common.search")}
              rules={[
                validations.size(
                  validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
                  validationConstants.SEARCH_KEYWORD_MAX_LENGTH
                )
              ]}
            >
              <Input allowClear placeholder={t("lifeInsuranceTariff.helpers.tariffGroupSearchHint")} />
            </Form.Item>
          </Col>

          <Col flex="110px" className="no-title-space">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default LifeInsuranceTariffGroupFilterView;
