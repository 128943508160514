import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../common/types";
import { ClientType } from "../client/enums";
import { Client, LegalClient, NaturalClient, SelfEmployedClient } from "../client/types";
import { InsuranceType } from "../contract/enums";
import { selectIsRequestInProgress } from "../ducks";
import { requests } from "./api";
import {
  deleteStateContractTerminationSearchResultAction,
  searchContractForTerminationActions,
  selectContractTerminationSearchResult
} from "./ducks";
import { ContractTerminationCategory, ContractTerminationReason } from "./enums";
import { ContractSearchForTerminationProps, ContractTerminationClient, ContractTerminationSearchResult } from "./types";

export const INSURANCE_TYPE_TO_TERMINATION_CATEGORY_MAP = new Map<InsuranceType, ContractTerminationCategory>([
  [InsuranceType.GENERIC, ContractTerminationCategory.GENERIC],
  [InsuranceType.MTPL, ContractTerminationCategory.VEHICLE],
  [InsuranceType.CRASH, ContractTerminationCategory.VEHICLE],
  [InsuranceType.GAP, ContractTerminationCategory.VEHICLE],
  [InsuranceType.PAS, ContractTerminationCategory.VEHICLE],
  [InsuranceType.REALTY, ContractTerminationCategory.PROPERTY],
  [InsuranceType.TRAVEL, ContractTerminationCategory.TRAVEL],
  [InsuranceType.LIFE, ContractTerminationCategory.LIFE]
]);

export const CONTRACT_TERMINATION_REASONS_MAP = new Map<ContractTerminationCategory, ContractTerminationReason[]>([
  [
    ContractTerminationCategory.VEHICLE,
    [
      ContractTerminationReason.VEHICLE_INSURANCE_PERIOD_END,
      ContractTerminationReason.VEHICLE_DECOMMISSION,
      ContractTerminationReason.WITHIN_TWO_MONTHS_OF_CONCLUSION,
      ContractTerminationReason.VEHICLE_DESTRUCTION,
      ContractTerminationReason.HOLDER_CHANGE,
      ContractTerminationReason.VEHICLE_THEFT,
      ContractTerminationReason.DISSATISFACTION_WITH_PAYMENT,
      ContractTerminationReason.DENIAL_OF_PAYMENT
    ]
  ],
  [
    ContractTerminationCategory.PROPERTY,
    [
      ContractTerminationReason.INSURANCE_PERIOD_END,
      ContractTerminationReason.INSURANCE_SUBJECT_END,
      ContractTerminationReason.OWNER_CHANGE,
      ContractTerminationReason.WITHIN_TWO_MONTHS_OF_CONCLUSION,
      ContractTerminationReason.DISSATISFACTION_WITH_PAYMENT,
      ContractTerminationReason.DENIAL_OF_PAYMENT
    ]
  ],
  [
    ContractTerminationCategory.LIFE,
    [
      ContractTerminationReason.DEATH,
      ContractTerminationReason.WITHIN_TWO_MONTHS_OF_CONCLUSION,
      ContractTerminationReason.LIVING_TO_THE_END_OF_INSURANCE,
      ContractTerminationReason.INSURANCE_CANCELLATION_WITH_PAYMENT,
      ContractTerminationReason.REFUSAL_TO_FULFILL
    ]
  ],
  [
    ContractTerminationCategory.TRAVEL,
    [
      ContractTerminationReason.INSURANCE_PERIOD_END,
      ContractTerminationReason.WITHIN_TWO_MONTHS_OF_CONCLUSION,
      ContractTerminationReason.DISSATISFACTION_WITH_PAYMENT,
      ContractTerminationReason.DENIAL_OF_PAYMENT
    ]
  ],
  [
    ContractTerminationCategory.GENERIC,
    [
      ContractTerminationReason.INSURANCE_PERIOD_END,
      ContractTerminationReason.INSURANCE_SUBJECT_END,
      ContractTerminationReason.OWNER_CHANGE,
      ContractTerminationReason.WITHIN_TWO_MONTHS_OF_CONCLUSION,
      ContractTerminationReason.DISSATISFACTION_WITH_PAYMENT,
      ContractTerminationReason.DENIAL_OF_PAYMENT
    ]
  ]
]);

export const useContractSearchForTermination = (): ContractSearchForTerminationProps => {
  const result = useSelector<RootState, ContractTerminationSearchResult>(selectContractTerminationSearchResult);
  const inProgress = useSelector<RootState, boolean>(state =>
    selectIsRequestInProgress(state, requests.SEARCH_CONTRACT_FOR_TERMINATION)
  );

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          onSearch: searchContractForTerminationActions.request,
          onResultDelete: deleteStateContractTerminationSearchResultAction
        },
        dispatch
      ),
    [dispatch]
  );

  return { result, inProgress, onSearch: actions.onSearch, onResultDelete: actions.onResultDelete };
};

export const clientToContractTerminationClient = (client: Client): ContractTerminationClient => {
  return client
    ? {
        type: client.type,
        address: client.address,
        ...(client.type === ClientType.NATURAL
          ? {
              personalIdentificationNumber: (client as NaturalClient).personalIdentificationNumber,
              firstName: (client as NaturalClient).firstName,
              lastName: (client as NaturalClient).lastName,
              academicDegree: (client as NaturalClient).academicDegree,
              academicDegreeAfter: (client as NaturalClient).academicDegreeAfter
            }
          : {
              companyName: (client as SelfEmployedClient | LegalClient).companyName,
              companyRegistrationNumber: (client as SelfEmployedClient | LegalClient).companyRegistrationNumber
            })
      }
    : null;
};
