import classNames from "classnames";
import React, { CSSProperties } from "react";
import { resolveFileIconType } from "../../utils/utils";
import AntIcon from "./AntIcon";

interface Props {
  contentType: string;
  size?: "small" | "default" | "large" | "huge";
  className?: string;
  style?: CSSProperties;
}

const FileTypeIcon = ({ contentType, size, className, style }: Props) => (
  <AntIcon
    type={resolveFileIconType(contentType)}
    className={classNames("file-type-icon", size, className)}
    style={style}
  />
);

export default FileTypeIcon;
