import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { selectIsNotFoundErrorResponse } from "../../../modules/ducks";
import NotFound from "../../pages/NotFound/NotFound";
import { ApiRequest, RootState } from "../../types";

interface Props {
  children: React.ReactNode;
  itemLoaded: boolean;
  notFoundCheckRequest?: ApiRequest;
}

interface StateProps {
  hasNotFoundErrorResponse: boolean;
}

const DisplayWrapper = (props: Props & StateProps) => {
  const [itemNotFound, setItemNotFound] = useState<boolean>(false);

  useEffect(() => {
    if (props.hasNotFoundErrorResponse) {
      setItemNotFound(true);
    }
  }, [props.hasNotFoundErrorResponse]);

  return props.itemLoaded ? (
    <>{props.children}</>
  ) : itemNotFound ? (
    <NotFound />
  ) : (
    <>
      <Skeleton active paragraph={{ rows: 6 }} />
      <Skeleton active paragraph={{ rows: 3 }} />
      <Skeleton active paragraph={{ rows: 2 }} />
    </>
  );
};

const mapStateToProps = (state: RootState, props: Props): StateProps => ({
  hasNotFoundErrorResponse: props.notFoundCheckRequest
    ? selectIsNotFoundErrorResponse(state, props.notFoundCheckRequest)
    : false
});

export default connect<StateProps, {}, Props, RootState>(mapStateToProps)(DisplayWrapper);
