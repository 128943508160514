import { Col, Form, Input, Modal, Row, Select } from "antd";
import React from "react";
import t from "../../../../../app/i18n";
import { ModalSizes, rowGutter } from "../../../../../common/constants";
import {
  resolveFormValidationError,
  selectStandardProps,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { createVehicleBrandMappingActions } from "../../ducks";
import { VehicleMappingType } from "../../enums";
import { CreateVehicleMapping, CreateVehicleMappingFormItem, VehicleMappingData } from "../../types";
import { parseVehicleMappingsFromIdentifiers, VEHICLE_IDENTIFIER_DELIMITER, VEHICLE_TYPES_PATH_MAP } from "../../utils";

interface Props {
  open: boolean;
  selectedMappingTypes: VehicleMappingType[];
  mappingData: VehicleMappingData[];
  onCreate: typeof createVehicleBrandMappingActions.request;
  onFormCancel: () => void;
}

const VehicleBrandCreateMappingModal = ({ open, selectedMappingTypes, mappingData, onCreate, onFormCancel }: Props) => {
  const [form] = Form.useForm<CreateVehicleMappingFormItem>();
  useFormErrorHandler(form, "vehicle.attrs", [requests.CREATE_VEHICLE_BRAND_MAPPING]);

  const inProgress = useRequestFinishedCallback([requests.CREATE_VEHICLE_BRAND_MAPPING], onFormCancel);

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        onCreate(parseVehicleMappingsFromIdentifiers(values) as CreateVehicleMapping);
      })
      .catch(resolveFormValidationError);
  };

  const colSpan = 6;

  return (
    <Modal
      width={ModalSizes.HUGE}
      open={open}
      maskClosable={false}
      confirmLoading={inProgress}
      title={t("vehicle.titles.brandCreate")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <Form form={form} layout="vertical" name="vehicleBrandCreateMappingForm">
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item name="name" label={t("vehicle.attrs.name")} rules={[validations.notBlank]}>
              <Input size="small" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          {Object.values(VehicleMappingType).map((type, index) => {
            const typePath = VEHICLE_TYPES_PATH_MAP.get(type);
            const mappings = mappingData.find(mapping => mapping.type === type);

            return selectedMappingTypes.includes(type) ? (
              <Col span={colSpan} key={index}>
                <Form.Item
                  name={`${typePath}Identifier`}
                  label={t(`vehicle.attrs.${typePath}Identifier`)}
                  rules={[validations.none]}
                >
                  <Select
                    {...selectStandardProps}
                    allowClear
                    size="small"
                    options={(mappings && mappings.brands ? mappings.brands : []).map(item => ({
                      value: item.id + VEHICLE_IDENTIFIER_DELIMITER + item.name,
                      label: `${item.name} (${item.id})`
                    }))}
                  />
                </Form.Item>
              </Col>
            ) : null;
          })}
        </Row>
      </Form>
    </Modal>
  );
};

export default VehicleBrandCreateMappingModal;
