import { Layout } from "antd";
import moment from "moment";
import React from "react";
import cliipLogo from "../../../assets/images/logo-clip-1000xsquare-flower-transparent.png";
import itgoLogo from "../../../assets/images/logo-itgo-transparent.png";
import t from "../../i18n";

const Footer = () => (
  <Layout.Footer className="footer">
    <div className="footer__wrapper">
      <div>
        <div className="footer__text">Powered by</div>
        <img className="footer__company-logo" src={itgoLogo} alt="itgo logo" />
      </div>
      <div className="footer__info">
        <span>
          <strong>cliip</strong> -{" "}
          <span>
            <strong>C</strong>lient <strong>L</strong>oan <strong>I</strong>nsurance <strong>I</strong>nvestment{" "}
            <strong>P</strong>ortal
          </span>
        </span>
        {t("common.copyright", { year: moment().year() })}
      </div>
      <img className="footer__app-logo" src={cliipLogo} alt="cliip logo" />
    </div>
  </Layout.Footer>
);

export default Footer;
