import { Tag } from "antd";
import React from "react";
import t from "../../../app/i18n";
import { JobState } from "../enums";

interface Props {
  state: JobState;
  style?: React.CSSProperties;
}

const JobStateTag = ({ state, style }: Props) => {
  let color: string;

  switch (state) {
    case JobState.MANUALLY_SCHEDULED:
      color = "blue";
      break;
    case JobState.IN_PROGRESS:
      color = "purple";
      break;
    case JobState.COMPLETED:
      color = "green";
      break;
    case JobState.CANCELLED:
      color = "orange";
      break;
    case JobState.ABORTED:
      color = "volcano";
      break;
    case JobState.TERMINATED_BY_ERROR:
      color = "red";
      break;
    default:
      return null;
  }

  return (
    <Tag color={color} style={style}>
      {t("job.enums.jobState." + state)}
    </Tag>
  );
};

export default JobStateTag;
