import { Button, Col, Form, Input, Modal, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { generatePath, Link } from "react-router-dom";
import t from "../../../../app/i18n";
import AntIcon from "../../../../common/components/icons/AntIcon";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { ModalSizes, rowGutter, TableSizes } from "../../../../common/constants";
import {
  formatLocaleCurrency,
  formatLocaleCurrencyWithNullAsZero,
  formatLocaleDate,
  formatLocaleNettoPoints
} from "../../../../common/utils/formatUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { paginationStandardProps, tableStandardProps } from "../../../../common/utils/utils";
import { validationConstants, validations } from "../../../../common/utils/validationUtils";
import { CONTRACT_ROUTE_PATHS } from "../../../contract/paths";
import InstitutionSelect from "../../../enumerations/components/form/InstitutionSelect";
import CommissionKindTag from "../../batch/components/CommissionKindTag";
import { CommissionKind } from "../../batch/enums";
import { CommissionsBatchOutputAttachment } from "../../batch/types";
import { removeBatchNameCustomSuffix } from "../../batch/utils";
import { requests } from "../api";
import {
  CalculatedCommissionAgentProfile,
  CalculatedCommissionsFilterPageRequest,
  CalculatedCommissionsFilterPageResult
} from "../types";

interface Props {
  commissionsOutput: CommissionsBatchOutputAttachment;
  commissionsPage: CalculatedCommissionsFilterPageResult;
  onClose: () => void;
  onAfterClose: () => void;
  onFilterSubmit: (filter: CalculatedCommissionsFilterPageRequest) => void;
  onPageChange: (pageNumber: number) => void;
}

const OutputCalculatedCommissionTableView = ({
  commissionsOutput,
  commissionsPage,
  onClose,
  onAfterClose,
  onFilterSubmit,
  onPageChange
}: Props) => {
  const [form] = Form.useForm<CalculatedCommissionsFilterPageRequest>();

  const columns: ColumnsType<CalculatedCommissionAgentProfile> = [
    {
      key: "sequenceNumber",
      title: "#",
      width: 45,
      render: (_, record, index) => commissionsPage.pageIndex * commissionsPage.pageSize + index + 1
    },
    {
      key: "contract",
      title: t("commissions.batch.attrs.commission.contract"),
      width: 90,
      ellipsis: { showTitle: false },
      render: (_, record) => (
        <>
          {record.commission.contractNumber && (
            <>
              <Ellipsis tooltip={record.commission.contractNumber}>
                <Link
                  to={generatePath(CONTRACT_ROUTE_PATHS.detail.to, { id: record.commission.contract.id })}
                  target="_blank"
                >
                  {record.commission.contractNumber}
                </Link>
              </Ellipsis>
              <br />
            </>
          )}
          {record.commission.secondaryContractNumber && (
            <Ellipsis tooltip={record.commission.secondaryContractNumber}>
              <Link
                to={generatePath(CONTRACT_ROUTE_PATHS.detail.to, { id: record.commission.contract.id })}
                target="_blank"
              >
                {record.commission.secondaryContractNumber}
              </Link>
            </Ellipsis>
          )}
        </>
      )
    },
    {
      key: "clientName",
      title: t("commissions.batch.attrs.commission.clientName"),
      width: 90,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.commission.clientName}</Ellipsis>
    },
    {
      key: "institution",
      title: t("commissions.batch.attrs.commission.institution"),
      width: 90,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.commission.institution.name}</Ellipsis>
    },
    {
      key: "product",
      title: t("commissions.batch.attrs.commission.product"),
      width: 95,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.commission.contract.product.name}</Ellipsis>
    },
    {
      key: "dates",
      title: t("commissions.batch.attrs.commission.dates"),
      width: 105,
      render: (_, record) => (
        <>
          {formatLocaleDate(record.commission.startDate)}
          <br />
          {formatLocaleDate(record.commission.endDate)}
        </>
      )
    },
    {
      key: "commissionKind",
      title: t("commissions.batch.enums.commissionKind._label"),
      width: 85,
      render: (_, record) => <CommissionKindTag kind={record.commission.commissionKind || CommissionKind.INITIAL} />
    },
    {
      key: "nettoPointAmount",
      title: t("commissions.batch.attrs.commission.calculatedCommissions.nettoPointAmount"),
      align: "right",
      width: 100,
      render: (_, record) => formatLocaleNettoPoints(record.nettoPointAmount)
    },
    {
      key: "commissionBaseAmount",
      title: t("commissions.batch.attrs.commission.commissionBaseAmount"),
      align: "right",
      width: 100,
      render: (_, record) => formatLocaleCurrency(record.commission.commissionBaseAmount)
    },
    {
      key: "commissionAmount",
      title: t("commissions.batch.attrs.commission.calculatedCommissions.commissionAmount"),
      align: "right",
      width: 100,
      render: (_, record) => formatLocaleCurrency(record.commissionAmount)
    }
  ];

  const inProgress = useRequestFinishedCallback([requests.FILTER_OUTPUT_CALCULATED_COMMISSIONS]);

  const colSpan = 8;

  return (
    <Modal
      width={ModalSizes.HUGE}
      title={
        commissionsOutput
          ? t("user.titles.outputCalculatedCommissions") + " " + removeBatchNameCustomSuffix(commissionsOutput.batch)
          : t("user.titles.outputCalculatedCommissions")
      }
      open={!!commissionsOutput}
      maskClosable={false}
      footer={
        <Button onClick={onClose} icon={<AntIcon type="close" />}>
          {t("common.close")}
        </Button>
      }
      onCancel={onClose}
      afterClose={() => {
        form.resetFields();
        onAfterClose();
      }}
    >
      <Form form={form} layout="vertical" onFinish={onFilterSubmit} name="outputCalculatedCommissionsFilterForm">
        <Row gutter={rowGutter} justify="center">
          <Col span={colSpan}>
            <Form.Item
              name="keyword"
              label={t("common.searchKeyword")}
              rules={[
                validations.size(
                  validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
                  validationConstants.SEARCH_KEYWORD_MAX_LENGTH
                )
              ]}
            >
              <Input allowClear placeholder={t("commissions.batch.helpers.commissionsSearchHint")} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <InstitutionSelect
              formItemProps={{
                name: "institutionIds",
                label: t("common.institutions"),
                rules: [validations.none]
              }}
              selectProps={{ mode: "multiple", maxTagCount: "responsive", allowClear: true }}
              optionsProps={{ groupByType: true }}
            />
          </Col>

          <Col flex="110px">
            <Form.Item className="form-item-without-label">
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row gutter={rowGutter}>
        <Col span={24}>
          <div className={commissionsPage.totalElementsCount > 0 ? "table-header-margin" : "margin-bottom-small"}>
            <b>{t("commissions.special.helpers.filteredCommissionAmountsSum")}: </b>
            {formatLocaleCurrencyWithNullAsZero(commissionsPage.commissionAmountsSum)}
          </div>
          <Table<CalculatedCommissionAgentProfile>
            {...tableStandardProps()}
            columns={columns}
            scroll={{ x: TableSizes.MEDIUM }}
            dataSource={commissionsPage.pageData}
            pagination={{
              ...paginationStandardProps,
              position: ["topRight"],
              current: commissionsPage.pageIndex + 1,
              pageSize: commissionsPage.pageSize,
              total: commissionsPage.totalElementsCount,
              onChange: onPageChange
            }}
            loading={inProgress}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default OutputCalculatedCommissionTableView;
