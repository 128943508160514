import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { useSelector } from "react-redux";
import { generatePath, Link } from "react-router-dom";
import useMeasure from "react-use-measure";
import t from "../../../../../app/i18n";
import InfoIcon from "../../../../../common/components/icons/InfoIcon";
import StatusTag from "../../../../../common/components/tags/StatusTag";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../../common/constants";
import { Permission } from "../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../common/types";
import {
  formatLocaleCurrency,
  formatLocaleCurrencyWithNullAsZero,
  formatLocaleDate
} from "../../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../common/utils/utils";
import { selectHasAnyPermissions, selectHasPermissions } from "../../../../auth/ducks";
import { CONTRACT_ROUTE_PATHS } from "../../../../contract/paths";
import { createDuplicatedCommissionActions, deleteDuplicatedCommissionActions } from "../../../postponed/ducks";
import { PostponedCommissionsReport } from "../../../postponed/enums";
import { PostponedCommissionsFilterPageResult } from "../../../postponed/types";
import {
  deleteCommissionActions,
  recalculateCommissionsForContractActions,
  tryToIncludeCommissionActions
} from "../../ducks";
import { CommissionsBatchStep, CommissionType } from "../../enums";
import { COMMISSIONS_BATCH_ROUTE_PATHS } from "../../paths";
import { Commission, CommissionsBatchBase, CommissionsFilterPageResult } from "../../types";
import CommissionKindTag from "../CommissionKindTag";
import PostponementReasonTag from "../PostponementReasonTag";
import BatchCalculatedCommissionActionsView from "./actions/BatchCalculatedCommissionActionsView";
import BatchCommissionActionsView from "./actions/BatchCommissionActionsView";
import BatchPostponedCommissionActionsView from "./actions/BatchPostponedCommissionActionsView";
import BatchCommissionExtendedTableView from "./BatchCommissionExtendedTableView";

interface Props {
  commissionsPage: CommissionsFilterPageResult | PostponedCommissionsFilterPageResult;
  batch: CommissionsBatchBase;
  viewType?: "default" | "postponed" | "calculated";
  onPageChange: (pageNumber: number) => void;
  onUpdateClick?: (commission: Commission) => void;
  onPostponeClick?: (commission: Commission) => void;
  onTryToInclude?: typeof tryToIncludeCommissionActions.request;
  onRecalculateForContract?: typeof recalculateCommissionsForContractActions.request;
  onCalculationsUpdateClick?: (commission: Commission) => void;
  onDelete?: typeof deleteCommissionActions.request;
  onCreateDuplicate?: typeof createDuplicatedCommissionActions.request;
  onDeleteDuplicate?: typeof deleteDuplicatedCommissionActions.request;
}

const BatchCommissionsTableView = ({ commissionsPage, batch, viewType = "default", ...props }: Props) => {
  const [measuredTableRef, tableMeasures] = useMeasure();

  const hasManageCommissionsPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.COMMISSIONS_MANAGE)(state)
  );

  const hasReadContractPermission = useSelector<RootState, boolean>(state =>
    selectHasAnyPermissions(
      Permission.INSURANCE_READ,
      Permission.LOAN_READ,
      Permission.INVESTMENT_READ,
      Permission.DEPOSIT_READ,
      Permission.SECOND_PILLAR_READ,
      Permission.THIRD_PILLAR_READ,
      Permission.GENERIC_READ
    )(state)
  );

  const columns: ColumnsType<Commission> = [
    {
      key: "duplicatedFromBatch",
      width: 35,
      render: (_, record) =>
        (viewType === "postponed" || viewType === "calculated") &&
        record.type === CommissionType.DUPLICATED_COMMISSION ? (
          <InfoIcon
            tooltip={
              <>
                <span>{t("commissions.postponed.helpers.createdInBatch")}: </span>
                <Link
                  to={generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.detail.to, { id: record.duplicateOf.batch.id })}
                  target="_blank"
                >
                  {record.duplicateOf.batch.name}
                </Link>
              </>
            }
          />
        ) : (
          viewType === "postponed" && (
            <InfoIcon
              tooltip={
                <>
                  <span>{t("commissions.postponed.helpers.createdInBatch")}: </span>
                  <Link
                    to={generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.detail.to, { id: record.batch.id })}
                    target="_blank"
                  >
                    {record.batch.name}
                  </Link>
                </>
              }
            />
          )
        )
    },
    {
      key: "institution",
      title: t("commissions.batch.attrs.commission.institution"),
      width: 120,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.institution.name}</Ellipsis>
    },
    {
      key: "contract",
      title: t("commissions.batch.attrs.commission.contract"),
      width: 120,
      ellipsis: { showTitle: false },
      render: (_, record) =>
        record.contract && hasReadContractPermission ? (
          <>
            {record.contractNumber && (
              <>
                <Ellipsis tooltip={record.contractNumber}>
                  <Link to={generatePath(CONTRACT_ROUTE_PATHS.detail.to, { id: record.contract.id })} target="_blank">
                    {record.contractNumber}
                  </Link>
                </Ellipsis>
                <br />
              </>
            )}
            {record.secondaryContractNumber && (
              <Ellipsis tooltip={record.secondaryContractNumber}>
                <Link to={generatePath(CONTRACT_ROUTE_PATHS.detail.to, { id: record.contract.id })} target="_blank">
                  {record.secondaryContractNumber}
                </Link>
              </Ellipsis>
            )}
          </>
        ) : (
          <>
            {record.contractNumber && (
              <>
                <Ellipsis>{record.contractNumber}</Ellipsis>
                <br />
              </>
            )}
            {record.secondaryContractNumber && <Ellipsis>{record.secondaryContractNumber}</Ellipsis>}
          </>
        )
    },
    {
      key: "product",
      title: t("commissions.batch.attrs.commission.product"),
      width: 145,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.contract?.product.name}</Ellipsis>
    },
    {
      key: "tariffCode",
      title: t("commissions.batch.attrs.commission.tariffCode"),
      width: 90,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.tariffCode}</Ellipsis>
    },
    {
      key: "clientName",
      title: t("commissions.batch.attrs.commission.clientName"),
      width: 100,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.clientName}</Ellipsis>
    },
    {
      key: "dates",
      title: t("commissions.batch.attrs.commission.dates"),
      width: 105,
      render: (_, record) => (
        <>
          {formatLocaleDate(record.startDate)}
          <br />
          {formatLocaleDate(record.endDate)}
        </>
      )
    },
    {
      key: "commissionKind",
      title: t("commissions.batch.enums.commissionKind._label"),
      width: 105,
      render: (_, record) => <CommissionKindTag kind={record.commissionKind} />
    },
    {
      key: "commissionBaseAmount",
      title: t("commissions.batch.attrs.commission.commissionBaseAmount"),
      align: "right",
      width: 127,
      render: (_, record) => formatLocaleCurrency(record.commissionBaseAmount)
    },
    {
      key: "commissionAmount",
      title: t("commissions.batch.attrs.commission.commissionAmount"),
      align: "right",
      width: 110,
      render: (_, record) => formatLocaleCurrency(record.commissionAmount)
    },
    {
      key: "postponementReason",
      title:
        viewType === "postponed"
          ? t("commissions.postponed.helpers.originalPostponementReason")
          : t("commissions.batch.enums.postponementReason._label"),
      align: "center",
      width: 165,
      render: (_, record) =>
        viewType === "postponed" && record.type === CommissionType.DUPLICATED_COMMISSION ? (
          <PostponementReasonTag
            reason={record.duplicateOf.postponementReason}
            manualPostponementReason={record.duplicateOf.manualPostponementReason}
          />
        ) : (
          <PostponementReasonTag
            reason={record.postponementReason}
            manualPostponementReason={record.manualPostponementReason}
          />
        )
    }
  ];

  switch (viewType) {
    case "default":
      if (batch.step !== CommissionsBatchStep.FINISH && hasManageCommissionsPermission) {
        columns.push({
          key: "actions",
          align: "right",
          fixed: "right",
          width: tableMeasures.width < 1545 ? 95 : 270,
          render: (_, record) => {
            return (
              <BatchCommissionActionsView
                record={record}
                batch={batch}
                onUpdateClick={props.onUpdateClick}
                onPostponeClick={props.onPostponeClick}
                onTryToInclude={props.onTryToInclude}
                onDelete={props.onDelete}
              />
            );
          }
        });
      }
      break;
    case "postponed":
      if (batch.step !== CommissionsBatchStep.FINISH && hasManageCommissionsPermission) {
        columns.push({
          key: "actions",
          align: "right",
          fixed: "right",
          width:
            (commissionsPage as PostponedCommissionsFilterPageResult).report ===
            PostponedCommissionsReport.UNRESOLVED_INCLUDED_IN_BATCH
              ? 180
              : 95,
          render: (_, record) => {
            return (
              <BatchPostponedCommissionActionsView
                record={record}
                batch={batch}
                report={(commissionsPage as PostponedCommissionsFilterPageResult).report}
                onCreateDuplicate={props.onCreateDuplicate}
                onUpdateClick={props.onUpdateClick}
                onDeleteDuplicate={props.onDeleteDuplicate}
              />
            );
          }
        });
      }
      break;
    case "calculated":
      columns.push({
        key: "statusTag",
        align: "center",
        width: 95,
        render: (_, record) =>
          (!!record.errors.length || record.calculatedCommissions.some(c => !!c.errors.length)) && (
            <StatusTag status="error" tooltip={t("commissions.batch.helpers.calculationError")} />
          )
      });
      if (batch.step !== CommissionsBatchStep.FINISH && hasManageCommissionsPermission) {
        columns.push({
          key: "actions",
          align: "right",
          fixed: "right",
          width: tableMeasures.width < 1780 ? 135 : 370,
          render: (_, record) => {
            return (
              <BatchCalculatedCommissionActionsView
                record={record}
                batch={batch}
                onUpdateClick={props.onUpdateClick}
                onPostponeClick={props.onPostponeClick}
                onTryToInclude={props.onTryToInclude}
                onRecalculateForContract={props.onRecalculateForContract}
                onDelete={props.onDelete}
              />
            );
          }
        });
      }
      break;
  }

  return (
    <>
      <div className={commissionsPage.totalElementsCount > 0 ? "table-header-margin" : "margin-bottom-small"}>
        <b>{t("commissions.batch.helpers.filteredCommissionsSum")}: </b>
        {formatLocaleCurrencyWithNullAsZero(commissionsPage.commissionAmountsSum)}
      </div>
      <Table<Commission>
        {...tableStandardProps()}
        ref={measuredTableRef}
        columns={columns}
        scroll={{ x: TableSizes.HUGE }}
        dataSource={commissionsPage.pageData}
        expandable={{
          rowExpandable: record =>
            ((commissionsPage as PostponedCommissionsFilterPageResult).report !==
              PostponedCommissionsReport.UNRESOLVED_INCLUDED_IN_BATCH &&
              !!record.errors.length) ||
            (viewType === "calculated" && !!record.calculatedCommissions.length),
          expandedRowRender: record => (
            <BatchCommissionExtendedTableView
              commission={record}
              showCalculations={viewType === "calculated"}
              onCalculationsUpdateClick={props.onCalculationsUpdateClick}
            />
          )
        }}
        pagination={{
          ...paginationTableProps,
          current: commissionsPage.pageIndex + 1,
          pageSize: commissionsPage.pageSize,
          total: commissionsPage.totalElementsCount,
          onChange: props.onPageChange
        }}
      />
    </>
  );
};

export default BatchCommissionsTableView;
