import { Card, Popconfirm, Tooltip } from "antd";
import React from "react";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../common/components/icons/PopconfirmDeleteIcon";
import { createLinkHref, openUrl } from "../../../../common/utils/utils";
import { getPartnerSsoActions } from "../../ducks";
import { PartnerSsoType } from "../../enums";
import { PartnerConfig } from "../../types";

interface Props {
  configs: PartnerConfig[];
  hasAdminPartnerConfigsPermission: boolean;
  logoProvider: () => string;
  onPartnerSsoGet: typeof getPartnerSsoActions.request;
  onLogoutClick: (configs: PartnerConfig[]) => void;
  onUpdateClick: (configs: PartnerConfig[]) => void;
  onDeleteClick: (configs: PartnerConfig[]) => void;
}

const CONFIG_CARD_HEIGHT = 210;

const PartnerConfigsCardView = ({ configs, hasAdminPartnerConfigsPermission, logoProvider, ...actions }: Props) => {
  const handlePartnerConfigClick = (config: PartnerConfig): void => {
    switch (config.ssoType) {
      case PartnerSsoType.MONLY:
      case PartnerSsoType.METLIFE:
        actions.onPartnerSsoGet({ ssoType: config.ssoType });
        break;
      default:
        openUrl(createLinkHref(config.link), "_blank");
        break;
    }
  };

  return (
    <Card
      title={configs[0].institution?.name || configs[0].label}
      hoverable
      size="small"
      style={{ marginBottom: 20, cursor: "default" }}
      extra={
        <>
          {configs.find(config => config.ssoType) && (
            <Popconfirm
              title={t("partnerSso.helpers.logoutConfirm")}
              icon={<PopconfirmDeleteIcon />}
              cancelText={t("common.no")}
              okText={t("common.yes")}
              okType="danger"
              onConfirm={() => actions.onLogoutClick(configs)}
            >
              <Tooltip title={t("common.logout")} placement="bottom">
                <span>
                  <ActionTextIcon icon="logout" color="orange" />
                </span>
              </Tooltip>
            </Popconfirm>
          )}
          {hasAdminPartnerConfigsPermission && (
            <>
              <Tooltip title={t("common.edit")} placement="bottom">
                <span>
                  <ActionTextIcon icon="edit" color="blue" onClick={() => actions.onUpdateClick(configs)} />
                </span>
              </Tooltip>

              <Popconfirm
                title={t("partnerConfig.helpers.deleteConfirm")}
                icon={<PopconfirmDeleteIcon />}
                cancelText={t("common.no")}
                okText={t("common.yes")}
                okType="danger"
                onConfirm={() => actions.onDeleteClick(configs)}
              >
                <Tooltip title={t("common.delete")} placement="bottom">
                  <span>
                    <ActionTextIcon icon="delete" color="red" />
                  </span>
                </Tooltip>
              </Popconfirm>
            </>
          )}
        </>
      }
    >
      <div className="partner-wrapper">
        <div className="partner-logo-container">
          <img src={logoProvider()} alt="partner-logo" />
        </div>
        <div className="partner-links-container">
          {configs.map(config => (
            <div
              className="partner-link"
              key={config.id}
              style={{ height: CONFIG_CARD_HEIGHT / configs.length }}
              onClick={() => handlePartnerConfigClick(config)}
            >
              <span>{config.label}</span>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default PartnerConfigsCardView;
