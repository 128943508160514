import { Card, Cascader, Col, Divider, Form, Input, InputNumber, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { DefaultOptionType } from "rc-select/lib/Select";
import React from "react";
import t from "../../../../../../../app/i18n";
import LabelWithTooltip from "../../../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../../../common/constants";
import AddressForm from "../../../../../../../common/modules/address/AddressForm";
import { licensePlateNormalizeFunction, selectStandardProps } from "../../../../../../../common/utils/formUtils";
import { regexPatterns, validations } from "../../../../../../../common/utils/validationUtils";
import { ClientType } from "../../../../../../client/enums";
import { InstitutionEnum } from "../../../../../../institution/enums";
import { ClientExperience } from "../../../../../enums";
import { CalcResult } from "../../../../types";
import { filterApplicableSuccessResults } from "../../../../utils";
import {
  TravelAbandonedHouseholdType,
  TravelInsuranceType,
  TravelPetType,
  TravelVehicleAssistanceType
} from "../../../enums";
import { TravelCalc, TravelCalcResultData, TravelGenForm } from "../../../types";

interface Props {
  form: FormInstance<TravelGenForm>;
  policyHolderType: ClientType;
  calcData: TravelCalc;
  calcResults: CalcResult<TravelCalcResultData>[][];
  selectedInstitutionEnum: InstitutionEnum;
}

const TravelGenOtherDataSection = ({
  form,
  policyHolderType,
  calcData,
  calcResults,
  selectedInstitutionEnum
}: Props) => {
  const colSpan = 4;
  const bigColSpan = 8;

  const policyHolderIsCompany = policyHolderType === ClientType.SELF_EMPLOYED || policyHolderType === ClientType.LEGAL;

  return (
    <Card type="inner" className="card-box" title={t("calc.travel.sections.otherData")}>
      {calcData.generalData.insuranceType === TravelInsuranceType.SHORT_TERM && (
        <>
          {calcData.coveragesData?.abandonedHousehold &&
            (selectedInstitutionEnum === InstitutionEnum.GENERALI ||
              selectedInstitutionEnum === InstitutionEnum.UNION) && (
              <>
                <Divider className="divider-subheader">
                  {t("calc.travel.attrs.coveragesData.abandonedHouseholdData._label")}
                </Divider>

                <Row gutter={rowGutter}>
                  <Col span={colSpan}>
                    <Form.Item
                      name={["coveragesData", "abandonedHouseholdData", "type"]}
                      label={t("calc.travel.enums.abandonedHouseholdType._label")}
                      rules={[validations.notNull]}
                    >
                      <Select
                        {...selectStandardProps}
                        options={Object.keys(TravelAbandonedHouseholdType).map(type => ({
                          value: type,
                          label: t("calc.travel.enums.abandonedHouseholdType." + type)
                        }))}
                      />
                    </Form.Item>
                  </Col>

                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, next) =>
                      prev.coveragesData?.abandonedHouseholdData?.type !==
                      next.coveragesData?.abandonedHouseholdData?.type
                    }
                  >
                    {({ getFieldValue }) =>
                      getFieldValue(["coveragesData", "abandonedHouseholdData", "type"]) ===
                        TravelAbandonedHouseholdType.FLAT && (
                        <>
                          <Col span={colSpan}>
                            <Form.Item
                              name={["coveragesData", "abandonedHouseholdData", "apartmentNumber"]}
                              label={t("calc.travel.attrs.coveragesData.abandonedHouseholdData.apartmentNumber")}
                              rules={[validations.notBlank, validations.size(1, 64)]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col span={colSpan}>
                            <Form.Item
                              name={["coveragesData", "abandonedHouseholdData", "apartmentFloor"]}
                              label={t("calc.travel.attrs.coveragesData.abandonedHouseholdData.apartmentFloor")}
                              rules={[validations.notNull, validations.minNumber(0), validations.maxNumber(40)]}
                            >
                              <InputNumber />
                            </Form.Item>
                          </Col>
                        </>
                      )
                    }
                  </Form.Item>
                </Row>

                <AddressForm
                  form={form}
                  rootNamePath={["coveragesData", "abandonedHouseholdData", "address"]}
                  label={t("calc.travel.attrs.coveragesData.abandonedHouseholdData.address._label")}
                  required
                />
              </>
            )}

          {calcData.coveragesData?.vehicleAssistance &&
            (selectedInstitutionEnum === InstitutionEnum.GENERALI ||
              selectedInstitutionEnum === InstitutionEnum.UNION) && (
              <>
                <Divider className="divider-subheader">
                  {t("calc.travel.attrs.coveragesData.vehicleAssistanceData._label")}
                </Divider>

                <Row gutter={rowGutter}>
                  <Col span={colSpan}>
                    <Form.Item
                      name={["coveragesData", "vehicleAssistanceData", "licensePlate"]}
                      label={t("calc.travel.attrs.coveragesData.vehicleAssistanceData.licensePlate")}
                      rules={[validations.notBlank, validations.fullLicensePlate]}
                      normalize={licensePlateNormalizeFunction}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  {selectedInstitutionEnum === InstitutionEnum.UNION && (
                    <Col span={colSpan}>
                      <Form.Item
                        name={["coveragesData", "vehicleAssistanceData", "type"]}
                        label={t("calc.travel.enums.vehicleAssistanceType._label")}
                        rules={[validations.notNull]}
                      >
                        <Select
                          {...selectStandardProps}
                          options={Object.keys(TravelVehicleAssistanceType).map(type => ({
                            value: type,
                            label: t("calc.travel.enums.vehicleAssistanceType." + type)
                          }))}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </>
            )}

          {calcData.coveragesData?.pet && selectedInstitutionEnum === InstitutionEnum.UNION && (
            <>
              <Divider className="divider-subheader">{t("calc.travel.attrs.coveragesData.petData._label")}</Divider>

              <Row gutter={rowGutter}>
                <Col span={colSpan}>
                  <Form.Item
                    name={["coveragesData", "petData", "type"]}
                    label={t("calc.travel.enums.petType._label")}
                    rules={[validations.notNull]}
                  >
                    <Select
                      {...selectStandardProps}
                      options={Object.keys(TravelPetType).map(type => ({
                        value: type,
                        label: t("calc.travel.enums.petType." + type)
                      }))}
                    />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={["coveragesData", "petData", "vaccinationIdentifier"]}
                    label={
                      <LabelWithTooltip
                        label={t("calc.travel.attrs.coveragesData.petData.vaccinationIdentifier")}
                        tooltip={t("calc.travel.helpers.vaccinationIdentifierDesc")}
                      />
                    }
                    rules={[
                      validations.notBlank,
                      validations.length(11),
                      validations.pattern(regexPatterns.vaccinationIdentifierRegex)
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </>
      )}

      <Divider className="divider-subheader">{t("calc.travel.sections.financialMediationData")}</Divider>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={["financialMediationData", "clientExperience"]}
            label={t("calc.enums.clientExperience._label")}
            rules={[validations.notNull]}
            initialValue={policyHolderIsCompany ? ClientExperience.SUFFICIENT : undefined}
          >
            <Select
              {...selectStandardProps}
              disabled={policyHolderIsCompany}
              options={(policyHolderIsCompany
                ? [ClientExperience.SUFFICIENT]
                : [ClientExperience.NONE, ClientExperience.MINIMAL]
              ).map(experience => ({
                value: experience,
                label: t("calc.enums.clientExperience." + experience)
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={bigColSpan}>
          <Form.Item
            name={["financialMediationData", "otherClientRequirements"]}
            label={t("calc.travel.attrs.financialMediationData.otherClientRequirements")}
            rules={[validations.none]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={bigColSpan}>
          <Form.Item
            name={["financialMediationData", "recommendedResult"]}
            label={t("calc.travel.attrs.financialMediationData.recommendedResult")}
            rules={[validations.notNull]}
          >
            <Cascader
              allowClear={false}
              expandTrigger="hover"
              options={calcResults
                .filter(resultsRow => filterApplicableSuccessResults(resultsRow).length > 0)
                .map<DefaultOptionType>(resultsRow => {
                  const results = filterApplicableSuccessResults<TravelCalcResultData>(resultsRow);
                  return {
                    label: results[0].insuranceInstitution.name,
                    value: results[0].insuranceInstitution.institutionEnum,
                    children: results[0].coverage
                      ? results.map<DefaultOptionType>(result => ({
                          label: `${t("calc.travel.sections.coverage")} ${result.coverage}`,
                          value: result.coverage
                        }))
                      : undefined
                  };
                })}
            />
          </Form.Item>
        </Col>

        <Col span={bigColSpan}>
          <Form.Item
            name={["financialMediationData", "recommendationReason"]}
            label={t("calc.travel.attrs.financialMediationData.recommendationReason")}
            rules={[validations.none]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={bigColSpan}>
          <Form.Item
            name={["financialMediationData", "additionalClientStatement"]}
            label={t("calc.travel.attrs.financialMediationData.additionalClientStatement")}
            rules={[validations.none]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={colSpan * 2}>
          <Form.Item
            name={["financialMediationData", "additionalSuitabilityStatement"]}
            label={
              <LabelWithTooltip
                label={t("calc.travel.attrs.financialMediationData.additionalSuitabilityStatement")}
                tooltip={t("calc.travel.helpers.additionalSuitabilityStatementDesc")}
              />
            }
            rules={[validations.none]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default TravelGenOtherDataSection;
