import { AxiosResponse } from "axios";
import { Pathname } from "history";
import { combineReducers } from "redux";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { PageSizes } from "../../common/constants";
import { EntityIdObject, NormalizedError, RootState } from "../../common/types";
import { initSearchPageResult } from "../../common/utils/apiUtils";
import messageUtils from "../../common/utils/messageUtils";
import { replaceInArray } from "../../common/utils/utils";
import { selectIsUserAuthenticated } from "../auth/ducks";
import { selectRouterLocationPathname } from "../ducks";
import api from "./api";
import { NotificationStatus, NotificationTopic } from "./enums";
import { NOTIFICATION_ROUTE_PATHS } from "./paths";
import {
  MarkNotificationsAsSeenRequest,
  Notification,
  NotificationFilterPageRequest,
  NotificationFilterPageResult,
  NotificationReducerState,
  NotificationSettings,
  NotificationsHeaderList,
  RefreshNotificationsPageAction,
  UpdateNotificationSettings
} from "./types";

/**
 * ACTIONS - NOTIFICATION SETTINGS
 */
export const getNotificationSettingsActions = createAsyncAction(
  "notification-settings/GET_REQUEST",
  "notification-settings/GET_SUCCESS",
  "notification-settings/GET_FAILURE"
)<void, NotificationSettings[], NormalizedError>();

export const updateNotificationSettingsActions = createAsyncAction(
  "notification-settings/UPDATE_REQUEST",
  "notification-settings/UPDATE_SUCCESS",
  "notification-settings/UPDATE_FAILURE"
)<UpdateNotificationSettings[], NotificationSettings[], NormalizedError>();

export const deleteStateNotificationSettingsListAction = createAction(
  "notification-settings/DELETE_STATE_LIST"
)<void>();

/**
 * ACTIONS - HEADER NOTIFICATIONS
 */
export const filterHeaderNotificationsActions = createAsyncAction(
  "notification-header/FILTER_REQUEST",
  "notification-header/FILTER_SUCCESS",
  "notification-header/FILTER_FAILURE"
)<NotificationFilterPageRequest, NotificationFilterPageResult, NormalizedError>();

export const markAllHeaderNotificationsAsSeenActions = createAsyncAction(
  "notification-header/MARK_ALL_AS_SEEN_REQUEST",
  "notification-header/MARK_ALL_AS_SEEN_SUCCESS",
  "notification-header/MARK_ALL_AS_SEEN_FAILURE"
)<void, void, NormalizedError>();

export const markHeaderNotificationsAsSeenActions = createAsyncAction(
  "notification-header/MARK_AS_SEEN_REQUEST",
  "notification-header/MARK_AS_SEEN_SUCCESS",
  "notification-header/MARK_AS_SEEN_FAILURE"
)<MarkNotificationsAsSeenRequest, Notification[], NormalizedError>();

export const toggleHeaderNotificationSentAndSeenStatusActions = createAsyncAction(
  "notification-header/TOGGLE_SENT_AND_SEEN_STATUS_REQUEST",
  "notification-header/TOGGLE_SENT_AND_SEEN_STATUS_SUCCESS",
  "notification-header/TOGGLE_SENT_AND_SEEN_STATUS_FAILURE"
)<EntityIdObject, Notification, NormalizedError>();

export const refreshHeaderNotificationsListAction = createAction(
  "notification-header/REFRESH_LIST"
)<RefreshNotificationsPageAction>();

export const deleteStateHeaderNotificationsListAction = createAction("notification-header/DELETE_STATE_LIST")<void>();

/**
 * ACTIONS - PAGE NOTIFICATIONS
 */
export const filterPageNotificationsActions = createAsyncAction(
  "notification-page/FILTER_REQUEST",
  "notification-page/FILTER_SUCCESS",
  "notification-page/FILTER_FAILURE"
)<NotificationFilterPageRequest, NotificationFilterPageResult, NormalizedError>();

export const markAllPageNotificationsAsSeenActions = createAsyncAction(
  "notification-page/MARK_ALL_AS_SEEN_REQUEST",
  "notification-page/MARK_ALL_AS_SEEN_SUCCESS",
  "notification-page/MARK_ALL_AS_SEEN_FAILURE"
)<void, void, NormalizedError>();

export const markPageNotificationsAsSeenActions = createAsyncAction(
  "notification-page/MARK_AS_SEEN_REQUEST",
  "notification-page/MARK_AS_SEEN_SUCCESS",
  "notification-page/MARK_AS_SEEN_FAILURE"
)<MarkNotificationsAsSeenRequest, Notification[], NormalizedError>();

export const togglePageNotificationSentAndSeenStatusActions = createAsyncAction(
  "notification-page/TOGGLE_SENT_AND_SEEN_STATUS_REQUEST",
  "notification-page/TOGGLE_SENT_AND_SEEN_STATUS_SUCCESS",
  "notification-page/TOGGLE_SENT_AND_SEEN_STATUS_FAILURE"
)<EntityIdObject, Notification, NormalizedError>();

export const refreshPageNotificationsListAction = createAction(
  "notification-page/REFRESH_LIST"
)<RefreshNotificationsPageAction>();

export const deleteStatePageNotificationsListAction = createAction("notification-page/DELETE_STATE_LIST")<void>();

const actions = {
  getNotificationSettingsActions,
  updateNotificationSettingsActions,
  deleteStateNotificationSettingsListAction,
  filterHeaderNotificationsActions,
  markAllHeaderNotificationsAsSeenActions,
  markHeaderNotificationsAsSeenActions,
  toggleHeaderNotificationSentAndSeenStatusActions,
  refreshHeaderNotificationsListAction,
  deleteStateHeaderNotificationsListAction,
  filterPageNotificationsActions,
  markAllPageNotificationsAsSeenActions,
  markPageNotificationsAsSeenActions,
  togglePageNotificationSentAndSeenStatusActions,
  refreshPageNotificationsListAction,
  deleteStatePageNotificationsListAction
};

export type NotificationAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: NotificationReducerState = {
  settings: [],
  headerList: {
    items: [],
    totalElementsCount: 0,
    totalUnseenCount: 0,
    basicPageSize: PageSizes.LARGE
  },
  pageList: {
    ...initSearchPageResult<Notification>(),
    onlyUnseen: false,
    topics: [],
    sentAtMin: null,
    sentAtMax: null,
    totalUnseenCount: 0
  }
};

const settingsReducer = createReducer(initialState.settings)
  .handleAction(
    [getNotificationSettingsActions.success, updateNotificationSettingsActions.success],
    (state, { payload }) =>
      payload.sort(
        (a, b) => Object.keys(NotificationTopic).indexOf(a.topic) - Object.keys(NotificationTopic).indexOf(b.topic)
      )
  )
  .handleAction(
    [getNotificationSettingsActions.failure, deleteStateNotificationSettingsListAction],
    () => initialState.settings
  );

const headerListReducer = createReducer(initialState.headerList)
  .handleAction(filterHeaderNotificationsActions.success, (state, { payload }) => ({
    items: payload.deletePreviousItems ? payload.pageData : [...state.items, ...payload.pageData],
    totalElementsCount: payload.totalElementsCount,
    totalUnseenCount: payload.totalUnseenCount,
    basicPageSize: payload.pageSize
  }))
  .handleAction(
    [toggleHeaderNotificationSentAndSeenStatusActions.success, togglePageNotificationSentAndSeenStatusActions.success],
    (state, { payload }) => ({
      ...state,
      totalUnseenCount:
        payload.status === NotificationStatus.SEEN ? state.totalUnseenCount - 1 : state.totalUnseenCount + 1,
      items: replaceInArray(
        state.items,
        item => item.id === payload.id,
        () => payload
      )
    })
  )
  .handleAction(
    [filterHeaderNotificationsActions.failure, deleteStateHeaderNotificationsListAction],
    () => initialState.headerList
  );

const pageListReducer = createReducer(initialState.pageList)
  .handleAction(filterPageNotificationsActions.success, (_, { payload }) => payload)
  .handleAction(
    [togglePageNotificationSentAndSeenStatusActions.success, toggleHeaderNotificationSentAndSeenStatusActions.success],
    (state, { payload }) =>
      state.pageData.length > 0
        ? {
            ...state,
            totalUnseenCount:
              payload.status === NotificationStatus.SEEN ? state.totalUnseenCount - 1 : state.totalUnseenCount + 1,
            pageData: replaceInArray(
              state.pageData,
              item => item.id === payload.id,
              () => payload
            )
          }
        : state
  )
  .handleAction(
    [filterPageNotificationsActions.failure, deleteStatePageNotificationsListAction],
    () => initialState.pageList
  );

export const notificationReducer = combineReducers<NotificationReducerState>({
  settings: settingsReducer,
  headerList: headerListReducer,
  pageList: pageListReducer
});

/**
 * SELECTORS
 */
const selectNotification = (state: RootState): NotificationReducerState => state.notification;

export const selectNotificationSettings = (state: RootState): NotificationSettings[] =>
  selectNotification(state).settings;
export const selectNotificationsHeaderList = (state: RootState): NotificationsHeaderList =>
  selectNotification(state).headerList;
export const selectNotificationsPageList = (state: RootState): NotificationFilterPageResult =>
  selectNotification(state).pageList;

/**
 * SAGAS - NOTIFICATION SETTINGS
 */
function* getNotificationSettings() {
  try {
    const response: AxiosResponse<NotificationSettings[]> = yield call(api.getNotificationSettings);
    yield put(getNotificationSettingsActions.success(response.data));
  } catch (error) {
    yield put(getNotificationSettingsActions.failure(error));
  }
}

function* updateNotificationSettings({ payload }: ReturnType<typeof updateNotificationSettingsActions.request>) {
  try {
    const response: AxiosResponse<NotificationSettings[]> = yield call(api.updateNotificationSettings, payload);
    yield put(updateNotificationSettingsActions.success(response.data));
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(updateNotificationSettingsActions.failure(error));
  }
}

/**
 * SAGAS - HEADER NOTIFICATIONS
 */
function* filterHeaderNotifications({ payload }: ReturnType<typeof filterHeaderNotificationsActions.request>) {
  try {
    const response: AxiosResponse<NotificationFilterPageResult> = yield call(api.filterNotifications, payload);
    yield put(
      filterHeaderNotificationsActions.success({ ...response.data, deletePreviousItems: payload.deletePreviousItems })
    );
    yield put(refreshHeaderNotificationsListAction({ useDelay: true }));
  } catch (error) {
    yield put(filterHeaderNotificationsActions.failure(error));
  }
}

function* markAllHeaderNotificationsAsSeen() {
  try {
    yield call(api.markAllNotificationsAsSeen);
    yield put(markAllHeaderNotificationsAsSeenActions.success());
    yield put(refreshHeaderNotificationsListAction({ useDelay: false }));
    yield put(refreshPageNotificationsListAction({ useDelay: false }));
  } catch (error) {
    yield put(markAllHeaderNotificationsAsSeenActions.failure(error));
  }
}

function* markHeaderNotificationsAsSeen({ payload }: ReturnType<typeof markHeaderNotificationsAsSeenActions.request>) {
  try {
    const response: AxiosResponse<Notification[]> = yield call(api.markNotificationsAsSeen, payload);
    yield put(markHeaderNotificationsAsSeenActions.success(response.data));
  } catch (error) {
    yield put(markHeaderNotificationsAsSeenActions.failure(error));
  }
}

function* toggleHeaderNotificationSentAndSeenStatus({
  payload
}: ReturnType<typeof toggleHeaderNotificationSentAndSeenStatusActions.request>) {
  try {
    const response: AxiosResponse<Notification> = yield call(api.toggleNotificationSentAndSeenStatus, payload);
    yield put(toggleHeaderNotificationSentAndSeenStatusActions.success(response.data));
  } catch (error) {
    yield put(toggleHeaderNotificationSentAndSeenStatusActions.failure(error));
  }
}

function* refreshHeaderNotificationsList({ payload }: ReturnType<typeof refreshHeaderNotificationsListAction>) {
  if (payload.useDelay) {
    yield delay(90_000);
  }

  if (yield select(selectIsUserAuthenticated)) {
    const list: NotificationsHeaderList = yield select(selectNotificationsHeaderList);
    yield put(
      filterHeaderNotificationsActions.request({
        deletePreviousItems: true,
        pageIndex: 0,
        pageSize: Math.max(list.items.length, list.basicPageSize)
      })
    );
  }
}

/**
 * SAGAS - PAGE NOTIFICATIONS
 */
function* filterPageNotifications({ payload }: ReturnType<typeof filterPageNotificationsActions.request>) {
  try {
    const response: AxiosResponse<NotificationFilterPageResult> = yield call(api.filterNotifications, payload);
    yield put(filterPageNotificationsActions.success(response.data));
    yield put(refreshPageNotificationsListAction({ useDelay: true }));
  } catch (error) {
    yield put(filterPageNotificationsActions.failure(error));
  }
}

function* markAllPageNotificationsAsSeen() {
  try {
    yield call(api.markAllNotificationsAsSeen);
    yield put(markAllPageNotificationsAsSeenActions.success());
    yield put(refreshHeaderNotificationsListAction({ useDelay: false }));
    yield put(refreshPageNotificationsListAction({ useDelay: false }));
  } catch (error) {
    yield put(markAllPageNotificationsAsSeenActions.failure(error));
  }
}

function* markPageNotificationsAsSeen({ payload }: ReturnType<typeof markPageNotificationsAsSeenActions.request>) {
  try {
    const response: AxiosResponse<Notification[]> = yield call(api.markNotificationsAsSeen, payload);
    yield put(markPageNotificationsAsSeenActions.success(response.data));
  } catch (error) {
    yield put(markPageNotificationsAsSeenActions.failure(error));
  }
}

function* togglePageNotificationSentAndSeenStatus({
  payload
}: ReturnType<typeof togglePageNotificationSentAndSeenStatusActions.request>) {
  try {
    const response: AxiosResponse<Notification> = yield call(api.toggleNotificationSentAndSeenStatus, payload);
    yield put(togglePageNotificationSentAndSeenStatusActions.success(response.data));
  } catch (error) {
    yield put(togglePageNotificationSentAndSeenStatusActions.failure(error));
  }
}

function* refreshPageNotificationsList({ payload }: ReturnType<typeof refreshPageNotificationsListAction>) {
  if (payload.useDelay) {
    yield delay(90_000);
  }

  const userIsAuthenticated = yield select(selectIsUserAuthenticated);
  const location: Pathname = yield select(selectRouterLocationPathname);

  if (userIsAuthenticated && location.endsWith(NOTIFICATION_ROUTE_PATHS.list.to)) {
    const { keyword, onlyUnseen, topics, sentAtMin, sentAtMax, pageIndex, pageSize }: NotificationFilterPageResult =
      yield select(selectNotificationsPageList);

    yield put(
      filterPageNotificationsActions.request({
        keyword,
        onlyUnseen,
        topics,
        sentAtMin,
        sentAtMax,
        pageIndex,
        pageSize
      })
    );
  }
}

export function* notificationSaga() {
  yield takeLatest(getNotificationSettingsActions.request, getNotificationSettings);
  yield takeLatest(updateNotificationSettingsActions.request, updateNotificationSettings);

  yield takeLatest(filterHeaderNotificationsActions.request, filterHeaderNotifications);
  yield takeLatest(markAllHeaderNotificationsAsSeenActions.request, markAllHeaderNotificationsAsSeen);
  yield takeLatest(markHeaderNotificationsAsSeenActions.request, markHeaderNotificationsAsSeen);
  yield takeLatest(toggleHeaderNotificationSentAndSeenStatusActions.request, toggleHeaderNotificationSentAndSeenStatus);
  yield takeLatest(refreshHeaderNotificationsListAction, refreshHeaderNotificationsList);

  yield takeLatest(filterPageNotificationsActions.request, filterPageNotifications);
  yield takeLatest(markAllPageNotificationsAsSeenActions.request, markAllPageNotificationsAsSeen);
  yield takeLatest(markPageNotificationsAsSeenActions.request, markPageNotificationsAsSeen);
  yield takeLatest(togglePageNotificationSentAndSeenStatusActions.request, togglePageNotificationSentAndSeenStatus);
  yield takeLatest(refreshPageNotificationsListAction, refreshPageNotificationsList);
}

/**
 * CONSTANTS
 */
export const FILTER_NOTIFICATIONS_DEFAULT_REQUEST: NotificationFilterPageRequest = {
  deletePreviousItems: true,
  pageIndex: 0,
  pageSize: initialState.headerList.basicPageSize
};
