import { Col, Radio, Row } from "antd";
import { useEffect, useState } from "react";
import t from "../../../../../../app/i18n";
import { rowGutter } from "../../../../../../common/constants";
import { CommissionsBatchStep, ManualBailAccountMovementsReport } from "../../../enums";
import { ManualBailAccountMovementsFilterPageResult } from "../../../types";

interface Props {
  filter: ManualBailAccountMovementsFilterPageResult;
  onReportSwitch: (report: ManualBailAccountMovementsReport) => void;
}

const BatchManualBailAccountMovementsFilterView = ({ filter, onReportSwitch }: Props) => {
  const [currentReport, setCurrentReport] = useState<ManualBailAccountMovementsReport>(
    ManualBailAccountMovementsReport.ASSOCIATED
  );

  useEffect(() => {
    setCurrentReport(filter.report);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleReportSwitch = (report: ManualBailAccountMovementsReport) => {
    setCurrentReport(report);
    onReportSwitch(report);
  };

  return (
    <Row gutter={rowGutter} justify="center" className="margin-bottom-medium">
      <Col span={24} className="center-align">
        <Radio.Group
          size="large"
          buttonStyle="solid"
          value={currentReport}
          onChange={e => handleReportSwitch(ManualBailAccountMovementsReport[e.target.value])}
        >
          <Radio.Button value={ManualBailAccountMovementsReport.ASSOCIATED}>
            {t("commissions.batch.enums.manualBailAccountMovementsReport.ASSOCIATED")}
          </Radio.Button>
          {filter.batch.step !== CommissionsBatchStep.FINISH && (
            <Radio.Button value={ManualBailAccountMovementsReport.DISASSOCIATED}>
              {t("commissions.batch.enums.manualBailAccountMovementsReport.DISASSOCIATED")}
            </Radio.Button>
          )}
        </Radio.Group>
      </Col>
    </Row>
  );
};

export default BatchManualBailAccountMovementsFilterView;
