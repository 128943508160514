import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import t from "../../../app/i18n";
import {
  EntityObject,
  NormalizedError,
  OptimisticLockVersion,
  RootState,
  TwoLevelEntityIdObject,
  TwoLevelEntityObject
} from "../../../common/types";
import { initSearchPageResult } from "../../../common/utils/apiUtils";
import messageUtils from "../../../common/utils/messageUtils";
import { replaceInArray } from "../../../common/utils/utils";
import { changeRunningRequestKeyAction } from "../../ducks";
import { CommissionType } from "../batch/enums";
import { Commission, UpdateCommission } from "../batch/types";
import api from "./api";
import {
  DuplicatePostponedCommission,
  PostponedCommissionReducerState,
  PostponedCommissionsFilterPageRequest,
  PostponedCommissionsFilterPageResult
} from "./types";

/**
 * ACTIONS
 */
export const filterPostponedCommissionsActions = createAsyncAction(
  "postponed-commission/FILTER_REQUEST",
  "postponed-commission/FILTER_SUCCESS",
  "postponed-commission/FILTER_FAILURE"
)<PostponedCommissionsFilterPageRequest, PostponedCommissionsFilterPageResult, NormalizedError>();

export const updatePostponedCommissionActions = createAsyncAction(
  "postponed-commission/UPDATE_REQUEST",
  "postponed-commission/UPDATE_SUCCESS",
  "postponed-commission/UPDATE_FAILURE"
)<EntityObject<UpdateCommission>, Commission, NormalizedError>();

export const resolvePostponedCommissionActions = createAsyncAction(
  "postponed-commission/RESOLVE_REQUEST",
  "postponed-commission/RESOLVE_SUCCESS",
  "postponed-commission/RESOLVE_FAILURE"
)<EntityObject<OptimisticLockVersion>, Commission, NormalizedError>();

export const unresolvePostponedCommissionActions = createAsyncAction(
  "postponed-commission/UNRESOLVE_REQUEST",
  "postponed-commission/UNRESOLVE_SUCCESS",
  "postponed-commission/UNRESOLVE_FAILURE"
)<EntityObject<OptimisticLockVersion>, Commission, NormalizedError>();

export const createDuplicatedCommissionActions = createAsyncAction(
  "postponed-commission/CREATE_DUPLICATE_REQUEST",
  "postponed-commission/CREATE_DUPLICATE_SUCCESS",
  "postponed-commission/CREATE_DUPLICATE_FAILURE"
)<EntityObject<DuplicatePostponedCommission>, Commission, NormalizedError>();

export const updateDuplicatedCommissionActions = createAsyncAction(
  "postponed-commission/UPDATE_DUPLICATE_REQUEST",
  "postponed-commission/UPDATE_DUPLICATE_SUCCESS",
  "postponed-commission/UPDATE_DUPLICATE_FAILURE"
)<TwoLevelEntityObject<UpdateCommission>, Commission, NormalizedError>();

export const deleteDuplicatedCommissionActions = createAsyncAction(
  "postponed-commission/DELETE_DUPLICATE_REQUEST",
  "postponed-commission/DELETE_DUPLICATE_SUCCESS",
  "postponed-commission/DELETE_DUPLICATE_FAILURE"
)<TwoLevelEntityIdObject, void, NormalizedError>();

export const deleteStatePostponedCommissionsPageAction = createAction("postponed-commission/DELETE_STATE_PAGE")<void>();

const actions = {
  filterPostponedCommissionsActions,
  updatePostponedCommissionActions,
  resolvePostponedCommissionActions,
  unresolvePostponedCommissionActions,
  createDuplicatedCommissionActions,
  updateDuplicatedCommissionActions,
  deleteDuplicatedCommissionActions,
  deleteStatePostponedCommissionsPageAction
};

export type PostponedCommissionAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: PostponedCommissionReducerState = {
  postponedCommissionsPage: {
    ...initSearchPageResult<Commission>(),
    report: null,
    batchId: null,
    postponementReasons: [],
    institutionIds: [],
    commissionAmountsSum: null
  }
};

const postponedCommissionsPageReducer = createReducer(initialState.postponedCommissionsPage)
  .handleAction(filterPostponedCommissionsActions.success, (_, { payload }) => payload)
  .handleAction(
    [filterPostponedCommissionsActions.failure, deleteStatePostponedCommissionsPageAction],
    () => initialState.postponedCommissionsPage
  )
  .handleAction(
    [updatePostponedCommissionActions.success, updateDuplicatedCommissionActions.success],
    (state, { payload }) => ({
      ...state,
      pageData: replaceInArray(
        state.pageData,
        item => item.id === payload.id,
        () => payload
      )
    })
  );
export const postponedCommissionReducer = combineReducers<PostponedCommissionReducerState>({
  postponedCommissionsPage: postponedCommissionsPageReducer
});

/**
 * SELECTORS
 */
const selectPostponedCommission = (state: RootState): PostponedCommissionReducerState =>
  state.commissions.postponedCommission;

export const selectPostponedCommissionsPage = (state: RootState): PostponedCommissionsFilterPageResult =>
  selectPostponedCommission(state).postponedCommissionsPage;

/**
 * SAGAS
 */
function* filterPostponedCommissions({ payload }: ReturnType<typeof filterPostponedCommissionsActions.request>) {
  try {
    const response: AxiosResponse<PostponedCommissionsFilterPageResult> = yield call(
      api.filterPostponedCommissions,
      payload
    );
    yield put(filterPostponedCommissionsActions.success(response.data));
  } catch (error) {
    yield put(filterPostponedCommissionsActions.failure(error));
  }
}

function* updatePostponedCommission({ payload }: ReturnType<typeof updatePostponedCommissionActions.request>) {
  try {
    const response: AxiosResponse<Commission> = yield call(api.updatePostponedCommission, payload);
    yield put(updatePostponedCommissionActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(updatePostponedCommissionActions.failure(error));
  }
}

function* resolvePostponedCommission({ payload }: ReturnType<typeof resolvePostponedCommissionActions.request>) {
  try {
    const response: AxiosResponse<Commission> = yield call(api.resolvePostponedCommission, payload);
    yield put(resolvePostponedCommissionActions.success(response.data));
    messageUtils.itemUpdatedNotification();
    yield* refreshCurrentPage();
  } catch (error) {
    yield put(resolvePostponedCommissionActions.failure(error));
  }
}

function* unresolvePostponedCommission({ payload }: ReturnType<typeof unresolvePostponedCommissionActions.request>) {
  try {
    const response: AxiosResponse<Commission> = yield call(api.unresolvePostponedCommission, payload);
    yield put(unresolvePostponedCommissionActions.success(response.data));
    messageUtils.itemUpdatedNotification();
    yield* refreshCurrentPage();
  } catch (error) {
    yield put(unresolvePostponedCommissionActions.failure(error));
  }
}

function* createDuplicatedCommission({ payload }: ReturnType<typeof createDuplicatedCommissionActions.request>) {
  try {
    const response: AxiosResponse<Commission> = yield call(api.createDuplicatedCommission, payload);
    yield put(createDuplicatedCommissionActions.success(response.data));
    if (response.data.type !== CommissionType.DUPLICATED_COMMISSION) {
      messageUtils.warnNotification(t("common.warning"), t("commissions.batch.helpers.commissionIncludeFailure"));
    } else {
      messageUtils.itemUpdatedNotification();
    }
    yield* refreshCurrentPage();
  } catch (error) {
    yield put(createDuplicatedCommissionActions.failure(error));
  }
}

function* updateDuplicatedCommission({ payload }: ReturnType<typeof updateDuplicatedCommissionActions.request>) {
  try {
    const response: AxiosResponse<Commission> = yield call(api.updateDuplicatedCommission, payload);
    yield put(updateDuplicatedCommissionActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(updateDuplicatedCommissionActions.failure(error));
  }
}

function* deleteDuplicatedCommission({ payload }: ReturnType<typeof deleteDuplicatedCommissionActions.request>) {
  try {
    yield call(api.deleteDuplicatedCommission, payload);
    yield put(deleteDuplicatedCommissionActions.success());
    messageUtils.itemUpdatedNotification();
    yield* refreshCurrentPage();
  } catch (error) {
    yield put(deleteDuplicatedCommissionActions.failure(error));
  }
}

function* refreshCurrentPage() {
  const page: PostponedCommissionsFilterPageResult = yield select(selectPostponedCommissionsPage);
  yield put(
    filterPostponedCommissionsActions.request({
      pageIndex: page.pageElementsCount === 1 ? Math.max(page.pageIndex - 1, 0) : page.pageIndex,
      pageSize: page.pageSize,
      keyword: page.keyword,
      report: page.report,
      batchId: page.batchId,
      postponementReasons: page.postponementReasons,
      institutionIds: page.institutionIds
    })
  );
}

export function* postponedCommissionSaga() {
  yield takeLatest(filterPostponedCommissionsActions.request, filterPostponedCommissions);
  yield takeLatest(updatePostponedCommissionActions.request, updatePostponedCommission);
  yield takeLatest(resolvePostponedCommissionActions.request, resolvePostponedCommission);
  yield takeLatest(unresolvePostponedCommissionActions.request, unresolvePostponedCommission);
  yield takeLatest(createDuplicatedCommissionActions.request, createDuplicatedCommission);
  yield takeLatest(updateDuplicatedCommissionActions.request, updateDuplicatedCommission);
  yield takeLatest(deleteDuplicatedCommissionActions.request, deleteDuplicatedCommission);
}
