import { Button, Col, Divider, Form, Row, Select } from "antd";
import { FormInstance, Rule } from "antd/lib/form";
import React, { useState } from "react";
import t from "../../../../app/i18n";
import { rowGutter } from "../../../../common/constants";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { RealtyGenForm } from "../../../calculator/calcs/realty/types";
import { TravelGenForm } from "../../../calculator/calcs/travel/types";
import { VehicleGenForm } from "../../../calculator/calcs/vehicle/types";
import { ClientFormType, ClientRepresentativeFunction, ClientType } from "../../enums";
import { Client, ClientRepresentative, LegalClient } from "../../types";
import ClientRepresentativeSelectModal from "./ClientRepresentativeSelectModal";
import ClientSearchInput, { ClientSearchInputProps } from "./ClientSearchInput";

interface Props<Type = ClientFormType> {
  form: FormInstance<VehicleGenForm> | FormInstance<RealtyGenForm> | FormInstance<TravelGenForm>;
  additionalRules?: Rule[];
  policyHolder: Client;
  searchInputProps: SearchInputProps<Type>;
  colSpan: number;
}

interface SearchInputProps<Type = ClientFormType> extends Omit<ClientSearchInputProps<Type>, "formItemProps"> {}

const ClientRepresentativeSearchForm = ({ form, additionalRules, policyHolder, searchInputProps, colSpan }: Props) => {
  const [selectModalOpen, setSelectModalOpen] = useState<boolean>(false);

  const handleSelectModalOpenClick = (): void => {
    searchInputProps.onFocus(ClientFormType.REPRESENTATIVE);
    setSelectModalOpen(true);
  };

  const handleRepresentativeSelect = (clientRepresentative: ClientRepresentative): void => {
    const identifier = clientRepresentative.representative.identifier;

    form.setFieldsValue({
      clientsData: {
        representativeFunction: ((policyHolder as LegalClient).representatives || []).find(
          r => r.representative.identifier === identifier
        )?.function,
        representativeIdentifier: identifier
      }
    });

    setSelectModalOpen(false);
    searchInputProps.onChange(identifier, ClientFormType.REPRESENTATIVE);
  };

  if (policyHolder?.type === ClientType.LEGAL) {
    const representatives = (policyHolder as LegalClient).representatives || [];

    return (
      <>
        <Divider className="divider-subheader">{t("calc.helpers.clientRepresentativeSection")}</Divider>

        <Row gutter={rowGutter}>
          {representatives.length > 0 && (
            <Col style={{ paddingTop: "32px" }}>
              <Button size="small" className="secondary-button" onClick={handleSelectModalOpenClick}>
                {t("client.helpers.selectRepresentative")}
              </Button>
            </Col>
          )}

          <Col span={colSpan}>
            <Form.Item
              name={["clientsData", "representativeFunction"]}
              label={t("client.enums.representativeFunction._label")}
              rules={[validations.notNull]}
            >
              <Select
                {...selectStandardProps}
                options={Object.keys(ClientRepresentativeFunction).map(func => ({
                  value: func,
                  label: t("client.enums.representativeFunction." + func)
                }))}
              />
            </Form.Item>
          </Col>

          <ClientSearchInput<ClientFormType>
            {...searchInputProps}
            formItemProps={{
              name: ["clientsData", "representativeIdentifier"],
              label: t("calc.helpers.representativeIdentifier"),
              rules: [validations.notBlank, validations.pin, validations.adultByPin, ...(additionalRules || [])],
              validateFirst: true
            }}
          />
        </Row>

        <ClientRepresentativeSelectModal
          open={selectModalOpen}
          client={policyHolder as LegalClient}
          onSelect={handleRepresentativeSelect}
          onFormCancel={() => setSelectModalOpen(false)}
        />
      </>
    );
  }

  return null;
};

export default ClientRepresentativeSearchForm;
