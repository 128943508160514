import { Button, Card, Col, Row, Space, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { generatePath, Link, useNavigate, useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import AntIcon from "../../../common/components/icons/AntIcon";
import BackNavigationArrow from "../../../common/components/views/BackNavigationArrow";
import { rowGutter } from "../../../common/constants";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";
import { Permission } from "../../../common/security/authorization/enums";
import { ActionProps, EntityIdObject, PageResult, RootState } from "../../../common/types";
import { formatAgentAggregatedName } from "../../../common/utils/formatUtils";
import { appendSearchParamsToURL } from "../../../common/utils/utils";
import { selectHasPermissions } from "../../auth/ducks";
import BailAccountSettingsForm from "../../commissions/bailaccount/components/forms/BailAccountSettingsForm";
import BailAccountMovementTableView from "../../commissions/bailaccount/components/views/BailAccountMovementTableView";
import BailAccountSettingsView from "../../commissions/bailaccount/components/views/BailAccountSettingsView";
import {
  createBailAccountMovementActions,
  createBailAccountSettingsActions,
  deleteBailAccountMovementActions,
  deleteBailAccountSettingsActions,
  deleteStateBailAccountMovementsPageAction,
  filterBailAccountMovementsActions,
  selectBailAccountMovementsPage,
  updateBailAccountMovementActions,
  updateBailAccountSettingsActions
} from "../../commissions/bailaccount/ducks";
import {
  BailAccountMovementFilterPageResult,
  CreateUpdateBailAccountSettings
} from "../../commissions/bailaccount/types";
import { CommissionsBatchOutputAttachment } from "../../commissions/batch/types";
import DebtAccountMovementTableView from "../../commissions/debtaccount/components/views/DebtAccountMovementTableView";
import {
  deleteStateDebtAccountMovementsPageAction,
  filterDebtAccountMovementsActions,
  selectDebtAccountMovementsPage
} from "../../commissions/debtaccount/ducks";
import { DebtAccountMovementFilterPageResult } from "../../commissions/debtaccount/types";
import CommissionsOutputsTableView from "../../commissions/outputs/components/CommissionsOutputsTableView";
import {
  deleteStateCommissionsOutputsPageAction,
  deleteStateOutputCalculatedCommissionsPageAction,
  deleteStateOutputSpecialCommissionsPageAction,
  filterCommissionsOutputsActions,
  filterOutputCalculatedCommissionsActions,
  filterOutputSpecialCommissionsActions,
  selectCommissionsOutputsPage,
  selectOutputCalculatedCommissionsPage,
  selectOutputSpecialCommissionsPage
} from "../../commissions/outputs/ducks";
import { CalculatedCommissionsFilterPageResult } from "../../commissions/outputs/types";
import CommissionsSettingsAssignLevelForm from "../../commissions/settings/components/forms/CommissionsSettingsAssignLevelForm";
import CommissionsSettingsRuleForm from "../../commissions/settings/components/forms/CommissionsSettingsRuleForm";
import CommissionsSettingsRuleHelperView from "../../commissions/settings/components/views/CommissionsSettingsRuleHelperView";
import CommissionsSettingsRuleTableView from "../../commissions/settings/components/views/CommissionsSettingsRuleTableView";
import {
  createCommissionsSettingsRuleActions,
  deleteCommissionsSettingsAttachmentActions,
  deleteCommissionsSettingsRuleActions,
  deleteStateCommissionsSettingsAction,
  getCommissionsSettingsActions,
  selectCommissionsSettingsData,
  updateCommissionsSettingsActions,
  updateCommissionsSettingsRuleActions,
  uploadCommissionsSettingsAttachmentsActions
} from "../../commissions/settings/ducks";
import { CommissionsSettings, CommissionsSettingsRule } from "../../commissions/settings/types";
import SpecialCommissionsSettingsRuleForm from "../../commissions/special/components/forms/SpecialCommissionsSettingsRuleForm";
import SpecialCommissionsSettingsRuleTableView from "../../commissions/special/components/views/SpecialCommissionsSettingsRuleTableView";
import {
  createSpecialCommissionsRuleActions,
  deleteSpecialCommissionsRuleActions,
  updateSpecialCommissionsRuleActions
} from "../../commissions/special/ducks";
import {
  SpecialCommissionBase,
  SpecialCommissionsFilterPageResult,
  SpecialCommissionsSettingsRule
} from "../../commissions/special/types";
import { selectRouterLocationSearch } from "../../ducks";
import { requests } from "../api";
import AgentTypeTag from "../components/AgentTypeTag";
import AgentCommissionsSummaryView from "../components/views/commissions/AgentCommissionsSummaryView";
import { deleteStateAgentDetailAction, getAgentActions, selectAgentDetail } from "../ducks";
import { AGENT_ROUTE_PATHS } from "../paths";
import { Agent } from "../types";

interface StateProps {
  agent: Agent;
  commissionsSettings: CommissionsSettings;
  commissionsOutputsPage: PageResult<CommissionsBatchOutputAttachment>;
  outputCalculatedCommissionsPage: CalculatedCommissionsFilterPageResult;
  outputSpecialCommissionsPage: SpecialCommissionsFilterPageResult<SpecialCommissionBase>;
  bailAccountMovementsPage: BailAccountMovementFilterPageResult;
  debtAccountMovementsPage: DebtAccountMovementFilterPageResult;
  hasCommissionsPermission: boolean;
  hasAgentCommissionsSettingsPermission: boolean;
  hasManageAgentCommissionsSettingsPermission: boolean;
  hasTopAgentCommissionsPermission: boolean;
  urlSearchQuery: string;
}

interface ActionsMap {
  getAgent: typeof getAgentActions.request;
  deleteStateAgentDetail: typeof deleteStateAgentDetailAction;
  getCommissionsSettings: typeof getCommissionsSettingsActions.request;
  updateCommissionsSettings: typeof updateCommissionsSettingsActions.request;
  deleteStateCommissionsSettings: typeof deleteStateCommissionsSettingsAction;
  uploadCommissionsSettingsAttachment: typeof uploadCommissionsSettingsAttachmentsActions.request;
  deleteCommissionsSettingsAttachment: typeof deleteCommissionsSettingsAttachmentActions.request;
  filterCommissionsOutputs: typeof filterCommissionsOutputsActions.request;
  deleteStateCommissionsOutputsPage: typeof deleteStateCommissionsOutputsPageAction;
  filterOutputCalculatedCommissions: typeof filterOutputCalculatedCommissionsActions.request;
  deleteStateOutputCalculatedCommissions: typeof deleteStateOutputCalculatedCommissionsPageAction;
  filterOutputSpecialCommissions: typeof filterOutputSpecialCommissionsActions.request;
  deleteStateOutputSpecialCommissions: typeof deleteStateOutputSpecialCommissionsPageAction;
  createCommissionsSettingsRule: typeof createCommissionsSettingsRuleActions.request;
  updateCommissionsSettingsRule: typeof updateCommissionsSettingsRuleActions.request;
  deleteCommissionsSettingsRule: typeof deleteCommissionsSettingsRuleActions.request;
  createSpecialCommissionsRule: typeof createSpecialCommissionsRuleActions.request;
  updateSpecialCommissionsRule: typeof updateSpecialCommissionsRuleActions.request;
  deleteSpecialCommissionsRule: typeof deleteSpecialCommissionsRuleActions.request;
  createBailAccountSettings: typeof createBailAccountSettingsActions.request;
  updateBailAccountSettings: typeof updateBailAccountSettingsActions.request;
  deleteBailAccountSettings: typeof deleteBailAccountSettingsActions.request;
  filterBailAccountMovements: typeof filterBailAccountMovementsActions.request;
  createBailAccountMovement: typeof createBailAccountMovementActions.request;
  updateBailAccountMovement: typeof updateBailAccountMovementActions.request;
  deleteBailAccountMovement: typeof deleteBailAccountMovementActions.request;
  deleteStateBailAccountMovementsPage: typeof deleteStateBailAccountMovementsPageAction;
  filterDebtAccountMovements: typeof filterDebtAccountMovementsActions.request;
  deleteStateDebtAccountMovementsPage: typeof deleteStateDebtAccountMovementsPageAction;
}

type Props = StateProps & ActionProps<ActionsMap>;

const TAB = {
  OUTPUTS: "outputs",
  RULES: "rules",
  SPECIAL_RULES: "special-rules",
  BAIL_ACCOUNT: "bail-account",
  DEBT_ACCOUNT: "debt-account"
};

const AgentCommissionsContainer = ({
  agent,
  commissionsSettings,
  commissionsOutputsPage,
  outputCalculatedCommissionsPage,
  outputSpecialCommissionsPage,
  bailAccountMovementsPage,
  debtAccountMovementsPage,
  hasCommissionsPermission,
  hasAgentCommissionsSettingsPermission,
  hasManageAgentCommissionsSettingsPermission,
  hasTopAgentCommissionsPermission,
  urlSearchQuery,
  actions
}: Props) => {
  const { id } = useParams<EntityIdObject>();
  const navigate = useNavigate();

  const [tabKey, setTabKey] = useState<string>(TAB.OUTPUTS);

  const [assignLevelFormOpen, setAssignLevelFormOpen] = useState<boolean>(false);

  const [commissionsRuleFormOpen, setCommissionsRuleFormOpen] = useState<boolean>(false);
  const [commissionsRuleToUpdate, setCommissionsRuleToUpdate] = useState<CommissionsSettingsRule>();

  const [specialCommissionsRuleFormOpen, setSpecialCommissionsRuleFormOpen] = useState<boolean>(false);
  const [specialCommissionsRuleToUpdate, setSpecialCommissionsRuleToUpdate] =
    useState<SpecialCommissionsSettingsRule>();

  const [bailAccountSettingsFormOpen, setBailAccountSettingsFormOpen] = useState<boolean>(false);

  useEffect(() => {
    actions.getAgent({ id });

    const urlParams = new URLSearchParams(urlSearchQuery);
    const urlTabKey = urlParams.get("tab");
    switch (urlTabKey) {
      case TAB.OUTPUTS:
      case TAB.BAIL_ACCOUNT:
      case TAB.DEBT_ACCOUNT:
        setTabKey(urlTabKey);
        break;
      case TAB.RULES:
      case TAB.SPECIAL_RULES:
        setTabKey(hasAgentCommissionsSettingsPermission ? urlTabKey : TAB.OUTPUTS);
        break;
      default:
        setTabKey(TAB.OUTPUTS);
        break;
    }

    return () => {
      actions.deleteStateAgentDetail();
      actions.deleteStateCommissionsSettings();
      actions.deleteStateBailAccountMovementsPage();
      actions.deleteStateDebtAccountMovementsPage();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (agent) {
      actions.getCommissionsSettings({ id: agent.id });
    }
  }, [agent]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabKeyChange = (key: string): void => {
    setTabKey(key);
    navigate(appendSearchParamsToURL({ pageIndex: null, tab: key }), { replace: true });
  };

  const handleAssignLevelClick = (): void => {
    setAssignLevelFormOpen(true);
  };

  const handleUnassignLevelClick = (): void => {
    actions.updateCommissionsSettings({
      id: commissionsSettings.agent.id,
      object: {
        optimisticLockVersion: commissionsSettings.optimisticLockVersion,
        includeInBulkPayment: commissionsSettings.includeInBulkPayment,
        useAsHead: commissionsSettings.useAsHead,
        unifyPointsCalculation: commissionsSettings.unifyPointsCalculation,
        commissionsSettingsLevelId: null,
        levelChangeDate: null,
        levelSuperiorAgentId: null
      }
    });
  };

  const handleAssignLevelFormCancel = (): void => {
    setAssignLevelFormOpen(false);
  };

  const handleIncludeInBulkPaymentChange = (checked: boolean): void => {
    if (commissionsSettings) {
      actions.updateCommissionsSettings({
        id: agent.id,
        object: {
          optimisticLockVersion: commissionsSettings.optimisticLockVersion,
          includeInBulkPayment: checked,
          useAsHead: commissionsSettings.useAsHead,
          unifyPointsCalculation: commissionsSettings.unifyPointsCalculation,
          commissionsSettingsLevelId: commissionsSettings.commissionsSettingsLevel?.id,
          levelChangeDate: commissionsSettings.lastLevelChangeDate,
          levelSuperiorAgentId: null
        }
      });
    }
  };

  const handleUseAsHeadChange = (checked: boolean): void => {
    if (commissionsSettings) {
      actions.updateCommissionsSettings({
        id: agent.id,
        object: {
          optimisticLockVersion: commissionsSettings.optimisticLockVersion,
          includeInBulkPayment: commissionsSettings.includeInBulkPayment,
          useAsHead: checked,
          unifyPointsCalculation: commissionsSettings.unifyPointsCalculation,
          commissionsSettingsLevelId: commissionsSettings.commissionsSettingsLevel?.id,
          levelChangeDate: commissionsSettings.lastLevelChangeDate,
          levelSuperiorAgentId: null
        }
      });
    }
  };

  const handleUnifyPointsCalculationChange = (checked: boolean): void => {
    if (commissionsSettings) {
      actions.updateCommissionsSettings({
        id: agent.id,
        object: {
          optimisticLockVersion: commissionsSettings.optimisticLockVersion,
          includeInBulkPayment: commissionsSettings.includeInBulkPayment,
          useAsHead: commissionsSettings.useAsHead,
          unifyPointsCalculation: checked,
          commissionsSettingsLevelId: commissionsSettings.commissionsSettingsLevel?.id,
          levelChangeDate: commissionsSettings.lastLevelChangeDate,
          levelSuperiorAgentId: null
        }
      });
    }
  };

  const handleCommissionsSettingsRuleUpdateClick = (rule: CommissionsSettingsRule): void => {
    setCommissionsRuleFormOpen(true);
    setCommissionsRuleToUpdate(rule);
  };

  const handleCommissionsSettingsRuleFormCancel = (): void => {
    setCommissionsRuleFormOpen(false);
    setCommissionsRuleToUpdate(null);
  };

  const handleSpecialCommissionsRuleUpdateClick = (rule: SpecialCommissionsSettingsRule): void => {
    setSpecialCommissionsRuleFormOpen(true);
    setSpecialCommissionsRuleToUpdate(rule);
  };

  const handleSpecialCommissionsRuleFormCancel = (): void => {
    setSpecialCommissionsRuleFormOpen(false);
    setSpecialCommissionsRuleToUpdate(null);
  };

  const handleBailAccountSettingsCreateUpdateClick = (): void => {
    setBailAccountSettingsFormOpen(true);
  };

  const handleBailAccountSettingsDeleteClick = (): void => {
    actions.deleteBailAccountSettings({ id: agent.id });
  };

  const handleBailAccountSettingsFormCreateSubmit = (settings: CreateUpdateBailAccountSettings): void => {
    actions.createBailAccountSettings({ id: agent.id, object: settings });
  };

  const handleBailAccountSettingsFormUpdateSubmit = (settings: CreateUpdateBailAccountSettings): void => {
    actions.updateBailAccountSettings({ id: agent.id, object: settings });
  };

  const handleBailAccountSettingsFormCancel = (): void => {
    setBailAccountSettingsFormOpen(false);
  };

  return (
    <DisplayWrapper itemLoaded={!!agent && !!commissionsSettings} notFoundCheckRequest={requests.GET_AGENT}>
      {agent && commissionsSettings && (
        <>
          <Card
            className="card-box"
            title={
              <>
                <BackNavigationArrow>
                  <h2 className="left-float">
                    {`${t("commissions.settings.titles.page")} | ${formatAgentAggregatedName(agent)}`}
                  </h2>
                </BackNavigationArrow>
                <AgentTypeTag style={{ margin: "6px 0 0 8px" }} type={agent.type} />
              </>
            }
            extra={
              <Link to={generatePath(AGENT_ROUTE_PATHS.detail.to, { id: agent.id })}>
                <Button size="small" icon={<AntIcon type="user" />} className="secondary-button">
                  {t("agent.actions.goToAgent")}
                </Button>
              </Link>
            }
          >
            <AgentCommissionsSummaryView
              agent={agent}
              commissionsSettings={commissionsSettings}
              hasTopAgentCommissionsPermission={hasTopAgentCommissionsPermission}
              hasManageAgentCommissionsPermission={hasManageAgentCommissionsSettingsPermission}
              onAssignLevelClick={handleAssignLevelClick}
              onUnassignLevelClick={handleUnassignLevelClick}
              onIncludeInBulkPaymentChange={handleIncludeInBulkPaymentChange}
              onUseAsHeadChange={handleUseAsHeadChange}
              onUnifyPointsCalculationChange={handleUnifyPointsCalculationChange}
              uploadCommissionsSettingsAttachment={actions.uploadCommissionsSettingsAttachment}
              deleteCommissionsSettingsAttachment={actions.deleteCommissionsSettingsAttachment}
            />

            <Tabs
              activeKey={tabKey}
              className="margin-top-small"
              onChange={handleTabKeyChange}
              items={[
                {
                  key: TAB.OUTPUTS,
                  label: (
                    <span>
                      <AntIcon type="euro-circle" />
                      {t("commissions.settings.sections.outputs")}
                    </span>
                  ),
                  children: (
                    <CommissionsOutputsTableView
                      agentId={agent.id}
                      outputsPage={commissionsOutputsPage}
                      filterCommissionsOutputs={actions.filterCommissionsOutputs}
                      deleteStateCommissionsOutputsPage={actions.deleteStateCommissionsOutputsPage}
                      showFullBatchNameWithLink={hasCommissionsPermission}
                      outputCalculatedCommissionsPage={outputCalculatedCommissionsPage}
                      filterOutputCalculatedCommissions={actions.filterOutputCalculatedCommissions}
                      deleteStateOutputCalculatedCommissions={actions.deleteStateOutputCalculatedCommissions}
                      outputSpecialCommissionsPage={outputSpecialCommissionsPage}
                      filterOutputSpecialCommissions={actions.filterOutputSpecialCommissions}
                      deleteStateOutputSpecialCommissions={actions.deleteStateOutputSpecialCommissions}
                      urlSearchQuery={urlSearchQuery}
                    />
                  )
                },
                hasAgentCommissionsSettingsPermission
                  ? {
                      key: TAB.RULES,
                      label: (
                        <span>
                          <AntIcon type="settings" />
                          {t("commissions.settings.sections.rules")}
                        </span>
                      ),
                      children: (
                        <>
                          <CommissionsSettingsRuleTableView
                            rules={commissionsSettings.rules}
                            agentId={agent.id}
                            showActions={hasManageAgentCommissionsSettingsPermission}
                            onUpdateClick={handleCommissionsSettingsRuleUpdateClick}
                            onDelete={actions.deleteCommissionsSettingsRule}
                          />

                          {hasManageAgentCommissionsSettingsPermission && (
                            <div className="margin-top-small">
                              <Space>
                                <Button
                                  type="primary"
                                  size="small"
                                  icon={<AntIcon type="plus" />}
                                  onClick={() => setCommissionsRuleFormOpen(true)}
                                >
                                  {t("commissions.settings.actions.addRule")}
                                </Button>
                                <CommissionsSettingsRuleHelperView />
                              </Space>
                            </div>
                          )}
                        </>
                      )
                    }
                  : null,
                hasAgentCommissionsSettingsPermission
                  ? {
                      key: TAB.SPECIAL_RULES,
                      label: (
                        <span>
                          <AntIcon type="retweet" />
                          {t("commissions.special.titles.page")}
                        </span>
                      ),
                      children: (
                        <>
                          <SpecialCommissionsSettingsRuleTableView
                            rules={commissionsSettings.specialRules}
                            agentId={agent.id}
                            showActions={hasManageAgentCommissionsSettingsPermission}
                            onUpdateClick={handleSpecialCommissionsRuleUpdateClick}
                            onDelete={actions.deleteSpecialCommissionsRule}
                          />

                          {hasManageAgentCommissionsSettingsPermission && (
                            <div
                              className={
                                commissionsSettings.specialRules.length > 0 ? "table-footer-margin" : "margin-top-small"
                              }
                            >
                              <Button
                                type="primary"
                                size="small"
                                icon={<AntIcon type="plus" />}
                                onClick={() => setSpecialCommissionsRuleFormOpen(true)}
                              >
                                {t("commissions.special.actions.addRule")}
                              </Button>
                            </div>
                          )}
                        </>
                      )
                    }
                  : null,
                {
                  key: TAB.BAIL_ACCOUNT,
                  label: (
                    <span>
                      <AntIcon type="plus-square" />
                      {t("commissions.bailAccount.titles.page")}
                    </span>
                  ),
                  children: (
                    <Row gutter={rowGutter}>
                      <Col span={7}>
                        <BailAccountSettingsView
                          settings={commissionsSettings.bailAccountSettings}
                          showActions={hasManageAgentCommissionsSettingsPermission}
                          noSettingsLabel={t("commissions.bailAccount.helpers.noSettings")}
                          onCreateUpdateClick={handleBailAccountSettingsCreateUpdateClick}
                          onDeleteClick={handleBailAccountSettingsDeleteClick}
                        />
                      </Col>

                      <Col span={17}>
                        <BailAccountMovementTableView
                          agentId={agent.id}
                          movementsPage={bailAccountMovementsPage}
                          filterBailAccountMovements={actions.filterBailAccountMovements}
                          createBailAccountMovement={actions.createBailAccountMovement}
                          updateBailAccountMovement={actions.updateBailAccountMovement}
                          deleteBailAccountMovement={actions.deleteBailAccountMovement}
                          deleteStateBailAccountMovements={actions.deleteStateBailAccountMovementsPage}
                          showFullBatchNameWithLink={hasCommissionsPermission}
                          showActions={hasManageAgentCommissionsSettingsPermission}
                          showCreateMovementAction={!!commissionsSettings.bailAccountSettings}
                          showFilterSwitch
                          urlSearchQuery={urlSearchQuery}
                        />
                      </Col>
                    </Row>
                  )
                },
                {
                  key: TAB.DEBT_ACCOUNT,
                  label: (
                    <span>
                      <AntIcon type="minus-square" />
                      {t("commissions.debtAccount.titles.page")}
                    </span>
                  ),
                  children: (
                    <ContentWrapper>
                      <DebtAccountMovementTableView
                        agentId={agent.id}
                        movementsPage={debtAccountMovementsPage}
                        filterDebtAccountMovements={actions.filterDebtAccountMovements}
                        deleteStateDebtAccountMovements={actions.deleteStateDebtAccountMovementsPage}
                        showFullBatchNameWithLink={hasCommissionsPermission}
                        showFilterSwitch
                        urlSearchQuery={urlSearchQuery}
                      />
                    </ContentWrapper>
                  )
                }
              ]}
            />
          </Card>

          <CommissionsSettingsAssignLevelForm
            open={assignLevelFormOpen}
            settings={commissionsSettings}
            onUpdate={actions.updateCommissionsSettings}
            onFormCancel={handleAssignLevelFormCancel}
          />

          <CommissionsSettingsRuleForm
            open={commissionsRuleFormOpen}
            rule={commissionsRuleToUpdate}
            agent={agent}
            onCreate={actions.createCommissionsSettingsRule}
            onUpdate={actions.updateCommissionsSettingsRule}
            onFormCancel={handleCommissionsSettingsRuleFormCancel}
          />

          <SpecialCommissionsSettingsRuleForm
            open={specialCommissionsRuleFormOpen}
            rule={specialCommissionsRuleToUpdate}
            agent={agent}
            onCreate={actions.createSpecialCommissionsRule}
            onUpdate={actions.updateSpecialCommissionsRule}
            onFormCancel={handleSpecialCommissionsRuleFormCancel}
          />

          <BailAccountSettingsForm
            settings={commissionsSettings.bailAccountSettings}
            open={bailAccountSettingsFormOpen}
            onCreateSubmit={handleBailAccountSettingsFormCreateSubmit}
            onUpdateSubmit={handleBailAccountSettingsFormUpdateSubmit}
            onFormCancel={handleBailAccountSettingsFormCancel}
          />
        </>
      )}
    </DisplayWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  agent: selectAgentDetail(state),
  commissionsSettings: selectCommissionsSettingsData(state) as CommissionsSettings,
  commissionsOutputsPage: selectCommissionsOutputsPage(state),
  outputCalculatedCommissionsPage: selectOutputCalculatedCommissionsPage(state),
  outputSpecialCommissionsPage: selectOutputSpecialCommissionsPage(state),
  bailAccountMovementsPage: selectBailAccountMovementsPage(state),
  debtAccountMovementsPage: selectDebtAccountMovementsPage(state),
  hasCommissionsPermission: selectHasPermissions(Permission.COMMISSIONS)(state),
  hasAgentCommissionsSettingsPermission: selectHasPermissions(Permission.AGENT_COMMISSIONS_SETTINGS)(state),
  hasManageAgentCommissionsSettingsPermission: selectHasPermissions(Permission.AGENT_COMMISSIONS_SETTINGS_MANAGE)(
    state
  ),
  hasTopAgentCommissionsPermission: selectHasPermissions(Permission.TOP_AGENT_COMMISSIONS)(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      getAgent: getAgentActions.request,
      deleteStateAgentDetail: deleteStateAgentDetailAction,
      getCommissionsSettings: getCommissionsSettingsActions.request,
      updateCommissionsSettings: updateCommissionsSettingsActions.request,
      deleteStateCommissionsSettings: deleteStateCommissionsSettingsAction,
      uploadCommissionsSettingsAttachment: uploadCommissionsSettingsAttachmentsActions.request,
      deleteCommissionsSettingsAttachment: deleteCommissionsSettingsAttachmentActions.request,
      filterCommissionsOutputs: filterCommissionsOutputsActions.request,
      deleteStateCommissionsOutputsPage: deleteStateCommissionsOutputsPageAction,
      filterOutputCalculatedCommissions: filterOutputCalculatedCommissionsActions.request,
      deleteStateOutputCalculatedCommissions: deleteStateOutputCalculatedCommissionsPageAction,
      filterOutputSpecialCommissions: filterOutputSpecialCommissionsActions.request,
      deleteStateOutputSpecialCommissions: deleteStateOutputSpecialCommissionsPageAction,
      createCommissionsSettingsRule: createCommissionsSettingsRuleActions.request,
      updateCommissionsSettingsRule: updateCommissionsSettingsRuleActions.request,
      deleteCommissionsSettingsRule: deleteCommissionsSettingsRuleActions.request,
      createSpecialCommissionsRule: createSpecialCommissionsRuleActions.request,
      updateSpecialCommissionsRule: updateSpecialCommissionsRuleActions.request,
      deleteSpecialCommissionsRule: deleteSpecialCommissionsRuleActions.request,
      createBailAccountSettings: createBailAccountSettingsActions.request,
      updateBailAccountSettings: updateBailAccountSettingsActions.request,
      deleteBailAccountSettings: deleteBailAccountSettingsActions.request,
      filterBailAccountMovements: filterBailAccountMovementsActions.request,
      createBailAccountMovement: createBailAccountMovementActions.request,
      updateBailAccountMovement: updateBailAccountMovementActions.request,
      deleteBailAccountMovement: deleteBailAccountMovementActions.request,
      deleteStateBailAccountMovementsPage: deleteStateBailAccountMovementsPageAction,
      filterDebtAccountMovements: filterDebtAccountMovementsActions.request,
      deleteStateDebtAccountMovementsPage: deleteStateDebtAccountMovementsPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(AgentCommissionsContainer);
